import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createLienHeSchema } from '../../../../schemas/createLienHeSchema'
import { https } from '../../../../services/apiService'
import { editPhanCongLienHe } from '../../../../store/actions/phanconglienheAction'
const dateFormat = 'DD-MM-YYYY'

const ModalEditPhanCong = ({ openChiTiet, setOpenChiTiet, onLoad }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoPhanCong, listTrangThaiPhanCong } = useSelector(
    (state) => state.phanconglienheReducer,
  )
  const [allThongTinLH, setAllThongTinLH] = useState([])
  // xử lí form thêm dịch vụ
  const handleSubmit = (value) => {
    delete value.tennguoiphancong
    dispatch(editPhanCongLienHe(value, onLoad))
    formik.resetForm()
    setOpenChiTiet(false)
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idpclh: infoPhanCong?.idpclh,
      tennguoiphancong: infoPhanCong?.tennguoiphancong,
      idttlh: infoPhanCong?.idttlh,
      noidung: infoPhanCong?.noidung,
      idttpclh: infoPhanCong?.idttpclh,
      ghichu: infoPhanCong?.ghichu,
      nguoisua: infoUser.idnv,
    },
    onSubmit: (value) => handleSubmit(value),
  })

  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('CRMThongTinLienHe/GetAllCRMThongTinLienHe')
        setAllThongTinLH(data.filter((item) => item.sudung))
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  return (
    <>
      <Modal
        okText="Xác nhận"
        cancelText="Huỷ"
        className="text-center"
        title={'Chi tiết phân công'}
        onOk={formik.handleSubmit}
        open={openChiTiet}
        onCancel={() => setOpenChiTiet(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">Người phân công</label>
            <Input value={formik.values.tennguoiphancong} readOnly />
          </div>
          <div>
            <label className="font-semibold  text-base">Công ty liên hệ</label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={formik.values.idttlh}
              options={allThongTinLH?.map((items) => ({
                label: items.tencongty,
                value: items.idttlh,
              }))}
              onChange={handleSelected('idttlh')}
              className="w-full"
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Nội dung</label>
            <Input
              value={formik.values.noidung}
              name="noidung"
              onChangeCapture={formik.handleChange}
              status={formik.errors.noidung && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Trạng thái</label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={formik.values.idttpclh}
              options={listTrangThaiPhanCong?.map((items) => ({
                label: items.trangthai,
                value: items.idttpclh,
              }))}
              onChange={handleSelected('idttpclh')}
              className="w-full"
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Ghi chú</label>
            <Input
              value={formik.values.ghichu}
              name="ghichu"
              onChangeCapture={formik.handleChange}
              status={formik.errors.ghichu && 'error'}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEditPhanCong
