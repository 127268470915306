import { ConfigProvider, Table, Modal, Button, Select } from 'antd'
import { FileAddOutlined, DownloadOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import * as typeAction from '../../../../store/constants/constants'
import {
  createBN_Action,
  getAllSelectClinicAction,
  getQuanAction,
  getXaAction,
} from '../../../../store/actions/receiveAction'
import { useFormik } from 'formik'
import dayjs from 'dayjs'
import moment from 'moment'
import { postKhachHangDTAction } from '../../../../store/actions/khachHangDTAction'
import { createCustomerSchema } from '../../../../schemas/createCustomerSchema'
import importKhachHangFileExcel from '../../../../data/Form/importKhachHangFileExcel.xlsx'
import * as XLSX from 'xlsx'
import ToastCus from '../../../../utils/Toast'
import styled from 'styled-components'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'
const BtnNhapExcel = styled(Button)`
  color: #fff;
  border-color: #1da57a;
  background-color: #1da57a;

  &:hover {
    color: #fff !important;
    border-color: #1da57acc !important;
    background-color: #1da57acc !important;
  }
`
const BtnFileMau = styled(Button)`
  color: #1da57a;
  border-color: #ccc;
  background-color: #fff;

  &:hover {
    color: #1da57a !important;
    border-color: #1da57a !important;
    background-color: #fff !important;
  }
`
const ImportKhachHang = ({ isModalImport, setIsModalImport, listDoiTac }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const [listKH, setListKH] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [rowActive, setRowActive] = useState(null)
  const [doiTac, setDoiTac] = useState(null)
  const handleDownloadFile = (e) => {
    e.preventDefault()
    const link = document.createElement('a')
    link.href = importKhachHangFileExcel
    link.target = '_blank'
    link.download = 'Mẫu-import-khách-hàng.xlsx'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const handleSubmit = () => {
    if (!listKH || listKH?.length === 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    setIsModalImport(false)
    dispatch(postKhachHangDTAction(listKH))
  }
  const handleFileChange = (e) => {
    if (!doiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn đối tác!',
      })
      return
    }
    setLoadingTable(true)
    try {
      const file = e.target.files[0]
      const fileName = file.name
      const extension = fileName.split('.').pop().toLowerCase()
      if (extension !== 'xlsx' && extension !== 'xls') {
        // kiểm tra xem có phải là file excel hay không
        ToastCus.fire({
          icon: 'error',
          title: 'Chỉ chấp nhận tệp Excel (.xlsx, .xls)!',
        })
        return
      }
      const render = new FileReader()
      render.readAsArrayBuffer(file)
      render.onload = (e) => {
        const excelData = e.target.result
        const workbook = XLSX.read(excelData, {
          type: 'buffer',
          cellDates: true,
        })
        const worksheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[worksheetName]
        const data = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          dateNF: null,
        })
        const newData = data?.map((item, index) => ({
          key: `KH${index}`,
          tenKH: item['Tên KH']?.trim() ?? '',
          ngaySinh: item['Ngày sinh']?.trim()
            ? moment(item['Ngày sinh']?.trim()).format('YYYY-MM-DD')
            : null,
          gioiTinh: item['Giới tính']?.trim() ?? '',
          dienThoai: item['SĐT']?.trim()?.replaceAll(' ', '') ?? '',
          cccd: item['CCCD']?.trim()?.replaceAll(' ', '') ?? '',
          diaChi: item['Địa chỉ']?.trim() ?? '',
          ghiChu: item['Ghi chú']?.trim() ?? '',
          idDoiTac: doiTac,
          nguoiTao: infoUser?.idnv,
        }))
        setListKH((prev) => newData)
      }
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi tạo voucher',
      })
    } finally {
      setLoadingTable(false)
    }
  }
  useEffect(() => {
    setListKH([])
    setDoiTac(null)
  }, [isModalImport])
  return (
    <Modal
      className="text-center"
      title="Thêm khách hàng của đối tác"
      open={isModalImport}
      onCancel={() => {
        setIsModalImport(false)
      }}
      okText="Lưu"
      cancelText="Huỷ bỏ"
      width={1200}
      onOk={handleSubmit}
    >
      <form className="text-start">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between gap-2">
            <BtnNhapExcel>
              <label className="flex items-center" for="file-upload">
                <FileAddOutlined style={{ fontSize: 16 }} />
                &nbsp;Nhập excel
              </label>
              <input
                id="file-upload"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                value={''}
                onChange={handleFileChange}
                type="file" ///////////////////////
                className="hidden" ///////////////////////
              />
            </BtnNhapExcel>
            <BtnFileMau
              onClick={handleDownloadFile}
              icon={<DownloadOutlined style={{ fontSize: 16 }} />}
            >
              File mẫu
            </BtnFileMau>
            <div className="ml-auto flex justify-end items-center gap-1">
              <Select
                placeholder="Chọn đối tác..."
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                // filterSort={(optionA, optionB) =>
                //   (optionA?.label ?? '')
                //     .toLowerCase()
                //     .localeCompare((optionB?.label ?? '').toLowerCase())
                // }
                options={listDoiTac?.map((item) => ({
                  key: `doitac${item?.maDoiTac}${item?.idDoiTac}`,
                  value: item?.idDoiTac,
                  label: item?.tenDoiTac,
                }))}
                status={!doiTac && 'error'}
                value={doiTac}
                onChange={(value) => setDoiTac(value)}
                className="w-60"
                size="small"
              />
            </div>
            {/* <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#1DA57A', // Change primary color
                  colorLink: '#1DA57A', // Change link color
                  // You can add more token customizations here
                },
                components: {
                  Button: {
                    // Customize the button styles here
                    colorPrimary: '#1DA57A', // Change button primary color
                    colorText: '#1DA57A',
                  },
                },
              }}
            >
              <div></div>
            </ConfigProvider> */}
          </div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                },
              },
            }}
          >
            <Table
              onRow={(record) => {
                return {
                  onClick: () => {
                    setRowActive(record)
                  }, // click row
                }
              }}
              loading={loadingTable}
              bordered
              pagination={{
                // style: {
                //   padding: 0,
                //   margin: 0,
                //   marginTop: 10,
                // },
                pageSize: 10,
                showSizeChanger: false,
              }}
              dataSource={listKH}
              scroll={{ x: 1350 }}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'stt',
                  key: 'stt',
                  width: 50,
                  align: 'center',
                  render: (text, record, index) => ++index,
                },
                // {
                //   title: 'Mã BN',
                //   dataIndex: 'maBenhNhan',
                //   key: 'maBenhNhan',
                //   width: 100,
                // },
                {
                  title: 'Tên BN',
                  dataIndex: 'tenKH',
                  key: 'tenKH',
                },
                {
                  title: 'Ngày sinh',
                  dataIndex: 'ngaySinh',
                  key: 'ngaySinh',
                  width: 120,
                  align: 'center',
                  render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
                },
                {
                  title: 'Giới tính',
                  dataIndex: 'gioiTinh',
                  key: 'gioiTinh',
                  width: 75,
                  align: 'center',
                },
                {
                  title: 'SĐT',
                  dataIndex: 'dienThoai',
                  key: 'dienThoai',
                  width: 120,
                  align: 'center',
                  render: (text, record) => (record?.key === rowActive?.key ? <p>{text}</p> : ''),
                },
                {
                  title: 'CCCD',
                  dataIndex: 'cccd',
                  key: 'cccd',
                  width: 120,
                  align: 'center',
                  // render: (text) => '123456789789',
                },

                {
                  title: 'Địa chỉ',
                  dataIndex: 'diaChi',
                  key: 'diaChi',
                  width: 350,
                },
                {
                  title: 'Ghi chú',
                  dataIndex: 'ghiChu',
                  key: 'ghiChu',
                  width: 200,
                },
                // {
                //   title: 'Người tạo',
                //   dataIndex: 'tenNguoiTao',
                //   key: 'tenNguoiTao',
                //   width: 200,
                // },
                // {
                //   title: 'Ngày tạo',
                //   dataIndex: 'ngayTao',
                //   key: 'ngayTao',
                //   align: 'center',
                //   render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
                //   width: 140,
                // },
                // {
                //   title: 'Mã TCQG',
                //   dataIndex: 'maTCQG',
                //   key: 'maTCQG',
                //   width: 90,
                // },
                // {
                //   title: '',
                //   dataIndex: 'action',
                //   key: 'action',
                //   width: 40,
                //   render: () => (
                //     <Tooltip title="Xem" color="blue" className="cursor-pointer">
                //       <EyeOutlined style={{ fontSize: 20 }} className="text-blue-500" />
                //     </Tooltip>
                //   ),
                // },
              ]}
            />
          </ConfigProvider>
        </div>
      </form>
    </Modal>
  )
}

export default ImportKhachHang
