import React from 'react'
import { useSelector } from 'react-redux'
import { Tabs } from 'antd'
// import { calculateAge } from '../../../../utils/calculateAge'
import TabKhamNoiKhoa from './TabFormKLS/TabKhamNoiKhoa'
import TabKhamNgoaiKhoa from './TabFormKLS/TabKhamNgoaiKhoa'
import TabKhamDaLieu from './TabFormKLS/TabKhamDaLieu'
import TabKhamSanPhuKhoa from './TabFormKLS/TabKhamSanPhuKhoa'
import TabKhamMat from './TabFormKLS/TabKhamMat'
import TabKhamTaiMuiHong from './TabFormKLS/TabKhamTaiMuiHong'
import TabKhamRangHamMat from './TabFormKLS/TabKhamRangHamMat'
import TabKetLuan from './TabFormKLS/TabKetLuan'
// import moment from 'moment'
// import dayjs from 'dayjs'
// import 'moment/locale/vi'
// moment.locale('vi')
// const now = moment()
// const dateFormat = 'DD/MM/YYYY'
function FormKLS({ reloadListBN, listIdNhomDcXemTabKetLuan }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  // const [tabForm, setTabForm] = useState(1)
  return (
    <div className="pt-2">
      {/* <ConfigProvider theme={{ token: { padding: '0px', lineHeight: '0px' } }}></ConfigProvider> */}
      <Tabs
        // value={tabForm}
        // onChange={(value) => setTabForm(value)}
        type="card"
        items={[
          ...([...listIdNhomDcXemTabKetLuan].includes(infoUser?.idNhom)
            ? [
                {
                  key: 1,
                  value: 1,
                  label: '1. Khám nội khoa',
                  children: <TabKhamNoiKhoa tabForm={'khamnoikhoa'} />,
                },
              ]
            : []),
          ...([...listIdNhomDcXemTabKetLuan, 215].includes(infoUser?.idNhom)
            ? [
                {
                  key: 2,
                  value: 2,
                  label: '2.1. Khám ngoại khoa',
                  children: <TabKhamNgoaiKhoa tabForm={'khamngoaikhoa'} />,
                },
              ]
            : []),
          ...([...listIdNhomDcXemTabKetLuan, 217].includes(infoUser?.idNhom)
            ? [
                {
                  key: 3,
                  value: 3,
                  label: '2.2. Khám da liễu',
                  children: <TabKhamDaLieu tabForm={'khamdalieu'} />,
                },
              ]
            : []),
          ...([...listIdNhomDcXemTabKetLuan, 185, 187, 191].includes(infoUser?.idNhom)
            ? [
                {
                  key: 4,
                  value: 4,
                  label: '3. Khám sản phụ khoa',
                  children: <TabKhamSanPhuKhoa tabForm={'khamsanphukhoa'} />,
                },
              ]
            : []),
          ...([...listIdNhomDcXemTabKetLuan, 189].includes(infoUser?.idNhom)
            ? [
                {
                  key: 5,
                  value: 5,
                  label: '4. Khám mắt',
                  children: <TabKhamMat tabForm={'khammat'} />,
                },
              ]
            : []),
          ...([...listIdNhomDcXemTabKetLuan, 218].includes(infoUser?.idNhom)
            ? [
                {
                  key: 6,
                  value: 6,
                  label: '5. Khám tai mũi họng',
                  children: <TabKhamTaiMuiHong tabForm={'khamtaimuihong'} />,
                },
              ]
            : []),
          ...([...listIdNhomDcXemTabKetLuan, 219].includes(infoUser?.idNhom)
            ? [
                {
                  key: 7,
                  value: 7,
                  label: '6. Khám răng hàm mặt',
                  children: <TabKhamRangHamMat tabForm={'khamranghammat'} />,
                },
              ]
            : []),
        ]}
        // items={[
        //   {
        //     key: 1,
        //     value: 1,
        //     label: '1. Khám nội khoa',
        //     children: <TabKhamNoiKhoa tabForm={'khamnoikhoa'} />,
        //   },
        //   {
        //     key: 2,
        //     value: 2,
        //     label: '2.1. Khám ngoại khoa',
        //     children: <TabKhamNgoaiKhoa tabForm={'khamngoaikhoa'} />,
        //   },
        //   {
        //     key: 3,
        //     value: 3,
        //     label: '2.2. Khám da liễu',
        //     children: <TabKhamDaLieu tabForm={'khamdalieu'} />,
        //   },
        //   {
        //     key: 4,
        //     value: 4,
        //     label: '3. Khám sản phụ khoa',
        //     children: <TabKhamSanPhuKhoa tabForm={'khamsanphukhoa'} />,
        //   },
        //   {
        //     key: 5,
        //     value: 5,
        //     label: '4. Khám mắt',
        //     children: <TabKhamMat tabForm={'khammat'} />,
        //   },
        //   {
        //     key: 6,
        //     value: 6,
        //     label: '5. Khám tai mũi họng',
        //     children: <TabKhamTaiMuiHong tabForm={'khamtaimuihong'} />,
        //   },
        //   {
        //     key: 7,
        //     value: 7,
        //     label: '6. Khám răng hàm mặt',
        //     children: <TabKhamRangHamMat tabForm={'khamranghammat'} />,
        //   },
        // ]}

        // {
        //   key: 8,
        //   value: 8,
        //   label: '7. Kết luận',
        //   children: <TabKetLuan tabForm={'ketluan'} reloadListBN={reloadListBN} />,
        // },
      />
    </div>
  )
}

export default FormKLS
