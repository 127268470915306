import React, { useEffect, useState } from 'react'
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Checkbox,
  Select,
  Space,
  Button,
  message,
} from 'antd'
import { https } from '../../../../services/apiService'
import { nhanvienService } from '../../../../services/nhanvien/nhanvienService'
import dayjs from 'dayjs'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import '../../../../../src/AesEstyles.css'
import 'moment/locale/vi'
moment.locale('vi')

const EditStaff = ({
  editMode,
  cancelEditModal,
  updateEditState,
  record,
  onEditSuccess,
  getListNhanVien,
}) => {
  const [editForm] = Form.useForm()
  // Lưu danh sách bằng cấp - chức danh
  const [degreeDiploma, setDegreeDiploma] = useState(null)
  const [regency, setRegency] = useState(null)
  // Lưu danh sách cty - Lưu ctyđược chọn - Lưu ID cty được chọn.
  const [consortium, setConsortium] = useState([])
  const [company, setCompany] = useState('')
  const [idCT, setIdCT] = useState(null)
  // Lưu danh sách chi nhánh => cty
  const [agency, setAgency] = useState('')
  // Lưu danh sách tp - danh sách quận - danh sách phường
  const [cityData, setCityData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [wardsData, setWardsData] = useState([])
  // Quản lý trạng thái tải cho các hoạt động truy xuất dữ liệu.
  const [loading, setLoading] = useState(false)
  const { Option } = Select // Sử dụng để tạo ra các lựa chọn (options) trong một component Select.
  const { infoUser } = useSelector((state) => state.userReducer)

  // danh sách bằng cấp, chức vụ
  useEffect(() => {
    const fetchDegreeAndPosition = async () => {
      try {
        const [degreeResult, positionResult] = await Promise.all([
          nhanvienService.getQualifications(),
          nhanvienService.getTitle(),
        ])
        setDegreeDiploma(degreeResult.data)
        setRegency(positionResult.data)
      } catch (error) {
        console.error('Lỗi khi tìm nạp bằng cấp hoặc chức vụ', error)
      }
    }
    fetchDegreeAndPosition()
  }, [])
  // Hàm nhân viên By ID {idnv}
  const fetchEmployeeByID = async (idnv) => {
    try {
      const result = await nhanvienService.getNhanVienByID(idnv)
      const employeeData = result.data
      if (Array.isArray(employeeData) && employeeData.length > 0) {
        const employee = employeeData[0]
        editForm.setFieldsValue({ idchinhanh: employee?.idchinhanh, idct: employee?.idct })
        fetchBranchesByCompany(employee?.idct)
      } else {
        console.error('Dữ liệu nhân viên không ở định dạng mong đợi:', employeeData)
      }
    } catch (error) {
      console.error('Lỗi tìm nhân viên theo ID:', error)
    }
  }
  useEffect(() => {
    if (record.id) {
      fetchEmployeeByID(record.id)
    }
  }, [record])
  const fetchCompanies = async () => {
    try {
      const result = await https.get('CongTy', {})
      setConsortium(result.data)
      // setIdCT(record.idct)
    } catch (error) {
      console.error('Lỗi khi tìm danh sách công ty', error)
    }
  }
  const fetchBranchesByCompany = async (idCT) => {
    try {
      if (!idCT) return
      const result = await https.get(`ChiNhanh/GetChiNhanhByIDCT`, { params: { idct: idCT } })
      setAgency(result.data)
    } catch (error) {
      console.error('Lỗi không có chi nhánh:', error)
    }
  }

  // danh sách cty
  useEffect(() => {
    fetchCompanies()
  }, [])
  const fetchData = async (url, params) => {
    try {
      setLoading(true)
      const result = await https.get(url, { params })
      setLoading(false)
      return result.data
    } catch (err) {
      setLoading(false)
      console.error(err)
      message.error('Lỗi khi tải dữ liệu')
      return null
    }
  }
  // hàm api tỉnh --> quận --> phường
  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      try {
        // danh sách thành phố
        const cityData = await fetchData('tinh', {})
        if (cityData) {
          setCityData(cityData)
        }
        if (record.idtinh && record.idquan) {
          // danh sách quận theo thành phố
          const districtData = await fetchData(`QuanHuyen/${record.idtinh}`, {})
          if (districtData) {
            setDistrictData(districtData)
          }
          // danh sách phường theo quận
          const wardsData = await fetchData(`PhuongXa/${record.idquan}`, {})
          if (wardsData) {
            setWardsData(wardsData)
          }
        }
      } catch (error) {
        console.error('Lỗi tìm nạp dữ liệu:', error)
      }
    }
    fetchDataAndUpdateState()
  }, [record])
  // Hàm cty, CN
  const handleCongTy = (value) => {
    const selectedCompany = consortium.find((item) => item.idct === value)
    setCompany(selectedCompany?.tenct || '')
    fetchBranchesByCompany(value)
    editForm.setFieldsValue({ idchinhanh: null }) // Xóa chi nhánh đã chọn khi công ty thay đổi
  }
  // Hàm chọn tp => quận
  const handleCityChange = (value) => {
    fetchData(`QuanHuyen/${value}`, {}).then((data) => {
      setDistrictData(data)
    })
    editForm.setFieldsValue({ idquan: null, idphuong: null })
    setDistrictData([])
    setWardsData([])
  }
  // chọn quận => phường
  const handleDistrictChange = (value) => {
    fetchData(`PhuongXa/${value}`, {}).then((data) => {
      setWardsData(data)
    })
    editForm.setFieldsValue({ idphuong: null })
    setWardsData([])
  }
  // hiện dữ liệu form
  useEffect(() => {
    if (record) {
      const initialValues = {
        ...record,
        ngaysinh: record.ngaysinh ? dayjs(record.ngaysinh, 'YYYY/MM/DD') : '',
        // thoiviec: record.thoiviec === 1, // Chuyển đổi từ số nguyên thành boolean
      }
      editForm.setFieldsValue(initialValues)
    }
  }, [record])
  // // Hàm cập nhật thông tin nhân viên
  const putMoreStaffs = async (idnv, update) => {
    try {
      const result = await nhanvienService.putUpdateStaff(idnv, update)
      return result
    } catch (error) {
      console.error('Error:', error)
      throw error // Ném lỗi để xử lý ở phần gọi hàm
    }
  }
  // Xử lý khi cập nhập sửa thông tin
  const onFinish = async (values) => {
    try {
      setLoading(true)
      // Chuyển đổi giá trị của checkbox thành số nguyên
      const updatedValues = {
        ...values,
        ngaysinh: values.ngaysinh
          ? dayjs(values.ngaysinh).format('YYYY-MM-DDTHH:mm:ss')
          : undefined,
        thoiviec: values.thoiviec ? 1 : 0, // Chuyển đổi từ boolean thành số nguyên (1 nếu true, 0 nếu false)
        nguoisua: infoUser?.tenNV,
      }
      const result = await putMoreStaffs(record.id, updatedValues) // Gọi API cập nhật thông tin nhân viên
      if (result) {
        message.success('Cập nhật thông tin thành công!')
        onEditSuccess()
      }
      updateEditState(false) // Đóng form chỉnh sửa
    } catch (error) {
      console.error('error:', error)
      message.error('Có lỗi xảy ra khi cập nhật thông tin!')
    } finally {
      setLoading(false)
    }
  }
  // Xử lý khi form submit lỗi
  const onFinishFailed = (errorInfo) => {}
  // Hủy và đóng form sửa thông tin
  const handleCancel = () => {
    updateEditState(false)
    cancelEditModal()
    // editForm.resetFields()
  }
  // chặn ngày sinh
  const disabledDate = (current) => {
    // Chặn các ngày trong tương lai
    return current && current > moment().endOf('day')
  }
  const validateMessages = {
    required: '${label} không được để trống!',
    types: { number: '${label} không phải là số hợp lệ!' },
    number: { range: '${label} phải nằm trong khoảng từ ${min} đến ${max}' },
  }

  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>Sửa thông tin nhân viên</div>}
      open={editMode}
      onCancel={handleCancel}
      footer={null}
      width={700}
    >
      <Form
        form={editForm}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
      >
        <Row gutter={8}>
          <Col span={6}>
            <Form.Item
              label="Mã nhân viên"
              style={{ marginBottom: '8px' }}
              name="manv"
              rules={[{ required: true }]}
            >
              <Input allowClear maxLength={8} disabled={false} placeholder="Nhập mã nhân viên" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Tên tắt chức danh"
              style={{ marginBottom: '8px' }}
              name="chucdanhviettat"
              rules={[{ required: true }]}
            >
              <Input allowClear maxLength={8} disabled={false} placeholder="Tắt chức danh" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tên nhân viên"
              style={{ marginBottom: '8px' }}
              name="tennv"
              rules={[{ required: true }]}
            >
              <Input allowClear placeholder="Nhập tên nhân viên" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item label="Ngày sinh" style={{ marginBottom: '8px' }} name="ngaysinh">
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Chọn ngày sinh"
                format="DD/MM/YYYY"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Giới tính" style={{ marginBottom: '8px' }} name="gioitinh">
              <Select style={{ width: '100%' }} placeholder="Chọn giới tính">
                <Select.Option value="Nam">Nam</Select.Option>
                <Select.Option value="Nữ">Nữ</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Số điện thoại" style={{ marginBottom: '8px' }} name="dienthoai">
              <Input allowClear maxLength={10} placeholder="Nhập số điện thoại" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item label="Bằng cấp" style={{ marginBottom: '8px' }} name="idbangcap">
              <Select
                placeholder="Chọn Nhập chức danh"
                options={degreeDiploma?.map((qualification) => ({
                  label: qualification.bangcap,
                  value: qualification.idbangcap,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Chức danh" style={{ marginBottom: '8px' }} name="idchucdanh">
              <Select
                placeholder="Chọn Nhập chức danh"
                options={regency?.map((professional) => ({
                  label: professional.tenchucdanh,
                  value: professional.idcd,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tên công ty" style={{ marginBottom: '8px' }} name="idct">
              <Select
                placeholder="Chọn tên công ty"
                onChange={handleCongTy}
                options={consortium?.map((company) => ({
                  label: company.tenct,
                  value: company.idct,
                  key: company.idct,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tên chi nhánh" style={{ marginBottom: '8px' }} name="idchinhanh">
              <Select
                placeholder="Chọn chi nhánh"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {agency &&
                  agency.map((position) => (
                    <Option key={position.idchinhanh} value={position.idchinhanh}>
                      {position.tenchinhanh}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Col span={24}>
          <Form.Item
            label="Tên máy chấm công"
            style={{ marginBottom: '8px' }}
            name="tenmaychamcong"
          >
            <Input allowClear style={{ width: '100%' }} placeholder="Nhập tên máy chấm công" />
          </Form.Item>
        </Col>
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item label="Tỉnh / Thành phố" style={{ marginBottom: '8px' }} name="idtinh">
              <Select
                placeholder="Chọn tỉnh/thành phố"
                onChange={handleCityChange}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {cityData &&
                  cityData.map((borough) => (
                    <Option key={borough.idTinh} value={borough.idTinh}>
                      {borough.tenTinh}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Quận / Huyện" style={{ marginBottom: '8px' }} name="idquan">
              <Select
                placeholder="Chọn quận/huyện"
                onChange={handleDistrictChange}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {districtData &&
                  districtData.map((item) => (
                    <Select.Option key={item.idQuan} value={item.idQuan}>
                      {item.tenQuan}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Phường / Xã" style={{ marginBottom: '8px' }} name="idphuong">
              <Select
                placeholder="Chọn phường/xã"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {wardsData &&
                  wardsData.map((item) => (
                    <Option key={item.idPhuong} value={item.idPhuong}>
                      {item.tenPhuong}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Địa chỉ" name="diachi">
          <Input allowClear placeholder="Nhập địa chỉ" />
        </Form.Item>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 8 }}>Đã nghỉ việc</span>
          <Form.Item name="thoiviec" valuePropName="checked" style={{ marginBottom: 0 }}>
            <Checkbox></Checkbox>
          </Form.Item>
        </div>
        <Form.Item style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit">
              {record ? 'Cập nhật' : 'Thêm'}
            </Button>
            <Button onClick={handleCancel}>Hủy</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditStaff
