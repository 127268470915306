import CachedIcon from '@mui/icons-material/Cached'
import IconButton from '@mui/material/IconButton'
import { ConfigProvider, DatePicker, Select, Table, Tabs } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { branchService } from '../../../../services/branch/branchService'
import { infoBNDienTim, infoBnPending } from '../../../../store/actions/dienTimAction'
import 'moment/locale/vi'
moment.locale('vi')

const formatDB = 'YYYY-MM-DD'
const today = moment()
const formatDate = 'DD/MM/YYYY'
const Customer = ({ BNPending, BNdone, fetchBnDone, fetchBNPending, setIdbncdha }) => {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [idBranch, setIdBranch] = useState(ID_BRANCH)
  const [idBranchDone, setIdBranchDone] = useState(ID_BRANCH)
  const [dateForm, setDateForm] = useState(today.format(formatDB))
  const [dateTo, setDateTo] = useState(today.format(formatDB))

  const [branch, setBranch] = useState([])
  const dispatch = useDispatch()
  //----------- fetch branch by company --------//
  const fetchBranchByCompany = async () => {
    try {
      const { data } = await branchService.getListBranch(ID_COMPANY)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }

  const onClickBn = (record) => {
    setIdbncdha(record.idbncdha)
    dispatch(infoBNDienTim(record.idbnttc, record.idbndt))
  }

  const onClickBnPending = (record) => {
    setIdbncdha(record.idbncdha)
    dispatch(infoBnPending(record.idbnttc, record.idbncdha))
  }
  // Disable future dates
  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day')
  }
  useEffect(() => {
    fetchBranchByCompany()
  }, [])
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              horizontalItemPadding: 12,
            },
          },
        }}
      >
        <Tabs
          items={[
            {
              key: 1,
              label: 'Danh sách chờ',
              children: (
                <>
                  <div className="p-1">
                    <div className="flex items-center gap-2">
                      <Select
                        onChange={(value) => setIdBranch(value)}
                        options={branch.map((item) => ({
                          label: item.tenChiNhanh,
                          value: item.idChiNhanh,
                        }))}
                        className="w-full"
                        value={idBranch}
                      />
                      <IconButton
                        className="ml-1"
                        color="primary"
                        aria-label="add to shopping cart"
                        onClick={() => fetchBNPending(idBranch)}
                      >
                        <CachedIcon />
                      </IconButton>
                    </div>

                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                        },
                      }}
                    >
                      <Table
                        scroll={{
                          y: 310,
                        }}
                        onRow={(record) => ({
                          onClick: () => onClickBnPending(record),
                        })}
                        bordered
                        pagination={false}
                        dataSource={BNPending}
                        columns={[
                          {
                            key: 'STT',
                            dataIndex: 'STT',
                            title: 'STT',
                            width: 40,
                            render: (text, record, index) => ++index,
                          },
                          {
                            key: 'tenbenhnhan',
                            dataIndex: 'tenbenhnhan',
                            title: 'Tên bệnh nhân',
                          },
                        ]}
                      />
                    </ConfigProvider>
                  </div>
                </>
              ),
            },
            {
              key: 2,
              label: 'Danh sách đã thực hiện',
              children: (
                <>
                  <div className="p-1">
                    <Select
                      onChange={(value) => setIdBranchDone(value)}
                      options={branch.map((item) => ({
                        label: item.tenChiNhanh,
                        value: item.idChiNhanh,
                      }))}
                      className="w-full"
                      value={idBranchDone}
                    />
                    <DatePicker
                      allowClear={false}
                      value={dayjs(dateForm)}
                      format={formatDate}
                      disabledDate={disabledDate}
                      onChange={(data, dateString) =>
                        setDateForm(moment(dateString, formatDate).format(formatDB))
                      }
                      size="small"
                    />{' '}
                    -{' '}
                    <DatePicker
                      allowClear={false}
                      value={dayjs(dateTo)}
                      format={formatDate}
                      disabledDate={disabledDate}
                      onChange={(data, dateString) =>
                        setDateTo(moment(dateString, formatDate).format(formatDB))
                      }
                      size="small"
                    />
                    <IconButton
                      onClick={() => fetchBnDone(idBranchDone, dateForm, dateTo)}
                      className="ml-1"
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <CachedIcon />
                    </IconButton>
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                        },
                      }}
                    >
                      <Table
                        scroll={{
                          y: 310,
                        }}
                        onRow={(record) => ({
                          onClick: () => onClickBn(record),
                        })}
                        bordered
                        pagination={false}
                        dataSource={BNdone}
                        columns={[
                          {
                            key: 'STT',
                            dataIndex: 'STT',
                            title: 'STT',
                            width: 40,
                            render: (text, record, index) => ++index,
                          },
                          {
                            key: 'tenbenhnhan',
                            dataIndex: 'tenbenhnhan',
                            title: 'Tên bệnh nhân',
                          },
                        ]}
                      />
                    </ConfigProvider>
                  </div>
                </>
              ),
            },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default Customer
