import {
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  QuestionCircleOutlined,
  SendOutlined,
} from '@ant-design/icons'
import AddIcon from '@mui/icons-material/Add'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import {
  Button,
  ConfigProvider,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { https } from '../../../services/apiService'
import {
  deleteBNCRMById,
  getListKHCRM,
  infoBNCRMAction,
  sendSMSCRMAction,
} from '../../../store/actions/benhnhanCRMAction'
import * as typeAction from '../../../store/constants/constants'
import ModalAdd from './ModalAddBenhNhan/ModalAdd'
import ModalEditBenhNham from './ModalEditBenhNhan/ModalEditBenhNham'
import ModalImport from './ModalInponrt/ModalImport'
import OptionFIlter from './OptionFIlter'

moment.locale('vi')
const { confirm } = Modal

const TraCuu = () => {
  const { modalAddKhamDoan, modalImportKhamDoan, modalEditBNKhamDoan } = useSelector(
    (state) => state.modalReducer,
  )
  const dispatch = useDispatch()
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState({})
  const [listBN, setListBN] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [loading, setLoading] = useState(false)
  const [sendAll, setSendAll] = useState(false)
  const [reload, setReload] = useState(false)
  const [isExport, setIsExport] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const fetchBN = async (filterCondition, page) => {
    setLoading(true)
    setReload(false)
    try {
      const condition = Object.values(filterCondition).filter(Boolean).join('')

      const { data } = await https.get(
        `CRMBenhNhan/GetDanhSachBenhNhanByCondition?pageNumber=${page}${condition}`,
      )
      setListBN(data.data)
      setTotalPage(data.totalPages)
      setPage(page)
      SetSearchParams({ page })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handlDeleteBenhNhan = (idbn) => {
    dispatch(deleteBNCRMById(idbn, setReload))
  }

  const handleAddKhamDoan = () => {
    dispatch({
      type: typeAction.OPEN_ADD_KHAM_DOAN,
    })
  }

  // xử lí dữ liệu xuất excel
  const exportToExcel = () => {
    setIsExport(true)
  }

  useEffect(() => {
    dispatch(getListKHCRM())
  }, [])

  const columns = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'STT',
        key: 'STT',
        width: 80,
        align: 'center',
      },
      {
        title: 'Mã BN',
        dataIndex: 'MABN',
        key: 'MABN',
        width: 120,
      },
      {
        title: 'Tên BN',
        dataIndex: 'TENBN',
        key: 'TENBN',
        width: 200,
      },
      {
        title: 'Giới tính',
        dataIndex: 'GIOITINH',
        key: 'GIOITINH',
        width: 90,
        align: 'center',
      },
      {
        title: 'Ngày sinh',
        dataIndex: 'NGAYSINH',
        key: 'NGAYSINH',
        width: 100,
        align: 'center',
      },
      {
        title: 'Gói khám',
        dataIndex: 'GOIKHAM',
        key: 'GOIKHAM',
        width: 150,
      },
      {
        title: 'Tên công ty',
        dataIndex: 'TENCTY',
        key: 'TENCTY',
        width: 250,
        // ...getColumnSearchProps("TENCTY"),
      },
      {
        title: 'KQ xét nghiệm',
        dataIndex: 'KQXN',
        key: 'KQXN',
        width: 130,
        align: 'center',
      },
      {
        title: 'KQ khám',
        dataIndex: 'KQK',
        key: 'LQK',
        width: 100,
        align: 'center',
      },
      {
        title: 'KQ CĐHA',
        dataIndex: 'KQCDHA',
        key: 'KQCDHA',
        width: 100,
        align: 'center',
      },
      {
        title: 'SMS',
        dataIndex: 'SMS',
        key: 'SMS',
        width: 120,
        align: 'center',
      },
      {
        title: 'Số lần gửi',
        dataIndex: 'SOLANSMS',
        key: 'SOLANSMS',
        width: 100,
        align: 'center',
        render: (text) => <p className={text === 3 ? 'text-red-500' : ''}>{text}</p>,
      },
      {
        title: 'Người cập nhật KQ',
        dataIndex: 'NGUOIKQ',
        key: 'NGUOIKQ',
        width: 150,
        align: 'center',
      },
      {
        title: 'Ngày cập nhật KQ',
        dataIndex: 'NGAYKQ',
        key: 'NGAYKQ',
        width: 150,
        align: 'center',
      },
      {
        title: 'Người gửi SMS',
        dataIndex: 'NGUOIGUISNS',
        key: 'NGUOIGUISNS',
        width: 150,
      },
      {
        title: 'Ngày gửi SMS',
        dataIndex: 'NGAYGUISMS',
        key: 'NGAYGUISMS',
        width: 150,
        align: 'center',
      },
      {
        title: 'Người tạo',
        dataIndex: 'NGUOITAO',
        key: 'NGUOITAO',
        width: 150,
      },
      {
        title: 'Ngày tạo',
        dataIndex: 'NGAYTAO',
        key: 'NGAYTAO',
        width: 150,
        align: 'center',
      },
      {
        title: 'Ghi chú',
        dataIndex: 'GHICHU',
        key: 'GHICHU',
        width: 200,
      },
      {
        title: 'Hành động',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: 90,
        fixed: 'right',
      },
    ]
  }, [])

  // useEffect(() => {
  //   fetchBN('', '', '', '', '', '', 1)
  // }, [])

  const handleRowSelectionChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowsPerPage((prevState) => ({
      ...prevState,
      [page]: newSelectedRowKeys, // Store selected rows for the current page
    }))
  }

  const rowSelection = {
    selectedRowKeys: selectedRowsPerPage[page] || [], // Restore selected rows for the current page
    onChange: handleRowSelectionChange,
  }

  const listBNSendSMS = useMemo(() => {
    return Object.values(selectedRowsPerPage).reduce((acc, current) => acc.concat(current), [])
  }, [selectedRowsPerPage])

  const showConfirm = (selectedRowsPerPage) => {
    confirm({
      title: 'Bạn có muốn gửi sms hay không ?',
      icon: <ExclamationCircleFilled />,
      content: `số lượng bệnh nhân gửi của bạn là ${listBNSendSMS.length} bệnh nhân ! `,
      okText: 'Gửi SMS',
      cancelText: 'Huỷ bỏ',
      onOk() {
        const listSendSMS = []
        for (const key in selectedRowsPerPage) {
          listSendSMS.push(selectedRowsPerPage[key])
        }
        dispatch(sendSMSCRMAction(listBNSendSMS, setLoading, setSelectedRowsPerPage, setReload))
      },
      onCancel() {},
    })
  }

  const content = (
    <div className="w-60">
      <Button
        onClick={() => showConfirm(selectedRowsPerPage)}
        type="primary"
        disabled={listBNSendSMS.length === 0}
        style={{ marginBottom: '8px', width: '100%' }}
      >
        Gửi sms bệnh nhân đã chọn
      </Button>
      <Button
        onClick={() => setSendAll(true)}
        type="primary"
        style={{ marginBottom: '8px', width: '100%' }}
      >
        Gửi sms tất cả bệnh nhân
      </Button>
    </div>
  )

  return (
    <>
      <div className="p-2 bg-[#E8E8E8] h-full ">
        <div
          className="bg-white p-2 h-full rounded-md"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="flex items-center gap-2">
            <div className="mr-4">
              <OptionFIlter
                page={page}
                fetchBN={fetchBN}
                sendAll={sendAll}
                setSendAll={setSendAll}
                reload={reload}
                setReload={setReload}
                isExport={isExport}
                setIsLoadingExport={setIsLoadingExport}
              />
            </div>
            <div className="flex items-center gap-1">
              <div>
                <Popover content={content} trigger="click" placement="bottom">
                  <Button type="primary">
                    <SendOutlined /> Gửi SMS
                  </Button>
                </Popover>
              </div>
            </div>
            <div className="flex items-center gap-2 ml-auto">
              {listBNSendSMS.length !== 0 ? (
                <>
                  <p className="hidden xl:block">
                    <span className="font-bold">Đã chọn:</span>{' '}
                    <span className="text-red-500 text-lg">{listBNSendSMS.length}</span>{' '}
                  </p>
                  <Button type="link" onClick={() => setSelectedRowsPerPage({})}>
                    Bỏ chọn tất cả <ClearOutlined />
                  </Button>
                </>
              ) : (
                ''
              )}

              <Button
                loading={isLoadingExport}
                onClick={exportToExcel}
                className="text-green-700 font-semibold"
                type="default"
              >
                Excel <ImportExportIcon />
              </Button>
              <Button
                onClick={() => {
                  dispatch({
                    type: typeAction.OPEN_MODAL_IMPORT_KHAM_DOAN,
                  })
                }}
                className="bg-blue-500 hover:bg-blue-400 text-white"
              >
                import
              </Button>
              {/* <Button
                onClick={handleAddKhamDoan}
                className="bg-green-700 hover:bg-green-500 text-white flex items-center"
              >
                Tạo <AddIcon fontSize="small" />
              </Button> */}
            </div>
          </div>
          <div className="mt-5">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
              }}
            >
              <Table
                loading={loading}
                pagination={false}
                bordered
                className="mb-5 table-crmBenhNhan"
                scroll={{
                  y: 680,
                  x: 2000,
                }}
                dataSource={listBN?.map((item, index) => ({
                  ...item,
                  STT: (page - 1) * 20 + (index + 1),
                  key: item.idbn,
                  MABN: item.mabn,
                  TENBN: item.tenbn,
                  GIOITINH: item.gioitinh,
                  SODIENTHOAI: item.sodienthoai,
                  NGAYSINH: item.ngaysinh && moment(item.ngaysinh).format('DD/MM/YYYY'),
                  GOIKHAM: item.tengoikham,
                  KQXN: !item.linK_KQXN ? (
                    <Tag color="volcano">Chưa có </Tag>
                  ) : (
                    <Tag color="green">Hoàn thành</Tag>
                  ),
                  KQK: !item.linK_KQKHAM ? (
                    <Tag color="volcano">Chưa có </Tag>
                  ) : (
                    <Tag color="green">Hoàn thành</Tag>
                  ),
                  KQCDHA: !item.linK_KQCDHA ? (
                    <Tag color="volcano">Chưa có </Tag>
                  ) : (
                    <Tag color="green">Hoàn thành</Tag>
                  ),
                  SOLANSMS: item.solansms,
                  TENCTY: item.tenkh,
                  NGUOIGUISNS: item.nguoiguisms,
                  NGAYGUISMS:
                    item.ngayguisms && moment(item.ngayguisms).format('DD/MM/YY HH:mm:ss'),
                  NGUOITAO: item.tennguoitao,
                  NGAYTAO: item.ngaytao && moment(item.ngaytao).format('DD/MM/YY HH:mm:ss'),
                  NGAYKQ: item.ngaykq && moment(item.ngaykq).format('DD/MM/YY HH:mm:ss'),
                  NGUOIKQ: item.tennguoikq,
                  GHICHU: item.ghichu,
                  SMS:
                    item.trangthaisms === 1 ? (
                      <Tag>Chưa gửi</Tag>
                    ) : item.trangthaisms === 2 ? (
                      <Tag color="success">Gửi thành công</Tag>
                    ) : (
                      <Tag color="error">Gửi thất bại</Tag>
                    ),
                  action: (
                    <ul className="flex gap-2 justify-around gap-2 ">
                      <li className="text-lg text-red-500">
                        <Tooltip title="Xóa" placement="top" className="cursor-pointer" color="red">
                          <Popconfirm
                            placement="left"
                            title="Xoá bệnh nhân"
                            description="Bạn có chắc xoá bệnh nhân này không ?"
                            onConfirm={() => {
                              handlDeleteBenhNhan(item.idbn)
                            }}
                            okText="Xoá"
                            okType="danger"
                            cancelText="Huỷ"
                            icon={
                              <QuestionCircleOutlined
                                style={{
                                  color: 'red',
                                }}
                              />
                            }
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        </Tooltip>
                      </li>
                      <li
                        onClick={() => {
                          dispatch(infoBNCRMAction(item.idbn))
                          dispatch({
                            type: typeAction.OPEN_MODAL_EDIT_BN_KHAM_DOAN,
                          })
                        }}
                        className="text-lg text-green-500"
                      >
                        <Tooltip
                          title="sửa"
                          className="cursor-pointer"
                          placement="top"
                          color="green"
                        >
                          <EditOutlined />
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                }))}
                columns={columns}
                rowSelection={rowSelection}
              />
            </ConfigProvider>
            <div className="flex justify-end">
              <Pagination
                className="mt-5"
                showSizeChanger={false}
                current={+useSearch.get('page')}
                simple
                defaultCurrent={1}
                pageSize={20}
                total={20 * totalPage}
                onChange={(page, pagesize) => {
                  setPage(page)
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {modalAddKhamDoan && <ModalAdd setReload={setReload} />}
      {modalImportKhamDoan && <ModalImport />}
      {modalEditBNKhamDoan && <ModalEditBenhNham setReload={setReload} />}
    </>
  )
}

export default TraCuu

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//old version (DO NOT DELETE)
// import {
//   DeleteOutlined,
//   EditOutlined,
//   ExclamationCircleFilled,
//   QuestionCircleOutlined,
//   ReadOutlined,
//   SearchOutlined,
// } from '@ant-design/icons'
// import AddIcon from '@mui/icons-material/Add'
// import CachedIcon from '@mui/icons-material/Cached'
// import ImportExportIcon from '@mui/icons-material/ImportExport'
// import { IconButton, debounce } from '@mui/material'
// import {
//   Button,
//   ConfigProvider,
//   FloatButton,
//   Input,
//   Modal,
//   Popconfirm,
//   Select,
//   Space,
//   Table,
//   Tag,
//   Tooltip,
//   Tour,
// } from 'antd'
// import moment from 'moment'
// import React, { useCallback, useEffect, useRef, useState } from 'react'
// import Highlighter from 'react-highlight-words'
// import { useDispatch, useSelector } from 'react-redux'
// import * as XLSX from 'xlsx'
// import { https } from '../../../services/apiService'
// import {
//   deleteBNCRMById,
//   getAllBNCRM,
//   getListKHCRM,
//   infoBNCRMAction,
//   searchBNCRM,
//   sendSMSCRMAction,
// } from '../../../store/actions/benhnhanCRMAction'
// import * as typeAction from '../../../store/constants/constants'
// import ToastCus from '../../../utils/Toast'
// import ModalAdd from './ModalAddBenhNhan/ModalAdd'
// import ModalEditBenhNham from './ModalEditBenhNhan/ModalEditBenhNham'
// import ModalImport from './ModalInponrt/ModalImport'
// import 'moment/locale/vi'
// moment.locale('vi')
// const { confirm } = Modal
// const TraCuu = () => {
//   const dispatch = useDispatch()
//   const [selectedRowKeys, setSelectedRowKeys] = useState([])
//   const ref1 = useRef(null)
//   const ref2 = useRef(null)
//   const ref3 = useRef(null)
//   const ref4 = useRef(null)
//   const ref5 = useRef(null)
//   const ref6 = useRef(null)
//   const ref7 = useRef(null)
//   const { modalAddKhamDoan, modalImportKhamDoan, modalEditBNKhamDoan } = useSelector(
//     (state) => state.modalReducer,
//   )
//   const { ListBNCRM, listSMS, isLoadingBN } = useSelector((state) => state.benhnhanCRMReducer)
//   const [listKh, setListKh] = useState(null)
//   const [filterKH, setFilterKH] = useState('')
//   const [keyword, setKeyword] = useState('')
//   const onSelectChange = (newSelectedRowKeys, infoBN) => {
//     const newData = []
//     for (let item of infoBN) {
//       const { KQK, KQXN, SMS, action, ...other } = item
//       newData.push(other)
//     }
//     dispatch({
//       type: typeAction.ADD_LIST_INFO_SMS_CRM,
//       payload: newData,
//     })
//     setSelectedRowKeys(newSelectedRowKeys)
//   }
//   const [data, setData] = useState(null)
//   const [dataSource, setDataSource] = useState(null)
//   const [open, setOpen] = useState(false)
//   const [searchText, setSearchText] = useState('')
//   const [searchedColumn, setSearchedColumn] = useState('')
//   const searchInput = useRef(null)
//   const steps = [
//     {
//       title: 'Tìm kiếm bệnh nhân ',
//       description: 'Nhập tên bệnh nhân hoặc mã bệnh nhân để tìm kiếm',
//       target: () => ref1.current,
//     },
//     {
//       title: 'Tải lại danh sách bệnh nhân',
//       description: 'Tải lại danh sách bệnh nhân khi cần thiết',
//       target: () => ref2.current,
//     },
//     {
//       title: 'Gửi SMS',
//       description: 'Khi đã chọn danh sách bệnh nhân muốn gửi, thì nút sẽ hiện lên và tiếp tục gửi',
//       target: () => ref3.current,
//     },
//     {
//       title: 'Thêm bệnh nhân',
//       description: 'Sẽ hiện form nhập thông tin bệnh nhân cần thiết và lưu',
//       target: () => ref4.current,
//     },
//     {
//       title: 'Thêm bệnh nhân theo danh sách Excel',
//       description: 'Sẽ hiện form và chọn file đã được đưa mẫu, sẽ hiện danh sách bệnh nhân và lưu',
//       target: () => ref5.current,
//     },
//     {
//       title: 'Xoá bệnh nhân',
//       description: 'Chọn bệnh nhân xoá và bấm Ok để hoàn thành xoá bệnh nhân',
//       target: () => ref6.current,
//     },
//     {
//       title: 'Sửa thông tin bệnh nhân',
//       description:
//         'Cập nhập thông tin bệnh nhân, upload file khám, xét nghiệm bệnh nhân, chuẩn đoán hình ảnh',
//       target: () => ref7.current,
//     },
//   ]
//   const getColumnSearchProps = (dataIndex) => ({
//     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
//       <div
//         style={{
//           padding: 8,
//         }}
//         onKeyDown={(e) => e.stopPropagation()}
//       >
//         <Input
//           ref={searchInput}
//           placeholder={'Nhập tìm kiếm'}
//           value={selectedKeys[0]}
//           onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
//           onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
//           style={{
//             marginBottom: 8,
//             display: 'block',
//           }}
//         />
//         <Space>
//           <Button
//             type="primary"
//             onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//             icon={<SearchOutlined />}
//             size="small"
//             style={{
//               width: 90,
//             }}
//           >
//             Tìm
//           </Button>
//           <Button
//             onClick={() => clearFilters && handleReset(clearFilters)}
//             size="small"
//             style={{
//               width: 90,
//             }}
//           >
//             Xoá
//           </Button>
//           <Button
//             type="link"
//             size="small"
//             onClick={() => {
//               close()
//             }}
//           >
//             Đóng
//           </Button>
//         </Space>
//       </div>
//     ),
//     filterIcon: (filtered) => (
//       <SearchOutlined
//         style={{
//           color: filtered ? '#1677ff' : undefined,
//         }}
//       />
//     ),
//     onFilter: (value, record) =>
//       record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
//     onFilterDropdownOpenChange: (visible) => {
//       if (visible) {
//         setTimeout(() => searchInput.current?.select(), 100)
//       }
//     },
//     render: (text) =>
//       searchedColumn === dataIndex ? (
//         <Highlighter
//           highlightStyle={{
//             backgroundColor: '#ffc069',
//             padding: 0,
//           }}
//           searchWords={[searchText]}
//           autoEscape
//           textToHighlight={text ? text.toString() : ''}
//         />
//       ) : (
//         text
//       ),
//   })

//   const handleSearch = (selectedKeys, confirm, dataIndex) => {
//     confirm()
//     setSearchText(selectedKeys[0])
//     setSearchedColumn(dataIndex)
//   }

//   const handleReset = (clearFilters) => {
//     clearFilters()
//     setSearchText('')
//   }

//   const handlDeleteBenhNhan = (idbn) => {
//     dispatch(deleteBNCRMById(idbn))
//   }

//   const debounceDropDown = useCallback(
//     debounce((value, filterKH) => {
//       dispatch(searchBNCRM(filterKH, value))
//     }, 300),
//     [],
//   ) // sử dụng debounce để tối tiểu thánh server perfoman

//   const onChangeTable = (pagination, filters, sorter, extra) => {
//     // Lấy dữ liệu đã lọc từ bộ lọc
//     const filteredDataSource = extra.currentDataSource
//     const newData = []
//     for (let items of filteredDataSource) {
//       const { action, SODIENTHOAI, key, SMS, KQXN, KQK, ...other } = items // tách dữ liệu cần export
//       newData.push({
//         ...other,
//         SMS: SMS.props.children,
//         KQK: KQK.props.children,
//         KQXN: KQXN.props.children,
//       })
//     }
//     setData(newData)
//   }

//   const handleSearchBN = (e, filterKH) => {
//     const { value } = e.target
//     setKeyword(value)
//     debounceDropDown(value, filterKH)
//   }
//   const handleAddKhamDoan = () => {
//     dispatch({
//       type: typeAction.OPEN_ADD_KHAM_DOAN,
//     })
//   }
//   const rowSelection = {
//     selectedRowKeys,
//     onChange: onSelectChange,
//   }
//   // xử lí dữ liệu xuất excel
//   const exportToExcel = () => {
//     const headers = [
//       'Mã bệnh nhân',
//       'Tên bệnh nhân',
//       'Giới tính',
//       'Ngày sinh',
//       'Gói khám',
//       'Tên công ty',
//       'KQ xét nghiệm',
//       'KQ khám',
//       'KQ CĐHA',
//       'Ghi chú',
//     ]
//     const formattedData = data.map((item) => ({
//       'Mã bệnh nhân': item.mabn,
//       'Tên bệnh nhân': item.tenbn,
//       'Giới tính': item.gioitinh,
//       'Ngày sinh': moment(item.ngaysinh).format('DD/MM/YYYY'),
//       'Gói khám': item.tengoikham,
//       'Tên công ty': item.tenkh,
//       'KQ xét nghiệm': item.linK_KQXN ?? '',
//       'KQ khám': item.linK_KQKHAM ?? '',
//       'KQ CĐHA': item.linK_KQCDHA ?? '',
//       'Ghi chú': item.ghichu,
//     }))
//     const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
//     const wb = XLSX.utils.book_new()
//     XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
//     XLSX.writeFile(wb, 'Danh sách bệnh nhân.xlsx')
//   }
//   // xử lý gửi sms
//   const showConfirm = () => {
//     for (let i = 0; i < listSMS.length; i++) {
//       if (!listSMS[i].linK_KQCDHA && !listSMS[i].linK_KQKHAM && !listSMS[i].linK_KQXN) {
//         ToastCus.fire({
//           icon: 'error',
//           title: `Bệnh nhân ${listSMS[i].tenbn} chưa có kết quả`,
//         })
//         return
//       }
//     }
//     confirm({
//       title: 'Bạn có muốn gửi sms hay không ?',
//       icon: <ExclamationCircleFilled />,
//       content: `số lượng bệnh nhân gửi của bạn là ${listSMS.length} bệnh nhân ! `,
//       okText: 'Gửi SMS',
//       cancelText: 'Huỷ bỏ',
//       onOk() {
//         dispatch(sendSMSCRMAction(listSMS))
//         setSelectedRowKeys([])
//       },
//       onCancel() {
//         setSelectedRowKeys([])
//       },
//     })
//   }
//   useEffect(() => {
//     dispatch(getAllBNCRM())
//     dispatch(getListKHCRM())
//   }, [])
//   useEffect(() => {
//     ListBNCRM && setData(ListBNCRM)
//     setDataSource(ListBNCRM)
//   }, [ListBNCRM])
//   const columns = [
//     {
//       title: 'STT',
//       dataIndex: 'STT',
//       key: 'STT',
//       width: 40,
//       align: 'center',
//     },
//     {
//       title: 'Mã BN',
//       dataIndex: 'MABN',
//       key: 'MABN',
//       width: 120,
//       ...getColumnSearchProps('MABN'),
//     },
//     {
//       title: 'Tên BN',
//       dataIndex: 'TENBN',
//       key: 'TENBN',
//       width: 200,
//       ...getColumnSearchProps('TENBN'),
//     },
//     {
//       title: 'Giới tính',
//       dataIndex: 'GIOITINH',
//       key: 'GIOITINH',
//       width: 90,
//       align: 'center',
//       ...getColumnSearchProps('GIOITINH'),
//     },
//     {
//       title: 'Ngày sinh',
//       dataIndex: 'NGAYSINH',
//       key: 'NGAYSINH',
//       width: 100,
//       align: 'center',
//     },
//     {
//       title: 'Gói khám',
//       dataIndex: 'GOIKHAM',
//       key: 'GOIKHAM',
//       width: 100,
//       align: 'center',
//       ...getColumnSearchProps('GOIKHAM'),
//     },
//     {
//       title: 'Tên công ty',
//       dataIndex: 'TENCTY',
//       key: 'TENCTY',
//       width: 250,
//       // ...getColumnSearchProps("TENCTY"),
//     },
//     {
//       title: 'KQ xét nghiệm',
//       dataIndex: 'KQXN',
//       key: 'KQXN',
//       width: 130,
//       align: 'center',
//       filters: [
//         {
//           text: 'Chưa có',
//           value: 'Chưa có',
//         },
//         {
//           text: 'Hoàn thành',
//           value: 'Hoàn thành',
//         },
//       ],
//       onFilter: (value, record) => record.KQXN.props.children.indexOf(value) === 0,
//     },
//     {
//       title: 'KQ khám',
//       dataIndex: 'KQK',
//       key: 'LQK',
//       width: 100,
//       align: 'center',
//       filters: [
//         {
//           text: 'Chưa có',
//           value: 'Chưa có',
//         },
//         {
//           text: 'Hoàn thành',
//           value: 'Hoàn thành',
//         },
//       ],
//       onFilter: (value, record) => record.KQK.props.children.indexOf(value) === 0,
//     },
//     {
//       title: 'KQ CĐHA',
//       dataIndex: 'KQCDHA',
//       key: 'KQCDHA',
//       width: 100,
//       align: 'center',
//       filters: [
//         {
//           text: 'Chưa có',
//           value: 'Chưa có',
//         },
//         {
//           text: 'Hoàn thành',
//           value: 'Hoàn thành',
//         },
//       ],
//       onFilter: (value, record) => record.KQCDHA.props.children.indexOf(value) === 0,
//     },
//     {
//       title: 'SMS',
//       dataIndex: 'SMS',
//       key: 'SMS',
//       width: 120,
//       align: 'center',
//       filters: [
//         {
//           text: 'Chưa gửi',
//           value: 'Chưa gửi',
//         },
//         {
//           text: 'Gửi thành công',
//           value: 'Gửi thành công',
//         },
//         {
//           text: 'Gửi thất bại',
//           value: 'Gửi thất bại',
//         },
//       ],
//       onFilter: (value, record) => record.SMS.props.children.indexOf(value) === 0,
//     },
//     {
//       title: 'Người cập nhật KQ',
//       dataIndex: 'NGUOIKQ',
//       key: 'NGUOIKQ',
//       width: 150,
//       align: 'center',
//     },
//     {
//       title: 'Ngày cập nhật KQ',
//       dataIndex: 'NGAYKQ',
//       key: 'NGAYKQ',
//       width: 150,
//       align: 'center',
//     },
//     {
//       title: 'Người gửi SMS',
//       dataIndex: 'NGUOIGUISNS',
//       key: 'NGUOIGUISNS',
//       width: 150,
//     },
//     {
//       title: 'Ngày gửi SMS',
//       dataIndex: 'NGAYGUISMS',
//       key: 'NGAYGUISMS',
//       width: 150,
//       align: 'center',
//     },
//     {
//       title: 'Người tạo',
//       dataIndex: 'NGUOITAO',
//       key: 'NGUOITAO',
//       width: 150,
//     },
//     {
//       title: 'Ngày tạo',
//       dataIndex: 'NGAYTAO',
//       key: 'NGAYTAO',
//       width: 150,
//       align: 'center',
//     },
//     {
//       title: 'Ghi chú',
//       dataIndex: 'GHICHU',
//       key: 'GHICHU',
//       width: 200,
//     },
//     {
//       title: 'Hành động',
//       dataIndex: 'action',
//       key: 'action',
//       align: 'center',
//       width: 90,
//       fixed: 'right',
//     },
//   ]

//   useEffect(() => {
//     ;(async () => {
//       const { data } = await https.get('CRMKhachHang/GetAll')
//       setListKh(data)
//     })()
//   }, [])

//   return (
//     <>
//       <div className="p-2 bg-[#E8E8E8] h-full ">
//         <div
//           className="bg-white p-2 h-full rounded-md"
//           style={{
//             boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
//           }}
//         >
//           <div className="flex justify-between ">
//             <div ref={ref1} className="flex 2xl:gap-5 gap-2 items-center ">
//               <Input.Search
//                 onChange={(e) => handleSearchBN(e, filterKH)}
//                 className="2xl:w-72 w-56"
//                 placeholder="Tìm kiếm tên bệnh nhân"
//                 value={keyword}
//               />

//               <div className="2xl:w-72 w-56 flex">
//                 <Select
//                   showSearch
//                   filterOption={(input, option) =>
//                     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
//                   }
//                   onChange={(value) => setFilterKH(value)}
//                   allowClear
//                   placeholder="Khách hàng"
//                   options={[
//                     { label: 'Tất cả', value: '' },
//                     ...(listKh
//                       ? listKh.map((items) => ({
//                           label: items.tenkh,
//                           value: items.idkh,
//                         }))
//                       : []),
//                   ]}
//                   className="w-full"
//                   value={filterKH}
//                 />
//               </div>
//               <IconButton
//                 ref={ref2}
//                 onClick={() => {
//                   dispatch(searchBNCRM(filterKH, keyword))
//                 }}
//                 color="primary"
//                 aria-label="add to shopping cart"
//               >
//                 <CachedIcon />
//               </IconButton>
//               {/* <Button
//                 ref={ref2}
//                 onClick={() => {
//                   dispatch(getAllBNKhamDoan());
//                 }}
//                 type="primary"
//                 icon={<ReloadOutlined />}
//               >
//                 {" "}
//                 Tải lại
//               </Button> */}
//               <div ref={ref3}>
//                 <button
//                   onClick={showConfirm}
//                   disabled={selectedRowKeys.length === 0}
//                   className={`flex items-center ${
//                     selectedRowKeys.length === 0
//                       ? 'bg-gray-400 cursor-not-allowed'
//                       : 'bg-blue-500 cursor-pointer hover:bg-blue-400 hover:gap-2 hover:translate-x-3'
//                   } text-white gap-1 px-2 py-1 text-gray-800 font-semibold tracking-widest rounded-md duration-300`}
//                 >
//                   SMS
//                   <svg
//                     className="w-5 h-5"
//                     stroke="currentColor"
//                     strokeWidth="1.5"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
//                       stroke-linejoin="round"
//                       stroke-linecap="round"
//                     ></path>
//                   </svg>
//                 </button>
//               </div>

//               {/* <Button
//                 className="flex items-center font-semibold"
//                 ref={ref3}
//                 type="primary"
//                 ghost
//                 disabled={selectedRowKeys.length === 0}
//                 onClick={showConfirm}
//               >
//                 SMS <SendIcon />
//               </Button> */}
//             </div>
//             <div className="flex items-center 2xl  gap-5">
//               {selectedRowKeys.length !== 0 ? (
//                 <p className="hidden xl:block">Đã chọn {selectedRowKeys.length} </p>
//               ) : (
//                 ''
//               )}

//               <Button
//                 onClick={exportToExcel}
//                 className="text-green-700 font-semibold"
//                 type="default"
//               >
//                 Excel <ImportExportIcon />
//               </Button>
//               <Button
//                 ref={ref5}
//                 onClick={() => {
//                   dispatch({
//                     type: typeAction.OPEN_MODAL_IMPORT_KHAM_DOAN,
//                   })
//                 }}
//                 className="bg-blue-500 hover:bg-blue-400 text-white"
//               >
//                 import
//               </Button>
//               <Button
//                 ref={ref4}
//                 onClick={handleAddKhamDoan}
//                 className="bg-green-700 hover:bg-green-500 text-white flex items-center"
//               >
//                 Tạo <AddIcon fontSize="small" />
//               </Button>
//             </div>
//           </div>
//           <div className="mt-5">
//             <ConfigProvider
//               theme={{
//                 token: {
//                   padding: 5,
//                 },
//                 components: {
//                   Table: {
//                     rowHoverBg: '#ecf0f1',
//                     headerBg: '#e6e6e6',
//                     footerBg: '#e6e6e6',
//                     borderColor: '#BABABA',
//                   },
//                 },

//               }}
//             >
//               <Table
//                 onChange={onChangeTable}
//                 loading={isLoadingBN}
//                 pagination={{ pageSize: 20 }}
//                 bordered
//                 rowSelection={rowSelection}
//                 className="mb-5 "
//                 scroll={{
//                   y: 720,
//                   x: 1500,
//                 }}
//                 dataSource={dataSource?.map((item, index) => ({
//                   ...item,
//                   key: item.idbn,
//                   STT: ++index,
//                   MABN: item.mabn,
//                   TENBN: item.tenbn,
//                   GIOITINH: item.gioitinh,
//                   SODIENTHOAI: item.sodienthoai,
//                   NGAYSINH: item.ngaysinh && moment(item.ngaysinh).format('DD/MM/YYYY'),
//                   GOIKHAM: item.tengoikham,
//                   KQXN: !item.linK_KQXN ? (
//                     <Tag color="volcano">Chưa có </Tag>
//                   ) : (
//                     <Tag color="green">Hoàn thành</Tag>
//                   ),
//                   KQK: !item.linK_KQKHAM ? (
//                     <Tag color="volcano">Chưa có </Tag>
//                   ) : (
//                     <Tag color="green">Hoàn thành</Tag>
//                   ),
//                   KQCDHA: !item.linK_KQCDHA ? (
//                     <Tag color="volcano">Chưa có </Tag>
//                   ) : (
//                     <Tag color="green">Hoàn thành</Tag>
//                   ),
//                   TENCTY: item.tenkh,
//                   NGUOIGUISNS: item.nguoiguisms,
//                   NGAYGUISMS:
//                     item.ngayguisms && moment(item.ngayguisms).format('DD/MM/YY HH:mm:ss'),
//                   NGUOITAO: item.tennguoitao,
//                   NGAYTAO: item.ngaytao && moment(item.ngaytao).format('DD/MM/YY HH:mm:ss'),
//                   NGAYKQ: item.ngaykq && moment(item.ngaykq).format('DD/MM/YY HH:mm:ss'),
//                   NGUOIKQ: item.tennguoikq,
//                   GHICHU: item.ghichu,
//                   SMS:
//                     item.trangthaisms === 1 ? (
//                       <Tag>Chưa gửi</Tag>
//                     ) : item.trangthaisms === 2 ? (
//                       <Tag color="success">Gửi thành công</Tag>
//                     ) : (
//                       <Tag color="error">Gửi thất bại</Tag>
//                     ),
//                   action: (
//                     <ul className="flex gap-2 justify-around gap-2 ">
//                       <li ref={ref6} className="text-lg text-red-500">
//                         <Tooltip title="Xóa" placement="top" className="cursor-pointer" color="red">
//                           <Popconfirm
//                             placement="left"
//                             title="Xoá bệnh nhân"
//                             description="Bạn có chắc xoá bệnh nhân này không ?"
//                             onConfirm={() => {
//                               handlDeleteBenhNhan(item.idbn)
//                             }}
//                             okText="Xoá"
//                             okType="danger"
//                             cancelText="Huỷ"
//                             icon={
//                               <QuestionCircleOutlined
//                                 style={{
//                                   color: 'red',
//                                 }}
//                               />
//                             }
//                           >
//                             <DeleteOutlined />
//                           </Popconfirm>
//                         </Tooltip>
//                       </li>
//                       <li
//                         ref={ref7}
//                         onClick={() => {
//                           dispatch(infoBNCRMAction(item.idbn))
//                           dispatch({
//                             type: typeAction.OPEN_MODAL_EDIT_BN_KHAM_DOAN,
//                           })
//                         }}
//                         className="text-lg text-green-500"
//                       >
//                         <Tooltip
//                           title="sửa"
//                           className="cursor-pointer"
//                           placement="top"
//                           color="green"
//                         >
//                           <EditOutlined />
//                         </Tooltip>
//                       </li>
//                     </ul>
//                   ),
//                 }))}
//                 columns={columns}
//               />
//             </ConfigProvider>
//           </div>
//         </div>
//       </div>
//       <FloatButton
//         onClick={() => {
//           setOpen(true)
//         }}
//         icon={<ReadOutlined />}
//         type="primary"
//         style={{
//           bottom: 20,
//           right: 100,
//         }}
//       />
//       <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
//       {modalAddKhamDoan && <ModalAdd />}
//       {modalImportKhamDoan && <ModalImport />}
//       {modalEditBNKhamDoan && <ModalEditBenhNham setSelectedRowKeys={setSelectedRowKeys} />}
//     </>
//   )
// }

// export default TraCuu
