import { https } from '../apiService'
export const xQuangService = {
  getListBnCho: (idcn) =>
    https.get(`BenhNhanXQuang/GetBenhNhanChoXQuang`, {
      params: { idcn },
    }),
  getListBnDa: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanXQuang/GetBenhNhanDaXQuang`, {
      params: { idcn, tuNgay, denNgay },
    }),
  getInfoBnXQuang: (idbncdha, idcdha, idbnttc) =>
    https.get(`BenhNhanXQuang/GetThongTinBenhNhanXQuang`, {
      params: { idbncdha, idcdha, idbnttc },
    }),
  postInfoBnXQuang: (form) => https.post(`BenhNhanXQuang/AddBenhNhanXQuang`, form),
  putInfoBnXQuang: (form) => https.put(`BenhNhanXQuang/UpdateBenhNhanXQuang`, form),
  putFileBnXQuang: (form) => https.put(`BenhNhanXQuang/UpdateFileXQuang`, form),
  deleteFileBnXQuang: (idbnxq, fileKetQua) =>
    https.delete(`BenhNhanXQuang/DeleteFileXQuang?idbnxq=${idbnxq}&fileKetQua=${fileKetQua}`),
}
