import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Tabs,
  Button,
  Input,
  InputNumber,
  Checkbox,
  DatePicker,
  Select,
  Divider,
  Table,
  ConfigProvider,
  Spin,
} from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { formatNumber } from 'devextreme/localization'
// import { childrenClinicService } from '../../../../../../services/childrenClinic/childrenClinicService'
import ToastCus from '../../../../../../utils/Toast'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import 'dayjs/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD/MM/YYYY'
const momentDate = 'YYYY-MM-DD'

const ToaThuoc = ({
  listThuocBN,
  setListThuocBN,
  listThuocTon,
  isLoadingThuocTon,
  ngayThuoc,
  setNgayThuoc,
  tinhLaiSLThuoc,
  tinhLaiSLListThuocBN,
  dvtKhongNhanSL,
  isBsKhongDuocKeToa,
}) => {
  const { historyKham } = useSelector((state) => state.childrenClinicReducer)
  const handleLayToaCu = (value, opt) => {
    if (isBsKhongDuocKeToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    let listThuocBNnew = []
    opt?.arrThuoc?.map((item, index) => {
      const infoThuoc = listThuocTon?.find(
        (itemThuocTon) => itemThuocTon?.idThuoc === item?.idthuoc && itemThuocTon?.tonKho >= 0,
      )
      const tonNew = (item?.soluong ?? 0) + (infoThuoc?.tonKho ?? 0)
      listThuocBNnew.push({
        idthuoc: item?.idthuoc,
        tenbietduoc: item?.tenbietduoc,
        tenhoatchat: item?.tenhoatchat,
        dvt: item?.dvt,
        solanngay: item?.solanngay,
        lieudung: item?.lieudung,
        donvidung: item?.donvidung,
        duongdung: item?.duongdung,
        soluong: item?.soluong <= (tonNew ?? 0) ? item?.soluong : 0,
        ton: infoThuoc?.tonKho ?? 0,
        hamluong: item?.hamluong,
        ghichu: item?.ghichu,
        idkhocn: infoThuoc?.idKhoCN ?? 0,
        giaban: item?.dongia,
        info: {
          thuocVatTu: {
            nongDo: infoThuoc?.thuocVatTu?.nongDo,
            quyCach: infoThuoc?.thuocVatTu?.quyCach,
            idNhom: infoThuoc?.thuocVatTu?.idNhom,
            maThuoc: infoThuoc?.thuocVatTu?.maThuoc,
          },
        },
      })
    })
    setListThuocBN(listThuocBNnew)
  }
  const handleChonThuocTon = (value, opt) => {
    if (isBsKhongDuocKeToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    if (listThuocBN?.find((item) => item?.idthuoc === opt?.idthuoc)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thuốc này đã được chọn!',
      })
      return
    }
    if (!opt?.ton || opt?.ton <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thuốc này không đủ tồn kho!',
      })
      return
    }
    setListThuocBN((prev) => [
      ...prev,
      {
        // ID: ThuocVT[index].ID,
        idthuoc: opt?.idthuoc,
        tenbietduoc: opt?.info?.thuocVatTu?.tenBietDuoc,
        tenhoatchat: opt?.info?.thuocVatTu?.tenHoatChat,
        dvt: opt?.info?.thuocVatTu?.dvt,
        solanngay: 2,
        lieudung: 1,
        donvidung: opt?.info?.thuocVatTu?.donViDung,
        duongdung: opt?.info?.thuocVatTu?.duongDung,
        soluong: dvtKhongNhanSL?.includes(
          opt?.info?.thuocVatTu?.dvt?.replaceAll(' ', '')?.toLowerCase(),
        )
          ? 1
          : // : 0,
            opt?.ton >= ngayThuoc * 2
            ? ngayThuoc * 2
            : opt?.ton, // nếu số lượng vược quá tông thì lấy tồn
        ton: opt?.ton,
        hamluong: opt?.info?.thuocVatTu?.hamLuong,
        ghichu: opt?.info?.thuocVatTu?.hamLuong,
        idkhocn: opt?.info?.idKhoCN,
        giaban: opt?.info?.thuocVatTu?.giaBan,
        solo: opt?.info?.soLo,
        handung: opt?.info?.hanDung,
        sohoadon: opt?.info?.soHoaDon,
        masodangky: opt?.info?.thuocVatTu?.maSoDangKy,
        info: opt?.info,
        // bskedon: infoUser?.tenNV,
        // dvt: ThuocVT[index].thuocVatTu.dvt,
        // ngayKeDon: "2024-05-23T16:39:02.286Z",
        // lieuDungTinh: 1,
        // idbnttc: infoBNTTC?.idbnttc,
      },
    ])
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 40,
      render: (text, record, index) => ++index,
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 60,
    },
    {
      title: 'Lần/ngày',
      dataIndex: 'solanngay',
      key: 'solanngay',
      align: 'center',
      width: 75,
      render: (text, record, index) => (
        <InputNumber
          size="small"
          className="w-full"
          variant={isBsKhongDuocKeToa ? 'filled' : 'outlined'}
          readOnly={isBsKhongDuocKeToa}
          min={1}
          step={1}
          value={text}
          onChange={(value) =>
            setListThuocBN((prev) =>
              prev?.map((item) =>
                item?.idthuoc === record?.idthuoc
                  ? {
                      ...item,
                      solanngay: value,
                      soluong: tinhLaiSLThuoc(
                        item?.dvt,
                        ngayThuoc,
                        item?.lieudung,
                        value,
                        item?.soluong,
                        item?.ton,
                      ),
                    }
                  : item,
              ),
            )
          }
        />
      ),
    },
    {
      title: 'Liều dùng',
      dataIndex: 'lieudung',
      key: 'lieudung',
      align: 'center',
      width: 80,
      render: (text, record, index) => (
        <Input
          size="small"
          className="w-full"
          variant={isBsKhongDuocKeToa ? 'filled' : 'outlined'}
          readOnly={isBsKhongDuocKeToa}
          value={text}
          onChange={(e) =>
            setListThuocBN((prev) =>
              prev?.map((item) =>
                item?.idthuoc === record?.idthuoc
                  ? {
                      ...item,
                      lieudung: e.target.value,
                      soluong: tinhLaiSLThuoc(
                        item?.dvt,
                        ngayThuoc,
                        e.target.value,
                        item?.solanngay,
                        item?.soluong,
                        item?.ton,
                      ),
                    }
                  : item,
              ),
            )
          }
        />
      ),
    },
    {
      title: 'Đơn vị dùng',
      dataIndex: 'donvidung',
      key: 'donvidung',
      align: 'center',
      width: 95,
    },
    {
      title: 'Đường dùng',
      dataIndex: 'duongdung',
      key: 'duongdung',
      align: 'center',
      width: 95,
    },
    {
      title: 'SL',
      dataIndex: 'soluong',
      key: 'soluong',
      align: 'center',
      width: 80,
      render: (text, record, index) => (
        <InputNumber
          size="small"
          className="w-full"
          variant={isBsKhongDuocKeToa ? 'filled' : 'outlined'}
          readOnly={isBsKhongDuocKeToa}
          min={1}
          step={1}
          max={record?.ton}
          status={
            !text || !record?.ton || text <= 0 || record?.ton <= 0 || text > record?.ton
              ? 'error'
              : ''
          }
          value={text}
          onChange={(value) => {
            setListThuocBN((prev) =>
              prev?.map((item) =>
                item?.idthuoc === record?.idthuoc ? { ...item, soluong: value } : item,
              ),
            )
          }}
        />
      ),
    },
    {
      title: 'Tồn',
      dataIndex: 'ton',
      key: 'ton',
      align: 'center',
      width: 80,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      //   align: 'center',
      width: 170,
      render: (text, record, index) => (
        <Input
          size="small"
          className="w-full"
          variant={isBsKhongDuocKeToa ? 'filled' : 'outlined'}
          readOnly={isBsKhongDuocKeToa}
          value={text}
          onChange={(e) =>
            setListThuocBN((prev) =>
              prev?.map((item) =>
                item?.idthuoc === record?.idthuoc ? { ...item, ghichu: e.target.value } : item,
              ),
            )
          }
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 40,
      render: (text, record, index) =>
        !isBsKhongDuocKeToa && (
          <CloseOutlined
            className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
            onClick={() =>
              setListThuocBN((prev) => prev?.filter((item) => item?.idthuoc !== record?.idthuoc))
            }
          />
        ),
    },
  ]
  return (
    <div>
      <Divider orientation={'left'} plain style={{ marginBottom: 3 }}>
        <p className="font-semibold">Kê toa thuốc</p>
      </Divider>
      <div className="flex justify-start items-center gap-2 mb-2">
        <span className="font-semibold w-[6.7rem]">Ngày thuốc:</span>
        <span>
          <InputNumber
            className="w-14"
            // variant={isBsDuocKeToa ? 'outlined' : 'filled'}
            // readOnly={!isBsDuocKeToa}
            min={0}
            step={1}
            value={ngayThuoc}
            onChange={(value) => {
              setNgayThuoc(value > 0 ? value : 0)
              !isBsKhongDuocKeToa && tinhLaiSLListThuocBN(value > 0 ? value : 0)
              // setListThuocBN((prev) =>
              //   prev?.map((item) => ({
              //     ...item,
              //     soluong: tinhLaiSLThuoc(
              //       item?.dvt,
              //       value,
              //       item?.lieudung,
              //       item?.solanngay,
              //       item?.soluong,
              //       item?.ton,
              //     ),
              //   })),
              // )
            }}
          />
        </span>
        <span className="w-full">
          {/* <Input className="w-full" placeholder="Nhập tên thuốc..." disabled={isLoadingThuocTon} /> */}
          <Select
            // ref={selectRef}
            className="w-full"
            placeholder="Nhập tên thuốc..."
            showSearch
            allowClear
            onChange={handleChonThuocTon}
            filterOption={(input, option) => {
              const label = option?.label ?? ''
              const tenBietDuoc = option?.tenBietDuoc ?? ''
              const searchText = input.toLowerCase()
              return (
                label.toLowerCase().includes(searchText) ||
                tenBietDuoc.toLowerCase().includes(searchText)
              )
            }}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            disabled={isLoadingThuocTon}
            style={{ color: 'black' }}
            value={isLoadingThuocTon ? 'Đang lấy dữ liệu thuốc...' : null}
            options={listThuocTon?.map((items) => ({
              label: items?.thuocVatTu?.tenHoatChat,
              tenBietDuoc: items?.thuocVatTu?.tenBietDuoc,
              value: items?.idThuoc,
              ton: items?.tonKho,
              dvt: items?.thuocVatTu?.dvt,
              hanDung: items?.hanDung,
              idthuoc: items?.idThuoc,
              info: items,
            }))}
            optionRender={(options) => (
              <ul className="flex">
                <li className="w-4/6 ">
                  <p className="font-semibold w-full text-pretty">{options?.data?.label}</p>
                  <span className="text-xs w-full text-gray-500">
                    ({options?.data?.tenBietDuoc})
                  </span>
                </li>
                {/* <li className="w-1/6 border-x flex items-center justify-center text-center">
            {moment(options.data.hanDung).format('DD/MM/YYYY')}
          </li> */}
                <li className="w-1/6 border-x text-center flex items-center justify-center">
                  {options?.data?.ton}
                </li>
                <li className="w-1/6 text-center flex items-center justify-center">
                  {options?.data?.dvt}
                </li>
              </ul>
            )}
          />
        </span>
        <span>
          <Select
            className="w-52"
            placeholder="Chọn ngày đã khám..."
            showSearch
            allowClear
            disabled={isLoadingThuocTon}
            filterOption={(input, option) => {
              const label = option?.label ?? ''
              const ngayKham = option?.ngaykham ?? ''
              const searchText = input.toLowerCase()
              return (
                label.toLowerCase().includes(searchText) ||
                ngayKham.toLowerCase().includes(searchText)
              )
            }}
            value={null}
            onChange={handleLayToaCu}
            options={historyKham?.map((item, index) => ({
              key: index,
              value: index,
              label: item?.ngaykham ? moment(item?.ngaykham).format('DD/MM/YYYY HH:mm:ss') : '-',
              ngaykham: item?.ngaykham,
              arrThuoc: item?.danhSachThuocDTOs,
            }))}
          />
        </span>
      </div>
      <div className="flex justify-center items-center w-full">
        <ConfigProvider theme={{ token: { padding: 5, fontSize: 13 } }}>
          <Table
            bordered
            columns={columns}
            pagination={false}
            className="w-[99.85%]"
            // dataSource={[1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
            dataSource={listThuocBN}
            scroll={{ x: 1100, y: 312 }}
            rowClassName={(record) => (record?.ton > 0 ? '' : 'bg-yellow-200 bg-opacity-50')}
            footer={(pageData) => {
              let totalPrice = 0
              pageData?.forEach(({ giaban, soluong }) => {
                totalPrice += giaban * (!_.isNaN(soluong) ? soluong : 0)
              })
              return (
                <div className="flex justify-end">
                  <p className="font-semibold text-sm">
                    Tổng tiền:&nbsp;
                    <span className="text-blue-500">
                      {formatNumber(!_.isNaN(totalPrice) ? totalPrice : 0)} VNĐ
                    </span>
                  </p>
                </div>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default ToaThuoc
