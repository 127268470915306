import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button, Input } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import { SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import * as typeAction from '../../../../../store/constants/constants'
import moment from 'moment'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { getBranchByChuyenKhoa, getBranchNotVP } from '../../../../../store/actions/BranchAction'
import { branchService } from '../../../../../services/branch/branchService'
import { cardService } from '../../../../../services/card/cardService'
import { reportService } from '../../../../../services/Report/reportService'
import { exportExcelformatSumCol } from '../../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../../utils/formattedNumber'
// import { columns } from './Columns'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const MemberCard = ({ chuyenKhoa }) => {
  const dispatch = useDispatch()
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [value, setValue] = useState(['all'])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState({ form: now, to: now })
  const [dataReport, setDataReport] = useState([])
  const [caLamViec, setCaLamViec] = useState('all')
  const [search, setSearch] = useState('')
  const [listTitleTTV, setListTitleTTV] = useState([])

  const handleDatePicker = (name) => (date, dateString) => {
    const formatDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDate }))
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn công ty ',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = () => {
    // const formatData = dataReport?.map((item) => {
    // })
    const formatData = dataReport?.map((item) => {
      const formattedItem = {
        'Mã nhân viên': item?.manhanvien,
        'Tên nhân viên': item?.tennguoiban,
      }
      listTitleTTV?.forEach((itemLT) => {
        formattedItem[itemLT?.loaithe] = item?.[`ttV${itemLT?.idlt}`]
      })
      formattedItem[`Tổng thẻ`] = item?.tongcong
      return formattedItem
    })
    const name = `DOANH SỐ THẺ THÀNH VIÊN CHUYÊN KHOA ${(chuyenKhoa === 1 && 'NHI') || (chuyenKhoa === 2 && 'SẢN') || (chuyenKhoa === 3 && 'LÃO') || (chuyenKhoa === 4 && 'MẮT')}`
    if (formatData?.length)
      exportExcelformatSumCol(formatData, name, [
        ...listTitleTTV?.map((item) => item?.loaithe),
        'Tổng thẻ',
      ])
  }
  const fetchBranch = async (id) => {
    try {
      setIsLoading(true)
      const { data } = await branchService.getBranchNotVP(id)
      setBranch(data?.filter((item) => item?.idChuyenKhoa === chuyenKhoa))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListTieuDeTTV = async () => {
    try {
      const { data } = await cardService.listTypeCard(chuyenKhoa, '') //chuyên khoa, keyword
      setListTitleTTV(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchReport = async () => {
    try {
      setIsLoading(true)
      let data = []
      if (chuyenKhoa === 1) {
        const { data: dsNhi } = await reportService.getDoanhSoTTVNhi(
          search?.length ? search?.trim() : null,
          `${caLamViec}`?.includes('all') ? null : caLamViec,
          (value?.includes('all') ? branch : value)
            ?.map((item) => `idcn=${value?.includes('all') ? item?.idChiNhanh : item}`)
            ?.join('&')
            ?.toString(),
          // company,
          date?.form,
          date?.to,
        )
        data = dsNhi
      } else if (chuyenKhoa === 2) {
        const { data: dsSan } = await reportService.getDoanhSoTTVSan(
          search?.length ? search?.trim() : null,
          `${caLamViec}`?.includes('all') ? null : caLamViec,
          (value?.includes('all') ? branch : value)
            ?.map((item) => `idcn=${value?.includes('all') ? item?.idChiNhanh : item}`)
            ?.join('&')
            ?.toString(),
          // company,
          date?.form,
          date?.to,
        )
        data = dsSan
      } else if (chuyenKhoa === 3) {
        const { data: dsLao } = await reportService.getDoanhSoTTVLao(
          search?.length ? search?.trim() : null,
          `${caLamViec}`?.includes('all') ? null : caLamViec,
          (value?.includes('all') ? branch : value)
            ?.map((item) => `idcn=${value?.includes('all') ? item?.idChiNhanh : item}`)
            ?.join('&')
            ?.toString(),
          // company,
          date?.form,
          date?.to,
        )
        data = dsLao
      } else if (chuyenKhoa === 4) {
        const { data: dsMat } = await reportService.getDoanhSoTTVMat(
          search?.length ? search?.trim() : null,
          `${caLamViec}`?.includes('all') ? null : caLamViec,
          (value?.includes('all') ? branch : value)
            ?.map((item) => `idcn=${value?.includes('all') ? item?.idChiNhanh : item}`)
            ?.join('&')
            ?.toString(),
          // company,
          date?.form,
          date?.to,
        )
        data = dsMat
      }
      setDataReport(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const onLoad = () => {
    setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    fetchReport()
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
    getListTieuDeTTV()
    fetchReport()
  }, [])
  return (
    <>
      <div className="flex gap-3 border-dotted border-b-0 p-2">
        <div className="flex items-center gap-2 w-[16.5rem]">
          <DatePicker
            allowClear={false}
            value={dayjs(date.form)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            value={dayjs(date.to)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        {infoUser?.idNhom !== 3 && ( //không phải lễ tân thì cho chọn chi nhánh
          <>
            <div className="w-[17rem]">
              <Select
                showSearch
                value={company}
                onChange={(value) => {
                  setCompany(value)
                  fetchBranch(value)
                  setValue('all')
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={listCompany?.map(({ idct, tenct }) => ({
                  label: tenct,
                  value: idct,
                }))}
                className="w-full"
              />
            </div>
            <div className="w-[17rem] flex gap-2">
              <TreeSelect
                {...tProps}
                style={{ flexWrap: 'unset' }}
                className="w-full h-8 overflow-clip"
              />
            </div>
          </>
        )}
        <div className="w-[7.5rem]">
          <Select
            className="w-full"
            options={[
              { key: 'all', value: 'all', label: 'Tất cả các ca' },
              { key: 1, value: 1, label: 'Ca A' },
              { key: 2, value: 2, label: 'Ca B' },
              { key: 3, value: 3, label: 'Ca C' },
            ]}
            value={caLamViec}
            onChange={(value) => setCaLamViec(value)}
          />
        </div>
        <div className="flex w-[13rem]">
          <Input
            value={search}
            placeholder="Nhập Mã NV, Tên NV..."
            onChange={(e) => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="flex gap-3">
          <IconButton
            disabled={isLoading}
            color="primary"
            onClick={onLoad}
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <div className="flex gap-3 ml-auto">
          <Button
            disabled={isLoading || !dataReport?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            borderRadius: 0,
          },
        }}
      >
        <Table
          loading={isLoading}
          bordered
          scroll={{
            y: 535,
          }}
          dataSource={dataReport.concat(dataReport)}
          columns={[
            {
              title: 'Mã nhân viên',
              dataIndex: 'manhanvien',
              key: 'manhanvien',
              width: 100,
            },
            {
              title: 'Tên nhân viên',
              dataIndex: 'tennguoiban',
              key: 'tennguoiban',
              width: 300,
            },
            ...listTitleTTV?.map((item) => ({
              title: <p className="text-left text-[0.8rem]">{item?.loaithe}</p>,
              dataIndex: `ttV${item?.idlt}`,
              key: `ttV${item?.idlt}`,
              align: 'center',
            })),
            {
              title: 'Tổng thẻ',
              dataIndex: 'tongcong',
              key: 'tongcong',
              fixed: 'right',
              align: 'center',
              width: 75,
            },
          ]}
          pagination={false} // Disable pagination
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {/* Tổng thanh toán */}
                <Table.Summary.Cell index={0}>Tổng cộng:</Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center"></Table.Summary.Cell>
                {listTitleTTV?.map((item, idx) => (
                  <Table.Summary.Cell index={2 + idx} align="center">
                    {dataReport?.reduce(
                      (tong, itemSum) => (tong += itemSum?.[`ttV${item?.idlt}`] ?? 0),
                      0,
                    )}
                  </Table.Summary.Cell>
                ))}
                <Table.Summary.Cell index={(listTitleTTV?.length ?? 0) + 2} align="center">
                  {dataReport?.reduce((tong, itemSum) => (tong += itemSum?.tongcong ?? 0), 0)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </ConfigProvider>
    </>
  )
}

export default MemberCard
