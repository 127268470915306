import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Input,
  Table,
  ConfigProvider,
  Button,
  DatePicker,
  Modal,
  Drawer,
  Select,
  Checkbox,
  TreeSelect,
} from 'antd'
import { FileTextOutlined, SyncOutlined, DeleteOutlined } from '@ant-design/icons'
import { xuatNoiBoService } from '../../../../services/xuatNoiBo/xuatNoiBoService'
import { companyService } from '../../../../services/Company/companyService'
import { branchService } from '../../../../services/branch/branchService'
import { chuyenKhoService } from '../../../../services/ChuyenKho/chuyenKhoService'
import moment from 'moment'
import dayjs from 'dayjs'
import { formatNumber } from 'devextreme/localization'
import { generatePhieuXuatBanKhachHang } from '../../../../utils/report/phieuThuTienXuatBanKhachHang'
import { generatePhieuXuatBanNoiBo } from '../../../../utils/report/phieuThuTienXuatBanNoiBo'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD/MM/YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
function ListPhieu({ keyTabs }) {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)

  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [keyword, setKeyword] = useState(null)
  const [isModal, setIsModal] = useState(false)
  const [listPhieuXuat, setListPhieuXuat] = useState([])
  const [infoPhieuXuat, setInfoPhieuXuat] = useState(null)
  const [listVTHHCuaPhieuXuat, setListVTHHCuaPhieuXuat] = useState([])
  const [listCongTy, setListCongTy] = useState([])
  const [idCongTy, setIdCongTy] = useState(ID_COMPANY)
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [value, setValue] = useState([ID_BRANCH])
  const [listKhoCn, setListKhoCn] = useState([])
  const [idKhoCn, setIdKhoCn] = useState(0)

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: listChiNhanh
        // ?.filter((item) => !idCongTy || item?.idct === idCongTy)
        ?.map((item, index) => ({
          title: item?.tenChiNhanh,
          value: item?.idChiNhanh,
          key: `${item?.idChiNhanh}`,
        })),

      // ?.filter((item) => item?.loaiChiNhanh === 'CHINHANH')
      // ?.filter((item) => (company ? item?.idct === company : item)) // dư
      // ?.filter((item) => (filterCK ? item?.idChuyenKhoa === filterCK : item))
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const getListPhieuXuat = async () => {
    try {
      setIsLoading(true)
      const { data } = await xuatNoiBoService.getListPhieuXuat(
        keyword?.trim()?.length ? keyword?.trim() : null,
        idCongTy,
        value?.includes('all')
          ? listChiNhanh
              ?.map((item) => `idcn=${item?.idChiNhanh}`)
              ?.join('&')
              ?.toString()
          : value
              ?.map((item) => `idcn=${item}`)
              ?.join('&')
              ?.toString(),
        //value?.includes('all') ? listChiNhanh?.map((item) => item?.idChiNhanh) : value,
        dateForm,
        dateTo,
      )
      setListPhieuXuat(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getInfoPhieuXuatById = async (idPhieuXuat) => {
    try {
      setIsLoading(true)
      //const { data: info } = await xuatSuDungService.getInfoPhieuXuat(idPhieuXuat)
      const { data: list } = await xuatNoiBoService.getListVTHHCuaPhieuXuat(idPhieuXuat)
      // setInfoPhieuXuat(info)
      setListVTHHCuaPhieuXuat(list)
      // setListPhieuXuat(data)
    } catch (error) {
      console.log(error)
      setInfoPhieuXuat(null)
      setListVTHHCuaPhieuXuat([])
    } finally {
      setIsLoading(false)
    }
  }
  const getListChiNhanhByIdCty = async (idct) => {
    try {
      setIsLoading(true)
      const { data: arrChiNhanh } = await branchService.getListBranch(idct)
      setListChiNhanh(arrChiNhanh)
    } catch (error) {
      console.log(error)
      setListChiNhanh([])
    } finally {
      setIsLoading(false)
    }
  }
  const getListKhoCnByIdCn = async (idcn) => {
    try {
      setIsLoading(true)
      const { data: arrKhoCn } = await chuyenKhoService.getlistKhoNhan(idcn)
      setListKhoCn(arrKhoCn)
    } catch (error) {
      console.log(error)
      setListKhoCn([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        const { data: arrCongTy } = await companyService.getAllListCompany()
        setListCongTy(arrCongTy)
        getListChiNhanhByIdCty(idCongTy)
        // getListKhoCnByIdCn(idChiNhanh)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    if (keyTabs === 2) {
      getListPhieuXuat()
    }
  }, [keyTabs])

  const handlePrintKH = (infoPhieuXuat, listVTHHCuaPhieuXuat, PkDangNhap) => {
    generatePhieuXuatBanKhachHang(infoPhieuXuat, listVTHHCuaPhieuXuat, PkDangNhap)
  }

  const handlePrintNB = (infoPhieuXuat, listVTHHCuaPhieuXuat, PkDangNhap) => {
    generatePhieuXuatBanNoiBo(infoPhieuXuat, listVTHHCuaPhieuXuat, PkDangNhap)
  }

  return (
    <div>
      <Drawer
        placement="right"
        width={1000}
        open={isModal}
        onClose={() => setIsModal(false)}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Chi tiết phiếu xuất nội bộ</span>
            <div>
              <Button
                type="primary"
                onClick={() => handlePrintKH(infoPhieuXuat, listVTHHCuaPhieuXuat, PkDangNhap)}
                className="mr-4"
                size="small"
              >
                Phiếu khách hàng
              </Button>
              <Button
                type="primary"
                onClick={() => handlePrintNB(infoPhieuXuat, listVTHHCuaPhieuXuat, PkDangNhap)}
                size="small"
              >
                Phiếu nội bộ
              </Button>
            </div>
          </div>
        }
      >
        <div className="grid grid-flow-row items-center gap-2">
          <div className="grid grid-cols-2 items-center gap-2">
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Tên BN:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={infoPhieuXuat?.tenbenhnhan}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Mã phiếu:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={infoPhieuXuat?.maphieu}
              />
            </div>

            {/* <div className="grid grid-cols-8 items-center gap-1">
              <label className="font-semibold text-end">Mã phiếu:</label>
              <Input
                className="col-span-7"
                size="small"
                readOnly
                variant="filled"
                value={infoPhieuXuat?.maphieu}
              />
            </div> */}
          </div>
          <div className="grid grid-cols-8 items-center gap-1">
            <label className="font-semibold text-end">Địa chỉ:</label>
            <Input
              className="col-span-7"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.diachi}
            />
          </div>
          <div className="grid grid-cols-2 items-center gap-2">
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Người xuất:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={infoPhieuXuat?.tennguoixuat}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Ngày xuất:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={
                  infoPhieuXuat?.ngayxuat
                    ? moment(infoPhieuXuat?.ngayxuat).format('DD/MM/YYYY HH:mm:ss')
                    : '-'
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-2 items-center gap-2">
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Chi nhánh xuất:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={infoPhieuXuat?.tenchinhanh}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-1">
              <label className="font-semibold text-end">Kho xuất:</label>
              <Input
                className="col-span-3"
                size="small"
                readOnly
                variant="filled"
                value={infoPhieuXuat?.tenkho}
              />
            </div>
          </div>
          {/* <div className="grid grid-cols-8 items-center gap-1">
            <label className="font-semibold text-end">Kho xuất:</label>
            <Input
              className="col-span-7"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.tenkhoxuat}
            />
          </div> */}
          {/* <div className="grid grid-cols-8 items-center gap-1">
            <label className="font-semibold text-end">Nội dung:</label>
            <Input
              className="col-span-7"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.noidung}
            />
          </div> */}
          <div className="grid grid-cols-8 items-center gap-1">
            <label className="font-semibold text-end">Ghi chú:</label>
            <Input
              className="col-span-7"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.ghichu}
            />
          </div>
          <div>
            <ul className="flex flex-wrap gap-2">
              {infoPhieuXuat?.fileketqua?.split(';')?.map((url, index) => {
                return (
                  url?.length && (
                    <li
                      key={`hinh${index}:${url}`}
                      className="w-[8.1rem] h-[8.1rem] overflow-hidden border rounded-md relative"
                    >
                      {/* <DeleteOutlined
                    // onClick={() => deleteFileById(items.id)}
                    className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                    style={{ fontSize: 18 }}
                  /> */}
                      <a href={url} target="_blank">
                        <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                          <div className="relative "></div>
                          {/* <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                            {`${infoPhieuXuat?.maphieu}-Hinh${++index}`}
                          </p> */}
                        </div>
                        <img src={url} className="w-full" alt={url} />
                      </a>
                    </li>
                  )
                )
              })}
            </ul>
          </div>
          <div className="grid grid-flow-row items-center mb-1">
            <div className="grid grid-cols-3 items-center gap-32">
              <div className="grid grid-cols-2">
                <label className="font-semibold text-end">Tiền mặt:</label>
                <div className="text-end">{formatNumber(infoPhieuXuat?.tienmat ?? 0)}</div>
              </div>
              <div className="grid grid-cols-2">
                <label className="font-semibold text-end">Chuyển khoản:</label>
                <div className="text-end">{formatNumber(infoPhieuXuat?.chuyenkhoan ?? 0)}</div>
              </div>
              <div className="grid grid-cols-2">
                <label className="font-semibold text-end">Máy POS:</label>
                <div className="text-end">{formatNumber(infoPhieuXuat?.pos ?? 0)}</div>
              </div>
            </div>
            <div className="grid grid-cols-3 items-center gap-32">
              <div className="grid grid-cols-2">
                <label className="font-semibold text-end">Momo:</label>
                <div className="text-end">{formatNumber(infoPhieuXuat?.momo ?? 0)}</div>
              </div>
              <div className="grid grid-cols-2">
                <label className="font-semibold text-end">VNPay:</label>
                <div className="text-end">{formatNumber(infoPhieuXuat?.vnpay ?? 0)}</div>
              </div>
              <div className="grid grid-cols-2">
                <label className="font-semibold text-end">ZaloPay:</label>
                <div className="text-end">{formatNumber(infoPhieuXuat?.zalopay ?? 0)}</div>
              </div>
            </div>
            <div className="grid grid-cols-3 items-center gap-32">
              <div className="grid grid-cols-2">
                <label className="font-semibold text-end">Phương thức:</label>
                <div className="text-end">{infoPhieuXuat?.phuongthuc}</div>
              </div>
              <div className="grid grid-cols-2">
                <label className="font-semibold text-end">Xuất HĐ:</label>
                <div className="text-end">
                  <Checkbox checked={infoPhieuXuat?.idxuathoadon} />
                </div>
              </div>
              <div className="grid grid-cols-2"></div>
            </div>
          </div>
          <div className="w-[59.7rem]">
            <ConfigProvider theme={{ token: { padding: 2, fontSize: 12 } }}>
              <Table
                scroll={{ y: 350 }}
                pagination={false}
                loading={isLoading}
                bordered
                dataSource={listVTHHCuaPhieuXuat}
                columns={[
                  {
                    title: 'STT',
                    dataIndex: 'stt',
                    key: 'stt',
                    width: 35,
                    align: 'center',
                    fixed: 'left',
                    render: (text, record, index) => ++index,
                  },
                  {
                    title: 'Mã hàng',
                    dataIndex: 'mathuoc',
                    key: 'mathuoc',
                    width: 100,
                    fixed: 'left',
                  },
                  {
                    title: 'Tên hàng',
                    dataIndex: 'tenthuoc',
                    key: 'tenthuoc',
                    fixed: 'left',
                  },
                  {
                    title: 'Số lô',
                    dataIndex: 'solo',
                    key: 'solo',
                    width: 85,
                  },
                  {
                    title: 'Hạn Dùng',
                    dataIndex: 'handung',
                    key: 'handung',
                    align: 'center',
                    width: 75,
                    render: (text, record, index) => (text ? moment(text).format(dateFormat) : '-'),
                  },
                  {
                    title: 'Số hóa đơn',
                    dataIndex: 'sohoadon',
                    key: 'sohoadon',
                    width: 85,
                  },
                  {
                    title: 'ĐVT',
                    dataIndex: 'dvt',
                    key: 'dvt',
                    width: 40,
                    align: 'center',
                  },
                  // {
                  //   title: 'Mã số đăng ký',
                  //   dataIndex: 'masodangky',
                  //   key: 'masodangky',
                  //   width: 120,
                  // },
                  {
                    title: 'Đơn giá',
                    dataIndex: 'dongia',
                    key: 'dongia',
                    width: 75,
                    align: 'right',
                    render: (text, record, index) => (
                      <p className="pr-1">{formatNumber(text ?? 0)}</p>
                    ),
                  },
                  {
                    title: 'SL',
                    dataIndex: 'soluong',
                    key: 'soluong',
                    width: 30,
                    align: 'center',
                  },
                  {
                    title: 'Thành tiền',
                    dataIndex: 'thanhtien',
                    key: 'thanhtien',
                    width: 75,
                    align: 'right',
                    fixed: 'right',
                    render: (text, record, index) => (
                      <p className="pr-1">{formatNumber(text ?? 0)}</p>
                    ),
                  },
                  // {
                  //   title: 'Ghi chú',
                  //   dataIndex: 'ghichu',
                  //   key: 'ghichu',
                  //   width: 250,
                  // },
                  // {
                  //   title: '',
                  //   dataIndex: 'action',
                  //   key: 'action',
                  //   width: 40,
                  //   align: 'center',
                  //   fixed: 'right',
                  //   render: (text, record, index) => (
                  //     <>
                  //       {/* <CloseOutlined
                  //     className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                  //     onClick={() =>
                  //       setListXuatVTHH((prev) =>
                  //         prev?.filter((item) => item?.key !== record?.key),
                  //       )
                  //     }
                  //   /> */}
                  //     </>
                  //   ),
                  // },
                ]}
              />
            </ConfigProvider>
          </div>
          <div className="grid grid-flow-row items-center text-[1rem] text-end">
            <div className="grid grid-cols-12 items-center">
              <div className="col-span-10">Tổng tiền:</div>
              <div className="col-span-2">
                {formatNumber(infoPhieuXuat?.tienthanhtoan ?? 0)}&nbsp;VNĐ
              </div>
            </div>
            <div className="grid grid-cols-12 items-center">
              <div className="col-span-10">Tổng trả:</div>
              <div className="col-span-2">
                {formatNumber(infoPhieuXuat?.tienthanhtoan ?? 0)}&nbsp;VNĐ
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <div className="flex justify-start items-center mb-2 gap-2">
        <div className="flex justify-start items-center w-[17rem] gap-1">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-2xl flex items-center">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        {infoUser?.idNhom !== 3 && (
          <>
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              options={listCongTy?.map((item) => ({
                key: `${item?.mact}${item?.idct}`,
                value: item?.idct,
                label: item?.tenct,
              }))}
              value={idCongTy}
              onChange={(value) => {
                setIdCongTy(value)
                setValue(['all'])
                // setIdChiNhanh(0)
                getListChiNhanhByIdCty(value)
              }}
            />
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-[17rem] h-8 overflow-clip"
              size="medium"
            />
            {/* <Select
              disabled={isLoading}
              className="w-[17rem]"
              options={[
                {
                  key: `chinhanh0`,
                  value: 0,
                  label: 'Tất cả các chi nhánh',
                },
                ...listChiNhanh?.map((item) => ({
                  key: `chinhanh${item?.idChiNhanh}`,
                  value: item?.idChiNhanh,
                  label: item?.tenChiNhanh,
                })),
              ]}
              value={idChiNhanh}
              onChange={(value) => {
                setIdChiNhanh(value)
                setIdKhoCn(0)
                getListKhoCnByIdCn(value)
              }}
            /> */}
          </>
        )}
        {/* <Select
          disabled={isLoading}
          className="w-[17rem]"
          options={[
            {
              key: `khocn0`,
              value: 0,
              label: 'Tất cả các kho',
            },
            ...listKhoCn?.map((item) => ({
              key: `khocn${item?.idKhoCN}`,
              value: item?.idKhoCN,
              label: item?.tenKho,
            })),
          ]}
          value={idKhoCn}
          onChange={(value) => setIdKhoCn(value)}
        /> */}
        <Input
          placeholder="Tìm mã phiếu..."
          className="w-[17rem]"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button
          onClick={() => {
            setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
            getListPhieuXuat()
          }}
          loading={isLoading}
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        ></Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
        }}
      >
        <Table
          //   scroll={{ x: 1000 }}
          //   pagination={{
          //     pageSize: 20,
          //     showSizeChanger: false,
          //   }}
          scroll={{ x: 2100, y: 720 }}
          pagination={false}
          loading={isLoading}
          bordered
          dataSource={listPhieuXuat}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 'stt',
              width: 45,
              align: 'center',
              render: (text, record, index) => ++index,
            },
            {
              title: 'Ngày xuất',
              dataIndex: 'ngayxuat',
              key: 'ngayxuat',
              width: 140,
              align: 'center',
              render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
            },
            {
              title: 'Mã phiếu',
              dataIndex: 'maphieu',
              key: 'maphieu',
              width: 140,
            },
            {
              title: 'Mã BN',
              dataIndex: 'mabenhnhan',
              key: 'mabenhnhan',
              width: 150,
            },
            {
              title: 'Tên BN',
              dataIndex: 'tenbenhnhan',
              key: 'tenbenhnhan',
            },
            {
              title: 'Tên chi nhánh',
              dataIndex: 'tenchinhanh',
              key: 'tenchinhanh',
              width: 250,
            },
            {
              title: 'Kho xuất',
              dataIndex: 'tenkho',
              key: 'tenkho',
              width: 220,
            },
            {
              title: 'Người xuất',
              dataIndex: 'tennguoixuat',
              key: 'tennguoixuat',
              width: 220,
            },
            {
              title: 'Người sửa',
              dataIndex: 'tennguoisua',
              key: 'tennguoisua',
              width: 220,
            },
            {
              title: 'Ngày sửa',
              dataIndex: 'ngaysua',
              key: 'ngaysua',
              width: 140,
              align: 'center',
              render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
            },
            {
              title: 'Xuất HĐ',
              dataIndex: 'idxuathoadon',
              key: 'idxuathoadon',
              width: 65,
              align: 'center',
              render: (text) => <Checkbox checked={text} />,
            },
            {
              title: 'Thanh toán',
              dataIndex: 'tienthanhtoan',
              key: 'tienthanhtoan',
              width: 100,
              align: 'right',
              fixed: 'right',
              render: (text) => formatNumber(text ?? 0),
            },
            {
              title: '',
              dataIndex: 'action',
              key: 'action',
              width: 40,
              align: 'center',
              fixed: 'right',
              render: (text, record, index) => (
                <>
                  <FileTextOutlined
                    className="text-lg text-blue-500 cursor-pointer flex justify-center items-center"
                    onClick={() => {
                      setIsModal(true)
                      setInfoPhieuXuat(record)
                      getInfoPhieuXuatById(record?.idxnb)
                    }}
                  />
                </>
              ),
            },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default ListPhieu
