import { DeleteOutlined, PlusOutlined, FormOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Select, Skeleton, Table, Input, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { xetNghiemService } from '../../../../../../services/xetNghiem/xetNghiemService'
import { infoBNXetNghiem } from '../../../../../../store/actions/xetNghiemAction'
import { calculateAge } from '../../../../../../utils/calculateAge'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const FormKQXN = () => {
  const dispatch = useDispatch()
  const today = moment()
  const formatDateAPI = 'YYYY-MM-DD'
  const [dateForm, setDateForm] = useState(today.format(formatDateAPI))
  const [dateTo, setDateTo] = useState(today.format(formatDateAPI))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  // const { detailXN } = useSelector((state) => state.xetNghiemReducer)
  const [BNDone, setBNDone] = useState([])
  const [detailXN, setDetailXN] = useState([])
  //---------------- lấy danh sách đã hoàn thành xét nghiêm --------//
  const fetchBNDoneXn = async () => {
    try {
      const result = await xetNghiemService.getBNDoneXN(PkDangNhap?.idChiNhanh, dateForm, dateTo)
      setBNDone(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  // --------- onClick chi tiết bệnh nhân xét nghiệm --------//
  const onClickXemDetailXN = async (record) => {
    try {
      const result = await xetNghiemService.getDetailXN(record.idbnttc)
      setDetailXN(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const columnsDetailXN = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã XN',
      dataIndex: 'machidinh',
      key: 'machidinh',
      width: 150,
    },
    {
      title: 'Tên xét nghiệm',
      dataIndex: 'tenchidinh',
      key: 'tenchidinh',
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'dvt',
      key: 'dvt',
      width: 90,
    },
    {
      title: 'Kết quả',
      dataIndex: 'ketqua',
      key: 'ketqua',
      // width: 200,
      // render: (text, record, index) => (
      //     <Input onChange={handleValue(record.ID)} value={text} min={0} type="number" size="small" />
      // ),
    },
    {
      title: 'Chỉ số',
      dataIndex: 'chisobinhthuong',
      key: 'chisobinhthuong',
      width: 150,
      align: 'center',
    },
  ]
  const columnsListXN = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã BN',
      dataIndex: 'maBenhNhan',
      key: 'maBenhNhan',
      width: 120,
      align: 'center',
    },
    {
      title: 'Tên BN',
      dataIndex: 'tenBenhNhan',
      key: 'tenBenhNhan',
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaySinh',
      width: 100,
      align: 'center',
      key: 'ngaySinh',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
      render: (text, record, index) => (
        <ul className="flex justify-center">
          <li
            onClick={() => onClickXemDetailXN(record)}
            className="text-green-700 bg-green-200 cursor-pointer w-6 hover:text-white hover:bg-green-700 duration-200 rounded"
          >
            <FormOutlined style={{ fontSize: 16, fontWeight: 700 }} />
          </li>
        </ul>
      ),
    },
  ]
  useEffect(() => {
    // fetchBranchByCompany()
    // fetchBNPendingXN()
    fetchBNDoneXn()
  }, [])
  return (
    <>
      <div className="w-full mt-5">
        <Divider
          orientation="left"
          style={{
            margin: '10px 0',
          }}
        >
          <span className="">Danh sách kết quả</span>
        </Divider>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            bordered
            columns={columnsListXN}
            pagination={false}
            dataSource={BNDone}
            scroll={{ y: 170 }}
          />
        </ConfigProvider>
      </div>
      <div className="w-full mt-10">
        <Divider
          orientation="left"
          style={{
            margin: '10px 0',
          }}
        >
          <span className="">Thông tin kết quả</span>
        </Divider>
        <div className="flex">
          <div className="w-1/2">
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Tên BN:</label>
              <Input value={detailXN?.TENBENHNHAN} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Mã BN:</label>
              <Input value={detailXN?.MABENHNHAN} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Ngày sinh:</label>
              <Input
                value={detailXN?.NGAYSINH && moment(detailXN?.NGAYSINH).format('DD/MM/YYYY')}
                size="small"
                variant="filled"
                readOnly
              />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Giới tính:</label>
              <Input value={detailXN?.GIOITINH} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Tuổi:</label>
              <Input
                value={detailXN?.NGAYSINH ? calculateAge(detailXN?.NGAYSINH) : null}
                size="small"
                variant="filled"
                readOnly
              />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-40">Địa chỉ:</label>
              <Input value={detailXN?.DIACHI} size="small" variant="filled" readOnly />
            </div>
          </div>
          <div className="w-1/2 pl-4">
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">Ngày KQ:</label>
              <Input
                value={
                  detailXN?.DanhSachXetNghiem &&
                  detailXN?.DanhSachXetNghiem[0]?.ngayketqua &&
                  moment(detailXN?.DanhSachXetNghiem[0]?.ngayketqua).format('DD/MM/YYYY HH:mm:ss')
                }
                size="small"
                variant="filled"
                readOnly
              />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">BS Chỉ định:</label>
              <Input value={detailXN?.BSCHIDINH} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">Chẩn đoán:</label>
              <Input value={detailXN?.CHUANDOAN} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">Đơn vị lấy:</label>
              <Input value={detailXN?.DONVILAY} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">Lấy mẫu:</label>
              <Input value={detailXN?.LAYMAU} size="small" variant="filled" readOnly />
            </div>
            <div className="flex justify-start mb-2">
              <label className="font-semibold w-36">Kết quả:</label>
              <Input value={detailXN?.KETQUA} size="small" variant="filled" readOnly />
            </div>
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            className="mt-5"
            bordered
            columns={columnsDetailXN}
            pagination={false}
            dataSource={detailXN?.DanhSachXetNghiem}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default FormKQXN
