import React, { useEffect, useState, useRef, useCallback } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import { ConfigProvider, Pagination, Table, Button, Input, Select, Tooltip, message } from 'antd'
import {
  SearchOutlined,
  PlusCircleOutlined,
  SyncOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import GetAppIcon from '@mui/icons-material/GetApp'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../services/apiService'
import fakerData from './fakeData.json'
import { nhanvienService } from '../../../services/nhanvien/nhanvienService'
import moment from 'moment'

import _ from 'lodash'
import 'moment/locale/vi'
import AddIP from './Modal/AddIP'
import EditKey from './Modal/EditKey'
moment.locale('vi')
const { Search } = Input
const CapKey = () => {
  const dispatch = useDispatch()
  const { stafs, PkDangNhap } = useSelector((state) => state.branchReducer)
  const [listNV, setListNV] = useState([])
  // State tìm kiếm, phân trang
  const [searchKeyword, setSearchKeyWord] = useState(null) // lưu trữ từ khóa tìm kiếm.
  const [currentPage, setCurrentPage] = useState(1) // lưu trữ trang all DSNV.
  const [pageSize, setPageSize] = useState(20) // Số lượng hiển thị trên mỗi trang.
  const [nextPage, setNextPage] = useState(1) // lưu trữ DSNV by CN
  // ============
  const [companies, setCompanies] = useState([]) // danh sách côngty
  const [branch, setBranch] = useState([]) // chi nhánh thuộc côngty
  const [idCT, setIdCT] = useState(PkDangNhap?.idCongTy ?? null) // ID của công ty được chọn.
  const [idCN, setIdCN] = useState(null) // ID chi nhánh
  const [positionData, setPositionData] = useState()
  // State modal

  const [loading, setLoading] = useState(false)
  const { Option } = Select

  const [isOpenAddIP, setIsOpenAddIP] = useState(false)
  const [isOpenEditIP, setIsOpenEditIP] = useState(false)
  const debounceSearchIP = useCallback(
    _.debounce((e) => {
      let kw = e?.target?.value ? e?.target?.value?.trim() : null
      if (kw?.length > 0) {
        setSearchKeyWord(kw)
      } else setSearchKeyWord(null)
    }, 300),
    [],
  )
  const getListNhanVien = async () => {
    try {
      setLoading(true)
      const result = await nhanvienService.getSearchNhanVien(
        searchKeyword?.trim(),
        idCN,
        idCT,
        currentPage,
      )
      setListNV(result.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getListNhanVien()
  }, [searchKeyword, idCN, idCT, currentPage])

  // Lấy dữ liệu từ API
  const fetchData = async (url, params) => {
    try {
      setLoading(true)
      const result = await https.get(url, { params })
      setLoading(false)
      return result.data
    } catch (err) {
      setLoading(false)
      console.error(err)
      message.error('Không thể lấy dữ liệu từ API')
      return null
    }
  }
  const fetchCompanies = async () => {
    try {
      const data = await fetchData('CongTy')
      if (data) {
        const mappedCompanies = data.map((company) => ({
          id: company.idct,
          name: company.tenct,
        }))
        setCompanies(mappedCompanies)
        setIdCT(1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCompanies()
  }, [])

  async function Branch(idCT) {
    try {
      const result = await https.get(`ChiNhanh`, {
        params: { idCT },
      })
      setBranch(result.data)
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    if (idCT) {
      Branch(idCT)
    }
  }, [idCT])

  useEffect(() => {}, [idCN, nextPage])

  const handleCompany = (value) => {
    setIdCT(value)
    setIdCN(null)
  }

  const handleEditRecord = (record) => {
    setIsOpenEditIP(true)
  }
  // Xử lý khi chọn chi nhánh
  const handleBranch = (value) => {
    if (value === 'all') {
      setIdCN(null)
    } else {
      setIdCN(value)
    }
    setCurrentPage(1)
    setNextPage(1)
  }

  // Đổi trang
  const onChangePage = (page) => {
    setCurrentPage(page)
  }
  const handleAddIP = () => {
    setIsOpenAddIP(true)
  }

  const cancelAddIPModal = () => {
    setIsOpenAddIP(false)
  }

  const cancelEditIPModal = () => {
    setIsOpenEditIP(false)
  }

  const onReload = () => {
    setCurrentPage(1)
    setSearchKeyWord(null)
    setIdCN(PkDangNhap?.idChiNhanh ?? null)
    setIdCT(PkDangNhap?.idCongTy ?? null)
  }
  // Hàm lấy chức danh
  const fetchPosition = async () => {
    try {
      const result = await nhanvienService.getTitle()
      setPositionData(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchPosition()
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      align: 'center',
      width: 45,
      render: (text, record, index) => {
        return (currentPage - 1) * pageSize + index + 1
      },
      fixed: 'left',
    },
    {
      title: 'Key',
      dataIndex: 'IpWan',
      key: 'IpWan',
      width: 90,
      fixed: 'left',
    },
    {
      title: 'Công ty',
      dataIndex: 'CongTy',
      key: 'CongTy',
      width: 90,
      // fixed: 'left',
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'ChiNhanh',
      key: 'ChiNhanh',
      width: 90,
      // fixed: 'left',
    },
    {
      title: 'Người tạo',
      dataIndex: 'NguoiTao',
      key: 'NguoiTao',
      align: 'center',
      width: 100,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'NgayTao',
      key: 'NgayTao',
      align: 'center',
      width: 90,
      // Kiểm tra nếu giá trị ngày sinh hợp lệ
      render: (NgayTao) => (NgayTao ? moment(NgayTao).format('DD/MM/YYYY') : null),
    },
    {
      title: 'Người sửa',
      dataIndex: 'NguoiSua',
      key: 'NguoiSua',

      width: 100,
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'NguoiSua',
      key: 'NgaySua',
      width: 90,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'TrangThai',
      key: 'TrangThai',
      align: 'center',
      fixed: 'right',
      width: 60,
      render: (value, record) => {
        let statusText = ''
        let className = ''

        switch (value) {
          case 0:
            statusText = 'Đang chờ'
            className = 'text-yellow-500'
            break
          case 1:
            statusText = 'Kích hoạt'
            className = 'text-green-500'
            break
          case 2:
            statusText = 'Ngưng hoạt động'
            className = 'text-red-500'
            break
          default:
            statusText = 'Unknown'
            className = 'text-gray-500'
            break
        }

        return (
          <div>
            <span className={className}>{statusText}</span>
          </div>
        )
      },
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'center',
      width: 45,
      fixed: 'right',
      render: (text, record) => (
        <div className="flex justify-around items-center">
          <Tooltip
            title="Sửa thông tin IP"
            color="#52c41a"
            overlayInnerStyle={{ fontSize: '13px' }}
          >
            <EditOutlined
              style={{ color: '#52c41a', fontSize: '18px', cursor: 'pointer' }}
              onClick={() => handleEditRecord(record)}
            />
          </Tooltip>
          <Tooltip
            title="Xoá thông tin IP"
            color="#ff4d4f"
            overlayInnerStyle={{ fontSize: '13px' }}
          >
            <DeleteOutlined style={{ color: '#ff4d4f', fontSize: '18px', cursor: 'pointer' }} />
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="h-full p-3 bg-[#EFEFEF]">
        <div className="bg-white rounded-xl p-2">
          <div className="flex flex-col md:flex-row justify-between mb-4 gap-4">
            <div className="flex flex-col md:flex-row gap-2" style={{ flex: 2 }}>
              <Select
                style={{ width: '100%', maxWidth: '260px' }}
                name="tenct"
                placeholder="Chọn công ty"
                onChange={handleCompany}
                value={idCT}
              >
                {companies.map((company) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </Select>
              <Select
                style={{ width: '100%', maxWidth: '250px' }}
                name="tenchinhanh"
                placeholder="Chọn chi nhánh"
                showSearch
                onChange={handleBranch}
                value={idCN || 'all'}
                options={[
                  { label: 'Tất cả', value: 'all' },
                  ...(branch?.map((branch) => ({
                    label: branch.tenChiNhanh,
                    value: branch.idChiNhanh,
                  })) || []),
                ]}
                filterOption={(input, option) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <Input
                placeholder="Tìm kiếm Key"
                prefix={<SearchOutlined className="pr-1" />}
                className="w-[250px]"
                // value={searchKeyword}
                onChange={debounceSearchIP}
                // onSearch={debounceSearchIP}
                //style={{ width: '100%', maxWidth: '250px' }}
              />
            </div>
            <div className="flex flex-col md:flex-row gap-2">
              <Button
                type="primary"
                className="rounded-lg bg-blue-500 hover:bg-blue-600"
                icon={<PlusCircleOutlined />}
                onClick={handleAddIP}
              >
                <span>Thêm Key </span>
              </Button>
              <Button type="primary" icon={<SyncOutlined />} onClick={onReload}>
                Tải lại
              </Button>
            </div>
          </div>

          <ConfigProvider
            renderEmpty={() => <div>Không tìm thấy IP</div>}
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              rowKey="id"
              scroll={{
                x: 2000,
              }}
              pagination={false}
              columns={columns}
              loading={loading}
              dataSource={fakerData?.map((items, index) => ({
                ...items,
                key: index,
              }))}
            />
            <div className="rounded-md mt-2 flex justify-end items-center">
              {/* <Pagination
                onChange={onChangePage}
                showSizeChanger={false}
                current={currentPage}
                pageSize={20}
                total={(listNV?.totalPages ?? 1) * 20}
              /> */}
            </div>
          </ConfigProvider>
        </div>
      </div>
      {isOpenAddIP && (
        <AddIP
          visible={isOpenAddIP}
          setVisible={setIsOpenAddIP}
          cancelAddModal={cancelAddIPModal}
          companies={companies}
          branch={branch}
          positionData={positionData}
        />
      )}
      {isOpenEditIP && (
        <EditKey
          visible={isOpenEditIP}
          setVisible={setIsOpenEditIP}
          cancelEditModal={cancelEditIPModal}
          companies={companies}
          branch={branch}
          positionData={positionData}
        />
      )}
    </>
  )
}

export default CapKey
