import React from 'react'
import NoiSoi from './NoiSoi'

const NoiSoiTH = () => {
  return (
    <div>
      <NoiSoi tenNoiSoi="noisoith" />
    </div>
  )
}

export default NoiSoiTH
