import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button, Input, Space } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import Highlighter from 'react-highlight-words'
import dayjs from 'dayjs'
import moment from 'moment'
import * as typeAction from '../../../../store/constants/constants'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { getBranchNotVP } from '../../../../store/actions/BranchAction'
import { reportService } from '../../../../services/Report/reportService'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const ThuocKhongLay = () => {
  const dispatch = useDispatch()
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const [company, setCompany] = useState(id_company)
  const [date, setDate] = useState({ from: now, to: now })
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState([BranchLogin])
  const [valueExport, setValueExport] = useState([])
  const [Phieu, setPhieu] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const fetchReport = async () => {
    try {
      openLoading()
      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanhs = value.map((id) => `&idcn=${id}`).join('&')
        const { data } = await reportService.getDanhSachThuocKhongLay(
          company,
          idChuyenKhoa,
          idChiNhanhs,
          date.from,
          date.to,
        )
        setPhieu(data)
      } else {
        const { data } = await reportService.getDanhSachThuocKhongLay(
          company,
          idChuyenKhoa,
          '',
          date.from,
          date.to,
        )
        setPhieu(data)
      }
      closeLoading()
    } catch (error) {
      console.log(error)
      closeLoading()
    } finally {
      closeLoading()
    }
  }
  const openLoading = () => {
    dispatch({
      type: typeAction.OPEN_LOADING_TABLE,
    })
  }
  const closeLoading = () => {
    dispatch({
      type: typeAction.CLOSE_LOADING_TABLE,
    })
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDate }))
  }
  const onChangeCompany = (value) => {
    setCompany(value)
    setValue([])
    setIdChuyenKhoa('')
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex]
      return recordValue
        ? recordValue.toString().toLowerCase().includes(value.toLowerCase())
        : false // Handle cases where the value is null or undefined
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn công ty ',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = () => {
    const formatData = valueExport?.map((item) => ({
      'Ngày khám': item?.ngaykham ? moment(item?.ngaykham).format('DD/MM/YYYY HH:mm:ss') : '-',
      'Tên chi nhánh': item?.tenchinhanh,
      'Dược sĩ': item?.duocsi,
      'Mã bệnh nhân': item?.mabenhnhan,
      'Tên bệnh nhân': item?.tenbenhnhan,
      'Bác sĩ kê': item.bskedon,
      'Tên biệt dược': item.tenbietduoc,
      'Tên hoạt chất': item.tenhoatchat,
      'Quy cách': item?.quycach,
      'Số lô': item?.solo,
      'Đơn giá': formattedNumber(item.dongia),
      'Hạn dùng': item?.handung ? moment(item?.handung).format('DD/MM/YYYY') : '-',
      'Số hoá đơn': item?.sohoadon,
    }))
    const name = 'Danh sách thuốc không lấy'
    exportExcelformat(formatData, name)
  }
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  useEffect(() => {
    dispatch(getBranchNotVP(company, setBranch))
  }, [company])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchReport()
    fetchChuyenKhoa()
  }, [])
  useEffect(() => {
    setValueExport(Phieu)
  }, [Phieu])
  return (
    <div className="p-3 bg-[#EFEFEF] h-full">
      <div className="p-2 rounded-lg border bg-white h-full">
        <div className="flex gap-3 border-dotted border-b-0  p-2 ">
          {infoUser?.idNhom !== 4 ? (
            <Select
              showSearch
              value={company}
              onChange={onChangeCompany}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-80"
            />
          ) : (
            ''
          )}
          {infoUser?.idNhom !== 4 ? (
            <Select
              showSearch
              value={idChuyenKhoa}
              onChange={onChangeChuyenKhoa}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả', value: '' },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
              className="w-80"
            />
          ) : (
            ''
          )}
          {infoUser?.idNhom !== 4 ? (
            <div className="w-80">
              <TreeSelect
                {...tProps}
                style={{ flexWrap: 'unset' }}
                className="w-full h-8 overflow-clip"
              />
            </div>
          ) : (
            ''
          )}

          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              value={dayjs(date.from)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('from')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              value={dayjs(date.to)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>

          <div className="flex gap-3">
            <IconButton onClick={fetchReport} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="flex gap-3 ml-auto">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              borderRadius: 0,
            },
          }}
        >
          <Table
            onChange={onChangeTable}
            dataSource={Phieu}
            loading={isLoadingTable}
            bordered
            pagination={false}
            scroll={{
              x: 1500,
              y: 700,
            }}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 60,
                render: (text, _, index) => ++index,
                align: 'center',
              },
              {
                title: 'Ngày',
                dataIndex: 'ngaykham',
                key: 'ngaykham',
                width: 150,
                render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
              },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenchinhanh',
                key: 'tenchinhanh',
                width: 250,
                ...getColumnSearchProps('tenchinhanh'),
              },
              {
                title: 'Tên dược sĩ',
                dataIndex: 'duocsi',
                key: 'duocsi',
                width: 250,
                ...getColumnSearchProps('duocsi'),
              },
              {
                title: 'Mã bệnh nhân',
                dataIndex: 'mabenhnhan',
                key: 'mabenhnhan',
                width: 150,
                ...getColumnSearchProps('mabenhnhan'),
              },
              {
                title: 'Tên bệnh nhân',
                dataIndex: 'tenbenhnhan',
                key: 'tenbenhnhan',
                width: 250,
                ...getColumnSearchProps('tenbenhnhan'),
              },
              {
                title: 'Bác sĩ kê',
                dataIndex: 'bskedon',
                key: 'bskedon',
                width: 250,
                ...getColumnSearchProps('bskedon'),
              },
              {
                title: 'Tên biệt dược',
                dataIndex: 'tenbietduoc',
                key: 'tenbietduoc',
                width: 350,
                ...getColumnSearchProps('tenbietduoc'),
              },
              {
                title: 'tên hoạt chất',
                dataIndex: 'tenhoatchat',
                key: 'tenhoatchat',
                width: 450,
                ...getColumnSearchProps('tenhoatchat'),
              },
              {
                title: 'Quy cách',
                dataIndex: 'quycach',
                key: 'quycach',
                width: 150,
                ...getColumnSearchProps('quycach'),
              },
              {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                width: 100,
                align: 'right',
                render: (text) => formattedNumber(text ?? 0),
              },
              {
                title: 'Số lô',
                dataIndex: 'solo',
                key: 'solo',
                width: 100,
                ...getColumnSearchProps('solo'),
              },
              {
                title: 'Hạn dùng',
                dataIndex: 'handung',
                key: 'handung',
                width: 100,
                render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
              },
              {
                title: 'Số hoá đơn',
                dataIndex: 'sohoadon',
                key: 'sohoadon',
                width: 150,
                ...getColumnSearchProps('sohoadon'),
              },
            ]}
            summary={(pageData) => {
              const total = pageData?.length
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={2}>
                      <p className="font-semibold"> Tổng thuốc: {total}</p>
                    </Table.Summary.Cell>{' '}
                    <Table.Summary.Cell colSpan={11}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default ThuocKhongLay
