import { SearchOutlined } from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { IconButton } from '@mui/material'
import { Button, ConfigProvider, DatePicker, Input, Select, Space, Table, TreeSelect } from 'antd'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../services/apiService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { calculateAgeForReport } from '../../../utils/calculateAge'
import * as XLSX from 'xlsx'
import ExcelJS from 'exceljs'
import 'moment/locale/vi'
moment.locale('vi')

const { SHOW_PARENT } = TreeSelect

const SoKhamBenh = () => {
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [chiNhanh, setChiNhanh] = useState(+localStorage.getItem('BRANH_LOGIN'))
  const [dateFrom, setDateFrom] = useState(now.format(dateFormat))
  const [dateTo, setDateTo] = useState(now.format(dateFormat))
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [listBN, setListBN] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [loadingExport, setLoadingExport] = useState(false)
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const [value, setValue] = useState([BranchLogin])
  const [filteredData, setFilteredData] = useState([])
  const [listColumnFilter, setListColumnFilter] = useState([])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    getAllCN_By_idct(company)
    getListBn('', dateFrom, dateTo, company, value, 1)
  }, [])

  const getAllCN_By_idct = async (value) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetAllByIdCTNotVanPhong?idCT=${value}`)
      setListChiNhanh(data?.filter((item) => !item?.idChuyenKhoa !== 2)) //bỏ những chi nhánh chuyên khoa sản chỉ lấy những chi nhánh nhi, mắt, lão
    } catch (error) {
      console.log(error)
    }
  }

  const handleCompany = (value) => {
    setCompany(value)
    setValue([])
    getAllCN_By_idct(value)
    setListBN([])
  }

  const exportToExcel = async (filteredData, dateFrom, dateTo) => {
    setLoadingExport(true)
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('SoKhamBenh')

    // Group data by ngaykham
    const groupedData = filteredData.reduce((groups, item) => {
      const dateKey = new Date(item.ngaykham).toLocaleDateString('vi-VN') // dd/MM/yyyy
      if (!groups[dateKey]) groups[dateKey] = []
      groups[dateKey].push(item)
      return groups
    }, {})

    // Border for the first row

    worksheet.columns = [
      { width: 10 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 30 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 20 },
      { width: 25 },
      { width: 25 },
      { width: 20 },
      // { header: 'Chi nhánh', key: 'branch', width: 20 },
    ]

    let startingRow = 2

    Object.keys(groupedData).forEach((groupKey, tableCount) => {
      const group = groupedData[groupKey]

      // Add table title
      worksheet.mergeCells(`A${startingRow}:M${startingRow}`)
      worksheet.getCell(`A${startingRow}`).value = `NGÀY ${groupKey}`
      worksheet.getCell(`A${startingRow}`).font = { bold: true }
      worksheet.getCell(`A${startingRow}`).alignment = { horizontal: 'center' }

      worksheet.getRow(startingRow).eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        }
      })

      startingRow++
      // Create header for each table
      worksheet.mergeCells(`A${startingRow}:A${startingRow + 1}`) // STT
      worksheet.getCell(`A${startingRow}`).value = 'STT'

      worksheet.mergeCells(`B${startingRow}:B${startingRow + 1}`) // Họ và tên
      worksheet.getCell(`B${startingRow}`).value = 'Họ và tên'

      worksheet.mergeCells(`C${startingRow}:D${startingRow}`) // Tuổi
      worksheet.getCell(`C${startingRow}`).value = 'Tuổi'
      worksheet.getCell(`C${startingRow + 1}`).value = 'Nam'
      worksheet.getCell(`D${startingRow + 1}`).value = 'Nữ'

      worksheet.mergeCells(`E${startingRow}:E${startingRow + 1}`) // Số thẻ BHYT
      worksheet.getCell(`E${startingRow}`).value = 'Số thẻ BHYT'

      worksheet.mergeCells(`F${startingRow}:F${startingRow + 1}`) // Địa Chỉ
      worksheet.getCell(`F${startingRow}`).value = 'Địa Chỉ'

      worksheet.mergeCells(`G${startingRow}:G${startingRow + 1}`) // Nghề Nghiệp
      worksheet.getCell(`G${startingRow}`).value = 'Nghề Nghiệp'

      worksheet.mergeCells(`H${startingRow}:H${startingRow + 1}`) // Dân Tộc
      worksheet.getCell(`H${startingRow}`).value = 'Dân Tộc'

      worksheet.mergeCells(`I${startingRow}:I${startingRow + 1}`) // Triệu Chứng
      worksheet.getCell(`I${startingRow}`).value = 'Triệu Chứng'

      worksheet.mergeCells(`J${startingRow}:J${startingRow + 1}`) // Chẩn Đoán
      worksheet.getCell(`J${startingRow}`).value = 'Chẩn Đoán'

      worksheet.mergeCells(`K${startingRow}:K${startingRow + 1}`) // Phương pháp điều trị
      worksheet.getCell(`K${startingRow}`).value = 'Phương pháp điều trị'

      worksheet.mergeCells(`L${startingRow}:L${startingRow + 1}`) // Y,BS khám bệnh
      worksheet.getCell(`L${startingRow}`).value = 'Y,BS khám bệnh'

      worksheet.mergeCells(`M${startingRow}:M${startingRow + 1}`) // Ghi Chú
      worksheet.getCell(`M${startingRow}`).value = 'Ghi Chú'

      // worksheet.mergeCells(`N${startingRow}:N${startingRow + 1}`) // Chi nhánh
      // worksheet.getCell(`N${startingRow}`).value = 'Chi nhánh'

      worksheet.getRow(startingRow).font = { bold: true }
      worksheet.getRow(startingRow + 1).font = { bold: true }

      // header row
      worksheet.getRow(startingRow).eachCell((cell) => {
        cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        }
      })
      //subheader
      worksheet.getRow(startingRow + 1).eachCell((cell) => {
        cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        }
      })

      startingRow += 2

      let row = startingRow
      let stt = 1

      group.forEach((item) => {
        const chandoan = `${item.chandoanbandau ?? ''}-${item.chandoan ?? ''}-${item.chandoanravien ?? ''}`
        const ppdieutri = item.danhSachThuocDTOs
          .map((thuoc) => `${thuoc.tenbietduoc} (${thuoc.soluong} ${thuoc.dvt})`)
          .join(', ') // Phương pháp điều trị
        worksheet.getCell(`A${row}`).value = stt++
        worksheet.getCell(`B${row}`).value = item.tenbenhnhan ?? ''
        worksheet.getCell(`C${row}`).value =
          item.gioitinh === 'Nam' ? calculateAgeForReport(item.ngaysinh) : ''
        worksheet.getCell(`D${row}`).value =
          item.gioitinh === 'Nữ' ? calculateAgeForReport(item.ngaysinh) : ''
        worksheet.getCell(`E${row}`).value = '' // Add Số thẻ BHYT if needed
        worksheet.getCell(`F${row}`).value =
          `${item.diachi ?? ''}, ${item.tenPhuongXa ?? ''}, ${item.tenQuanHuyen ?? ''}, ${item.tenTinhTP ?? ''}`
        worksheet.getCell(`G${row}`).value = item.tenNgheNghiep ?? ''
        worksheet.getCell(`H${row}`).value = item.tenDanToc ?? ''
        worksheet.getCell(`I${row}`).value = item.trieuchung ?? ''
        worksheet.getCell(`J${row}`).value = chandoan
        worksheet.getCell(`K${row}`).value = ppdieutri
        worksheet.getCell(`L${row}`).value = item.bskham ?? ''
        worksheet.getCell(`M${row}`).value = item.ghichu ?? ''
        // worksheet.getCell(`N${row}`).value = item.tenChiNhanh ?? ''

        // style for body
        worksheet.getRow(row).eachCell((cell) => {
          cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
          }
        })

        row++
      })

      startingRow = row
    })

    // Merge the first row and center the text
    worksheet.getCell('A1').value = 'SỔ KHÁM BỆNH'
    worksheet.mergeCells('A1:M1')
    worksheet.getCell('A1').alignment = { horizontal: 'center' }
    worksheet.getCell('A1').font = { bold: true }

    //download by blob
    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer()
    // Create a Blob from the buffer
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    const formatDateFrom = moment(dateFrom, 'DD-MM-YYYY').format('DD-MM-YYYY')
    const formatDateTo = moment(dateTo, 'DD-MM-YYYY').format('DD-MM-YYYY')

    link.download = `Sổ khám bệnh từ ${formatDateFrom} đến ${formatDateTo}.xlsx`
    link.click()
    setLoadingExport(false)
    // Clean up
    URL.revokeObjectURL(link.href)
  }

  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const categoriesChiNhanh = useMemo(() => {
    return {
      listCNNhi: listChiNhanh?.filter((branch) => branch.idChuyenKhoa === 1),
      listCNSan: listChiNhanh?.filter((branch) => branch.idChuyenKhoa === 2),
      listCNLao: listChiNhanh?.filter((branch) => branch.idChuyenKhoa === 3),
      listCNMat: listChiNhanh?.filter((branch) => branch.idChuyenKhoa === 4),

    }
  }, [listChiNhanh])

  const getListBn = async (keyword = '', dateFrom, dateTo, company, value, pageNumber = 1) => {
    setLoading(true)
    try {
      let idChiNhanhs = null
      const dateFromFormat = moment(dateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD')
      const dateToFormat = moment(dateTo, 'DD/MM/YYYY').format('YYYY-MM-DD')
      let cloneValue = cloneDeep(value)
      if (cloneValue?.length > 0 && cloneValue[0] !== 'all') {
        if (cloneValue.includes('nhi')) {
          const listIdCNNhi = categoriesChiNhanh.listCNNhi.map((item) => item.idChiNhanh)
          cloneValue = cloneValue.filter((val) => val !== 'nhi').concat(listIdCNNhi)
        }
        if (cloneValue.includes('lao')) {
          const listIdCNLao = categoriesChiNhanh.listCNLao.map((item) => item.idChiNhanh)
          cloneValue = cloneValue.filter((val) => val !== 'lao').concat(listIdCNLao)
        }
        if (cloneValue.includes('mat')) {
          const listIdCNMat = categoriesChiNhanh.listCNMat.map((item) => item.idChiNhanh)
          cloneValue = cloneValue.filter((val) => val !== 'mat').concat(listIdCNMat)
        }
        idChiNhanhs = cloneValue
          .map((id, idx) => {
            if (idx === 0) {
              return `?idChiNhanh=${id}`
            }
            return `&idChiNhanh=${id}`
          })
          .join('&')
        const result = await https.get(
          `BenhNhan/GetSoKhamBenhByCondition${idChiNhanhs}&idCongTy=${company}&fromDate=${dateFromFormat}&toDate=${dateToFormat}${keyword ? `&keyword=${keyword}` : ''}&pageNumber=${pageNumber}`,
        )
        setListBN(result.data)
      } else {
        const result = await https.get(
          `BenhNhan/GetSoKhamBenhByCondition?idCongTy=${company}&fromDate=${dateFromFormat}&toDate=${dateToFormat}${keyword ? `&keyword=${keyword}` : ''}&pageNumber=${pageNumber}`,
        )
        setListBN(result.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleCLickSearch = (search, dateFrom, dateTo, company, value) => {
    //reset
    setListBN([])
    getListBn(search, dateFrom, dateTo, company, value)
  }

  const handleSearch = (selectedKeys, confirm, dataIndices) => {
    if (!dataIndices) return //khong search ''
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndices.join(','))
    const idx = listColumnFilter.findIndex((item) => item === dataIndices[0])
    if (idx === -1) {
      setListColumnFilter((prev) => [...prev, { [dataIndices[0]]: selectedKeys[0] }])
    }
  }

  const handleReset = (clearFilters, confirm, dataIndices) => {
    clearFilters()
    setSearchText('')
    setSearchedColumn('')
    confirm()
    setListColumnFilter((prev) => prev.filter((item) => !item.hasOwnProperty(dataIndices[0])))
  }

  const getColumnSearchProps = (...dataIndices) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndices)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndices)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndices)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const combinedValue = dataIndices
        .map((index) => (record[index] ? record[index].toString() : ''))
        .join(' - ')
        .toLowerCase()
      return combinedValue.includes(value.toLowerCase())
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text, record) => {
      const combinedText = dataIndices
        .map((index) => (record[index] ? ` - ${record[index]}` : ''))
        .join('')
        .substring(3) // Remove the leading " - "

      if (searchedColumn === dataIndices.join(',')) {
        return (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={combinedText}
          />
        )
      }
      return combinedText
    },
  })

  const columnsInfoBN = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      key: 'stt',
      width: 70,
      fixed: 'left',
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      width: 180,
      fixed: 'left',
      ...getColumnSearchProps('tenChiNhanh'),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
      width: 150,
      ...getColumnSearchProps('tenbenhnhan'),
      fixed: 'left',
    },
    {
      title: 'Tuổi',
      dataIndex: 'tuoi',
      key: 'tuoi',

      children: [
        {
          key: 'nam',
          title: 'Nam',
          dataIndex: 'gioitinh',
          render: (text, record) => (text === 'Nam' ? calculateAgeForReport(record.ngaysinh) : ''),
          width: 140,
        },
        {
          key: 'nu',
          title: 'Nữ',
          dataIndex: 'gioitinh',
          render: (text, record) => (text === 'Nữ' ? calculateAgeForReport(record.ngaysinh) : ''),
          width: 140,
        },
      ],
    },
    {
      title: 'Số thẻ BHYT',
      dataIndex: 'q',
      key: 'q',
      align: 'center',
      width: 130,
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diachi',
      key: 'diachi',
      width: 400,
      ...getColumnSearchProps('diachi', 'tenPhuongXa', 'tenQuanHuyen', 'tenTinhTP'),
    },
    {
      title: 'Nghề nghiệp',
      dataIndex: 'tenNgheNghiep',
      key: 'tenNgheNghiep',
      width: 200,
    },
    {
      title: 'Dân tộc',
      dataIndex: 'tenDanToc',
      key: 'tenDanToc',
      width: 70,
      align: 'center',
    },
    {
      title: 'Triệu chứng',
      dataIndex: 'trieuchung',
      key: 'trieuchung',
      width: 250,
    },
    {
      title: 'Chẩn đoán',
      dataIndex: 'chandoan',
      key: 'chandoan',
      ...getColumnSearchProps('chandoanbandau', 'chandoan', 'chandoanravien'),
      width: 250,
      render: (text, record) =>
        `${record.chandoanbandau ? ` - ${record.chandoanbandau}` : ''}${record.chandoan ? ` - ${record.chandoan}` : ''}${record.chandoanravien ? ` - ${record.chandoanravien}` : ''}`,
    },
    {
      title: 'Phương pháp điều trị',
      dataIndex: 'ppDieuTri',
      width: 250,
      key: 'ppDieuTri',
      ...getColumnSearchProps('ppDieuTri'),
    },
    {
      title: 'Y, BS khám bệnh',
      dataIndex: 'bskham',
      key: 'bskham',
      width: 140,
      ...getColumnSearchProps('bskham'),
    },
    {
      title: 'Ngày khám',
      dataIndex: 'ngaykham',
      key: 'ngaykham',
      align: 'center',
      width: 150,
      render: (text, record) => moment(record.ngaykham).format('DD/MM/YYYY'),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 300,
    },
  ]

  useEffect(() => {
    //map object value in list filter array
    const newFilteredData = listBN
      .filter((item) => {
        if (listColumnFilter.length === 0) return true
        return listColumnFilter.every((filterObj) => {
          const [key, value] = Object.entries(filterObj)[0]
          if (key === 'diachi') {
            //join address
            const joinDiaChi = `${item.diachi ?? ''}${item.tenPhuongXa ? ', ' + item.tenPhuongXa : ''}${item.tenQuanHuyen ? ', ' + item.tenQuanHuyen : ''}${item.tenTinhTP ? ', ' + item.tenTinhTP : ''}`
            return joinDiaChi?.toLowerCase().includes(value.toLowerCase())
          }
          if (key === 'ppDieuTri') {
            //join thuoc
            const joinThuoc = item?.danhSachThuocDTOs
              ?.map((val) => `${val.tenbietduoc} (${val.soluong} ${val.dvt})`)
              .join(', ')
            return joinThuoc?.toLowerCase().includes(value.toLowerCase())
          }
          return item[key]?.toString().toLowerCase().includes(value.toLowerCase())
        })
      })
      .map((item, index) => ({
        stt: index + 1, // Reindex the STT
        ...item,
        maPhieuThu: `PT${item.maBenhNhan}`,
        maToaThuoc: `MTT${item.maBenhNhan}`,
        diachi: `${item.diachi ?? ''}${item.tenPhuongXa ? ', ' + item.tenPhuongXa : ''}${
          item.tenQuanHuyen ? ', ' + item.tenQuanHuyen : ''
        }${item.tenTinhTP ? ', ' + item.tenTinhTP : ''}`,
        ppDieuTri: item?.danhSachThuocDTOs
          ?.map((val) => `${val.tenbietduoc} (${val.soluong} ${val.dvt})`)
          .join(', '),
      }))
    setFilteredData(newFilteredData)
  }, [listBN, listColumnFilter])

  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: [
        {
          title: 'Chuyên khoa nhi',
          value: 'nhi',
          key: `0-1-1`,
          children: categoriesChiNhanh.listCNNhi.map((branch, index) => ({
            title: branch.tenChiNhanh,
            value: branch.idChiNhanh,
            key: `0-1-1-${index}`,
          })),
        },
        // {
        //   title: 'Chuyên khoa sản',
        //   value: 'san',
        //   key: `0-1-2`,
        //   children: categoriesChiNhanh.listCNSan.map((branch, index) => ({
        //     title: branch.tenChiNhanh,
        //     value: branch.idChiNhanh,
        //     key: `0-1-2-${index}`,
        //   })),
        // },
        {
          title: 'Chuyên khoa lão',
          value: 'lao',
          key: `0-1-3`,
          children: categoriesChiNhanh.listCNLao.map((branch, index) => ({
            title: branch.tenChiNhanh,
            value: branch.idChiNhanh,
            key: `0-1-3-${index}`,
          })),
        },
        {
          title: 'Chuyên khoa mắt',
          value: 'mat',
          key: `0-1-4`,
          children: categoriesChiNhanh.listCNMat.map((branch, index) => ({
            title: branch.tenChiNhanh,
            value: branch.idChiNhanh,
            key: `0-1-4-${index}`,
          })),
        },
      ],
    },
  ]

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const filterTreeNode = (input, node) => {
    const lowerCaseInput = input.toLowerCase()
    return node.title.toLowerCase().indexOf(lowerCaseInput) >= 0
  }

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh',
    showSearch: true,
    filterTreeNode,
    style: {
      width: '100%',
    },
  }

  return (
    <>
      <div className="h-full bg-[#EFEFEF] p-5">
        <div className="h-full bg-white border rounded-xl">
          <div className="w-full border-b flex items-center justify-between">
            <div className="p-2 flex gap-5 items-center">
              <Select
                showSearch
                className="w-72"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={handleCompany}
                value={company}
                options={listCompany?.map((items) => ({
                  label: items.tenct,
                  value: items.idct,
                }))}
              />
              <div className="w-80">
                <TreeSelect
                  {...tProps}
                  style={{ flexWrap: 'unset' }}
                  className="w-full h-8 overflow-clip"
                />
              </div>
              <ul className="flex gap-1 items-center">
                <li>
                  <DatePicker
                    defaultValue={dayjs(dateFrom, dateFormat)}
                    maxDate={dayjs(now)}
                    allowClear={false}
                    onChange={handleDatePicker('from')}
                    format={dateFormat}
                  />
                </li>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                  </svg>
                </span>
                <li>
                  <DatePicker
                    defaultValue={dayjs(dateTo, dateFormat)}
                    onChange={handleDatePicker('to')}
                    maxDate={dayjs(now)}
                    allowClear={false}
                    format={dateFormat}
                  />
                </li>
              </ul>
              <IconButton
                onClick={() => handleCLickSearch(search, dateFrom, dateTo, company, value)}
                color="primary"
                aria-label="add to shopping cart"
              >
                <CachedIcon />
              </IconButton>
              <Button
                onClick={() => exportToExcel(filteredData, dateFrom, dateTo)}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
                loading={loadingExport}
                disabled={loadingExport}
              >
                Xuất Excel
              </Button>
            </div>
          </div>
          <div className="mt-2 p-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  borderRadius: 0,
                },
                components: {
                  Table: {
                    cellFontSize: 12,
                  },
                },
              }}
            >
              <Table
                className="table-skb"
                loading={loading}
                scroll={{ x: 3000, y: 670 }}
                pagination={false}
                bordered
                columns={columnsInfoBN}
                dataSource={filteredData}
                footer={() => (
                  <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
                    Tổng: {filteredData.length} {/* Correct total displayed */}
                  </div>
                )}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default SoKhamBenh
