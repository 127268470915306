import * as typeAction from '../constants/constants'

export const getListBNChoTKAction = (idcn) => ({
    type: typeAction.GET_LIST_BN_CHO_TRONGKINH,
    idcn,
})
export const getListBNDaTKAction = (idcn, tungay, denngay) => ({
    type: typeAction.GET_LIST_BN_DA_TRONGKINH,
    idcn,
    tungay,
    denngay,
})
export const putListDVBNTrongKinhAction = (listdv, idcn, tungay, denngay) => ({
    type: typeAction.PUT_LIST_DV_BN_TRONGKINH,
    listdv,
    idcn,
    tungay,
    denngay
})
// export const getInfoBNDVThuThuatAction = (idbnttc, idcn) => ({
//     type: typeAction.GET_INFO_BN_DV_THUTHUAT,
//     idbnttc,
//     idcn,
// })
// export const getListVTTieuHaoThuThuatAction = (idcn) => ({
//     type: typeAction.GET_LIST_VT_THUTHUAT,
//     idcn,
// })