import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Input, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { branchService } from '../../../../services/branch/branchService'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { useFormik } from 'formik'

export default function AddIP({ visible, setVisible, cancelAddModal }) {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [branch, setBranch] = useState(null)
  const handleCancel = () => {
    setVisible(false)
  }
  const fetchBranch = async (id) => {
    try {
      const result = await branchService.getListBranch(id)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])
  const formik = useFormik({
    initialValues: {
      IP: '',
      IDCT: id_company,
      idChiNhanh: null,
      ghiChu: '',
      nguoitao: localStorage.getItem('id_user'),
    },
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })
  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const handleSubmit = (value) => {
    console.log('value', value)
    // Thêm IP và ghi chú
    const newIP = {
      IP: value.IP,
      ghiChu: value.ghiChu,
      idChiNhanh: value.idChiNhanh,
      IDCT: value.IDCT,
      nguoitao: value.nguoitao,
    }
    // Gửi dữ liệu lên server hoặc xử lý theo yêu cầu của bạn
    console.log('New IP:', newIP)
  }
  console.log('branch', branch)
  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>Thêm Key</div>}
      open={visible}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={cancelAddModal}>
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={formik.handleSubmit}>
          Thêm
        </Button>,
      ]}
    >
      <form className="flex flex-col gap-2">
        <div>
          <label className="font-semibold">
            <span className="text-red-500">*</span> Key:
          </label>
          <Input
            name="IP"
            type="text"
            value={formik.values.IP}
            onChange={formik.handleChange}
            className="w-full"
          />
        </div>
        <div>
          <label className="font-semibold">
            {' '}
            <span className="text-red-500">*</span>Công ty:
          </label>
          <Select
            showSearch
            value={formik.values.IDCT}
            onChange={(value) => {
              fetchBranch(value)
              formik.setFieldValue('IDCT', value)
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
          />
        </div>
        <div>
          <label className="font-semibold">
            <span className="text-red-500">*</span> Chi nhánh:
          </label>
          <Select
            status={formik.errors.idChiNhanh ? 'error' : ''}
            value={formik.values.idChiNhanh}
            showSearch
            onChange={handleSelected('idChiNhanh')}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={branch?.map((items) => ({
              label: items.tenChiNhanh,
              value: items.idChiNhanh,
            }))}
            className="w-full"
          />
        </div>
        <div>
          <label className="font-semibold">Ghi chú:</label>
          <Input.TextArea
            name="ghiChu"
            value={formik.values.ghiChu}
            onChange={formik.handleChange}
            className="w-full"
            rows={4} // Bạn có thể điều chỉnh số hàng hiển thị của TextArea
          />
        </div>
      </form>
    </Modal>
  )
}
