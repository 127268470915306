import React, { useEffect, useState } from 'react'
import { Button, ConfigProvider, DatePicker, Select, Table, TreeSelect, Typography } from 'antd'
import { branchService } from '../../../services/branch/branchService'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import dayjs from 'dayjs'
import { IconButton } from '@mui/material'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import * as XLSX from 'xlsx'
import { formattedNumber } from '../../../utils/formattedNumber'
import { reportService } from '../../../services/Report/reportService'
import 'moment/locale/vi'
moment.locale('vi')

const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const { SHOW_PARENT } = TreeSelect
const { Text } = Typography
function TabMat(props) {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState({ from: now, to: now })
  const [value, setValue] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    if (PkDangNhap?.idChuyenKhoa === 4) {
      setValue([BranchLogin])
    }
  }, [PkDangNhap])

  const dispatch = useDispatch()
  const fetchBranch = async (id) => {
    const { data } = await branchService.getListBranch(id)
    setBranch(data.filter((value) => value.idChuyenKhoa === 4))
  }

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const handleDatePicker = (name) => (date, dateString) => {
    const formatValueDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevState) => ({ ...prevState, [name]: formatValueDate }))
  }

  const exportToExcel = () => {
    // Prepare data in the format required by xlsx
    const formattedData = data.map((item) => ({
      Header: item.header,
      Value: item.value,
    }))

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(formattedData)
    const workbook = XLSX.utils.book_new()

    // Merge cells for the first row
    worksheet['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }, // Merge first row, columns A and B
    ]

    // Add "Báo Cáo" to the merged cell
    worksheet['A1'] = { v: 'Báo Cáo', t: 's' }

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // Export the workbook
    XLSX.writeFile(workbook, 'exported_data.xlsx')
  }

  const onLoad = async () => {
    setIsLoading(true)
    let idChiNhanhs = ''
    if (value?.length > 0 && value[0] !== 'all') {
      idChiNhanhs = value.map((id) => `&idChiNhanhs=${id}`).join('&')
    }
    try {
      const { data } = await reportService.getThongKeMat(company, date.from, date.to, idChiNhanhs)
      setData(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])

  // const data = [
  //   { key: '1', header: 'TỔNG LƯỢT KHÁCH DỊCH VỤ', value: 'Row 1 Data' },
  //   { key: '2', header: 'Lượt khách khám mắt + đo khúc xạ', value: 'Row 2 Data' },
  //   { key: '3', header: 'Lượt khách vãng lai tái khám miễn phí', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lượt khách làm thủ thuật', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lượt khách lần đầu thẻ thành viên', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lượt khách tái khám thẻ thành viên', value: 'Row 3 Data' },
  //   { key: '3', header: 'Khách đến khám tại phòng khám lần đầu', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lượt khách đến mua thuốc từ dịch vụ', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lượt khách mua lẻ thuốc', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu dịch vụ khám bệnh', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu dịch vụ đo khúc xạ', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu thủ thuật', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu chỉ định khác', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu bán thuốc từ dịch vụ', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu bán lẻ thuốc', value: 'Row 3 Data' },
  //   { key: '3', header: 'Lợi nhuận doanh thu bán thuốc', value: 'Row 3 Data' },
  //   { key: '3', header: 'Số lượng bán thẻ thành viên', value: 'Row 3 Data' },
  //   { key: '3', header: 'Số lượng bán thẻ bạc', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu thẻ thành viên', value: 'Row 3 Data' },
  //   { key: '3', header: 'Doanh thu thẻ thành viên bạc', value: 'Row 3 Data' },
  //   { key: '3', header: 'TỔNG DOANH THU', value: 'Row 3 Data' },
  //   { key: '3', header: 'Thanh toán thẻ bằng máy Vietcombank', value: 'Row 3 Data' },
  //   { key: '3', header: 'Chuyển khoản Vietcombank', value: 'Row 3 Data' },
  //   { key: '3', header: 'TIỀN MẶT NỘP NGÂN HÀNG', value: 'Row 3 Data' },
  // ]

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Ngày',
      dataIndex: 'ngay',
      key: 'ngay',
      width: 100,
      fixed: 'left',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      fixed: 'left',
      width: 250,
    },
    {
      title: 'Ngày khai trương',
      dataIndex: 'ngaykhaitruong',
      key: 'ngaykhaitruong',
      width: 150,
      fixed: 'left',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
    },

    {
      title: 'Khám mắt và đo khúc xạ',
      dataIndex: 'khammatvadokhucxa',
      key: 'khammatvadokhucxa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tái khám vãng lai',
      dataIndex: 'taikhamvanglai',
      key: 'taikhamvanglai',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt thủ thuật',
      dataIndex: 'luotthuthuat',
      key: 'luotthuthuat',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách lần đầu thẻ',
      dataIndex: 'luotkhachlandauthe',
      key: 'luotkhachlandauthe',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách tái khám thẻ',
      dataIndex: 'luotkhachtaikhamthe',
      key: 'luotkhachtaikhamthe',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lần đầu thẻ thành viên',
      dataIndex: 'luotkhachlandauthe',
      key: 'luotkhachlandauthe',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khách đến khám lần đầu',
      dataIndex: 'khachdenkhamlandau',
      key: 'khachdenkhamlandau',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách toa thuốc',
      dataIndex: 'luotkhachtoathuoc',
      key: 'luotkhachtoathuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lượt khách toa bán lẻ',
      dataIndex: 'luotkhachtoabanle',
      key: 'luotkhachtoabanle',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu khám bệnh',
      dataIndex: 'doanhthukhambenh',
      key: 'doanhthukhambenh',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu đo khúc xạ',
      dataIndex: 'doanhthudokhucxa',
      key: 'doanhthudokhucxa',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },

    {
      title: 'Doanh thu thủ thuật',
      dataIndex: 'doanhthuthuthuat',
      key: 'doanhthuthuthuat',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu khác',
      dataIndex: 'doanhthukhac',
      key: 'doanhthukhac',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu toa thuốc',
      dataIndex: 'doanhthutoathuoc',
      key: 'doanhthutoathuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu toa bán lẻ',
      dataIndex: 'doanhthutoabanle',
      key: 'doanhthutoabanle',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lợi nhuận bán thuốc',
      dataIndex: 'loinhuanbanthuoc',
      key: 'loinhuanbanthuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số lượng bán thẻ thành viên',
      dataIndex: 'soluongbanthethanhvien',
      key: 'soluongbanthethanhvien',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số lượng bán thẻ bạc',
      dataIndex: 'soluongbanthebac',
      key: 'soluongbanthebac',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số lượng bán thẻ vàng',
      dataIndex: 'soluongbanthevang',
      key: 'soluongbanthevang',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu thẻ thánh viên',
      dataIndex: 'doanhthuthethanhvien',
      key: 'doanhthuthethanhvien',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu thẻ bạc',
      dataIndex: 'doanhthuthebac',
      key: 'doanhthuthebac',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu thẻ vàng',
      dataIndex: 'doanhthuthevang',
      key: 'doanhthuthevang',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tổng doanh thu',
      dataIndex: 'tongdoanhthu',
      key: 'tongdoanhthu',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Thanh toán máy POS',
      dataIndex: 'thanhtoanpos',
      key: 'thanhtoanpos',
      align: 'right',
      width: 350,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Thanh toán chuyển khoản',
      dataIndex: 'thanhtoanchuyenkhoan',
      key: 'thanhtoanchuyenkhoan',
      align: 'right',
      width: 350,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tiền mặt nộp ngân hàng',
      dataIndex: 'tienmat',
      key: 'tienmat',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Thanh toán đối tác',
      dataIndex: 'thanhtoandoitac',
      key: 'thanhtoandoitac',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongTienNgay',
      key: 'tongTienNgay',
      width: 100,
      align: 'right',
      fixed: 'right',
      render: (_, record) => {
        const total = record.tienmat + record.thanhtoanpos + record.thanhtoanchuyenkhoan
        return formattedNumber(total)
      },
    },
  ]

  return (
    <div>
      <div className="flex gap-5 justify-between border-dotted border-b-0 p-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value)
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-80">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('from')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
        </div>
        {/* <div className="font-semibold">
          <Button
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div> */}
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 15,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1500,
              y: 660,
            }}
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
            loading={isLoading}
            summary={(pageData) => {
              let total1 = 0
              let total2 = 0
              let total3 = 0
              let total4 = 0
              let total5 = 0
              let total6 = 0
              let total7 = 0
              let total8 = 0
              let total9 = 0
              let total10 = 0
              let total11 = 0
              let total12 = 0
              let total13 = 0
              let total14 = 0
              let total15 = 0
              let total16 = 0
              let total17 = 0
              let total18 = 0
              let total19 = 0
              let toltal20 = 0
              let toltal21 = 0
              let toltal22 = 0
              let toltal23 = 0
              let toltal24 = 0
              let toltal25 = 0
              let toltal26 = 0
              let toltal27 = 0
              let TongTien = 0
              pageData.forEach(
                ({
                  khammatvadokhucxa,
                  taikhamvanglai,
                  luotthuthuat,
                  luotkhamthuthuat,
                  luotkhachlandauthe,
                  luotkhachtaikhamthe,
                  khachdenkhamlandau,
                  luotkhachtoathuoc,
                  luotkhachtoabanle,
                  doanhthukhambenh,
                  doanhthudokhucxa,
                  doanhthuthuthuat,
                  doanhthukhac,
                  doanhthutoathuoc,
                  doanhthutoabanle,
                  loinhuanbanthuoc,
                  soluongbanthethanhvien,
                  soluongbanthebac,
                  soluongbanthevang,
                  doanhthuthethanhvien,
                  doanhthuthebac,
                  doanhthuthevang,
                  tongdoanhthu,
                  thanhtoanpos,
                  thanhtoanchuyenkhoan,
                  tienmat,
                  thanhtoandoitac,
                }) => {
                  total1 += khammatvadokhucxa
                  total2 += taikhamvanglai
                  total3 += luotthuthuat
                  total4 += luotkhamthuthuat
                  total5 += luotkhachlandauthe
                  total6 += luotkhachtaikhamthe
                  total7 += khachdenkhamlandau
                  total8 += luotkhachtoathuoc
                  total9 += luotkhachtoabanle
                  total10 += doanhthukhambenh
                  total11 += doanhthudokhucxa
                  total12 += doanhthuthuthuat
                  total13 += doanhthukhac
                  total14 += doanhthutoathuoc
                  total15 += doanhthutoabanle
                  total16 += loinhuanbanthuoc
                  total17 += soluongbanthethanhvien
                  total18 += soluongbanthebac
                  total19 += soluongbanthevang
                  toltal20 += doanhthuthethanhvien
                  toltal21 += doanhthuthebac
                  toltal22 += doanhthuthevang
                  toltal23 += tongdoanhthu
                  toltal24 += thanhtoanpos
                  toltal25 += thanhtoanchuyenkhoan
                  toltal26 += tienmat
                  toltal27 += thanhtoandoitac
                  TongTien = toltal24 + toltal25 + toltal26
                },
              )
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={4}>
                      <Text strong>Tổng cộng</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total1)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total2)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total3)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total4)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total5)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total6)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total7)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total8)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total9)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total10)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total11)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total12)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total13)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total14)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total15)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total16)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total17)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={21} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total18)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={22} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(total19)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={23} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal20)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={24} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal21)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={25} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal22)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={26} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal23)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={27} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal24)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={28} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal25)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={29} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal26)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={30} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(toltal27)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={31} align="right" fixed>
                      <span style={{ color: 'red' }}>{formattedNumber(TongTien)}</span>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

TabMat.propTypes = {}

export default TabMat
