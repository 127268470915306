import * as Yup from 'yup'

export const ChildrenClinicSchema = Yup.object().shape({
  lydokham: Yup.string().required(),
  benhsu: Yup.string().required(),
  trieuchung: Yup.string().required(),
  loidan: Yup.string().required(),
  ngaytaikham: Yup.string().required(),
  lydokolaythuoc: Yup.string().required(),
})

export const KSLClinicSchema = Yup.object().shape({
  // lydokham: Yup.string().required(),
  // benhsu: Yup.string().required(),
  // trieuchung: Yup.string().required(),
  // loidan: Yup.string().required(),
  ngaytaikham: Yup.string().required(),
  lydokolaythuoc: Yup.string().required(),
})
