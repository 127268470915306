import { call, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as typeAction from '../constants/constants'
import { trongKinhService } from '../../services/trongKinh/trongKinhService'
import { doKhucXaService } from '../../services/doKhucXa/doKhucXaService'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export function* trongKinhSaga() {
  //LẤY DANH SÁCH BỆNH NHÂN CHỜ THỰC HIỆN TRÒNG KÍNH
  yield takeLatest(
    typeAction.GET_LIST_BN_CHO_TRONGKINH,
    function* getListBNChoTKSaga({ type, idcn }) {
      try {
        const result = yield call(() => trongKinhService.getListBNChoTK(idcn))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_CHO_TRONGKINH,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LẤY DANH SÁCH BỆNH NHÂN ĐÃ THỰC HIỆN TRÒNG KÍNH
  yield takeLatest(
    typeAction.GET_LIST_BN_DA_TRONGKINH,
    function* getListBNDaTKSaga({ type, idcn, tungay, denngay }) {
      try {
        const result = yield call(() => trongKinhService.getListBNDaTK(idcn, tungay, denngay))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_TRONGKINH,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LƯU DANH SÁCH VẬT TƯ TIÊU HAO BỆNH NHÂN THỦ THUẬT
  yield takeLatest(
    typeAction.PUT_LIST_DV_BN_TRONGKINH,
    function* putListDVBNTrongKinhSaga({ type, listdv, idcn, tungay, denngay }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const listDV = yield call(() => trongKinhService.putListDVBNTrongKinh(listdv))
        const listCho = yield call(() => trongKinhService.getListBNChoTK(idcn))
        const listDa = yield call(() => trongKinhService.getListBNDaTK(idcn, tungay, denngay))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_CHO_TRONGKINH,
          payload: listCho.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_TRONGKINH,
          payload: listDa.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_TRONG_VA_GONG_KINH_BN_DKX,
          payload: null,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thông tin thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Lưu thông tin thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // //LẤY THÔNG TIN BỆNH NHÂN THỦ THUẬT
  // yield takeLatest(
  //     typeAction.GET_INFO_BN_DV_THUTHUAT,
  //     function* getInfoBNDVThuThuatSaga({ type, idbnttc, idcn }) {
  //         try {
  //             const result = yield call(() => thuThuatService.getInfoBNDVThuThuat(idbnttc))
  //             const listVT = yield call(() => thuThuatService.getListVTThuThuat(idcn))
  //             yield put({
  //                 type: typeAction.DISPATCH_INFO_BN_DV_THUTHUAT,
  //                 payload: result.data,
  //             })
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
  //                 payload: listVT?.data.filter((items) => items.tonKho >= 0).map((items) => ({ ...items, iD: ('' + items.idThuoc + items.soLo + items.hanDung + items.soHoaDon + items.maSoDangKy + items.tonKho) })),
  //             })
  //         } catch (err) {
  //             yield console.log(err)
  //         }
  //     }
  // )
  // //LẤY DANH SÁCH TỒN KHO VẬT TƯ TIÊU HAO THỦ THUẬT
  // yield takeLatest(
  //     typeAction.GET_LIST_VT_THUTHUAT,
  //     function* getListVTThuThuatSaga({ type, idcn }) {
  //         try {
  //             const result = yield call(() => thuThuatService.getListVTThuThuat(idcn))
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
  //                 payload: result?.data.filter((items) => items.tonKho >= 0).map((items) => ({ ...items, iD: ('' + items.idThuoc + items.soLo + items.hanDung + items.soHoaDon + items.maSoDangKy + items.tonKho) })),
  //             })
  //         } catch (err) {
  //             yield console.log(err)
  //         }
  //     }
  // )
}
