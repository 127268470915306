import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import _ from 'lodash'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
import { drugstoreService } from '../../services/drugstore/drugstoreService'
import { generatePhieuToaThuoc } from '../../utils/report/phieuToaThuoc'
// import { BNChoKham, thuocKeToa, updateDetailBN } from '../actions/childrenClinicAction'
// import { generateKhamBenhXuatVacXin } from '../../utils/report/vacxin/phieuKhamBenhXuatVacxin'
// import { generatePhieuChiDinh } from '../../utils/report/phieuChiDinh'
// import { cashierService } from '../../services/cashier/cashierService'
// import { doKhucXaService } from '../../services/doKhucXa/doKhucXaService'
// import { generatePhieuKiemTruocTiemChung } from '../../utils/report/phieuKiemTruocTiemChung'
// import { generatePhieuKiemTruocTiemChungLao } from '../../utils/report/phieuKiemTruocTiemChungLao'
// import { generatePhieuKiemTruocTiemChungSan } from '../../utils/report/phieuKiemTruocTiemChungSan'
// // import { generatePhieuToaThuocTA } from '../../utils/report/phieuToaThuocTA'
// import { generatePhieuKhamBenh } from '../../utils/report/phieuKhamBenh'
// // import { generatePhieuToaThuocKoLayThuoc } from '../../utils/report/phieuToaThuocKoLayThuoc'
import ToastCus from '../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
export function* khamNoiKhoaSaga() {
  // LƯU THÔNG TIN CHẨN ĐOÁN
  yield takeLatest(
    typeAction.PUT_INFO_CHANDOAN,
    function* putInfoChanDoanSaga({ type, form, list, isPrint, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
        const ID_CONGTY_LOGIN = Number(localStorage.getItem('id_company'))
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const { infoUser } = yield select((state) => state.userReducer)
        const { infoBN, infoBNTTC } = yield select((state) => state.khamSucKhoeReducer)

        // check dược sĩ đang thao tác thì không lưu
        if (infoBNTTC?.trangthaikhambenh !== 1) {
          const { data: infoToa } = yield call(() =>
            drugstoreService.getServiceNhaThuoc(infoBNTTC?.idbnttc),
          )
          if (infoToa?.DANHANTOA === 1) {
            yield put({
              type: typeAction.DISPATCH_INFO_TOA_KSK,
              payload: infoToa,
            })
            // yield put({
            //   type: typeAction.DISPATCH_RESET_STORE_KSK,
            // })
            // yield call(() => resetForm())
            yield put({
              type: typeAction.CLOSE_LOADING_PAGE,
            })
            ToastCus.fire({
              icon: 'error',
              title: 'Dược sĩ đang thao tác!',
            })
            return
          }
        }

        const { data: infoBNTTCnew } = yield call(() =>
          childrenClinicService.updateDetailBN({
            idbnttc: infoBNTTC?.idbnttc,
            idbn: infoBNTTC?.idbn,
            chieucao: form?.editsinhhieu?.chieucao,
            cannang: form?.editsinhhieu?.cannang,
            mach: form?.editsinhhieu?.mach,
            huyetap: form?.editsinhhieu?.huyetap,
            nhietdo: form?.editsinhhieu?.nhietdo,
            nhiptho: form?.editsinhhieu?.nhiptho,
            spO2: form?.editsinhhieu?.spO2,
            // ngaykham: '2024-11-01T06:20:38.288Z',
            // sttkham: 0,
            // idcnpk: 0,
            // idlankham: 0,
            // idnguon: 0,
            ghichu: form?.ghichu,
            // nguoitao: 'string',
            // ngaysua: '2024-11-01T06:20:38.288Z',
            // nguoisua: 0,
            trangthaikhambenh: isPrint ? (list?.length ? 2 : 4) : infoBNTTC?.trangthaikhambenh,
            bskham: infoUser?.tenNV,
            lydokham: form?.lydokham,
            // benhsu: 'string',
            trieuchung: form?.trieuchung,
            idchandoan: form?.idchandoan,
            chandoan: form?.chandoan,
            idloidan: form?.idloidan,
            loidan: form?.loidan,
            tiensubanthan: form?.editbenhnhan?.tienSuBanThan,
            diung: form?.editbenhnhan?.diUng,
            // idcalamviec: 0,
            idbskham: infoUser?.idnv,
            // idcn: ID_CHINHANH_LOGIN,
            // idct: ID_CONGTY_LOGIN,
            // idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
            ngaytaikham: form?.ngaytaikham,
            // soLanDaTaiKham: 0,
            // idloaikham: 0,
            // nguoinha: 'string',
            // tennguoinha: 'string',
            // dienthoainguoinha: 'string',
            lydokolaythuoc: list?.length ? 0 : form?.lydokolaythuoc,
            noidungkolaythuoc: list?.length ? '' : form?.noidungkolaythuoc,
            // tenPhongKham: 'string',
            // trangThaiKB: 'string',
            // tenLanKham: 'string',
            // tenLoaiKham: 'string',
            // tenNguon: 'string',
            // tenCaLamViec: 'string',

            benhsu: form?.benhsu,
            benhkemtheo: form?.benhkemtheo,
            idicD10BENHKEMTHEO: form?.idicD10BENHKEMTHEO,
            chandoanbandau: form?.chandoanbandau,
            idicD10BANDAU: form?.idicD10BANDAU,
            chandoanravien: form?.chandoanravien,
            idicD10RAVIEN: form?.idicD10RAVIEN,

            quatrinhbenhly: form?.quatrinhbenhly,
            tieusugiadinh: form?.tieusugiadinh,
            toanthan: form?.toanthan,
            cacbophan: form?.cacbophan,
            daxuly: form?.daxuly,
            dieutringoaitru: form?.dieutringoaitru,
            dienbienlamsang: form?.dienbienlamsang,
            ppdieutri: form?.ppdieutri,
            ttlucravien: form?.ttlucravien,
            huongxuly: form?.huongxuly,
          }),
        )
        yield call(() =>
          childrenClinicService.putInfoBN({
            idbn: infoBN?.idbn,
            diUng: form?.editbenhnhan?.diUng,
            tienSuBanThan: form?.editbenhnhan?.tienSuBanThan,
            dienThoai: form?.editbenhnhan?.dienThoai,
          }),
        )
        const { data: toaNew } = yield call(() =>
          childrenClinicService.putToaThuocBsTuLaySoLo(
            infoBNTTC?.idbnttc,
            ID_CHINHANH_LOGIN,
            list?.map((item, index) => ({
              // idbnthuoc: 0,
              stt: `${++index}`,
              idbnttc: infoBNTTC?.idbnttc,
              idthuoc: item?.idthuoc,
              maToaThuoc: item?.info?.thuocVatTu?.maThuoc,
              tenBietDuoc: item?.tenbietduoc,
              tenHoatChat: item?.tenhoatchat,
              dvt: item?.dvt,
              nongDo: item?.info?.thuocVatTu?.nongDo,
              hamLuong: item?.dvt,
              quyCach: item?.info?.thuocVatTu?.quyCach,
              donGia: item?.giaban,
              soLuong: item?.soluong,
              // thanhTien: item?.soluong * item?.giaban,
              // tongTien: item?.soluong * item?.giaban,
              bsKeDon: infoUser?.tenNV,
              // ngayKeDon: '2024-11-01T07:07:15.175Z',
              ghiChu: item?.ghichu,
              lieuDung: `${item?.lieudung}`,
              soLanNgay: item?.solanngay,
              idkhocn: item?.idkhocn,
              duongDung: item?.duongdung,
              idnhomthuoc: item?.info?.thuocVatTu?.idNhom,
              donvidung: item?.donvidung,
              // muitiem: 0,
              // ngayhentiem: '2024-11-01T07:07:15.175Z',
              // idphanloai: item?.info?.thuocVatTu?.idNhom,
              // soLo: item?.solo,
              // hanDung: item?.handung,
              // soHoaDon: item?.sohoadon,
            })),
          ),
        )

        if (isPrint && toaNew?.length) {
          yield call(() => {
            let listVT = []
            // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
            toaNew
              ?.sort((a, b) => (a?.stt ?? '').localeCompare(b?.stt ?? ''))
              ?.filter((item) => item?.idnhomthuoc !== 179 && item?.idnhomthuoc !== 205)
              ?.map((item) => {
                if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
                  listVT?.map(
                    (itemVT) =>
                      itemVT?.idthuoc === item?.idthuoc && (itemVT.soLuong += item.soLuong),
                  )
                } else {
                  listVT.push({
                    ...item,
                  })
                }
              })
            // for (const item of toaPrint) {
            //   console.log(item)

            // }
            generatePhieuToaThuoc(
              infoBN,
              {
                ...infoBNTTCnew,
                // dataDoKhucXa: form?.dataDoKhucXa?.nguoikham ? form?.dataDoKhucXa : null,
              },
              listVT,
              PkDangNhap,
            )
          })
        }

        yield put({
          type: typeAction.DISPATCH_RESET_STORE_KSK,
        })
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.log('Lưu thông tin chẩn đoán : ', error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
