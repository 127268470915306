import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Input, DatePicker, Select, Space, Button, message } from 'antd'
import { https } from '../../../../services/apiService'
import { nhanvienService } from '../../../../services/nhanvien/nhanvienService'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import '../../../../../src/AesEstyles.css' // Import file CSS tùy chỉnh
import * as typeAction from '../../../../store/constants/constants'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import { branchService } from '../../../../services/branch/branchService'
import {
  // fetchAllThuocVT,
  getBranchNhapKho,
} from '../../../../store/actions/NhapKhoAction'
import {
  branchChuyenKhoByCompany,
  getInfoKhoDefault,
  getListKhoNhanAction,
  getListTonkhoCn_action,
  postPhieuCKAction,
} from '../../../../store/actions/chuyenKhoAction'
import 'moment/locale/vi'
moment.locale('vi')
const AddStaff = ({
  stafs,
  visible,
  cancelAddModal,
  setModalVisibility,
  setPage,
  pageSize,
  modifyTing,
  onAddSuccess,
  getListNhanVien,
}) => {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  const [addForm] = Form.useForm()
  // lưu danh sách bằng cấp - chức danh.
  const [grant, setGrant] = useState([])
  const [position, setPosition] = useState([])
  // lưu danh sách cty - Lưu tên cty được chọn - Lưu ID cty được chọn.
  const [congTyData, setCtData] = useState([])
  const [company, setCompany] = useState('')
  const [idCT, setIdCT] = useState(null) //
  // lưu danh sách chi nhánh liên kết với cty đã chọn.
  const [branchech, setBranchech] = useState([])
  // lưu danh sách thành phố - quận =>(tp) - phường => (quận)
  const [cityData, setCityData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [wardsData, setWardsData] = useState([])
  const { Option } = Select // hiển thị các tùy chọn trong danh sách thả xuống.
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { branch, defaultKhoNhap } = useSelector((state) => state.NhapKhoReducer)
  const { KhoNhan, KhoVT, TonKho, branchByCompany } = useSelector((state) => state.chuyenKhoReducer)
  // danh sách bằng cấp
  const fetchGrant = async () => {
    const result = await nhanvienService.getQualifications()
    setGrant(result.data)
  }
  // danh sách chức danh
  const fetchTitle = async () => {
    const result = await nhanvienService.getTitle()
    setPosition(result.data)
  }
  useEffect(() => {
    fetchGrant()
    fetchTitle()
  }, [])
  //------------ lấy lên tên công ty tương ứng -------------//
  const selectedStaff = listCompany?.find((item) => item.idct === id_company)
  useEffect(() => {
    dispatch(branchChuyenKhoByCompany(id_company))
    dispatch(listAllCompanyAction())
    dispatch(getInfoKhoDefault()) // lấy thông tin mặc của của chi nhánh nhánh tên và list kho chi nhánh
  }, [])
  // Hàm dữ liệu api
  const fetchData = async (url, params) => {
    try {
      const result = await https.get(url, { params })
      return result.data
    } catch (err) {
      console.error(err)
      message.error('Không có dữ liệu data')
      return null
    }
  }
  // danh sách cty
  useEffect(() => {
    const companies = async () => {
      const data = await fetchData('CongTy', {
        company,
      })
      if (data) {
        setCtData(data)
      }
    }
    companies()
  }, [company])
  // danh sách chi nhánh => cty
  async function branchse(idCT) {
    try {
      const result = await https.get(`ChiNhanh/GetChiNhanhByIDCT`, {
        params: { idCT },
      })
      setBranchech(result.data)
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    if (idCT) {
      branchse(idCT)
    }
  }, [idCT])
  const cities = async () => {
    try {
      const data = await fetchData(`tinh`, {})
      if (data) {
        setCityData(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // danh sách tỉnh / thành phố
  useEffect(() => {
    cities()
  }, [])
  // chọn TP => quận cho TP đã chọn.
  const handleCity = (value) => {
    fetchData(`QuanHuyen/${value}`, {}).then((data) => {
      setDistrictData(data)
    })
    addForm.setFieldsValue({ idquan: null, idphuong: null }) // Xóa giá trị đã chọn của quận và phường
    setDistrictData([])
    setWardsData([])
  }
  // chọn quận => phường cho QUẬN đã chọn.
  const handleDistrict = (value) => {
    fetchData(`PhuongXa/${value}`, {}).then((data) => {
      setWardsData(data)
    })
    addForm.setFieldsValue({ idphuong: null }) // Xóa giá trị đã chọn của phường
    setWardsData([])
  }
  // kích hoạt tìm nạp các chi nhánh cho cty đã chọn.
  const handleCompanyChange = (value) => {
    setCompany(value)
    setIdCT(value)
    addForm.setFieldsValue({ idchinhanh: null }) // Xóa chi nhánh đã chọn khi công ty thay đổi
    // setBranchech([]) // Xóa danh sách chi nhánh hiện tại
  }
  // Hàm thêm thông tin nhân viên
  const postAddNvStafs = async (form) => {
    try {
      const result = await nhanvienService.postStaffs(form)
      return result
    } catch (error) {
      console.error('Error:', error)
      throw error // Ném lỗi để xử lý ở phần gọi hàm
    }
  }
  // Xử lý khi hoàn thành thêm thông tin
  const onFinish = async (values) => {
    try {
      // Chuyển đổi ngày sinh sang định dạng mong muốn
      const formattedValues = {
        ...values,
        ngaysinh: values.ngaysinh ? values.ngaysinh.format('YYYY-MM-DDTHH:mm:ss') : undefined,
        nguoitao: infoUser?.tenNV,
      }
      const result = await postAddNvStafs(formattedValues)
      if (result) {
        modifyTing(result) // Lưu trữ thông tin bản ghi
        // Thêm nhân viên thành công, cập nhật trang hiện tại thành trang cuối
        // const totalPages = Math.ceil((stafs.totalPages * pageSize) / pageSize)
        // setPage(totalPages)
        message.success('Thêm Nhân Viên Thành Công!')
        onAddSuccess() // Gọi hàm và truyền dữ liệu nhân viên mới
      }
      setModalVisibility(false)
      addForm.resetFields()
    } catch (error) {
      console.error('Error:', error)
      message.error('Có lỗi xảy ra!')
    }
  }
  // Xử lý khi form submit lỗi
  const onFinishFailed = (errorInfo) => {}
  // Hủy và đóng form thêm thông tin
  const handleCancel = () => {
    setModalVisibility(false)
    cancelAddModal()
    addForm.resetFields()
  }

  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>Thêm nhân viên</div>}
      open={visible}
      onCancel={handleCancel}
      footer={null}
      width={700}
    >
      <Form form={addForm} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row gutter={8}>
          <Col span={6}>
            <Form.Item
              label="Mã nhân viên"
              name="manv"
              style={{ marginBottom: '8px' }}
              rules={[{ required: true, message: 'Vui lòng nhập mã nhân viên!' }]}
            >
              <Input allowClear disabled={false} maxLength={8} placeholder="Nhập mã nhân viên" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Tên tắt chức danh"
              style={{ marginBottom: '8px' }}
              name="chucdanhviettat"
              rules={[{ required: true }]}
            >
              <Input allowClear maxLength={8} placeholder="Tắt chức danh" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tên nhân viên"
              style={{ marginBottom: '8px' }}
              name="tennv"
              rules={[{ required: true, message: 'Vui lòng nhập tên nhân viên!' }]}
            >
              <Input allowClear placeholder="Nhập tên nhân viên" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item label="Ngày sinh" style={{ marginBottom: '8px' }} name="ngaysinh">
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Chọn ngày sinh"
                format="DD/MM/YYYY"
                disabledDate={(current) => {
                  // Vô hiệu hóa tất cả các ngày sau ngày hôm nay
                  return current && current > moment().endOf('day')
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Giới tính"
              style={{ marginBottom: '8px' }}
              name="gioitinh"
              rules={[{ message: 'Vui lòng chọn giới tính!' }]}
            >
              <Select allowClear style={{ width: '100%' }} placeholder="Chọn giới tính">
                <Select.Option value="Nam">Nam</Select.Option>
                <Select.Option value="Nữ">Nữ</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Số điện thoại" style={{ marginBottom: '8px' }} name="dienthoai">
              <Input allowClear maxLength={10} placeholder="Nhập số điện thoại" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item style={{ marginBottom: '8px' }} label="Bằng cấp" name="idbangcap">
              <Select placeholder="Chọn bằng cấp">
                {grant.map((qualification) => (
                  <Option key={qualification.idbangcap} value={qualification.idbangcap}>
                    {qualification.bangcap}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Chức danh"
              rules={[{ required: true, message: 'Vui lòng chọn bằng cấp!' }]}
              name="idchucdanh"
              style={{ marginBottom: '8px' }}
            >
              <Select placeholder="Chọn chức danh">
                {position.map((CareerTitles) => (
                  <Option key={CareerTitles.idcd} value={CareerTitles.idcd}>
                    {CareerTitles.tenchucdanh}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              label="Tên công ty"
              style={{ marginBottom: '8px' }}
              name="idct"
              rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Chọn tên công ty"
                onChange={handleCompanyChange}
              >
                {congTyData.map((company) => (
                  <Option key={company.idct} value={company.idct}>
                    {company.tenct}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tên chi nhánh"
              style={{ marginBottom: '8px' }}
              name="idchinhanh"
              rules={[{ required: true, message: 'Vui lòng chọn chi nhánh!' }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Chọn tên chi nhánh"
              >
                {branchech.map((branch) => (
                  <Option key={branch.idchinhanh} value={branch.idchinhanh}>
                    {branch.tenchinhanh}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Col span={24}>
          <Form.Item
            label="Tên máy chấm công"
            style={{ marginBottom: '8px' }}
            name="tenmaychamcong"
          >
            <Input
              allowClear
              style={{ width: '100%' }}
              placeholder="Nhập tên máy chấm công mặc định dưới chi nhánh"
            />
          </Form.Item>
        </Col>
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item label="Tỉnh/TP" style={{ marginBottom: '8px' }} name="idtinh">
              <Select
                style={{ width: '100%' }}
                placeholder="Chọn tỉnh / thành phố"
                onChange={handleCity}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {cityData.map((conscious) => (
                  <Option key={conscious.idTinh} value={conscious.idTinh}>
                    {conscious.tenTinh}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Quận/Huyện" style={{ marginBottom: '8px' }} name="idquan">
              <Select
                style={{ width: '100%' }}
                placeholder="Chọn quận / huyện"
                onChange={handleDistrict}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {districtData.map((city) => (
                  <Option key={city.idQuan} value={city.idQuan} title={city.tenQuan}>
                    {city.tenQuan}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Phường/Xã" style={{ marginBottom: '8px' }} name="idphuong">
              <Select
                style={{ width: '100%' }}
                placeholder="Chọn phường / xã"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {wardsData.map((ward) => (
                  <Option key={ward.idPhuong} value={ward.idPhuong} title={ward.tenPhuong}>
                    {ward.tenPhuong}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Col span={24}>
          <Form.Item label="Địa chỉ" style={{ marginBottom: '8px' }} name="diachi">
            <Input allowClear style={{ width: '100%' }} placeholder="Nhập địa chỉ" />
          </Form.Item>
        </Col>
        <Form.Item style={{ marginBottom: '8px', textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit">
              Thêm
            </Button>
            <Button onClick={handleCancel}>Hủy</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default AddStaff
