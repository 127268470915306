import { Table, ConfigProvider, Input, Select, DatePicker, Tooltip, Button } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached'
import moment from 'moment'
import dayjs from 'dayjs'
import _ from 'lodash'
import {
  branchPTByCompany,
  filterPT_recevieCK,
  listInfoThuocVTChyenKho_Action,
} from '../../../../../store/actions/chuyenKhoAction'
import { chuyenKhoService } from '../../../../../services/ChuyenKho/chuyenKhoService'
import * as XLSX from 'xlsx'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { branchService } from '../../../../../services/branch/branchService'
import { buildDateFilterCondition } from '../../../../../utils/buildDateFilterCondition'
import 'moment/locale/vi'
moment.locale('vi')

const now = new Date()
const dateFormat = 'DD-MM-YYYY'

const Receive = ({ showModal, setStatusUpdate }) => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const { PTReceive, branchPT } = useSelector((state) => state.chuyenKhoReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(id_company)
  const [idChiNhanh, setIdChiNhanh] = useState(BranchLogin)
  const [since, setSince] = useState(now)
  const [toDate, SetToDate] = useState(now)
  const [ListKho, setListKho] = useState([])
  const [searchKho, setSearchKho] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [searchMa, setSearchMa] = useState('')
  const [khoChuyen, setKhoChuyen] = useState('')
  const [searchNguoiXuat, setSearchNguoiXuat] = useState('')
  const [searchDate, setSearchDate] = useState('')
  const [searchNguoiNhan, setSearchNguoiNhan] = useState('')
  const [searchDateNhan, setSearchDateNhan] = useState('')
  const [filterDate, setFilterDate] = useState('=')
  const [filterDateNhan, setFilterDateNhan] = useState('=')
  const [ghiChu, setGhiChu] = useState('')
  const [branhNhan, setBranchNhan] = useState('')
  const [branchChuyen, setBranchChuyen] = useState('')
  const ID_COMPANY = localStorage.getItem('id_company')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [ListBranch, setListBranch] = useState([])

  // set ngày lại từ ngày
  const handleSince = (name) => (date, dateString) => {
    if (name === 'since') {
      setSince(dateString)
    } else {
      SetToDate(dateString)
    }
  }
  const handleShowModalDetail = (idNhapXuat) => {
    showModal()
    dispatch(listInfoThuocVTChyenKho_Action(idNhapXuat))
    setStatusUpdate(false) // tắt trạng thái cho phép update
  }
  const statusChuyenKho = 3 // trạng thái nhận
  const filter = {
    status: statusChuyenKho, //
    since: moment(since, dateFormat).format('YYYY-MM-DD'),
    toDate: moment(toDate, dateFormat).format('YYYY-MM-DD'),
    idChiNhanh: idChiNhanh,
    idCongTy: company,
  }
  // xử lí chọn chi nhánh
  const handleSelected = (value) => {
    setIdChiNhanh(value)
    if (value) {
      fetchKho(value)
    }
    setSearchKho('')
  }
  const filteredData = data?.filter((items) => {
    const findKho = searchKho.toLowerCase()
    const findMa = searchMa.trim().toLowerCase()
    const nameLower = searchName.trim().toLowerCase()
    const nameNhanLower = searchNguoiNhan.trim().toLowerCase()
    const nguoiXuatLower = searchNguoiXuat.trim().toLowerCase()
    const branchNhanLower = branhNhan.trim().toLowerCase()
    const branchChuyenLower = branchChuyen.trim().toLowerCase()
    const ghiChuLower = ghiChu.trim().toLowerCase()
    const khoChuyenLower = khoChuyen.trim().toLowerCase()
    return (
      (items?.tenKhoNhap?.toLowerCase().includes(findKho) || !items?.tenKhoNhap) &&
      (items?.maPhieu?.toLowerCase().includes(findMa) || !items?.maPhieu) &&
      (items?.tenPhieu?.toLowerCase().includes(nameLower) || !items?.tenPhieu) &&
      (items?.tenNVXuat?.toLowerCase().includes(nguoiXuatLower) || !items?.tenNVXuat) &&
      (items?.tenNVNhan?.toLowerCase().includes(nameNhanLower) || !items?.tenNVNhan) &&
      (items?.noiDung?.toLowerCase().includes(ghiChuLower) || !items?.noiDung) &&
      (items?.tenKhoXuat?.toLowerCase().includes(khoChuyenLower) || !items?.tenKhoXuat) &&
      buildDateFilterCondition(filterDate, searchDate, items?.ngayXuat) &&
      buildDateFilterCondition(filterDateNhan, searchDateNhan, items?.ngayNhan) &&
      (items?.tenChiNhanhXuat?.toLowerCase().includes(branchChuyenLower) ||
        !items?.tenChiNhanhXuat) &&
      (items?.tenChiNhanhNhan.toLowerCase().includes(branchNhanLower) || !items?.tenChiNhanhNhan)
    )
  })
  const handleCompany = (value) => {
    dispatch(branchPTByCompany(value))
    setCompany(value)
    setIdChiNhanh(null)
  }
  // xử lí lọc dưc liệu
  const handleFilter = () => {
    dispatch(filterPT_recevieCK(filter, setIsLoading))
  }
  //---------- lấy danh sách kho chi nhánh-------------//
  const fetchKho = async (id) => {
    try {
      const { data } = await chuyenKhoService.getlistKhoNhan(id)
      setListKho(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    dispatch(filterPT_recevieCK(filter, setIsLoading))
    dispatch(branchPTByCompany(id_company))
    fetchKho(BranchLogin)
    fetchBranch()
  }, [])
  useEffect(() => {
    const sortedData = _.orderBy(PTReceive, 'ngayXuat', 'desc')
    setData(sortedData)
  }, [PTReceive])

  const exportToExcel = () => {
    const headers = [
      'Mã phiếu',
      'Tên phiếu',
      'Người nhận',
      'Ngày nhận',
      'Chi nhánh nhận',
      'Kho nhận',
      'Người chuyển',
      'Ngày chuyển',
      'Chi nhánh chuyển',
      'Kho chuyển',
      'Ghi chú',
    ]

    const formattedData = filteredData.map((item) => ({
      'Mã phiếu': item.maPhieu,
      'Tên phiếu': item.tenPhieu,
      'Người nhận': item.tenNVNhan,
      'Ngày nhận': moment(item.ngayNhan).format('DD/MM/YYYY hh:mm:ss'),
      'Chi nhánh nhận': item.tenChiNhanhNhan,
      'Kho nhận': item.tenKhoNhap,
      'Người chuyển': item.tenNVXuat,
      'Ngày chuyển': moment(item.ngayXuat).format('DD/MM/YYYY hh:mm:ss'),
      'Chi nhánh chuyển': item.tenChiNhanhXuat,
      'Kho chuyển': item.tenKhoXuat,
      'Ghi chú': item.noiDung,
    }))

    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, 'Phiếu chuyển kho - Phiếu đã nhận.xlsx')
  }

  const fetchBranch = async () => {
    const { data } = await branchService.getListBranch(ID_COMPANY)
    setListBranch(data)
  }

  const compare = (a, b, dataIndex) => {
    if (a[dataIndex] < b[dataIndex]) {
      return -1
    }
    if (a[dataIndex] > b[dataIndex]) {
      return 1
    }
    return 0
  }

  const PhepTinh = [
    { label: '>', value: '>' },
    { label: '<', value: '<' },
    { label: '=', value: '=' },
  ]

  const handleSearch = (name) => (e) => {
    const { value } = e.target
    switch (name) {
      case 'ma':
        setSearchMa(value || '')
        break
      case 'TENPHIEU':
        setSearchName(value || '')
        break
      case 'NGUOICHUYEN':
        setSearchNguoiXuat(value || '')
        break
      case 'NOICHUYEN':
        setBranchChuyen(value || '')
        break
      case 'NGUOINHAN':
        setSearchNguoiNhan(value || '')
        break
      case 'NGAYNHAN':
        setSearchDateNhan(value || '')
        break
      case 'GHICHU':
        setGhiChu(value || '')
        break
      case 'KHOCHUYEN':
        setKhoChuyen(value || '')
        break
      // case 'quyCach':
      //   setSearchQuyCach(value || '')
      //   break
      // case 'tongXuat':
      //   setSearchXuat(value || '')
      //   break
      // case 'tongNhap':
      //   setSearchNhap(value || '')
      //   break
      // case 'SLChan':
      //   setSearchSLChan(value || '')
      //   break
      // case 'tonKho':
      //   setSearchTon(value || '')
      //   break
      // case 'soLo':
      //   setSearchSoLo(value || '')
      //   break
      // case 'soHoaDon':
      //   setSearchHD(value || '')
      //   break
      default:
        break
    }
  }

  const handleSelectedKhoNhan = (value) => {
    setSearchKho(value || '')
  }

  const handleSelectedBranhNhan = (value, option) => {
    setBranchNhan(value || '')
    if (value) {
      fetchKho(option.id)
    }
    setSearchKho('')
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'MAPHIEU',
      key: 'MAPHIEU',
      width: 120,
      sorter: (a, b) => compare(a, b, 'MAPHIEU'),
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('ma')} value={searchMa} />
            </>
          ),
          width: 120,
          dataIndex: 'MAPHIEU',
          key: 1,
        },
      ],
    },
    {
      title: 'Tên phiếu',
      dataIndex: 'TENPHIEU',
      key: 'TENPHIEU',
      sorter: (a, b) => compare(a, b, 'TENPHIEU'),
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('TENPHIEU')} value={searchName} />
            </>
          ),
          width: 200,
          dataIndex: 'TENPHIEU',
          key: 1,
        },
      ],
    },
    {
      title: 'Người nhận',
      dataIndex: 'NGUOINHAN',
      key: 'NGUOINHAN',
      sorter: (a, b) => compare(a, b, 'NGUOINHAN'),
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('NGUOINHAN')} value={searchNguoiNhan} />
            </>
          ),
          dataIndex: 'NGUOINHAN',
          key: 1,
          width: 160,
        },
      ],
    },
    {
      title: 'Ngày Nhận',
      dataIndex: 'NGAYNHAN',
      key: 'NGAYNHAN',
      width: 120,
      sorter: (a, b) => compare(a, b, 'NGAYNHAN'),
      children: [
        {
          title: (
            <div className="flex gap-1">
              <Select
                onChange={(value) => setFilterDateNhan(value)}
                value={filterDateNhan}
                size="small"
                className="w-16"
                options={PhepTinh}
              />
              <DatePicker
                value={searchDateNhan !== '' ? dayjs(searchDateNhan, 'YYYY-MM-DD') : ''}
                size="small"
                format={'DD/MM/YYYY'}
                onChange={(date, DateString) => {
                  setSearchDateNhan(
                    DateString ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
                  )
                }}
              />
            </div>
          ),
          dataIndex: 'NGAYNHAN',
          key: 2,
          width: 160,
        },
      ],
    },
    {
      title: 'Chi nhánh nhận',
      dataIndex: 'chiNhanhNhan',
      key: 'chiNhanhNhan',
      sorter: (a, b) => compare(a, b, 'chiNhanhNhan'),
      children: [
        {
          title: (
            <>
              <Select
                size="small"
                className="w-full"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                onChange={handleSelectedBranhNhan}
                value={branhNhan}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...ListBranch?.map((item) => ({
                    label: item.tenChiNhanh,
                    value: item.tenChiNhanh,
                    id: item.idChiNhanh,
                  })),
                ]}
              />
            </>
          ),
          dataIndex: 'chiNhanhNhan',
          key: 2,
          width: 190,
        },
      ],
    },
    {
      title: 'Kho nhận',
      dataIndex: 'KHONHAN',
      key: 'KHONHAN',
      width: 150,
      sorter: (a, b) => compare(a, b, 'KHONHAN'),
      children: [
        {
          title: (
            <>
              <Select
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                size="small"
                value={searchKho}
                showSearch
                onChange={handleSelectedKhoNhan}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...ListKho.map((item) => ({
                    label: item.tenKho,
                    value: item.tenKho,
                    key: item.tenKho, // Thêm key cho mỗi tùy chọn
                  })),
                ]}
                className="w-full"
              />
            </>
          ),
          dataIndex: 'KHONHAN',
          key: 2,
          width: 150,
        },
      ],
    },
    {
      title: 'Người chuyển',
      dataIndex: 'NGUOICHUYEN',
      key: 'NGUOICHUYEN',
      sorter: (a, b) => compare(a, b, 'NGUOICHUYEN'),
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('NGUOICHUYEN')} value={searchNguoiXuat} />
            </>
          ),
          dataIndex: 'NGUOICHUYEN',
          key: 1,
          width: 160,
        },
      ],
    },
    {
      title: 'Ngày chuyển',
      dataIndex: 'NGAYCHUYEN',
      key: 'NGAYCHUYEN',
      width: 120,
      sorter: (a, b) => compare(a, b, 'NGAYCHUYEN'),
      children: [
        {
          title: (
            <div className="flex gap-1">
              <Select
                onChange={(value) => setFilterDate(value)}
                value={filterDate}
                size="small"
                className="w-16"
                options={PhepTinh}
              />
              <DatePicker
                value={searchDate !== '' ? dayjs(searchDate, 'YYYY-MM-DD') : ''}
                size="small"
                format={'DD/MM/YYYY'}
                onChange={(date, DateString) => {
                  setSearchDate(
                    DateString ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
                  )
                }}
              />
            </div>
          ),
          dataIndex: 'NGAYCHUYEN',
          key: 2,
          width: 160,
        },
      ],
    },
    {
      title: 'Chi nhánh chuyển',
      dataIndex: 'chiNhanhChuyen',
      key: 'chiNhanhChuyen',
      sorter: (a, b) => compare(a, b, 'chiNhanhChuyen'),
      children: [
        {
          title: (
            <>
              <Select
                size="small"
                className="w-full"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                onChange={(value) => setBranchChuyen(value)}
                value={branchChuyen}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...ListBranch?.map((item) => ({
                    label: item.tenChiNhanh,
                    value: item.tenChiNhanh,
                    id: item.idChiNhanh,
                  })),
                ]}
              />
            </>
          ),
          dataIndex: 'chiNhanhChuyen',
          key: 2,
          width: 190,
        },
      ],
    },
    {
      title: 'Kho chuyển',
      dataIndex: 'KHOCHUYEN',
      key: 'KHOCHUYEN',
      width: 150,
      sorter: (a, b) => compare(a, b, 'KHOCHUYEN'),
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('KHOCHUYEN')} value={khoChuyen} />
            </>
          ),
          dataIndex: 'KHOCHUYEN',
          key: 2,
          width: 150,
        },
      ],
    },
    {
      title: 'Ghi chú',
      dataIndex: 'GHICHU',
      key: 'GHICHU',
      width: 200,
      sorter: (a, b) => compare(a, b, 'GHICHU'),
      children: [
        {
          title: (
            <>
              <Input size="small" onChange={handleSearch('GHICHU')} value={ghiChu} />
            </>
          ),
          dataIndex: 'GHICHU',
          key: 1,
          width: 160,
        },
      ],
    },
    {
      title: 'Hành động',
      dataIndex: 'ACTION',
      key: 'ACTION',
      width: 100,
      align: 'center',
      fixed: 'right',
    },
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <div className="h-full">
      <div className="flex gap-5">
        <div className="w-80 flex gap-1">
          <Select
            onChange={handleCompany}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={company}
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
          />
        </div>
        <div className="w-72 flex gap-1">
          <Select
            onChange={handleSelected}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={idChiNhanh}
            options={[
              { label: 'Tất cả', value: null },
              ...branchPT?.map(({ idChiNhanh, tenChiNhanh }) => ({
                label: tenChiNhanh,
                value: idChiNhanh,
              })),
            ]}
            className="w-full"
          />
        </div>
        <div className="w-52">
          <Select
            onChange={(value) => setSearchKho(value)}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={searchKho}
            options={[
              { label: 'Tất cả', value: '' },
              ...ListKho?.map((item) => ({
                label: item.tenKho,
                value: item.tenKho,
              })),
            ]}
            className="w-full"
          />
        </div>
        <div className="flex gap-3">
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleSince('since')}
              format={dateFormat}
            />
          </div>
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              minDate={dayjs(since, dateFormat)}
              onChange={handleSince('toDate')}
              format={dateFormat}
            />
          </div>
        </div>
        <div
          className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
          onClick={handleFilter}
        >
          <CachedIcon sx={{ fontSize: 25 }} />
        </div>
        <div className="flex gap-3 ml-auto">
          <Button
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div
        className=" mt-2 "
        // style={{
        //     boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        // }}
      >
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 12,
            },
          }}
        >
          <Table
            loading={isLoading}
            pagination={false}
            scroll={{
              x: 1400,
              y: 660,
            }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredData?.map((items, index) => ({
              STT: ++index,
              MAPHIEU: items.maPhieu,
              TENPHIEU: items.tenPhieu,
              NGUOINHAN: items.tenNVNhan,
              NGAYNHAN: moment(items.ngayNhan).format('DD/MM/YYYY HH:mm:ss'),
              chiNhanhNhan: items.tenChiNhanhNhan,
              KHONHAN: items.tenKhoNhap,
              chiNhanhChuyen: items.tenChiNhanhXuat,
              NGUOICHUYEN: items.tenNVXuat,
              NGAYCHUYEN: moment(items.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
              KHOCHUYEN: items.tenKhoXuat,
              GHICHU: items.noiDung,
              ACTION: (
                <Tooltip title="Xem chi tiết" color="#108ee9">
                  <EyeOutlined
                    onClick={() => handleShowModalDetail(items.idNhapXuat)}
                    className="text-xl text-[#108ee9]  cursor-pointer"
                  />
                </Tooltip>
              ),
            }))}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Receive
