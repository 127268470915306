import React, { useEffect, useState } from 'react'
import { DatePicker, Table, Select, TreeSelect, ConfigProvider, Button } from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import Chart from 'react-apexcharts'
import { optionKieuToa, optionLyDoDuoc, optionToTalLyDo } from './configDasboard'
import moment from 'moment'
import dayjs from 'dayjs'
import { reportService } from '../../../../services/Report/reportService'
import { useDispatch, useSelector } from 'react-redux'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { getBranchNotVP } from '../../../../store/actions/BranchAction'
import { drugstoreService } from '../../../../services/drugstore/drugstoreService'
import { childrenClinicService } from '../../../../services/childrenClinic/childrenClinicService'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
moment.locale('vi')
const { RangePicker } = DatePicker
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const { SHOW_PARENT } = TreeSelect
const ThongKe = () => {
  const id_company = Number(localStorage.getItem('id_company'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const [date, setDate] = useState({ from: now, to: now })
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState([BranchLogin])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(id_company)
  const [TongLyDo, setTongLyDo] = useState([])
  const [LydoDuoc, setLyDoDuoc] = useState([])
  const [LydoBS, setLyDoBS] = useState([])
  const [Phieu, setPhieu] = useState([])
  const [totalType, setTotalType] = useState({ duoc: 0, BS: 0 })
  const [countLyDo, setCountLydo] = useState()
  const [countDuoc, setCountDuoc] = useState([])
  const [countBS, setCountBS] = useState([])
  const [DanhSachDuoc, setDanhSachDuoc] = useState([])
  const [DanhSachBS, setDanhSachBS] = useState([])
  const dispatch = useDispatch()
  const handelDatePicker = (date, dateString) => {
    const formatDateValue1 = moment(dateString[0], dateFormat).format(momentDate)
    const formatDateValue2 = moment(dateString[1], dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, from: formatDateValue1 }))
    setDate((prevSate) => ({ ...prevSate, to: formatDateValue2 }))
  }
  const fetLyDo = async () => {
    const { data } = await reportService.getAllLyDoLayThuoc()
    const uniqueLyDo = [...new Set(data.map((i) => i.lydo))]
    setTongLyDo(uniqueLyDo)
  }
  const fetLyDoDuoc = async () => {
    const { data } = await drugstoreService.getLyDoKhongLayThuoc()
    setLyDoDuoc(data.map((i) => i.lydo))
  }
  const fetLyDoBS = async () => {
    const { data } = await childrenClinicService.getListLyDoKhongKeToa()
    setLyDoBS(data.map((i) => i.lydo))
  }
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const exportToExcelDuoc = () => {
    const formatData = DanhSachDuoc?.map((item) => ({
      'Ngày khám': item?.ngaykham ? moment(item?.ngaykham).format('DD/MM/YYYY HH:mm:ss') : '-',
      'Tên chi nhánh': item?.tenchinhanh,
      'Dược sĩ': item?.duocsi,
      'Mã bệnh nhân': item?.mabenhnhan,
      'Tên bệnh nhân': item?.tenbenhnhan,
      'Lý do': item?.lydo,
      'Kiểu toa': 'Khách không lấy toa',
    }))
    const name = 'Danh sách khách hàng không lấy thuốc dược '
    exportExcelformat(formatData, name)
  }
  const exportToExcelBacSi = () => {
    const formatData = DanhSachBS?.map((item) => ({
      'Ngày khám': item?.ngaykham ? moment(item?.ngaykham).format('DD/MM/YYYY HH:mm:ss') : '-',
      'Tên chi nhánh': item?.tenchinhanh,
      'Bác sĩ': item?.bskedon,
      'Mã bệnh nhân': item?.mabenhnhan,
      'Tên bệnh nhân': item?.tenbenhnhan,
      'Lý do': item?.lydo,
      'Kiểu toa': 'Bác sĩ không kê toa',
    }))
    const name = 'Danh sách khách hàng không lấy thuốc bác sĩ '
    exportExcelformat(formatData, name)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn công ty ',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const onChangeCompany = (value) => {
    setCompany(value)
    setValue([''])
    setIdChuyenKhoa('')
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  const fetchReport = async () => {
    try {
      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        idChiNhanhs = value.map((id) => `&idcn=${id}`).join('&')
        const { data } = await reportService.getDanhSachBenhNhanKhongLayThuoc(
          company,
          idChuyenKhoa,
          idChiNhanhs,
          date.from,
          date.to,
        )
        setPhieu(data)
      } else {
        const { data } = await reportService.getDanhSachBenhNhanKhongLayThuoc(
          company,
          idChuyenKhoa,
          '',
          date.from,
          date.to,
        )
        setPhieu(data)
      }
    } catch (error) {
      console.log(error)
    } finally {
    }
  }
  useEffect(() => {
    dispatch(getBranchNotVP(company, setBranch))
  }, [company])
  useEffect(() => {
    fetLyDo()
    fetchChuyenKhoa()
    fetchReport()
    fetLyDoDuoc()
    fetLyDoBS()
  }, [])
  useEffect(() => {
    const totalDuoc = Phieu?.filter((i) => i.kieutoa === 1).length
    const totalBS = Phieu.length - totalDuoc
    setTotalType((prevState) => ({ ...prevState, duoc: totalDuoc }))
    setTotalType((prevState) => ({ ...prevState, BS: totalBS }))
  }, [Phieu])
  useEffect(() => {
    const countLyDo = TongLyDo.map((lydo) => Phieu.filter((p) => p.lydo === lydo).length)
    const sortedData = countLyDo
      .map((count, index) => ({ count, lydo: TongLyDo[index] })) // Ghép count và lý do
      .sort((a, b) => a.count - b.count) // Sắp xếp theo count từ thấp đến cao
    const sortedCounts = sortedData.map((item) => item.count) // Lấy lại mảng count đã sắp xếp
    const sortedLyDos = sortedData.map((item) => item.lydo) // Lấy lại mảng lý do đã sắp xếp
    setCountLydo(sortedCounts)
    setTongLyDo(sortedLyDos)
  }, [Phieu])
  useEffect(() => {
    const Duoc = Phieu.filter((i) => i.kieutoa === 2)
    setDanhSachDuoc(Duoc)
    const BS = Phieu.filter((i) => i.kieutoa === 1)
    setDanhSachBS(BS)
  }, [Phieu])
  useEffect(() => {
    const countLyDo = LydoDuoc.map(
      (lydo) => Phieu.filter((p) => p.lydo === lydo && p.kieutoa === 2).length,
    )
    setCountDuoc(countLyDo)
  }, [Phieu])
  useEffect(() => {
    const countLyDo = LydoBS.map(
      (lydo) => Phieu.filter((p) => p.lydo === lydo && p.kieutoa === 1).length,
    )
    setCountBS(countLyDo)
  }, [Phieu])
  return (
    <div>
      <div className="flex items-center gap-2 mb-2">
        {infoUser?.idNhom !== 4 ? (
          <Select
            showSearch
            value={company}
            onChange={onChangeCompany}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-80"
          />
        ) : (
          ''
        )}
        {infoUser?.idNhom !== 4 ? (
          <Select
            showSearch
            value={idChuyenKhoa}
            onChange={onChangeChuyenKhoa}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { label: 'Tất cả', value: '' },
              ...ChuyenKhoa.map((items) => ({
                label: items.tenChuyenKhoa,
                value: items.idChuyenKhoa,
              })),
            ]}
            className="w-80"
          />
        ) : (
          ''
        )}
        {infoUser?.idNhom !== 4 ? (
          <div className="w-80">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
        ) : (
          ''
        )}
        <RangePicker
          format={dateFormat}
          allowClear={false}
          onChange={handelDatePicker}
          maxDate={dayjs(now)}
          value={[dayjs(date.from, momentDate), dayjs(date.to, momentDate)]}
        />
        <IconButton onClick={fetchReport} color="primary" aria-label="delete" size="small">
          <SyncIcon />
        </IconButton>
      </div>
      <div className="flex gap-10">
        <div className="w-3/4 border rounded">
          <h2 className="text-lg font-semibold border-b p-2">Tổng hợp lý do</h2>
          <div>
            <Chart
              options={{
                ...optionToTalLyDo,
                xaxis: {
                  categories: TongLyDo,
                },
              }}
              series={[{ data: countLyDo }]}
              type="bar"
              width="100%"
              height={320}
            />
          </div>
        </div>
        <div className="w-1/4 border rounded">
          <h2 className="text-lg font-semibold border-b p-2">Tổng kiểu toa</h2>
          <Chart
            options={optionKieuToa}
            series={[totalType.duoc, totalType.BS]}
            type="donut"
            width="100%"
            height={320}
          />
        </div>
      </div>
      <div className="flex gap-10 mt-10">
        <div className="w-1/2  border rounded">
          <h2 className="text-lg font-semibold border-b flex items-center justify-between p-2">
            Danh sách Dược{' '}
            <Button
              onClick={exportToExcelDuoc}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </h2>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              scroll={{
                x: 1300,
                y: 320,
              }}
              pagination={false}
              dataSource={DanhSachDuoc}
              columns={[
                {
                  title: 'Ngày',
                  width: 60,
                  key: 'ngaykham',
                  dataIndex: 'ngaykham',
                  render: (text) => moment(text).format('DD/MM/YYYY'),
                },
                {
                  title: 'Chi nhánh',
                  width: 150,
                  key: 'tenchinhanh',
                  dataIndex: 'tenchinhanh',
                },
                {
                  title: 'Dược sĩ',
                  width: 150,
                  dataIndex: 'duocsi',
                  key: 'duocsi',
                },
                {
                  title: 'Mã bệnh nhân',
                  width: 100,
                  dataIndex: 'mabenhnhan',
                  key: 'mabenhnhan',
                },
                {
                  title: 'Tên bệnh nhân',
                  width: 150,
                  dataIndex: 'tenbenhnhan',
                  key: 'tenbenhnhan',
                },
                {
                  title: 'Lý do',
                  width: 200,
                  dataIndex: 'lydo',
                  key: 'lydo',
                },
              ]}
            />
          </ConfigProvider>
        </div>
        <div className="w-1/2  border rounded">
          <h2 className="text-lg font-semibold border-b p-2">Lý do dược </h2>
          <Chart
            options={{
              ...optionLyDoDuoc,
              xaxis: {
                categories: LydoDuoc,
              },
            }}
            series={[{ data: countDuoc }]}
            type="bar"
            width="100%"
            height={320}
          />
        </div>
      </div>
      <div className="flex gap-10 mt-10">
        <div className="w-1/2  border rounded">
          <h2 className="text-lg font-semibold border-b flex items-center justify-between p-2">
            Danh sách Bác sĩ{' '}
            <Button
              onClick={exportToExcelBacSi}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </h2>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              scroll={{
                x: 1300,
                y: 320,
              }}
              pagination={false}
              dataSource={DanhSachBS}
              columns={[
                {
                  title: 'Ngày',
                  width: 60,
                  key: 'ngaykham',
                  dataIndex: 'ngaykham',
                  render: (text) => moment(text).format('DD/MM/YYYY'),
                },
                {
                  title: 'Chi nhánh',
                  width: 150,
                  key: 'tenchinhanh',
                  dataIndex: 'tenchinhanh',
                },
                {
                  title: 'Bác sĩ',
                  width: 150,
                  dataIndex: 'bskedon',
                  key: 'bskedon',
                },
                {
                  title: 'Mã bệnh nhân',
                  width: 100,
                  dataIndex: 'mabenhnhan',
                  key: 'mabenhnhan',
                },
                {
                  title: 'Tên bệnh nhân',
                  width: 150,
                  dataIndex: 'tenbenhnhan',
                  key: 'tenbenhnhan',
                },
                {
                  title: 'Lý do',
                  width: 200,
                  dataIndex: 'lydo',
                  key: 'lydo',
                },
              ]}
            />
          </ConfigProvider>
        </div>
        <div className="w-1/2  border rounded">
          <h2 className="text-lg font-semibold border-b p-2">Lý do bác sĩ</h2>
          <Chart
            options={{
              ...optionLyDoDuoc,
              xaxis: {
                categories: LydoBS,
              },
            }}
            series={[{ data: countBS }]}
            type="bar"
            width="100%"
            height={320}
          />
        </div>
      </div>
    </div>
  )
}

export default ThongKe
