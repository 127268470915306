import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, DatePicker, Input, Select, Table } from 'antd'
import { IconButton } from '@mui/material'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { https } from '../../../services/apiService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import dayjs from 'dayjs'
import * as XLSX from 'xlsx'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const formatMoment = 'YYYY-MM'
const dateFormat = 'MM-YYYY'
function ThongKeMatHang(props) {
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [dateFrom, setDateFrom] = useState(now.format(formatMoment))
  const [dateTo, setDateTo] = useState(now.format(formatMoment))
  const [keyword, setKeyword] = useState('')
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [chiNhanh, setChiNhanh] = useState('')
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat).format(formatMoment)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const getAllCN_By_idct = async (value) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetChiNhanhByIDCT?idct=${value}`)
      setListChiNhanh(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCompany = (value) => {
    setCompany(value)
    setChiNhanh('')
    getAllCN_By_idct(value)
  }

  const getDataAsync = async (company, chinhanh, tuNgay, denNgay, keyword) => {
    setLoading(true)
    try {
      const { data } = await https.get(
        `VPPDatHang/GetThongkeHangHoa?idcn=${chinhanh}&idct=${company}&tuNgay=${tuNgay}&denNgay=${denNgay}&keyword=${keyword}`,
      )
      setData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDataAsync(company, chiNhanh, dateFrom, dateTo, keyword)
    dispatch(listAllCompanyAction())
    getAllCN_By_idct(company)
  }, [])

  const handleChiNhanh = (value) => {
    setChiNhanh(value)
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 80,
      align: 'center',
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 200,
    },
    {
      title: 'Tên hàng hóa',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
    },
    {
      title: 'Số lượng đặt',
      dataIndex: 'soluongdat',
      key: 'soluongdat',
      width: 150,
      align: 'center',
    },
    {
      title: 'Số lượng duyệt',
      dataIndex: 'soluongduyet',
      key: 'soluongduyet',
      width: 150,
      align: 'center',
    },
  ]

  const exportToExcel = () => {
    const headers = ['STT', 'Mã hàng hóa', 'Hàng hóa', 'Số lượng đặt', 'Số lượng duyệt']
    const formattedData = data.map((item, idx) => ({
      STT: idx + 1,
      'Mã hàng hóa': item.mathuoc,
      'Hàng hóa': item.tenbietduoc,
      'Số lượng đặt': item.soluongdat,
      'Số lượng duyệt': item.soluongduyet,
    }))
    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, `Thống kê hàng đặt/duyệt ${dateFrom} - ${dateTo}.xlsx`)
  }

  return (
    <div className="h-full w-full bg-[#EBEBEB] p-2 border rounded-sm ">
      <div className="flex gap-5 justify-between bg-white border-dotted border-b-0 p-4">
        <div className="flex gap-5 justify-between w-full">
          <Select
            showSearch
            className="w-72"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleCompany}
            value={company}
            options={listCompany?.map((items) => ({
              label: items.tenct,
              value: items.idct,
            }))}
          />
          <Select
            showSearch
            className="w-72"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleChiNhanh}
            value={chiNhanh}
            options={[
              {
                tenchinhanh: 'Tất cả',
                idchinhanh: '',
              },
              ...listChiNhanh,
            ].map((items) => ({
              label: items.tenchinhanh,
              value: items.idchinhanh,
            }))}
          />
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('from')}
              format={dateFormat}
              picker="month"
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
              picker="month"
            />
          </div>
          <div className="w-64">
            <Input
              className="w-full"
              placeholder="Nhập từ khóa..."
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              value={keyword}
            />
          </div>
          <div className="flex gap-3 mr-auto">
            <IconButton
              color="primary"
              aria-label="delete"
              size="small"
              onClick={() => getDataAsync(company, chiNhanh, dateFrom, dateTo, keyword)}
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="ml-auto">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-white p-2">
        <div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              bordered
              scroll={{
                y: 800,
              }}
              pagination={false}
              columns={columns}
              dataSource={data}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  )
}

ThongKeMatHang.propTypes = {}

export default ThongKeMatHang
