import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Input, DatePicker, Select, Spin, Radio, ConfigProvider, Button } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined, SyncOutlined } from '@ant-design/icons'
const { TextArea } = Input
const listPhanLoai = [
  {
    value: 1,
    label: 'Loại I',
  },
  {
    value: 2,
    label: 'Loại II',
  },
  {
    value: 3,
    label: 'Loại III',
  },
  {
    value: 4,
    label: 'Loại IV',
  },
  {
    value: 5,
    label: 'Loại V',
  },
  {
    value: 6,
    label: 'Loại khác',
  },
]
function DivFormKham({ labelKham, fieldKham, fieldLoai, fieldGhichu, form, handleChangeForm }) {
  const { infoBNTTC } = useSelector((state) => state.khamSucKhoeReducer)
  return (
    <div className="grid grid-cols-12 items-center text-[12.5px] border rounded-md p-1 bg-slate-50">
      <div className="col-span-5 grid grid-cols-5 items-center gap-1">
        <div className="font-semibold text-end">{labelKham}:</div>
        <TextArea
          className="col-span-4 w-full text-[12.5px]"
          rows={3}
          maxLength={2000}
          value={form[fieldKham]}
          onChange={(e) => handleChangeForm(fieldKham, e.target.value)}
        />
      </div>
      <div className="col-span-5 grid grid-cols-6 items-center gap-1">
        <div className="font-semibold text-end">Phân loại:</div>
        <div className="col-span-5 w-full border rounded-md pl-2 pr-0 py-[15px] bg-white">
          <Radio.Group
            value={infoBNTTC ? form[fieldLoai] : null}
            onChange={(e) => handleChangeForm(fieldLoai, e.target.value)}
            className="grid grid-cols-6"
          >
            {listPhanLoai?.map((item) => (
              <Radio value={item?.value} className="text-[11.6px] flex items-center">
                {item?.label}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>
      <div className="col-span-2 grid grid-cols-3 items-center gap-1">
        <div className="font-semibold text-end">Ghi chú:</div>
        <TextArea
          className="col-span-2 w-full text-[12.5px]"
          rows={3}
          value={form[fieldGhichu]}
          onChange={(e) => handleChangeForm(fieldGhichu, e.target.value)}
          maxLength={2000}
        />
      </div>
    </div>
  )
}

export default DivFormKham
