import React, { useEffect, useState, useRef } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import ToastCus from '../../../../utils/Toast'
// import * as typeAction from '../../../../store/constants/constants'
import { Input } from 'antd'

function SinhHieu({ isEdit, formSinhHieu, setFormSinhHieu }) {
  const [isEditSinhHieu, setIsEditSinhHieu] = useState(null)
  const handleChangeSinhHieu = (field, value) => {
    // console.log(field, value)
    setFormSinhHieu((prev) => ({
      ...prev,
      [field]: ['chieucao', 'cannang'].includes(field) ? (value >= 0 ? value : 0) : value,
    }))
    // dispatch({
    //   type: typeAction.DISPATCH_INFO_BNTTC_KSK,
    //   payload: {
    //     ...infoBNTTC,
    //     [field]: ['chieucao', 'cannang'].includes(field) ? (value >= 0 ? value : 0) : value,
    //   },
    // })
  }
  const calculateBMI = (weight, height) => {
    if (!weight || !height) return 0
    return formatNumberVND(weight / Math.pow(height / 100, 2))
  }
  const divSinhHieu = (name, field, value, unit) => {
    return (
      <>
        <div
          className="bg-white border rounded-lg py-1 px-2 flex justify-between items-center gap-1 text-[13.5px]"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
          onClick={() => formSinhHieu && setIsEditSinhHieu(name)}
        >
          <div className="w-[45%]">{name}</div>
          <div className="w-[55%] font-semibold text-green-700 flex justify-end items-center text-end">
            {isEditSinhHieu === name && field !== 'bmi' && isEdit ? (
              <>
                <Input
                  value={formSinhHieu[field]}
                  className="text-sm leading-5"
                  type={['chieucao', 'cannang'].includes(field) ? 'Number' : ''}
                  size="small"
                  autoFocus
                  onBlur={() => setIsEditSinhHieu(null)}
                  onChange={(e) => handleChangeSinhHieu(field, e.target.value)}
                />
              </>
            ) : (
              <p className="leading-6">
                {value ?? 0}&nbsp;{unit}
              </p>
            )}
          </div>
        </div>
      </>
    )
  }
  return (
    <div className="grid grid-cols-8 gap-2">
      {divSinhHieu('Chiều cao', 'chieucao', formSinhHieu?.chieucao ?? 0, 'Cm')}
      {divSinhHieu('Cân nặng', 'cannang', formSinhHieu?.cannang ?? 0, 'Kg')}
      {divSinhHieu('Mạch', 'mach', formSinhHieu?.mach ?? 0, 'Lần/phút')}
      {divSinhHieu('Huyết áp', 'huyetap', formSinhHieu?.huyetap ?? 0, 'mmHg')}
      {divSinhHieu('Nhiệt độ', 'nhietdo', formSinhHieu?.nhietdo ?? 0, '°C')}
      {divSinhHieu('Nhịp thở', 'nhiptho', formSinhHieu?.nhiptho ?? 0, 'Lần/Phút')}
      {divSinhHieu('Spo2', 'spO2', formSinhHieu?.spO2 ?? 0, '%')}
      {divSinhHieu(
        'BMI',
        'bmi',
        calculateBMI(Number(formSinhHieu?.cannang ?? 0), Number(formSinhHieu?.chieucao ?? 0)),
        '',
      )}
    </div>
  )
}

export default SinhHieu
