import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  ConfigProvider,
  Input,
  Pagination,
  Table,
  Tabs,
  Modal,
  Select,
  Tooltip,
  notification,
} from 'antd'
import {
  SearchOutlined,
  CloudUploadOutlined,
  ContainerOutlined,
  FilterOutlined,
  CheckOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import { https } from '../../../../services/apiService'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import { phanAnhServices } from '../../../../services/phanAnh/phanAnhServices'
import { putNhanXuLyAction, putThongTinXuLyAction } from '../../../../store/actions/phanAnhAction'
import ToastCus from '../../../../utils/Toast'
import { v4 as uuidv4 } from 'uuid'
import style from '../style.module.css'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal

function TabXuLyPhanAnh() {
  const dispatch = useDispatch()
  const now = moment()
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { listBranch, PkDangNhap } = useSelector((state) => state.branchReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [Branch, setBranch] = useState('all')
  const [Company, setCompany] = useState(ID_COMPANY)
  const [isLoading, setIsLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [listXLPA, setListXLPA] = useState([])
  const [infoPAKH, setInfoPAKH] = useState(null)
  const [infoXLPA, setInfoXLPA] = useState(null)
  const [isModal, setIsModal] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [noiDungXL, setNoiDungXL] = useState(EditorState.createEmpty())
  const [selectedFile, setSelectedFile] = useState([])
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const [api, contextHolder] = notification.useNotification()

  const handleResetForm = () => {
    setSelectedFile([])
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clear the input
    }
  }
  const handleNhanXuLy = async () => {
    dispatch(
      putNhanXuLyAction(infoXLPA, infoPAKH, getInfoPhanAnhVaXuLy, getListXuLyPhanAnh, setIsLoading),
    )
  }
  const handleSubmit = () => {
    const formData = new FormData()
    formData.append('IDXL', infoXLPA?.idxl)
    formData.append('BOPHANTIEPNHAN', infoXLPA?.idbophantiepnhan)
    formData.append('NGUOISUA', infoUser?.idnv)

    const contentState = noiDungXL.getCurrentContent()
    const row = convertToRaw(contentState)
    // const {blocks,...other} = row
    const dataNoiDung = JSON.stringify(row)
    formData.append('NOIDUNG', dataNoiDung)

    if (selectedFile?.length !== 0) {
      const dataHinhAnh = selectedFile.map((item) => item.file)
      dataHinhAnh?.forEach((file, index) => {
        formData.append('HINHANHS', file)
      })
      // const formDataObj = Object.fromEntries(formData.entries())
      // console.log(formDataObj)
      // for (let pair of formData.entries()) {
      //   console.log(pair)
      // }
      // console.log(formData)
    }
    dispatch(
      putThongTinXuLyAction(
        formData,
        infoXLPA,
        infoPAKH,
        getInfoPhanAnhVaXuLy,
        getListXuLyPhanAnh,
        setIsLoading,
        handleResetForm,
      ),
    )
  }
  const openNotificationWithIcon = () => {
    api.error({
      message: 'Chọn file đính kèm',
      description: 'Chỉ chấp nhận tệp là hình ảnh',
    })
  }
  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
    handleFileChange(e.dataTransfer.files[0])
    // const files = Array.from(e.dataTransfer.files)
    //  setFileList(files);
  }
  const handleFileSelect = (e) => {
    // let file = e.target.files[0]
    handleFileChange(e.target.files[0])
  }
  const handleFileChange = async (file) => {
    //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp']
    if (!allowedFileTypes.includes(file.type)) {
      openNotificationWithIcon()
      fileInputRef.current.value = '' // clear the input
      return
    }
    // formik.setFieldValue('HoaDonFile', file)
    // console.log(formData);
    fileInputRef.current.value = ''
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      const localUrl = URL.createObjectURL(file)
      setSelectedFile((preState) => [
        ...preState,
        { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
      ])
    }
  }
  const onChangeNoiDungXuLy = (newState) => {
    if (infoXLPA?.idtrangthaixuly === 2) setNoiDungXL(newState)
  }
  //-------- lấy danh sách xử lý phản ảnh -------//
  const getListXuLyPhanAnh = async () => {
    try {
      setIsLoading(true)
      const { data } = await phanAnhServices.getListXuLyPhanAnh(
        keyword,
        Branch === 'all' ? null : Branch,
        Company,
        infoUser?.idChiNhanh, // chi nhánh bộ phận của user
      )
      setListXLPA(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  //-------- lấy thông tin phản ảnh khách hàng -------//
  const getInfoPhanAnhVaXuLy = async (idpakh, idxl) => {
    try {
      setIsLoading(true)
      const { data } = await phanAnhServices.getInfoPhanAnh(idpakh)
      setInfoPAKH(data)
      if (data?.DanhSachXuLyPhanAnh?.length) {
        const infoxl = data?.DanhSachXuLyPhanAnh?.find((item) => item?.idxl === idxl)
        setInfoXLPA(infoxl)
        if (infoxl?.noidung) {
          const convertJson = JSON.parse(infoxl?.noidung)
          const contentState = convertFromRaw(convertJson)
          setNoiDungXL(EditorState.createWithContent(contentState))
        } else {
          setNoiDungXL(null)
        }
      } else {
        setIsModal(false)
        setInfoXLPA(null)
        setNoiDungXL(null)
      }
      if (data?.NOIDUNG) {
        const convertJson = JSON.parse(data?.NOIDUNG)
        const contentState = convertFromRaw(convertJson)
        setEditorState(EditorState.createWithContent(contentState))
      } else {
        setEditorState(null)
      }
      //await getListBoPhanXuLy()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getListXuLyPhanAnh()
  }, [])
  const columnsXL = [
    {
      title: 'STT',
      dataIndex: '',
      key: '',
      width: 35,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      width: 140,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaytao ?? '').localeCompare(b?.ngaytao ?? '')
        },
      },
    },
    {
      title: 'Mã phản ảnh',
      dataIndex: 'mapakh',
      key: 'mapakh',
      width: 120,
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.mapakh ?? '').localeCompare(b?.mapakh ?? '')
        },
      },
    },
    {
      title: 'Tên phản ảnh',
      dataIndex: 'tenpakh',
      key: 'tenpakh',
      // width: 350,
      sorter: {
        compare: (a, b) => {
          return (a?.tenpakh ?? '').localeCompare(b?.tenpakh ?? '')
        },
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
      width: 220,
      sorter: {
        compare: (a, b) => {
          return (a?.tennguoitao ?? '').localeCompare(b?.tennguoitao ?? '')
        },
      },
    },
    {
      title: 'Bộ phận tiếp nhận',
      dataIndex: 'bophantiepnhan',
      key: 'bophantiepnhan',
      width: 250,
      sorter: {
        compare: (a, b) => {
          return (a?.tenbophantiepnhan ?? '').localeCompare(b?.tenbophantiepnhan ?? '')
        },
      },
    },
    {
      title: 'Bộ phận xử lý',
      dataIndex: 'bophanxuly',
      key: 'bophanxuly',
      width: 250,
      sorter: {
        compare: (a, b) => {
          return (a?.tenbophanxuly ?? '').localeCompare(b?.tenbophanxuly ?? '')
        },
      },
      // render: (text, record, index) => (
      //   <>
      //     {/* {record?.idxl ? (
      //       record?.bophanxuly
      //     ) : (
      //       <Select
      //         size="small"
      //         className="w-full"
      //         status={!record?.bophanxuly ? 'error' : ''}
      //         value={record?.bophanxuly}
      //         //onChange={(value) => handleChangeBoPhanXL(value, index)}
      //         options={listBoPhanXuLy?.map((item) => ({
      //           value: item?.idChiNhanh,
      //           label: item?.tenChiNhanh,
      //         }))}
      //       />
      //     )} */}
      //   </>
      // ),
    },
    {
      title: 'Ngày xử lý',
      dataIndex: 'ngayxuly',
      key: 'ngayxuly',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngayxuly ?? '').localeCompare(b?.ngayxuly ?? '')
        },
      },
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      key: 'nguoisua',
      width: 250,
      sorter: {
        compare: (a, b) => {
          return (a?.tennguoisua ?? '').localeCompare(b?.tennguoisua ?? '')
        },
      },
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 'ngaysua',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysua ?? '').localeCompare(b?.ngaysua ?? '')
        },
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 250,
      // render: (text, record, index) => (
      //   <>
      //     {record?.idxl ? (
      //       text
      //     ) : (
      //       <Input
      //         size="small"
      //         className="w-full"
      //         value={record?.ghichu}
      //         //onChange={(e) => handleChangeGhiChu(e.target.value, index)}
      //       />
      //     )}
      //   </>
      // ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangthaixuly',
      key: 'trangthaixuly',
      width: 110,
      align: 'center',
      fixed: 'right',
      sorter: {
        compare: (a, b) => {
          return (a?.trangthaixuly ?? '').localeCompare(b?.trangthaixuly ?? '')
        },
      },
      render: (text, record, index) => (
        <>
          <span
            className={`px-2 py-1 rounded-full text-xs font-medium ${(record?.idtrangthaixuly === 1 && 'bg-amber-200 text-amber-800') || (record?.idtrangthaixuly === 2 && 'bg-sky-200 text-sky-800') || (record?.idtrangthaixuly === 3 && 'bg-indigo-200 text-indigo-800') || (record?.idtrangthaixuly === 4 && 'bg-emerald-200 text-emerald-800')}`}
          >
            {text}
          </span>
        </>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 35,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <ul className="flex justify-center items-center gap-2">
          <li className="flex justify-center items-center">
            <ContainerOutlined
              onClick={() => {
                setIsModal(true)
                handleResetForm()
                getInfoPhanAnhVaXuLy(record?.idpakh, record?.idxl)
              }}
              className="text-xl text-blue-500 cursor-pointer"
            />
          </li>
        </ul>
      ),
    },
  ]
  return (
    <div>
      <Modal
        loading={isLoading}
        width={1100}
        open={isModal}
        onCancel={() => setIsModal(false)}
        onOk={() => {
          if (infoXLPA?.idtrangthaixuly === 1) handleNhanXuLy()
          else if (infoXLPA?.idtrangthaixuly === 2) handleSubmit()
          else setIsModal(false)
        }}
        okText={infoXLPA?.idtrangthaixuly === 1 ? 'Nhận xử lý' : 'Lưu'}
        cancelText="Huỷ bỏ"
        title={<p className="text-center">Xử lý phản ảnh khách hàng</p>}
        className="flex flex-col gap-2"
      >
        <div className="grid grid-flow-row gap-2">
          <div className="grid grid-cols-3">
            <div className="grid grid-cols-3 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Mã phản ảnh:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.MAPAKH} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Tên phản ảnh:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.TENPAKH} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Loại phản ảnh:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.TENLOAIPAKH} />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="grid grid-cols-3 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Tên khách hàng:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.NGUOIPHANANH} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Số điện thoại:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.SODIENTHOAI} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Bộ phận xử lý:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.NGUOITIEPNHAN} />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="col-span-2 grid grid-cols-6 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Địa chỉ:</label>
              </div>
              <div className="col-span-5">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.DIACHI} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Người tiếp nhận:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.NGUOITAO} />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="col-span-2 grid grid-cols-6 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Ghi chú:</label>
              </div>
              <div className="col-span-5">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.GHICHU} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Ngày tiếp nhận:</label>
              </div>
              <div className="col-span-2">
                <Input
                  size="small"
                  variant="filled"
                  readOnly
                  value={
                    infoPAKH?.NGAYTAO && moment(infoPAKH?.NGAYTAO).format('DD/MM/YYYY HH:mm:ss')
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Tabs
          items={[
            infoXLPA?.idtrangthaixuly !== 1 && {
              key: 1,
              label: 'Xử lý',
              children: (
                <>
                  <div className="mb-2">
                    {infoXLPA?.idtrangthaixuly === 3 && (
                      <ul className="flex flex-wrap gap-2 mb-2">
                        {infoXLPA?.hinhanh?.split(';')?.map((url, index) => {
                          return (
                            url?.length && (
                              <li
                                key={`hinh${index}:${url}`}
                                className="w-[6.5rem] h-[6.5rem] overflow-hidden border rounded-md relative"
                              >
                                {/* <DeleteOutlined
                // onClick={() => deleteFileById(items.id)}
                className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                style={{ fontSize: 18 }}
              /> */}
                                <a href={url} target="_blank">
                                  <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                                    <div className="relative "></div>
                                    {/* <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                        {`${infoPhieuXuat?.maphieu}-Hinh${++index}`}
                      </p> */}
                                  </div>
                                  <img src={url} className="w-full" alt={url} />
                                </a>
                              </li>
                            )
                          )
                        })}
                      </ul>
                    )}
                    <div className="h-[25rem] p-2 border border-gray-400 mb-2">
                      <Editor
                        editorState={noiDungXL}
                        onEditorStateChange={onChangeNoiDungXuLy}
                        toolbar={{
                          options: [
                            'inline',
                            'blockType',
                            'fontFamily',
                            'fontSize',
                            'textAlign',
                            'colorPicker',
                          ],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                          },
                          blockType: {
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                          },
                          fontFamily: {
                            options: ['Times New Roman'],
                          },
                          fontSize: {
                            options: [14, 16, 18, 24, 30],
                          },
                        }}
                        toolbarClassName={style.toolbarClassName}
                        editorClassName={style.editorClassNameModal}
                      />
                    </div>
                    {(infoXLPA?.idtrangthaixuly === 1 || infoXLPA?.idtrangthaixuly === 2) && (
                      <>
                        <ul className="flex flex-wrap gap-2 mb-2">
                          {selectedFile?.map((items, index) => (
                            <li
                              key={`upHinh${index}`}
                              className="w-[6.5rem] h-[6.5rem] overflow-hidden border rounded-md relative"
                            >
                              <DeleteOutlined
                                onClick={() => deleteFileById(items.id)}
                                className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                                style={{ fontSize: 18 }}
                              />
                              <a href={items?.URL} target="_blank">
                                <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                                  <div className="relative "></div>
                                  <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                                    {items.name}
                                  </p>
                                </div>
                                <img src={items.base64} className="w-full" alt={items.name} />
                              </a>
                            </li>
                          ))}
                        </ul>
                        <div
                          style={{
                            border: '2px dashed #cccccc',
                            borderRadius: '4px',
                            padding: '30px',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                          className="border"
                          ref={dropzoneRef}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                          onClick={handleDivClick}
                        >
                          <CloudUploadOutlined style={{ fontSize: 36 }} className="text-gray-500" />
                          <label style={{ cursor: 'pointer' }}>
                            <p className="font-semibold text-lg text-gray-500">
                              Thả tập tin vào đây hoặc bấm vào để tải lên.
                            </p>
                          </label>
                          <input
                            ref={fileInputRef}
                            id="fileInput"
                            type="file"
                            onChange={handleFileSelect}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              ),
            },
            {
              key: 2,
              label: 'Thông tin',
              children: (
                <>
                  <div className="mb-2">
                    <ul className="flex flex-wrap gap-2">
                      {infoPAKH?.HINHANH?.split(';')?.map((url, index) => {
                        return (
                          url?.length && (
                            <li
                              key={`hinh${index}:${url}`}
                              className="w-[6.5rem] h-[6.5rem] overflow-hidden border rounded-md relative"
                            >
                              {/* <DeleteOutlined
                    // onClick={() => deleteFileById(items.id)}
                    className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                    style={{ fontSize: 18 }}
                  /> */}
                              <a href={url} target="_blank">
                                <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                                  <div className="relative "></div>
                                  {/* <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                            {`${infoPhieuXuat?.maphieu}-Hinh${++index}`}
                          </p> */}
                                </div>
                                <img src={url} className="w-full" alt={url} />
                              </a>
                            </li>
                          )
                        )
                      })}
                    </ul>
                    <div className="h-[25rem] p-2 border border-gray-400 mt-2">
                      <Editor
                        editorState={editorState}
                        //onEditorStateChange={onEditorStateChange}
                        toolbar={{
                          options: [
                            'inline',
                            'blockType',
                            'fontFamily',
                            'fontSize',
                            'textAlign',
                            'colorPicker',
                          ],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                          },
                          blockType: {
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                          },
                          fontFamily: {
                            options: ['Times New Roman'],
                          },
                          fontSize: {
                            options: [14, 16, 18, 24, 30],
                          },
                        }}
                        toolbarClassName={style.toolbarClassName}
                        editorClassName={style.editorClassNameModal}
                      />
                    </div>
                  </div>
                </>
              ),
            },
          ]}
        />
      </Modal>
      <div className="flex justify-between items-center mb-2">
        <div className="flex justify-start items-center gap-2">
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<FilterOutlined />}
            defaultValue={ID_COMPANY}
            options={listCompany?.map((items) => ({
              label: items.tenct,
              value: items.idct,
            }))}
            className="w-[17rem]"
            value={Company}
            onChange={(value) => {
              setCompany(value)
              dispatch(listBranchAction(value))
              setBranch('all')
            }}
          />
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<FilterOutlined />}
            defaultValue={'all'}
            value={Branch}
            options={[
              { label: 'Tất cả', value: 'all' },
              ...(listBranch
                ? listBranch.map((items) => ({
                    label: items.tenChiNhanh,
                    value: items.idChiNhanh,
                  }))
                : []),
            ]}
            className="w-[17rem]"
            onChange={(value) => setBranch(value)}
          />
          <Input
            placeholder="Nhập từ khóa..."
            prefix={<SearchOutlined className="pr-1" />}
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
            value={keyword}
            className="w-[17rem]"
          />
          <IconButton
            onClick={() => {
              setKeyword((prev) => (prev?.length > 0 ? prev?.trim() : prev))
              getListXuLyPhanAnh()
            }}
            disabled={isLoading}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        {/* <Button
            type="primary"
            className="flex justify-center items-center"
            onClick={() => {
              //   setIsModal(true)
            }}
          >
            <PlusOutlined />
            &nbsp;Thêm
          </Button> */}
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
        }}
      >
        <Table
          //   scroll={{ x: 1000 }}
          //   pagination={{
          //     pageSize: 20,
          //     showSizeChanger: false,
          //   }}
          scroll={{ x: 2250, y: 715 }}
          pagination={false}
          loading={isLoading}
          columns={columnsXL}
          dataSource={listXLPA}
          bordered
        />
      </ConfigProvider>
    </div>
  )
}

export default TabXuLyPhanAnh
