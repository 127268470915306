import { ConfigProvider, Table } from 'antd'
import moment from 'moment'
import React, { memo } from 'react'
import 'moment/locale/vi'
import styles from './style.module.css'
moment.locale('vi')
const HistoryTongQuat = memo(({ historyKham }) => {
  return (
    <div className="py-3 h-[66vh]">
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            borderRadius: 0,
            fontSize: 13,
          },
        }}
      >
        <Table
          tableLayout="auto"
          scroll={{
            x: historyKham.length !== 0 ? 'max-content' : null,
            y: '61vh',
          }}
          bordered
          pagination={false}
          dataSource={historyKham}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 1,
              render: (text, _, index) => ++index,
              width: 40,
              align: 'center',
            },
            {
              title: 'Ngày Khám',
              dataIndex: 'ngaykham',
              key: 2,
              width: 80,
              align: 'center',
              // render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
              render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
            },
            {
              title: 'Tái khám',
              dataIndex: 'ngaytaikham',
              key: 3,
              align: 'center',
              // width: 80,
              // render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
              render: (text) => (
                <p className="min-w-[4rem]">{text ? moment(text).format('DD/MM/YYYY ') : '-'}</p>
              ),
            },
            {
              title: 'Loại khám',
              dataIndex: 'tenloaikham',
              key: 4,
              render: (text) => <p className="min-w-[4rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
            {
              title: 'Ngày thuốc',
              dataIndex: 'ngaythuoc',
              key: 5,
              align: 'center',
              className: styles.minWidthColumn,
              render: (text) => <p className="min-w-[4rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
            {
              title: 'Cân nặng',
              dataIndex: 'cannang',
              key: 6,
              width: 80,
              align: 'center',
              render: (text) => <p className="min-w-[4rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
            {
              title: 'Nhiệt độ',
              dataIndex: 'nhietdo',
              key: 7,
              width: 80,
              align: 'center',
              render: (text) => <p className="min-w-[4rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
            {
              title: 'Lý do khám',
              dataIndex: 'lydokham',
              key: 10,
              render: (text) => <p className="min-w-[6rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
            {
              title: 'Bệnh sử',
              dataIndex: 'benhsu',
              key: 11,
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
            {
              title: 'Triệu chứng',
              dataIndex: 'trieuchung',
              key: 12,
              className: styles.minWidthColumn,
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
            {
              title: 'Chẩn đoán',
              dataIndex: 'chandoan',
              key: 8,
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
            {
              title: 'Toa thuốc',
              dataIndex: 'danhSachThuocDTOs',
              key: 9,
              render: (text) => (
                <p className="min-w-[5rem]">
                  {() => {
                    const toaThuoc = text
                      .filter((item) => item.tenbietduoc)
                      .map((item) => item.tenbietduoc + ` ( ${item.soluong} ${item.dvt} )`)
                      .join(', ')
                    return toaThuoc
                  }}
                </p>
              ),
              // render: (text) => {
              //   const toaThuoc = text
              //     .filter((item) => item.tenbietduoc)
              //     .map((item) => item.tenbietduoc + ` ( ${item.soluong} ${item.dvt} )`)
              //     .join(', ')
              //   return toaThuoc
              // },
            },

            {
              title: 'Lời dặn',
              dataIndex: 'loidan',
              key: 13,
              // width: 500,
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
            {
              title: 'Bác sĩ',
              dataIndex: 'bskham',
              key: 14,
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
            {
              title: 'Chi nhánh',
              dataIndex: 'tenchinhanh',
              key: 15,
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
            // { title: 'Tiền sử bản thân', dataIndex: 'tiensu', key: 16 },
            // { title: 'Dị ứng', dataIndex: 'tenchinhanh', key: 17 },
            {
              title: 'Ghi chú',
              dataIndex: 'ghichu',
              key: 18,
              className: styles.minWidthColumn,
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
              // render: (text) => (text ? text : <p className="w-20">&nbsp;</p>),
            },
          ]}
        />
      </ConfigProvider>
    </div>
  )
})

export default HistoryTongQuat
