import { CloseOutlined, ContainerOutlined, SearchOutlined } from '@ant-design/icons'
import { ConfigProvider, Input, Pagination, Select, Table, Tooltip } from 'antd'
import { debounce } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { https } from '../../../services/apiService'
import { recieveService } from '../../../services/receive/recieveService'
import { SuggestDoctor } from '../../../store/actions/childrenClinicAction'
import { listAllCompanyAction } from '../../../store/actions/companyAction'

import {
  DISPATCH_DAN_TOC,
  DISPATCH_DOI_TUONG,
  DISPATCH_NGHE_NGHIEP,
  DISPATCH_QUOC_TICH,
} from '../../../store/constants/constants'
import DrawerTT from './DrawerTT/DrawerTT'
import {
  getInfoBN_Mat_TT_Action,
  getList_BN_BY_CHINHANH_CTY_Mat,
} from '../../../store/actions/traCuuBnMatAction'
import 'moment/locale/vi'
moment.locale('vi')

function BenhAnMat(props) {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { listBN } = useSelector((state) => state.traCuuBnMatReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [chiNhanh, setChiNhanh] = useState(+localStorage.getItem('BRANH_LOGIN'))
  const [totalPage, setTotalPage] = useState(1)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [activeRow, setActiveRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (listBN?.totalPage) {
      setTotalPage(listBN?.totalPage)
    }
  }, [listBN?.totalPage])

  useEffect(() => {
    getDataOption()
  }, [])

  useEffect(() => {
    dispatch(SuggestDoctor())
  }, [])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    dispatch(getList_BN_BY_CHINHANH_CTY_Mat(company, chiNhanh, '', 1, setIsLoading))
    getAllCN_By_idct(company)
  }, [])
  const handleCompany = (value) => {
    setCompany(value)
    setChiNhanh('')
    getAllCN_By_idct(value)
  }

  const getAllCN_By_idct = async (value) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetAllByIdCTNotVanPhong?idCT=${value}`)
      setListChiNhanh(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChiNhanh = (value) => {
    setChiNhanh(value)
    setCurrentPage(1)
    SetSearchParams({ page: 1 })

    dispatch(getList_BN_BY_CHINHANH_CTY_Mat(company, value, search.trim(), 1, setIsLoading))
  }

  const handleSearchInput = (e, company, chiNhanh) => {
    const { value } = e.target
    debounceSearch(value.trim(), company, chiNhanh)
    setSearch(value)
  }

  const debounceSearch = useCallback(
    debounce((keyword, company, chiNhanh) => {
      dispatch(getList_BN_BY_CHINHANH_CTY_Mat(company, chiNhanh, keyword, 1, setIsLoading))
    }, 500),
    [],
  )

  const handleClearSearch = (company, chiNhanh) => {
    setSearch('')
    //get lai data
    dispatch(getList_BN_BY_CHINHANH_CTY_Mat(company, chiNhanh, '', 1, setIsLoading))
  }

  const handleClickRow = (record) => {
    setActiveRow(record)
  }

  const columnsInfoBN = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      key: 'stt',
      width: 60,
    },
    {
      title: 'Mã phiếu thu',
      dataIndex: 'maPhieuThu',
      align: 'left',
      key: 'maPhieuThu',
      width: 150,
    },
    {
      title: 'Mã toa thuốc',
      dataIndex: 'maToaThuoc',
      align: 'left',
      key: 'maToaThuoc',
      width: 150,
    },
    {
      title: 'Ngày khám',
      dataIndex: 'ngayKham',
      align: 'left',
      key: 'ngayKham',
      width: 150,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm') : ''),
    },
    {
      title: 'Mã bệnh nhân',
      dataIndex: 'maBenhNhan',
      align: 'left',
      key: 'maBenhNhan',
      render: (text, record) => (
        <p className="text-blue-500 cursor-pointer" onClick={() => handleOpenDrawer(record)}>
          {text}
        </p>
      ),
      width: 150,
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenBenhNhan',
      key: 'tenBenhNhan',
      align: 'left',
      width: 250,
    },
    {
      title: 'Giới tính',
      dataIndex: 'gioiTinh',
      align: 'center',
      key: 'gioiTinh',
      width: 80,
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaySinh',
      key: 'ngaySinh',
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
      width: 100,
    },
    {
      title: 'Tuổi',
      dataIndex: 'tuoi',
      key: 'tuoi',
      align: 'center',
      width: 180,
    },
    {
      title: 'SĐT',
      dataIndex: 'dienThoai',
      width: 120,
      align: 'center',
      key: 'dienThoai',
      render: (text, record) => (record.idbn === activeRow?.idbn ? <p>{text}</p> : ''),
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diaChi',
      key: 'diaChi',
      width: 800,
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      width: 250,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      fixed: 'right', // Fix the last column
    },
  ]

  const handleChangePage = (company, chiNhanh, search, page) => {
    setCurrentPage(page)
    SetSearchParams({ page })
    dispatch(getList_BN_BY_CHINHANH_CTY_Mat(company, chiNhanh, search, page, setIsLoading))
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleOpenDrawer = (item) => {
    dispatch(getInfoBN_Mat_TT_Action(item.idbn, PkDangNhap?.idChuyenKhoa))
    setOpen(true)
  }

  const getDataOption = async () => {
    try {
      const [NgheNghiep, DanToc, DoiTuong, QuocTich] = await Promise.all([
        recieveService.getNgheNghiep(),
        recieveService.getDanToc(),
        recieveService.getDoiTuong(),
        recieveService.getQuocTich(),
      ])

      dispatch({ type: DISPATCH_NGHE_NGHIEP, payload: NgheNghiep.data })
      dispatch({ type: DISPATCH_DAN_TOC, payload: DanToc.data })
      dispatch({ type: DISPATCH_DOI_TUONG, payload: DoiTuong.data })
      dispatch({ type: DISPATCH_QUOC_TICH, payload: QuocTich.data })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="h-full bg-[#EFEFEF] p-5">
        <div className="h-full bg-white border rounded-xl">
          <div className="w-full border-b flex justify-between">
            <div className="p-2 flex gap-5">
              <Select
                showSearch
                className="w-80"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={handleCompany}
                value={company}
                options={listCompany?.map((items) => ({
                  label: items.tenct,
                  value: items.idct,
                }))}
              />

              <Select
                showSearch
                className="w-80"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                // filterSort={(optionA, optionB) =>
                //   (optionA?.label ?? '')
                //     .toLowerCase()
                //     .localeCompare((optionB?.label ?? '').toLowerCase())
                // }
                onChange={handleChiNhanh}
                value={chiNhanh}
                options={[
                  { label: 'Tất cả', value: '' },
                  ...(listChiNhanh ?? []).map((items) => ({
                    label: items.tenChiNhanh,
                    value: items.idChiNhanh,
                  })),
                ]}
              />
              {/* <Button
                disabled={loadingExport}
                loading={loadingExport}
                onClick={() => exportToExcel(search, chiNhanh, company)}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button> */}
            </div>
            <div className="p-2 flex gap-5 w-[400px]">
              <Input
                value={search}
                placeholder="Tìm kiếm"
                onChange={(e) => handleSearchInput(e, company, chiNhanh)}
                prefix={<SearchOutlined />}
                suffix={
                  search?.length > 0 && (
                    <CloseOutlined onClick={() => handleClearSearch(company, chiNhanh)} />
                  )
                }
              />
            </div>
          </div>

          <div className="mt-2 p-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                  },
                },
              }}
            >
              <Table
                loading={isLoading}
                scroll={{ x: 2200 }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      handleClickRow(record)
                    }, // click row
                  }
                }}
                bordered
                pagination={false}
                columns={columnsInfoBN}
                dataSource={listBN?.data?.map((item, index) => ({
                  stt: (currentPage - 1) * 20 + (index + 1),
                  ...item,
                  maToaThuoc: `MTT${item.maBenhNhan}`,
                  diaChi: item.diaChiDayDu,
                  action: (
                    <ul className="flex gap-2 justify-around">
                      <li className="text-lg">
                        <Tooltip title="Chi tiết" color="#108ee9">
                          <ContainerOutlined
                            onClick={() => handleOpenDrawer(item)}
                            className="text-xl text-[#108ee9]  cursor-pointer"
                          />
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                }))}
              />
            </ConfigProvider>
            <div className="flex justify-end">
              <Pagination
                className="mt-5"
                showSizeChanger={false}
                current={+useSearch.get('page')}
                simple
                defaultCurrent={1}
                pageSize={20}
                total={20 * totalPage}
                onChange={(page, pagesize) => {
                  handleChangePage(company, chiNhanh, search, page)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <DrawerTT open={open} onClose={onClose} company={company} chiNhanh={chiNhanh} />
    </>
  )
}

BenhAnMat.propTypes = {}

export default BenhAnMat
