import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
import { Tabs } from 'antd'
import SieuAm from './SieuAm'
import DienTim from './DienTim'
import XQuang from './XQuang'
import NoiSoi from './NoiSoi'

const FormKQCDHA = () => {
  const [tabForm, setTabForm] = useState(1)
  return (
    <div className="pt-2">
      <Tabs
        value={tabForm}
        onChange={(value) => setTabForm(value)}
        type="card"
        items={[
          {
            key: 1,
            value: 1,
            label: 'Siêu âm',
            children: <SieuAm tabForm={tabForm} />,
          },
          {
            key: 2,
            value: 2,
            label: 'Điện tim',
            children: <DienTim tabForm={tabForm} />,
          },
          {
            key: 3,
            value: 3,
            label: 'X-Quang',
            children: <XQuang tabForm={tabForm} />,
          },
          {
            key: 4,
            value: 4,
            label: 'Nội soi CTC',
            children: <NoiSoi tabForm={tabForm} />,
          },
          {
            key: 5,
            value: 5,
            label: 'Nội soi TH',
            children: <NoiSoi tabForm={tabForm} />,
          },
          {
            key: 6,
            value: 6,
            label: 'Nội soi TMH',
            children: <NoiSoi tabForm={tabForm} />,
          },
        ]}
      />
    </div>
  )
}

export default FormKQCDHA
