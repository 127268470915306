import JsBarcode from 'jsbarcode'
import moment from 'moment/moment'
import { generateLogo } from '../algorithm'
import { formatNumberVND } from '../formatNumberVND'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord } from '../splitWordForReport'

export const generatePhieuChiDinh = (
  infoUser,
  detailBN,
  arrChiDinh,
  pkDangNhap,
  chandoanbandau,
  ngaysudung = null
) => {
  //ngaysudung: Chỉ sử dụng cho in lại mới cần ngày chỉ định (các phiếu còn lại k cần sẽ lấy ngày hiện tại(do các phiếu còn lại k đc in lại))
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  //header
  generateLogo(doc, xPos, pkDangNhap)

  JsBarcode('#barcode', `PCD${detailBN.idbnttc}`, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 35 // Width of the barcode image
  const barcodeX = pageWidth - 45 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 45, 5, 35, 14)
  // Calculate the center position for text
  const text = `PCD${detailBN.idbnttc}`
  const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
  doc.setFontSize(11)
  doc.text(text, textX, 22)

  doc.setFont('times', 'bold')
  doc.setFontSize(13)
  doc.text('PHIẾU CHỈ ĐỊNH', doc.internal.pageSize.width / 2, 30, { align: 'center' })

  doc.setFontSize(11)
  let textWidthName = doc.getTextDimensions('Họ và tên: ').w
  doc.text(detailBN.benhNhan.tenBenhNhan, xPos + textWidthName, 42)
  doc.setFont('times', 'regular')
  doc.text('Họ và tên: ', xPos, 42)

  doc.text(`Ngày sinh: ${moment(detailBN.benhNhan.ngaySinh).format('DD/MM/YYYY')}`, xPos, 47)
  doc.text(`Tuổi: ${detailBN.benhNhan.tuoi ?? ''}`, doc.internal.pageSize.width / 2 - 20, 47)
  const textWidth1 = doc.getTextDimensions(`Giới tính: ${detailBN.benhNhan.gioiTinh ?? ''}`).w
  const x1 = doc.internal.pageSize.width - textWidth1 - 10
  doc.text(`Giới tính: ${detailBN.benhNhan.gioiTinh}`, x1, 47)
  const diaChiBN = `Địa chỉ: ${detailBN.benhNhan.diaChi ?? ''}${detailBN.benhNhan.tenPhuongXa ? ', ' + detailBN.benhNhan.tenPhuongXa : ''}${detailBN.benhNhan.tenQuanHuyen ? ', ' + detailBN.benhNhan.tenQuanHuyen : ''}${detailBN.benhNhan.tenTinhTP ? ', ' + detailBN.benhNhan.tenTinhTP : ''}`
  if (diaChiBN.length > 70) {
    const wordsArray = splitLongWord(diaChiBN, 70)
    doc.text(wordsArray[0], xPos, 52)
    doc.text(wordsArray[1], xPos, 56)
    yPos = 56
  } else {
    doc.text(diaChiBN, xPos, 52)
    yPos = 52
  }
  yPos += 5

  const chanDoanSoBo = `Chẩn đoán sơ bộ: ${chandoanbandau ?? ''}`
  if (chanDoanSoBo.length > 70) {
    const wordsArray = splitLongWord(chanDoanSoBo, 70)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    yPos += 5
  } else {
    doc.text(chanDoanSoBo, xPos, yPos)
  }
  yPos += 5
  //table
  const headers = ['STT', 'Tên chỉ định']

  const data = arrChiDinh.map((item, idx) => [
    idx + 1,
    item.tencdha || item.tendichvu || item.tenxn || item.name,
  ])

  const columnWidths = [20, 110]
  doc.autoTable({
    head: [headers],
    body: data,
    startY: yPos,
    columnStyles: {
      // Set custom column widths
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1], halign: 'left' },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      halign: 'center',
      valign: 'middle',
      fontStyle: 'regular',
      fontSize: 9,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontStyle: 'bold', // Set font weight to bold for headers
      fontSize: 10,
    },
    alternateRowStyles: {
      font: 'times',
      fontStyle: 'regular',
      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'firstPage',
    margin: { left: xPos },
  })

  const tongTien = arrChiDinh.reduce((acc, item) => acc + item.dongia, 0)
  const data2Column = [
    ['', ''],
    ['Tổng tiền: ', formatNumberVND(tongTien)],
  ]
  doc.autoTable({
    head: [data2Column[0]],
    body: data2Column.slice(1),
    startY: doc.autoTable.previous.finalY,
    columnStyles: {
      // Set custom column widths (portrait)
      0: { cellWidth: 100, halign: 'right' },
      1: { cellWidth: 30, halign: 'right', fontStyle: 'bold' },
      // Set custom column widths (landscape)
      // 0: { cellWidth: 160, halign: 'right' },
      // 1: { cellWidth: 30, halign: 'right', fontStyle: 'bold' },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      valign: 'middle',
      fontStyle: 'regular', // Set font weight to bold for headers
      fontSize: 10,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontSize: 10,
    },
    alternateRowStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for alternate rows
    },
    showHead: 'never',
    margin: { left: xPos },
  })

  yPos = doc.autoTable.previous.finalY + 10

  const dateText = `${moment(ngaysudung ?? new Date()).format('hh:mm')}, ngày ${moment(ngaysudung ?? new Date()).format('DD')} tháng ${moment(ngaysudung ?? new Date()).format('MM')} năm ${moment(ngaysudung ?? new Date()).format('YYYY')}`
  const doctorText = infoUser.tenNV ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 15 // 15 is the padding from the right edge

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5

  doc.text('Bác sĩ chỉ định', pageWidth - 52, yPos)
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)

  doc.setFont('times', 'regular')
  doc.setFontSize(8)
  doc.text('(*) Vui lòng đến quầy lễ tân để được hướng dẫn.', xPos, pageHeight - 5)
  // doc.save('Phieu-thu.pdf')
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)

  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
