import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Input, Select } from 'antd'
import { calculateAge } from '../../../../utils/calculateAge'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const InfoBN = ({ tenNoiSoi, infoBnNoiSoi }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const now = useMemo(() => moment(), [infoBnNoiSoi])
  return (
    <div className="grid grid-flow-row pt-5 gap-5">
      <div className="">
        <Divider orientation={'left'} plain style={{ marginTop: 0, marginBottom: 3, fontSize: 15 }}>
          <p className="font-semibold text-blue-500">Thông tin hành chính</p>
        </Divider>
        <div className="grid grid-flow-row px-2 gap-2">
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Tên BN:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnNoiSoi?.tenbenhnhan}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Mã BN:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnNoiSoi?.mabenhnhan}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Ngày sinh:</div>
            <Input
              size="small"
              className="w-full"
              variant="filled"
              readOnly
              value={infoBnNoiSoi?.ngaysinh && moment(infoBnNoiSoi?.ngaysinh).format('DD/MM/YYYY')}
            />
            <div className="font-semibold">Giới tính:</div>
            <Input
              size="small"
              className="w-full"
              variant="filled"
              readOnly
              value={infoBnNoiSoi?.gioitinh}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Tuổi:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnNoiSoi?.ngaysinh && calculateAge(infoBnNoiSoi?.ngaysinh)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Địa chỉ:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnNoiSoi?.diachi}
            />
          </div>
        </div>
      </div>
      <div className="">
        <Divider orientation={'left'} plain style={{ marginTop: 0, marginBottom: 3, fontSize: 15 }}>
          <p className="font-semibold text-blue-500">
            Thông tin phiếu&nbsp;
            {(tenNoiSoi === 'noisoictc' && 'Nội soi CTC') ||
              (tenNoiSoi === 'noisoith' && 'Nội soi TH') ||
              (tenNoiSoi === 'noisoitmh' && 'Nội soi TMH')}
          </p>
        </Divider>
        <div className="grid grid-flow-row px-2 gap-2">
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Ngày nội soi:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={
                (infoBnNoiSoi?.ngaynoisoi &&
                  moment(infoBnNoiSoi?.ngaynoisoi).format('DD/MM/YYYY HH:mm:ss')) ||
                (infoBnNoiSoi && now.format('DD/MM/YYYY HH:mm:ss'))
              }
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">BS chỉ định:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnNoiSoi?.tenbschidinh}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">BS nội soi:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnNoiSoi?.tenbsnoisoi || (infoBnNoiSoi && infoUser?.tenNV)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">CĐ sơ bộ:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={null}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Chỉ định:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnNoiSoi?.loainoisoi}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBN
