import { https } from '../apiService'

export const khamNoiKhoaService = {
  getListBNCho: (idcnpk) =>
    https.get(`BenhNhanKhamNoiKhoa/GetBenhNhanChoKhamNoiKhoa`, { params: { idcnpk } }),
  getListBNDang: (idbskham, tuNgay, denNgay) =>
    https.get(`BenhNhanKhamNoiKhoa/GetBenhNhanDangKhamNoiKhoa`, {
      params: { idbskham, tuNgay, denNgay },
    }),
  getListBNDa: (idbskham, tuNgay, denNgay) =>
    https.get(`BenhNhanKhamNoiKhoa/GetBenhNhanDaKhamNoiKhoa`, {
      params: { idbskham, tuNgay, denNgay },
    }),
}
