import { https } from '../apiService'
export const recieveService = {
  getListCombo: (idcn) => https.get('VoucherDichVu/GetDanhSachCombo', { params: { idcn } }),
  getListCTGiamGia: (idcn) =>
    https.get('VoucherDichVu/GetDanhSachChuongTrinhGiamGia', { params: { idcn } }),
  getNguonKH: () => https.get('NguonKH'),
  getPhongKham: (id) => https.get(`PhongKhamByIDChiNhanh?idChiNhanh=${id}`),
  getNgheNghiep: () => https.get('NgheNghiep'),
  getHinhThucTT: () => https.get('HinhThucTT'),
  getDanToc: () => https.get('DanToc'),
  getDoiTuong: () => https.get('DoiTuong'),
  getListNguoiThan: () => https.get('NguoiNhaBenhNhan/GetAllNguoiNha'),
  getTinhTP: () => https.get('tinh'),
  getQuocTich: () => https.get('QuocTich'),
  getPhuongXa: (idQuan) => https.get(`PhuongXa/${idQuan}`),
  getQuanHuyen: (idTinh) => https.get(`QuanHuyen/${idTinh}`),
  getGroupsChiDinh: (id) => https.get(`DichVuNhom/byChiNhanh?idChiNhanh=${id}`),
  getListChiDinhByGroups: (idNhomDichVu, idChiNhanh, idct) =>
    https.get(`DichVuChiNhanh/ByNhomAndChiNhanh`, { params: { idNhomDichVu, idChiNhanh, idct } }),
  postNewBN: (form) => https.post('BenhNhan', form),
  searchBN: (keyword, idct) =>
    https.get(`BenhNhan/SearchBenhNhanByCT`, {
      params: {
        keyword,
        idct,
      },
    }),
  searchBNByIdctAndKeyword: (keyword, idct) =>
    https.get(`BenhNhan/SearchBenhNhan`, {
      params: {
        keyword,
        idct,
      },
    }),
  getGroupXetNghiemByBranch: (id) => https.get(`XetNghiemNhom/ByIDChiNhanh?idChiNhanh=${id}`),
  getInfoBN: (idBN) => https.get(`BenhNhan/${idBN}`),
  getListXetNghiemByGroups: (idNhomXN, idChiNhanh, idct) =>
    https.get(`XetnghiemChiNhanh/ByNhomXNAndChiNhanh`, { params: { idNhomXN, idChiNhanh, idct } }),
  getGroupsCĐHA: () => https.get('ChanDoanHinhAnhNhom/GetAllNhomChanDoanHinhAnh'),
  getLisChiDinhCĐHA: (id, idct) =>
    https.get(`ChanDoanHinhAnh/GetChanDoanHinhAnhTheoNhom/${id}?idct=${idct}`),
  postBNKham: (form) => https.post('BenhNhanTTC', form),
  postBNXetNghiem: (form) => https.post('BenhNhanXetNghiem', form),
  postBNCDHA: (form) => https.post('BenhNhanCDHA', form),
  postBNDV: (form) => https.post('BenhNhanDichVu', form),
  postBNPay: (form) => https.post('BenhNhanThanhToan', form),
  postDetailPay: (form) => https.post('BenhNhanChiTietTT', form),
  getListBNStatus: (status, idChiNhanh) =>
    https.get(
      `BenhNhanThongTinChung/GetListBenhNhanChoKham?trangthai=${status}&chiNhanh=${idChiNhanh}`,
    ),
  postPaymentCard: (form) => https.post('TheBenhNhan/TruTienTheThanhToan', form),
  updateCancelUseVoucher: (maVoucher) =>
    https.put(`VoucherDanhSach/UpdateCancelVoucher?mavoucher=${maVoucher}`),
  postApplyMemberCard: (form) => https.post('The/ApplyTheThanhVien', form),
  postListCDApplyMemberCard: (list) => https.post('The/ApplyTheThanhVienThuNgan', list),
  addBenhNhanVoucher: (data) => https.post('VoucherBenhNhan/AddVoucherBenhNhan', data),
  addVoucherDoiTac: (form) => https.post('VoucherDanhSach/ThemVoucherDoiTac', form),
  postListDVApplyTTV: (list) => https.post('TheBenhNhan/AddTheThanhToan', list),
  getHitoryBN: (id) => https.get(`BenhNhanTTC/LichSuKhamBenh/${id}`),
  getBNbyIdct: (idct, pageIndex) =>
    https.get(`BenhNhan/GetByCondition?idCongTy=${idct}&pageNumber=${pageIndex}`),

  getListXetNghiemTiepNhan: (idcn) =>
    https.get(`XetNghiemDanhMuc/GetXetNghiemDanhMucByIDCN`, { params: { idcn: idcn } }),
  getListCDHATiepNhan: (idCT) =>
    https.get(`ChanDoanHinhAnh/GetAllChanDoanHinhAnh`, { params: { idCT } }),
  getDanhSachTaiKham: (idChiNhanh, fromDate, toDate) =>
    https.get('BenhNhanLienHeTaiKham/GetDanhSachLienHeTaiKham', {
      params: {
        idChiNhanh,
        fromDate,
        toDate,
      },
    }),
  updateStatusBN: (maphieuthu, trangthai) =>
    https.put(
      `BenhNhanThongTinChung/CapNhatTrangThaiBNTTC?maphieuthu=${maphieuthu}&trangthai=${trangthai}`,
    ),
  postApplyVoucherTiepNhan: (list) => https.post('VoucherDanhSach/ApplyVoucher', list),
  updateBNTaiKham: (form) => https.put('BenhNhanLienHeTaiKham/UpdateLienHeTaiKham', form),
  getLichSuTongQuat: (idbn) => https.get(`BenhNhan/GetLichSuKhamTongQuat?IDBN=${idbn}`),
  deleteDichVuBN: (id) => https.delete(`BenhNhanDichVu/Delete?idBNDV=${id}`),
  deleteXetNghiemBN: (id) => https.delete(`BenhNhanXetNghiem/DeleteBenhNhanXetNghiem?idbnxn=${id}`),
  deleteCDHABN: (id) => https.delete(`BenhNhanCDHA/DeleteBenhNhanCDHA?idbncdha=${id}`),
  getInfoNguoiThan: (idBN, NguoiNha) =>
    https.get(`BenhNhan/GetNguoiNhaByIDBN?idBN=${idBN}&NguoiNha=${NguoiNha}`),
  getUuDaiTaiKhamBN:(IDBN,IDCHINHANH) => https.get(`UuDaiTaiKham/GetUuDaiTheoBenhNhanChiNhanh?IDBN=${IDBN}&IDCHINHANH=${IDCHINHANH}`)
}
