import {
  ConfigProvider,
  DatePicker,
  Select,
  Table,
  Tabs,
  Space,
  Input,
  Button as ButtonAntd,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons'
import IconButton from '@mui/material/IconButton'
import Highlighter from 'react-highlight-words'
import CachedIcon from '@mui/icons-material/Cached'
import { branchService } from '../../../../services/branch/branchService'
import moment from 'moment'
import dayjs from 'dayjs'
import { sieuAmService } from '../../../../services/SieuAm/sieuAmService'
import { useDispatch } from 'react-redux'
import { infoBNSieuAm, infoBnPending } from '../../../../store/actions/sieuAmAction'
import 'moment/locale/vi'
moment.locale('vi')
const formatDB = 'YYYY-MM-DD'
const today = moment()
const formatDate = 'DD/MM/YYYY'
const Customer = ({ BNPending, BNdone, fetchBnDone, setListImagePrint, fetchBNPending }) => {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [idBranch, setIdBranch] = useState(ID_BRANCH)
  const [dateForm, setDateForm] = useState(today.format(formatDB))
  const [dateTo, setDateTo] = useState(today.format(formatDB))

  const [branch, setBranch] = useState([])
  const dispatch = useDispatch()

  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <ButtonAntd
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </ButtonAntd>
          <ButtonAntd
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </ButtonAntd>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
    // searchedColumn === dataIndex ? (
    //   <Highlighter
    //     highlightStyle={{
    //       backgroundColor: '#ffc069',
    //       padding: 0,
    //     }}
    //     searchWords={[searchText]}
    //     autoEscape
    //     textToHighlight={text ? text.toString() : ''}
    //   />
    // ) : (
    //   text
    // ),
  })
  //----------- fetch branch by company --------//
  const fetchBranchByCompany = async () => {
    try {
      const { data } = await branchService.getListBranch(ID_COMPANY)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }

  //--------- thông tin bệnh nhân chờ siêu âm  -----------//
  const onClickBn = (record) => {
    setListImagePrint([])
    dispatch(infoBNSieuAm(record.idbnttc, record.idbncdha))
  }
  //------------ lấy thông tin bệnh nhân chờ -----------//
  const onClickBnPending = (record) => {
    setListImagePrint([])
    dispatch(infoBnPending(record.idbnttc, record.idbncdha))
  }
  // Disable future dates
  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day')
  }
  useEffect(() => {
    fetchBranchByCompany()
  }, [])
  const columns = [
    {
      key: 'STT',
      dataIndex: 'STT',
      title: 'STT',
      width: 40,
      render: (text, record, index) => ++index,
    },
    {
      key: 'tenbenhnhan',
      dataIndex: 'tenbenhnhan',
      title: 'Tên bệnh nhân',
      ...getColumnSearchProps('tenbenhnhan'),
    },
  ]
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              horizontalItemPadding: 12,
            },
          },
        }}
      >
        <Tabs
          items={[
            {
              key: 1,
              label: 'Ca siêu âm',
              children: (
                <>
                  <div className="p-1">
                    <div className="flex items-center gap-2">
                      <Select
                        onChange={(value) => setIdBranch(value)}
                        options={branch.map((item) => ({
                          label: item.tenChiNhanh,
                          value: item.idChiNhanh,
                        }))}
                        className="w-full"
                        value={idBranch}
                      />
                      <IconButton
                        onClick={() => fetchBNPending(ID_BRANCH)}
                        className="ml-1"
                        color="primary"
                        aria-label="add to shopping cart"
                      >
                        <CachedIcon />
                      </IconButton>
                    </div>

                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                        },
                        // components: {
                        //   Table: {
                        //     rowHoverBg: '#ecf0f1',
                        //     headerBg: '#e6e6e6',
                        //     footerBg: '#e6e6e6',
                        //     borderColor: '#BABABA',
                        //   },
                        // },
                      }}
                    >
                      <Table
                        scroll={{
                          y: 310,
                        }}
                        onRow={(record) => ({
                          onClick: () => onClickBnPending(record),
                        })}
                        bordered
                        pagination={false}
                        dataSource={BNPending}
                        columns={columns}
                      />
                    </ConfigProvider>
                  </div>
                </>
              ),
            },
            {
              key: 2,
              label: 'Đã siêu âm',
              children: (
                <>
                  <div className="p-1">
                    <DatePicker
                      allowClear={false}
                      value={dayjs(dateForm)}
                      format={formatDate}
                      disabledDate={disabledDate}
                      onChange={(data, dateString) =>
                        setDateForm(moment(dateString, formatDate).format(formatDB))
                      }
                      size="small"
                    />{' '}
                    -{' '}
                    <DatePicker
                      allowClear={false}
                      value={dayjs(dateTo)}
                      format={formatDate}
                      disabledDate={disabledDate}
                      onChange={(data, dateString) =>
                        setDateTo(moment(dateString, formatDate).format(formatDB))
                      }
                      size="small"
                    />
                    <IconButton
                      onClick={() => fetchBnDone(ID_BRANCH, dateForm, dateTo)}
                      className="ml-1"
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <CachedIcon />
                    </IconButton>
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                        },
                        // components: {
                        //   Table: {
                        //     rowHoverBg: '#ecf0f1',
                        //     headerBg: '#e6e6e6',
                        //     footerBg: '#e6e6e6',
                        //     borderColor: '#BABABA',
                        //   },
                        // },
                
                      }}
                    >
                      <Table
                        scroll={{
                          y: 310,
                        }}
                        onRow={(record) => ({
                          onClick: () => onClickBn(record),
                        })}
                        bordered
                        pagination={false}
                        dataSource={BNdone}
                        columns={columns}
                      />
                    </ConfigProvider>
                  </div>
                </>
              ),
            },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default Customer
