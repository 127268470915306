import {
  DeleteOutlined,
  FileExcelOutlined,
  SearchOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons'
import { Button, ConfigProvider, Input, Space, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import fileNhapVoucher from '../../../../data/Form/form-nhap-voucher.xlsx'
import ToastCus from '../../../../utils/Toast'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import Highlighter from 'react-highlight-words'
import 'moment/locale/vi'
moment.locale('vi')

function DanhSachTheGiamGia({
  formik,
  setDataInTable,
  dataInTable,
  loadingTable,
  setLoadingTable,
  setCheckTaoMa,
  checkTaoMa,
}) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleNhapMaBangExcel = (e) => {
    if (formik.values.giatri === null) formik.setFieldValue('giatri', 0)
    formik.setFieldValue('soluongma', null)
    formik.setFieldValue('dodaima', null)
    formik.setErrors({
      soluongma: null,
      dodaima: null,
      giatri: !formik.values.giatri ? 'require' : null,
    })
    if (!formik.values.giatri) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập giá trị!',
      })
      e.target.type = ''
      return
    }
    e.target.type = 'file'
  }
  const handleFileChange = (e) => {
    handleNhapMaBangExcel(e)
    setLoadingTable(true)
    try {
      const file = e.target.files[0]
      const fileName = file.name
      const extension = fileName.split('.').pop().toLowerCase()
      if (extension !== 'xlsx' && extension !== 'xls') {
        // kiểm tra xem có phải là file excel hay không
        ToastCus.fire({
          icon: 'error',
          title: 'Chỉ chấp nhận tệp Excel (xlsx, xls)',
        })
        return
      }
      const render = new FileReader()
      render.readAsArrayBuffer(file)
      render.onload = (e) => {
        const excelData = e.target.result
        const workbook = XLSX.read(excelData, {
          type: 'buffer',
          cellDates: true,
        })
        const worksheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[worksheetName]
        const data = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          dateNF: null,
        })
        const newData = data.map((item) => ({
          mavoucher: item['Mã voucher']?.trim()?.replaceAll(' ', '')?.toUpperCase() ?? '',
          giatri: formik.values.giatri,
          nguoitao: infoUser.tenNV,
          ngaytao: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          nguoikichhoat: '',
          ngaykichhoat: '',
          trangthai: 1,
        }))
        setDataInTable((prev) => [...prev, ...newData])
      }
      formik.setFieldValue('soluongma', null)
      formik.setFieldValue('dodaima', null)
      formik.setErrors({ soluongma: null, dodaima: null })
      setCheckTaoMa(true)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi tạo voucher',
      })
    } finally {
      setLoadingTable(false)
    }
  }

  const handleDownloadFile = (e) => {
    e.preventDefault()
    const link = document.createElement('a')
    link.href = fileNhapVoucher
    link.target = '_blank'
    link.download = 'Mẫu import voucher.xlsx'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleDeleteInTable = (item) => {
    setDataInTable((prev) => prev.filter((val) => val.mavoucher !== item.mavoucher))
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
  //     <div
  //       style={{
  //         padding: 8,
  //       }}
  //       onKeyDown={(e) => e.stopPropagation()}
  //     >
  //       <Input
  //         ref={searchInput}
  //         placeholder={'Nhập tìm kiếm'}
  //         value={selectedKeys[0]}
  //         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{
  //           marginBottom: 8,
  //           display: 'block',
  //         }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Tìm
  //         </Button>
  //         <Button
  //           onClick={() => clearFilters && handleReset(clearFilters)}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Xoá
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             close()
  //           }}
  //         >
  //           Đóng
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined
  //       style={{
  //         color: filtered ? '#1677ff' : undefined,
  //       }}
  //     />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownOpenChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.current?.select(), 100)
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{
  //           backgroundColor: '#ffc069',
  //           padding: 0,
  //         }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ''}
  //       />
  //     ) : (
  //       text
  //     ),
  // })

  const columnsVoucher = [
    {
      title: 'Mã voucher',
      dataIndex: 'mavoucher',
      align: 'center',
      key: 'mavoucher',
      align: 'center',
      fixed: 'left',
      ...getColumnSearchProps('mavoucher'),
      width: 120,
    },
    {
      title: (
        <p className="text-center">Giá trị {formik?.values?.loaigiatri === 2 ? `(%)` : `(VNĐ)`}</p>
      ),
      dataIndex: 'giatri',
      key: 'giatri',
      align: 'center',
      width: 105,
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
      width: 220,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      align: 'center',
      width: 140,
    },
    {
      title: 'Người kích hoạt',
      dataIndex: 'nguoikichhoat',
      key: 'nguoikichhoat',
      width: 220,
    },
    {
      title: 'Ngày kích hoạt',
      dataIndex: 'ngaykichhoat',
      key: 'ngaykichhoat',
      align: 'center',
      width: 140,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangthai',
      key: 'trangthai',
      align: 'center',
      width: 90,
      fixed: 'right',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 40,
      align: 'center',
      fixed: 'right',
    },
  ]

  return (
    <div className="">
      <div className="flex justify-start gap-2 mb-2">
        <Button
          onClick={() => {
            setDataInTable([])
            setCheckTaoMa(false)
          }}
          disabled={!checkTaoMa}
        >
          Reset
        </Button>
        <Button disabled={checkTaoMa} className="text-green-700 font-semibold mr-2" type="default">
          <label className="cursor-pointer px-4 py-1" for="file-upload">
            <FileExcelOutlined /> Nhập
          </label>
          <input
            id="file-upload"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            value={''}
            onChange={handleFileChange}
            onClick={handleNhapMaBangExcel}
            type="" ///////////////////////
            className="hidden" ///////////////////////
          />
        </Button>

        <button
          onClick={handleDownloadFile}
          className="text-green-700 text-base cursor-pointer flex items-center"
          size="small"
        >
          <VerticalAlignBottomOutlined />
          File mẫu
        </button>
      </div>
      <div className="overflow-auto h-[489px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            loading={loadingTable}
            scroll={{
              //y: 450,
              x: 1100,
            }}
            bordered
            //pagination={false}
            pagination={{
              style: {
                padding: 0,
                margin: 0,
                marginTop: 10,
              },
              pageSize: 10,
              showSizeChanger: false,
            }}
            columns={columnsVoucher}
            dataSource={dataInTable.map((item) => ({
              action: (
                <ul className="flex gap-2 justify-around">
                  <li className="text-lg text-red-500">
                    <Tooltip title="Xóa" placement="top" className="cursor-pointer" color="red">
                      <DeleteOutlined onClick={() => handleDeleteInTable(item)} />
                    </Tooltip>
                  </li>
                </ul>
              ),
              mavoucher: item.mavoucher,
              giatri: formatNumberVND(item.giatri),
              nguoitao: item.nguoitao,
              ngaytao: item.ngaytao ? moment(item.ngaytao).format('DD/MM/YYYY HH:mm:ss') : '',
              nguoikichhoat: item.nguoikichhoat,
              ngaykichhoat: item.ngaykichhoat
                ? moment(item.ngaykichhoat).format('DD/MM/YYYY HH:mm:ss')
                : '',
              trangthai:
                item.trangthai === 1
                  ? ' Mới tạo'
                  : item.trangthai === 2
                    ? 'Chưa sử dụng'
                    : 'Đã sử dụng',
            }))}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

DanhSachTheGiamGia.propTypes = {}

export default DanhSachTheGiamGia
