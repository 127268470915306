import dayjs from 'dayjs'
import moment from 'moment/moment'
import { generateCheckbox } from '../algorithm'
import { calculateAgeForReport } from '../calculateAge'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord, splitLongWordFitWidth } from '../splitWordForReport'

export const generatePhieuKiemTruocTiemChungSan = ({
  infoBN,
  infoBNTTC,
  infoKSL,
  tenVacXin,
  PkDangNhap,
}) => {
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  doc.setFont('times', 'bold')
  doc.setFontSize(10)
  doc.text('HỆ THỐNG Y TẾ PHỤ SẢN 315', xPos + 4, yPos)
  doc.setFontSize(9)
  doc.text('CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM', pageWidth - 75, yPos)
  yPos += 4
  doc.setFont('times', 'regular')
  doc.setFontSize(8)
  doc.text(PkDangNhap.diaChi ?? '', xPos, yPos)
  doc.text('Độc lập - Tự do - Hạnh phúc', pageWidth - 60, yPos)
  yPos += 4

  doc.text(
    `Điện thoại: ${PkDangNhap.dienThoai ?? '0901.315.315'} - Hotline: ${PkDangNhap.sohotline ?? '0901.315.315'}`,
    xPos + 2,
    yPos,
  )
  yPos += 4
  doc.setFont('times', 'bold')
  doc.setFontSize(9)
  doc.text('BẢNG KIỂM TRƯỚC TIÊM CHỦNG ĐỐI VỚI NGƯỜI LỚN', doc.internal.pageSize.width / 2, yPos, {
    align: 'center',
  })
  yPos += 4
  doc.setFont('times', 'regular')
  doc.text(`Họ và tên: ${infoBN?.tenBenhNhan ?? ''}`, xPos, yPos)
  doc.text('Nam', pageWidth - 40, yPos)
  if (infoBN?.gioiTinh?.includes('Nam')) {
    generateCheckbox(doc, pageWidth - 45, yPos - 3.5, 4, 4, true)
  } else {
    generateCheckbox(doc, pageWidth - 45, yPos - 3.5, 4, 4, false)
  }
  doc.setFontSize(9)
  doc.text('Nữ', pageWidth - 25, yPos)
  if (infoBN?.gioiTinh?.includes('Nữ')) {
    generateCheckbox(doc, pageWidth - 30, yPos - 3.5, 4, 4, true)
  } else {
    generateCheckbox(doc, pageWidth - 30, yPos - 3.5, 4, 4, false)
  }
  doc.setFontSize(9)
  yPos += 4
  doc.text(
    `Tuổi: ${infoBN?.ngaySinh && calculateAgeForReport(infoBN?.ngaySinh)}, sinh ngày ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('DD')} tháng ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('MM')} năm ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('YYYY')}`,
    xPos,
    yPos,
  )
  yPos += 4
  //   const diaChiBN = `Địa chỉ: ${detailBN.benhNhan.diaChi ?? ''}${detailBN.benhNhan.tenPhuongXa ? ', ' + detailBN.benhNhan.tenPhuongXa : ''}${detailBN.benhNhan.tenQuanHuyen ? ', ' + detailBN.benhNhan.tenQuanHuyen : ''}${detailBN.benhNhan.tenTinhTP ? ', ' + detailBN.benhNhan.tenTinhTP : ''}`
  const diaChiBN = `Địa chỉ: ${infoBN?.diaChi + ', ' ?? ''}${infoBN?.tenPhuongXa + ', ' ?? ''}${infoBN?.tenQuanHuyen + ', ' ?? ''}${infoBN?.tenTinhTP + ', ' ?? ''}`
  if (diaChiBN.length > 80) {
    const wordsArray = splitLongWord(diaChiBN, 80)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(diaChiBN, xPos, yPos)
  }
  yPos += 4
  doc.text(`Cân nặng: ${infoBNTTC?.cannang ?? '...'} kg`, xPos, yPos)
  doc.text(`Mạch: ${infoBNTTC?.mach ?? '...'} `, xPos + 27, yPos)
  doc.text(`Huyết áp: ${infoBNTTC?.huyetap ?? '...'} mmHg`, xPos + 50, yPos)
  doc.text(`Mã tiêm chủng quốc gia: ${infoBN?.maTCQG ?? ''}`, pageWidth - 60, yPos)
  yPos += 2

  let startTable = yPos
  doc.line(xPos - 2, yPos, pageWidth - 8, yPos)
  doc.setFontSize(8)
  doc.text(
    'Dành cho người đi tiêm chủng: Những câu hỏi sau đây có thể giúp cân nhắc bạn có thể được tiêm những vaccin gì ',
    xPos,
    yPos + 3,
  )
  doc.text(
    'trong ngày hôm nay. Nếu bạn trả lời “ CÓ’’ cho bất kỳ câu hỏi nào không có nghĩa là bạn không nên tiêm chủng. ',
    xPos,
    yPos + 6,
  )
  doc.text(
    'Điều này chỉ có nghĩa là phải hỏi thêm nhiều câu khác. Nếu có câu hỏi nào không rõ ràng, vui lòng hỏi lại nhân viên ',
    xPos,
    yPos + 9,
  )
  doc.text('y tế tại nơi tiêm chủng để được giải thích rõ hơn.', xPos, yPos + 12)
  doc.line(xPos - 2, yPos + 13, pageWidth - 8, yPos + 13)
  yPos += 13
  doc.setFontSize(9)
  doc.text('BẢNG KIỂM TRƯỚC TIÊM CHỦNG ĐỐI VỚI NGƯỜI LỚN', xPos + 8, yPos + 4)
  doc.setFontSize(8)
  doc.text('Có', xPos + 102, yPos + 4)
  doc.text('Không', xPos + 110, yPos + 4)
  doc.text('Không rõ', xPos + 120.5, yPos + 4)
  doc.setFontSize(9)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  yPos += 5
  doc.text('1. Hiện tại bạn có đang mắc bệnh lý gì không ?', xPos, yPos + 3.5)
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.benhly === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.benhly === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.benhly === 2 ? true : false)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  yPos += 5
  doc.text('2. Bạn có dị ứng với thuốc, thức ăn hay vắc xin không ?', xPos, yPos + 3.5)
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.diung === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.diung === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.diung === 2 ? true : false)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  yPos += 5
  doc.text(
    '3. Bạn có bị phản ứng nặng sau những lần tiêm chủng vắc xin trước đây ?',
    xPos,
    yPos + 3.5,
  )
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.phanungnang === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.phanungnang === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.phanungnang === 2 ? true : false)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  yPos += 5
  doc.text('4. Bạn có bị động kinh, hoặc có bệnh lý về não, thần kinh không ?', xPos, yPos + 3.5)
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.thankinh === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.thankinh === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.thankinh === 2 ? true : false)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  yPos += 5
  doc.text(
    '5. Bạn có bị ưng thư, bệnh bạch cầu, AIDS hay bất cứ rối loạn hệ thống',
    xPos,
    yPos + 3.5,
  )
  doc.text('miễn dịch nào không ?', xPos, yPos + 7)
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.ungthu === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.ungthu === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.ungthu === 2 ? true : false)

  doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  yPos += 8
  doc.text(
    '6. Trong vòng 3 tháng qua, bạn có điều trị cortisone,prednisone,các thuốc',
    xPos,
    yPos + 3.5,
  )
  doc.text(
    'steroide khác hay thuốc điều trị ung thư, hoặc điều trị bằng tia X không ?',
    xPos,
    yPos + 7,
  )
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.corsiton === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.corsiton === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.corsiton === 2 ? true : false)

  doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  yPos += 8
  doc.text(
    '7. Trong vòng một năm qua, bạn có được truyền máu hay các chế phẩm từ',
    xPos,
    yPos + 3.5,
  )
  doc.text('máu hoặc globulin miễn dịch không ?', xPos, yPos + 7)
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.truyenmau === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.truyenmau === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.truyenmau === 2 ? true : false)

  doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  yPos += 8
  doc.text(
    '8. Đối với phụ nữ, bạn có đang mang thai hay có dự định mang thai trong',
    xPos,
    yPos + 3.5,
  )
  doc.text('tháng tới không ?', xPos, yPos + 7)
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.mangthai === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.mangthai === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.mangthai === 2 ? true : false)

  doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  yPos += 8
  doc.text('9. Bạn có được tiêm chủng vắc xin trong vòng 4 tuần vừa qua không ?', xPos, yPos + 3.5)
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.tiemchunG4TUAN === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.tiemchunG4TUAN === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.tiemchunG4TUAN === 2 ? true : false)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  yPos += 5

  let endTable = yPos
  //line y
  doc.line(xPos - 2, startTable, xPos - 2, endTable)
  doc.line(xPos + 99, startTable + 13, xPos + 99, endTable)
  doc.line(xPos + 108, startTable + 13, xPos + 108, endTable)
  doc.line(xPos + 120, startTable + 13, xPos + 120, endTable)
  doc.line(pageWidth - 8, startTable, pageWidth - 8, endTable)

  yPos += 3.5
  doc.setFont('times', 'italic')
  doc.setFontSize(8)
  const formattedDateTime = `Hồi ${moment(new Date()).format('hh')} giờ ${moment(new Date()).format('mm')} , ngày ${moment(new Date()).format('DD')} tháng ${moment(new Date()).format('MM')} năm ${moment(new Date()).format('YYYY')}`
  doc.text(xPos, yPos, formattedDateTime)
  doc.text(pageWidth - 50, yPos, 'Người điền thông tin (ký tên)')

  doc.setFontSize(9)
  doc.setFont('times', 'regular')
  yPos += 10
  let startTableBs = yPos
  doc.line(xPos - 2, yPos, pageWidth - 8, yPos)
  doc.text('DÀNH CHO BÁC SĨ TƯ VẤN VÀ THĂM KHÁM LÂM SÀNG', xPos + 8, yPos + 4)
  doc.setFontSize(8)
  doc.text('Có', xPos + 102, yPos + 4)
  doc.text('Không', xPos + 110, yPos + 4)
  doc.text('Không rõ', xPos + 120, yPos + 4)
  doc.setFontSize(9)
  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  yPos += 5
  doc.text('1. Thân nhiệt ≥ 37.5°C hoặc ≤ 35.5°C', xPos, yPos + 3.5)
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.sothathannhiet === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.sothathannhiet === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.sothathannhiet === 2 ? true : false)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  yPos += 5
  doc.text('2. Tri giác bất thường', xPos, yPos + 3.5)
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.trigiacbatthuong === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.trigiacbatthuong === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.trigiacbatthuong === 2 ? true : false)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  yPos += 5
  doc.text('3. Tiếng tim bất thường', xPos, yPos + 3.5)
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.timbatthuong === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.timbatthuong === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.timbatthuong === 2 ? true : false)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  yPos += 5
  doc.text('4. Nhịp thở, nghe phổi bất thường', xPos, yPos + 3.5)
  generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.nhipthophoibatthuong === 1 ? true : false)
  generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.nhipthophoibatthuong === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.nhipthophoibatthuong === 2 ? true : false)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  yPos += 5
  //infoKSL?.khac
  const chidinhkhac = `5. Các chống chỉ định khác: ${infoKSL.ghichu ?? ''}`
  let tempYposChiDinh = yPos + 3
  if (doc.getTextWidth(chidinhkhac) > 140) {
    doc.setFontSize(7)
    const wordsArray = splitLongWordFitWidth(doc, chidinhkhac, 125)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, tempYposChiDinh)
      tempYposChiDinh += 2.5
    }
    doc.setFontSize(8)
  } else {
    tempYposChiDinh = tempYposChiDinh + 2
    doc.text(chidinhkhac, xPos, tempYposChiDinh)
  }

  doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  yPos += 8
  let tempYpos = yPos + 3
  doc.text('Kết luận : Đủ điều kiện tiêm chủng lần này :', xPos, yPos + 3.5)
  const loaiVacXin = `Chỉ định vắc xin: ${tenVacXin ?? ''}`
  if (doc.getTextWidth(loaiVacXin) > 80) {
    doc.setFontSize(6)
    const wordsArray = splitLongWordFitWidth(doc, loaiVacXin, 45)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos + 85, tempYpos)
      tempYpos += 2.5
    }
    doc.setFontSize(8)
  } else {
    tempYpos = tempYpos + 2
    doc.text(loaiVacXin, xPos + 85, tempYpos)
  }


  doc.text('Có', xPos + 10, yPos + 6.5)
  generateCheckbox(doc, xPos + 16, yPos + 4, 3, 3, infoKSL?.tiemchung === 1 ? true : false)
  doc.text('Không', xPos + 40, yPos + 6.5)
  generateCheckbox(doc, xPos + 50, yPos + 4, 3, 3, infoKSL?.tiemchung === 0 ? true : false)
  //infoKSL?.lydo
  const lydotrihoan = `Lý do trì hoãn: ${infoKSL.lydo ?? ''}`
  let tempYposTriHoan = yPos + 9
  if (doc.getTextWidth(lydotrihoan) > 120) {
    doc.setFontSize(7)
    const wordsArray = splitLongWordFitWidth(doc, lydotrihoan, 80)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, tempYposTriHoan)
      tempYposTriHoan += 3
    }
    doc.setFontSize(8)
  } else {
    tempYposTriHoan = tempYposTriHoan + 2
    doc.text(lydotrihoan, xPos, tempYposTriHoan)
  }

  doc.line(xPos - 2, yPos + 16, pageWidth - 8, yPos + 16)
  yPos += 16
  doc.text('Người đi tiêm chủng : Đã được tư vấn và đồng ý tiêm chủng', xPos, yPos + 3.5)
  doc.text('Bác sỹ tư vấn', xPos + 98, yPos + 3.5)
  yPos += 20
  doc.text('Ký tên, ghi rõ họ tên:', xPos, yPos)
  const leftLineX = xPos + 84;
  const rightLineX = pageWidth - 8;
  const textToCenter = infoKSL.bskham ?? '';
  
  // Calculate the width of the text
  const textWidth = doc.getTextWidth(textToCenter);
  
  // Calculate the center point between the two lines
  const centerX = (leftLineX + rightLineX) / 2;
  
  // Calculate the starting x-coordinate for the text to be centered
  const textX = centerX - (textWidth / 2);
  
  // Draw the text
  doc.text(textToCenter, textX, yPos);

  doc.line(xPos - 2, yPos + 2, pageWidth - 8, yPos + 2)
  let endTableBs = yPos + 2

  //line y
  doc.line(xPos - 2, startTableBs, xPos - 2, endTableBs)

  doc.line(xPos + 99, startTableBs, xPos + 99, startTableBs + 25)
  doc.line(xPos + 108, startTableBs, xPos + 108, startTableBs + 25)
  doc.line(xPos + 120, startTableBs, xPos + 120, startTableBs + 25)

  doc.line(xPos + 84, startTableBs + 33, xPos + 84, endTableBs)

  doc.line(pageWidth - 8, startTableBs, pageWidth - 8, endTableBs)
  // doc.save('Phieu-thu.pdf')
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
