import React, { useState, useEffect } from 'react'
import { Modal, Input, Steps, Divider, Button, Tooltip, InputNumber } from 'antd'
import { RetweetOutlined, PlusOutlined } from '@ant-design/icons'
import * as typeAction from '../../../../store/constants/constants'
import InfoBN from './InfoBN/InfoBN'
import CardTTV from './TypeCard/CardTTV'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, useFormik } from 'formik'
import { AddCardBN, resetFormTTV, cardReceiveAction } from '../../../../store/actions/cardAction'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import moment from 'moment'
import ToastCus from '../../../../utils/Toast'
import { getAllSelectClinicAction } from '../../../../store/actions/receiveAction'
import ModalCreateCustomer from '../../../layout/Receive/ModalCreateCustomer/ModalCreateCustomer'
import { Button as ButtonMui } from '@mui/material'
import 'moment/locale/vi'
moment.locale('vi')
const ModalAdd = ({ isModalOpen, setIsModalOpen, formLoad }) => {
  const now = moment()
  const {
    detailCard,
    infoBN,
    infoBNthu2,
    infoPaymentCard,
    tongThanhToan,
    tongGiamGia,
    discountArr,
    CTGiamTTV,
    infoVoucherDoiTac,
  } = useSelector((state) => state.cartReducer)
  const data = useSelector((state) => state.receiveReducer)
  const infoBNnew = data?.infoBN
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [openModaCreateCustomer, setOpenModaCreateCustomer] = useState(false)
  const [isDoiTac, setIsDoiTac] = useState(false)
  const [valueChuongTrinhDoiTac, setValueChuongTrinhDoiTac] = useState('')
  const [valueGiaVoucherDoiTac, setValueGiaVoucherDoiTac] = useState(0)
  const [formTTV, setFormTTV] = useState({
    mathe: null,
    idbntemp: null,
    idchuyenkhoa: detailCard?.idchuyenkhoa,
    sothanhvien: 1,
    idnhom: detailCard?.idnhom,
    ngaydangky: now.format(),
    tungay: null,
    denngay: null,
    mavoucher: '',
    listMaVoucher: null, // list mã voucher ['abc','xyz',...]
    mavoucherdoitac: '',
    idcn: PkDangNhap?.idChiNhanh,
    idcalamviec: infoUser?.dangNhap?.idCaLamViec,
    idnguoitao: infoUser?.idnv,
    nguoitao: infoUser?.tenNV,
    idnguoiban: infoUser?.idnv,
    nguoiban: infoUser?.tenNV,
    nguoimua: null,
    sodienthoai: null,
    diachi: null,
    lydo: null,
    idphuongthuc: 1, // thanh toán đủ
    hoadondientu: 0, // không xuất
    ghichuxuathoadon: null,
  })
  const [form, setForm] = useState({
    tienmat: 0,
    chuyenkhoan: 0,
    thett: 0,
    momo: 0,
    vnpay: 0,
    zalopay: 0,
    pos: 0,
    tongtien: 0,
    tiengiamgia: tongGiamGia + CTGiamTTV,
    tienthanhtoan: tongThanhToan - CTGiamTTV,
    trangthaithanhtoan: 2,
  })
  const dispatch = useDispatch()
  // show modal create new customer
  const showCreateCustomer = () => {
    setOpenModaCreateCustomer(true)
  }
  // cacel show modal create new customer
  const cancelCreateCustomer = () => {
    setOpenModaCreateCustomer(false)
  }
  const handleOk = (values) => {
    // Implement form submission logic here
    // setIsModalOpen(false);
  }
  const doiViTriBN = () => {
    const tempBN = { ...infoBN }
    dispatch({
      type: typeAction.DISPATCH_INFO_BN_CARD,
      payload: infoBNthu2,
    })
    dispatch({
      type: typeAction.DISPATCH_INFO_BN_THU2_CARD,
      payload: tempBN,
    })
  }
  const handleResetForm = () => {
    dispatch(resetFormTTV())
    // //reset form
    setForm({
      tienmat: 0,
      chuyenkhoan: 0,
      thett: 0,
      momo: 0,
      vnpay: 0,
      zalopay: 0,
      pos: 0,
      tongtien: 0,
      tiengiamgia: tongGiamGia + CTGiamTTV,
      tienthanhtoan: tongThanhToan - CTGiamTTV,
      trangthaithanhtoan: 2,
    })
    setFormTTV({
      mathe: null,
      idbntemp: null,
      idchuyenkhoa: detailCard?.idchuyenkhoa,
      sothanhvien: 1,
      idnhom: detailCard?.idnhom,
      ngaydangky: now.format(),
      tungay: null,
      denngay: null,
      mavoucher: '',
      listMaVoucher: null, // list mã voucher ['abc','xyz',...]
      mavoucherdoitac: '',
      idcn: PkDangNhap?.idChiNhanh,
      idcalamviec: infoUser?.dangNhap?.idCaLamViec,
      idnguoitao: infoUser?.idnv,
      nguoitao: infoUser?.tenNV,
      idnguoiban: infoUser?.idnv,
      nguoiban: infoUser?.tenNV,
      nguoimua: null,
      sodienthoai: null,
      diachi: null,
      lydo: null,
      idphuongthuc: 1, //thanh toán đủ
      hoadondientu: 0, // không xuất
      ghichuxuathoadon: null,
    })
    setCurrent(0)
  }

  const handleSubmit = async () => {
    let formTT = null,
      formBNthu2 = null
    // dataLoad = { ...formLoad }
    if (infoPaymentCard && form.thett > 0) {
      formTT = {
        idthe: infoPaymentCard?.idThe,
        idbn: infoBN?.idbn,
        nguoinhan: infoUser?.tenNV,
        tiensudung: form.thett,
        chinhanhnap: PkDangNhap?.idChiNhanh,
      }
    }
    if (infoBNthu2 && detailCard?.caploaithe > 1 && formTTV?.sothanhvien === 2) {
      formBNthu2 = {
        idbn: infoBNthu2?.idbn,
        nguoitao: infoUser?.tenNV,
      }
    }
    // generatePhieuThuTienTTV(infoBN, formTTV, form, PkDangNhap)
    await dispatch(
      AddCardBN(
        {
          ...form,
          ...formTTV,
          ghichu:
            CTGiamTTV !== 0
              ? `CT giảm giá ${CTGiamTTV} cho khách đăng kí thẻ thành viên lần đầu tại phòng khám nhi đồng 315 `
              : '',
          tiengiamgia: tongGiamGia + CTGiamTTV,
          idct: formTTV?.mavoucherdoitac && infoVoucherDoiTac ? infoVoucherDoiTac.idctDoiTac : null,
          giatri:
            formTTV?.mavoucherdoitac && infoVoucherDoiTac ? infoVoucherDoiTac.giaVoucher : null,
        },
        detailCard?.idlt,
        infoBN?.idbn,
        PkDangNhap?.idChiNhanh,
        formTT,
        setIsModalOpen,
        {
          infoBN: infoBN,
          infoBNthu2: infoBNthu2 ?? null,
          infoTTV: formTTV,
          infoTT: form,
          PkDangNhap: PkDangNhap,
        },
        formLoad,
        formBNthu2,
      ),
    )

    //await dispatch(cardReceiveAction(formLoad?.idcn, formLoad?.tungay, formLoad?.denngay, formLoad?.idlt, formLoad?.idnt, formLoad?.keyword, formLoad?.idct))
    handleResetForm()
    handleCancel()
  }
  const checkValuePrice = (total, name, value) => {
    if (total > form.tienthanhtoan) {
      setForm((prevState) => ({ ...prevState, [name]: 0 }))
    } else {
      setForm((prevState) => ({ ...prevState, [name]: value }))
    }
  }
  const onChangeInput = (name, value) => {
    //const { name, value } = e.target
    const newValue = Number(value)
    // setForm({ ...form, [name]: newValue });
    switch (name) {
      case 'tienmat':
        const total_0 =
          newValue +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_0, name, newValue)
        break
      case 'chuyenkhoan':
        const total_1 =
          form.tienmat + newValue + form.pos + form.momo + form.vnpay + form.zalopay + form.thett
        checkValuePrice(total_1, name, newValue)
        break
      case 'momo':
        const total_2 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          newValue +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_2, name, newValue)
        break
      case 'vnpay':
        const total_3 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          newValue +
          form.zalopay +
          form.thett
        checkValuePrice(total_3, name, newValue)
        break
      case 'zalopay':
        const total_4 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          newValue +
          form.thett
        checkValuePrice(total_4, name, newValue)
        break
      case 'pos':
        const total_5 =
          form.tienmat +
          form.chuyenkhoan +
          newValue +
          form.momo +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_5, name, newValue)
        break
      case 'thett':
        if (newValue > infoPaymentCard?.tienVi) return
        const total_6 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          form.zalopay +
          newValue
        checkValuePrice(total_6, name, newValue)
        break
      default:
        break
    }
  }

  const handleKeyDown = (e) => {
    const { name } = e.target
    if (e.key === 'P' || e.key === 'p') {
      // Kiểm tra nếu phím nhấn là "P"
      // Cập nhật giá tiền vào ô input
      switch (name) {
        case 'tienmat':
          const priceTM = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceTM }))
          break
        case 'chuyenkhoan':
          const priceCK = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceCK }))
          break
        case 'momo':
          const priceMOMO = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceMOMO }))
          break
        case 'vnpay':
          const priceVNPAY = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceVNPAY }))
          break
        case 'zalopay':
          const priceZALOPAY = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceZALOPAY }))
          break
        case 'pos':
          const pricePOS = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: pricePOS }))
          break
        case 'thett':
          const priceTheTT = form.tienthanhtoan - form.tongtien
          if (priceTheTT > infoPaymentCard?.tienVi) {
            setForm((prevState) => ({ ...prevState, [name]: infoPaymentCard?.tienVi }))
            break
          }
          setForm((prevState) => ({ ...prevState, [name]: priceTheTT }))
          break
        default:
          break
      }
    }
  }
  const handleCancel = () => {
    if (discountArr || infoVoucherDoiTac) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng hủy áp dụng voucher trước khi đóng!',
      })
      return
    }
    setIsModalOpen(false)
    handleResetForm()
  }

  const [current, setCurrent] = useState(0)
  const next = () => {
    setFormTTV({
      ...formTTV,
      mathe: formTTV.mathe === null ? '' : formTTV.mathe,
      tungay: formTTV.tungay === null ? '' : formTTV.tungay,
      nguoimua: formTTV.nguoimua === null ? '' : formTTV.nguoimua,
      sodienthoai: formTTV.sodienthoai === null ? '' : formTTV.sodienthoai,
      diachi: formTTV.diachi === null ? '' : formTTV.diachi,
      lydo: formTTV.lydo === null ? '' : formTTV.lydo,
      ghichuxuathoadon: formTTV.ghichuxuathoadon === null ? '' : formTTV.ghichuxuathoadon,
    })
    // if (formTTV.mathe === null) setFormTTV({ ...formTTV, mathe: '', })
    // console.log(formTTV)
    // if (!formTTV.tungay) setFormTTV({ ...formTTV, tungay: '', })
    // if (!formTTV.nguoimua) setFormTTV({ ...formTTV, nguoimua: '', })
    // if (!formTTV.sodienthoai) setFormTTV({ ...formTTV, sodienthoai: '', })
    // if (!formTTV.diachi) setFormTTV({ ...formTTV, diachi: '', })
    // if (!formTTV.lydo) setFormTTV({ ...formTTV, lydo: '', })
    // if (!formTTV.ghichuxuathoadon) setFormTTV({ ...formTTV, ghichuxuathoadon: '', })
    if (!formTTV.mathe || formTTV.mathe.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập mã thẻ!',
      })
      return
    }
    if (!formTTV.sothanhvien) {
      setFormTTV({ ...formTTV, sothanhvien: 1 })
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập số thành viên sử dụng thẻ!',
      })
      return
    }
    if (!formTTV.tungay || formTTV.tungay.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập ngày hiệu lực!',
      })
      return
    }
    if (!formTTV.nguoimua || formTTV.nguoimua.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập tên người nộp tiền!',
      })
      return
    }
    if (!formTTV.sodienthoai || formTTV.sodienthoai.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập số điện thoại!',
      })
      return
    }
    if (!formTTV.diachi || formTTV.diachi.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập địa chỉ!',
      })
      return
    }
    // if (!formTTV.lydo || formTTV.lydo.trim().length <= 0) {
    //   ToastCus.fire({
    //     icon: 'error',
    //     text: 'Vui lòng nhập lý do!',
    //   })
    //   return
    // }
    if (
      formTTV.hoadondientu === 1 &&
      (!formTTV.ghichuxuathoadon || formTTV.ghichuxuathoadon.trim().length <= 0)
    ) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập nội dung xuất hóa đơn điện tử!',
      })
      return
    }
    if (detailCard?.caploaithe > 1 && formTTV?.sothanhvien === 2 && !infoBNthu2) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn bệnh nhân thứ 2!',
      })
      return
    }
    if (
      ((formTTV?.mavoucher && !discountArr) || (formTTV?.mavoucherdoitac && !infoVoucherDoiTac)) &&
      detailCard &&
      infoBN &&
      formTTV?.tiensudung
    ) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn áp dụng voucher!',
      })
      return
    }
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }
  // const ChangeSelected = (name) => (value) => {
  //   Formik.setFieldValue(name, value);
  // };
  // const handleDatePicker = (date, dateString) => {
  //   Formik.setFieldValue(
  //     "ngayKhaiTruong",
  //     dateString ? moment(dateString, DateFormat).format() : null
  //   );
  // };
  useEffect(() => {
    dispatch(getAllSelectClinicAction())
    // setFormTTV({
    //   ...formTTV,
    //   idnguoitao: infoUser?.idnv,
    //   nguoitao: infoUser?.tenNV,
    //   idnguoiban: infoUser?.idnv,
    //   nguoiban: infoUser?.tenNV,
    // })
  }, [])
  useEffect(() => {
    setFormTTV({ ...formTTV, idbntemp: infoBN?.idbn })
    if (formTTV?.idbntemp !== infoBN?.idbn) {
      setFormTTV({ ...formTTV, sodienthoai: infoBN?.dienThoai, diachi: infoBN?.diaChi })
    }
  }, [infoBN])
  useEffect(() => {
    if (infoBNnew && isModalOpen) {
      dispatch({
        type: typeAction.DISPATCH_INFO_BN_CARD,
        payload: infoBNnew,
      })
    }
  }, [infoBNnew])
  useEffect(() => {
    if (!isModalOpen) {
      handleResetForm()
    }
    setFormTTV({
      ...formTTV,
      idnguoitao: infoUser?.idnv,
      nguoitao: infoUser?.tenNV,
      idnguoiban: infoUser?.idnv,
      nguoiban: infoUser?.tenNV,
    })
  }, [isModalOpen])

  useEffect(() => {
    const newTotal =
      parseFloat(form.chuyenkhoan) +
      parseFloat(form.momo) +
      parseFloat(form.pos) +
      parseFloat(form.vnpay) +
      parseFloat(form.zalopay) +
      parseFloat(form.tienmat) +
      parseFloat(form.thett)
    setForm((prevState) => ({ ...prevState, tongtien: newTotal }))
    setFormTTV({
      ...formTTV,
      idchuyenkhoa: detailCard?.idchuyenkhoa,
      idnhom: detailCard?.idnhom,
    })
  }, [form.chuyenkhoan, form.momo, form.pos, form.vnpay, form.zalopay, form.tienmat, form.thett])

  useEffect(() => {
    if (formTTV?.sothanhvien === 2) {
      dispatch({
        type: typeAction.DISPATCH_INFO_BN_THU2_CARD,
        payload: null,
      })
    }
  }, [formTTV?.sothanhvien])
  const steps = [
    {
      title: 'Thông tin',
      content: (
        <>
          <Divider
            orientation="left"
            style={{
              margin: '10px 0',
            }}
          >
            <span className="text-blue-500">Thông tin bệnh nhân</span>
            <span className="ml-3">
              <Tooltip title="Tạo bệnh nhân" color="blue">
                <ButtonMui
                  //onClick={() => { setIsEditTTV(true) }}
                  onClick={showCreateCustomer}
                  variant="contained"
                  // color="success"
                  size="small"
                  startIcon={<PlusOutlined />}
                >
                  Tạo bệnh nhân
                </ButtonMui>
              </Tooltip>
            </span>
            <ModalCreateCustomer
              openModaCreateCustomer={openModaCreateCustomer}
              cancelCreateCustomer={cancelCreateCustomer}
            />
          </Divider>
          <InfoBN />

          {detailCard?.caploaithe > 1 && formTTV?.sothanhvien === 2 ? (
            <>
              <Divider
                orientation="left"
                style={{
                  margin: '10px 0',
                }}
              >
                <span className="text-green-500">Thông tin bệnh nhân thứ 2</span>
                <span className="ml-3">
                  <Tooltip title="Đổi vị trí" color="green">
                    <ButtonMui
                      //onClick={() => { setIsEditTTV(true) }}
                      onClick={doiViTriBN}
                      variant="contained"
                      color="success"
                      size="small"
                      startIcon={<RetweetOutlined />}
                    >
                      Đổi vị trí
                    </ButtonMui>
                  </Tooltip>
                </span>
              </Divider>
              <InfoBN isBNtang={true} />
            </>
          ) : (
            <></>
          )}

          <Divider
            orientation="left"
            style={{
              margin: '10px 0',
            }}
          >
            <span className="text-blue-500">Thông tin thẻ</span>
          </Divider>
          <CardTTV
            formTTV={formTTV}
            setFormTTV={setFormTTV}
            handleOk={handleOk}
            handleCancel={handleCancel}
            isDoiTac={isDoiTac}
            setIsDoiTac={setIsDoiTac}
            valueChuongTrinhDoiTac={valueChuongTrinhDoiTac}
            setValueChuongTrinhDoiTac={setValueChuongTrinhDoiTac}
            valueGiaVoucherDoiTac={valueGiaVoucherDoiTac}
            setValueGiaVoucherDoiTac={setValueGiaVoucherDoiTac}
          />
        </>
      ),
    },
    {
      title: 'Thanh toán',
      content: (
        <div className="flex justify-center mt-5">
          <div className="text-start w-4/6">
            <div className="flex flex-col gap-2">
              <div className="flex gap-5">
                <div className="w-1/2">
                  <h2 className="font-semibold">Tiền mặt:</h2>
                  <InputNumber
                    value={form.tienmat}
                    name="tienmat"
                    onKeyDown={handleKeyDown}
                    onChange={(value) => onChangeInput('tienmat', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
                <div className="w-1/2">
                  <h2 className="font-semibold">Chuyển khoản:</h2>
                  <InputNumber
                    value={form.chuyenkhoan}
                    name="chuyenkhoan"
                    onKeyDown={handleKeyDown}
                    onChange={(value) => onChangeInput('chuyenkhoan', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
              </div>
              <div className="flex gap-5">
                <div className="w-1/2">
                  <h2 className="font-semibold">Máy POS:</h2>
                  <InputNumber
                    value={form.pos}
                    name="pos"
                    onKeyDown={handleKeyDown}
                    onChange={(value) => onChangeInput('pos', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
                <div className="w-1/2">
                  <h2 className="font-semibold">Momo:</h2>
                  <InputNumber
                    value={form.momo}
                    name="momo"
                    onKeyDown={handleKeyDown}
                    onChange={(value) => onChangeInput('momo', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
              </div>
              <div className="flex gap-5">
                <div className="w-1/2">
                  <h2 className="font-semibold">VNPay:</h2>
                  <InputNumber
                    value={form.vnpay}
                    name="vnpay"
                    onKeyDown={handleKeyDown}
                    onChange={(value) => onChangeInput('vnpay', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
                <div className="w-1/2">
                  <h2 className="font-semibold">Zalo Pay:</h2>
                  <InputNumber
                    value={form.zalopay}
                    name="zalopay"
                    onKeyDown={handleKeyDown}
                    onChange={(value) => onChangeInput('zalopay', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
              </div>
              <div className="flex gap-5 items-center">
                <div className="w-1/2">
                  <h2 className="font-semibold">Thẻ thanh toán:</h2>
                  <InputNumber
                    disabled={!infoPaymentCard}
                    value={form.thett}
                    name="thett"
                    onKeyDown={handleKeyDown}
                    onChange={(value) => onChangeInput('thett', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
                <div className="w-1/2">
                  {' '}
                  {!infoPaymentCard ? (
                    <h2 className="font-semibold text-red-500 pt-5">* Chưa kích hoạt thẻ</h2>
                  ) : (
                    <h2 className="font-semibold  pt-5">
                      Tối đa : {formatNumberVND(infoPaymentCard?.tienVi ?? 0)} VNĐ
                    </h2>
                  )}
                </div>
              </div>
              <p>
                <i>
                  {' '}
                  * Gợi ý: chọn ô thanh toán và nhấn phím <span className="font-semibold">
                    P
                  </span>{' '}
                  để tính tổng tiền{' '}
                </i>
              </p>
            </div>
            <div className="flex py-2 ">
              <h2 className="w-1/2 text-lg font-semibold text-center">
                Tiền thanh toán: {formatNumberVND(form.tienthanhtoan)} VNĐ
              </h2>
              <h2 className="w-1/2 text-lg font-semibold text-center">
                Tổng tiền: {formatNumberVND(form.tongtien)} VNĐ
              </h2>
            </div>
          </div>
        </div>
      ),
    },
  ]
  return (
    <Modal
      title="Tạo thẻ thành viên"
      width={900}
      className="text-center"
      okText="Tạo thẻ"
      cancelText="Huỷ"
      open={isModalOpen}
      footer={null}
      //   onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <div className="w-full flex justify-center">
        <Steps current={current} className="w-[500px]" items={steps} />
      </div>
      <div>{steps[current].content}</div>
      <div
        className="flex justify-end"
        style={{
          marginTop: 24,
        }}
      >
        {current > 0 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => {
              setForm({
                tienmat: 0,
                chuyenkhoan: 0,
                thett: 0,
                momo: 0,
                vnpay: 0,
                zalopay: 0,
                pos: 0,
                tongtien: 0,
                tiengiamgia: tongGiamGia + CTGiamTTV,
                tienthanhtoan: tongThanhToan - CTGiamTTV,
                trangthaithanhtoan: 2,
              })
              prev()
            }}
          >
            Trở lại
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button
            disabled={!infoBN || !detailCard}
            type="primary"
            onClick={() => {
              setForm({
                ...form,
                tienthanhtoan: tongThanhToan - CTGiamTTV,
              })
              next()
            }}
          >
            Tiếp tục
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            disabled={form.tongtien !== form.tienthanhtoan}
            onClick={handleSubmit}
          >
            Thanh toán
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default ModalAdd
