import { CaretDownOutlined, EditOutlined } from '@ant-design/icons'
import { Input, Select, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as typeAction from '../../../../store/constants/constants'

function InputMSDK({ items, infoThuocVT }) {
  const [isShowOption, setIsShowOption] = useState(false)
  const dispatch = useDispatch()

  const Changemsdk = (e, id) => {
    let { value } = e.target
    const index = infoThuocVT.findIndex((items) => items.id === id)

    dispatch({
      type: typeAction.CHANGE_MSDK_NHAP_KHO,
      payload: {
        index,
        value: value,
      },
    })
  }

  const handleChange = (value) => {
    const index = infoThuocVT.findIndex((item) => item.id === items.id)
    dispatch({
      type: typeAction.CHANGE_MSDK_NHAP_KHO,
      payload: {
        index,
        value: value,
      },
    })
  }

  console.log(items.optionsMSDK);

  return (
    <div className="flex">
      {isShowOption ? (
        <>
          <Select
            onChange={handleChange}
            className="w-full"
            value={items.khoChiTiet.maSoDangKy}
            options={items.optionsMSDK?.map((val) => ({
              label: val,
              value: val,
            }))}
            size="small"
          />
          <Tooltip title="Nhập mã số đăng kí" color="blue">
            <EditOutlined
              style={{ fontSize: '14px', marginLeft: '5px' }}
              onClick={() => setIsShowOption(false)}
            />
          </Tooltip>
        </>
      ) : (
        <>
          <Input
            onChange={(e) => Changemsdk(e, items.id)}
            size="small"
            value={items.khoChiTiet.maSoDangKy}
          />
          {items.optionsMSDK?.length > 0 && (
            <Tooltip title="Chọn mã số đăng kí" color="green">
              <CaretDownOutlined
                style={{ fontSize: '14px', marginLeft: '5px' }}
                onClick={() => setIsShowOption(true)}
              />
            </Tooltip>
          )}
        </>
      )}
    </div>
  )
}

InputMSDK.propTypes = {}

export default InputMSDK
