import { ConfigProvider, Table, Input, Space, Button, Typography } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { formatNumber } from 'devextreme/localization'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import React, { useState, useRef } from 'react'
import 'moment/locale/vi'
moment.locale('vi')
const { Text } = Typography
const TableDetail = ({ data, isLoading }) => {
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex] ?? '' // Kiểm tra nếu record[dataIndex] là null/undefined, gán chuỗi rỗng
      return recordValue.toString().toLowerCase().includes(value.toLowerCase())
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          padding: 5,
        },
      }}
    >
      <Table
        pagination={false}
        loading={isLoading}
        bordered
        dataSource={data}
        scroll={{ x: data?.length > 0 ? 'max-content' : 2800, y: '63vh' }}
        columns={[
          {
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 40,
            fixed: 'left',
            render: (text, record, index) => ++index,
          },
          {
            title: 'Ngày thu',
            dataIndex: 'ngayThu',
            key: 'ngayThu',
            align: 'center',
            width: 150,
            fixed: 'left',
            render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
          },
          {
            title: 'Chi nhánh',
            dataIndex: 'tenChiNhanh',
            key: 'tenChiNhanh',
            fixed: 'left',
            ...getColumnSearchProps('tenChiNhanh'),
          },
          {
            title: 'Ngày khai trương',
            dataIndex: 'ngayKhaiTruong',
            key: 'ngayKhaiTruong',
            align: 'center',
            width: 140,
            render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
          },
          {
            title: 'Loại phiếu',
            dataIndex: 'loai',
            align: 'center',
            width: 100,
            key: 'loai',
            ...getColumnSearchProps('loai'),
          },
          {
            title: 'Hình thức',
            dataIndex: 'tenHinhThuc',
            key: 'tenHinhThuc',
            onCell: () => ({
              style: {
                minWidth: 90,
              },
            }),
            ...getColumnSearchProps('tenHinhThuc'),
          },
          {
            title: 'Phương thức',
            dataIndex: 'tenPhuongThucTT',
            key: 'tenPhuongThucTT',
            width: 130,
            ...getColumnSearchProps('tenPhuongThucTT'),
          },
          // {
          //   title: 'Thẻ thành viên',
          //   dataIndex: 'ten',
          //   key: 'tenPhuongThucTT',
          //   width: 100,
          //   fixed: 'left',
          //   ...getColumnSearchProps('tenPhuongThucTT'),
          // },
          {
            title: 'Mã BN',
            dataIndex: 'maBenhNhan',
            key: 'maBenhNhan',
            width: 120,
            ...getColumnSearchProps('maBenhNhan'),
          },
          {
            title: 'Tên bệnh nhân',
            dataIndex: 'tenBenhNhan',
            key: 'tenBenhNhan',
            ...getColumnSearchProps('tenBenhNhan'),
            onCell: () => ({
              style: {
                minWidth: 140,
              },
            }),
          },
          {
            title: 'Đối tượng',
            dataIndex: 'doiTuong',
            key: 'doiTuong',
            onCell: () => ({
              style: {
                minWidth: 90,
              },
            }),
            ...getColumnSearchProps('doiTuong'),
          },
          {
            title: 'Nguồn khách',
            dataIndex: 'nguonKhach',
            key: 'nguonKhach',
            onCell: () => ({
              style: {
                minWidth: 120,
              },
            }),
            ...getColumnSearchProps('nguonKhach'),
          },
          {
            title: 'Người thu',
            dataIndex: 'nguoiThu',
            key: 'nguoiThu',
            ...getColumnSearchProps('nguoiThu'),
          },
          {
            title: 'Mã phiếu thu',
            dataIndex: 'maPhieuThu',
            key: 'maPhieuThu',
            ...getColumnSearchProps('maPhieuThu'),
            onCell: () => ({
              style: {
                minWidth: 120,
              },
            }),
          },
          {
            title: 'Số phiếu xuất',
            dataIndex: 'soPhieuXuat',
            key: 'soPhieuXuat',
            ...getColumnSearchProps('soPhieuXuat'),
            onCell: () => ({
              style: {
                minWidth: 120,
              },
            }),
          },
          {
            title: 'Số chứng từ',
            dataIndex: 'soChungTu',
            key: 'soChungTu',
            ...getColumnSearchProps('soChungTu'),
            onCell: () => ({
              style: {
                minWidth: 120,
              },
            }),
          },
          {
            title: 'Mã dịch vụ',
            dataIndex: 'ma',
            key: 'ma',
            onCell: () => ({
              style: {
                minWidth: 90,
              },
            }),
            // ...getColumnSearchProps('ten'),
          },
          {
            title: 'Tên dịch vụ',
            dataIndex: 'ten',
            key: 'ten',
            onCell: () => ({
              style: {
                minWidth: 90,
              },
            }),
            // ...getColumnSearchProps('ten'),
          },
          {
            title: 'Đơn vị',
            dataIndex: 'dvt',
            key: 'dvt',
            width: 60,
            align: 'center',
          },
          {
            title: 'Đơn giá',
            dataIndex: 'donGia',
            key: 'donGia',
            width: 100,
            align: 'right',
            render: (text) => formatNumber(text),
          },
          {
            title: 'SL',
            dataIndex: 'soLuong',
            key: 'soLuong',
            width: 100,
            align: 'center',
          },
          {
            title: 'Thành tiền',
            dataIndex: 'thanhTien',
            key: 'thanhTien',
            width: 100,
            align: 'right',
            render: (text) => formatNumber(text),
          },
          {
            title: 'Tiền giảm',
            dataIndex: 'tienGiamGia',
            key: 'tienGiamGia',
            width: 100,
            align: 'right',
            render: (text) => formatNumber(text),
          },
          {
            title: 'Thanh toán',
            dataIndex: 'thanhToan',
            key: 'thanhToan',
            width: 100,
            align: 'right',
            render: (text) => formatNumber(text),
          },
          {
            title: 'Mã thanh toán',
            dataIndex: 'mathanhtoan',
            key: 'mathanhtoan',
            onCell: () => ({
              style: {
                minWidth: 140,
              },
            }),
            align: 'center',
            ...getColumnSearchProps('mathanhtoan'),
          },
        ]}
        summary={(pageData) => {
          let total = 0
          let price = 0
          let discount = 0
          let count = 0
          let donGias = 0
          pageData.forEach(({ thanhToan, thanhTien, tienGiamGia, soLuong, donGia }) => {
            total += thanhToan
            price += thanhTien
            discount += tienGiamGia
            count += soLuong
            donGias += donGia
          })
          return (
            <Table.Summary fixed>
              <Table.Summary.Row >
                <Table.Summary.Cell index={0} ></Table.Summary.Cell>
                 <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10}></Table.Summary.Cell>
                <Table.Summary.Cell index={11}></Table.Summary.Cell>
                <Table.Summary.Cell index={12}></Table.Summary.Cell>
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
                <Table.Summary.Cell index={15}></Table.Summary.Cell>
                <Table.Summary.Cell index={16}></Table.Summary.Cell>
                <Table.Summary.Cell index={17}></Table.Summary.Cell>
                <Table.Summary.Cell index={18} type="danger" align="right">
                  {formatNumber(donGias)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={19} type="danger" align="center">
                  {count}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={20}>
                  <Text type="danger">{formatNumber(price)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={21} align="right">
                  <Text type="danger">{formatNumber(discount)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={22} align="right">
                  <Text type="danger">{formatNumber(total)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={23}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )
        }}
      />
    </ConfigProvider>
  )
}

export default TableDetail
