import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { demoModalCAction, infoBranchLogin } from '../../store/actions/BranchAction'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import LayoutApp from '../../HOCs/LayoutApp'
import Dashboard from '../../components/layout/Dashboard/Dashboard'
import logoNhi from '../../assets/images/logo/logo_nhiDong315.png'
import logoSan from '../../assets/images/logo/logo_phuSan.jpg'
import logoMat from '../../assets/images/logo/Logo_mat.png'
import logoLao from '../../assets/images/logo/logo_laoKhoa.png'
import logo from '../../assets/images/logo/logoLogin/6logo.jpg'
import logoIvy from '../../assets/images/logo/logo_ivy.png'

import { LazyLoadImage } from 'react-lazy-load-image-component'
const Home = () => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const idBranch = localStorage.getItem('BRANH_LOGIN')
  useEffect(() => {
    dispatch(infoBranchLogin(idBranch))
  }, [])
  return (
    <>
      {infoUser?.tenNhom === 'Ban Giám đốc' ? (
        <Dashboard />
      ) : (
        <div className="h-full relative bg-cyan-50 border-t">
          <div className=" flex flex-col w-full justify-center items-center absolute top-36">
            <LazyLoadImage src={logoIvy} alt="logo nhi" width={300} loading="lazy" />
            <div className="flex justify-center items-center">
              <h2
                className="text-[#229799] text-5xl font-semibold"
                style={{ fontFamily: 'Manrope, sans-serif' }}
              >
                PHÒNG KHÁM ĐA KHOA IVY HEALTH
              </h2>
            </div>
            <div className="text-[#229799]  text-center ">
              <div className="text-xl font-medium">
                <p>
                  <FmdGoodIcon /> 120 Nguyễn Trãi, Phường Bến Thành, Quận 1, Thành Phố Hồ Chí Minh{' '}
                </p>
                <p>
                <LocalPhoneIcon /> 0908.710.710  - 
                 <LocalPhoneIcon />0901.315.315
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Home
