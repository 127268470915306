import React, { useEffect, useState } from 'react'
import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import { addChiNhanhVungSchema } from '../../../schemas/addChiNhanhVungSchema'
import { useDispatch, useSelector } from 'react-redux'
import { suCoService } from '../../../services/suCoService/suCoServvice'
import { addChiNhanhVung } from '../../../store/actions/suCoAction'
function CreateChiNhanhVung({fetchChiNhanhByVung, openVung, setOpenVung,Khu,idVung }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const [branch,setBranch] = useState([])
  const dispatch = useDispatch()
  const handleCancel = () => {
    setOpenVung(false)
  }
  const handleSubmit = (value) => {
    dispatch(addChiNhanhVung(value,fetchChiNhanhByVung, onReset))
  }
  //----- lấy danh sách khu ------//
  const fetchBranh = async () => {
    try {
      const { data } = await suCoService.getAllBranh()
      
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onReset = () => {
    formik.resetForm()
  }
  const onSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idvung: idVung,
      idcn: null,
      nguoitao: infoUser?.idnv,
    },
    validationSchema: addChiNhanhVungSchema,
    onSubmit: (value) => handleSubmit(value),
  })
  useEffect(() => {
    fetchBranh()
  }, [])
  return (
    <Modal
      onOk={formik.handleSubmit}
      okText="Tạo"
      cancelText="Huỷ bỏ"
      open={openVung}
      onCancel={handleCancel}
      title={
        <p className="font-semibold text-xl w-full text-center text-blue-500">
          THÊM CHI NHÁNH KHU VỤC
        </p>
      }
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Khu vực:<span className="text-red-500">(*)</span>
          </label>
          <Select
            showSearch
            options={Khu?.map((khu) => ({
              label: khu.TENVUNG,
              value: khu.IDVUNG,
            }))}
            name="idvung"
            onChange={onSelected('idvung')}
            status={formik.errors.idvung && formik.touched.idvung ? 'error' : null}
            value={formik.values.idvung}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Chi nhánh:<span className="text-red-500">(*)</span>
          </label>
          <Select
            showSearch
            filterOption={(input, option) => {
              const name = option?.label ?? ''
              const searchText = input.toLowerCase()
              return name.toLowerCase().includes(searchText)
            }}
            placeholder="Chọn chi nhánh"
            onChange={onSelected('idcn')}
            value={formik.values.idcn}
            options={branch?.map((item) => ({
              label:item.tenchinhanh,
              value: item.idchinhanh
            }))}
            className="w-full"
            name="nhom"
            status={formik.errors.idcn && formik.touched.idcn ? 'error' : null}
          />
        </div>
      </div>
    </Modal>
  )
}

export default CreateChiNhanhVung
