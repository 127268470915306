import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Table, ConfigProvider, Button, DatePicker, Modal, Drawer, Select } from 'antd'
import { FileTextOutlined, SyncOutlined, DeleteOutlined } from '@ant-design/icons'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { chuyenKhoService } from '../../../../services/ChuyenKho/chuyenKhoService'
// import { xuatSuDungService } from '../../../../services/xuatSuDung/xuatSuDungService'
import { xuatTraNCCService } from '../../../../services/xuatTraNCC/xuatTraNCCService'
import { companyService } from '../../../../services/Company/companyService'
import { branchService } from '../../../../services/branch/branchService'
import { formattedNumber } from '../../../../utils/formattedNumber'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD/MM/YYYY'
const momentDate = 'YYYY-MM-DD'
function ListHangHoa({ keyTabs }) {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [isLoading, setIsLoading] = useState(false)
  const [idKhoCn, setIdKhoCn] = useState(0)
  const [keyword, setKeyword] = useState(null)
  const [isModal, setIsModal] = useState(false)
  const [listKho, setListKho] = useState([])
  const [listVTHH, setListVTHH] = useState([])
  const [listCongTy, setListCongTy] = useState([])
  const [idCongTy, setIdCongTy] = useState(ID_COMPANY)
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [idChiNhanh, setIdChiNhanh] = useState(ID_BRANCH)
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const getListVTHHDaXuat = async () => {
    try {
      setIsLoading(true)
      const { data } = await xuatTraNCCService.getListVTHHDaXuat(
        keyword?.trim()?.length ? keyword?.trim() : null,
        idCongTy,
        idChiNhanh === 0 ? null : idChiNhanh,
        dateForm,
        dateTo,
      )
      setListVTHH(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListChiNhanhByIdCty = async (idct) => {
    try {
      setIsLoading(true)
      const { data: arrChiNhanh } = await branchService.getListBranch(idct)
      setListChiNhanh(arrChiNhanh)
    } catch (error) {
      console.log(error)
      setListChiNhanh([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        const { data: arrCongTy } = await companyService.getAllListCompany()
        setListCongTy(arrCongTy)
        getListChiNhanhByIdCty(idCongTy)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    if (keyTabs === 3) {
      getListVTHHDaXuat()
    }
  }, [keyTabs])
  return (
    <div>
      <div className="flex justify-start items-center mb-2 gap-2">
        <div className="flex justify-start items-center w-[17rem] gap-1">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-2xl flex items-center">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        {/* <Select
          className="w-60"
          options={[
            {
              key: 0,
              value: 0,
              label: 'Tất cả các kho',
            },
            ...listKho?.map((item) => ({
              key: item?.idKhoCN,
              value: item?.idKhoCN,
              label: item?.tenKho,
            })),
          ]}
          value={idKhoCn}
          onChange={(value) => setIdKhoCn(value)}
        /> */}
        {infoUser?.idNhom !== 3 && (
          <>
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              options={listCongTy?.map((item) => ({
                key: `${item?.mact}${item?.idct}`,
                value: item?.idct,
                label: item?.tenct,
              }))}
              value={idCongTy}
              onChange={(value) => {
                setIdCongTy(value)
                setIdChiNhanh(0)
                getListChiNhanhByIdCty(value)
              }}
            />
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  key: `chinhanh0`,
                  value: 0,
                  label: 'Tất cả các chi nhánh',
                },
                ...listChiNhanh?.map((item) => ({
                  key: `chinhanh${item?.idChiNhanh}`,
                  value: item?.idChiNhanh,
                  label: item?.tenChiNhanh,
                })),
              ]}
              value={idChiNhanh}
              onChange={(value) => {
                setIdChiNhanh(value)
              }}
            />
          </>
        )}
        <Input
          placeholder="Nhập từ khóa tìm kiếm..."
          className="w-[17rem]"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button
          onClick={() => {
            setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
            getListVTHHDaXuat()
          }}
          loading={isLoading}
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        ></Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 3,
            fontSize: 13,
          },
        }}
      >
        <Table
          //   scroll={{ x: 1000 }}
          //   pagination={{
          //     pageSize: 20,
          //     showSizeChanger: false,
          //   }}
          scroll={{ x: 2850, y: 695 }}
          pagination={false}
          loading={isLoading}
          bordered
          dataSource={listVTHH}
          columns={[
            {
              key: 1,
              title: 'STT',
              dataIndex: 'stt',
              width: 40,
              fixed: true,
              align: 'center',
            },
            {
              key: 2,
              title: 'Thông tin chung',
              children: [
                {
                  key: 2.1,
                  title: 'Mã phiếu',
                  dataIndex: 'maphieutra',
                  width: 110,
                },
                {
                  key: 2.2,
                  title: 'Ngày xuất',
                  dataIndex: 'ngaytra',
                  align: 'center',
                  width: 130,
                  render: (text, record, index) =>
                    text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-',
                },
                {
                  key: 2.3,
                  title: 'Chi nhánh',
                  dataIndex: 'tenchinhanh',
                  width: 220,
                },
                {
                  key: 2.4,
                  title: 'Kho xuất',
                  dataIndex: 'tenkho',
                  width: 185,
                },
                {
                  key: 2.5,
                  title: 'Mã đối tác',
                  dataIndex: 'madoitac',
                  width: 110,
                },
                {
                  key: 2.6,
                  title: 'Tên đối tác',
                  dataIndex: 'tendoitac',
                },
                {
                  key: 2.7,
                  title: 'Mã hàng',
                  dataIndex: 'mathuoc',
                  width: 110,
                },
                {
                  key: 2.8,
                  title: 'Tên hàng',
                  dataIndex: 'tenthuoc',
                  width: 450,
                },
              ],
            },
            {
              key: 3,
              title: 'Đơn vị chẳn',
              children: [
                {
                  key: 3.1,
                  title: 'SL',
                  dataIndex: 'soluongchan',
                  width: 40,
                  align: 'center',
                  editable: true,
                },
                {
                  key: 3.2,
                  title: 'Đơn vị',
                  dataIndex: 'donvichan',
                  align: 'center',
                  width: 50,
                },
                {
                  key: 3.3,
                  title: 'Đơn giá',
                  dataIndex: 'dongiachan',
                  align: 'right',
                  width: 85,
                  render: (text, record, index) => formattedNumber(text ?? 0),
                },
              ],
            },
            {
              key: 4,
              title: 'Đơn vị lẻ',
              children: [
                {
                  key: 4.1,
                  title: 'Quy cách',
                  dataIndex: 'quycachdonggoi',
                  width: 65,
                  align: 'center',
                },
                {
                  key: 4.2,
                  title: 'SL',
                  dataIndex: 'soluongle',
                  width: 40,
                  align: 'center',
                },
                {
                  key: 4.3,
                  title: 'Đơn vị',
                  dataIndex: 'donvile',
                  align: 'center',
                  width: 50,
                },
                {
                  key: 4.4,
                  title: 'Đơn giá',
                  dataIndex: 'dongiale',
                  align: 'right',
                  width: 85,
                  render: (text, record, index) => formattedNumber(text ?? 0),
                },
              ],
            },
            {
              key: 5,
              title: <p className="text-center">Thành tiền</p>,
              children: [
                {
                  key: 5.1,
                  title: <p className="text-center">Thành tiền</p>,
                  dataIndex: 'thanhtien',
                  width: 85,
                  align: 'right',
                  render: (text, record, index) => formattedNumber(text ?? 0),
                },
              ],
            },
            {
              key: 6,
              title: 'Chi tiết',
              children: [
                {
                  key: 6.1,
                  title: 'Số lô',
                  dataIndex: 'solo',
                  width: 95,
                  align: 'center',
                },
                {
                  key: 6.2,
                  title: 'Hạn dùng',
                  dataIndex: 'handung',
                  align: 'center',
                  width: 80,
                  render: (text, record, index) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
                },
                {
                  key: 6.3,
                  title: 'Số hóa đơn',
                  dataIndex: 'sohoadon',
                  align: 'center',
                  width: 95,
                },
                {
                  key: 6.4,
                  title: 'Mã Số DK',
                  dataIndex: 'masodangky',
                  align: 'center',
                  width: 95,
                },
                {
                  key: 6.5,
                  title: 'Ghi chú',
                  dataIndex: 'ghichu',
                  align: 'left',
                  width: 200,
                },
              ],
            },
            // {
            //   key: 6,
            //   title: '',
            //   dataIndex: 'ACTION',
            //   width: 40,
            //   align: 'center',
            //   fixed: 'right',
            //   render: (text, record, index) => (
            //     <>
            //       <CloseOutlined
            //         className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
            //         onClick={() =>
            //           setListXuatVTHH((prev) =>
            //             prev?.filter((item) => item?.key !== record?.key),
            //           )
            //         }
            //       />
            //     </>
            //   ),
            // },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default ListHangHoa
