import { ConfigProvider, Input, Modal, Select, Table, notification } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { chuyenKhoService } from '../../../../services/ChuyenKho/chuyenKhoService'
import { useDispatch, useSelector } from 'react-redux'
import { CloseSquareOutlined } from '@ant-design/icons'
import { addHangChuyen } from '../../../../store/actions/chuyenKhoAction'
import 'moment/locale/vi'
moment.locale('vi')
const columns = [
  {
    key: 1,
    title: 'Thông tin hàng hoá',
    children: [
      {
        title: 'STT',
        dataIndex: 'STT',
        key: 1.4,
        width: 50,
        align: 'center',
        fixed: 'left',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Mã hàng',
        dataIndex: 'MaHangHoa',
        key: 1.2,
        width: 120,
        fixed: 'left',
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Tên hàng',
        dataIndex: 'tenHangHoa',
        key: 1.1,
        width: 350,
        fixed: 'left',
        // sorter: (a, b) => a.age - b.age,
      },

      // {
      //   title: "Số lô",
      //   dataIndex: "SOLO",
      //   key: 1.3,
      //   width: 120,
      //   // sorter: (a, b) => a.age - b.age,
      // },
    ],
  },
  {
    key: 2,
    title: 'Đơn vị chẳn',
    children: [
      {
        title: 'SL',
        dataIndex: 'SLCHAN',
        key: 3.2,
        width: 90,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Đơn vị',
        dataIndex: 'DVCHAN',
        key: 2.2,
        width: 80,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Tồn',
        dataIndex: 'tkChan',
        key: 2.3,
        width: 60,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
    ],
  },
  {
    key: 3,
    title: 'Đơn vị lẻ',
    children: [
      {
        title: 'Quy cách',
        dataIndex: 'QUYCACH',
        key: 3.1,
        width: 150,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'SL',
        dataIndex: 'SLLE',
        key: 3.2,
        width: 90,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Đơn vị',
        dataIndex: 'DVLE',
        key: 3.3,
        width: 80,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Tồn',
        dataIndex: 'tonKho',
        key: 3.4,
        width: 60,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
    ],
  },

  {
    key: 4,
    title: 'Số lô',
    dataIndex: 'soLo',
    width: 120,
    align: 'center',
  },
  {
    key: 5,
    title: 'Hạn dùng',
    dataIndex: 'hanDung',
    width: 120,
    align: 'center',
  },
  {
    key: 7,
    title: 'MSĐK',
    dataIndex: 'maSoDangKy',
    width: 150,
    align: 'center',
  },
  {
    key: 8,
    title: 'Số HĐ',
    dataIndex: 'soHoaDon',
    width: 120,
    align: 'center',
  },
  {
    key: 9,
    title: 'Đối tác',
    dataIndex: 'tenDoiTac',
    width: 500,
  },
  {
    key: 10,
    title: 'Ghi chú',
    dataIndex: 'ghiChu',
    width: 150,
  },
  {
    key: 6,
    title: '',
    dataIndex: 'action',
    width: 40,
    align: 'center',
    fixed: 'right',
  },
]
const ModalAdd = ({ openAdd, setOpenAdd, showModal }) => {
  const [ton, setTon] = useState([])
  const [production, setProduction] = useState([])
  const [api, contextHolder] = notification.useNotification()
  const { detailPT } = useSelector((state) => state.chuyenKhoReducer)
  const dispatch = useDispatch()
  //------- lấy danh sách tông kho chi nhánh chuyển kho  ---------//
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  const fetchTons = async () => {
    try {
      const { data } = await chuyenKhoService.getTonKhoCN(detailPT?.idKhoXuat)
      // - lấy những giá trị tồn lơn 0 và gán thêm ID = index  để có thể chỉnh đúng hàng hoá
      const filteredData = data.result
        .filter((item) => item.tonKho > 0)
        .map((item, index) => ({ ID: index + 1, ...item }))
      setTon(filteredData)
    } catch (error) {
      console.log(error)
    }
  }
  // nhập ghi chú
  const onChangeNote = (items) => (e) => {
    const { value } = e.target
    const index = production.findIndex((production) => production.ID === items.ID)
    const item = {
      ...production[index],
      ghiChu: value,
    }
    const newProduction = [...production]
    newProduction[index] = item
    setProduction(newProduction)
  }
  // XỬ LÝ TĂNG SỐ LƯỢNG THUỐC
  const handleChangeSL = (items) => (e) => {
    const value = Number(e.target.value)
    if (value + items.soLuong * items.thuocVatTu.quyCachDongGoi > items.tonKho) {
      openNotificationWithIcon('error', 'Thay đổi số lượng', 'Tồn kho không đủ số lượng')
      return
    }
    const index = production.findIndex((production) => production.ID === items.ID)
    const item = {
      ...production[index],
      soLuongLe: value < 0 || value === '' ? 0 : value, // nếu giá trị < 0 or = '' ==> value = 0
    }
    const newProduction = [...production]
    newProduction[index] = item
    setProduction(newProduction)
  }
  // ----------tăng số lượng chẳn ------------
  const handleSLChan = (items) => (e) => {
    const value = Number(e.target.value)
    if (value * items.thuocVatTu.quyCachDongGoi + items.soLuongLe > items.tonKho) {
      openNotificationWithIcon('error', 'Thay đổi số lượng', 'Tồn kho không đủ số lượng')
      return
    }
    const index = production.findIndex((production) => production.ID === items.ID)
    const item = {
      ...production[index],
      soLuong: value < 0 || value === '' ? 0 : value, // nếu giá trị < 0 or = '' ==> value = 0
    }
    const newProduction = [...production]
    newProduction[index] = item
    setProduction(newProduction)
  }
  //XỬ LÍ LẤY THUỐC KHO VT
  const handleKhoVT = (value) => {
    for (let items of production) {
      if (value === items.ID) {
        openNotificationWithIcon('error', 'Thêm thuốc vật tư', 'thuốc vật tư đã được chọn!')
        return
      }
    }
    const index = ton.findIndex((items) => items.ID === value)
    setProduction((prevState) => [
      ...prevState,
      { ...ton[index], soLuongLe: 0, soLuong: 0, ghiChu: '' },
    ])
  }
  // XỬ LÝ XOÁ 1 THUỐC TRONG KHO
  const handleDeleteKhoVTById = (ID) => {
    const updatedProduction = production.filter((items) => items.ID !== ID)
    setProduction(updatedProduction)
  }
  const handleCancel = () => {
    setOpenAdd(false)
    showModal()
    setProduction([])
  }
  const handleOk = () => {
    const listKhoChiTiet = production
      .map((items) => {
        const soLuongLe = items.soLuongLe + items.soLuong * items.thuocVatTu.quyCachDongGoi
        const soLuong = items.soLuong + items.soLuongLe / items.thuocVatTu.quyCachDongGoi

        if (soLuongLe === 0) {
          openNotificationWithIcon('error', 'Lưu phiếu chuyển', 'Số lượng thuốc không được trống!')
          return null // Trả về null nếu số lượng lẻ là 0 để loại bỏ khỏi mảng sau này
        }

        return {
          idThuoc: items.idThuoc,
          soLuong: soLuong,
          trangThaiChuyenKho: 1,
          soLuongLe: soLuongLe,
          soLuongDongGoi: items.thuocVatTu.quyCachDongGoi,
          quyCachDongGoi: items.thuocVatTu.quyCach,
          hanDung: items.hanDung,
          soLo: items.soLo,
          idNhapXuat: detailPT?.idNhapXuat,
          soHoaDon: items.soHoaDon,
          ghiChu: items.ghiChu,
        }
      })
      .filter((item) => item !== null) // Loại bỏ những item có giá trị là null
    if (listKhoChiTiet.length === 0) {
      return
    }
    dispatch(addHangChuyen(detailPT?.idNhapXuat, listKhoChiTiet, handleCancel))
  }

  useEffect(() => {
    fetchTons()
  }, [detailPT])
  return (
    <>
      {contextHolder}
      <Modal
        onOk={handleOk}
        okText="Lưu"
        cancelText="Huỷ bỏ"
        title={<p className="text-lg text-gray-400">THÊM MỚI HÀNG HOÁ</p>}
        open={openAdd}
        width={'70%'}
        onCancel={handleCancel}
      >
        <div className="flex flex-col gap-5">
          <div className="flex gap-2">
            <div className="w-1/4">
              <label className="font-semibold text-gray-400">Chi nhánh chuyển</label>
              <p className="font-semibold text-base">{detailPT?.tenChiNhanhXuat}</p>
            </div>
            <div className="w-1/4">
              <label className="font-semibold text-gray-400">Kho chuyển</label>
              <p className="font-semibold text-base">{detailPT?.tenKhoXuat}</p>
            </div>
            <div className="w-1/4">
              <label className="font-semibold text-gray-400">Chi nhánh nhận</label>
              <p className="font-semibold text-base">{detailPT?.tenChiNhanhNhan}</p>
            </div>
            <div className="w-1/4">
              <label className="font-semibold text-gray-400">Kho nhận</label>
              <p className="font-semibold text-base">{detailPT?.tenKhoNhap}</p>
            </div>
          </div>
          <div>
            <label className="font-semibold text-base">Nhập tìm kiếm hàng hoá:</label>
            <Select
              className="w-full"
              showSearch
              filterOption={(input, option) => {
                const label = option?.label ?? ''
                const soHoaDon = option?.soHoaDon ?? ''
                const soLo = option?.soLo ?? ''
                const maHang = option.maThuoc ?? ''
                const searchText = input.toLowerCase()
                return (
                  label.toLowerCase().includes(searchText) ||
                  soHoaDon.toLowerCase().includes(searchText) ||
                  soLo.toLowerCase().includes(searchText) ||
                  maHang.toLowerCase().includes(searchText)
                )
              }}
              value={null}
              placeholder="Nhập tên hàng hoá, số hoá đơn, số lố, mã hàng"
              onChange={handleKhoVT}
              defaultActiveFirstOption={false}
              // onSearch={debounceDropDown}
              notFoundContent={null}
              options={ton?.map((d) => ({
                value: d.ID,
                label: d.thuocVatTu?.tenBietDuoc,
                maThuoc: d.thuocVatTu?.maThuoc,
                hoatChat: d.thuocVatTu?.tenHoatChat,
                tonKho: d.tonKho,
                handung: d.hanDung,
                soHoaDon: d.soHoaDon,
                soLo: d.soLo,
              }))}
              optionRender={(options) => (
                <ul className="flex">
                  {/* <li className="w-1/12">{options.data.maThuoc}</li> */}
                  <li className="w-8/12  text-pretty ">
                    <p className="font-semibold">
                      {' '}
                      {options.data.label} - {options.data.maThuoc}
                    </p>
                    <i className="text-gray-400 text-sm">{options.data.hoatChat}</i>
                  </li>
                  <li className="w-2/12 ">
                    <p className="font-semibold">Số hoá đơn: {options.data.soHoaDon}</p>
                    <i className="text-gray-400"> Số lô: {options.data.soLo}</i>
                  </li>
                  <li className="w-2/12 ">
                    <p className="font-semibold">
                      Số lượng: <span className="text-blue-500">{options.data.tonKho}</span>
                    </p>
                    <i className="text-gray-400">
                      {' '}
                      Hạn dùng:{' '}
                      {options.data.handung && moment(options.data.handung).format('DD/MM/YYYY')}
                    </i>
                  </li>
                </ul>
              )}
            />
          </div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
                borderRadius: 0,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                },
              },
            }}
          >
            <Table
              scroll={{
                x: 1500,
                y: 400,
              }}
              pagination={false}
              columns={columns}
              dataSource={production?.map((items, index) => ({
                STT: ++index,
                tenHangHoa: items.thuocVatTu?.tenBietDuoc,
                MaHangHoa: items.thuocVatTu?.maThuoc,
                tonKho:
                  items.tonKho -
                  (items.thuocVatTu.quyCachDongGoi * items.soLuong + items.soLuongLe),
                soLo: items.soLo,
                SLCHAN: (
                  <Input
                    size="small"
                    onChange={handleSLChan(items)}
                    value={items.soLuong}
                    type="number"
                  />
                ),
                tkChan: Math.floor(
                  items.tonKho / items.thuocVatTu.quyCachDongGoi -
                    items.soLuong -
                    items.soLuongLe / items.thuocVatTu.quyCachDongGoi,
                ),
                DVCHAN: items.thuocVatTu.donViChan,
                QUYCACH: items.thuocVatTu.quyCach,
                tenDoiTac: items.tenDoiTac,
                soHoaDon: items.soHoaDon,
                maSoDangKy: items.maSoDangKy,
                hanDung: items.hanDung && moment(items.hanDung).format('DD/MM/YYYY'),
                SLLE: (
                  <Input
                    size="small"
                    onChange={handleChangeSL(items)}
                    value={items.soLuongLe}
                    type="number"
                  />
                ),
                DVLE: items.thuocVatTu.dvt,
                ghiChu: <Input size="small" onChange={onChangeNote(items)} value={items.GhiChu} />,
                action: (
                  <div
                    onClick={() => {
                      handleDeleteKhoVTById(items.ID)
                    }}
                  >
                    <CloseSquareOutlined
                      style={{ fontSize: 18 }}
                      className="text-red-500 cursor-pointer"
                    />
                  </div>
                ),
              }))}
            />
          </ConfigProvider>
        </div>
      </Modal>
    </>
  )
}

export default ModalAdd
