import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Input, DatePicker, Select, Spin, Radio, ConfigProvider, Button } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined, SyncOutlined } from '@ant-design/icons'
import { putInfoKhamLamSanKSKAction } from '../../../../../../store/actions/khamSucKhoeAction'
import DivFormKham from './DivFormKham'
// import styled from 'styled-components'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { TextArea } = Input
// const BtnLuu = styled(Button)`
//   color: #fff;
//   border-color: #fff;
//   background-color: #1677ff;

//   &:hover {
//     color: #fff !important;
//     border-color: #2566e8 !important;
//     background-color: #2566e8 !important;
//   }
// `

function TabKhamNoiKhoa({ tabForm }) {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBN, infoBNTTC, infoTTV, infoKhamNoiKhoaKSK } = useSelector(
    (state) => state.khamSucKhoeReducer,
  )

  const initialState = {
    idbnttc: infoBNTTC?.idbnttc,
    tuanhoan: infoKhamNoiKhoaKSK?.tuanhoan ?? '',
    tuanhoanloai: infoKhamNoiKhoaKSK?.tuanhoanloai ?? 1,
    tuanhoanghichu: infoKhamNoiKhoaKSK?.tuanhoanghichu ?? '',
    hohap: infoKhamNoiKhoaKSK?.hohap ?? '',
    hohaploai: infoKhamNoiKhoaKSK?.hohaploai ?? 1,
    hohapghichu: infoKhamNoiKhoaKSK?.hohapghichu ?? '',
    tieuhoa: infoKhamNoiKhoaKSK?.tieuhoa ?? '',
    tieuhoaloai: infoKhamNoiKhoaKSK?.tieuhoaloai ?? 1,
    tieuhoaghichu: infoKhamNoiKhoaKSK?.tieuhoaghichu ?? '',
    thantietnieu: infoKhamNoiKhoaKSK?.thantietnieu ?? '',
    thantietnieuloai: infoKhamNoiKhoaKSK?.thantietnieuloai ?? 1,
    thantietnieughichu: infoKhamNoiKhoaKSK?.thantietnieughichu ?? '',
    noitiet: infoKhamNoiKhoaKSK?.noitiet ?? '',
    noitietloai: infoKhamNoiKhoaKSK?.noitietloai ?? 1,
    noitietghichu: infoKhamNoiKhoaKSK?.noitietghichu ?? '',
    coxuongkhop: infoKhamNoiKhoaKSK?.coxuongkhop ?? '',
    coxuongkhoploai: infoKhamNoiKhoaKSK?.coxuongkhoploai ?? 1,
    coxuongkhopghichu: infoKhamNoiKhoaKSK?.coxuongkhopghichu ?? '',
    thankinh: infoKhamNoiKhoaKSK?.thankinh ?? '',
    thankinhloai: infoKhamNoiKhoaKSK?.thankinhloai ?? 1,
    thankinhghichu: infoKhamNoiKhoaKSK?.thankinhghichu ?? '',
    tamthan: infoKhamNoiKhoaKSK?.tamthan ?? '',
    tamthanloai: infoKhamNoiKhoaKSK?.tamthanloai ?? 1,
    tamthanghichu: infoKhamNoiKhoaKSK?.tamthanghichu ?? '',
    khuyencao: infoKhamNoiKhoaKSK?.khuyencao ?? '',
  }
  const [form, setForm] = useState(initialState)

  const resetForm = () => {
    setForm(initialState)
  }
  const handleChangeForm = (name, value) => {
    setForm((prev) => ({ ...prev, [name]: value }))
  }
  const handleSubmit = () => {
    dispatch(
      putInfoKhamLamSanKSKAction(
        { ...form, idbnttc: infoBNTTC?.idbnttc, bskham: infoUser?.idnv },
        tabForm,
        resetForm,
      ),
    )
  }
  useEffect(() => {
    if (infoBNTTC?.idbnttc !== form?.idbnttc) {
      setForm(initialState)
    }
  }, [tabForm])
  useEffect(() => {
    setForm(initialState)
  }, [infoBNTTC, infoKhamNoiKhoaKSK])
  return (
    <div>
      <div className="flex justify-start items-center">
        <div className="w-[25rem]">
          <span className="font-semibold">BS Khám:&nbsp;</span>
          {infoBNTTC?.idbnttc ? infoKhamNoiKhoaKSK?.tenbskham : ''}
        </div>
        <Button
          type="primary"
          className="ml-auto"
          onClick={handleSubmit}
          disabled={!infoBN || !infoBNTTC || infoBNTTC?.trangthaikhambenh !== 1}
        >
          Lưu
        </Button>
      </div>
      <div className="grid grid-flow-row items-center mt-2 gap-3">
        <DivFormKham
          labelKham={'a. Tuần hoàn'}
          fieldKham={'tuanhoan'}
          fieldLoai={'tuanhoanloai'}
          fieldGhichu={'tuanhoanghichu'}
          form={form}
          handleChangeForm={handleChangeForm}
        />
        <DivFormKham
          labelKham={'b. Hô hấp'}
          fieldKham={'hohap'}
          fieldLoai={'hohaploai'}
          fieldGhichu={'hohapghichu'}
          form={form}
          handleChangeForm={handleChangeForm}
        />
        <DivFormKham
          labelKham={'c. Tiêu hóa'}
          fieldKham={'tieuhoa'}
          fieldLoai={'tieuhoaloai'}
          fieldGhichu={'tieuhoaghichu'}
          form={form}
          handleChangeForm={handleChangeForm}
        />
        <DivFormKham
          labelKham={'d. Thận - tiết niệu'}
          fieldKham={'thantietnieu'}
          fieldLoai={'thantietnieuloai'}
          fieldGhichu={'thantietnieughichu'}
          form={form}
          handleChangeForm={handleChangeForm}
        />
        <DivFormKham
          labelKham={'đ. Nội tiết'}
          fieldKham={'noitiet'}
          fieldLoai={'noitietloai'}
          fieldGhichu={'noitietghichu'}
          form={form}
          handleChangeForm={handleChangeForm}
        />
        <DivFormKham
          labelKham={'e. Cơ xương khớp'}
          fieldKham={'coxuongkhop'}
          fieldLoai={'coxuongkhoploai'}
          fieldGhichu={'coxuongkhopghichu'}
          form={form}
          handleChangeForm={handleChangeForm}
        />
        <DivFormKham
          labelKham={'g. Thần kinh'}
          fieldKham={'thankinh'}
          fieldLoai={'thankinhloai'}
          fieldGhichu={'thankinhghichu'}
          form={form}
          handleChangeForm={handleChangeForm}
        />
        <DivFormKham
          labelKham={'h. Tâm thần'}
          fieldKham={'tamthan'}
          fieldLoai={'tamthanloai'}
          fieldGhichu={'tamthanghichu'}
          form={form}
          handleChangeForm={handleChangeForm}
        />
        <div className="grid grid-cols-12 gap-1 items-center text-[12.5px] border rounded-md p-1 bg-slate-50">
          <div className="font-semibold text-end">Khuyến cáo:</div>
          <TextArea
            className="col-span-11 w-full text-[12.5px]"
            rows={2}
            maxLength={2000}
            value={form?.khuyencao}
            onChange={(e) => handleChangeForm('khuyencao', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default TabKhamNoiKhoa
