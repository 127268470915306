import * as typeAction from '../constants/constants'
export const postInfoBnXQuangAction = (form, list, resetForm) => ({
  type: typeAction.POST_INFO_BN_XQUANG,
  form,
  list,
  resetForm,
})
export const putInfoBnXQuangAction = (form, list, isPrint, resetForm) => ({
  type: typeAction.PUT_INFO_BN_XQUANG,
  form,
  list,
  isPrint,
  resetForm,
})
