import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import ListSelling from './ListSelling.jsx/ListSelling'
import BIlling from './Billing/BIlling'
import { drugstoreService } from '../../../services/drugstore/drugstoreService'
import { Tabs } from 'antd'
import PTVacXin from './PTThuoc/PTVacXin'
import ToastCus from '../../../utils/Toast'
const Selling = () => {
  const { service } = useSelector((state) => state.drugstoreReducer)
  const [check, setCheck] = useState(false)
  const [checkTraThuoc, setCheckTraThuoc] = useState(false)
  const [lyDo, setLyDo] = useState([]) //listLdKhongLayThuoc
  const [lyDoTraToa, setLyDoTraToa] = useState([]) //listLdKhongLayThuoc
  const [idLyDo, setIdLyDo] = useState(7)
  const [lyDoKhac, setLyDoKhac] = useState(null)
  const handleCheckBox = () => {
    if (!service?.BSKEDON || !service?.NGAYKEDON) {
      ToastCus.fire({
        icon: 'error',
        title: 'Bác sĩ đang kê toa lại không thể chỉnh sửa!',
      })
      return
    }
    setCheck(!check)
  }
  const fetchLyDo = async () => {
    try {
      const { data: listLdKhongLayThuoc } = await drugstoreService.getLyDoKhongLayThuoc()
      const { data: listLdTraToaChoBs } = await drugstoreService.getLyDoTraToaChoBs()
      setLyDo(listLdKhongLayThuoc)
      setLyDoTraToa(listLdTraToaChoBs)
      setIdLyDo(listLdKhongLayThuoc[0]?.idld)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchLyDo()
  }, [])
  return (
    <>
      <div className="h-full w-full bg-[#EBEBEB] p-3">
        <div className="p-2 bg-white rounded-xl border h-full">
          <Tabs
            items={[
              {
                key: 1,
                label: 'Xuất vắc xin',
                children: (
                  <div className="flex border rounded-lg">
                    <div className="w-4/6 h-full border-r ">
                      <ListSelling
                        lyDo={lyDo}
                        lyDoTraToa={lyDoTraToa}
                        idLyDo={idLyDo}
                        setIdLyDo={setIdLyDo}
                        check={check}
                        setCheck={setCheck}
                        handleCheckBox={handleCheckBox}
                        checkTraThuoc={checkTraThuoc}
                        setCheckTraThuoc={setCheckTraThuoc}
                        lyDoKhac={lyDoKhac}
                        setLyDoKhac={setLyDoKhac}
                      />
                    </div>
                    <div className="w-2/6 h-full ">
                      <BIlling
                        idLyDo={idLyDo}
                        check={check}
                        setIdLyDo={setIdLyDo}
                        setCheck={setCheck}
                        lyDo={lyDo}
                        checkTraThuoc={checkTraThuoc}
                        setCheckTraThuoc={setCheckTraThuoc}
                        lyDoKhac={lyDoKhac}
                      />
                    </div>
                  </div>
                ),
              },
              {
                label: 'Phiếu thu',
                key: 2,
                children: <PTVacXin />,
              },
            ]}
          />
        </div>
      </div>
      {/* <div className="h-full w-full bg-[#EBEBEB]">
        <div className="p-2 flex gap-2 h-full">
          <div
            className="w-4/6 h-full bg-white border rounded-lg"
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <ListSelling lyDo={lyDo} idLyDo={idLyDo} setIdLyDo={setIdLyDo} check={check} setCheck={setCheck} handleCheckBox={handleCheckBox} />
          </div>
          <div
            className="w-2/6 h-full bg-white border rounded-lg"
            style={{
              boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
            }}
          >
            <BIlling idLyDo={idLyDo} check={check} setIdLyDo={setIdLyDo} setCheck={setCheck} lyDo={lyDo} />
          </div>
        </div>
      </div> */}
    </>
  )
}

export default Selling
