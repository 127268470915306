import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, DatePicker, Select, Table, TreeSelect, Typography } from 'antd'
import { branchService } from '../../../services/branch/branchService'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import dayjs from 'dayjs'
import { IconButton } from '@mui/material'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import * as XLSX from 'xlsx'
import { https } from '../../../services/apiService'
import { formattedNumber } from '../../../utils/formattedNumber'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD/MM/YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
const { Text } = Typography
function TabTMTD(props) {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [value, setValue] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    if (PkDangNhap?.idChuyenKhoa === 3) {
      setValue([BranchLogin])
    }
  }, [PkDangNhap])

  const dispatch = useDispatch()
  const fetchBranch = async (id) => {
    const result = await branchService.getListBranch(id)
    //get list branch Nhi
    const listBranchTMTD = result.data.filter((value) => value.idChuyenKhoa === 3)
    setBranch(listBranchTMTD)
  }

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const idChiNhanhs = value.map((id) => `idChiNhanhs=${id}`).join('&')
      const response = await https.get('/ThongKeThang/ThongKeThangTMTD', {
        params: {
          idCongTy: company,
          fromDate: dateForm,
          toDate: dateTo,
        },
        paramsSerializer: (params) => {
          return `${idChiNhanhs}&${new URLSearchParams(params).toString()}`
        },
      })
      setData(response.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const exportToExcel = () => {
    const formattedData = data.map((item) => {
      return {
        'Tên Chi Nhánh': item.tenchinhanh,
        Ngày: item.ngay ? moment(item.ngay).format('DD/MM/YYYY') : '-',
        'Ngày Khai trương': item.ngaykhaitruong
          ? moment(item.ngaykhaitruong).format('DD/MM/YYYY')
          : '-',
        'Khám nội tổng quát': item.khamnoitongquat,
        'Khám PHCN': item.khamphcn,
        'Khám sàng lọc tiêm chủng': item.khamsangloctiemchung,
        'Làm thủ thuật': item.luotkhamthuthuat,
        'Vãng lai tái khám miễn phí': item.taikhammienphivanglai,
        'Lần đầu thẻ thành viên': item.luotkhachlandauthe,
        'Tái khám thẻ thành viên': item.luotkhachtaikhamthe,
        'Khám tại phòng khám lần đầu': item.khachdenkhamlandau,
        'Đến mua thuốc từ dịch vụ': item.luotkhachtoathuoc,
        'Mua lẻ thuốc': item.luotkhachtoabanle,
        'Tổng lượt khách': item.tongluotkhach,
        'Dịch vụ khám bệnh': item.doanhthukhambenh,
        'Dịch vụ khám PHCN': item.doanhthukhamphcn,
        'Dịch vụ xét nghiệm': item.doanhthuxetnghiem,
        'Dịch vụ siêu âm': item.doanhthusieuam,
        'Dịch vụ khác': item.doanhthukhac,
        'Bán thuốc từ dịch vụ': item.doanhthutoathuoc,
        'Bán lẻ thuốc': item.doanhthutoabanle,
        'Vắc xin': item.doanhthuvacxin,
        'Lợi nhuận doanh thu bán thuốc': item.loinhuanbanthuoc,
        'Số lượng bán thẻ thành viên': item.soluongbanthethanhvien,
        'Doanh thu thẻ thành viên': item.doanhthuthethanhvien,
        'Tổng doanh thu': item.tongdoanhthu,
        'Thanh toán thẻ bằng máy Vietcombank': item.thanhtoanpos,
        'Chuyển khoản Vietcombank': item.thanhtoanchuyenkhoan,
        'Tiền mặt nộp ngân hàng': item.tienmat,
      }
    })
    const ws = XLSX.utils.json_to_sheet(formattedData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, 'ThongKeThangTMTD.xlsx')
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Ngày',
      dataIndex: 'ngay',
      key: 'ngay',
      width: 100,
      fixed: 'left',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      fixed: 'left',
      width: 250,
    },
    {
      title: 'Ngày khai trương',
      dataIndex: 'ngaykhaitruong',
      key: 'ngaykhaitruong',
      width: 150,
      fixed: 'left',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
    },

    {
      title: 'Khám nội tổng quát',
      dataIndex: 'khamnoitongquat',
      key: 'khamnoitongquat',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khám PHCN',
      dataIndex: 'khamphcn',
      key: 'khamphcn',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khám sàng lọc tiêm chủng',
      dataIndex: 'khamsangloctiemchung',
      key: 'khamsangloctiemchung',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Làm thủ thuật',
      dataIndex: 'luotkhamthuthuat',
      key: 'luotkhamthuthuat',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Vãng lai tái khám miễn phí',
      dataIndex: 'taikhammienphivanglai',
      key: 'taikhammienphivanglai',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lần đầu thẻ thành viên',
      dataIndex: 'luotkhachlandauthe',
      key: 'luotkhachlandauthe',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tái khám thẻ thành viên',
      dataIndex: 'luotkhachtaikhamthe',
      key: 'luotkhachtaikhamthe',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Khám tại phòng khám lần đầu',
      dataIndex: 'khachdenkhamlandau',
      key: 'khachdenkhamlandau',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Đến mua thuốc từ dịch vụ',
      dataIndex: 'luotkhachtoathuoc',
      key: 'luotkhachtoathuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Mua lẻ thuốc',
      dataIndex: 'luotkhachtoabanle',
      key: 'luotkhachtoabanle',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tổng lượt khách',
      dataIndex: 'tongluotkhach',
      key: 'tongluotkhach',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },

    {
      title: 'Dịch vụ khám bệnh',
      dataIndex: 'doanhthukhambenh',
      key: 'doanhthukhambenh',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Dịch vụ khám PHCN',
      dataIndex: 'doanhthukhamphcn',
      key: 'doanhthukhamphcn',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Dịch vụ xét nghiệm',
      dataIndex: 'doanhthuxetnghiem',
      key: 'doanhthuxetnghiem',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Dịch vụ siêu âm',
      dataIndex: 'doanhthusieuam',
      key: 'doanhthusieuam',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Dịch vụ khác',
      dataIndex: 'doanhthukhac',
      key: 'doanhthukhac',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Bán thuốc từ dịch vụ',
      dataIndex: 'doanhthutoathuoc',
      key: 'doanhthutoathuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Bán lẻ thuốc',
      dataIndex: 'doanhthutoabanle',
      key: 'doanhthutoabanle',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Vắc xin',
      dataIndex: 'doanhthuvacxin',
      key: 'doanhthuvacxin',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Lợi nhuận doanh thu bán thuốc',
      dataIndex: 'loinhuanbanthuoc',
      key: 'loinhuanbanthuoc',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số lượng bán thẻ thành viên',
      dataIndex: 'soluongbanthethanhvien',
      key: 'soluongbanthethanhvien',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Doanh thu thẻ thành viên',
      dataIndex: 'doanhthuthethanhvien',
      key: 'doanhthuthethanhvien',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tổng doanh thu',
      dataIndex: 'tongdoanhthu',
      key: 'tongdoanhthu',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Thanh toán thẻ bằng máy Vietcombank',
      dataIndex: 'thanhtoanpos',
      key: 'thanhtoanpos',
      align: 'right',
      width: 350,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Chuyển khoản Vietcombank',
      dataIndex: 'thanhtoanchuyenkhoan',
      key: 'thanhtoanchuyenkhoan',
      align: 'right',
      width: 350,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tiền mặt nộp ngân hàng',
      dataIndex: 'tienmat',
      key: 'tienmat',
      align: 'right',
      width: 150,
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongTienNgay',
      key: 'tongTienNgay',
      width: 100,
      align: 'right',
      fixed: 'right',
      render: (_, record) => {
        const total = record.tienmat + record.thanhtoanpos + record.thanhtoanchuyenkhoan
        return formattedNumber(total)
      },
    },
  ]

  return (
    <div>
      <div className="flex gap-5 justify-between border-dotted border-b-0 p-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value)
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-80">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
            {/* <Select
                  onChange={(value) => setIdChiNhanh(value)}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={[
                    { label: 'Tất cả', value: null },
                    ...branch.map(({ idChiNhanh, tenChiNhanh }) => ({
                      label: tenChiNhanh,
                      value: idChiNhanh,
                    })),
                  ]}
                  value={idChiNhanh}
                  className="w-full"
                /> */}
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
              // picker="month"
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
              // picker="month"
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={fetchData} color="primary" aria-label="sync" size="small">
              <SyncIcon />
            </IconButton>
          </div>
        </div>
        <div className="font-semibold">
          <Button
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 15,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1500,
              y: 660,
            }}
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
            loading={isLoading}
            summary={(pageData) => {
              let totalkhamnoitongquat = 0
              let totalkhamphcn = 0
              let totalkhamsangloctiemchung = 0
              let totaluotkhamthuthuat = 0
              let totaltaikhammienphivanglai = 0
              let totalluotkhachlandauthe = 0
              let totalluotkhachtaikhamthe = 0
              let totalkhachdenkhamlandau = 0
              let totalluotkhachtoathuoc = 0
              let totalluotkhachtoabanle = 0
              let totaltongluotkhach = 0
              let totaldoanhthukhambenh = 0
              let totaldoanhthukhamphcn = 0
              let totaldoanhthuxetnghiem = 0
              let totaldoanhthusieuam = 0
              let totaldoanhthukhac = 0
              let totaldoanhthutoathuoc = 0
              let totaldoanhthutoabanle = 0
              let totaldoanhthuvacxin = 0
              let totalloinhuanbanthuoc = 0
              let totalsoluongbanthethanhvien = 0
              let totaldoanhthuthethanhvien = 0
              let totaltongdoanhthu = 0
              let totalthanhtoanpos = 0
              let totalthanhtoanchuyenkhoan = 0
              let totaltienmat = 0
              let totaltongTienNgay = 0
              pageData.forEach(
                ({
                  khamnoitongquat,
                  khamphcn,
                  khamsangloctiemchung,
                  luotkhamthuthuat,
                  taikhammienphivanglai,
                  luotkhachlandauthe,
                  luotkhachtaikhamthe,
                  khachdenkhamlandau,
                  luotkhachtoathuoc,
                  luotkhachtoabanle,
                  tongluotkhach,
                  doanhthukhambenh,
                  doanhthukhamphcn,
                  doanhthuxetnghiem,
                  doanhthusieuam,
                  doanhthukhac,
                  doanhthutoathuoc,
                  doanhthutoabanle,
                  doanhthuvacxin,
                  loinhuanbanthuoc,
                  soluongbanthethanhvien,
                  doanhthuthethanhvien,
                  tongdoanhthu,
                  thanhtoanpos,
                  thanhtoanchuyenkhoan,
                  tienmat,
                }) => {
                  totalkhamnoitongquat += khamnoitongquat
                  totalkhamphcn += khamphcn
                  totalkhamsangloctiemchung += khamsangloctiemchung
                  totaluotkhamthuthuat += luotkhamthuthuat
                  totaltaikhammienphivanglai += taikhammienphivanglai
                  totalluotkhachlandauthe += luotkhachlandauthe
                  totalluotkhachtaikhamthe += luotkhachtaikhamthe
                  totalkhachdenkhamlandau += khachdenkhamlandau
                  totalluotkhachtoathuoc += luotkhachtoathuoc
                  totalluotkhachtoabanle += luotkhachtoabanle
                  totaltongluotkhach += tongluotkhach
                  totaldoanhthukhambenh += doanhthukhambenh
                  totaldoanhthukhamphcn += doanhthukhamphcn
                  totaldoanhthuxetnghiem += doanhthuxetnghiem
                  totaldoanhthusieuam += doanhthusieuam
                  totaldoanhthukhac += doanhthukhac
                  totaldoanhthutoathuoc += doanhthutoathuoc
                  totaldoanhthutoabanle += doanhthutoabanle
                  totaldoanhthuvacxin += doanhthuvacxin
                  totalloinhuanbanthuoc += loinhuanbanthuoc
                  totalsoluongbanthethanhvien += soluongbanthethanhvien
                  totaldoanhthuthethanhvien += doanhthuthethanhvien
                  totaltongdoanhthu += tongdoanhthu
                  totalthanhtoanpos += thanhtoanpos
                  totalthanhtoanchuyenkhoan += thanhtoanchuyenkhoan
                  totaltienmat += tienmat
                  totaltongTienNgay += thanhtoanpos + thanhtoanchuyenkhoan + tienmat
                },
              )
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={4}>
                      <Text strong>Tổng cộng</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totalkhamnoitongquat)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totalkhamphcn)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totalkhamsangloctiemchung)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaluotkhamthuthuat)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totaltaikhammienphivanglai)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totalluotkhachlandauthe)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totalluotkhachtaikhamthe)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totalkhachdenkhamlandau)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totalluotkhachtoathuoc)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totalluotkhachtoabanle)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaltongluotkhach)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthukhambenh)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthukhamphcn)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totaldoanhthuxetnghiem)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthusieuam)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthukhac)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthutoathuoc)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={21} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthutoabanle)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={22} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthuvacxin)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={23} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totalloinhuanbanthuoc)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={24} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totalsoluongbanthethanhvien)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={25} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totaldoanhthuthethanhvien)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={26} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaltongdoanhthu)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={27} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totalthanhtoanpos)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={28} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totalthanhtoanchuyenkhoan)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={29} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaltienmat)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={30} align="right" fixed>
                      <span style={{ color: 'red' }}>{formattedNumber(totaltongTienNgay)}</span>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

TabTMTD.propTypes = {}

export default TabTMTD
