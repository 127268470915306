import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import { ConfigProvider, DatePicker, Select, Table, Button as ButtonAntd, Tabs } from 'antd'
import Button from '@mui/material/Button'
import CachedIcon from '@mui/icons-material/Cached'
import { FileTextOutlined } from '@ant-design/icons'
import InfoBN from './InfoBN/InfoBN'
import VatTu from './VatTu/VatTu'
import moment from 'moment'
import dayjs from 'dayjs'
import {
  getListVTTieuHaoThuThuatAction,
  getListVTTHThuThuatTheoKhoAction,
} from '../../../store/actions/thuThuatAction'
import {
  getListBNChoTCAction,
  getListBNDaTCAction,
  getInfoBNTiemChungAction,
} from '../../../store/actions/tiemChungAction'
import { branchService } from '../../../services/branch/branchService'
import { https } from '../../../services/apiService'
import 'moment/locale/vi'
moment.locale('vi')
const TiemChung = () => {
  const dispatch = useDispatch()
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const dateFormatAPI = 'YYYY-MM-DD'
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { listVTBN } = useSelector((state) => state.thuThuatReducer)
  const { listBNCho, listBNDaThucHien, infoBNTiemChung } = useSelector(
    (state) => state.tiemChungReducer,
  )
  // const [vatTu, setVatTu] = useState([])
  const [branchs, setBranch] = useState([])
  const [pending, setPending] = useState([])
  const [infoBN, setInfoBN] = useState(null)
  const [branchPending, setBranchPendding] = useState(ID_BRANCH)
  const [branchDone, setBranchDone] = useState(ID_BRANCH)
  const [dateFrom, setDateFrom] = useState(now.format(dateFormatAPI))
  const [dateTo, setDateTo] = useState(now.format(dateFormatAPI))
  const [khoVatTu, setKhoVatTu] = useState(1) // kho vật tư mặc định là điều dưỡng
  //-------- lấy danh sách chi nhánh theo công ty đăng nhập -------//
  const fetchBranchByCompany = async () => {
    try {
      const result = await branchService.getListBranch(PkDangNhap?.idCongTy)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  // -------------- lấy thông tin chi tiết bệnh nhân ------------/
  const onClickDetailBN = async (record) => {
    try {
      const { data } = await https.get(`BenhNhanTTC/GetByIDBNTTC?id=${record.idbnttc}`)
      dispatch(getInfoBNTiemChungAction(record.idbnttc, PkDangNhap?.idChiNhanh))
      setInfoBN(data)
    } catch (error) {
      console.log(error)
    }
  }
  const reloadListVTTieuHao = () => {
    //dispatch(getListVTTieuHaoThuThuatAction(PkDangNhap?.idChiNhanh))
    dispatch(getListVTTHThuThuatTheoKhoAction(PkDangNhap?.idChiNhanh, khoVatTu)) // khoVatTu = 1 là kho điều dưỡng, khoVatTu = 2 là kho cấp cứu
  }
  const reloadListBNChoTC = () => {
    dispatch(getListBNChoTCAction(branchPending))
  }
  const reloadListBNDaTC = () => {
    dispatch(getListBNDaTCAction(branchDone, dateFrom, dateTo))
  }
  useEffect(() => {
    if (PkDangNhap) {
      reloadListBNChoTC()
      reloadListBNDaTC()
      reloadListVTTieuHao()
      // dispatch(getListBNChoAction(PkDangNhap?.idChiNhanh))
      // dispatch(getListBNDaThucHienAction(PkDangNhap?.idChiNhanh, dateFrom, dateTo))
      fetchBranchByCompany()
    }
  }, [PkDangNhap])
  useEffect(() => {
    reloadListVTTieuHao()
  }, [khoVatTu, infoBNTiemChung])
  return (
    <>
      <div className="h-full p-3 bg-[#EFEFEF]">
        <div className="h-full bg-white border rounded-xl p-3">
          <div className="flex gap-2 h-full">
            <div className="w-1/4 border h-full rounded-lg">
              <div className="border-b p-2 h-1/2">
                <h2 className="font-semibold  text-lg text-gray-500">Chờ thực hiện</h2>
                <div className="">
                  <div className=" flex gap-2">
                    <Select
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      value={branchPending}
                      onChange={(value) => {
                        setBranchPendding(value)
                      }}
                      showSearch
                      options={branchs.map((items) => ({
                        label: items.tenChiNhanh,
                        value: items.idChiNhanh,
                      }))}
                      className="w-full"
                    />
                    <Button
                      onClick={reloadListBNChoTC}
                      //className="w-1/4"
                      variant="text"
                      size="small"
                      //endIcon={<CachedIcon />}
                    >
                      <CachedIcon />
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <ConfigProvider theme={{ token: { padding: 5 } }}>
                    <Table
                      //   onRow={(record) => ({
                      //     onClick: () => onClickBNPending(record),
                      //   })}
                      pagination={false}
                      dataSource={listBNCho ?? []}
                      columns={[
                        {
                          title: 'STT',
                          dataIndex: 'stt',
                          key: 'stt',
                          width: 40,
                          align: 'center',
                          render: (text, record, index) => ++index,
                        },
                        {
                          title: 'Mã BN',
                          dataIndex: 'mabenhnhan',
                          key: 'mabenhnhan',
                          width: 120,
                        },
                        {
                          title: 'Tên bệnh nhân',
                          dataIndex: 'tenbenhnhan',
                          key: 'tenbenhnhan',
                        },
                      ]}
                      onRow={(record) => ({
                        onClick: () => onClickDetailBN(record),
                      })}
                      bordered
                      scroll={{ y: 295 }}
                    />
                  </ConfigProvider>
                </div>
              </div>
              <div className=" p-2 h-1/2">
                <div className="flex justify-between">
                  <h2 className="font-semibold text-lg text-gray-500">Đã thực hiện</h2>
                  {/* <Button
                    //   onClick={goToPhieuThu}
                    size="small"
                    color="success"
                    startIcon={<FileTextOutlined />}
                  >
                    Phiếu
                  </Button> */}
                </div>

                <div className="flex items-center justify-center">
                  <DatePicker
                    allowClear={false}
                    format={dateFormat}
                    className="w-2/5"
                    maxDate={dayjs(now)}
                    defaultValue={dayjs(dateFrom)}
                    value={dayjs(dateFrom)}
                    onChange={(date, dateString) => {
                      setDateFrom(moment(dateString, dateFormat).format(dateFormatAPI))
                      // setDateTo(moment(dateString, dateFormat).format(dateFormatAPI))
                    }}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 w-1/5"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                  </svg>

                  <DatePicker
                    format={dateFormat}
                    allowClear={false}
                    className="w-2/5"
                    maxDate={dayjs(now)}
                    minDate={dayjs(dateFrom)}
                    defaultValue={dayjs(dateTo)}
                    value={dayjs(dateTo)}
                    onChange={(date, dateString) => {
                      setDateTo(moment(dateString, dateFormat).format(dateFormatAPI))
                    }}
                  />
                </div>
                <div className="mt-2">
                  <div className=" flex gap-2">
                    <Select
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      onChange={(value) => {
                        setBranchDone(value)
                      }}
                      value={branchDone}
                      showSearch
                      options={branchs.map((items) => ({
                        label: items.tenChiNhanh,
                        value: items.idChiNhanh,
                      }))}
                      className="w-full"
                    />
                    <Button
                      onClick={reloadListBNDaTC}
                      //className="w-1/4"
                      variant="text"
                      size="small"
                      //endIcon={<CachedIcon />}
                    >
                      <CachedIcon />
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <ConfigProvider theme={{ token: { padding: 5 } }}>
                    <Table
                      pagination={false}
                      dataSource={listBNDaThucHien ?? []}
                      columns={[
                        {
                          title: 'STT',
                          dataIndex: 'stt',
                          key: 'stt',
                          width: 40,
                          align: 'center',
                          render: (text, record, index) => ++index,
                        },
                        {
                          title: 'Mã BN',
                          dataIndex: 'mabenhnhan',
                          key: 'mabenhnhan',
                          width: 120,
                        },
                        {
                          title: 'Tên bệnh nhân',
                          dataIndex: 'tenbenhnhan',
                          key: 'tenbenhnhan',
                        },
                      ]}
                      onRow={(record) => ({
                        onClick: () => onClickDetailBN(record),
                      })}
                      bordered
                      scroll={{ y: 265 }}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
            <div className="w-3/4">
              <div className="h-full border rounded-lg p-2">
                <div className="mb-2">
                  <InfoBN infoBN={infoBN} />
                </div>
                <div>
                  <VatTu
                    formLoad={{
                      idcn: PkDangNhap?.idChiNhanh,
                      tungay: dateFrom,
                      denngay: dateTo,
                    }}
                    infoBN={infoBN}
                    khoVatTu={khoVatTu}
                    setKhoVatTu={setKhoVatTu}
                    reloadListVTTieuHao={reloadListVTTieuHao}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TiemChung
