import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, DatePicker, Select, Table, TreeSelect, Typography } from 'antd'
import { branchService } from '../../../services/branch/branchService'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import dayjs from 'dayjs'
import { IconButton } from '@mui/material'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import * as XLSX from 'xlsx'
import axios from 'axios'
import { https } from '../../../services/apiService'
import { render } from 'bwip-js'
import { formattedNumber } from '../../../utils/formattedNumber'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD/MM/YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
const { Text } = Typography

function TabNhiKhoa(props) {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [value, setValue] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    if (PkDangNhap?.idChuyenKhoa === 1) {
      setValue([BranchLogin])
    }
  }, [PkDangNhap])

  const dispatch = useDispatch()
  const fetchBranch = async (id) => {
    const result = await branchService.getListBranch(id)
    //get list branch Nhi
    const listBranchNhi = result.data.filter((value) => value.idChuyenKhoa === 1)
    setBranch(listBranchNhi)
  }

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const idChiNhanhs = value.map((id) => `idChiNhanhs=${id}`).join('&')
      const response = await https.get('/ThongKeThang/ThongKeThangNhi', {
        params: {
          idCongTy: company,
          fromDate: dateForm,
          toDate: dateTo,
        },
        paramsSerializer: (params) => {
          return `${idChiNhanhs}&${new URLSearchParams(params).toString()}`
        },
      })
      setData(response.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn phòng khám ',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Ngày',
      dataIndex: 'ngay',
      key: 'ngay',
      width: 100,
      fixed: 'left',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      fixed: 'left',
      width: 250,
    },
    {
      title: 'Ngày khai trương',
      dataIndex: 'ngaykhaitruong',
      key: 'ngaykhaitruong',
      width: 150,
      fixed: 'left',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
    },
    {
      title: 'Lượt khách',
      dataIndex: 'luotkhach',
      key: 'luotkhach',
      align: 'center',
      children: [
        {
          title: 'Khám có thu phí',
          dataIndex: 'khamthuphi',
          key: 'khamthuphi',
          align: 'right',
          width: 150,
        },
        {
          title: 'Khám sàng lọc tiêm chủng',
          dataIndex: 'khamsangloctiemchung',
          key: 'khamsangloctiemchung',
          align: 'right',
          width: 150,
        },
        {
          title: 'Khám sức khỏe',
          dataIndex: 'khamsuckhoe',
          key: 'khamsuckhoe',
          align: 'right',
          width: 150,
        },
        {
          title: 'Vãng lai tái khám miễn phí',
          dataIndex: 'taikhammienphivanglai',
          key: 'taikhammienphivanglai',
          align: 'right',
          width: 150,
        },
        {
          title: 'Lần đầu thẻ thành viên',
          dataIndex: 'luotkhachlandauthe',
          key: 'luotkhachlandauthe',
          align: 'right',
          width: 150,
        },
        {
          title: 'Tái khám thẻ thành viên',
          dataIndex: 'luotkhachtaikhamthe',
          key: 'luotkhachtaikhamthe',
          align: 'right',
          width: 150,
        },
        {
          title: 'Đến khám tại phòng khám lần đầu',
          dataIndex: 'khachdenkhamlandau',
          key: 'khachdenkhamlandau',
          align: 'right',
          width: 150,
        },
        {
          title: 'Mua thuốc từ dịch vụ',
          dataIndex: 'luotkhachtoathuoc',
          key: 'luotkhachtoathuoc',
          align: 'right',
          width: 150,
        },
        {
          title: 'Mua lẻ thuốc',
          dataIndex: 'luotkhachtoabanle',
          key: 'luotkhachtoabanle',
          align: 'right',
          width: 150,
        },
        {
          title: 'Tổng lượt khách',
          dataIndex: 'tongluotkhach',
          key: 'tongluotkhach',
          align: 'right',
          width: 220,
          render: (text) => text.toLocaleString(),
        },
      ],
    },
    {
      title: 'Doanh thu',
      dataIndex: 'doanhthu',
      key: 'doanhthu',
      align: 'center',
      children: [
        {
          title: 'Khám bệnh',
          dataIndex: 'doanhthudichvu',
          key: 'doanhthudichvu',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'Xét nghiệm',
          dataIndex: 'doanhthuxetnghiem',
          key: 'doanhthuxetnghiem',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'Bán thuốc từ dịch vụ',
          dataIndex: 'doanhthutoathuoc',
          key: 'doanhthutoathuoc',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'Bán lẻ thuốc',
          dataIndex: 'doanhthutoabanle',
          key: 'doanhthutoabanle',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'DT vắc xin',
          dataIndex: 'doanhthuvacxin',
          key: 'doanhthuvacxin',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'Lợi nhuận doanh thu bán thuốc',
          dataIndex: 'loinhuanbanthuoc',
          key: 'loinhuanbanthuoc',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'Thẻ thành viên',
          dataIndex: 'doanhthuthethanhvien',
          key: 'doanhthuthethanhvien',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'Thẻ thành viên bạc',
          dataIndex: 'doanhthuthebac',
          key: 'doanhthuthebac',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'Thẻ thành viên vàng',
          dataIndex: 'doanhthuthevang',
          key: 'doanhthuthevang',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'Dịch vụ khác',
          dataIndex: 'doanhthukhac',
          key: 'doanhthukhac',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        // {
        //   title: 'Tổng doanh thu',
        //   dataIndex: 'tongdoanhthu',
        //   align: 'right',
        //   key: 'tongdoanhthu',
        //   width: 150,
        //   render: (text) => formattedNumber(text),
        // },
      ],
    },
    {
      title: 'Số lượng bán thẻ',
      dataIndex: 'soluongthe',
      key: 'soluongthe',
      align: 'center',
      children: [
        {
          title: 'Thành viên',
          dataIndex: 'soluongbanthethanhvien',
          key: 'soluongbanthethanhvien',
          width: 150,
          align: 'right',
        },
        {
          title: 'Thẻ bạc',
          dataIndex: 'soluongbanthebac',
          key: 'soluongbanthebac',
          width: 150,
          align: 'right',
        },
        {
          title: 'Thẻ vàng',
          dataIndex: 'soluongbanthevang',
          key: 'soluongbanthevang',
          width: 150,
          align: 'right',
        },
      ],
    },
    {
      title: 'Các khoản khác',
      dataIndex: 'cackhoankhac',
      key: 'cackhoankhac',
      align: 'center',
      children: [
        {
          title: 'Thanh toán thẻ bằng máy Vietcombank',
          dataIndex: 'thanhtoanpos',
          key: 'thanhtoanpos',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'Chuyển khoản Vietcombank',
          dataIndex: 'thanhtoanchuyenkhoan',
          key: 'thanhtoanchuyenkhoan',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'Đối tác công nợ',
          dataIndex: 'thanhtoandoitac',
          key: 'thanhtoandoitac',
          align: 'right',
          width: 150,
          render: (text) => formattedNumber(text),
        },
        {
          title: 'Tiền mặt nộp ngân hàng',
          dataIndex: 'tienmat',
          key: 'tienmat',
          width: 150,
          align: 'right',
          render: (text) => formattedNumber(text),
        },
      ],
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongTienNgay',
      key: 'tongTienNgay',
      width: 100,
      align: 'right',
      fixed: 'right',
      render: (_, record) => {
        const total = record.tienmat + record.thanhtoanpos + record.thanhtoanchuyenkhoan
        return formattedNumber(total)
      },
    },
  ]

  const exportToExcel = () => {
    const formattedData = data.map((item) => {
      return {
        'Tên Chi Nhánh': item.tenchinhanh,
        Ngày: item.ngay ? moment(item.ngay).format('DD/MM/YYYY') : '-',
        'Ngày Khai trương': item.ngaykhaitruong
          ? moment(item.ngaykhaitruong).format('DD/MM/YYYY')
          : '-',
        'Lượt khách khám có thu phí': item.khamthuphi,
        'Lượt khách khám sàng lọc tiêm chủng': item.khamsangloctiemchung,
        'Lượt khách khám sức khỏe': item.khamsuckhoe,
        'Lượt khách vãng lai tái khám miễn phí': item.taikhammienphivanglai,
        'Lượt khách lần đầu thẻ thành viên': item.luotkhachlandauthe,
        'Lượt khách tái khám thẻ thành viên': item.luotkhachtaikhamthe,
        'Lượt khách đến khám tại phòng khám lần đầu': item.khachdenkhamlandau,
        'Lượt khách mua thuốc từ dịch vụ': item.luotkhachtoathuoc,
        'Lượt khách mua lẻ thuốc': item.luotkhachtoabanle,
        'Tổng lượt khách': item.tongluotkhach,
        'Doanh thu dịch vụ khám bệnh': formattedNumber(item.doanhthudichvu),
        'Doanh thu dịch vụ xét nghiệm': formattedNumber(item.doanhthuxetnghiem),
        'Doanh thu bán thuốc từ dịch vụ': formattedNumber(item.doanhthutoathuoc),
        'Doanh thu bán lẻ thuốc': formattedNumber(item.doanhthutoabanle),
        'Doanh thu vắc xin': formattedNumber(item.doanhthuvacxin),
        'Lợi nhuận doanh thu bán thuốc': formattedNumber(item.loinhuanbanthuoc),
        'Doanh thu thẻ thành viên': formattedNumber(item.doanhthuthethanhvien),
        'Doanh thu thẻ thành viên bạc': formattedNumber(item.doanhthuthebac),
        'Doanh thu thẻ thành viên vàng': formattedNumber(item.doanhthuthevang),
        'Doanh thu dịch vụ khác': formattedNumber(item.doanhthukhac),
        // 'Tổng doanh thu': formattedNumber(item.tongdoanhthu),
        'Thanh toán thẻ bằng máy Vietcombank': formattedNumber(item.thanhtoanpos),
        'Chuyển khoản Vietcombank': formattedNumber(item.thanhtoanchuyenkhoan),
        'Đối tác công nợ': formattedNumber(item.thanhtoandoitac),
        'Tiền mặt nộp ngân hàng': formattedNumber(item.tienmat),
      }
    })
    const ws = XLSX.utils.json_to_sheet(formattedData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, 'ThongKeThangNhi.xlsx')
  }

  return (
    <div>
      <div className="flex gap-5 justify-between border-dotted border-b-0 p-2 ">
        <div className="flex gap-5">
          <div className="w-80">
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranch(value)
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-80">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
              // picker="month"
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
              // picker="month"
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={fetchData} color="primary" aria-label="sync" size="small">
              <SyncIcon />
            </IconButton>
          </div>
        </div>
        <div className="font-semibold">
          <Button
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 15,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            scroll={{
              x: 1500,
              y: 660,
            }}
            summary={(pageData) => {
              let totalkhamthuphi = 0
              let totalkhamsangloctiemchung = 0
              let totalkhamsuckhoe = 0
              let totaltaikhammienphivanglai = 0
              let totalluotkhachlandauthe = 0
              let totalluotkhachtaikhamthe = 0
              let totalkhachdenkhamlandau = 0
              let totalluotkhachtoathuoc = 0
              let totalluotkhachtoabanle = 0
              let totaldoanhthudichvu = 0
              let totaldoanhthuxetnghiem = 0
              let totaldoanhthukhac = 0
              let totaldoanhthutoathuoc = 0
              let totaldoanhthutoabanle = 0
              let totaldoanhthuvacxin = 0
              let totalloinhuanbanthuoc = 0
              let totalsoluongbanthethanhvien = 0
              let totalsoluongbanthebac = 0
              let totalsoluongbanthevang = 0
              let totaldoanhthuthethanhvien = 0
              let totaldoanhthuthebac = 0
              let totaldoanhthuthevang = 0
              let totaldichvukhac = 0
              let totaltongdoanhthu = 0
              let totalthanhtoanpos = 0
              let totalthanhtoanchuyenkhoan = 0
              let totalthanhtoandoitac = 0
              let totaltienmat = 0
              let totaltongluotkhach = 0
              let totaltongTienNgay = 0

              pageData.forEach(
                ({
                  khamthuphi,
                  khamsangloctiemchung,
                  khamsuckhoe,
                  taikhammienphivanglai,
                  luotkhachlandauthe,
                  luotkhachtaikhamthe,
                  khachdenkhamlandau,
                  luotkhachtoathuoc,
                  luotkhachtoabanle,
                  doanhthudichvu,
                  doanhthuxetnghiem,
                  doanhthukhac,
                  doanhthutoathuoc,
                  doanhthutoabanle,
                  doanhthuvacxin,
                  loinhuanbanthuoc,
                  soluongbanthethanhvien,
                  soluongbanthebac,
                  soluongbanthevang,
                  doanhthuthethanhvien,
                  doanhthuthebac,
                  doanhthuthevang,
                  tongdoanhthu,
                  thanhtoanpos,
                  thanhtoanchuyenkhoan,
                  thanhtoandoitac,
                  tienmat,
                  tongluotkhach,
                }) => {
                  totalkhamthuphi += khamthuphi
                  totalkhamsangloctiemchung += khamsangloctiemchung
                  totalkhamsuckhoe += khamsuckhoe
                  totaltaikhammienphivanglai += taikhammienphivanglai
                  totalluotkhachlandauthe += luotkhachlandauthe
                  totalluotkhachtaikhamthe += luotkhachtaikhamthe
                  totalkhachdenkhamlandau += khachdenkhamlandau
                  totalluotkhachtoathuoc += luotkhachtoathuoc
                  totalluotkhachtoabanle += luotkhachtoabanle
                  totaltongluotkhach += tongluotkhach
                  totaldoanhthudichvu += doanhthudichvu
                  totaldoanhthuxetnghiem += doanhthuxetnghiem
                  totaldoanhthukhac += doanhthukhac
                  totaldoanhthutoathuoc += doanhthutoathuoc
                  totaldoanhthutoabanle += doanhthutoabanle
                  totaldoanhthuvacxin += doanhthuvacxin
                  totalloinhuanbanthuoc += loinhuanbanthuoc
                  totalsoluongbanthethanhvien += soluongbanthethanhvien
                  totalsoluongbanthebac += soluongbanthebac
                  totalsoluongbanthevang += soluongbanthevang
                  totaldoanhthuthethanhvien += doanhthuthethanhvien
                  totaldoanhthuthebac += doanhthuthebac
                  totaldoanhthuthevang += doanhthuthevang
                  totaltongdoanhthu += tongdoanhthu
                  totalthanhtoanpos += thanhtoanpos
                  totalthanhtoanchuyenkhoan += thanhtoanchuyenkhoan
                  totalthanhtoandoitac += thanhtoandoitac
                  totaltienmat += tienmat
                  totaltongTienNgay += thanhtoanpos + thanhtoanchuyenkhoan + tienmat
                },
              )
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={4} align="center">
                      <Text strong>Tổng cộng</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                      <span style={{ color: 'red' }}>{totalkhamthuphi}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                      <span style={{ color: 'red' }}>{totalkhamsangloctiemchung}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right">
                      <span style={{ color: 'red' }}>{totalkhamsuckhoe}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right">
                      <span style={{ color: 'red' }}>{totaltaikhammienphivanglai}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right">
                      <span style={{ color: 'red' }}>{totalluotkhachlandauthe}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="right">
                      <span style={{ color: 'red' }}>{totalluotkhachtaikhamthe}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="right">
                      <span style={{ color: 'red' }}>{totalkhachdenkhamlandau}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="right">
                      <span style={{ color: 'red' }}>{totalluotkhachtoathuoc}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="right">
                      <span style={{ color: 'red' }}>{totalluotkhachtoabanle}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="right">
                      <span style={{ color: 'red' }}>{totaltongluotkhach}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthudichvu)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totaldoanhthuxetnghiem)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthutoathuoc)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthutoabanle)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthuvacxin)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totalloinhuanbanthuoc)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19} align="right">
                      <span style={{ color: 'red' }}>
                        {formattedNumber(totaldoanhthuthethanhvien)}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthuthebac)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={21} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthuthevang)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={22} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaldoanhthukhac)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={23} align="right">
                      <span style={{ color: 'red' }}>{totalsoluongbanthethanhvien}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={24} align="right">
                      <span style={{ color: 'red' }}>{totalsoluongbanthebac}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={25} align="right">
                      <span style={{ color: 'red' }}>{totalsoluongbanthevang}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={26} align="right">
                      <span style={{ color: 'red' }}>{totalthanhtoanpos}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={27} align="right">
                      <span style={{ color: 'red' }}>{totalthanhtoanchuyenkhoan}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={28} align="right">
                      <span style={{ color: 'red' }}>{totalthanhtoandoitac}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={29} align="right">
                      <span style={{ color: 'red' }}>{formattedNumber(totaltienmat)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={30} colSpan={2} align="right" fixed>
                      <span style={{ color: 'red' }}>{formattedNumber(totaltongTienNgay)}</span>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
            loading={isLoading}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

TabNhiKhoa.propTypes = {}

export default TabNhiKhoa
