import React, { useState, useEffect, useRef } from 'react'
import { Divider, ConfigProvider, Table, Tooltip, Spin, Input, Image, Space, Button } from 'antd'
import { EyeOutlined, SearchOutlined } from '@ant-design/icons'
import { noiSoiService } from '../../../../../../services/NoiSoi/noiSoiService'
import ToastCus from '../../../../../../utils/Toast'
import { calculateAge } from '../../../../../../utils/calculateAge'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const NoiSoi = ({ tabForm }) => {
  const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_CONGTY_LOGIN = Number(localStorage.getItem('id_company'))
  const [isLoading, setisLoading] = useState(false)
  const [isLoadingInfo, setisLoadingInfo] = useState(false)
  const [listKetQua, setlistKetQua] = useState([])
  const [infoKetQua, setinfoKetQua] = useState(null)
  const [image, setImage] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
    // searchedColumn === dataIndex ? (
    //   <Highlighter
    //     highlightStyle={{
    //       backgroundColor: '#ffc069',
    //       padding: 0,
    //     }}
    //     searchWords={[searchText]}
    //     autoEscape
    //     textToHighlight={text ? text.toString() : ''}
    //   />
    // ) : (
    //   text
    // ),
  })

  const getListKetQua = async () => {
    try {
      setisLoading(true)
      const today = moment().format('YYYY-MM-DD')
      const { data } =
        tabForm === 4
          ? await noiSoiService.getListBnDaCTC(ID_CHINHANH_LOGIN, today, today)
          : tabForm === 5
            ? await noiSoiService.getListBnDaTH(ID_CHINHANH_LOGIN, today, today)
            : tabForm === 6
              ? await noiSoiService.getListBnDaTMH(ID_CHINHANH_LOGIN, today, today)
              : { data: [] }

      // await noiSoiService.getListBnDaCTC(ID_CHINHANH_LOGIN, today, today)
      setlistKetQua(data)
    } catch (error) {
      console.log('getListKetQua : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    } finally {
      setisLoading(false)
    }
  }
  const getInfoKetQua = async (idbncdha) => {
    try {
      setisLoadingInfo(true)
      const { data } = await noiSoiService.getInfoBnNoiSoi(idbncdha)
      setinfoKetQua(data)
      if (data?.linkhinhanh) {
        const images = data?.linkhinhanh?.split(';').map((item) => ({ url: item, checked: false }))
        setImage(images)
      } else {
        setImage([])
      }
      if (data?.bieumau) {
        const convertJson = JSON.parse(data?.bieumau)
        const contentState = convertFromRaw(convertJson)
        setEditorState(EditorState.createWithContent(contentState))
      } else {
        setEditorState(null)
      }
      setisLoadingInfo(false)
    } catch (error) {
      console.log('getListKetQua : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    }
  }
  useEffect(() => {
    if ([4, 5, 6].includes(tabForm)) getListKetQua()
  }, [tabForm])
  return (
    <div>
      <Divider orientation={'left'} plain style={{ marginTop: 0, marginBottom: 3, fontSize: 15 }}>
        <p className="font-semibold">Danh sách kết quả</p>
      </Divider>
      <div className="flex justify-center items-center">
        <ConfigProvider
          theme={{
            token: { padding: 3, fontSize: 13 },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
              },
            },
          }}
        >
          <Table
            onRow={(record) => ({
              onClick: () => getInfoKetQua(record?.idbncdha),
            })}
            loading={isLoading}
            scroll={{ y: 155 }}
            className="w-[99.85%]"
            rowClassName={(record) =>
              record?.idbncdha === infoKetQua?.idbncdha ? 'bg-sky-100' : ''
            }
            bordered
            pagination={false}
            dataSource={listKetQua}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 35,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Mã bệnh nhân',
                dataIndex: 'mabenhnhan',
                key: 'mabenhnhan',
                width: 150,
                ...getColumnSearchProps('mabenhnhan'),
              },
              {
                title: 'Tên bệnh nhân',
                dataIndex: 'tenbenhnhan',
                key: 'tenbenhnhan',
                ...getColumnSearchProps('tenbenhnhan'),
              },
              {
                title: 'Ngày sinh',
                dataIndex: 'ngaysinh',
                key: 'ngaysinh',
                align: 'center',
                width: 100,
                render: (text, record, index) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
              },
              {
                title: '',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: 50,
                render: (text, record, index) => (
                  <>
                    <Tooltip placement="topRight" title={'Xem chi tiết'} color={'blue'}>
                      <EyeOutlined className="text-lg text-blue-500 cursor-pointer flex justify-center items-center" />
                    </Tooltip>
                  </>
                ),
              },
            ]}
          />
        </ConfigProvider>
      </div>
      <Divider orientation={'left'} plain style={{ marginTop: 20, marginBottom: 3, fontSize: 15 }}>
        <p className="font-semibold">Thông tin kết quả</p>
      </Divider>
      <Spin spinning={isLoadingInfo}>
        <div className="grid grid-cols-3">
          <div>
            <Divider
              orientation={'left'}
              plain
              style={{ marginTop: 0, marginBottom: 0, fontSize: 15 }}
            >
              <p className="font-semibold text-blue-500">Thông tin hành chính</p>
            </Divider>
            <div className="grid grid-flow-row pr-2 gap-1">
              <div>
                <div className="font-semibold">Tên bệnh nhân:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={infoKetQua?.tenbenhnhan}
                />
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <div className="font-semibold">Mã bệnh nhân:</div>
                  <Input
                    size="small"
                    className="w-full"
                    variant="filled"
                    readOnly
                    value={infoKetQua?.mabenhnhan}
                  />
                </div>
                <div>
                  <div className="font-semibold">Ngày sinh:</div>
                  <Input
                    size="small"
                    className="w-full"
                    variant="filled"
                    readOnly
                    value={
                      infoKetQua?.ngaysinh && moment(infoKetQua?.ngaysinh).format('DD/MM/YYYY')
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <div className="font-semibold">Giới tính:</div>
                  <Input
                    size="small"
                    className="w-full"
                    variant="filled"
                    readOnly
                    value={infoKetQua?.gioitinh}
                  />
                </div>
                <div>
                  <div className="font-semibold">Tuổi:</div>
                  <Input
                    size="small"
                    className="w-full"
                    variant="filled"
                    readOnly
                    value={infoKetQua?.ngaysinh && calculateAge(infoKetQua?.ngaysinh)}
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold">Địa chỉ:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={infoKetQua?.diachi}
                />
              </div>
            </div>
            <Divider
              orientation={'left'}
              plain
              style={{ marginTop: 15, marginBottom: 0, fontSize: 15 }}
            >
              <p className="font-semibold text-blue-500">Thông tin phiếu</p>
            </Divider>
            <div className="grid grid-flow-row pr-2 gap-1">
              <div>
                <div className="font-semibold">Ngày nội soi:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={
                    infoKetQua?.ngaynoisoi &&
                    moment(infoKetQua?.ngaynoisoi).format('DD/MM/YYYY HH:mm:ss')
                  }
                />
              </div>
              <div>
                <div className="font-semibold">Bác sĩ nội soi:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={infoKetQua?.tenbsnoisoi}
                />
              </div>
              <div>
                <div className="font-semibold">Bác sĩ chỉ định:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={infoKetQua?.tenbschidinh}
                />
              </div>
              <div>
                <div className="font-semibold">Chẩn đoán sơ bộ:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={infoKetQua?.chandoansobo}
                />
              </div>
              <div>
                <div className="font-semibold">Chỉ định:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={infoKetQua?.loainoisoi}
                />
              </div>
            </div>
            <Divider
              orientation={'left'}
              plain
              style={{ marginTop: 15, marginBottom: 0, fontSize: 15 }}
            >
              <p className="font-semibold text-blue-500">Thông tin hình ảnh</p>
            </Divider>
            <div
              className={`flex flex-wrap gap-2 ${image?.length > 8 ? ' overflow-y-auto h-[16rem] ' : ''}`}
            >
              {image?.map((item, index) => (
                <div
                  key={`div${index}`}
                  className="border rounded-md w-[6.2rem] h-[6.2rem] overflow-hidden flex justify-center items-center"
                >
                  <Image key={`hinhAnh${index}`} alt={item?.url} src={item?.url} />
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-2">
            <div className="">
              <style>
                {`.toolbarClassName {
              background-color: #f5f5f5;
            }
            
            .wrapperClassName {
              border: 1px solid #d4d4d4;
              border-radius: 0.25rem;
            }
            
            .editorClassName {
              min-height: 609px;
              max-height: 609px;
              overflow-y: auto;
              padding: 0 10px;
            }`}
              </style>
              <Editor
                editorState={editorState}
                toolbar={{
                  options: ['inline', 'blockType', 'fontFamily', 'textAlign', 'colorPicker'],
                  inline: {
                    options: ['bold', 'italic'],
                  },
                  blockType: {
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                  },
                  fontFamily: {
                    options: ['Times New Roman'],
                  },
                }}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
              />
              <div className="pt-3">
                <h2 className="font-semibold">KẾT LUẬN</h2>
                <Input.TextArea
                  style={{
                    height: 105,
                    resize: 'none',
                    fontFamily: 'Time New Roman',
                  }}
                  className="font-bold text-[1rem]"
                  name="KETLUAN"
                  // onChange={formik.handleChange}
                  value={infoKetQua?.ketluan}
                />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default NoiSoi
