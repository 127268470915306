import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { columns } from './Columns'
import { useDispatch, useSelector } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import * as typeAction from '../../../../../store/constants/constants'
import moment from 'moment'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { getBranchByChuyenKhoa } from '../../../../../store/actions/BranchAction'
import { reportService } from '../../../../../services/Report/reportService'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
import { formatNumberVND } from '../../../../../utils/formatNumberVND'
import 'moment/locale/vi'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const Detail = ({ ChuyenKhoa }) => {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [company, setCompany] = useState(id_company)
  const [date, setDate] = useState({ form: now, to: now })
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState(['all'])
  const [data, setData] = useState([])
  const fetchReport = async () => {
    try {
      openLoading()
      let idChiNhanhs = null
      // Kiểm tra giá trị của `value` để xử lý các trường hợp rỗng hoặc "all"
      if (value?.length > 0 && value[0] !== 'all') {
        // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
        idChiNhanhs = value.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await reportService.getServiceDetail(
          company,
          date.form,
          date.to,
          idChiNhanhs,
        )
        setData(data)
      } else {
        // Xử lý trường hợp "all"
        const { data } = await reportService.getServiceDetail(company, date.form, date.to, '')
        setData(data)
      }
      closeLoading()
    } catch (error) {
      console.log(error)
      closeLoading()
    } finally {
      closeLoading()
    }
  }

  const openLoading = () => {
    dispatch({
      type: typeAction.OPEN_LOADING_TABLE,
    })
  }
  const closeLoading = () => {
    dispatch({
      type: typeAction.CLOSE_LOADING_TABLE,
    })
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDate }))
  }
  const onChangeCompany = (value) => {
    setCompany(value)
    setValue(['all'])
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn công ty ',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = () => {
    const formatData = data.map((item) => ({
      Ngày: moment(item.ngay).format('DD/MM/YYYY'),
      'Chi nhánh': item.tenChiNhanh,
      'Nhóm dịch vụ': item.tenNhomDV,
      'Mã dịch vụ': item.maDV,
      'Tên dịch vụ': item.tenDV,
      'Đơn vị tính': item.dvt,
      'Số lượng': item.soLuong,
      'Đơn giá': item.donGia,
      'Thành tiền': item.thanhTien,
    }))
    const name = 'Báo cáo dịch vụ chi tiết'
    exportExcelformat(formatData, name)
  }
  useEffect(() => {
    dispatch(getBranchByChuyenKhoa(company, idChuyenKhoa, setBranch))
  }, [company])

  const onChangeChuyenKhoa = (value) => {
    dispatch(getBranchByChuyenKhoa(company, value, setBranch))
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    // fetchReport()
    onChangeChuyenKhoa(idChuyenKhoa)
  }, [])
  // validate
  const valieDateSelect = infoUser?.idNhom === 3
  return (
    <>
      <div className="flex gap-3 border-dotted border-b-0  p-2 ">
        <Select
          showSearch
          value={company}
          onChange={onChangeCompany}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={listCompany?.map(({ idct, tenct }) => ({
            label: tenct,
            value: idct,
          }))}
          className={`w-80 ${valieDateSelect ? 'hidden' : 'block'}`}
        />
        <Select
          showSearch
          value={idChuyenKhoa}
          onChange={onChangeChuyenKhoa}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            { label: 'Tất cả', value: '' },
            ...ChuyenKhoa.map((items) => ({
              label: items.tenChuyenKhoa,
              value: items.idChuyenKhoa,
            })),
          ]}
          className="w-80"
        />
        <div className={`w-80 ${valieDateSelect ? 'hidden' : 'block'}`}>
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        <div className="flex items-center gap-3">
          <DatePicker
            allowClear={false}
            value={dayjs(date.form)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            value={dayjs(date.to)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>

        <div className="flex gap-3">
          <IconButton color="primary" onClick={fetchReport} aria-label="delete" size="small">
            <SyncIcon />
          </IconButton>
        </div>
        <div className="flex gap-3 ml-auto">
          <Button
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            borderRadius: 0,
          },
        }}
      >
        <Table
          loading={isLoadingTable}
          bordered
          scroll={{
            x: 1500,
            y: 660,
          }}
          // dataSource={data}
          columns={columns}
          pagination={false} // Disable pagination
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {/* Tổng thanh toán */}
                <Table.Summary.Cell index={0}>Tổng cộng:</Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={6} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={7} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={8} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={9} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={10} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={11}>
                  {formatNumberVND(data.reduce((total, item) => total + item.thanhTien, 0))}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </ConfigProvider>
    </>
  )
}

export default Detail
