import React, { memo } from 'react'
import {
  Button,
  ConfigProvider,
  DatePicker,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tabs,
  Segmented,
} from 'antd'
import moment from 'moment'
import { formatNumberVND } from '../../../../../utils/formatNumberVND'
import 'moment/locale/vi'
moment.locale('vi')
const TableVoucher = memo(({ listVoucher, columnsVoucher, loadingTable }) => {
  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
        }}
      >
        <Table
          scroll={{
            x: 1100,
          }}
          bordered
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
          loading={loadingTable}
          columns={columnsVoucher}
          dataSource={listVoucher.map((item) => ({
            mavoucher: item.mavoucher,
            giatri: formatNumberVND(item.giatri),
            ngayhieuluc: item.ngayhieuluc ? moment(item.ngayhieuluc).format('DD/MM/YYYY') : '',
            thoigianhieuluc: item.thoigianhieuluc,
            nguoisua: item.tennguoisua,
            ngaysua: item.ngaysua ? moment(item.ngaysua).format('DD/MM/YYYY HH:mm:ss') : '',
            nguoitao: item.nguoitao,
            ngaytao: item.ngaytao ? moment(item.ngaytao).format('DD/MM/YYYY HH:mm:ss') : '',
            nguoikichhoat: item.nguoikichhoat,
            ngaykichhoat: item.ngaykichhoat
              ? moment(item.ngaykichhoat).format('DD/MM/YYYY HH:mm:ss')
              : '',
            trangthai:
              item.trangthai === 1
                ? ' Mới tạo'
                : item.trangthai === 2
                  ? 'Chưa sử dụng'
                  : 'Đã sử dụng',
          }))}
        />
      </ConfigProvider>
    </div>
  )
})

export default TableVoucher
