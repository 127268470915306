import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import { dienTimService } from '../../../services/DienTim/dienTimService'
import { saveBnDienTIm } from '../../../store/actions/dienTimAction'
import Customer from './Customer/Customer'
import ImageSA from './ImageSA/ImageSA'
import Index from './Index/Index'
import InfoBN from './InfoBN/InfoBN'
import Template from './Template/Template'
import 'moment/locale/vi'
moment.locale('vi')
const formatDB = 'YYYY-MM-DDTHH:mm:ss'
const today = moment()
const DienTim = () => {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const { infoBNDienTim } = useSelector((state) => state.dienTimReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [image, setImage] = useState([])
  const [BNPending, setBNPending] = useState([])
  const [BNdone, setBnDone] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [idbncdha, setIdbncdha] = useState(null)

  const dispatch = useDispatch()
  //----------- lấy danh sách chờ BN --------//
  const fetchBNPending = async (id) => {
    try {
      const { data } = await dienTimService.getBNPendingByBranch(id)
      setBNPending(data)
    } catch (error) {
      console.log(error)
    }
  }
  // ----------- lấy danh bệnh nhân đã thực hiên ------------//
  const fetchBnDone = async (idcn, dateForm, dataTo) => {
    try {
      const { data } = await dienTimService.getBNDoneBtBranch(idcn, dateForm, dataTo)
      setBnDone(data)
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IDBNTTC: infoBNDienTim?.idbnttc,
      // IDBM: 1,
      BSCHIDINH: infoBNDienTim?.bskham ?? '',
      BSTHUCHIEN: infoUser?.tenNV,
      IDBNCDHA: idbncdha,
      FILEHINHANHS: null,
      KETLUAN: infoBNDienTim?.infoDienTim?.ketluan,
      KETQUADIENTIM: infoBNDienTim?.infoDienTim?.ketluan,
    },
    onSubmit: (values) => handleSubmit(values),
  })
  const reload = () => {
    formik.resetForm()
    fetchBNPending(ID_BRANCH)
    setEditorState(EditorState.createEmpty())
    setImage([])
    setIdbncdha(null)
  }
  const handleSubmit = (values) => {
    if (image.length !== 0) {
      // * Nếu  như có hình ảnh thì gán hình ảnh vào !
      const data = image.map((item) => item.file)
      values.FILEHINHANHS = data
    }
    const contentState = editorState.getCurrentContent()
    const row = convertToRaw(contentState)
    const data = JSON.stringify(row)
    values.KETQUADIENTIM = data
    const formData = new FormData()
    for (let key in values) {
      if (key === 'FILEHINHANHS') {
        if (values[key] && values[key].length > 0) {
          values[key].forEach((file, index) => {
            formData.append(key, file)
          })
        }
      } else {
        formData.append(key, values[key])
      }
    }
    dispatch(saveBnDienTIm(formData, reload))
  }

  useEffect(() => {
    fetchBNPending(ID_BRANCH)
    fetchBnDone(ID_BRANCH, today.format('YYYY-MM-DD'), today.format('YYYY-MM-DD'))
  }, [])

  useEffect(() => {
    if (infoBNDienTim?.infoDienTim?.linkhinhanh) {
      const images = infoBNDienTim.infoDienTim.linkhinhanh
        .split(';')
        .filter((item) => item !== '')
        .map((item) => ({ url: item, checked: false }))
      setImage(images)
    } else {
      setImage([])
    }
  }, [infoBNDienTim])

  useEffect(() => {
    if (infoBNDienTim?.infoDienTim) {
      if (infoBNDienTim?.infoDienTim?.ketquadientim) {
        const convertJson = JSON.parse(infoBNDienTim?.infoDienTim?.ketquadientim)
        const contentState = convertFromRaw(convertJson)
        setEditorState(EditorState.createWithContent(contentState))
      } else {
        setEditorState(null)
      }
    } else {
      setEditorState(null)
    }
  }, [infoBNDienTim])

  return (
    <>
      <div className="h-full bg-[#EFEFEF]">
        <div>
          <div className="p-2">
            <div className="bg-white flex border rounded-lg">
              <div className="w-1/2 border-r">
                <div className="flex">
                  <div className="w-1/2 border-r">
                    <Customer
                      fetchBNPending={fetchBNPending}
                      fetchBnDone={fetchBnDone}
                      BNdone={BNdone}
                      BNPending={BNPending}
                      setIdbncdha={setIdbncdha}
                    />
                  </div>
                  <div className="w-1/2">
                    <div>
                      <InfoBN />
                    </div>
                    <div>
                      <div>
                        <Index />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-t">
                  <ImageSA image={image} setImage={setImage} />
                </div>
              </div>
              <div className="w-1/2">
                <Template
                  formik={formik}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DienTim
