import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { xQuangService } from '../../services/xQuang/xQuangService'
import { thuThuatService } from '../../services/thuThuat/thuThuatService'
import { generatePhieuSieuAm } from '../../utils/report/san/phieuSieuAm'
import { generatePhieuSieuAmMultiImage } from '../../utils/report/san/phieuSieuAmForMultiImageOnly'
import ToastCus from '../../utils/Toast'

export function* xQuangSaga() {
  // POST BỆNH NHÂN X QUANG
  yield takeLatest(
    typeAction.POST_INFO_BN_XQUANG,
    function* postInfoBnXQuangSaga({ type, form, list, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer),
          { PkDangNhap } = yield select((state) => state.branchReducer)
        const formData = new FormData()
        formData.append('IDBNTTC', form?.infoBnXQuang?.IDBNTTC)
        formData.append('BSXQUANG', form?.idBsDocPhim)
        formData.append('KTV', infoUser?.idnv)
        formData.append('CHANDOAN', '')
        formData.append('CHIDINH', form?.infoBnXQuang?.CHIDINH ?? '')
        formData.append('IDBNCDHA', form?.infoBnXQuang?.IDBNCDHA)
        formData.append('KETLUAN', form?.ketLuan ?? '')
        formData.append('BIEUMAU', form?.mota ?? '')
        formData.append('TIEUDE', form?.infoBieuMau?.tieude ?? '')
        if (form?.infoBieuMau?.idbm) formData.append('IDBM', form?.infoBieuMau?.idbm)
        if (form?.listHinh?.length) {
          const Files = form?.listHinh?.map((item) => item?.file)
          Files?.forEach((file, index) => {
            formData.append('files', file)
          })
        }
        const { data: infoXQuang } = yield call(() => xQuangService.postInfoBnXQuang(formData))
        yield call(() =>
          thuThuatService.putListVTBNThuThuat(
            form?.infoBnXQuang?.IDBNTTC,
            list?.map((item) => ({
              // idbnvtth: 0,
              idbnttc: form?.infoBnXQuang?.IDBNTTC,
              idthuoc: item?.idthuoc,
              tenBietDuoc: item?.tenbietduoc,
              tenHoatChat: item?.tenhoatchat,
              dvt: item?.dvt,
              nongDo: item?.nongdo,
              hamLuong: item?.hamluong,
              quyCach: item?.quycach,
              donGia: item?.dongia,
              soLuong: item?.soluong,
              thanhTien: item?.soluong * item?.dongia,
              tongTien: item?.soluong * item?.dongia,
              // bsKeDon: item?.tenhoatchat,
              // ngayKeDon: item?.tenhoatchat,
              nguoiThucHien: infoUser?.tenNV,
              // ngayThucHien: item?.tenhoatchat,
              ghiChu: item?.ghichu,
              idkhocn: item?.idkhocn,
              soLo: item?.solo,
              duongDung: item?.duongdung,
              hanDung: item?.handung,
              // idDichVu: item?.tenhoatchat,
              // idXetNghiem: item?.tenhoatchat,
              idcdha: form?.infoBnXQuang?.IDCDHA,
              soHoaDon: item?.sohoadon,
            })),
          ),
        )

        // thông tin in
        // console.log(infoXQuang)
        // console.log(form)
        const formatInfoBnXQ = {
          ...form?.infoBnXQuang,
          ...infoXQuang,

          // tieude: infoXQuang?.tieude,
          // bieumau: infoXQuang?.bieumau,
          // ketluan: infoXQuang?.ketluan,

          ngaysieuam: infoXQuang?.ngayxquang,
          bssieuam: infoXQuang?.tenbsxquang,

          mabenhnhan: form?.infoBnXQuang?.MABENHNHAN,
          tenbenhnhan: form?.infoBnXQuang?.TENBENHNHAN,
          ngaysinh: form?.infoBnXQuang?.NGAYSINH,
          gioitinh: form?.infoBnXQuang?.GIOITINH,
          diachi: form?.infoBnXQuang?.DIACHI,
          tenphuong: form?.infoBnXQuang?.TENPHUONG,
          tenquan: form?.infoBnXQuang?.TENQUAN,
          tentinh: form?.infoBnXQuang?.TENTINH,
          chandoansobo: form?.infoBnXQuang?.CHANDOAN,
          loaisieuam: form?.infoBnXQuang?.CHIDINH,
          bschidinh: form?.infoBnXQuang?.BSCHIDINH,
        }
        yield call(() =>
          form?.listHinhPrint?.length >= 3
            ? generatePhieuSieuAmMultiImage(formatInfoBnXQ, form?.listHinhPrint, PkDangNhap)
            : generatePhieuSieuAm(formatInfoBnXQ, form?.listHinhPrint, PkDangNhap),
        )

        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // PUT BỆNH NHÂN X QUANG
  yield takeLatest(
    typeAction.PUT_INFO_BN_XQUANG,
    function* postInfoBnXQuangSaga({ type, form, list, isPrint, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer),
          { PkDangNhap } = yield select((state) => state.branchReducer)

        if (form?.listHinhXoa?.length) {
          // xóa hình cũ
          for (const url of form?.listHinhXoa) {
            yield call(() => xQuangService.deleteFileBnXQuang(form?.infoBnXQuang?.IDBNXQ, url))
          }
        }
        if (form?.listHinh?.length) {
          // thêm hình mới
          const formData = new FormData()
          formData.append('IDBNXQ', form?.infoBnXQuang?.IDBNXQ)
          const Files = form?.listHinh?.map((item) => item?.file)
          Files?.forEach((file, index) => {
            formData.append('HINHANH', file)
          })
          yield call(() => xQuangService.putFileBnXQuang(formData))
        }

        const { data: infoXQuang } = yield call(() =>
          xQuangService.putInfoBnXQuang({
            idbnxq: form?.infoBnXQuang?.IDBNXQ,
            idbnttc: form?.infoBnXQuang?.IDBNTTC,
            // ngayxquang: '2024-11-13T03:21:16.006Z',
            // bsxquang: 0,
            // chandoan: 'string',
            // chidinh: 'string',
            idbncdha: form?.infoBnXQuang?.IDBNCDHA,
            // linkhinhanh: 'string',
            ketluan: form?.ketLuan,
            bieumau: form?.mota,
            tieude: form?.infoBieuMau?.tieude,
            // ghichu: '',
            nguoisua: infoUser?.idnv,
            // ngaysua: '2024-11-13T03:21:16.006Z',
            // idttxq: 0,
            idbm: form?.infoBieuMau?.idbm,
          }),
        )
        yield call(() =>
          thuThuatService.putListVTBNThuThuat(
            form?.infoBnXQuang?.IDBNTTC,
            list?.map((item) => ({
              // idbnvtth: 0,
              idbnttc: form?.infoBnXQuang?.IDBNTTC,
              idthuoc: item?.idthuoc,
              tenBietDuoc: item?.tenbietduoc,
              tenHoatChat: item?.tenhoatchat,
              dvt: item?.dvt,
              nongDo: item?.nongdo,
              hamLuong: item?.hamluong,
              quyCach: item?.quycach,
              donGia: item?.dongia,
              soLuong: item?.soluong,
              thanhTien: item?.soluong * item?.dongia,
              tongTien: item?.soluong * item?.dongia,
              // bsKeDon: item?.tenhoatchat,
              // ngayKeDon: item?.tenhoatchat,
              nguoiThucHien: infoUser?.tenNV,
              // ngayThucHien: item?.tenhoatchat,
              ghiChu: item?.ghichu,
              idkhocn: item?.idkhocn,
              soLo: item?.solo,
              duongDung: item?.duongdung,
              hanDung: item?.handung,
              // idDichVu: item?.tenhoatchat,
              // idXetNghiem: item?.tenhoatchat,
              idcdha: form?.infoBnXQuang?.IDCDHA,
              soHoaDon: item?.sohoadon,
            })),
          ),
        )

        if (isPrint) {
          // thông tin in
          // console.log(infoXQuang)
          // console.log(form)

          const formatInfoBnXQ = {
            ...form?.infoBnXQuang,
            ...infoXQuang,

            // tieude: infoXQuang?.tieude,
            // bieumau: infoXQuang?.bieumau,
            // ketluan: infoXQuang?.ketluan,

            ngaysieuam: infoXQuang?.ngayxquang,
            bssieuam: infoXQuang?.tenbsxquang,

            mabenhnhan: form?.infoBnXQuang?.MABENHNHAN,
            tenbenhnhan: form?.infoBnXQuang?.TENBENHNHAN,
            ngaysinh: form?.infoBnXQuang?.NGAYSINH,
            gioitinh: form?.infoBnXQuang?.GIOITINH,
            diachi: form?.infoBnXQuang?.DIACHI,
            tenphuong: form?.infoBnXQuang?.TENPHUONG,
            tenquan: form?.infoBnXQuang?.TENQUAN,
            tentinh: form?.infoBnXQuang?.TENTINH,
            chandoansobo: form?.infoBnXQuang?.CHANDOAN,
            loaisieuam: form?.infoBnXQuang?.CHIDINH,
            bschidinh: form?.infoBnXQuang?.BSCHIDINH,
          }
          yield call(() =>
            form?.listHinhPrint?.length >= 3
              ? generatePhieuSieuAmMultiImage(formatInfoBnXQ, form?.listHinhPrint, PkDangNhap)
              : generatePhieuSieuAm(formatInfoBnXQ, form?.listHinhPrint, PkDangNhap),
          )
        }

        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
