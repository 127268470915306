import { DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Divider, Input, Modal, Select, Space, Table } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import { cloneDeep } from 'lodash'
import ToastCus from '../../../../utils/Toast'
import { addListPhanCongLienHe } from '../../../../store/actions/phanconglienheAction'

const dateFormat = 'DD-MM-YYYY'
const { TextArea } = Input
const ModalAddPhanCong = ({ open, setOpen, onLoad }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listTrangThaiPhanCong } = useSelector((state) => state.phanconglienheReducer)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [loading, setLoading] = useState(false)
  const [nhanVienSales, setNhanVienSales] = useState([])
  const [dataNVSalesPhanCong, setDataNVSalesPhanCong] = useState([])
  const [allThongTinLH, setAllThongTinLH] = useState([])
  const dispatch = useDispatch()
  // xử lí form thêm dịch vụ
  const handleSubmit = () => {
    setLoading(true)
    //remove nguoi lien he null
    const formatData = dataNVSalesPhanCong.filter((item) => {
      if (item.idttlh) {
        delete item.id
        return item
      }
    })
    dispatch(addListPhanCongLienHe(formatData, onLoad, setLoading))
    setOpen(false)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleChangeNguoiLienHe = (value, record) => {
    //setvalue for dataNVSalesPhanCong
    const findItemIdx = dataNVSalesPhanCong.findIndex((item) => item.id === record.id)
    if (findItemIdx !== -1) {
      const cloneNVPhanCong = cloneDeep(dataNVSalesPhanCong)
      //check trùng
      const check = cloneNVPhanCong.filter(
        (item) =>  item.idttlh === value,
      )
      if (check.length > 0) {
        ToastCus.fire({
          icon: 'error',
          title: 'Công ty đã được phân công liên hệ',
        })
        return
      }
      cloneNVPhanCong[findItemIdx].idttlh = value
      cloneNVPhanCong[findItemIdx].idct = allThongTinLH.filter(
        (item) => item.idttlh === value,
      )[0].idct
      setDataNVSalesPhanCong(cloneNVPhanCong)
    }
  }

  const handleChangeTrangThai = (value, record) => {
    //setvalue for dataNVSalesPhanCong
    const findItemIdx = dataNVSalesPhanCong.findIndex((item) => item.id === record.id)
    if (findItemIdx !== -1) {
      const cloneNVPhanCong = cloneDeep(dataNVSalesPhanCong)
      cloneNVPhanCong[findItemIdx].idttpclh = value
      setDataNVSalesPhanCong(cloneNVPhanCong)
    }
  }

  const handleChangeInput = (e, record, key) => {
    const findItemIdx = dataNVSalesPhanCong.findIndex((item) => item.id === record.id)
    if (findItemIdx !== -1) {
      const cloneNVPhanCong = cloneDeep(dataNVSalesPhanCong)
      cloneNVPhanCong[findItemIdx][key] = e.target.value
      setDataNVSalesPhanCong(cloneNVPhanCong)
    }
  }

  const columnsNVSales = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'manv',
      key: 'manv',
      className: 'text-sm',
      width: 150,
      ...getColumnSearchProps('manv'),
    },
    {
      title: 'Tên Nhân viên',
      dataIndex: 'tennv',
      key: 'tennv',
      align: 'left',
      className: 'text-sm',
      ...getColumnSearchProps('tennv'),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 40,
      align: 'center',
    },
  ]

  const columnsNVSalesPhanCong = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'manhanvien',
      key: 'manhanvien',
      className: 'text-sm',
      width: 120,
      ...getColumnSearchProps('manhanvien'),
      fixed: 'left',
    },
    {
      title: 'Tên Nhân viên',
      dataIndex: 'tennhanvien',
      key: 'tennhanvien',
      align: 'left',
      className: 'text-sm',
      ...getColumnSearchProps('tennhanvien'),
      fixed: 'left',
    },
    {
      title: 'Công ty liên hệ',
      dataIndex: 'tencongty',
      key: 'tencongty',
      align: 'left',
      className: 'text-sm',
      render: (text, record) => (
        <Select
        showSearch
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
          value={record.idttlh}
          options={allThongTinLH
            .map((items) => ({
              label: items.tencongty,
              value: items.idttlh,
              ...items,
            }))}
          className="w-full"
          onChange={(value) => handleChangeNguoiLienHe(value, record)}
        />
      ),
    },
    {
      title: 'Nội dung',
      dataIndex: 'noidung',
      key: 'noidung',
      align: 'left',
      className: 'text-sm',
      render: (text, record) => (
        <TextArea
          style={{ height: 60, resize: 'none' }}
          key={record.id}
          rows={2}
          value={record.noidung}
          onChange={(e) => handleChangeInput(e, record, 'noidung')}
        />
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangthai',
      key: 'trangthai',
      align: 'left',
      className: 'text-sm',
      render: (text, record) => (
        // <Select
        //   value={record.idttpclh}
        //   options={listTrangThaiPhanCong?.map((items) => ({
        //     label: items.trangthai,
        //     value: items.idttpclh,
        //     ...items,
        //   }))}
        //   className="w-full"
        //   // onChange={(value) => handleChangeTrangThai(value, record)}
        // />
        <p>{listTrangThaiPhanCong?.filter((item) => item.idttpclh === 1)[0].trangthai}</p>
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      align: 'left',
      className: 'text-sm',
      render: (text, record) => (
        <Input
          key={record.id}
          value={record.ghichu}
          onChange={(e) => handleChangeInput(e, record, 'ghichu')}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 40,
      align: 'center',
      fixed: 'right',
    },
  ]

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('NhanVien/GetNhanVienSale')
        setNhanVienSales(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('CRMThongTinLienHe/GetAllCheckPhanCongLienHe')
        setAllThongTinLH(data.filter((val) => val.sudung))
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const handleAddPhanCong = (item) => {
    const uniqueId = `${item.manv}-${Date.now()}`
    const formatData = {
      id: uniqueId,
      manhanvien: item.manv,
      tennhanvien: item.tennv,
      idttlh: '',
      idnv: item.id,
      nguoiphancong: infoUser.idnv,
      idttpclh: 1, // set default
      idct: '',
      noidung: '',
      ghichu: '',
    }
    setDataNVSalesPhanCong((prev) => [...prev, formatData])
  }

  const handleDelete = (item) => {
    setDataNVSalesPhanCong((prev) => prev.filter((val) => val.id !== item.id))
  }

  return (
    <>
      <Modal
        style={{
          top: 20,
        }}
        width={'90%'}
        okText="Thêm"
        cancelText="Huỷ"
        className="text-center"
        title={'Phân công liên hệ'}
        onOk={handleSubmit}
        open={open}
        onCancel={() => setOpen(false)}
        okButtonProps={{ disabled: loading }}
      >
        <div className="flex flex-col gap-1">
          <div className="flex">
            <div className="flex w-full gap-1 items-center">
              <label className="w-40 text-end">Người phân công:</label>
              <p className="font-semibold ml-2">{infoUser.tenNV}</p>
            </div>
          </div>
        </div>

        <Divider />
        <div className="flex gap-2">
          <div className="w-1/3">
            <p className="text-base text-left font-semibold mb-2">Danh sách nhân viên</p>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
              }}
            >
              <Table
                scroll={{ y: 600 }}
                bordered
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,
                }}
                columns={columnsNVSales}
                dataSource={nhanVienSales.map((item) => ({
                  ...item,
                  key: item.manhanvien,
                  action: (
                    <PlusOutlined
                      onClick={() => handleAddPhanCong(item)}
                      className="text-xl text-green-500 cursor-pointer"
                    />
                  ),
                }))}
              />
            </ConfigProvider>
          </div>
          <div className="w-2/3">
            <p className="text-base text-left font-semibold mb-2">Danh sách phân công</p>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
              }}
            >
              <Table
                scroll={{ y: 600, x: 1500 }}
                bordered
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,
                }}
                columns={columnsNVSalesPhanCong}
                dataSource={dataNVSalesPhanCong.map((item) => ({
                  ...item,
                  key: item.manhanvien,
                  action: (
                    <ul className="flex gap-2 justify-around">
                      <li
                        className="text-lg text-red-500 cursor-pointer"
                        onClick={() => handleDelete(item)}
                      >
                        <DeleteOutlined />
                      </li>
                    </ul>
                  ),
                }))}
              />
            </ConfigProvider>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalAddPhanCong
