import * as typeAction from '../constants/constants'

export const loginUser = (payload, IDCompany, navigate,action) => ({
  type: typeAction.GET_LOGIN_API,
  payload,
  IDCompany,
  navigate,
  action
})

export const logoutUser = (navigate,id) => ({
  type: typeAction.LOGOUT_USER,
  navigate,
  id
})

export const getListMenu = (payload) => ({
  type: typeAction.GET_LIST_MENU,
  payload,
})

export const updateInfoUserToStore = (token) => ({
  type: typeAction.UPDATE_INFO_USER_LOCASTORAGE,
  token,
})

export const openShiffCaLamViec = (form) => ({
  type: typeAction.POST_CLOCK_IN_WORK,
  form,
})

export const changePassword = (form, openNotificationWithIcon, handleLogOut) => ({
  type: typeAction.CHANGE_PASSWORD_USER,
  form,
  openNotificationWithIcon,
  handleLogOut,
})
//---------- lấy danh sách tài khản -------------//
export const fetchUser = (keyword, pageIndex) => ({
  type: typeAction.GET_ACCOUNT_BY_PAGEINDEX,
  keyword,
  pageIndex,
  
})
//---------- thêm người dung -------------//
export const addUser = (form,restStore) => ({
  type: typeAction.ADD_NEW_USER,
  form,
  restStore
})
//---------- cập nhật người dung -------------//
export const updateUser = (form,restStore) => ({
  type: typeAction.UPDATE_USER,
  form,
  restStore
})
//---------- thêm người dung -------------//
export const resetPassUser = (username,nguoiSua, formLoad) => ({
  type: typeAction.RESET_PASSWORD_USER,
  username,
  nguoiSua,
  formLoad,
})
