import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
import { drugstoreService } from '../../services/drugstore/drugstoreService'
import { recieveService } from '../../services/receive/recieveService'
import { BNChoKham, thuocKeToa, updateDetailBN } from '../actions/childrenClinicAction'
import { generateKhamBenhXuatVacXin } from '../../utils/report/vacxin/phieuKhamBenhXuatVacxin'
import { generatePhieuToaThuoc } from '../../utils/report/phieuToaThuoc'
import { generatePhieuChiDinh } from '../../utils/report/phieuChiDinh'
import { cashierService } from '../../services/cashier/cashierService'
import { doKhucXaService } from '../../services/doKhucXa/doKhucXaService'
import { cardService } from '../../services/card/cardService'
import { generatePhieuKiemTruocTiemChung } from '../../utils/report/phieuKiemTruocTiemChung'
import { generatePhieuKiemTruocTiemChungLao } from '../../utils/report/phieuKiemTruocTiemChungLao'
import { generatePhieuKiemTruocTiemChungSan } from '../../utils/report/phieuKiemTruocTiemChungSan'
import moment from 'moment'
// import { generatePhieuToaThuocTA } from '../../utils/report/phieuToaThuocTA'
import 'moment/locale/vi'
import { generatePhieuKhamBenh } from '../../utils/report/phieuKhamBenh'
import { generatePhieuToaThuocKoLayThuoc } from '../../utils/report/phieuToaThuocKoLayThuoc'
moment.locale('vi')

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* childrenClinicSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(typeAction.GET_BN_CHO_KHAM_BY_PK, function* BNPending({ type, idcnPk }) {
    try {
      const result = yield call(() => childrenClinicService.getBNCho(idcnPk))
      yield put({
        type: typeAction.DISPATCH_BN_CHO_KHAM,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // LẤY THÔNG TIN CHI TIẾT DANH SÁCH BỆNH NHÂN KHÁM BỆNH
  yield takeLatest(
    typeAction.GET_INFO_DETAIL_BN_KHAM,
    function* BNPending({ type, idbn, idbnttc }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { PkDangNhap } = yield select((state) => state.branchReducer)

        const result = yield call(() => recieveService.getInfoBN(idbn))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: result.data,
        })
        const infoDetail = yield call(() => childrenClinicService.getDetailBNKham(idbnttc))
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: infoDetail.data,
        })
        const infoDetailToaThuoc = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        yield put({
          type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM,
          payload: infoDetailToaThuoc.data,
        })
        const cardBN = yield call(() =>
          cardService.getInfoMemberCard(idbn, PkDangNhap?.idChuyenKhoa),
        )
        yield put({
          type: typeAction.TTV_BENH_NHAN_NHI,
          payload: cardBN.data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        const history = yield call(() => recieveService.getLichSuTongQuat(idbn))
        const sortedData = _.orderBy(history.data, 'ngaykham', 'desc')
        const newDataHistory = sortedData.slice(1)
        yield put({
          type: typeAction.DISPATCH_HISTORY_KHAM_PK,
          payload: newDataHistory,
        })
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // LẤY THÔNG TIN GỢI Ý KHÁM BỆNH BÁCH SĨ
  yield takeLatest(typeAction.GET_SUGGEST_DOCTOR, function* suggestDoctor({ type }) {
    try {
      // const chanDoan = yield call(() => childrenClinicService.getChanDoan(''));
      // yield put({
      //   type: typeAction.DISPATCH_CHAN_DOAN,
      //   payload: chanDoan.data,
      // });
      const loiDan = yield call(() => childrenClinicService.getLoiDan())
      yield put({
        type: typeAction.DISPATCH_LOI_DAN,
        payload: loiDan.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // LẤY DANH SÁCH THUỐC KÊ TOA TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_THUOC_KE_TOA_DOCTOR,
    function* thuocKeToaBS({ type, idChiNhanh }) {
      try {
        const result = yield call(() => childrenClinicService.getKetoaThuoc(idChiNhanh))
        let listVT = []
        // result?.data?.filter((item1) => result?.data?.indexOf(item2) !== index)
        const arrTonDuoc = result?.data?.filter(
          (items) =>
            items?.tonKho >= 0 &&
            // items?.thuocVatTu?.idNhom !== 169 &&
            items?.thuocVatTu?.idNhom !== 179 &&
            items?.thuocVatTu?.idNhom !== 205,
        ) //không lấy gọng kính trong kho dược
        arrTonDuoc?.map((item, index) => {
          if (listVT?.find((itemVT) => itemVT?.idThuoc === item?.idThuoc)) {
            listVT?.map(
              (itemVT) => itemVT?.idThuoc === item?.idThuoc && (itemVT.tonKho += item.tonKho),
            )
          } else {
            listVT.push({ ...item, ID: index })
          }
        })
        yield put({
          type: typeAction.DISPATCH_THUOC_KE_TOA_DOCTOR,
          payload: {
            ThuocVT: listVT,
            listTonDuoc: arrTonDuoc,
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  //---------------- Cập nhật THÔNG TIN KHÁM BỆNH-------------//
  yield takeLatest(
    typeAction.UPDATE_DETAIL_BN_KHAM,
    function* BNPending({ type, infoBN, formBenhNhan, form, idcnPk, newArr, isPrint }) {
      // return
      try {
        const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        const { InfoBNDoKhucXa } = yield select((state) => state.doKhucXaReducer)
        // validate chỉ chuyên khoa mắt - bs mắt lưu trước khi đo khúc xạ
        if (PkDangNhap?.idChuyenKhoa === 4 && isPrint) {
          // const dataDKX =
          const { data: dkx } = yield call(() => doKhucXaService.getInfoBNDoKhucXa(form?.idbnttc))
          if (dkx && dkx?.DichVuBenhNhanDoKhucXa?.length && !dkx?.NGUOIKHAM) {
            Toast.fire({
              icon: 'error',
              title: 'Bệnh nhân chưa có kết quả đo khúc xạ!',
            })
            return
          }
        }
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        if (form?.lydokolaythuoc && !newArr?.find((item) => item?.isGongKinh)) {
          yield call(() => childrenClinicService.deleteBNThuocBSKhongKeToa(form?.idbnttc))
        }
        const result = yield call(
          () =>
            childrenClinicService.updateDetailBN({
              ...form,
              trangthaikhambenh: isPrint ? (newArr?.length > 0 ? 2 : 4) : 1,
            }), // ấn lưu & in đổi ttkb nếu có thuốc = 2, không có thuốc = 4, ấn nút khác thì 1
        )
        yield call(() => childrenClinicService.putInfoBN(formBenhNhan))

        // //in toa rong o nhi
        // if (newArr.length === 0 && form?.lydokolaythuoc && ) {
        //   generatePhieuToaThuocKoLayThuoc(infoBN, result.data, PkDangNhap)
        // }

        if (
          isPrint &&
          form?.lydokolaythuoc &&
          !newArr?.filter((item) => !item?.isGongKinh)?.length && // không có thuốc bthg và có thể có gọng kính hoặc không
          (PkDangNhap?.idChuyenKhoa === 1 ||
            (PkDangNhap?.idChuyenKhoa === 4 && form?.dataDoKhucXa && form?.dataDoKhucXa?.nguoikham)) // chuyên khoa nhi + mắt không có thuốc nhưng vẫn in phiếu toa thuốc
        ) {
          generatePhieuToaThuoc(
            infoBN,
            {
              ...result.data,
              dataDoKhucXa: form?.dataDoKhucXa?.nguoikham ? form?.dataDoKhucXa : null,
              khongCoToaNhungVanInToa: 1,
            },
            [{ idphanloai: 1 }], //không có thuốc nhưng vẫn in phiếu toa thuốc
            PkDangNhap,
          )
        }

        if (newArr.length > 0 && !form?.lydokolaythuoc) {
          //&& !form?.lydokolaythuoc
          // const formatData = newArr.map((item) => ({
          //   ...item,
          //   soLanNgay: item.soLanNgay + '',
          //   lieuDung: item.lieuDung + '',
          // }))
          const toathuoc = yield call(() =>
            childrenClinicService.putToaThuocBsTuLaySoLo(
              form?.idbnttc,
              PkDangNhap?.idChiNhanh,
              newArr,
            ),
          )
          //childrenClinicService.putBNThuocBSKeToaLai(idbnttc, list))
          if (isPrint && toathuoc?.data?.length > 0) {
            yield call(() => {
              let listVT = []
              // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
              toathuoc?.data
                ?.sort((a, b) => (a?.stt ?? '').localeCompare(b?.stt ?? ''))
                ?.filter((item) => item?.idnhomthuoc !== 179 && item?.idnhomthuoc !== 205)
                ?.map((item) => {
                  if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
                    listVT?.map(
                      (itemVT) =>
                        itemVT?.idthuoc === item?.idthuoc && (itemVT.soLuong += item.soLuong),
                    )
                  } else {
                    listVT.push({
                      ...item,
                    })
                  }
                })
              // for (const item of toaPrint) {
              //   console.log(item)

              // }
              generatePhieuToaThuoc(
                infoBN,
                {
                  ...result.data,
                  dataDoKhucXa: form?.dataDoKhucXa?.nguoikham ? form?.dataDoKhucXa : null,
                },
                listVT,
                PkDangNhap,
              )
            })
          }
        } else if (
          form?.lydokolaythuoc &&
          newArr?.find((item) => item?.isGongKinh) &&
          PkDangNhap?.idChuyenKhoa === 4
        ) {
          yield call(() =>
            childrenClinicService.putToaThuocBsTuLaySoLo(
              form?.idbnttc,
              PkDangNhap?.idChiNhanh,
              newArr?.filter((item) => item?.isGongKinh),
            ),
          )
        }

        //phieu kham benh
        // if (isPrint && PkDangNhap?.idChuyenKhoa !== 2) {
        //   yield call(() => generatePhieuKhamBenh(result.data, PkDangNhap))
        // }

        yield put(BNChoKham(idcnPk))
        yield put(thuocKeToa(ID_BRANCH_LOGIN))
        // yield put({
        //   type: typeAction.DISPATCH_DETAIL_BN_KHAM,
        //   payload: null,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_INFO_BN_KHAM,
        //   payload: null,
        // })
        // yield put({
        //   type: typeAction.NGAY_THUOC_NHI,
        //   payload: 2,
        // })
        yield put({
          type: typeAction.RESET_BS_KHAM_CAPNHAT,
        })
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      }
    },
  )
  // ------------- thêm chỉ định khám bệnh cho bệnh nhân ------------//
  yield takeLatest(
    typeAction.ADD_NEW_CHI_DINH_KHAM,
    function* thuocKeToaBS({
      type,
      ValuePrinter,
      form,
      listDel,
      idbnttc,
      tenNV,
      handleReset,
      chandoanbandau,
    }) {
      try {
        const data = yield select((state) => state.childrenClinicReducer)
        const dataChiNhanh = yield select((state) => state.branchReducer)
        const dataUser = yield select((state) => state.userReducer)
        const { infoBNTTC } = yield select((state) => state.khamSucKhoeReducer)

        const { infoUser } = dataUser
        const { DetailBN } = data
        const { PkDangNhap } = dataChiNhanh
        const today = moment()
        // Lọc form thành ba mảng riêng dựa trên type
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const dvArray = form
          .filter((item) => item.type === 'dv' && !item?.infoCD) //&& !item.old
          .map((item) => ({
            idbnttc: idbnttc,
            iddichvu: item.idchiDinh,
            dongia: item.dongia,
            ptgiamgia: item?.ptgiamgia ?? 0,
            tiengiamgia: item?.tiengiamgia ?? 0,
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia,
            ngaysudung: today.format('YYYY-MM-DDTHH:mm:ss'),
            trangthaikb: 1,
            trangthaitt: 3,
            idnguoichidinh: infoUser?.idnv,
          }))
        const xnArray = form
          .filter((item) => item.type === 'xn' && !item?.infoCD) //&& !item.old
          .map((item) => ({
            idbnttc: idbnttc,
            idxn: item.idchiDinh,
            ngaychidinh: today.format('YYYY-MM-DDTHH:mm:ss'),
            bschidinh: tenNV,
            trangthaitt: 3,
            dongia: item.dongia,
            ptgiamgia: item?.ptgiamgia ?? 0,
            tiengiamgia: item?.tiengiamgia ?? 0,
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia,
          }))
        const cdhaArray = form
          .filter((item) => item.type === 'cdha' && !item?.infoCD) /// && !item.old)
          .map((item) => ({
            idbnttc: idbnttc,
            idcdha: item.idchiDinh,
            ngaychidinh: today.format('YYYY-MM-DDTHH:mm:ss'),
            idtrangthaitt: 3,
            dongia: item.dongia,
            ptgiamgia: item?.ptgiamgia ?? 0,
            tiengiamgia: item?.tiengiamgia ?? 0,
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia,
            bschidinh: infoUser?.idnv,
          }))
        const listDvDel = listDel?.filter(
          (item) => item?.trangthaitt !== 2 && item.type === 'dv' && item?.infoCD,
        )
        const listXnDel = listDel?.filter(
          (item) => item?.trangthaitt !== 2 && item.type === 'xn' && item?.infoCD,
        )
        const listCdhaDel = listDel?.filter(
          (item) => item?.trangthaitt !== 2 && item.type === 'cdha' && item?.infoCD,
        )
        // xóa những chỉ định chưa thu
        if (listDvDel.length > 0)
          for (let dv of listDvDel) {
            yield call(() => recieveService.deleteDichVuBN(dv?.infoCD?.idbndv))
          }
        if (listXnDel.length > 0)
          for (let xn of listXnDel) {
            yield call(() => recieveService.deleteXetNghiemBN(xn?.infoCD?.idbnxn))
          }
        if (listCdhaDel.length > 0)
          for (let cdhn of listCdhaDel) {
            yield call(() => recieveService.deleteCDHABN(cdhn?.infoCD?.idbncdha))
          }

        // thêm những chỉ định mới
        if (dvArray?.length > 0) {
          yield call(() => recieveService.postBNDV(dvArray)) //childrenClinicService.putBenhNhanListDVBacSi(idbnttc, dvArray)) //recieveService.postBNDV(dvArray))
        }
        if (xnArray?.length > 0) {
          yield call(() => recieveService.postBNXetNghiem(xnArray)) //childrenClinicService.putBenhNhanListXNBacSi(idbnttc, xnArray)) //recieveService.postBNXetNghiem(xnArray))
        }
        if (cdhaArray?.length > 0) {
          yield call(() => recieveService.postBNCDHA(cdhaArray)) //childrenClinicService.putBenhNhanListCDHABacSi(idbnttc, cdhaArray)) //recieveService.postBNCDHA(cdhaArray))
        }

        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        yield call(() =>
          generatePhieuChiDinh(
            infoUser,
            DetailBN ?? infoBNTTC,
            ValuePrinter,
            PkDangNhap,
            chandoanbandau,
          ),
        )
        handleReset()
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })

        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
      }
    },
  )
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_DA_KHAM_BY_PK,
    function* BNDaKham({ type, idnv, idcn, tuNgay, denNgay }) {
      try {
        const listDangKham = yield call(() =>
          childrenClinicService.getBNDangKham(idnv, idcn, tuNgay, denNgay),
        )
        const listDaKham = yield call(() =>
          childrenClinicService.getBNDaKham(idnv, idcn, tuNgay, denNgay),
        )
        yield put({
          type: typeAction.DISPATCH_BN_DANG_KHAM,
          payload: listDangKham.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_KHAM,
          payload: listDaKham.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )

  // bác sĩ sửa toa đã kê, chọn không kê toa hoặc xóa hết thuốc trong toa đã kê
  yield takeLatest(
    typeAction.DELETE_BN_THUOC_BS_KHONG_KE_TOA,
    function* deleteBNThuocBSKhongKeToa({
      type,
      form,
      formBenhNhan,
      idbnttc,
      idlydokhonglaythuoc,
    }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const infoDetailToaThuoc = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        if (infoDetailToaThuoc?.data?.DANHANTOA === 1) {
          yield put({
            type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM,
            payload: infoDetailToaThuoc?.data,
          })
          Toast.fire({
            icon: 'error',
            title: 'Dược sĩ đang thao tác!',
          })
          yield put({
            type: typeAction.CLOSE_LOADING_PAGE,
          })
          return
        }
        yield call(() => childrenClinicService.putInfoBN(formBenhNhan))
        yield call(() => childrenClinicService.deleteBNThuocBSKhongKeToa(idbnttc))
        yield call(() => childrenClinicService.updateDetailBN(form))
        yield call(() => childrenClinicService.putLyDoKhongKeToaBNTTC(idbnttc, idlydokhonglaythuoc))
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.NGAY_THUOC_NHI,
          payload: 2,
        })
        yield put({
          type: typeAction.RESET_TOA_THUOC_BN_DOCTOR,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
        console.log(error)
      }
    },
  )
  // bác sĩ sửa toa đã kê, kê lại toa đã lưu
  yield takeLatest(
    typeAction.PUT_BN_THUOC_BS_KE_TOA_LAI,
    function* updateBNThuocBSKeToaLai({
      type,
      form,
      formBenhNhan,
      idbnttc,
      list,
      infobn,
      bnttc,
      pkdangnhap,
      isPrint,
    }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })

        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const infoDetailToaThuoc = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        if (infoDetailToaThuoc.data?.DANHANTOA === 1) {
          yield put({
            type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM,
            payload: infoDetailToaThuoc.data,
          })
          Toast.fire({
            icon: 'error',
            title: 'Dược sĩ đang thao tác!',
          })
          yield put({
            type: typeAction.CLOSE_LOADING_PAGE,
          })
          return
        }
        //-----------update bệnh nhân thông rtin chung ----------//
        const { data: bnttcNew } = yield call(() => childrenClinicService.updateDetailBN(form))
        const BNTTCPrint = { ...bnttc, ...bnttcNew }
        //------------ update  lại info bệnh nhân ---------//
        yield call(() => childrenClinicService.putInfoBN(formBenhNhan))
        let listThuoc = null
        if (bnttc?.xuatvacxin) {
          // kê lại vắc xin
          listThuoc = yield call(() =>
            childrenClinicService.putBNThuocBSKeToaLaiVacXin(idbnttc, list),
          )
          if (isPrint && listThuoc?.data?.length > 0) {
            yield call(() =>
              generateKhamBenhXuatVacXin(
                infobn,
                BNTTCPrint,
                listThuoc?.data?.sort((a, b) => (a?.stt ?? '').localeCompare(b?.stt ?? '')),
                pkdangnhap,
              ),
            )
            if (PkDangNhap.idChuyenKhoa === 3) {
              //lao
              yield call(() => generatePhieuKiemTruocTiemChungLao(BNTTCPrint?.formPrint))
            } else if (PkDangNhap.idChuyenKhoa === 2) {
              // san
              yield call(() => generatePhieuKiemTruocTiemChungSan(BNTTCPrint?.formPrint))
            } else {
              // nhi
              yield call(() => generatePhieuKiemTruocTiemChung(BNTTCPrint?.formPrint))
            }
          }
        } else {
          // kê lại toa thuốc bthg
          listThuoc = yield call(() =>
            childrenClinicService.putToaThuocBsTuLaySoLo(idbnttc, pkdangnhap?.idChiNhanh, list),
          )
          if (isPrint && listThuoc?.data?.length > 0) {
            yield call(() => {
              let listVT = []
              // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
              listThuoc?.data
                ?.sort((a, b) => (a?.stt ?? '').localeCompare(b?.stt ?? ''))
                ?.filter((item) => item?.idnhomthuoc !== 179 && item?.idnhomthuoc !== 205)
                ?.map((item) => {
                  if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
                    listVT?.map(
                      (itemVT) =>
                        itemVT?.idthuoc === item?.idthuoc && (itemVT.soLuong += item.soLuong),
                    )
                  } else {
                    listVT.push({
                      ...item,
                    })
                  }
                })
              generatePhieuToaThuoc(infobn, BNTTCPrint, listVT, pkdangnhap)
            })
          }
        }

        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.NGAY_THUOC_NHI,
          payload: 2,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.RESET_TOA_THUOC_BN_DOCTOR,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu toa thành công!',
        })
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu toa thất bại!',
        })
        console.log(error)
      }
    },
  )
}
