import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Button,
  ConfigProvider,
  Input,
  Segmented,
  Table,
  notification
} from 'antd'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import {
  groupsChiDinhKham_Action
} from '../../../../store/actions/receiveAction'
import ToastCus from '../../../../utils/Toast'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
const XN = 'Xét nghiệm'
const KB = 'Khám bệnh'
const CĐHA = 'Chẩn đoán hình ảnh'

const TabsChiDinh = ({ setDataInTableDV, dataInTableDV }) => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [loadingTable, setLoadingTable] = useState(false)
  const { infoGoiKham } = useSelector((state) => state.goikhamCRMReducer)
  const { groupsChiDinh, ChiDinh } = useSelector((state) => state.receiveReducer)
  const [valueSegmented, setValueSegmented] = useState(KB)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [filterActive, setFilterActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const searchInput = useRef(null)
  const branch = JSON.parse(localStorage.getItem('BRANH_LOGIN'))
  const dispatch = useDispatch()

  useEffect(() => {
    if (infoGoiKham) {
      setDataInTableDV(
        infoGoiKham.listDV.map((item) => {
          return {
            idCheck: item.iddv || item.idxn || item.idcdha,
            iddv: item.iddv,
            idxn: item.idxn,
            idcdha: item.idcdha,
            maDichVu: item.machidinh,
            tenDichVu: item.tenchidinh,
            donVi: 'Lần',
            donGia: item.dongia,
          }
        }),
      )
    }
  }, [infoGoiKham])

  useEffect(() => {
    dispatch(groupsChiDinhKham_Action(branch))
  }, [])


  const [api, contextHolder] = notification.useNotification()

  const onClickChiDinh = (items, valueSegmented) => {
    const formatData = {
      maDichVu: items.maDichVu || items.maxn || items.macdha,
      tenDichVu: items.tenDichVu || items.tenxn || items.tenChiDinh,
      donVi: items?.donVi || items?.dvt,
      donGia: items?.donGia || items?.dongia,
    }
    if (valueSegmented === KB) {
      formatData['iddv'] = items.iddv
      formatData['idCheck'] = items.iddv
    } else if (valueSegmented === XN) {
      formatData['idxn'] = items.idxn
      formatData['idCheck'] = items.idxn
    } else {
      formatData['idcdha'] = items.idChiDinh
      formatData['idCheck'] = items.idChiDinh
    }

    const findItem = dataInTableDV.filter((item) => item.idCheck === formatData.idCheck)
    if (findItem.length > 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dịch vụ đã được chọn',
      })
      return
    }
    setDataInTableDV((pre) => [...pre, formatData])
  }

  const getListDV = async (page) => {
    setLoadingTable(true)
    try {
      const { data } = await https.get(`DichVu/GetDichVuLargeRecords?pageIndex=${page}`)
      setData((prev) => [...prev, ...data.data])
      setTotalPages(data.totalPages)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }
  }

  const getListXN = async (page) => {
    setLoadingTable(true)
    try {
      const { data } = await https.get(
        `XetNghiemDanhMuc/GetXetNghiemLargeRecords?pageIndex=${page}`,
      )
      setData((prev) => [...prev, ...data.data])
      setTotalPages(data.totalPages)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }
  }

  const getListCDHA = async (page) => {
    setLoadingTable(true)
    try {
      const { data } = await https.get(
        `ChanDoanHinhAnh/GetChanDoanHinhAnhLargeRecords?pageIndex=${page}`,
      )
      setData((prev) => [...prev, ...data.data])
      setTotalPages(data.totalPages)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }
  }

  const searchDV = async (keyword) => {
    setLoadingTable(true)
    try {
      const { data } = await https.get(`DichVu/Mobile/SearchDichVu?keyword=${keyword}`)
      setData(data)
      setTotalPages(1)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }
  }

  const searchXN = async (keyword) => {
    setLoadingTable(true)
    try {
      const { data } = await https.get(`XetNghiemDanhMuc/SearchXetNghiemDanhMuc?tenXNDM=${keyword}`)
      setData(data)
      setTotalPages(1)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }
  }

  const searchCDHA = async (keyword) => {
    setLoadingTable(true)
    try {
      const { data } = await https.get(`ChanDoanHinhAnh/SearchChuanDoanHinhAnh?tenCDHA=${keyword}`)
      setData(data)
      setTotalPages(1)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }
  }

  // chọn loại chỉ định
  const handleChangeSegmented = (value) => {
    setValueSegmented(value)
    setData([])
    setTotalPages(0)
    setPage(1)
    setSearchText('')
    setFilterActive(false)
    if (value === KB) {
      getListDV(1)
      //call api get list dich vu
    }
    if (value === XN) {
      //call api get list xet nghiem
      getListXN(1)
    }
    if (value === CĐHA) {
      //call api get chan doan hinh anh
      getListCDHA(1)
    }
  }

  const debounceSearch = useCallback(
    debounce((keyword, valueSegmented) => {
      switch (valueSegmented) {
        case KB:
          searchDV(keyword)
          break
        case XN:
          searchXN(keyword)
          break
        case CĐHA:
          searchCDHA(keyword)
          break
        default:
          break
      }
    }, 500),
    [],
  )

  const handleSearchChiDinh = (selectedKeys, confirm, valueSegmented) => {
    if (searchText) {
      confirm()
      setSearchText(searchText)
      debounceSearch(searchText, valueSegmented)
      setFilterActive(true)
      return
    }
    ToastCus.fire({
      icon: 'error',
      title: 'Vui lòng nhập tên dịch vụ cần tìm',
    })
  }

  const handleResetChiDinh = (clearFilters, confirm, valueSegmented) => {
    clearFilters()
    confirm()
    setSearchText('')
    setFilterActive(false)
    setData([])
    switch (valueSegmented) {
      case KB:
        getListDV(1)
        break
      case XN:
        getListXN(1)
        break
      case CĐHA:
        getListCDHA(1)
        break
      default:
        break
    }
  }

  const columns = [
    {
      title: 'Mã DV',
      dataIndex: 'madv',
      key: 'madv',
      width: 120,
    },
    {
      title: 'Dịch vụ',
      dataIndex: 'dv',
      key: 'dv',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="p-2">
          <Input
            placeholder="Nhập tên dịch vụ"
            value={searchText}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              setSearchText(e.target.value)
            }}
            onPressEnter={() => handleSearchChiDinh(selectedKeys, confirm, valueSegmented)}
            className="mb-3 w-64"
          />
          <div className="flex">
            <Button
              type="danger"
              onClick={() => handleResetChiDinh(clearFilters, confirm, valueSegmented)}
              className="bg-red-500 text-white w-2/4"
            >
              Hủy
            </Button>
            <Button
              type="primary"
              onClick={() => handleSearchChiDinh(selectedKeys, confirm, valueSegmented)}
              className="bg-green-500 ml-2 text-white w-2/4"
            >
              Tìm
            </Button>
          </div>
        </div>
      ),
      filterIcon: (filtered) => {
        return (
          <SearchOutlined
            style={{ color: filterActive ? '#1890ff' : '#333333' }}
            className="text-xl"
          />
        )
      },
      render: (text, record) => <p className={`${record.children ? 'font-medium' : ''}`}>{text}</p>,
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      align: 'right',
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
    },
  ]

  const fetchData = async (page) => {
    try {
      if (valueSegmented === KB) {
        await getListDV(page)
      } else if (valueSegmented === XN) {
        await getListXN(page)
      } else {
        await getListCDHA(page)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData(page)
  }, [page])

  const handleScroll = useCallback(
    debounce(() => {
      //api search return all data not pagination
      // disable scroll when search filtering
      if (filterActive) return
      const tableBody = document.querySelector('.table-dichvu .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 50 &&
        !loadingTable
      ) {
        if (page + 1 > totalPages) {
          return
        }
        setPage((prevPage) => prevPage + 1)
      }
    }, 300),
    [loadingTable],
  )

  useEffect(() => {
    const tableBody = document.querySelector('.table-dichvu .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <div className="flex flex-col gap-5">
      {contextHolder}
      <Segmented onChange={handleChangeSegmented} options={[KB, XN, CĐHA]} block />
      <div className="flex">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            scroll={{
              y: 220,
            }}
            loading={loadingTable}
            pagination={false}
            bordered
            columns={columns}
            dataSource={data.map((items) => ({
              ...items,
              madv: items.maDichVu || items.maxn || items.macdha,
              dv: items.tenDichVu || items.tenxn || items.tenChiDinh,
              price: formatNumberVND(items.donGia ?? items.dongia ?? 0),
              action: (
                <PlusOutlined
                  onClick={() => {
                    onClickChiDinh(items, valueSegmented)
                  }}
                  className="text-blue-500 cursor-pointer"
                />
              ),
            }))}
            className="w-full table-dichvu"
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default React.memo(TabsChiDinh)
