import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
  Input,
  Modal,
  Image,
  notification,
  Select,
  Popover,
  AutoComplete,
  Divider,
  Popconfirm,
  Dropdown,
  Timeline,
  Tag,
  Typography
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  PlusOutlined,
  DeleteOutlined,
  SwapOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import { useFormik } from 'formik'
import _ from 'lodash'
import { priority } from './Priority'
import {
  deleteFileBaoHong,
  deleteFileXuLy,
  deleteNhanVienDetail,
  getAllHangMuc,
  getAllNhanVienBaoHong,
  getKTThucHienBaoHong,
  getNhanVienSuCoByVung,
  historyStaff,
  updateStatus,
  updateXuLyBaoHong,
  upLoadFileBaoHong,
  uploadFileXuLy,
} from '../../../store/actions/suCoAction'
import moment from 'moment'
import { suCoService } from '../../../services/suCoService/suCoServvice'
import 'moment/locale/vi'
import DeletStaff from './DeleteStaff/DeletStaff'
import ChuyenStaff from './ChuyenStaff/ChuyenStaff'
moment.locale('vi')
const { confirm } = Modal
const { TextArea } = Input
const { Text, Link } = Typography
const DetailBaoHong = ({ isOpen, setIsOpen }) => {
  const fileInputRef = useRef(null)
  const fileXyLy = useRef(null)
  const { AllDanhMuc, nhanVien, DetailBaoHong,HistoryStaff, KTBaoHong, imgBaoHong, InfoXuLy } = useSelector(
    (state) => state.suCoReducer,
  )
  const { infoUser } = useSelector((state) => state.userReducer)
  const [branch, setBranch] = useState([])
  const [infoNhan, setInfoNhan] = useState({ tenNhan: null, tenDonViNhan: null })
  const [previewImage, setPreviewImage] = useState('')
  const [file, setFiles] = useState([])
  const [openDel, setOpenDel] = useState(false)
  const [openHistory, setOpenHistory] = useState(false)
  const [chuyenStaff,setChuyenStaff] = useState(false)
  const [staff,setStaff] = useState(null)
  const [api, contextHolder] = notification.useNotification()
  const [formXuLy, setFormXuLy] = useState(null)
  const dispatch = useDispatch()
  const handleCancel = () => {
    setIsOpen(false)
    setOpenHistory(false)
  }
  //----- lấy danh sách khu ------//
  const fetchBranh = async () => {
    try {
      const { data } = await suCoService.getAllBranh()
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleSubmit = (values) => {
    if (values.trangthaiphieu === 2) {
      values.nguoinhan = infoUser?.idnv
      values.ngaynhan = moment().format('YYYY-MM-DDTHH:mm:ss')
    } else if (values.idtrangthaiphieu === 4) {
      const today = moment().format('YYYY-MM-DDTHH:mm:ss')
      values.ngayhoanthanh = today
    }
    dispatch(updateXuLyBaoHong(values, InfoXuLy?.idxuly, formXuLy))
  }
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  const onChangeSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const handleHistoryStaff = () => {
    setOpenHistory(!openHistory)
    if (!openHistory) {
      dispatch(historyStaff(DetailBaoHong?.idbaohong))
    }
  }
  const onReset = () => {
    formik.resetForm()
    handleCancel()
    setPreviewImage('')
    setFiles([])
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbaohong: DetailBaoHong?.idbaohong,
      tenbaohong: DetailBaoHong?.tenbaohong,
      noidungbaohong: DetailBaoHong?.noidungbaohong,
      trangthaiphieu: DetailBaoHong?.idtrangthaiphieu,
      mucdosuco: DetailBaoHong?.idmucdosuco,
      chinhanhbaohong: DetailBaoHong?.idchinhanhbaohong,
      iddanhmucnhan: DetailBaoHong?.iddanhmucnhan,
      trangthaiphieu: DetailBaoHong?.idtrangthaiphieu,
      donvinhan: DetailBaoHong?.iddonvinhan,
      idnhan: DetailBaoHong?.idnhan,
      nguoibaohong: DetailBaoHong?.idnguoibaohong,
      nguoinhan: DetailBaoHong?.idnguoinhan,
      ngaynhan: DetailBaoHong?.ngaynhan,
    },
    onSubmit: (value) => handleSubmit(value),
  })
  const handleLoaiSuCo = async (value) => {
    formik.setFieldValue('iddanhmucnhan', value)
    try {
      const { data } = await suCoService.getinfoNhanByHangMuc(value)
      setInfoNhan({ tenNhan: data?.tennhan, tenDonViNhan: data?.nhom })
      formik.setFieldValue('donvinhan', data.idnhom)
      formik.setFieldValue('idnhan', data.idnhan)
    } catch (error) {
      console.log(error)
    }
  }
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  )
  useEffect(() => {
    dispatch(getNhanVienSuCoByVung())
    fetchBranh()
    dispatch(getAllHangMuc())
  }, [])
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      dispatch(getAllNhanVienBaoHong(keyword))
    }, 300),
    [],
  )
  const handleFileChange = (event) => {
    const file = event.target.files[0] // Get the first file selected

    const allowedFileTypes = ['image/jpeg', 'image/png']
    if (!allowedFileTypes.includes(file.type)) {
      openNotificationWithIcon('error', 'Thêm hình ảnh', 'Chỉ cho phép file hình ảnh')
      fileInputRef.current.value = '' // clear the input
      return
    } else {
      const formData = new FormData()
      formData.append('IDBAOHONG', DetailBaoHong?.idbaohong)
      formData.append('IDCN', formik.values.chinhanhbaohong)
      formData.append('HINHANH', file)
      dispatch(upLoadFileBaoHong(formData, DetailBaoHong?.idbaohong))
    }
  }
  const handleFileChangeXuLy = (event) => {
    const file = event.target.files[0] // Get the first file selected
    const allowedFileTypes = ['image/jpeg', 'image/png']
    if (!allowedFileTypes.includes(file.type)) {
      openNotificationWithIcon('error', 'Thêm hình ảnh', 'Chỉ cho phép file hình ảnh')
      fileXyLy.current.value = '' // clear the input
      return
    } else {
      const formData = new FormData()
      formData.append('IDXULY', InfoXuLy?.idxuly)
      formData.append('IDCN', formik.values.chinhanhbaohong)
      formData.append('HINHANH', file)
      dispatch(uploadFileXuLy(formData, DetailBaoHong?.idbaohong))
    }
  }
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }
  const addNhanVien = async (option, ob) => {
    for (let i of KTBaoHong) {
      // kiểm tra nhân viên đã add hay chưa
      if (i.idnv === option.id) {
        openNotificationWithIcon('error', 'Thêm người thực hiện', 'Người này đã được thêm')
        return
      }
    }
    const payload = {
      idbaohong: DetailBaoHong?.idbaohong,
      idnv: option?.id,
      nguoitao: infoUser?.idnv,
    }
    try {
      await suCoService.postNhanVienBaoHong(payload)
      dispatch(getKTThucHienBaoHong(DetailBaoHong?.idbaohong))
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    setFormXuLy(InfoXuLy?.noidung)
  }, [InfoXuLy])
  useEffect(() => {
    setInfoNhan({ tenNhan: DetailBaoHong?.tennhan, tenDonViNhan: DetailBaoHong?.donvinhan })
  }, [DetailBaoHong])
  return (
    <>
      {contextHolder}
      <Modal
        width="55%"
        onOk={formik.handleSubmit}
        okText="Lưu"
        cancelText="Huỷ bỏ"
        open={isOpen}
        okButtonProps={{
          disabled: !(
            DetailBaoHong?.idtrangthaiphieu === 1 || DetailBaoHong?.idtrangthaiphieu === 2
          ),
        }}
        onCancel={handleCancel}
        title={
          <p className="font-semibold text-xl w-full text-center text-blue-500">
            CHI TIẾT CÔNG VIỆC
          </p>
        }
      >
        <div className="flex gap-5">
          <div className="flex flex-col gap-2 w-2/3">
            <div className="flex flex-col gap-1">
              <label className="font-medium text-gray-400">
                Tên sự cố:<span className="text-red-500">(*)</span>
              </label>
              <Input
                name="tenbaohong"
                onChange={formik.handleChange}
                status={formik.errors.tenbaohong && formik.touched.tenbaohong ? 'error' : null}
                value={formik.values.tenbaohong}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">
                {' '}
                Loại sự cố:<span className="text-red-500">(*)</span>
              </label>
              <Select
                options={AllDanhMuc?.map((i) => ({
                  label: i.tendanhmuc,
                  value: i.idnhandm,
                }))}
                name="iddanhmucnhan"
                onChange={handleLoaiSuCo}
                value={formik.values.iddanhmucnhan}
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">Nội dung báo hỏng:</label>
              <TextArea
                showCount
                // maxLength={100}
                onChange={formik.handleChange}
                value={formik.values.noidungbaohong}
                name="noidungbaohong"
                placeholder="Nhập nội dung chi tiết"
                style={{
                  height: 150,
                  resize: 'none',
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">Hình ảnh sự cố</label>
              <div className="flex gap-1 ">
                {imgBaoHong?.split(';').map((url, index) => (
                  <li
                    key={index}
                    className="h-[100px] w-[100px] relative rounded border overflow-hidden"
                  >
                    <Image width={98} height={100} src={url} alt={`Image ${index + 1}`} />
                    {DetailBaoHong?.idtrangthaiphieu === 1 ||
                    DetailBaoHong?.idtrangthaiphieu === 2 ? (
                      <div
                        className="h-6 flex justify-center text-red-500 items-center absolute bottom-0 w-full border-t bg-red-50 cursor-pointer duration-200 hover:text-white hover:bg-red-500"
                        disabled
                        onClick={() => dispatch(deleteFileBaoHong(DetailBaoHong?.idbaohong, url))}
                      >
                        <DeleteOutlined style={{ fontSize: 18 }} />
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                ))}
                {imgBaoHong > 5 ||
                DetailBaoHong?.idtrangthaiphieu === 1 ||
                DetailBaoHong?.idtrangthaiphieu === 2 ? (
                  <div
                    onClick={handleButtonClick}
                    className="border-dotted border items-center flex justify-center rounded-md w-24 h-24 cursor-pointer hover:border-blue-500"
                  >
                    {uploadButton}
                  </div>
                ) : (
                  ''
                )}

                <input
                  ref={fileInputRef}
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 w-1/3">
            <div className="flex gap-2">
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-gray-400 font-medium">Mức độ </label>
                <Select
                  value={formik.values.mucdosuco}
                  onChange={onChangeSelected('mucdosuco')}
                  className="w-full"
                  options={priority}
                />
              </div>
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-gray-400 font-medium">Trạng thái</label>
                <Select
                  value={formik.values.trangthaiphieu}
                  onChange={onChangeSelected('trangthaiphieu')}
                  className="w-full"
                  options={[
                    {
                      label: 'Mới tạo',
                      value: 1,
                    },
                    {
                      label: 'Đã nhận',
                      value: 2,
                    },
                    {
                      label: 'Xác nhận',
                      value: 3,
                    },
                    ...(formik.values.idtrangthaiphieu === 4 ||
                    infoUser?.idnv !== DetailBaoHong?.idnguoibaohong
                      ? [
                          {
                            label: 'Hoàng thành',
                            value: 4,
                          },
                        ]
                      : []),
                  ]}
                />
              </div>
            </div>

            <div className="flex gap-2">
              <div className="flex flex-col gap-1 ">
                <label className="text-gray-400 font-medium"> Đơn vị nhận:</label>
                <Input
                  variant="filled"
                  readOnly
                  name="thoigianxuly"
                  onChange={formik.handleChange}
                  value={infoNhan?.tenDonViNhan}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-gray-400 font-medium">Loại nhãn:</label>
                <Input
                  variant="filled"
                  readOnly
                  name="thoigianxuly"
                  onChange={formik.handleChange}
                  value={infoNhan?.tenNhan}
                />
              </div>
            </div>
            <div className="flex flex-col gap-1 ">
              <label className="text-gray-400 font-medium"> Chi nhánh:</label>
              <Select
                // disabled
                showSearch
                filterOption={(input, option) => {
                  const name = option?.label ?? ''
                  const searchText = input.toLowerCase()
                  return name.toLowerCase().includes(searchText)
                }}
                placeholder="Chọn chi nhánh"
                onChange={onChangeSelected('chinhanhbaohong')}
                value={formik.values.chinhanhbaohong}
                options={branch?.map((item) => ({
                  label: item.tenchinhanh,
                  value: item.idchinhanh,
                }))}
                className="w-full"
                name="nhom"
                status={formik.errors.idcn && formik.touched.idcn ? 'error' : null}
              />
              {/* <Input
              variant="filled"
              readOnly
              name="chinhanhbaohong"
              onChange={formik.handleChange}
              value={DetailBaoHong?.chinhanhbaohong}
            /> */}
            </div>
            <div className="flex flex-col gap-1 ">
              <label className="text-gray-400 font-medium"> Người tạo:</label>
              <Input
                variant="filled"
                readOnly
                name="nguoibaohong"
                onChange={formik.handleChange}
                value={DetailBaoHong?.nguoibaohong}
              />
            </div>
            <div className="flex flex-col gap-1 ">
              <label className="text-gray-400 font-medium"> Ngày tạo:</label>
              <Input
                variant="filled"
                readOnly
                name="ngaybaohong"
                onChange={formik.handleChange}
                // status={formik.errors.thoigianxuly && formik.touched.tennhan ? 'error' : null}
                value={moment(DetailBaoHong?.ngaybaohong).format('DD/MM/YYYY HH:mm:ss')}
              />
            </div>
            <div className="flex flex-col gap-1">
            <label className="text-gray-400 font-medium flex justify-between">
                {openHistory ? ' Lịch sử thay đổi' : 'Người thực hiện'}
                <span
                  onClick={handleHistoryStaff}
                  className="text-blue-500 font-normal hover:border-b cursor-pointer border-blue-400"
                >
                  {!openHistory ? ' Lịch sử thay đổi' : 'Người thực hiện'}
                </span>
              </label>
              <div className={`flex gap-1 mb-2 flex-wrap  overflow-auto  ${openHistory ? 'h-32' : 'h-24'}`}>
              {openHistory ? (
                  <Timeline
                    className="mt-2"
                    items={HistoryStaff?.map((history) => ({
                      children: (
                        <>
                          <p>{moment(history.ngaynhan).format('DD/MM/YYYY HH:mm:ss')}</p>
                          <p>
                            {' '}
                            Hành động:{' '}
                            <Tag
                              color={
                                history.idtrangthai === 1
                                  ? 'green'
                                  : history.idtrangthai === 2
                                    ? 'blue'
                                    : 'red'
                              }
                            >
                              {history.trangthai}
                            </Tag>
                          </p>
                          <p>
                            {history.idtrangthai === 1 ? (
                              history.tennguoinhan
                            ) : history.idtrangthai === 2 ? (
                              <span>
                                <Text delete>{history.tennguoinhancu}</Text> ---{' '}
                                <Text mark>{history.tennguoinhan}</Text>
                              </span>
                            ) : (
                              <Text delete>{history.tennguoinhan}</Text>
                            )}
                          </p>
                          <p>Thực hiện: {history.tennguoitao}</p>
                          <p>Lý do: {history.lydo}</p>
                        </>
                      ),
                    }))}
                  />
                ) : <> {KTBaoHong.map((item) => (
                  <Dropdown
                    disabled={
                      DetailBaoHong?.idtrangthaiphieu !== 1 && DetailBaoHong?.idtrangthaiphieu !== 2
                    }
                    menu={{
                      items: [
                        {
                          key: 1,
                          label: 'Đổi người',
                          icon: <SwapOutlined style={{ color: 'green' }} />,
                          onClick:() => {
                            setChuyenStaff(true)
                            setStaff(item)
                          }
                        },
                        {
                          key: 2,
                          label: 'Xoá kĩ thuật',
                          icon: <DeleteOutlined style={{ color: 'red' }} />,
                          onClick:() => {
                            setOpenDel(true)
                            setStaff(item)
                          }
                        },
                      ],
                    }}
                    placement="bottomRight"
                    arrow
                  >
                    <div
                      className="rounded px-1  h-5  dark:text-black bg-blue-500 text-white flex items-center cursor-pointer gap-1 relative assignMEM"
                      key={item.idnvbh}
                    >
                      {item.tennhanvien}
                    </div>
                  </Dropdown>
                ))}</> }
               
              </div>
              {!openHistory && <Popover
                placement="bottomLeft"
                trigger="click"
                content={() => {
                  return (
                    <AutoComplete
                      placeholder="Enter name"
                      style={{ width: '100%' }}
                      options={nhanVien?.map((item) => ({
                        label: item.tennv,
                        value: item.tennv,
                        id: item.id,
                      }))}
                      onSearch={debounceDropDown}
                      onSelect={(value, option) => {
                        addNhanVien(option)
                      }}
                    />
                  )
                }}
                title="Thêm người"
              >
                {DetailBaoHong?.idtrangthaiphieu === 1 || DetailBaoHong?.idtrangthaiphieu === 2   ? (
                  <button className="w-6 h-6 rounded bg-gray-300 dark:text-black">+</button>
                ) : (
                  ''
                )}
              </Popover> }
              
            </div>
          </div>
        </div>
        <Divider style={{ margin: 4 }} orientation="left" dashed plain>
          <p className="text-blue-500 font-semibold">Thông tin xử lý</p>
        </Divider>
        <div className="flex gap-2">
          <div className="flex flex-col gap-1 w-2/3">
            <label className="text-gray-400 font-medium">Nội dung xử lý:</label>
            <TextArea
              showCount
              // maxLength={100}
              onChange={(e) => setFormXuLy(e.target.value)}
              value={formXuLy}
              name="NOIDUNGBAOHONG"
              placeholder="Nhập nội dung chi tiết"
              style={{
                height: 100,
                resize: 'none',
              }}
            />
          </div>
          <div className="w-1/3">
            <label className="text-gray-400 font-medium">Hình ảnh xử lý</label>
            <div className="flex gap-1 ">
              {InfoXuLy?.hinhanh?.split(';').map((url, index) => (
                <li
                  key={index}
                  className="h-[100px] w-[100px] relative rounded border overflow-hidden"
                >
                  <Image width={98} height={100} src={url} alt={`Image ${index + 1}`} />
                  {DetailBaoHong?.idtrangthaiphieu === 1 ||
                  DetailBaoHong?.idtrangthaiphieu === 2 ? (
                    <div
                      className="h-6 flex justify-center text-red-500 items-center absolute bottom-0 w-full border-t bg-red-50 cursor-pointer duration-200 hover:text-white hover:bg-red-500"
                      disabled
                      onClick={() =>
                        dispatch(deleteFileXuLy(InfoXuLy?.idxuly, url, InfoXuLy?.idbaohong))
                      }
                    >
                      <DeleteOutlined style={{ fontSize: 18 }} />
                    </div>
                  ) : (
                    ''
                  )}
                </li>
              ))}
              {DetailBaoHong?.idtrangthaiphieu === 1 || DetailBaoHong?.idtrangthaiphieu === 2 ? (
                <div
                  onClick={() => fileXyLy.current.click()}
                  className="border-dotted border items-center flex justify-center rounded-md w-24 h-24 cursor-pointer hover:border-blue-500"
                >
                  {imgBaoHong > 5 ? null : uploadButton}
                </div>
              ) : (
                ''
              )}

              <input ref={fileXyLy} id="file-xuly" type="file" onChange={handleFileChangeXuLy} />
            </div>
          </div>
        </div>
      </Modal>
      <DeletStaff openDel={openDel} setOpenDel={setOpenDel} staff={staff} />
      <ChuyenStaff  chuyenStaff={chuyenStaff} setChuyenStaff={setChuyenStaff} debounceDropDown={debounceDropDown} staff={staff}/>
    </>
  )
}

export default DetailBaoHong
