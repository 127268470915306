import { https } from '../apiService'

export const childrenClinicService = {
  getBNCho: (idcnPk) => https.get(`BenhNhanTTC/DanhSachChoKham?idCnPk=${idcnPk}`),
  getDetailBNKham: (idbnttc) => https.get(`BenhNhanTTC/GetByIDBNTTC?id=${idbnttc}`),
  updateDetailBN: (form) => https.put('BenhNhanTTC', form),
  getLoiDan: () => https.get('LoiDanBacSi/GetAll'),
  getKetoaThuoc: (idChiNhanh) => https.get(`TonKho/KhoDuocByChiNhanh?IDChiNhanh=${idChiNhanh}`),
  postToaThuoc: (data) => https.post('BenhNhanThuoc/ThemBenhNhanThuoc', data),
  getBNDichVu: (idbnttc) => https.get(`BenhNhanDichVu/GetBenhNhanDichVu/${idbnttc}`),
  getDetailPhieuThu: (idbnttc) =>
    https.get(`BenhNhanThongTinChung/GetThongTinPhieuThu?idbnttc=${idbnttc}`),
  getBNDangKham: (idnv, idcn, tuNgay, denNgay) =>
    https.get(
      `BenhNhanTTC/GetDanhSachBenhNhanDangkham?idbskham=${idnv}&idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getBNDaKham: (idnv, idcn, tuNgay, denNgay) =>
    https.get(
      `BenhNhanTTC/GetDanhSachBenhNhanDakham?idbskham=${idnv}&idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getChanDoan: (keyword) => https.get(`ChanDoan/SearchChanDoan?keyword=${keyword}`),
  getChanDoanByidICD: (idICD) => https.get(`ChanDoan/GetICD10ByID`, { params: { idICD } }),
  getDetailToaThuocBNKham: (idbnttc) => https.get(`BenhNhanTTC/toa?id=${idbnttc}`),
  deleteBNThuocBSKhongKeToa: (idbnttc) =>
    https.delete(`BenhNhanThuoc/DeleteBNThuocAndBNPhieuthuByIDBNTTC?idbnttc=${idbnttc}`),
  putBNThuocBSKeToaLai: (idbnttc, list) =>
    https.put(`BenhNhanThuoc/UpdateBenNhanThuocForBacSi?idbnttc=${idbnttc}`, list),
  putBNThuocBSKeToaLaiVacXin: (idbnttc, list) =>
    https.put(`BenhNhanThuoc/UpdateBenNhanThuocByTiemChung?idbnttc=${idbnttc}`, list),
  getListLyDoKhongKeToa: () => https.get(`LyDoKoLayThuoc/GetAllLyDo_BacSi`),
  putLyDoKhongKeToaBNTTC: (idbnttc, idlido) =>
    https.put(`BenhNhanTTC/UpdateLiDoKhongLayThuoc?idbnttc=${idbnttc}&idlido=${idlido}`),
  putBenhNhanListDVBacSi: (idbnttc, list) =>
    https.put(`BenhNhanDichVu/UpdateBenhNhanDichVuBacSi?idbnttc=${idbnttc}`, list),
  putBenhNhanListXNBacSi: (idbnttc, list) =>
    https.put(`BenhNhanXetNghiem/UpdateBenhNhanXetNghiemBacSi?idbnttc=${idbnttc}`, list),
  putBenhNhanListCDHABacSi: (idbnttc, list) =>
    https.put(`BenhNhanCDHA/UpdateBenhNhanCDHABacSi?idbnttc=${idbnttc}`, list),
  putToaThuocBsTuLaySoLo: (idbnttc, idcn, list) =>
    https.put(
      `BenhNhanThuoc/UpdateBenNhanThuocForBacSiTongHop?idbnttc=${idbnttc}&idcn=${idcn}`,
      list,
    ),
  putBNThuocKTVKeGong: (idbnttc, list) =>
    https.put(`BenhNhanThuoc/UpdateBenNhanThuocForBacSiGongKinh?idbnttc=${idbnttc}`, list),
  putInfoBN: (form) => https.put('BenhNhan', form),
}
