import React, { useRef, useState } from 'react'
import { Table, ConfigProvider, Input, Space, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const ListBN = ({ isLoading, listBn, getInfoBnXQuang }) => {
  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
    // searchedColumn === dataIndex ? (
    //   <Highlighter
    //     highlightStyle={{
    //       backgroundColor: '#ffc069',
    //       padding: 0,
    //     }}
    //     searchWords={[searchText]}
    //     autoEscape
    //     textToHighlight={text ? text.toString() : ''}
    //   />
    // ) : (
    //   text
    // ),
  })
  return (
    <div className="p-2">
      <ConfigProvider
        theme={{
          token: {
            padding: 4,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
            },
          },
        }}
      >
        <Table
          loading={isLoading}
          scroll={{ y: 290 }}
          bordered
          pagination={false}
          onRow={(record) => ({
            onClick: () => getInfoBnXQuang(record?.idbncdha, record?.idcdha, record?.idbnttc),
          })}
          dataSource={listBn}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 'stt',
              width: 35,
              align: 'center',
              render: (text, record, index) => ++index,
            },
            {
              title: 'Mã bệnh nhân',
              dataIndex: 'mabenhnhan',
              key: 'mabenhnhan',
              width: 130,
              ...getColumnSearchProps('mabenhnhan'),
            },
            {
              title: 'Tên bệnh nhân',
              dataIndex: 'tenbenhnhan',
              key: 'tenbenhnhan',
              ...getColumnSearchProps('tenbenhnhan'),
            },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default ListBN
