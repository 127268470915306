import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { noiSoiService } from '../../services/NoiSoi/noiSoiService'
import { generatePhieuSieuAm } from '../../utils/report/san/phieuSieuAm'
import { generatePhieuSieuAmMultiImage } from '../../utils/report/san/phieuSieuAmForMultiImageOnly'

import ToastCus from '../../utils/Toast'

export function* noiSoiSaga() {
  // POST BỆNH NHÂN X QUANG
  yield takeLatest(
    typeAction.POST_INFO_BN_NOISOI,
    function* postInfoBnXQuangSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer),
          { PkDangNhap } = yield select((state) => state.branchReducer)
        const formData = new FormData()
        formData.append('IDBNTTC', form?.infoBnNoiSoi?.idbnttc)
        formData.append('BSNOISOI', infoUser?.idnv)
        formData.append('CHANDOANSOBO', '')
        formData.append('LOAINOISOI', form?.infoBnNoiSoi?.loainoisoi)
        formData.append('IDBNCDHA', form?.infoBnNoiSoi?.idbncdha)
        formData.append('KETLUAN', form?.ketLuan ?? '')
        formData.append('BIEUMAU', form?.mota ?? '')
        formData.append('TIEUDE', form?.infoBieuMau?.tieude ?? '')
        if (form?.infoBieuMau?.idbm) formData.append('IDBM', form?.infoBieuMau?.idbm)
        if (form?.listHinh?.length) {
          const Files = form?.listHinh?.map((item) => item?.file)
          Files?.forEach((file, index) => {
            formData.append('files', file)
          })
        }
        const { data: infoNoiSoi } = yield call(() => noiSoiService.postInfoBnNoiSoi(formData))

        // thông tin in
        // console.log(infoNoiSoi)
        // console.log(form)

        const formatInfoBnNS = {
          ...form?.infoBnNoiSoi,
          ...infoNoiSoi,

          loaisieuam: form?.infoBnNoiSoi?.loainoisoi,
          bschidinh: form?.infoBnNoiSoi?.tenbschidinh,

          ngaysieuam: infoNoiSoi?.ngaynoisoi,
          bssieuam: infoNoiSoi?.tenbsnoisoi,

          // tieude: infoXQuang?.tieude,
          // bieumau: infoXQuang?.bieumau,
          // ketluan: infoXQuang?.ketluan,
        }
        yield call(() =>
          form?.listHinhPrint?.length >= 3
            ? generatePhieuSieuAmMultiImage(formatInfoBnNS, form?.listHinhPrint, PkDangNhap)
            : generatePhieuSieuAm(formatInfoBnNS, form?.listHinhPrint, PkDangNhap),
        )

        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // PUT BỆNH NHÂN X QUANG
  yield takeLatest(
    typeAction.PUT_INFO_BN_NOISOI,
    function* postInfoBnXQuangSaga({ type, form, isPrint, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer),
          { PkDangNhap } = yield select((state) => state.branchReducer)

        if (form?.listHinhXoa?.length) {
          // xóa hình cũ
          for (const url of form?.listHinhXoa) {
            yield call(() => noiSoiService.deleteFileBnNoiSoi(form?.infoBnNoiSoi?.idbnns, url))
          }
        }
        if (form?.listHinh?.length) {
          // thêm hình mới
          const formData = new FormData()
          formData.append('IDBNNS', form?.infoBnNoiSoi?.idbnns)
          const Files = form?.listHinh?.map((item) => item?.file)
          Files?.forEach((file, index) => {
            formData.append('HINHANH', file)
          })
          yield call(() => noiSoiService.putFileBnNoiSoi(formData))
        }
        const { data: infoNoiSoi } = yield call(() =>
          noiSoiService.putInfoBnNoiSoi({
            idbnns: form?.infoBnNoiSoi?.idbnns,
            idbnttc: form?.infoBnNoiSoi?.idbnttc,
            // ngaynoisoi: '2024-11-14T08:17:23.345Z',
            // bsnoisoi: 0,
            // chandoansobo: 'string',
            // loainoisoi: 'string',
            idbncdha: form?.infoBnNoiSoi?.idbncdha,
            // linkhinhanh: 'string',
            ketluan: form?.ketLuan,
            bieumau: form?.mota,
            tieude: form?.infoBieuMau?.tieude,
            nguoisua: infoUser?.idnv,
            // ngaysua: '2024-11-14T08:17:23.345Z',
            // idttns: 0,
            idbm: form?.infoBieuMau?.idbm,
            // ghichu: 'string',
          }),
        )

        if (isPrint) {
          // thông tin in
          // console.log(infoNoiSoi)
          // console.log(form)

          const formatInfoBnNS = {
            ...form?.infoBnNoiSoi,
            ...infoNoiSoi,

            loaisieuam: form?.infoBnNoiSoi?.loainoisoi,
            bschidinh: form?.infoBnNoiSoi?.tenbschidinh,

            ngaysieuam: infoNoiSoi?.ngaynoisoi,
            bssieuam: infoNoiSoi?.tenbsnoisoi,

            // tieude: infoXQuang?.tieude,
            // bieumau: infoXQuang?.bieumau,
            // ketluan: infoXQuang?.ketluan,
          }
          yield call(() =>
            form?.listHinhPrint?.length >= 3
              ? generatePhieuSieuAmMultiImage(formatInfoBnNS, form?.listHinhPrint, PkDangNhap)
              : generatePhieuSieuAm(formatInfoBnNS, form?.listHinhPrint, PkDangNhap),
          )
        }

        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
