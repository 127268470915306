import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Table, Button, Input, ConfigProvider, Select, Checkbox, Popconfirm } from 'antd'
import {
  PlusOutlined,
  VerticalAlignBottomOutlined,
  SyncOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import { dichVuService } from '../../../services/dichVu/dichVuService'
import { companyService } from '../../../services/Company/companyService'
import { formattedNumber } from '../../../utils/formattedNumber'
import { https } from '../../../services/apiService'
import { putSuDungListDichVuChuyenKhoaAction } from '../../../store/actions/dichVuAction'
import ToastCus from '../../../utils/Toast'
import ModalAdd from './Modal/ModalAdd'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const BtnXuatExcel = styled(Button)`
  color: #1da57a;
  border-color: #ccc;
  background-color: #fff;

  &:hover {
    color: #1da57a !important;
    border-color: #1da57a !important;
    background-color: #fff !important;
  }
`
const DichVu = () => {
  const dispatch = useDispatch()
  const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_CONGTY_LOGIN = Number(localStorage.getItem('id_company'))
  const [listChuyenKhoa, setlistChuyenKhoa] = useState([])
  const [listNhomDichVu, setlistNhomDichVu] = useState([])
  const [listCongTy, setlistCongTy] = useState([])
  const [listDichVu, setlistDichVu] = useState([])
  const [listDichVuChuyenKhoa, setlistDichVuChuyenKhoa] = useState([])
  const [idNhomDv, setidNhomDv] = useState(null)
  const [idCongTy, setidCongTy] = useState(ID_CONGTY_LOGIN)
  const [search, setsearch] = useState(null)
  const [isLoading, setisLoading] = useState(false)
  const [isLoadingExcel, setisLoadingExcel] = useState(false)
  const [isLoadingChuyenKhoa, setisLoadingChuyenKhoa] = useState(false)
  const [infoChuyenKhoa, setinfoChuyenKhoa] = useState(null)
  const [isModal, setisModal] = useState(null)
  const [infoDvEdit, setinfoDvEdit] = useState(null)

  const handleSuDungDichVuChuyenKhoa = (infoDv, sudung) => {
    if (!infoChuyenKhoa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn chuyên khoa!',
      })
      return
    }
    dispatch(
      putSuDungListDichVuChuyenKhoaAction(
        infoChuyenKhoa?.idChuyenKhoa,
        sudung ? 0 : 1,
        [infoDv?.iddv],
        getListDichVuChuyenKhoa,
      ),
    )
  }
  const handleSuDungListDichVuChuyenKhoa = (isChecked) => {
    if (!infoChuyenKhoa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn chuyên khoa!',
      })
      return
    }
    dispatch(
      putSuDungListDichVuChuyenKhoaAction(
        infoChuyenKhoa?.idChuyenKhoa,
        isChecked ? 0 : 1,
        listDichVu?.map((item) => item?.iddv),
        getListDichVuChuyenKhoa,
      ),
    )
  }
  const getListChuyenKhoa = async () => {
    try {
      setisLoadingChuyenKhoa(true)
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setlistChuyenKhoa(data)
    } catch (error) {
      console.log('getAllChuyenKhoa : ', error)
    } finally {
      setisLoadingChuyenKhoa(false)
    }
  }
  const getListNhomDichVu = async () => {
    try {
      const { data } = await dichVuService.getListGroupsDichVu()
      setlistNhomDichVu(data)
      setidNhomDv(data?.find((item) => item?.tenNhom?.toLowerCase()?.includes('gói khám'))?.idNhom)
    } catch (error) {
      console.log('getListNhomDichVu : ', error)
    }
  }
  const getListCongTy = async () => {
    try {
      const { data } = await companyService.getAllListCompany()
      setlistCongTy(data)
    } catch (error) {
      console.log('getListCongTy : ', error)
    }
  }
  const getListDichVu = async () => {
    try {
      setisLoading(true)
      const { data } = await dichVuService.searchByKeyword(
        idNhomDv,
        idCongTy,
        search?.length ? search?.trim() : search,
      )
      setlistDichVu(data)
    } catch (error) {
      console.log('getListDichVu : ', error)
    } finally {
      setisLoading(false)
    }
  }
  const getListDichVuChuyenKhoa = async (idck) => {
    try {
      setisLoadingChuyenKhoa(true)
      const { data } = await dichVuService.getListDichVuChuyenKhoa(idck)
      setlistDichVuChuyenKhoa(data)
    } catch (error) {
      console.log('getListDichVuChuyenKhoa : ', error)
    } finally {
      setisLoadingChuyenKhoa(false)
    }
  }
  useEffect(() => {
    getListChuyenKhoa()
    getListNhomDichVu()
    getListCongTy()
  }, [])
  useEffect(() => {
    if (idNhomDv && idCongTy) {
      getListDichVu()
    }
  }, [listCongTy, listNhomDichVu])

  const handleDownload = async () => {
    try {
      setisLoadingExcel(true)
      const { data } = await https.get('DichVu/ExportDichVuDetailToExcel', { responseType: 'blob' })
      const href = URL.createObjectURL(data)
      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', 'Danh mục dịch vụ.xlsx')
      document.body.appendChild(link)
      link.click()
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (error) {
      console.log('handleDownload : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      setisLoadingExcel(false)
    }
  }
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <ModalAdd
        isModal={isModal}
        setisModal={setisModal}
        listNhomDichVu={listNhomDichVu}
        listCongTy={listCongTy}
        getListDichVu={getListDichVu}
        infoDvEdit={infoDvEdit}
        setinfoDvEdit={setinfoDvEdit}
      />
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <div className="grid grid-cols-4 gap-2 h-full">
          <div className="pr-2 border-e">
            <div className="h-[2.5rem] flex items-center font-semibold text-[16px]">
              {infoChuyenKhoa ? infoChuyenKhoa?.tenChuyenKhoa : 'Chọn chuyên khoa'}
            </div>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                  },
                },
              }}
            >
              <Table
                onRow={(record) => {
                  return {
                    onClick: () => {
                      setinfoChuyenKhoa(record)
                      getListDichVuChuyenKhoa(record?.idChuyenKhoa)
                    }, // click row
                  }
                }}
                rowClassName={(record) =>
                  record?.idChuyenKhoa === infoChuyenKhoa?.idChuyenKhoa ? 'bg-[#ecf0f1]' : ''
                }
                loading={isLoadingChuyenKhoa}
                scroll={{ y: 779 }}
                bordered
                pagination={false}
                dataSource={listChuyenKhoa}
                columns={[
                  {
                    title: 'STT',
                    dataIndex: 'stt',
                    key: 'stt',
                    width: 50,
                    align: 'center',
                    render: (text, record, index) => ++index,
                  },
                  {
                    title: 'Mã chuyên khoa',
                    dataIndex: 'maChuyenKhoa',
                    key: 'maChuyenKhoa',
                    width: 120,
                  },
                  {
                    title: 'Tên chuyên khoa',
                    dataIndex: 'tenChuyenKhoa',
                    key: 'tenChuyenKhoa',
                  },
                ]}
              />
            </ConfigProvider>
          </div>
          <div className="col-span-3">
            <div className="flex justify-between items-center mb-2">
              <div className="flex justify-start items-center gap-2">
                <Select
                  placeholder="Chọn công ty..."
                  className="w-[17rem]"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '')?.toLowerCase()?.includes((input ?? '')?.toLowerCase())
                  }
                  value={idCongTy}
                  onChange={(value) => setidCongTy(value)}
                  options={listCongTy
                    ?.filter((item) => item?.idct === ID_CONGTY_LOGIN)
                    ?.map((item) => ({
                      key: item?.idct,
                      value: item?.idct,
                      label: item?.tenct,
                    }))}
                />
                <Select
                  placeholder="Chọn nhóm dịch vụ..."
                  className="w-[17rem]"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '')?.toLowerCase()?.includes((input ?? '')?.toLowerCase())
                  }
                  value={idNhomDv}
                  onChange={(value) => setidNhomDv(value)}
                  options={listNhomDichVu?.map((item) => ({
                    key: item?.idNhom,
                    value: item?.idNhom,
                    label: item?.tenNhom,
                  }))}
                />
                <Input
                  placeholder="Tìm dịch vụ..."
                  className="w-[17rem]"
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                />
                <Button
                  loading={isLoading}
                  type="primary"
                  shape="circle"
                  icon={<SyncOutlined />}
                  onClick={() => {
                    setsearch((prev) => (prev?.length ? prev?.trim() : prev))
                    getListDichVu()
                  }}
                />
              </div>
              <div className="flex justify-end items-center gap-2">
                <BtnXuatExcel
                  loading={isLoadingExcel}
                  icon={<VerticalAlignBottomOutlined />}
                  onClick={handleDownload}
                >
                  Xuất excel
                </BtnXuatExcel>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => setisModal('add')}>
                  Tạo dịch vụ
                </Button>
              </div>
            </div>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                  },
                },
              }}
            >
              <style>
                {`
              .custom-ok-button {
                background-color: #1677ff;
              }
              .custom-ok-button:hover {
                background-color: background-color: rgba(22, 119, 255, 0.8);
              }
            `}
              </style>
              <Table
                loading={isLoading}
                scroll={{ x: 2330, y: 779 }}
                bordered
                pagination={false}
                dataSource={listDichVu}
                columns={[
                  {
                    title: () => {
                      let isIndeterminate =
                        listDichVu?.some((itemDv) =>
                          listDichVuChuyenKhoa?.find(
                            (itemCk) => itemCk?.iddv === itemDv?.iddv && itemCk?.sudung === 1,
                          ),
                        ) &&
                        listDichVu?.some((itemDv) =>
                          listDichVuChuyenKhoa?.find(
                            (itemCk) => itemCk?.iddv === itemDv?.iddv && !itemCk?.sudung,
                          ),
                        )
                      let isChecked = listDichVu?.every((itemDv) =>
                        listDichVuChuyenKhoa
                          ?.filter((item) => item?.sudung === 1)
                          ?.find((itemCk) => itemCk?.iddv === itemDv?.iddv),
                      )
                      // ||
                      // !listDichVu?.every((itemDv) =>
                      //   listDichVuChuyenKhoa
                      //     ?.filter((item) => !item?.sudung)
                      //     ?.find((itemCk) => itemCk?.iddv === itemDv?.iddv),
                      // )
                      return !infoChuyenKhoa || !listDichVu?.length ? (
                        <Checkbox
                          checked={false}
                          onChange={() => {
                            ToastCus.fire({
                              icon: 'error',
                              title: 'Vui lòng kiểm tra lại!',
                            })
                          }}
                        />
                      ) : (
                        <>
                          <Popconfirm
                            // placement="topLeft"
                            title={'Gán dịch vụ vào chuyên khoa'}
                            description={
                              <div>
                                <div>
                                  Bạn xác nhận muốn&nbsp;
                                  <span className="font-semibold">
                                    {isChecked ? 'BỎ GÁN' : 'GÁN'}
                                  </span>
                                  &nbsp;cho&nbsp;
                                  <span className="font-semibold">Tất cả dịch vụ đang xem</span>?
                                </div>
                                {/* <div className="font-semibold">
                                Dịch vụ:&nbsp;
                                <span className="italic">
                                  {record?.maDichVu} - {record?.tenDichVu}
                                </span>
                              </div>
                              <div className="font-semibold">
                                Chuyên khoa:&nbsp;
                                <span className="italic">
                                  {infoChuyenKhoa?.maChuyenKhoa} -{infoChuyenKhoa?.tenChuyenKhoa}
                                </span>
                              </div> */}
                              </div>
                            }
                            onConfirm={() => handleSuDungListDichVuChuyenKhoa(isChecked)}
                            okText="Xác nhận"
                            cancelText="Hủy bỏ"
                            okButtonProps={{
                              className: 'custom-ok-button',
                            }}
                          >
                            <Checkbox indeterminate={isIndeterminate} checked={isChecked} />
                          </Popconfirm>
                        </>
                      )
                    },
                    dataIndex: 'checkbox',
                    key: 'checkbox',
                    width: 50,
                    align: 'center',
                    fixed: 'left',
                    render: (text, record, index) => {
                      let sudung = listDichVuChuyenKhoa?.find(
                        (itemCk) => itemCk?.iddv === record?.iddv,
                      )?.sudung
                      return !infoChuyenKhoa ? (
                        <Checkbox
                          checked={false}
                          onChange={() => {
                            ToastCus.fire({
                              icon: 'error',
                              title: 'Vui lòng chọn chuyên khoa!',
                            })
                          }}
                        />
                      ) : (
                        <>
                          <Popconfirm
                            // placement="topLeft"
                            title={'Gán dịch vụ vào chuyên khoa'}
                            description={
                              <div>
                                <div>
                                  Bạn xác nhận muốn&nbsp;
                                  <span className="font-semibold">{sudung ? 'BỎ GÁN' : 'GÁN'}</span>
                                  ?
                                </div>
                                {/* <div className="font-semibold">
                                Dịch vụ:&nbsp;
                                <span className="italic">
                                  {record?.maDichVu} - {record?.tenDichVu}
                                </span>
                              </div>
                              <div className="font-semibold">
                                Chuyên khoa:&nbsp;
                                <span className="italic">
                                  {infoChuyenKhoa?.maChuyenKhoa} -{infoChuyenKhoa?.tenChuyenKhoa}
                                </span>
                              </div> */}
                              </div>
                            }
                            onConfirm={() => handleSuDungDichVuChuyenKhoa(record, sudung)}
                            okText="Xác nhận"
                            cancelText="Hủy bỏ"
                            okButtonProps={{
                              className: 'custom-ok-button',
                            }}
                          >
                            <Checkbox checked={sudung} />
                          </Popconfirm>
                        </>
                      )
                    },
                  },
                  {
                    title: 'STT',
                    dataIndex: 'stt',
                    key: 'stt',
                    width: 50,
                    align: 'center',
                    fixed: 'left',
                    render: (text, record, index) => ++index,
                  },
                  idNhomDv === 47
                    ? {
                        title: 'Mã gói khám',
                        dataIndex: 'iddv',
                        key: 'iddv',
                        width: 120,
                        fixed: 'left',
                        align: 'center',
                      }
                    : {
                        width: 0,
                      },

                  {
                    title: 'Mã dịch vụ',
                    dataIndex: 'maDichVu',
                    key: 'maDichVu',
                    width: 120,
                    fixed: 'left',
                  },
                  {
                    title: 'Tên dịch vụ',
                    dataIndex: 'tenDichVu',
                    key: 'tenDichVu',
                  },
                  {
                    title: 'ĐVT',
                    dataIndex: 'donVi',
                    key: 'donVi',
                    width: 60,
                    align: 'center',
                  },
                  {
                    title: 'Người tạo',
                    dataIndex: 'nguoiTao',
                    key: 'nguoiTao',
                    width: 250,
                  },
                  {
                    title: 'Ngày tạo',
                    dataIndex: 'ngayTao',
                    key: 'ngayTao',
                    width: 140,
                    align: 'center',
                    render: (text, record, index) =>
                      text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-',
                  },
                  {
                    title: 'Người sửa',
                    dataIndex: 'nguoiTao',
                    key: 'nguoiTao',
                    width: 250,
                  },
                  {
                    title: 'Ngày sửa',
                    dataIndex: 'ngayTao',
                    key: 'ngayTao',
                    width: 140,
                    align: 'center',
                    render: (text, record, index) =>
                      text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-',
                  },
                  {
                    title: 'Tiền gửi mẫu',
                    dataIndex: 'tienGuiMau',
                    key: 'tienGuiMau',
                    width: 120,
                    align: 'right',
                    render: (text, record, index) => formattedNumber(text ?? 0),
                  },
                  {
                    title: 'Tiền chiết khấu',
                    dataIndex: 'tienChietKhau',
                    key: 'tienChietKhau',
                    width: 120,
                    align: 'right',
                    render: (text, record, index) => formattedNumber(text ?? 0),
                  },
                  {
                    title: 'Tiền gia công',
                    dataIndex: 'tienGiaCong',
                    key: 'tienGiaCong',
                    width: 120,
                    align: 'right',
                    render: (text, record, index) => formattedNumber(text ?? 0),
                  },
                  {
                    title: 'Tiền vận chuyển',
                    dataIndex: 'tienCongVanChuyen',
                    key: 'tienCongVanChuyen',
                    width: 120,
                    align: 'right',
                    render: (text, record, index) => formattedNumber(text ?? 0),
                  },
                  {
                    title: 'Tiền vốn',
                    dataIndex: 'giaVon',
                    key: 'giaVon',
                    width: 100,
                    align: 'right',
                    render: (text, record, index) => formattedNumber(text ?? 0),
                  },
                  {
                    title: 'Đơn giá',
                    dataIndex: 'donGia',
                    key: 'donGia',
                    width: 100,
                    align: 'right',
                    fixed: 'right',
                    render: (text, record, index) => formattedNumber(text ?? 0),
                  },
                  {
                    title: '',
                    dataIndex: 'action',
                    key: 'action',
                    width: 50,
                    align: 'center',
                    fixed: 'right',
                    render: (text, record, index) => (
                      <EditOutlined
                        className="cursor-pointer text-green-500 text-lg flex justify-center items-center"
                        onClick={() => {
                          setinfoDvEdit(record)
                          setisModal('edit')
                        }}
                      />
                    ),
                  },
                ]}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DichVu
