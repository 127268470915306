import moment from 'moment'
import { formatNumberVND } from '../../../../utils/formatNumberVND'

export const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
    width: 40,
    fixed: 'left',
    render: (text, record, index) => ++index,
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'tenchinhanh',
    key: 'tenchinhanh',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Mã hàng',
    dataIndex: 'mahang',
    key: 'mahang',
    width: 150,
  },
  {
    title: 'Tên hàng',
    dataIndex: 'tenhang',
    key: 'tenhang',
    width: 200,
  },
  {
    title: 'Hoạt chất',
    dataIndex: 'hoatchat',
    key: 'hoatchat',
    width: 200,
  },
  {
    title: 'Nhóm hàng',
    dataIndex: 'nhomhang',
    key: 'nhomhang',
    width: 200,
  },
  {
    title: 'Đơn Vị',
    dataIndex: 'dvt',
    key: 'dvt',
    width: 100,
  },
  {
    title: 'Khối lượng',
    dataIndex: 'khoiluong',
    key: 'khoiluong',
    width: 150,
    align: 'right',
  },
  {
    title: 'Đơn giá',
    dataIndex: 'dongia',
    key: 'dongia',
    width: 150,
    align: 'right',
    render: (text) => formatNumberVND(text ?? 0),
  },
  {
    title: 'Thành tiền',
    dataIndex: 'thanhtien',
    key: 'thanhtien',
    width: 150,
    align: 'right',
    render: (text) => formatNumberVND(text ?? 0),
  },
  {
    title: 'Giảm %',
    dataIndex: 'giamphantram',
    key: 'giamphantram',
    width: 100,
    align: 'right',
  },
  {
    title: 'Giảm giá',
    dataIndex: 'giamgia',
    key: 'giamgia',
    width: 150,
    align: 'right',
    render: (text) => formatNumberVND(text ?? 0),
  },
  {
    title: 'Thanh toán',
    dataIndex: 'thanhtoan',
    key: 'thanhtoan',
    width: 150,
    align: 'right',
    fixed: 'right',
    render: (text) => formatNumberVND(text ?? 0),
  },
  //   {
  //     title: 'Trạng thái',
  //     dataIndex: 'trangthai',
  //     key: 'trangthai',
  //     width: 150,
  //     align: 'right',
  //     fixed: 'right',
  //     render: (text) => (
  //       <span style={{ color: text === '1' ? 'green' : 'red', fontWeight: 'bold' }}>
  //         {text === '1' ? 'Đã gửi mẫu' : 'Chưa gửi mẫu'}
  //       </span>
  //     ),
  //   },
]
