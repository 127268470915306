import { https } from '../apiService'

export const banLeService = {
  getPhieuThuBanLe: (idcn, tuNgay, denNgay, keyword) =>
    https.get('BenhNhanPhieuThu/GetDanhSachPhieuThuBanLe', {
      params: {
        idcn,
        tuNgay,
        denNgay,
        keyword,
      },
    }),
}
