import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  ConfigProvider,
  Input,
  Pagination,
  Table,
  Segmented,
  Modal,
  Select,
  InputNumber,
} from 'antd'
import { SyncOutlined, PlusOutlined } from '@ant-design/icons'
const { confirm } = Modal
function ModalAdd({ isModalAdd, setIsModalAdd }) {
  const [isLoading, setIsLoading] = useState(false)
  const [tabChiDinh, setTabChiDinh] = useState(1)
  const [form, setForm] = useState(null)
  return (
    <Modal
      width={1200}
      open={isModalAdd}
      onCancel={() => setIsModalAdd(false)}
      okText={'Lưu'}
      //   onOk={vacXinEdit ? handleSubmitEdit : formik.handleSubmit}
      cancelText="Huỷ bỏ"
      title={<p className="text-center">Tạo gói khám</p>}
      className="flex flex-col gap-2"
    >
      <div className="grid grid-flow-row gap-1">
        <div className="grid grid-cols-9 gap-1">
          <div className="font-semibold text-end">
            <span className="text-red-500">(*)</span>Tên gói khám:
          </div>
          <Input className="col-span-5 w-full" size="small" />
          <div className="font-semibold text-end">
            <span className="text-red-500">(*)</span>Mã gói khám:
          </div>
          <Input className="col-span-2 w-full" size="small" />
        </div>
        <div className="grid grid-cols-9 gap-1">
          <div className="font-semibold text-end">Sao chép:</div>
          <Input className="col-span-5 w-full" size="small" />
          <div className="font-semibold text-end">Số tiền:</div>
          <InputNumber
            className="col-span-2 w-full"
            size="small"
            // value={formPay?.tienmat}
            // onChange={(value) => handleOnChangeHinhThucTT('tienmat', value)}
            // onKeyDown={(e) => {
            //   if (e.key === 'p' || e.key === 'P') {
            //     handleOnChangeHinhThucTT('tienmat', tongTien)
            //   }
            // }}
            formatter={(value) => {
              // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
              return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            step={100}
            min={0}
            max={999999999}
          />
        </div>
        <div className="grid grid-cols-9 gap-1">
          <div className="font-semibold text-end">Ghi chú:</div>
          <Input className="col-span-8 w-full" size="small" />
        </div>
      </div>
      <div className="mt-2">
        <ConfigProvider
          theme={{
            token: {
              padding: 3,
              fontSize: 13,
            },
          }}
        >
          <Table
            //   scroll={{ x: 1000 }}
            //   pagination={{
            //     pageSize: 20,
            //     showSizeChanger: false,
            //   }}
            loading={isLoading}
            scroll={{ y: 200 }}
            pagination={false}
            bordered
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 45,
                align: 'center',
                render: (text, record, index) => ++index,
              },
            ]}
            dataSource={[
              1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2,
              3, 4, 5, 6, 7, 8, 9,
            ]}
          />
        </ConfigProvider>
      </div>
      <div className="mt-10">
        <div className="flex justify-center items-center mb-2">
          <Segmented
            options={[
              {
                key: 1,
                value: 1,
                label: 'Khám bệnh',
              },
              {
                key: 2,
                value: 2,
                label: 'Xét nghiệm',
              },
              {
                key: 3,
                value: 3,
                label: 'Chẩn đoán hình ảnh',
              },
            ]}
            value={tabChiDinh}
            onChange={(value) => setTabChiDinh(value)}
            className="w-full"
            block
          />
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 3,
              fontSize: 13,
            },
          }}
        >
          <Table
            //   scroll={{ x: 1000 }}
            //   pagination={{
            //     pageSize: 20,
            //     showSizeChanger: false,
            //   }}
            loading={isLoading}
            scroll={{ y: 200 }}
            pagination={false}
            bordered
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 45,
                align: 'center',
                render: (text, record, index) => ++index,
              },
            ]}
            dataSource={[
              1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2,
              3, 4, 5, 6, 7, 8, 9,
            ]}
          />
        </ConfigProvider>
      </div>
    </Modal>
  )
}

export default ModalAdd
