import React, { useState } from 'react'
import { Tabs } from 'antd'
import XetNghiemGK from './TabFormCLSGK/XetNghiemGK'
import SieuAmGK from './TabFormCLSGK/SieuAmGK'
import NoiSoiGK from './TabFormCLSGK/NoiSoiGK'
import DienTimGK from './TabFormCLSGK/DienTimGK'
import XQuangGK from './TabFormCLSGK/XQuangGK'

const FormCLSGK = () => {
  const [tabForm, setTabForm] = useState(1)
  return (
    <div className="pt-2">
      {/* <ConfigProvider theme={{ token: { padding: '0px', lineHeight: '0px' } }}></ConfigProvider> */}
      <Tabs
        value={tabForm}
        onChange={(value) => setTabForm(value)}
        type="card"
        items={[
          {
            key: 1,
            value: 1,
            label: 'Xét nghiệm',
            children: <XetNghiemGK tabForm={tabForm} />,
          },
          {
            key: 2,
            value: 2,
            label: 'Siêu âm',
            children: <SieuAmGK tabForm={tabForm} />,
          },
          {
            key: 3,
            value: 3,
            label: 'Nội soi',
            children: <NoiSoiGK tabForm={tabForm} />,
          },
          {
            key: 4,
            value: 4,
            label: 'Điện tim',
            children: <DienTimGK tabForm={tabForm} />,
          },
          {
            key: 5,
            value: 5,
            label: 'X-Quang',
            children: <XQuangGK tabForm={tabForm} />,
          },
        ]}
      />
    </div>
  )
}

export default FormCLSGK
