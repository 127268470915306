import { https } from '../apiService'

export const NhapKhoService = {
  getBranch: (id) => https.get(`ChiNhanh/${id}`),
  getListKhoNhap: (id) => https.get(`KhoChiNhanh/${id}`),
  getListDoiTac: () => https.get('DoiTac'),
  getInfoDoiTac: (idDoiTac) => https.get(`DoiTac/IdDoiTac/${idDoiTac}`),
  getInfoThuocVT: (idThuoc) => https.get(`ThuocVatTu/${idThuoc}`),
  postPhieuNhap: (formData) => https.post('KhoNhapXuat/PhieuNhap', formData),
  postkhoChiTiet: (data) => https.post('khoChiTiet', data),
  getKhoChitietByNhapXuat: (id) => https.get(`KhoChiTiet/${id}`),
  getPhieuNhapKho: () => https.get('KhoNhapXuat/phieunhap'),
  deletePhieu: (idPhieu) => https.put(`KhoNhapXuat/Delete/${idPhieu}`),
  getInfoPTNhapKho: (idNhapXuat) => https.get(`KhoNhapXuat/${idNhapXuat}`),
  getPTBycondition: (filter) =>
    https.get('KhoNhapXuat/FindPhieuNhapByCondition', {
      params: {
        fromDate: filter.since,
        toDate: filter.toDate,
        idChiNhanh: filter.idChiNhanh,
        idCongTy: filter.idCongTy,
        keyword: filter.keyword,
      },
    }),
  getThuocVTByKeyword: (keyword, company) =>
    https.get(`ThuocVatTu/Search`, {
      params: {
        keyword,
        idCT: company,
      },
    }),
  putInfoPTNhapKho: (form, idNhapXuat) => https.put(`KhoNhapXuat/${idNhapXuat}`, form),
  getInfoThuocVTByIdNhapXuat: (id) => https.get(`khoChiTiet/${id}`),
  putKhoChiTiet: (form) => https.put(`KhoChiTiet/${form.idChiTietNX}`, form),
  deleteKhoChiTiet: (id) => https.put(`KhoChiTiet/${id}`, { idChiTietNX: id, checkDelete: 1 }),
  updateXacNhanKho: (idNhapXuat, status) =>
    https.put(`KhoNhapXuat/UpdateTrangThai/${idNhapXuat}?trangThai=${status}`),
  uploadFile: (id, form) => https.put(`KhoNhapXuat/UpdateFileHoaDon?id=${id}`, form),
  deleteFile: (form) => https.delete('KhoNhapXuat/DeleteFileHoaDon', { data: form }),
  getDetailKhoNhap: ( keyword,idct, idcn, tuNgay, denNgay) =>
    https.get('KhoChiTiet/GetKhoChiTietByKhoNhap', {
      params: {
        keyword,
        idct,
        idcn,
        tuNgay,
        denNgay,
      },
    }),
    getDetailkhoXuat: ( keyword,idct, idcn, tuNgay, denNgay) =>
      https.get('KhoChiTiet/GetKhoChiTietByKhoXuat', {
        params: {
          keyword,
          idct,
          idcn,
          tuNgay,
          denNgay,
        },
      }),
    getPhieuHangByMaHang: (mahang) => https.get(`KhoChiTiet/GetKhoChiTietByMaPhieu?maphieu=${mahang}`),
    updateStatusPhieu: (id,status) =>https.put(`KhoNhapXuat/UpdateTrangThai/${id}?trangThai=${status}`),
    getHangVPPByMaFromKho: (maHang) => https.get(`VPPDatHangTongHop/GetHangHoaVPPTheoKhoTong?madathang=${maHang}`),
    getHangVPPNhaCC: (maHang,iddoitac) => https.get(`VPPDatHangTongHop/GetHangHoaVPPTheoNhaCungCap?madathang=${maHang}&iddoitac=${iddoitac}`)
}
