import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, Divider, Form, Input, Space, Table, Tabs, Tooltip } from 'antd'
import Highlighter from 'react-highlight-words'
import { https } from '../../../../services/apiService'
import { cardService } from '../../../../services/card/cardService'
import { cloneDeep, debounce } from 'lodash'
import { DeleteOutlined, PlusOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import ToastCus from '../../../../utils/Toast'

function TabHangHoa({ dataHangHoa, setDataHangHoa }) {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [listHangHoa, setListHangHoa] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleAddHangHoa = (infoHangHoa) => {
    if (dataHangHoa?.find((item) => item?.idthuoc === infoHangHoa?.idthuoc)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vật tư hàng hóa này đã được chọn!',
      })
      return
    }
    setDataHangHoa((prev) => [...prev, { ...infoHangHoa }])
  }
  const handleRemoveHangHoa = (infoHangHoa) => {
    setDataHangHoa((prev) => [...prev?.filter((item) => item?.idthuoc !== infoHangHoa?.idthuoc)])
  }
  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const { data } = await https.get(`VoucherHangHoa/GetHangHoaVoucher`, {
          params: { idct: ID_COMPANY },
        })
        setListHangHoa(data)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])
  return (
    <div>
      <div className="overflow-auto h-[265px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            // loading={loadingTable}
            scroll={{ y: 180 }}
            bordered
            pagination={false}
            dataSource={dataHangHoa}
            columns={[
              {
                title: 'Mã hàng',
                dataIndex: 'mathuoc',
                key: 'mathuoc',
                width: 120,
              },
              {
                title: 'Tên vật tư hàng hóa',
                dataIndex: 'tenbietduoc',
                key: 'tenbietduoc',
              },
              // {
              //   title: 'Nhóm hàng',
              //   dataIndex: '',
              //   key: '',
              //   width: 200,
              // },
              {
                title: 'ĐVT',
                dataIndex: 'dvt',
                key: 'dvt',
                align: 'center',
                width: 75,
              },
              {
                title: 'Đơn giá',
                dataIndex: 'giaban',
                key: 'giaban',
                align: 'right',
                width: 100,
                render: (text, record, index) => formatNumberVND(text ?? 0),
              },
              {
                title: '',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: 40,
                render: (text, record, index) => (
                  <>
                    <CloseOutlined
                      className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                      onClick={() => handleRemoveHangHoa(record)}
                    />
                  </>
                ),
              },
            ]}
          />
        </ConfigProvider>
      </div>
      <div>
        <div className="overflow-auto h-[264px]">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              scroll={{ y: 180 }}
              pagination={{
                pageSize: 5,
                showSizeChanger: false,
              }}
              loading={isLoading}
              bordered
              dataSource={listHangHoa}
              columns={[
                {
                  title: 'Mã hàng',
                  dataIndex: 'mathuoc',
                  key: 'mathuoc',
                  width: 120,
                  ...getColumnSearchProps('mathuoc'),
                },
                {
                  title: 'Tên vật tư hàng hóa',
                  dataIndex: 'tenbietduoc',
                  key: 'tenbietduoc',
                  ...getColumnSearchProps('tenbietduoc'),
                },
                // {
                //   title: 'Nhóm hàng',
                //   dataIndex: '',
                //   key: '',
                //   width: 200,
                // },
                {
                  title: 'ĐVT',
                  dataIndex: 'dvt',
                  key: 'dvt',
                  align: 'center',
                  width: 75,
                },
                {
                  title: 'Đơn giá',
                  dataIndex: 'giaban',
                  key: 'giaban',
                  align: 'right',
                  width: 100,
                  render: (text, record, index) => formatNumberVND(text ?? 0),
                },
                {
                  title: '',
                  dataIndex: 'action',
                  key: 'action',
                  align: 'center',
                  width: 40,
                  render: (text, record, index) => (
                    <>
                      <PlusOutlined
                        className="text-lg text-blue-500 cursor-pointer flex justify-center items-center"
                        onClick={() => handleAddHangHoa(record)}
                      />
                    </>
                  ),
                },
              ]}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  )
}

export default TabHangHoa
