import React, { useEffect, useState } from 'react'
import { Drawer, Table, Button, Modal, Tag, Input, List, Avatar, message } from 'antd'
import { https } from '../../../../services/apiService' // Adjust the import path as needed
import moment from 'moment'
import 'moment/locale/vi'
import { SearchOutlined, CheckCircleOutlined } from '@ant-design/icons'
moment.locale('vi')

const DrawerCaTrong = ({ onClose, visible, idCN, loaiNhomND, thangDK }) => {
  const [data, setData] = useState([])
  const [hasData, setHasData] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [employeeData, setEmployeeData] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [checkboxStates, setCheckboxStates] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [currentIdCLV, setCurrentIdCLV] = useState(null)
  const [currentDay, setCurrentDay] = useState(null) // Add this state to store the current day

  const fetchData = async () => {
    try {
      const response = await https.get(
        `/CaLamViec/GetDanhSachCaLamViecTrong?idCN=${idCN}&loaiNhomND=${loaiNhomND}&thangDK=${thangDK}`,
      )

      const formattedData = response.data.map((item, index) => {
        // Kiểm tra từng ca (Sáng, Chiều, Tối)
        const caSang = item.caLamViec.find((ca) => ca.maCa.includes('-S')) ? 'Còn trống' : ''
        const caChieu = item.caLamViec.find((ca) => ca.maCa.includes('-C')) ? 'Còn trống' : ''
        const caToi = item.caLamViec.find((ca) => ca.maCa.includes('-T')) ? 'Còn trống' : ''
        const idCLV = item.caLamViec.find((ca) => ca.idCalamViecCC !== null)
          ? item.caLamViec.find((ca) => ca.idCalamViecCC !== null).idCalamViecCC
          : null

        return {
          key: index.toString(),
          ngay: item.ngayDK,
          caSang,
          caChieu,
          caToi,
          idCLV,
        }
      })

      setData(formattedData)
      setHasData(formattedData.length > 0)
      console.log('Formatted Data:', formattedData) // Debugging log
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const showModal = async (idCLV, pageNumber = 1, day = null) => {
    try {
      const params = {
        keyword: searchKeyword,
        idCN: idCN,
        ngayDK: day,
        idCLV: idCLV,
        pageNumber: pageNumber,
      }

      const response = await https.get('/CaLamViec/GetDanhSachNhanVienByIdCLV', { params })
      setEmployeeData((prevData) => [...prevData, ...response.data.data])
      setIsModalVisible(true)
      setCurrentPage(pageNumber)
      setCurrentIdCLV(idCLV)
      if (day) setCurrentDay(day) // Update the currentDay state
    } catch (error) {
      console.error('Error fetching employee data:', error)
    }
  }

  const handleSearch = () => {
    setEmployeeData([]) // Clear previous data
    showModal(currentIdCLV, 1, currentDay)
  }

  const handleOk = async (newEmployees) => {
    const updatedEmployeeData = newEmployees.map((employee) => ({
      idNV: employee.idNV,
      tenNV: employee.tenNV,
      iddkclvct: employee.iddkclvct, //ok
      idCN: checkboxStates[employee.idNV] ? idCN : employee.idCN, // Truyền idCN khi checkbox được chọn
      idtt: employee.idtt, //ok
    }))
    try {
      const response = await https.put(
        '/CaLamViec/UpdateDanhSachNhanVienByIdCLV',
        updatedEmployeeData,
      )
      if (response.status === 200) {
        setIsModalVisible(false)
        message.success('Cập nhật thành công')
        setEmployeeData([])
        setCurrentPage(1)
        setSearchKeyword('')
        setCheckboxStates({})
        fetchData() // Gọi lại hàm fetchData để cập nhật dữ liệu
      } else {
        message.error('Cập nhật thất bại')
      }
    } catch (error) {
      console.error('Error saving employee data:', error)
      message.error('Đã xảy ra lỗi khi cập nhật dữ liệu')
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setSearchKeyword('')
    setCheckboxStates({})
    setCurrentPage(1)
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Ngày',
      dataIndex: 'ngay',
      key: 'ngay',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Ca Sáng',
      dataIndex: 'caSang',
      key: 'caSang',
      render: (caSang, record) =>
        caSang === 'Còn trống' ? (
          <Button onClick={() => showModal(4, 1, moment(record.ngay).format('YYYY-MM-DD'))}>
            Còn trống
          </Button>
        ) : (
          caSang
        ),
    },
    {
      title: 'Ca Chiều',
      dataIndex: 'caChieu',
      key: 'caChieu',
      render: (caChieu, record) =>
        caChieu === 'Còn trống' ? (
          <Button onClick={() => showModal(5, 1, moment(record.ngay).format('YYYY-MM-DD'))}>
            Còn trống
          </Button>
        ) : (
          caChieu
        ),
    },
    {
      title: 'Ca Tối',
      dataIndex: 'caToi',
      key: 'caToi',
      render: (caToi, record) =>
        caToi === 'Còn trống' ? (
          <Button onClick={() => showModal(6, 1, moment(record.ngay).format('YYYY-MM-DD'))}>
            Còn trống
          </Button>
        ) : (
          caToi
        ),
    },
  ]

  useEffect(() => {
    fetchData()
  }, [idCN, loaiNhomND, thangDK])

  console.log('employeeData:', employeeData)
  return (
    <Drawer
      title="Ca còn trống trong tháng"
      width="50%"
      placement="right"
      size="large"
      onClose={onClose}
      visible={visible}
    >
      <Table columns={columns} dataSource={data} pagination={false} />
      <Modal
        title="Danh sách nhân viên"
        visible={isModalVisible}
        onOk={() => handleOk(employeeData)}
        onCancel={handleCancel}
        okText="Xác nhận"
        cancelText="Hủy"
        style={{ width: '80% !important' }}
      >
        <div className="flex flex-col ">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold mb-4">Danh sách nhân viên</h2>
            <div className="flex items-center">
              <span className="mr-2">Chú ý: </span>
              <Tag color="geekblue"> Đã đăng kí ở chi nhánh</Tag>
            </div>
          </div>
          <div className="flex mb-4">
            <Input
              placeholder="Tìm kiếm nhân viên theo tên hoặc mã nhân viên"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              onPressEnter={handleSearch}
              style={{ marginRight: 8 }}
            />
            <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>
              Tìm kiếm
            </Button>
          </div>
          <div
            className="overflow-auto"
            style={{ maxHeight: '400px' }}
            onScroll={(e) => {
              const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
              if (scrollTop + clientHeight >= scrollHeight) {
                showModal(currentIdCLV, currentPage + 1, currentDay)
              }
            }}
          >
            <List
              itemLayout="horizontal"
              dataSource={employeeData}
              renderItem={(employee) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar style={{ backgroundColor: '#87d068' }}>
                        {employee.tenNV.charAt(0)}
                      </Avatar>
                    }
                    title={
                      <label
                        htmlFor={`employee-${employee.idNV}`}
                        style={{
                          fontWeight: 'bold',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ marginRight: '8px' }}>{employee.tenNV}</span>
                        <span style={{ color: '#999' }}>{employee.maNV}</span>
                      </label>
                    }
                    description={
                      employee.idtt === 4 ? (
                        <>
                          <Tag color="blue" icon={<CheckCircleOutlined />}>
                            Đã chốt
                          </Tag>
                          <Tag color="geekblue">{employee.tenCN}</Tag>
                        </>
                      ) : (
                        <>{employee.tenCN && <Tag color="geekblue">{employee.tenCN}</Tag>}</>
                      )
                    }
                  />
                  <input
                    type="checkbox"
                    id={`employee-${employee.idNV}`}
                    className="mr-2"
                    checked={checkboxStates[employee.idNV] ?? employee.idCN === idCN}
                    onChange={(e) => {
                      setCheckboxStates((prev) => ({
                        ...prev,
                        [employee.idNV]: e.target.checked,
                      }))
                    }}
                    disabled={employee.idtt === 4}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </Modal>
    </Drawer>
  )
}

export default DrawerCaTrong
