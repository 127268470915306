import * as typeAction from '../constants/constants'

export const getAllDichVu = () => ({
  type: typeAction.GET_ALL_LIST_DICH_VU,
})

export const ListDichVuAction = (idNhomDv, idct) => ({
  type: typeAction.GET_LIST_DICH_VU,
  idNhomDv,
  idct,
})
export const listGroupsDichVuAction = () => ({
  type: typeAction.GET_LIST_GROUPS_DICH_VU,
})
export const getInfoDichVuAction = (iddv) => ({
  type: typeAction.GET_INFO_DICH_VU,
  iddv,
})
export const addNewDichVuAction = (form, listCdGK, resetForm) => ({
  type: typeAction.POST_NEW_DICH_VU,
  form,
  listCdGK,
  resetForm,
})
export const deleteDichVuAction = (iddv, onLoad) => ({
  type: typeAction.DELETE_DICH_VU,
  iddv,
  onLoad,
})
export const updateDichVuAction = (form, listCdGK, resetForm) => ({
  type: typeAction.UPDATE_DICH_VU,
  form,
  listCdGK,
  resetForm,
})

export const searchDanhMucDVAction = (idnhomdv, idct, keyword) => ({
  type: typeAction.SEARCH_DV_BY_KEYWORD,
  idnhomdv,
  idct,
  keyword,
})

export const putListChiTietCuaGoiKhamAction = (infoGK, listCdGK, resetForm) => ({
  type: typeAction.PUT_LIST_CHIDINH_GOIKHAM,
  infoGK,
  listCdGK,
  resetForm,
})
export const putSuDungListDichVuChuyenKhoaAction = (
  idck,
  sudung,
  listIdDv,
  getListDichVuChuyenKhoa,
) => ({
  type: typeAction.PUT_SUDUNG_LIST_DICHVU_CHUYENKHOA,
  idck,
  sudung,
  listIdDv,
  getListDichVuChuyenKhoa,
})
