import React, { useEffect, lazy, Suspense, useState } from 'react'
import FormLogin from './FormLogin/FormLogin'
import style from './login.module.css'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import ButtonLang from '../../components/common/ButtonLang/ButtonLang'
import LoadingPage from '../../components/layout/Loading/LoadingPage'
import { useDispatch, useSelector } from 'react-redux'
import { listBranchAction } from '../../store/actions/BranchAction'
import { listAllCompanyAction } from '../../store/actions/companyAction'
import bgivy from '../../assets/images/logo/Imge-Dakhoa.jpg'
import logoIvy from '../../assets/images/logo/logo_ivy.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const Login = () => {
  const { t } = useTranslation('translation')
  const { loadingLogin } = useSelector((state) => state.modalReducer)
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const dispatch = useDispatch()

  useEffect(() => {
    if (ID_COMPANY) {
      dispatch(listBranchAction(ID_COMPANY))
    }
  }, [ID_COMPANY])
  useEffect(() => {
    dispatch(listAllCompanyAction())
  }, [])
  return (
    <>
      <div className="bg-gradient-to-r flex justify-center items-center from-[#48CFCB] to-[#229799] h-screen">
        <div className="w-3/4 h-3/4 rounded-xl bg-white flex  overflow-hidden shadow-2xl shadow-cyan-600">
          <div className="w-1/2">
            <div className="py-10 px-36">
              <img className="w-56 -translate-x-3" src={logoIvy} alt="" />
              <h2 className="text-[#23989C] text-2xl">
                Welcome to <br />
                <span className=" text-4xl text-[#23989C] font-semibold ">Đa khoa Ivy Health</span>
              </h2>
              <div className='mt-10'>
                <FormLogin />
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <img src={bgivy} alt="bg-dakhoa-ivy" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
      {loadingLogin && <LoadingPage />}
    </>
  )
}

export default Login
