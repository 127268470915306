import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Tabs,
  Button,
  Input,
  InputNumber,
  Checkbox,
  DatePicker,
  Select,
  Divider,
  Popover,
} from 'antd'
import { SearchOutlined, DownOutlined, UpOutlined, PrinterOutlined } from '@ant-design/icons'
// import { calculateAge } from '../../../../utils/calculateAge'
import * as typeAction from '../../../../../store/constants/constants'
import { childrenClinicService } from '../../../../../services/childrenClinic/childrenClinicService'
import { putInfoChanDoanAction } from '../../../../../store/actions/khamNoiKhoaAction'
import { generatePhieuToaThuoc } from '../../../../../utils/report/phieuToaThuoc'
import { generatePhieuKhamBenh } from '../../../../../utils/report/phieuKhamBenh'
import ToastCus from '../../../../../utils/Toast'
import ToaThuoc from '../../../KhamSucKhoe/TabKham/FormCD/ToaThuoc/ToaThuoc'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import 'dayjs/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD/MM/YYYY'
const momentDate = 'YYYY-MM-DD'
const dvtKhongNhanSL = ['lọ', 'chai', 'hủ', 'tuýp', 'tube']
const FormCD = ({ formBenhNhan, formSinhHieu, tabKham, reloadListBN }) => {
  const dispatch = useDispatch()
  const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_CONGTY_LOGIN = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoBN, infoBNTTC, infoToa } = useSelector((state) => state.khamSucKhoeReducer)
  const [soNgayTK, setSoNgayTK] = useState(0)
  const [ngayHenTK, setNgayHenTK] = useState(now.format(momentDate))
  const [checkKhongKeToa, setCheckKhongKeToa] = useState(true)
  const [listLyDoBsKhongKeToa, setListLyDoBsKhongKeToa] = useState([])
  const [listChanDoan, setListChanDoan] = useState([])
  const [listLoiDan, setListLoiDan] = useState([])
  const [listThuocBN, setListThuocBN] = useState([])
  const [listThuocTon, setListThuocTon] = useState([])
  const [isLoadingLyDo, setIsLoadingLyDo] = useState(false)
  const [isLoadingThuocTon, setIsLoadingThuocTon] = useState(false)
  const [isMoRong, setisMoRong] = useState(false)
  const [ngayThuoc, setNgayThuoc] = useState(2)
  const isBsDuocKeToa =
    !infoBNTTC ||
    (infoBNTTC &&
      (infoBNTTC?.trangthaikhambenh === 1 ||
        (infoBNTTC?.trangthaikhambenh === 2 && !infoToa?.DANHANTOA)))
      ? true
      : false
  const lyDoDefault = listLyDoBsKhongKeToa?.find((item) => item?.lydo === 'Khỏe')
  const initialState = {
    idbnttc: infoBNTTC?.idbnttc,
    lydokham: infoBNTTC?.lydokham ?? '',
    trieuchung: infoBNTTC?.trieuchung ?? '',
    idchandoan: infoBNTTC?.idchandoan,
    chandoan: infoBNTTC?.chandoan ?? '',
    idloidan: infoBNTTC?.idloidan,
    loidan: infoBNTTC?.loidan ?? '',
    lydokolaythuoc: infoBNTTC?.lydokolaythuoc,
    noidungkolaythuoc: infoBNTTC?.noidungkolaythuoc ?? '',
    ghichu: infoBNTTC?.ghichu,

    benhsu: infoBNTTC?.benhsu ?? '',
    benhkemtheo: infoBNTTC?.benhkemtheo,
    idicD10BENHKEMTHEO: infoBNTTC?.idicD10BENHKEMTHEO,
    chandoanbandau: infoBNTTC?.chandoanbandau,
    idicD10BANDAU: infoBNTTC?.idicD10BANDAU,
    chandoanravien: infoBNTTC?.chandoanravien ?? '',
    idicD10RAVIEN: infoBNTTC?.idicD10RAVIEN,

    quatrinhbenhly: infoBNTTC?.quatrinhbenhly ?? '',
    tieusugiadinh: infoBNTTC?.tieusugiadinh ?? '',
    toanthan: infoBNTTC?.toanthan ?? '',
    cacbophan: infoBNTTC?.cacbophan ?? '',
    daxuly: infoBNTTC?.daxuly ?? '',
    dieutringoaitru: infoBNTTC?.dieutringoaitru ?? '',
    dienbienlamsang: infoBNTTC?.dienbienlamsang ?? '',
    ppdieutri: infoBNTTC?.ppdieutri ?? '',
    ttlucravien: infoBNTTC?.ttlucravien ?? '',
    huongxuly: infoBNTTC?.huongxuly ?? '',

    // ngaytaikham: infoBNTTC?.ngaytaikham,
    // trangthaikhambenh: 2,
    // bskham: infoBNTTC?.bskham,
    // tiensubanthan: infoBNTTC?.tiensubanthan,
    // diung: infoBNTTC?.diung,
  }
  const [form, setForm] = useState(initialState)
  const maLoiDan =
    infoBNTTC && form?.idloidan
      ? `${listLoiDan?.find((item) => item?.idld === form?.idloidan)?.maLoiDan?.split(' ')[0] ?? ''} ...`
      : null

  const handleSubmit = (isPrint) => {
    if (!isBsDuocKeToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    if (isPrint && !checkKhongKeToa && !listThuocBN?.length) {
      setCheckKhongKeToa(true)
      setForm((prev) => ({ ...prev, lydokolaythuoc: null }))
      handleChangeSoNgayTK(0)
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại lý do không kê toa!',
      })
      return
    }
    if (
      !ngayHenTK ||
      soNgayTK < 0 ||
      !form?.lydokham ||
      !form?.benhsu ||
      !form?.trieuchung ||
      !form?.loidan ||
      (checkKhongKeToa && !form?.lydokolaythuoc)
    ) {
      // if (checkKhongKeToa)
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại thông tin!',
      })
      return
    }
    if (
      !checkKhongKeToa &&
      listThuocBN?.find(
        (item) =>
          !item?.soluong ||
          !item?.ton ||
          item?.soluong <= 0 ||
          item?.ton <= 0 ||
          item?.soluong > item?.ton,
      )
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng!',
      })
      return
    }
    dispatch(
      putInfoChanDoanAction(
        {
          ...form,
          ngaytaikham: ngayHenTK,
          editbenhnhan: formBenhNhan,
          editsinhhieu: formSinhHieu,
        },
        checkKhongKeToa ? [] : listThuocBN, // không kê toa thì truyền thuốc rỗng để gọi api cập nhật lại thành rỗng
        isPrint,
        resetForm,
      ),
    )
  }
  const resetForm = () => {
    setForm(initialState)
    setSoNgayTK(0)
    setNgayHenTK(now.format(momentDate))
    setCheckKhongKeToa(false)
    setNgayThuoc(2)
    setListThuocBN([])
    reloadListBN()
    getListThuocTonChoBsKe()
  }
  const handlePrint = () => {
    //có thuốc in lại toa
    const infoBn = {
      ...infoBN,
      diaChi: infoBNTTC?.benhNhan?.diaChi,
      tenPhuongXa: infoBNTTC?.benhNhan?.tenPhuongXa,
      tenQuanHuyen: infoBNTTC?.benhNhan?.tenQuanHuyen,
      tenTinhTP: infoBNTTC?.benhNhan?.tenTinhTP,
    }
    let listVT = []
    // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
    for (const item of infoToa?.DanhSachThuoc) {
      if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
        listVT?.map(
          (itemVT) =>
            itemVT?.idthuoc === item?.idthuoc &&
            (itemVT.soLuong += item.soluong) &&
            (itemVT.soluong += item.soluong),
        )
      } else {
        listVT.push({
          ...item,
          soLuong: item?.soluong,
          tenhoatchat: item?.tenhoatchat,
          duongDung: item?.duongdung,
          soLanNgay: item?.solanngay,
          lieuDung: item?.lieudung,
          ghiChu: item?.ghichu,
        })
      }
    }
    generatePhieuToaThuoc(infoBn, infoBNTTC, listVT, PkDangNhap)
  }
  const tinhLaiSLThuoc = (dvt, ngaythuoc, lieudung, solanngay, soluong, ton) => {
    const formatLieuDung = (lieudung) => {
      let text = ('' + lieudung)?.replace(/[^\d,.\/]/g, '')?.replaceAll(',', '.') //?.replaceAll(' ', '')
      if (text?.includes('/')) {
        const arr = text?.split('/')
        if (arr?.length === 2 && arr[1]?.length) return Number(arr[0]) / Number(arr[1])
      }
      return _.isNumber(+text) && !_.isNaN(+text) ? +text : 0
    }
    if (!dvt || !dvtKhongNhanSL?.includes(dvt?.replaceAll(' ', '')?.toLowerCase())) {
      const slnew = Math.ceil(ngaythuoc * formatLieuDung(lieudung) * solanngay)
      return slnew <= ton ? slnew : ton
    }
    return soluong
  }

  const tinhLaiSLListThuocBN = (ngaythuoc) => {
    setListThuocBN((prev) =>
      prev?.map((item) => ({
        ...item,
        soluong: tinhLaiSLThuoc(
          item?.dvt,
          ngaythuoc,
          item?.lieudung,
          item?.solanngay,
          item?.soluong,
          item?.ton,
        ),
      })),
    )
  }
  const demSoNgay = (dateString) => {
    const today = moment().format(momentDate)
    // const ngaykham = moment(infoBNTTC?.ngaykham, momentDate).format(momentDate)
    const ngaytk = moment(dateString, dateFormat).format(momentDate)
    const startDate = moment(today)
    const startDateNgayKham = moment(infoBNTTC?.ngaykham, momentDate)
    const endDate = moment(ngaytk)
    return infoBNTTC
      ? infoBNTTC?.trangthaikhambenh === 1
        ? endDate.diff(startDate, 'days')
        : endDate.diff(startDateNgayKham, 'days')
      : 0
  }
  const handleChangeSoNgayTK = (value) => {
    setSoNgayTK(value)
    setNgayThuoc(value)
    tinhLaiSLListThuocBN(value)
    setNgayHenTK(moment()?.add(value, 'days')?.format('YYYY-MM-DD'))
  }
  const handleChangeNgayHenTK = (date, dateString) => {
    setSoNgayTK(demSoNgay(dateString))
    setNgayThuoc(demSoNgay(dateString))
    tinhLaiSLListThuocBN(demSoNgay(dateString))
    setNgayHenTK(moment(dateString, dateFormat).format(momentDate))
  }
  const handleChangeKhongKeToa = (e) => {
    if (!isBsDuocKeToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    const { checked } = e.target
    setCheckKhongKeToa(checked)
    if (checked) {
      const today = moment().format(dateFormat)
      setSoNgayTK(demSoNgay(today))
      setNgayThuoc(demSoNgay(today))
      tinhLaiSLListThuocBN(demSoNgay(today))
      setNgayHenTK(moment(today, dateFormat).format(momentDate))
      setForm((prev) => ({ ...prev, lydokolaythuoc: null }))
    }
  }
  const getListLyDoBsKhongKeToa = async () => {
    try {
      setIsLoadingLyDo(true)
      const { data } = await childrenClinicService.getListLyDoKhongKeToa()
      setListLyDoBsKhongKeToa(data)
    } catch (error) {
      console.log('getListLyDoBsKhongKeToa : ', error)
    } finally {
      setIsLoadingLyDo(false)
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      getListChanDoan(keyword)
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const getListChanDoan = async (keyword) => {
    try {
      const { data } = await childrenClinicService.getChanDoan(keyword)
      setListChanDoan(data)
    } catch (error) {
      console.log('getListChanDoan : ', error)
    }
  }
  const getListLoiDan = async () => {
    try {
      const { data } = await childrenClinicService.getLoiDan()
      setListLoiDan(data)
    } catch (error) {
      console.log('getListLoiDan : ', error)
    }
  }
  const getListThuocTonChoBsKe = async () => {
    try {
      setIsLoadingThuocTon(true)
      const { data } = await childrenClinicService.getKetoaThuoc(ID_CHINHANH_LOGIN)
      let listVT = []
      await data
        ?.filter((item) => item?.tonKho > 0)
        ?.map((item, index) => {
          if (listVT?.find((itemVT) => itemVT?.idThuoc === item?.idThuoc)) {
            listVT?.map(
              (itemVT) => itemVT?.idThuoc === item?.idThuoc && (itemVT.tonKho += item.tonKho),
            )
          } else {
            listVT.push(item)
          }
        })
      // console.log(data)
      setListThuocTon(listVT)
    } catch (error) {
      console.log('getListThuocTonChoBsKe : ', error)
    } finally {
      setIsLoadingThuocTon(false)
    }
  }
  useEffect(() => {
    getListChanDoan('')
    getListLoiDan()
    getListLyDoBsKhongKeToa()
    getListThuocTonChoBsKe()
  }, [])
  useEffect(() => {
    setForm(initialState)
    const dateString = infoBNTTC?.ngaytaikham
      ? moment(infoBNTTC?.ngaytaikham, momentDate).format(dateFormat)
      : moment().add(2, 'days').format(dateFormat) // MẶC ĐỊNH TÁI KHÁM
    setSoNgayTK(demSoNgay(dateString))
    setNgayThuoc(2)
    setNgayHenTK(moment(dateString, dateFormat).format(momentDate))
    if (infoBNTTC?.idbnttc) {
      getListThuocTonChoBsKe()
    }
  }, [infoBNTTC, tabKham])
  useEffect(() => {
    if (infoToa?.DanhSachThuoc?.length) {
      let listVT = []
      infoToa?.DanhSachThuoc?.map((item) => {
        const infoThuoc = listThuocTon?.find((itemTon) => itemTon?.idThuoc === item?.idthuoc)
        if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
          listVT?.map(
            (itemVT) =>
              itemVT?.idthuoc === item?.idthuoc &&
              (itemVT.soluong += item?.soluong ?? 0) &&
              (itemVT.ton += isBsDuocKeToa ? item?.soluong + (infoThuoc?.tonKho ?? 0) : 0),
          )
        } else {
          listVT.push({
            idthuoc: item?.idthuoc,
            tenbietduoc: item?.tenbietduoc,
            tenhoatchat: item?.tenhoatchat,
            dvt: item?.dvt,
            solanngay: item?.solanngay,
            lieudung: item?.lieudung,
            donvidung: item?.donvidung,
            duongdung: item?.duongdung,
            soluong: item?.soluong,
            ton: isBsDuocKeToa // || infoBNTTC?.trangthaikhambenh === 1
              ? item?.soluong + (infoThuoc?.tonKho ?? 0)
              : infoThuoc?.tonKho ?? 0,
            hamluong: item?.hamluong,
            ghichu: item?.ghichu,
            idkhocn: item?.idkhocn,
            giaban: item?.dongia,
            // solo: item?.solo,
            // handung: item?.handung,
            // sohoadon: item?.sohoadon,
            // masodangky: item?.masodangky,
            info: {
              thuocVatTu: {
                nongDo: item?.nongdo,
                quyCach: item?.quycach,
                idNhom: item?.idnhomthuoc,
                maThuoc: item?.mathuoc,
              },
            },
            // bskedon: infoUser?.tenNV,
            // dvt: ThuocVT[index].thuocVatTu.dvt,
            // ngayKeDon: "2024-05-23T16:39:02.286Z",
            // lieuDungTinh: 1,
            // idbnttc: infoBNTTC?.idbnttc,
          })
        }
        setCheckKhongKeToa(false)
        setListThuocBN(listVT)
      })
    } else {
      setListThuocBN([])
      setCheckKhongKeToa(infoBNTTC?.lydokolaythuoc ? true : false)
    }
  }, [infoToa, infoToa?.DanhSachThuoc, infoToa?.DanhSachThuoc?.length])

  const fetchMissingOption = async (idIcd) => {
    try {
      const { data } = await childrenClinicService.getChanDoanByidICD(idIcd)
      const newOption = data
      //   console.log(ChanDoan, newOption)
      setListChanDoan((prev) => [...prev, newOption])
    } catch (error) {
      console.log('fetchMissingOption : ', error)
    }
  }
  useEffect(() => {
    if (form?.idchandoan && !listChanDoan?.find((item) => item?.idicD10 === form?.idchandoan)) {
      fetchMissingOption(form?.idchandoan)
    }
  }, [form?.idchandoan])
  const divInputFormCD = (label, field, require) => {
    return (
      <div className="grid grid-cols-7 items-center gap-1">
        <div className="font-semibold text-end">
          {require && <span className="text-red-500">(*)&nbsp;</span>}
          {label}:
        </div>
        <div className="col-span-6">
          <Input
            disabled={!isBsDuocKeToa}
            size="small"
            className="w-full"
            style={{ color: 'black' }}
            status={require && infoBNTTC && !form[field] ? 'error' : ''}
            value={form[field]}
            onChange={(e) => setForm((prev) => ({ ...prev, [field]: e.target.value }))}
          />
        </div>
      </div>
    )
  }
  const divSelectICD10 = (label, field, fieldIDC, require) => {
    const onClickChanDoan = (value, options, key, keyIDC) => {
      const newValue = options?.mabenh + '-' + options?.icD10TV + '/'
      setForm((prev) => ({ ...prev, [key]: newValue, [keyIDC]: value }))
    }
    return (
      <div className="grid grid-cols-7 items-center gap-1">
        <div className="font-semibold text-end">
          {require && <span className="text-red-500">(*)&nbsp;</span>}
          {label}:
        </div>
        <div className="col-span-6">
          <div className="flex justify-start items-center gap-3">
            <Select
              disabled={!isBsDuocKeToa}
              placeholder="ICD-10"
              size="small"
              className="w-28"
              style={{ color: 'black' }}
              showSearch
              dropdownStyle={{ width: 550 }}
              filterOption={false}
              onChange={(value, options) => onClickChanDoan(value, options, field, fieldIDC)}
              onSearch={debounceDropDown}
              suffixIcon={<SearchOutlined />}
              notFoundContent={null}
              value={form[fieldIDC]}
              options={listChanDoan?.map((item) => ({
                label: item?.mabenh,
                value: item?.idicD10,
                mabenh: item?.mabenh,
                icD10TV: item?.icD10TV,
                ...item,
              }))}
              optionRender={(options) => (
                <ul className="flex ">
                  <li className="w-10 mr-2">{options?.data?.mabenh}</li>
                  <li className="w-full border-l px-2 truncate">{options?.data?.icD10TV}</li>
                </ul>
              )}
            />
            <Input
              disabled={!isBsDuocKeToa}
              size="small"
              className="w-full"
              style={{ color: 'black' }}
              status={require && infoBNTTC && !form[field] ? 'error' : ''}
              value={form[field]}
              onChange={(e) => setForm((prev) => ({ ...prev, [field]: e.target.value }))}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="mt-1">
      <div className="flex justify-end items-center mb-2">
        <div className="flex justify-start items-center gap-5 mr-5">
          <div className="flex justify-start items-center gap-1">
            <div className="font-semibold">Số ngày TK:</div>
            <InputNumber
              disabled={checkKhongKeToa || !isBsDuocKeToa}
              size="small"
              className="w-12"
              style={{ color: 'black' }}
              min={0}
              step={1}
              status={infoBNTTC && soNgayTK < 0 ? 'error' : ''}
              value={soNgayTK}
              onChange={(value) => handleChangeSoNgayTK(value > 0 ? value : 0)}
            />
          </div>
          <div className="flex justify-start items-center gap-1">
            <div className="font-semibold">Hẹn tái khám:</div>
            <DatePicker
              disabled={checkKhongKeToa || !isBsDuocKeToa}
              placeholder=" "
              size="small"
              className="w-28"
              style={{ color: 'black' }}
              allowClear={false}
              status={infoBNTTC && (!ngayHenTK || soNgayTK < 0) ? 'error' : ''}
              value={ngayHenTK ? dayjs(ngayHenTK) : null}
              minDate={dayjs(now)}
              onChange={handleChangeNgayHenTK}
              format={dateFormat}
              locale={{ ...locale, week: { start: 1 } }}
            />
          </div>
          <div className="flex justify-start items-center gap-1">
            <Checkbox checked={checkKhongKeToa} onChange={handleChangeKhongKeToa}>
              Không kê toa
            </Checkbox>
          </div>
        </div>
        <div className="flex justify-center items-center gap-2">
          {infoBNTTC?.trangthaikhambenh === 1 ? (
            <Button
              type="primary"
              disabled={!infoBN || !infoBNTTC}
              onClick={() => handleSubmit(false)}
            >
              Cập nhật
            </Button>
          ) : (
            <Popover
              content={() => {
                return (
                  <div className="w-32">
                    <Button
                      type="primary"
                      disabled={!infoBN || !infoBNTTC || !infoToa?.DanhSachThuoc?.length}
                      onClick={handlePrint}
                      style={{ marginBottom: '8px', width: '100%' }}
                    >
                      Toa thuốc
                    </Button>
                    <Button
                      type="primary"
                      disabled={!infoBN || !infoBNTTC}
                      onClick={() => generatePhieuKhamBenh(infoBNTTC, PkDangNhap)}
                      style={{ width: '100%' }}
                    >
                      Phiếu khám bệnh
                    </Button>
                  </div>
                )
              }}
              trigger="click"
              placement="bottom"
            >
              <Button type="primary" disabled={!infoBN || !infoBNTTC}>
                <PrinterOutlined /> In
              </Button>
            </Popover>
          )}
          <Button
            type="primary"
            disabled={!infoBN || !infoBNTTC || infoBNTTC?.trangthaikhambenh === 4}
            onClick={() => handleSubmit(true)}
          >
            Lưu & in
          </Button>
        </div>
      </div>
      <div className="grid grid-flow-row items-center overflow-auto gap-1">
        {checkKhongKeToa && (
          <div className="grid grid-cols-7 items-center gap-1">
            <div className="font-semibold text-end">
              <span className="text-red-500">(*)&nbsp;</span>
              Lý do không kê toa:
            </div>
            <div className="col-span-6">
              <Select
                disabled={isLoadingLyDo || !isBsDuocKeToa}
                size="small"
                className="w-full"
                style={{ color: 'black' }}
                status={infoBNTTC && !form?.lydokolaythuoc ? 'error' : ''}
                value={
                  isLoadingLyDo
                    ? 'Đang lấy dữ liệu...'
                    : infoBNTTC && form?.lydokolaythuoc
                      ? form?.lydokolaythuoc
                      : null
                }
                onChange={(value, opt) =>
                  setForm((prev) => ({
                    ...prev,
                    lydokolaythuoc: value,
                    noidungkolaythuoc: opt?.label,
                  }))
                }
                options={listLyDoBsKhongKeToa?.map((item) => ({
                  key: item?.idld,
                  value: item?.idld,
                  label: item?.lydo,
                }))}
              />
            </div>
          </div>
        )}
        {divInputFormCD('Lý do khám', 'lydokham', true)}
        {divInputFormCD('Bệnh sử', 'benhsu', true)}
        {divInputFormCD('Triệu chứng', 'trieuchung', true)}
        {divSelectICD10('Chẩn đoán', 'chandoan', 'idchandoan', false)}
        {divSelectICD10('Chẩn đoán bệnh phụ', 'benhkemtheo', 'idicD10BENHKEMTHEO', false)}
        {divSelectICD10('Chẩn đoán ban đầu', 'chandoanbandau', 'idicD10BANDAU', false)}
        <div className="grid grid-cols-7 items-center gap-1">
          <div className="font-semibold text-end">
            <span className="text-red-500">(*)&nbsp;</span>
            Lời dặn:
          </div>
          <div className="col-span-6">
            <div className="flex justify-start items-center gap-3">
              <Select
                disabled={!isBsDuocKeToa}
                placeholder="Gợi ý"
                size="small"
                className="w-28"
                style={{ color: 'black' }}
                dropdownStyle={{ width: 750 }}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                  (option?.mald ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                optionRender={(options) => (
                  <h2 className="text-pretty border-b">
                    {options?.data?.mald} - {options?.data?.label}
                  </h2>
                )}
                onChange={(value, opt) =>
                  setForm((prev) => ({ ...prev, idloidan: value, loidan: opt?.label }))
                }
                value={maLoiDan}
                suffixIcon={<SearchOutlined />}
                options={listLoiDan?.map((item) => ({
                  mald: item?.maLoiDan,
                  label: item?.loiDan,
                  value: item?.idld,
                  key: item?.idld,
                }))}
                // status={formik.errors.idloidan ? 'error' : ''}
                // status={infoBNTTC && !form?.idloidan ? 'error' : ''}
                // value={null}
                // name="loidan"
              />
              <Input
                disabled={!isBsDuocKeToa}
                size="small"
                className="w-full"
                style={{ color: 'black' }}
                status={infoBNTTC && !form?.loidan ? 'error' : ''}
                value={form?.loidan}
                onChange={(e) => setForm((prev) => ({ ...prev, loidan: e.target.value }))}
              />
            </div>
          </div>
        </div>
        {divInputFormCD('Ghi chú', 'ghichu', false)}

        {isMoRong && (
          <>
            {divInputFormCD('Quá trình bệnh lý', 'quatrinhbenhly', false)}
            {divInputFormCD('Tiền sử gia đình', 'tieusugiadinh', false)}
            {divInputFormCD('Toàn thân', 'toanthan', false)}
            {divInputFormCD('Các bộ phận', 'cacbophan', false)}
            {divInputFormCD('Đã xử lý', 'daxuly', false)}
            {divSelectICD10('Chẩn đoán ra viện', 'chandoanravien', 'idicD10RAVIEN', false)}
            {divInputFormCD('Điều trị ngoại trú', 'dieutringoaitru', false)}
            {divInputFormCD('Diễn biến lâm sàng', 'dienbienlamsang', false)}
            {divInputFormCD('Phương pháp điều trị', 'ppdieutri', false)}
            {divInputFormCD('Tình trạng lúc ra viện', 'ttlucravien', false)}
            {divInputFormCD('Hướng xử lý', 'huongxuly', false)}
          </>
        )}

        <div className="flex justify-center items-center mt-1">
          {isMoRong ? (
            <div
              className="text-blue-500 cursor-pointer flex justify-center items-center gap-1"
              onClick={() => setisMoRong(false)}
            >
              <span>Thu gọn</span>
              <UpOutlined className="animate-bounce text-lg" />
            </div>
          ) : (
            <div
              className="text-blue-500 cursor-pointer flex justify-center items-center gap-1"
              onClick={() => setisMoRong(true)}
            >
              <span>Mở rộng</span>
              <DownOutlined className="animate-bounce text-lg" />
            </div>
          )}
        </div>

        {!checkKhongKeToa && (
          <>
            <ToaThuoc
              listThuocBN={listThuocBN}
              setListThuocBN={setListThuocBN}
              listThuocTon={listThuocTon}
              isLoadingThuocTon={isLoadingThuocTon}
              ngayThuoc={ngayThuoc}
              setNgayThuoc={setNgayThuoc}
              tinhLaiSLThuoc={tinhLaiSLThuoc}
              tinhLaiSLListThuocBN={tinhLaiSLListThuocBN}
              dvtKhongNhanSL={dvtKhongNhanSL}
              isBsDuocKeToa={isBsDuocKeToa}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default FormCD
