import { DatePicker, Input, Modal, Radio } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updatePayment } from '../../../../store/actions/toolAction'
import 'moment/locale/vi'
moment.locale('vi')
const today = moment()
const ModalUpdateThanhToan = ({ openUpdatePay, setOpenUpdatePay }) => {
  const [value, setValue] = useState(1)
  const [maPhieu, setMaPhieu] = useState(null)
  const [date, setDate] = useState(today.format('YYYY-MM-DDTHH:mm:ss'))
  const dispatch = useDispatch()
  const handleCancel = () => {
    setOpenUpdatePay(false)
  }
  const handleDatePicker = (data, dateString) => {
    setDate(moment(dateString, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'))
  }
  const handleOK = () => {
    dispatch(updatePayment(value, maPhieu, date, setMaPhieu))
  }
  const onChange = (e) => {
    setValue(e.target.value)
  }
  return (
    <Modal
      onOk={handleOK}
      okButtonProps={{ disabled: !maPhieu }}
      onCancel={handleCancel}
      open={openUpdatePay}
      title={
        <h2 className="flex gap-2 text-xl items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-10 text-gray-400"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
            />
          </svg>
          Cập nhập ngày thanh toán
        </h2>
      }
    >
      <div className="flex flex-col gap-2 mt-5">
        <div>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>Thu ngân</Radio>
            <Radio value={4}>Bán toa thuốc</Radio>
            <Radio value={5}>Bán lẻ</Radio>
          </Radio.Group>
        </div>
        <div>
          <label className="font-semibold text-base mb-2">Mã thanh toán</label>
          <Input
            value={maPhieu}
            placeholder="PT. . . ."
            onChange={(e) => setMaPhieu(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label className="font-semibold text-base ">Ngày thanh toán</label>
          <DatePicker
            allowClear={false}
            showTime
            onChange={handleDatePicker}
            value={dayjs(date, 'YYYY-MM-DDTHH:mm:ss')}
            format={'DD/MM/YYYY HH:mm:ss'}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalUpdateThanhToan
