import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ConfigProvider, Table, Tooltip, Modal, Input, Image, Divider } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { noiSoiService } from '../../../../services/NoiSoi/noiSoiService'
import { calculateAge } from '../../../../utils/calculateAge'
import ToastCus from '../../../../utils/Toast'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const LichSu = ({ listLichSuNoiSoi, isLoadingLichSu }) => {
  const [isModal, setisModal] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [infoLichSu, setinfoLichSu] = useState(null)
  const [image, setImage] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const getInfoLichSuNoiSoi = async (idbncdha) => {
    try {
      setisLoading(true)
      const { data } = await noiSoiService.getInfoBnNoiSoi(idbncdha)
      setinfoLichSu(data)
      if (data?.linkhinhanh) {
        const images = data?.linkhinhanh?.split(';').map((item) => ({ url: item, checked: false }))
        setImage(images)
      } else {
        setImage([])
      }
      if (data?.bieumau) {
        const convertJson = JSON.parse(data?.bieumau)
        const contentState = convertFromRaw(convertJson)
        setEditorState(EditorState.createWithContent(contentState))
      } else {
        setEditorState(null)
      }
      setisLoading(false)
    } catch (error) {
      console.log('getInfoLichSuNoiSoi : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    }
  }
  return (
    <>
      <div className="px-2 pb-2">
        <div className="grid grid-flow-row">
          <ConfigProvider
            theme={{
              token: { padding: 3, fontSize: 13 },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                },
              },
            }}
          >
            <Table
              onRow={(record) => ({
                onClick: () => {
                  getInfoLichSuNoiSoi(record?.idbncdha)
                  setisModal(true)
                },
              })}
              loading={isLoadingLichSu}
              scroll={{ y: 334 }}
              className="min-w-1"
              bordered
              pagination={false}
              dataSource={listLichSuNoiSoi}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'stt',
                  key: 'stt',
                  width: 35,
                  align: 'center',
                  fixed: 'left',
                  render: (text, record, index) => ++index,
                },
                {
                  title: 'Ngày nội soi',
                  dataIndex: 'ngaynoisoi',
                  key: 'ngaynoisoi',
                  align: 'center',
                  width: 140,
                  render: (text, record, index) =>
                    text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-',
                },
                {
                  title: 'Tên nội soi',
                  dataIndex: 'loainoisoi',
                  key: 'loainoisoi',
                },
                {
                  title: 'Bác sĩ nội soi',
                  dataIndex: 'tenbsnoisoi',
                  key: 'tenbsnoisoi',
                  width: 250,
                },
                {
                  title: '',
                  dataIndex: 'action',
                  key: 'action',
                  fixed: 'right',
                  align: 'center',
                  width: 35,
                  render: (text, record, index) => (
                    <Tooltip placement="topRight" title={'Xem chi tiết'} color={'blue'}>
                      <EyeOutlined className="text-lg text-blue-500 cursor-pointer flex justify-center items-center" />
                    </Tooltip>
                  ),
                },
              ]}
            />
          </ConfigProvider>
        </div>
      </div>
      <Modal
        loading={isLoading}
        style={{ top: 20 }}
        width={1300}
        open={isModal}
        onCancel={() => setisModal(false)}
        //   okText={'Lưu'}
        //   onOk={vacXinEdit ? handleSubmitEdit : formik.handleSubmit}
        cancelText="Đóng"
        title={<p className="text-center">Xem chi tiết lịch sử nội soi</p>}
        //   className="flex flex-col gap-2"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
          </>
        )}
      >
        <div className="grid grid-cols-3 ">
          <div>
            <Divider
              orientation={'left'}
              plain
              style={{ marginTop: 0, marginBottom: 3, fontSize: 15 }}
            >
              <p className="font-semibold text-blue-500">Thông tin hành chính</p>
            </Divider>
            <div className="grid grid-flow-row px-2 gap-1">
              <div>
                <div className="font-semibold">Tên bệnh nhân:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={infoLichSu?.tenbenhnhan}
                />
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <div className="font-semibold">Mã bệnh nhân:</div>
                  <Input
                    size="small"
                    className="w-full"
                    variant="filled"
                    readOnly
                    value={infoLichSu?.mabenhnhan}
                  />
                </div>
                <div>
                  <div className="font-semibold">Ngày sinh:</div>
                  <Input
                    size="small"
                    className="w-full"
                    variant="filled"
                    readOnly
                    value={
                      infoLichSu?.ngaysinh && moment(infoLichSu?.ngaysinh).format('DD/MM/YYYY')
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <div className="font-semibold">Giới tính:</div>
                  <Input
                    size="small"
                    className="w-full"
                    variant="filled"
                    readOnly
                    value={infoLichSu?.gioitinh}
                  />
                </div>
                <div>
                  <div className="font-semibold">Tuổi:</div>
                  <Input
                    size="small"
                    className="w-full"
                    variant="filled"
                    readOnly
                    value={infoLichSu?.ngaysinh && calculateAge(infoLichSu?.ngaysinh)}
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold">Địa chỉ:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={infoLichSu?.diachi}
                />
              </div>
            </div>
            <Divider
              orientation={'left'}
              plain
              style={{ marginTop: 20, marginBottom: 3, fontSize: 15 }}
            >
              <p className="font-semibold text-blue-500">Thông tin phiếu</p>
            </Divider>
            <div className="grid grid-flow-row px-2 gap-1">
              <div>
                <div className="font-semibold">Ngày nội soi:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={
                    infoLichSu?.ngaynoisoi &&
                    moment(infoLichSu?.ngaynoisoi).format('DD/MM/YYYY HH:mm:ss')
                  }
                />
              </div>
              <div>
                <div className="font-semibold">Bác sĩ chỉ định:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={infoLichSu?.tenbschidinh}
                />
              </div>
              <div>
                <div className="font-semibold">Bác sĩ nội soi:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={infoLichSu?.tenbsnoisoi}
                />
              </div>
              <div>
                <div className="font-semibold">Chẩn đoán sơ bộ:</div>
                <Input size="small" className="w-full" variant="filled" readOnly value={null} />
              </div>
              <div>
                <div className="font-semibold">Chỉ định:</div>
                <Input
                  size="small"
                  className="w-full"
                  variant="filled"
                  readOnly
                  value={infoLichSu?.loainoisoi}
                />
              </div>
            </div>
            <Divider
              orientation={'left'}
              plain
              style={{ marginTop: 20, marginBottom: 3, fontSize: 15 }}
            >
              <p className="font-semibold text-blue-500">Thông tin hình ảnh</p>
            </Divider>
            <div className="flex flex-wrap gap-2 mb-2 overflow-y-auto h-[14.3rem]">
              {image?.map((item, index) => (
                <div
                  key={`div${index}`}
                  className="border rounded-md w-[6rem] h-[6rem] overflow-hidden flex justify-center items-center"
                >
                  <Image key={`hinhAnh${index}`} alt={item?.url} src={item?.url} />
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-2">
            <div className="">
              <style>
                {`.toolbarClassName {
              background-color: #f5f5f5;
            }
            
            .wrapperClassName {
              border: 1px solid #d4d4d4;
              border-radius: 0.25rem;
            }
            
            .editorClassName {
              min-height: 609px;
              max-height: 609px;
              overflow-y: auto;
              padding: 0 10px;
            }`}
              </style>
              <Editor
                editorState={editorState}
                toolbar={{
                  options: ['inline', 'blockType', 'fontFamily', 'textAlign', 'colorPicker'],
                  inline: {
                    options: ['bold', 'italic'],
                  },
                  blockType: {
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                  },
                  fontFamily: {
                    options: ['Times New Roman'],
                  },
                }}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
              />
              <div className="pt-3">
                <h2 className="font-semibold">KẾT LUẬN</h2>
                <Input.TextArea
                  style={{
                    height: 105,
                    resize: 'none',
                    fontFamily: 'Time New Roman',
                  }}
                  className="font-bold text-[1rem]"
                  name="KETLUAN"
                  // onChange={formik.handleChange}
                  value={infoLichSu?.ketluan}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default LichSu
