import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LayoutApp from '../../../HOCs/LayoutApp'
import { ConfigProvider, DatePicker, Select, Table, Button as ButtonAntd } from 'antd'
import Button from '@mui/material/Button'
import CachedIcon from '@mui/icons-material/Cached'
import { FileTextOutlined } from '@ant-design/icons'
import InfoBN from './InfoBN/InfoBN'
import VatTu from './VatTu/VatTu'
import moment from 'moment'
import dayjs from 'dayjs'
import {
  getListBNChoTKAction,
  getListBNDaTKAction,
  putListDVBNTrongKinhAction,
} from '../../../store/actions/trongKinhAction'
import { getListVTTHThuThuatTheoKhoAction } from '../../../store/actions/thuThuatAction'
import { getInfoBNDoKhucXaAction } from '../../../store/actions/doKhucXaAction'
import { branchService } from '../../../services/branch/branchService'
import 'moment/locale/vi'
moment.locale('vi')
const TrongKinh = () => {
  const dispatch = useDispatch()
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const dateFormatAPI = 'YYYY-MM-DD'
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  // const { infoBNDVThuThuat, listBNCho, listBNDaThucHien, listVTBN } = useSelector((state) => state.thuThuatReducer)
  const { InfoBNTrongVaGongKinh } = useSelector((state) => state.doKhucXaReducer)
  const { listBNChoTK, listBNDaTK } = useSelector((state) => state.trongKinhReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [branchs, setBranch] = useState([])
  const [pending, setPending] = useState([])
  const [branchPending, setBranchPendding] = useState(ID_BRANCH)
  const [branchDone, setBranchDone] = useState(ID_BRANCH)
  const [dateFrom, setDateFrom] = useState(now.format(dateFormatAPI))
  const [dateTo, setDateTo] = useState(now.format(dateFormatAPI))
  const checkDcCapNhat = true
  //-------- lấy danh sách chi nhánh theo công ty đăng nhập -------//
  const fetchBranchByCompany = async () => {
    try {
      const result = await branchService.getListBranch(PkDangNhap?.idCongTy)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  // -------------- lấy thông tin chi tiết bệnh nhân chưa thu tiền ------------/
  const onClickDetailBN = (record) => {
    dispatch(getInfoBNDoKhucXaAction(record.idbnttc))
  }
  const reloadListBNChoTK = () => {
    dispatch(getListBNChoTKAction(branchPending))
  }
  const reloadListBNDaTK = () => {
    dispatch(getListBNDaTKAction(branchDone, dateFrom, dateTo))
  }

  const handleLuuTrongKinh = () => {
    const listDVTK = InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan?.map((item) => ({
      ...item,
      // idnguoithuchien: infoUser?.idnv,
      // nguoithuchien: infoUser?.tenNV,
      // ngaythuchien: item.tenbietduoc,
    }))
    dispatch(putListDVBNTrongKinhAction(listDVTK, PkDangNhap?.idChiNhanh, dateFrom, dateTo))
  }

  const reloadListVTTieuHao = () => {
    dispatch(getListVTTHThuThuatTheoKhoAction(PkDangNhap?.idChiNhanh, 3)) // khoVatTu = 1 là kho điều dưỡng, khoVatTu = 2 là kho cấp cứu, khoChon = 3 là kho kỹ thuật viên tròng kính
  }
  useEffect(() => {
    if (PkDangNhap) {
      reloadListBNChoTK()
      reloadListBNDaTK()
      fetchBranchByCompany()
      reloadListVTTieuHao()
    }
  }, [PkDangNhap])
  useEffect(() => {
    reloadListVTTieuHao()
  }, [InfoBNTrongVaGongKinh])
  return (
    <>
      <div className="h-full p-3 bg-[#EFEFEF]">
        <div className="h-full bg-white border rounded-xl p-3">
          <div className="flex gap-2 h-full">
            <div className="w-1/4 border h-full rounded-lg">
              <div className="border-b p-2 h-1/2">
                <h2 className="font-semibold  text-lg text-gray-500">Chờ thực hiện</h2>
                <div className="">
                  <div className=" flex gap-2">
                    <Select
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      value={branchPending}
                      onChange={(value) => {
                        if (infoUser?.idNhom !== 3) setBranchPendding(value)
                      }}
                      showSearch
                      options={branchs.map((items) => ({
                        label: items.tenChiNhanh,
                        value: items.idChiNhanh,
                      }))}
                      className="w-full"
                    />
                    <Button onClick={reloadListBNChoTK} variant="text" size="small">
                      <CachedIcon />
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <ConfigProvider theme={{ token: { padding: 5 } }}>
                    <Table
                      pagination={false}
                      dataSource={listBNChoTK ?? []}
                      columns={[
                        {
                          title: 'STT',
                          dataIndex: 'stt',
                          key: 'stt',
                          width: 40,
                          align: 'center',
                          render: (text, record, index) => ++index,
                        },
                        {
                          title: 'Mã BN',
                          dataIndex: 'mabenhnhan',
                          key: 'mabenhnhan',
                          width: 120,
                        },
                        {
                          title: 'Tên bệnh nhân',
                          dataIndex: 'tenbenhnhan',
                          key: 'tenbenhnhan',
                        },
                      ]}
                      onRow={(record) => ({
                        onClick: () => onClickDetailBN(record),
                      })}
                      bordered
                      scroll={{ y: 295 }}
                    />
                  </ConfigProvider>
                </div>
              </div>
              <div className=" p-2 h-1/2">
                <div className="flex justify-between">
                  <h2 className="font-semibold text-lg text-gray-500">Đã thực hiện</h2>
                </div>

                <div className="flex items-center justify-center">
                  <DatePicker
                    allowClear={false}
                    format={dateFormat}
                    className="w-2/5"
                    maxDate={dayjs(now)}
                    defaultValue={dayjs(dateFrom)}
                    value={dayjs(dateFrom)}
                    onChange={(date, dateString) => {
                      setDateFrom(moment(dateString, dateFormat).format(dateFormatAPI))
                      // setDateTo(moment(dateString, dateFormat).format(dateFormatAPI))
                    }}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 w-1/5"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                  </svg>

                  <DatePicker
                    format={dateFormat}
                    allowClear={false}
                    className="w-2/5"
                    maxDate={dayjs(now)}
                    minDate={dayjs(dateFrom)}
                    defaultValue={dayjs(dateTo)}
                    value={dayjs(dateTo)}
                    onChange={(date, dateString) => {
                      setDateTo(moment(dateString, dateFormat).format(dateFormatAPI))
                    }}
                  />
                </div>
                <div className="mt-2">
                  <div className=" flex gap-2">
                    <Select
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      onChange={(value) => {
                        if (infoUser?.idNhom !== 3) setBranchDone(value)
                      }}
                      value={branchDone}
                      showSearch
                      options={branchs.map((items) => ({
                        label: items.tenChiNhanh,
                        value: items.idChiNhanh,
                      }))}
                      className="w-full "
                    />
                    <Button onClick={reloadListBNDaTK} variant="text" size="small">
                      <CachedIcon />
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <ConfigProvider theme={{ token: { padding: 5 } }}>
                    <Table
                      pagination={false}
                      dataSource={listBNDaTK ?? []}
                      columns={[
                        {
                          title: 'STT',
                          dataIndex: 'stt',
                          key: 'stt',
                          width: 40,
                          align: 'center',
                          render: (text, record, index) => ++index,
                        },
                        {
                          title: 'Mã BN',
                          dataIndex: 'mabenhnhan',
                          key: 'mabenhnhan',
                          width: 120,
                        },
                        {
                          title: 'Tên bệnh nhân',
                          dataIndex: 'tenbenhnhan',
                          key: 'tenbenhnhan',
                        },
                      ]}
                      onRow={(record) => ({
                        onClick: () => onClickDetailBN(record),
                      })}
                      bordered
                      scroll={{ y: 265 }}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
            <div className="w-3/4">
              <div className="h-full border rounded-lg p-2">
                <div className="flex justify-end mb-2">
                  <ButtonAntd
                    disabled={
                      !InfoBNTrongVaGongKinh?.IDBNTTC ||
                      (InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan?.length > 0 &&
                        InfoBNTrongVaGongKinh?.DanhSachTrongKinhBenhNhan[0]?.trangthaikb === 4)
                      // || InfoBNTrongVaGongKinh?.DanhSachVatTuTieuHao?.length > 0
                    }
                    onClick={handleLuuTrongKinh}
                    type="primary"
                  >
                    Lưu
                  </ButtonAntd>
                </div>
                <div className="mb-2">
                  <InfoBN />
                </div>
                <div>
                  <VatTu checkDcCapNhat={checkDcCapNhat} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TrongKinh
