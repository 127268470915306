import { https } from '../apiService'

export const khamSucKhoeService = {
  getListBNCho: (idcn) =>
    https.get(`BenhNhanKhamSucKhoe/GetBenhNhanChoKhamSucKhoe`, { params: { idcn } }),
  getListBNDang: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanKhamSucKhoe/GetBenhNhanDangKhamSucKhoe`, {
      params: { idcn, tuNgay, denNgay },
    }),
  getListBNDa: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanKhamSucKhoe/GetBenhNhanDaKhamSucKhoe`, {
      params: { idcn, tuNgay, denNgay },
    }),
  getInfoKhamNoiKhoaKSK: (idbnttc) =>
    https.get(`BenhNhanKhamNoiKhoa/GetBenhNhanKhamSucKhoeNoiKhoa`, {
      params: { idbnttc },
    }),
  putInfoKhamNoiKhoaKSK: (form) => https.put(`BenhNhanKhamNoiKhoa/UpdateBenhNhanKhamSucKhoeNoiKhoa`, form),
  getInfoKhamNgoaiKhoaKSK: (idbnttc) =>
    https.get(`BenhNhanKhamNgoaiKhoa/GetBenhNhanKhamSucKhoeNgoaiKhoa`, {
      params: { idbnttc },
    }),
  putInfoKhamNgoaiKhoaKSK: (form) =>
    https.put(`BenhNhanKhamNgoaiKhoa/UpdateBenhNhanKhamSucKhoeNgoaiKhoa`, form),
  getInfoKhamDaLieuKSK: (idbnttc) =>
    https.get(`BenhNhanKhamDaLieu/GetBenhNhanKhamSucKhoeDaLieu`, {
      params: { idbnttc },
    }),
  putInfoKhamDaLieuKSK: (form) => https.put(`BenhNhanKhamDaLieu/UpdateBenhNhanKhamSucKhoeDaLieu`, form),
  getInfoKhamSanPhuKhoaKSK: (idbnttc) =>
    https.get(`BenhNhanSanPhuKhoa/GetBenhNhanSanPhuKhoa`, {
      params: { idbnttc },
    }),
  putInfoKhamSanPhuKhoaKSK: (form) =>
    https.put(`BenhNhanSanPhuKhoa/UpdateBenhNhanSanPhuKhoa`, form),
  getInfoKhamMatKSK: (idbnttc) =>
    https.get(`BenhNhanKhamSucKhoeMat/GetBenhNhanKhamSucKhoeMat`, {
      params: { idbnttc },
    }),
  putInfoKhamMatKSK: (form) =>
    https.put(`BenhNhanKhamSucKhoeMat/UpdateBenhNhanKhamSucKhoeMat`, form),
  getInfoKhamTaiMuiHongKSK: (idbnttc) =>
    https.get(`BenhNhanTaiMuiHong/GetBenhNhanKhamSucKhoeTaiMuiHong`, {
      params: { idbnttc },
    }),
  putInfoKhamTaiMuiHongKSK: (form) =>
    https.put(`BenhNhanTaiMuiHong/UpdateBenhNhanKhamSucKhoeTaiMuiHong`, form),
  getInfoKhamRangHamMatKSK: (idbnttc) =>
    https.get(`BenhNhanRangHamMat/GetBenhNhanKhamSucKhoeRangHamMat`, {
      params: { idbnttc },
    }),
  putInfoKhamRangHamMatKSK: (form) =>
    https.put(`BenhNhanRangHamMat/UpdateBenhNhanKhamSucKhoeRangHamMat`, form),
  getInfoKetLuanKSK: (idbnttc) =>
    https.get(`BenhNhanKhamSucKhoe/GetBenhNhanKhamSucKhoe`, {
      params: { idbnttc },
    }),
  putInfoKetLuanKSK: (form) => https.put(`BenhNhanKhamSucKhoe/UpdateBenhNhanKhamSucKhoe`, form),
}
