import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  Modal,
  Input,
  Select,
  InputNumber,
  ConfigProvider,
  Table,
  Space,
  Button,
  Segmented,
} from 'antd'
import { SearchOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { addNewDichVuAction, updateDichVuAction } from '../../../../store/actions/dichVuAction'
import { dichVuService } from '../../../../services/dichVu/dichVuService'
import { recieveService } from '../../../../services/receive/recieveService'
import ToastCus from '../../../../utils/Toast'
import { formattedNumber } from '../../../../utils/formattedNumber'
import _ from 'lodash'

const ModalAdd = ({
  isModal,
  setisModal,
  listNhomDichVu,
  listCongTy,
  getListDichVu,
  infoDvEdit,
  setinfoDvEdit,
}) => {
  const dispatch = useDispatch()
  const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_CONGTY_LOGIN = Number(localStorage.getItem('id_company'))
  const [listCdChon, setListCdChon] = useState([])
  const [tabChiDinh, setTabChiDinh] = useState(1)
  const [listChiDinh, setListChiDinh] = useState([])
  const [listGoiKham, setlistGoiKham] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSaoChep, setisLoadingSaoChep] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const infoNhomGoiKham = listNhomDichVu?.find((item) =>
    item?.tenNhom?.toLowerCase()?.includes('gói khám'),
  )
  const initValue = {
    madv: '',
    tendv: '',
    tenviettat: '',
    dvt: '',
    idct: ID_CONGTY_LOGIN,
    idnhomdv: infoNhomGoiKham?.idNhom,
    dongia: 0,
    tienguimau: 0,
    tienchietkhau: 0,
    tiengiacong: 0,
    tienvanchuyen: 0,
    tienvon: 0,
    ghichu: '',
  }
  const [form, setForm] = useState(initValue)
  const resetForm = () => {
    setForm(initValue)
    setinfoDvEdit(null)
    setisModal(null)
    getListDichVu()
  }
  const handleSubmit = () => {
    if (!form?.madv || !form?.tendv || !form?.dvt) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    if (form?.idnhomdv === infoNhomGoiKham?.idNhom && !listCdChon?.length) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn dịch vụ cho gói khám!',
      })
      return
    }
    if (isModal === 'edit' && (!infoDvEdit || !infoDvEdit?.iddv)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    setisModal(null)

    if (isModal === 'add') {
      dispatch(
        addNewDichVuAction(
          form,
          form?.idnhomdv === infoNhomGoiKham?.idNhom ? listCdChon : [], // nếu là gói khám thì truyền list dv trong gói đó để lưu
          resetForm,
        ),
      )
    } else if (isModal === 'edit') {
      dispatch(
        updateDichVuAction(
          { ...form, iddv: infoDvEdit?.iddv },
          form?.idnhomdv === infoNhomGoiKham?.idNhom ? listCdChon : [], // nếu là gói khám thì truyền list dv trong gói đó để lưu
          resetForm,
        ),
      )
    }
  }
  const handleChonDvChoGk = (infoDv) => {
    if (listCdChon?.find((item) => item?.iddv === infoDv?.iddv)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dịch vụ này đã được chọn!',
      })
      return
    }
    setListCdChon((prev) => [...prev, infoDv])
  }
  const getListChiDinhTheoTab = async (tab) => {
    try {
      setIsLoading(true)
      if (tab === 1) {
        const { data } = await dichVuService.getListDichVuGiaChiNhanhAllNhom(
          ID_CHINHANH_LOGIN,
          ID_CONGTY_LOGIN,
        )
        setListChiDinh(
          data
            ?.filter((item) => item?.idNhomDV !== 47)
            ?.map((item) => ({
              iddv: item?.idChiDinh,
              madv: item?.dichVu?.maDichVu,
              tendv: item?.dichVu?.tenDichVu,
              dvt: item?.dichVu?.donVi,
              idnhomdv: item?.idNhomDV,
              tennhomdv: item?.tenNhom,
              dongia: item?.donGia,
              loaichidinh: 1, // 1 = dịch vụ, 2 = xét nghiệm, 3 = cdha
            })),
        )
      } else if (tab === 2) {
        const { data } = await recieveService.getListXetNghiemTiepNhan(ID_CHINHANH_LOGIN)
        setListChiDinh(
          data?.map((item) => ({
            iddv: item?.idChiDinh,
            madv: item?.xetNghiem?.maxn,
            tendv: item?.xetNghiem?.tenxn,
            dvt: item?.xetNghiem?.dvt,
            idnhomdv: item?.xetNghiem?.idnhomxn,
            tennhomdv: item?.tenNhom,
            dongia: item?.donGia,
            loaichidinh: 2, // 1 = dịch vụ, 2 = xét nghiệm, 3 = cdha
          })),
        )
      } else if (tab === 3) {
        const { data } = await recieveService.getListCDHATiepNhan(ID_CONGTY_LOGIN)
        setListChiDinh(
          data?.map((item) => ({
            iddv: item?.idChiDinh,
            madv: item?.macdha,
            tendv: item?.tenChiDinh,
            dvt: item?.dvt,
            idnhomdv: item?.idnhomcdha,
            tennhomdv: item?.tennhom,
            dongia: item?.donGia,
            loaichidinh: 3, // 1 = dịch vụ, 2 = xét nghiệm, 3 = cdha
          })),
        )
      }
    } catch (error) {
      console.log('getListChiDinhTheoTab : ', error)
    } finally {
      setIsLoading(false)
    }
  }
  const getInfoGoiKhamSaoChep = async (iddv) => {
    try {
      setisLoadingSaoChep(true)
      const { data } = await dichVuService.getInfoGoiKham(iddv)
      if (data?.length && data[0]?.DanhSachDichVu?.length) {
        setListCdChon(
          data[0]?.DanhSachDichVu?.sort((a, b) => a?.loaichidinh - b?.loaichidinh)?.map((item) => ({
            iddv: item?.iddv,
            madv: item?.madichvu,
            tendv: item?.tendichvu,
            dvt: item?.dvt,
            idnhomdv: item?.idnhom,
            tennhomdv: item?.tennhom,
            dongia: item?.dongia,
            loaichidinh: item?.loaichidinh, // 1 = dịch vụ, 2 = xét nghiệm, 3 = cdha
          })),
        )
      } else setListCdChon([])
    } catch (error) {
      console.log('getInfoGoiKhamSaoChep : ', error)
    } finally {
      setisLoadingSaoChep(false)
    }
  }
  const getListDichVuGoiKham = async (keyword) => {
    try {
      const { data } = await dichVuService.searchByKeyword(
        47,
        ID_CONGTY_LOGIN,
        keyword?.length ? keyword?.trim() : keyword,
      )
      setlistGoiKham(data)
    } catch (error) {
      console.log('getListDichVu : ', error)
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      const kw = keyword?.length ? keyword?.trim() : keyword
      if (kw?.length) {
        getListDichVuGoiKham(kw)
      }
    }, 300),
    [],
  )
  useEffect(() => {
    if (isModal) {
      if (isModal === 'add') {
        setForm(initValue)
        setListCdChon([])
        getListChiDinhTheoTab(tabChiDinh)
      } else if (isModal === 'edit' && infoDvEdit?.iddv) {
        setForm({
          madv: infoDvEdit?.maDichVu,
          tendv: infoDvEdit?.tenDichVu,
          tenviettat: infoDvEdit?.tenDichVu,
          dvt: infoDvEdit?.donVi,
          idct: infoDvEdit?.idct,
          idnhomdv: infoDvEdit?.idNhomDV,
          dongia: infoDvEdit?.donGia,
          tienguimau: infoDvEdit?.tienGuiMau,
          tienchietkhau: infoDvEdit?.tienChietKhau,
          tiengiacong: infoDvEdit?.tienGiaCong,
          tienvanchuyen: infoDvEdit?.tienCongVanChuyen,
          tienvon: infoDvEdit?.giaVon,
          ghichu: infoDvEdit?.ghiChu,
        })
        if (infoDvEdit?.idNhomDV === infoNhomGoiKham?.idNhom) {
          getInfoGoiKhamSaoChep(infoDvEdit?.iddv)
          getListChiDinhTheoTab(tabChiDinh)
        }
      } else {
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        resetForm()
        return
      }
    }
  }, [isModal])
  const inputGiaTien = (field) => {
    return (
      <InputNumber
        size="small"
        className="w-full"
        min={0}
        step={100}
        max={999999999}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
        formatter={(value) => {
          const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
          return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }}
        value={form[field]}
        onChange={(value) => setForm((prev) => ({ ...prev, [field]: value }))}
      />
    )
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
    // searchedColumn === dataIndex ? (
    //   <Highlighter
    //     highlightStyle={{
    //       backgroundColor: '#ffc069',
    //       padding: 0,
    //     }}
    //     searchWords={[searchText]}
    //     autoEscape
    //     textToHighlight={text ? text.toString() : ''}
    //   />
    // ) : (
    //   text
    // ),
  })
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'madv',
      key: 'madv',
      width: 150,
      ...getColumnSearchProps('madv'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          // return (a?.tiengiamgia ?? 0) - (b?.tiengiamgia ?? 0)
          return (a?.madv ?? '').localeCompare(b?.madv ?? '')
        },
      },
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tendv',
      key: 'tendv',
      ...getColumnSearchProps('tendv'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          // return (a?.tiengiamgia ?? 0) - (b?.tiengiamgia ?? 0)
          return (a?.tendv ?? '').localeCompare(b?.tendv ?? '')
        },
      },
    },
    {
      title: 'ĐVT',
      dataIndex: 'dvt',
      key: 'dvt',
      width: 80,
      align: 'center',
      ...getColumnSearchProps('dvt'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          // return (a?.tiengiamgia ?? 0) - (b?.tiengiamgia ?? 0)
          return (a?.dvt ?? '').localeCompare(b?.dvt ?? '')
        },
      },
    },
    {
      title: 'Tên nhóm',
      dataIndex: 'tennhomdv',
      key: 'tennhomdv',
      width: 200,
      ...getColumnSearchProps('tennhomdv'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          // return (a?.tiengiamgia ?? 0) - (b?.tiengiamgia ?? 0)
          return (a?.tennhomdv ?? '').localeCompare(b?.tennhomdv ?? '')
        },
      },
    },
    {
      title: 'Đơn giá',
      dataIndex: 'dongia',
      key: 'dongia',
      width: 100,
      align: 'right',
      // ...getColumnSearchProps('dongia'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.dongia ?? 0) - (b?.dongia ?? 0)
          // return (a?.dongia ?? '').localeCompare(b?.dvt ?? '')
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
  ]
  return (
    <Modal
      width={1200}
      style={{ top: 20 }}
      open={isModal}
      onCancel={() => setisModal(null)}
      okText={'Lưu'}
      onOk={handleSubmit}
      cancelText="Huỷ bỏ"
      title={
        <p className="text-center">
          {(isModal === 'add' && 'Tạo dịch vụ') || (isModal === 'edit' && 'Chỉnh sửa dịch vụ')}
        </p>
      }
    >
      <div className="grid grid-flow-row items-center gap-1 pb-2">
        <div className="grid grid-cols-3 items-center gap-3">
          <div>
            <div className="font-semibold">
              <span className="text-red-500">(*)&nbsp;</span>Tên dịch vụ:
            </div>
            <Input
              size="small"
              status={!form?.tendv ? 'error' : ''}
              value={form?.tendv}
              onChange={(e) => setForm((prev) => ({ ...prev, tendv: e.target.value }))}
            />
          </div>
          <div>
            <div className="font-semibold">
              <span className="text-red-500">(*)&nbsp;</span>Mã dịch vụ:
            </div>
            <Input
              size="small"
              status={!form?.madv ? 'error' : ''}
              value={form?.madv}
              onChange={(e) => setForm((prev) => ({ ...prev, madv: e.target.value }))}
            />
          </div>
          <div>
            <div className="font-semibold">
              <span className="text-red-500">(*)&nbsp;</span>Đơn vị tính:
            </div>
            <Input
              size="small"
              status={!form?.dvt ? 'error' : ''}
              value={form?.dvt}
              onChange={(e) => setForm((prev) => ({ ...prev, dvt: e.target.value }))}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 items-center gap-3">
          <div>
            <div className="font-semibold">Tên viết tắt:</div>
            <Input
              size="small"
              value={form?.tenviettat}
              onChange={(e) => setForm((prev) => ({ ...prev, tenviettat: e.target.value }))}
            />
          </div>
          <div>
            <div className="font-semibold">Công ty:</div>
            <Select
              disabled={isModal === 'edit'}
              style={{ color: 'black' }}
              size="small"
              className="w-full"
              placeholder="Chọn công ty..."
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')?.toLowerCase()?.includes((input ?? '')?.toLowerCase())
              }
              value={form?.idct}
              onChange={(value) => setForm((prev) => ({ ...prev, idct: value }))}
              options={listCongTy?.map((item) => ({
                key: item?.idct,
                value: item?.idct,
                label: item?.tenct,
              }))}
            />
          </div>
          <div>
            <div className="font-semibold">Nhóm dịch vụ:</div>
            <Select
              disabled={isModal === 'edit'}
              style={{ color: 'black' }}
              size="small"
              className=" w-full"
              placeholder="Chọn nhóm dịch vụ..."
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')?.toLowerCase()?.includes((input ?? '')?.toLowerCase())
              }
              value={form?.idnhomdv}
              onChange={(value) => setForm((prev) => ({ ...prev, idnhomdv: value }))}
              options={listNhomDichVu?.map((item) => ({
                key: item?.idNhom,
                value: item?.idNhom,
                label: item?.tenNhom,
              }))}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 items-center gap-3">
          <div>
            <div className="font-semibold">Tiền chiết khấu:</div>
            {inputGiaTien('tienchietkhau')}
          </div>
          <div>
            <div className="font-semibold">Tiền gửi mẫu:</div>
            {inputGiaTien('tienguimau')}
          </div>
          <div>
            <div className="font-semibold">Đơn giá:</div>
            {inputGiaTien('dongia')}
          </div>
        </div>
        <div className="grid grid-cols-3 items-center gap-3">
          <div>
            <div className="font-semibold">Tiền gia công:</div>
            {inputGiaTien('tiengiacong')}
          </div>
          <div>
            <div className="font-semibold">Tiền vận chuyển:</div>
            {inputGiaTien('tienvanchuyen')}
          </div>
          <div>
            <div className="font-semibold">Tiền vốn:</div>
            {inputGiaTien('tienvon')}
          </div>
        </div>
        <div>
          <div className="font-semibold">Ghi chú:</div>
          <Input
            size="small"
            value={form?.ghichu}
            onChange={(e) => setForm((prev) => ({ ...prev, ghichu: e.target.value }))}
          />
        </div>
        {form?.idnhomdv === infoNhomGoiKham?.idNhom && (
          <div>
            <div className="font-semibold">Sao chép gói:</div>
            <Select
              disabled={isLoadingSaoChep}
              size="small"
              className="w-full"
              placeholder="Tìm tên gói khám..."
              showSearch
              allowClear
              suffixIcon={<SearchOutlined className=" " />}
              filterOption={false}
              notFoundContent={null}
              value={isLoadingSaoChep ? 'Đang lấy dữ liệu...' : null}
              style={{ color: 'black' }}
              onChange={getInfoGoiKhamSaoChep}
              onSearch={debounceDropDown}
              options={listGoiKham?.map((item) => ({
                key: item?.iddv,
                value: item?.iddv,
                label: item,
              }))}
              optionRender={(opt) => (
                <>
                  <div className="grid grid-cols-12 items-center gap-2">
                    <div className="col-span-2">{opt?.data?.label?.maDichVu}</div>
                    <div className="col-span-7 border-x-2 pl-2">{opt?.data?.label?.tenDichVu}</div>
                    <div className="border-e-2 pr-2 text-center">{opt?.data?.label?.donVi}</div>
                    <div className="col-span-2 text-end">
                      {formattedNumber(opt?.data?.label?.donGia ?? 0)}
                    </div>
                  </div>
                </>
                // <ul className="flex">
                //   <li className="w-5/6">
                //     <p className="font-semibold">
                //       {opt?.data?.label?.tenDichVu} - {opt?.data?.label?.maDichVu}
                //     </p>
                //   </li>
                // </ul>
              )}
            />
          </div>
        )}
      </div>
      {form?.idnhomdv === infoNhomGoiKham?.idNhom && form?.idnhomdv === 47 && (
        <>
          <div className="mt-2">
            <div className="h-[13rem]">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 3,
                    fontSize: 13,
                  },
                }}
              >
                <Table
                  //   scroll={{ x: 'max-content' }}
                  //   pagination={{
                  //     pageSize: 20,
                  //     showSizeChanger: false,
                  //   }}
                  loading={isLoadingSaoChep}
                  scroll={{ y: 150 }}
                  pagination={false}
                  bordered
                  dataSource={listCdChon}
                  columns={[
                    ...columns,
                    {
                      title: '',
                      dataIndex: 'action',
                      key: 'action',
                      width: 50,
                      align: 'center',
                      render: (text, record, index) => (
                        <>
                          <CloseOutlined
                            className="cursor-pointer text-red-500 text-lg"
                            onClick={() =>
                              setListCdChon((prev) => prev?.filter((item, idx) => idx !== index))
                            }
                          />
                        </>
                      ),
                    },
                  ]}
                  footer={() => {
                    let tongTien = listCdChon?.reduce((tong, item) => (tong += item?.dongia), 0)
                    return (
                      <>
                        <div className="flex justify-end gap-1 font-semibold text-end pr-3">
                          <div className="">Tổng tiền:</div>
                          <div className="w-[10%]">{formattedNumber(tongTien ?? 0)} VNĐ</div>
                        </div>
                      </>
                    )
                  }}
                  // dataSource={[
                  //   1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2,
                  //   3, 4, 5, 6, 7, 8, 9,
                  // ]}
                />
              </ConfigProvider>
            </div>
          </div>
          <div className="mt-8">
            <div className="flex justify-center items-center mb-2">
              <Segmented
                options={[
                  {
                    key: 1,
                    value: 1,
                    label: 'Khám bệnh',
                  },
                  {
                    key: 2,
                    value: 2,
                    label: 'Xét nghiệm',
                  },
                  {
                    key: 3,
                    value: 3,
                    label: 'Chẩn đoán hình ảnh',
                  },
                ]}
                value={tabChiDinh}
                onChange={(value) => {
                  setTabChiDinh(value)
                  getListChiDinhTheoTab(value)
                }}
                className="w-full"
                block
              />
            </div>
            <div className="h-[12.5rem]">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 3,
                    fontSize: 13,
                  },
                }}
              >
                <Table
                  //   scroll={{ x: 1000 }}
                  //   pagination={{
                  //     pageSize: 20,
                  //     showSizeChanger: false,
                  //   }}
                  loading={isLoading}
                  scroll={{ y: 170 }}
                  pagination={false}
                  bordered
                  dataSource={listChiDinh}
                  columns={[
                    ...columns,
                    {
                      title: '',
                      dataIndex: 'action',
                      key: 'action',
                      width: 50,
                      align: 'center',
                      render: (text, record, index) => (
                        <>
                          <PlusOutlined
                            className="cursor-pointer text-blue-500 text-lg"
                            onClick={() => handleChonDvChoGk(record)}
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </ConfigProvider>
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}

export default ModalAdd
