import { produce } from 'immer'
import * as typeAction from '../constants/constants'
import _, { cloneDeep } from 'lodash'
const initialState = {
  listBNCho: [],
  listBNDang: [],
  listBNDa: [],
  infoBN: null,
  infoBNTTC: null,
  infoToa: null,
  infoTTV: null,
  infoKhamNoiKhoaKSK: null,
  infoKhamNgoaiKhoaKSK: null,
  infoKhamDaLieuKSK: null,
  infoKhamSanPhuKhoaKSK: null,
  infoKhamMatKSK: null,
  infoKhamTaiMuiHongKSK: null,
  infoKhamRangHamMatKSK: null,
  infoKetLuanKSK: null,
}
const khamSucKhoeReducer = (state = initialState, { type, payload }) => {
  // const formatLieuDung = (lieudung) => {
  //   let text = ('' + lieudung)?.replace(/[^\d,.\/]/g, '')?.replaceAll(',', '.') //?.replaceAll(' ', '')
  //   if (text?.includes('/')) {
  //     const arr = text?.split('/')
  //     if (arr?.length === 2 && arr[1]?.length) return Number(arr[0]) / Number(arr[1])
  //   }
  //   return _.isNumber(+text) && !_.isNaN(+text) ? +text : 0
  // }
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE_CLINIC:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LIST_BN_CHO_KSK:
        draft.listBNCho = payload
        break
      case typeAction.DISPATCH_LIST_BN_DANG_KSK:
        draft.listBNDang = payload
        break
      case typeAction.DISPATCH_LIST_BN_DA_KSK:
        draft.listBNDa = payload
        break
      case typeAction.DISPATCH_INFO_BN_KSK:
        draft.infoBN = payload
        break
      case typeAction.DISPATCH_INFO_BNTTC_KSK:
        draft.infoBNTTC = payload
        break
      case typeAction.DISPATCH_INFO_TOA_KSK:
        draft.infoToa = payload
        break
      case typeAction.DISPATCH_INFO_TTV_KSK:
        draft.infoTTV = payload
        break
      case typeAction.DISPATCH_INFO_KHAMNOIKHOA_KSK:
        draft.infoKhamNoiKhoaKSK = payload
        break
      case typeAction.DISPATCH_INFO_KHAMNGOAIKHOA_KSK:
        draft.infoKhamNgoaiKhoaKSK = payload
        break
      case typeAction.DISPATCH_INFO_KHAMDALIEU_KSK:
        draft.infoKhamDaLieuKSK = payload
        break
      case typeAction.DISPATCH_INFO_KHAMSANPHUKHOA_KSK:
        draft.infoKhamSanPhuKhoaKSK = payload
        break
      case typeAction.DISPATCH_INFO_KHAMMAT_KSK:
        draft.infoKhamMatKSK = payload
        break
      case typeAction.DISPATCH_INFO_KHAMTAIMUIHONG_KSK:
        draft.infoKhamTaiMuiHongKSK = payload
        break
      case typeAction.DISPATCH_INFO_KHAMRANGHAMMAT_KSK:
        draft.infoKhamRangHamMatKSK = payload
        break
      case typeAction.DISPATCH_INFO_KETLUAN_KSK:
        draft.infoKetLuanKSK = payload
        break
      case typeAction.DISPATCH_RESET_STORE_KSK:
        draft.infoBN = null
        draft.infoBNTTC = null
        draft.infoToa = null
        draft.infoTTV = null
        draft.infoKhamNoiKhoaKSK = null
        draft.infoKhamNgoaiKhoaKSK = null
        draft.infoKhamDaLieuKSK = null
        draft.infoKhamSanPhuKhoaKSK = null
        draft.infoKhamMatKSK = null
        draft.infoKhamTaiMuiHongKSK = null
        draft.infoKhamRangHamMatKSK = null
        draft.infoKetLuanKSK = null
        break
      default:
        return state
    }
  })
}

export default khamSucKhoeReducer
