import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  ConfigProvider,
  Input,
  Pagination,
  Table,
  Segmented,
  Modal,
  Select,
  InputNumber,
  Space,
  Spin,
} from 'antd'
import { SyncOutlined, PlusOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { dichVuService } from '../../../services/dichVu/dichVuService'
import { recieveService } from '../../../services/receive/recieveService'
import { formattedNumber } from '../../../utils/formattedNumber'
import { putListChiTietCuaGoiKhamAction } from '../../../store/actions/dichVuAction'
import ToastCus from '../../../utils/Toast'

const { confirm } = Modal
function ModalEdit({ isModalEdit, setIsModalEdit, goiKhamEdit, setGoiKhamEdit, getListGoiKham }) {
  const dispatch = useDispatch()
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isLoadingEdit, setIsLoadingEdit] = useState(false)
  const [isLoadingInfo, setIsLoadingInfo] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [tabChiDinh, setTabChiDinh] = useState(1)
  const [listChiDinh, setListChiDinh] = useState([])
  const [listCdChon, setListCdChon] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const resetForm = () => {
    setGoiKhamEdit(null)
    setIsModalEdit(false)
    setIsLoadingEdit(false)
    getListGoiKham()
  }
  const handleSubmit = () => {
    if (!goiKhamEdit?.tenDichVu || !goiKhamEdit?.maDichVu) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
    }
    setIsLoadingEdit(true)
    dispatch(putListChiTietCuaGoiKhamAction(goiKhamEdit, listCdChon, resetForm))
  }

  const handleChonDvChoGk = (infoDv) => {
    if (listCdChon?.find((item) => item?.iddv === infoDv?.iddv)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dịch vụ này đã được chọn!',
      })
      return
    }
    setListCdChon((prev) => [...prev, infoDv])
  }
  const getListChiDinhTheoTab = async (tab) => {
    try {
      setIsLoading(true)
      if (tab === 1) {
        const { data } = await dichVuService.getListDichVuGiaChiNhanhAllNhom(ID_BRANCH, ID_COMPANY)
        setListChiDinh(
          data
            ?.filter((item) => item?.idNhomDV !== 47)
            ?.map((item) => ({
              iddv: item?.idChiDinh,
              madv: item?.dichVu?.maDichVu,
              tendv: item?.dichVu?.tenDichVu,
              dvt: item?.dichVu?.donVi,
              idnhomdv: item?.idNhomDV,
              tennhomdv: item?.tenNhom,
              dongia: item?.donGia,
              loaichidinh: 1, // 1 = dịch vụ, 2 = xét nghiệm, 3 = cdha
            })),
        )
      } else if (tab === 2) {
        const { data } = await recieveService.getListXetNghiemTiepNhan(ID_BRANCH)
        setListChiDinh(
          data?.map((item) => ({
            iddv: item?.idChiDinh,
            madv: item?.xetNghiem?.maxn,
            tendv: item?.xetNghiem?.tenxn,
            dvt: item?.xetNghiem?.dvt,
            idnhomdv: item?.xetNghiem?.idnhomxn,
            tennhomdv: item?.tenNhom,
            dongia: item?.donGia,
            loaichidinh: 2, // 1 = dịch vụ, 2 = xét nghiệm, 3 = cdha
          })),
        )
      } else if (tab === 3) {
        const { data } = await recieveService.getListCDHATiepNhan(ID_COMPANY)
        setListChiDinh(
          data?.map((item) => ({
            iddv: item?.idChiDinh,
            madv: item?.macdha,
            tendv: item?.tenChiDinh,
            dvt: item?.dvt,
            idnhomdv: item?.idnhomcdha,
            tennhomdv: item?.tennhom,
            dongia: item?.donGia,
            loaichidinh: 3, // 1 = dịch vụ, 2 = xét nghiệm, 3 = cdha
          })),
        )
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getInfoGoiKham = async (idgk) => {
    try {
      setIsLoadingInfo(true)
      const { data } = await dichVuService.getInfoGoiKham(idgk)
      // console.log(data[0]?.DanhSachDichVu)
      if (data?.length && data[0]?.DanhSachDichVu?.length) {
        setListCdChon(
          data[0]?.DanhSachDichVu?.map((item) => ({
            iddv: item?.iddv,
            madv: item?.madichvu,
            tendv: item?.tendichvu,
            dvt: item?.dvt,
            idnhomdv: item?.idnhom,
            tennhomdv: item?.tennhom,
            dongia: item?.dongia,
            loaichidinh: item?.loaichidinh, // 1 = dịch vụ, 2 = xét nghiệm, 3 = cdha
          })),
        )
      } else setListCdChon([])
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingInfo(false)
    }
  }
  useEffect(() => {
    if (goiKhamEdit?.iddv) {
      getInfoGoiKham(goiKhamEdit?.iddv)
      setTabChiDinh(1)
      getListChiDinhTheoTab(1)
    }
  }, [goiKhamEdit?.iddv])
  // useEffect(() => {
  //   getListChiDinhTheoTab(tabChiDinh)
  // }, [])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
    // searchedColumn === dataIndex ? (
    //   <Highlighter
    //     highlightStyle={{
    //       backgroundColor: '#ffc069',
    //       padding: 0,
    //     }}
    //     searchWords={[searchText]}
    //     autoEscape
    //     textToHighlight={text ? text.toString() : ''}
    //   />
    // ) : (
    //   text
    // ),
  })
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'madv',
      key: 'madv',
      width: 150,
      ...getColumnSearchProps('madv'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          // return (a?.tiengiamgia ?? 0) - (b?.tiengiamgia ?? 0)
          return (a?.madv ?? '').localeCompare(b?.madv ?? '')
        },
      },
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tendv',
      key: 'tendv',
      ...getColumnSearchProps('tendv'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          // return (a?.tiengiamgia ?? 0) - (b?.tiengiamgia ?? 0)
          return (a?.tendv ?? '').localeCompare(b?.tendv ?? '')
        },
      },
    },
    {
      title: 'ĐVT',
      dataIndex: 'dvt',
      key: 'dvt',
      width: 80,
      align: 'center',
      ...getColumnSearchProps('dvt'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          // return (a?.tiengiamgia ?? 0) - (b?.tiengiamgia ?? 0)
          return (a?.dvt ?? '').localeCompare(b?.dvt ?? '')
        },
      },
    },
    {
      title: 'Tên nhóm',
      dataIndex: 'tennhomdv',
      key: 'tennhomdv',
      width: 200,
      ...getColumnSearchProps('tennhomdv'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          // return (a?.tiengiamgia ?? 0) - (b?.tiengiamgia ?? 0)
          return (a?.tennhomdv ?? '').localeCompare(b?.tennhomdv ?? '')
        },
      },
    },
    {
      title: 'Đơn giá',
      dataIndex: 'dongia',
      key: 'dongia',
      width: 100,
      align: 'right',
      // ...getColumnSearchProps('dongia'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.dongia ?? 0) - (b?.dongia ?? 0)
          // return (a?.dongia ?? '').localeCompare(b?.dvt ?? '')
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
  ]
  return (
    <Modal
      width={1200}
      open={isModalEdit}
      onCancel={() => {
        if (isLoadingEdit) {
          ToastCus.fire({
            icon: 'error',
            title: 'Vui lòng đợi xử lý,\n không đóng cửa sổ thao tác!',
          })
          return
        }
        setIsModalEdit(false)
      }}
      okText={'Lưu'}
      // onOk={handleSubmit}
      cancelText="Huỷ bỏ"
      title={<p className="text-center">Chỉnh sửa gói khám</p>}
      className="flex flex-col gap-2"
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <Button type="primary" onClick={handleSubmit} loading={isLoadingEdit}>
            Lưu
          </Button>
          {/* <OkBtn loading={isLoading} /> */}
        </>
      )}
    >
      {isLoadingEdit && (
        <div className="z-10 absolute h-full w-full top-0 left-0 bg-white bg-opacity-50 flex justify-center items-start rounded-lg">
          <div className="grid grid-flow-row items-center mt-[21rem]">
            <Spin size="large" />
            <div className="font-semibold">Vui lòng đợi xử lý, không đóng cửa sổ thao tác!</div>
          </div>
        </div>
      )}
      <div className="grid grid-flow-row gap-1">
        <div className="grid grid-cols-9 gap-1">
          <div className="font-semibold text-end">
            <span className="text-red-500">(*)</span>Tên gói khám:
          </div>
          <Input
            className="col-span-5 w-full"
            size="small"
            status={!goiKhamEdit?.tenDichVu ? 'error' : ''}
            value={goiKhamEdit?.tenDichVu}
            onChange={(e) => setGoiKhamEdit((prev) => ({ ...prev, tenDichVu: e.target.value }))}
          />
          <div className="font-semibold text-end">
            <span className="text-red-500">(*)</span>Mã gói khám:
          </div>
          <Input
            className="col-span-2 w-full"
            size="small"
            status={!goiKhamEdit?.maDichVu ? 'error' : ''}
            value={goiKhamEdit?.maDichVu}
            onChange={(e) => setGoiKhamEdit((prev) => ({ ...prev, maDichVu: e.target.value }))}
          />
        </div>
        <div className="grid grid-cols-9 gap-1">
          <div className="font-semibold text-end">Sao chép:</div>
          <Input className="col-span-5 w-full" size="small" disabled />
          <div className="font-semibold text-end">Số tiền:</div>
          <InputNumber
            className="col-span-2 w-full"
            size="small"
            value={goiKhamEdit?.donGia}
            onChange={(value) => setGoiKhamEdit((prev) => ({ ...prev, donGia: value }))}
            formatter={(value) => {
              // const roundedValue = Math.round(Number(value), 3) // Làm tròn đến số nguyên gần nhất
              return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            step={100}
            min={0}
            max={999999999}
          />
        </div>
        <div className="grid grid-cols-9 gap-1">
          <div className="font-semibold text-end">Ghi chú:</div>
          <Input
            className="col-span-8 w-full"
            size="small"
            value={goiKhamEdit?.ghiChu}
            onChange={(e) => setGoiKhamEdit((prev) => ({ ...prev, ghiChu: e.target.value }))}
          />
        </div>
      </div>
      <div className="mt-2">
        <div className="h-[15rem]">
          <ConfigProvider
            theme={{
              token: {
                padding: 3,
                fontSize: 13,
              },
            }}
          >
            <Table
              //   scroll={{ x: 'max-content' }}
              //   pagination={{
              //     pageSize: 20,
              //     showSizeChanger: false,
              //   }}
              loading={isLoadingInfo}
              scroll={{ y: 200 }}
              pagination={false}
              bordered
              dataSource={listCdChon}
              columns={[
                ...columns,
                {
                  title: '',
                  dataIndex: 'action',
                  key: 'action',
                  width: 50,
                  align: 'center',
                  render: (text, record, index) => (
                    <>
                      <CloseOutlined
                        className="cursor-pointer text-red-500 text-lg"
                        onClick={() =>
                          setListCdChon((prev) => prev?.filter((item, idx) => idx !== index))
                        }
                      />
                    </>
                  ),
                },
              ]}
              footer={() => {
                let tongTien = listCdChon?.reduce((tong, item) => (tong += item?.dongia), 0)
                return (
                  <>
                    <div className="flex justify-end gap-1 font-semibold text-end pr-3">
                      <div className="">Tổng tiền:</div>
                      <div className="w-[10%]">{formattedNumber(tongTien ?? 0)} VNĐ</div>
                    </div>
                  </>
                )
              }}
              // dataSource={[
              //   1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2,
              //   3, 4, 5, 6, 7, 8, 9,
              // ]}
            />
          </ConfigProvider>
        </div>
      </div>
      <div className="mt-10">
        <div className="flex justify-center items-center mb-2">
          <Segmented
            options={[
              {
                key: 1,
                value: 1,
                label: 'Khám bệnh',
              },
              {
                key: 2,
                value: 2,
                label: 'Xét nghiệm',
              },
              {
                key: 3,
                value: 3,
                label: 'Chẩn đoán hình ảnh',
              },
            ]}
            value={tabChiDinh}
            onChange={(value) => {
              setTabChiDinh(value)
              getListChiDinhTheoTab(value)
            }}
            className="w-full"
            block
          />
        </div>
        <div className="h-[15rem]">
          <ConfigProvider
            theme={{
              token: {
                padding: 3,
                fontSize: 13,
              },
            }}
          >
            <Table
              //   scroll={{ x: 1000 }}
              //   pagination={{
              //     pageSize: 20,
              //     showSizeChanger: false,
              //   }}
              loading={isLoading}
              scroll={{ y: 200 }}
              pagination={false}
              bordered
              dataSource={listChiDinh}
              columns={[
                ...columns,
                {
                  title: '',
                  dataIndex: 'action',
                  key: 'action',
                  width: 50,
                  align: 'center',
                  render: (text, record, index) => (
                    <>
                      <PlusOutlined
                        className="cursor-pointer text-blue-500 text-lg"
                        onClick={() => handleChonDvChoGk(record)}
                      />
                    </>
                  ),
                },
              ]}
            />
          </ConfigProvider>
        </div>
      </div>
    </Modal>
  )
}

export default ModalEdit
