import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { NhapKhoService } from '../../services/NhapKho/NhapKhoService'
import Swal from 'sweetalert2'
import {
  filterPTNhapKhoAction,
  getAllPhieuNhapKho,
  getInfoDoitac,
  getInfoPTNhapByIdAction,
} from '../actions/NhapKhoAction'
import { generatePhieuNhapKho } from '../../utils/report/phieuNhapKho'
import { branchService } from '../../services/branch/branchService'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import 'moment/locale/vi'
import { khoVTservice } from '../../services/khoVT/khoVTservice'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* NhapKhoSaga() {
  // get chi nhánh và kho mặc định
  yield takeLatest(typeAction.GET_BRANCH_AND_KHO_VT_DEFAULT, function* branch({ type, payload }) {
    // yield console.log(payload);
    try {
      const idBranch = localStorage.getItem('BRANH_LOGIN')
      const result = yield call(() => NhapKhoService.getBranch(idBranch))
      const res = yield call(() => NhapKhoService.getListKhoNhap(idBranch))
      yield put({
        type: typeAction.DISPATCH_BRANCH_NHAPKHO,
        payload: result.data,
      })
      yield put({
        type: typeAction.DISPATCH_DEFAULT_KHONHAP,
        payload: res.data,
      })
    } catch (err) {
      console.log(err)
    }
  })

  //get kho nhập
  yield takeLatest(typeAction.GET_BRANCH_NHAPKHO, function* listKhoNhap({ type, idChiNhanh }) {
    try {
      const result = yield call(() => NhapKhoService.getListKhoNhap(idChiNhanh))
      yield put({
        type: typeAction.DISPATCH_LIST_KHONHAP,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  //LÂY DANH SÁCH CHI NHÁNH NHẬP KHO THEO CÔNG TY
  yield takeLatest(
    typeAction.GET_BRANCH_NHAP_KHO_BY_COMPANY,
    function* branchByCompany({ type, IDCompany }) {
      try {
        const result = yield call(() => branchService.getListBranch(IDCompany))
        yield put({
          type: typeAction.DISPATCH_BRANCH_BY_COMPANY,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  //get list đối tác
  yield takeLatest(typeAction.GET_LIST_DOITAC, function* listDoiTac({ type, payload }) {
    // yield console.log(payload);
    try {
      const result = yield call(() => NhapKhoService.getListDoiTac())
      yield put({
        type: typeAction.DISPATCH_LIST_DOITAC,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  //LẤY DANH SÁCH CHI NHÁNH NHẬP KHO
  yield takeLatest(typeAction.GET_BRANCH_NHAP_KHO_PT, function* ({ type, IDCompany }) {
    // yield console.log(payload);
    try {
      const result = yield call(() => branchService.getListBranch(IDCompany))
      yield put({
        type: typeAction.DISPATCH_BRANCH_PT_NHAP_KHO,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  //get info Đối tác
  yield takeLatest(typeAction.GET_INFO_DOITCA, function* infoDoiTac({ type, payload }) {
    // yield console.log(payload);
    try {
      const result = yield call(() => NhapKhoService.getInfoDoiTac(payload))
      yield put({
        type: typeAction.DISPATCH_INFO_DOITAC,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  // get info thuốc và vật tư
  yield takeLatest(
    typeAction.GET_INFO_THUOCVT,
    function* getInfoVTYT({ type, setSelectedItem, payload }) {
      try {
        const result = yield call(() => NhapKhoService.getInfoThuocVT(payload))
        const data = result.data
        const formKhoChiTiet = yield {
          tienVAT: data.GIAMUA * data.QUYCACHDONGGOI * 0.05,
          ptVAT: 0.05,
          idThuoc: data.IDTHUOC,
          soLo: '',
          hanDung: null,
          soLuong: 1,
          countLe: 0,
          donGiaMua: data.GIAMUA,
          donGiaBan: 0,
          phiVanChuyen: 0,
          phiGiaCong: 0,
          soLuongNhan: 0,
          trangThaiChuyenKho: 5,
          ptckTruocVat: 0,
          ckTruocVat: 0,
          vat5: data.GIAMUA * data.QUYCACHDONGGOI * 0.05,
          vat8: 0,
          vat10: 0,
          thanhTien: data.GIAMUA * data.QUYCACHDONGGOI + data.GIAMUA * data.QUYCACHDONGGOI * 0.05,
          thucTra: data.GIAMUA * data.QUYCACHDONGGOI + data.GIAMUA * data.QUYCACHDONGGOI * 0.05,
          soLuongDongGoi: data.QUYCACHDONGGOI,
          quyCachDongGoi: data.QUYCACH,
          nhietDo: null,
          maSoDangKy: data.MASODANGKY,
          tongTienTruocVAT: data.GIAMUA * data.QUYCACHDONGGOI,
          ghiChu: '',
        }
        //get msdk by id thuoc
        const optionsMSDK = yield call(() => khoVTservice.getMSDKThuoc(data.IDTHUOC))
        const formatData = [...optionsMSDK.data.map(val => val.maSoDangKy), data.MASODANGKY]
        const newItems = {
          ...data,
          khoChiTiet: formKhoChiTiet,
          optionsMSDK: formatData.filter(val => val !== null),
        }
        yield put({
          type: typeAction.DISPATCH_LIST_INFO_THUOCVT,
          payload: { newItems, setSelectedItem },
        })
      } catch (err) {
        console.log(err)
      }
    },
  )

  //ADD Phiếu nhập kho và kho chi tiết
  yield takeLatest(
    typeAction.POST_PHIEU_NHAP_KHO,
    function* postPhieuNhapKho({ type, payload, ListThuocVT, xuatPhieuNhapKho }) {
      // yield console.log(payload);
      try {
        const dataChiNhanh = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataChiNhanh
        const phieuNhap = yield call(() => NhapKhoService.postPhieuNhap(payload))
        const id = yield phieuNhap.data.data.idNhapXuat
        const array = []
        ListThuocVT.forEach((item, index) => {
          const { khoChiTiet, ...others } = item
          const { tienVAT, ptVAT, ...data } = khoChiTiet

          data.idNhapXuat = id
          data.soLuongLe = item.khoChiTiet.soLuong * item.QUYCACHDONGGOI + item.khoChiTiet.countLe
          data.soHoaDon = phieuNhap.data.data.soHoaDon

          // Initialize VAT fields
          data.vat5 = 0
          data.vat8 = 0
          data.vat10 = 0

          // Assign VAT based on ptVAT
          if (ptVAT === 0.05) {
            data.vat5 = tienVAT
          } else if (ptVAT === 0.1) {
            data.vat10 = tienVAT
          } else if (ptVAT === 0.08) {
            data.vat8 = tienVAT
          }

          // Add index field
          data.stt = index + 1

          array.push(data)
        })
        const khoChiTiet = yield call(() => NhapKhoService.postkhoChiTiet(array))
        Toast.fire({
          icon: 'success',
          title: 'Thêm Phiếu nhập thành công',
        })
        if (xuatPhieuNhapKho) {
          //xuat phieu nhap kho
          if (phieuNhap.data.data.tenKhoNhap === 'Kho vắc xin') {
            const resultCN = yield call(() =>
              branchService.getInfoBranch(phieuNhap.data.data.idChiNhanhNhan),
            )
            let clonePK = cloneDeep(PkDangNhap)
            clonePK = {
              ...clonePK,
              idChuyenKhoa: resultCN.data.idChuyenKhoa,
              diaChi: resultCN.data.diaChi,
              dienThoai: resultCN.data.dienThoai,
              sohotline: resultCN.data.sohotline,
            }
            generatePhieuNhapKho(phieuNhap.data.data, khoChiTiet.data, clonePK)
          } else {
            generatePhieuNhapKho(phieuNhap.data.data, khoChiTiet.data, PkDangNhap)
          }
        }
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Thêm Phiếu thất bại!',
        })
      }
    },
  )
  // get add list phiếu nhập kho
  yield takeLatest(typeAction.GET_ALL_PHIEU_NHAP, function* getAllListPhieuNhap({ type, payload }) {
    // yield console.log(payload);
    try {
      const result = yield call(() => NhapKhoService.getPhieuNhapKho())
      yield put({
        type: typeAction.DISPATCH_LIST_PHIEU_NHAP,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })

  // DELETE PHIẾU NHẬP KHO THEO ID PHIẾU NHẬP KHO
  yield takeLatest(
    typeAction.DELETE_PHIEU_NHAP_KHO,
    function* deletePhieuNhap({ type, idPhieu, filter }) {
      try {
        yield call(() => NhapKhoService.deletePhieu(idPhieu)) // sau khi xoá phiếu thành công tải lại danh sách phiếu nhập kho
        yield put(filterPTNhapKhoAction(filter))
        Toast.fire({
          icon: 'success',
          title: 'Xoá phiếu thành công!',
        })
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Xoá phiếu thất bại!',
        })
        console.log(err)
      }
    },
  )
  // LẤY THÔNG TIN PHIẾU NHẬP KHO THEO ID
  yield takeLatest(typeAction.GET_INFO_PT_NHAP_KHO, function* infoPTNhapKho({ type, idNhapXuat }) {
    try {
      const result = yield call(() => NhapKhoService.getInfoPTNhapKho(idNhapXuat)) //
      const thuocVT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
      const data = thuocVT.data.map((items, index) => {
        let ptvat = 0
        let countLe =
          items.soLuongLe -
          Math.floor(items.soLuongLe / items.soLuongDongGoi) * items.soLuongDongGoi
        if (items.vat5 !== 0) {
          ptvat = 5
        } else if (items.vat8 !== 0) {
          ptvat = 8
        } else if (items.vat10 !== 0) {
          ptvat = 10
        }
        return { ...items, ID: index, ptvat, countLe }
      })
      yield put({
        type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
        payload: data,
      })
      yield put({
        type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  // FILTER DANH SÁCH PHIẾU NHẬP KHO
  yield takeLatest(typeAction.GET_FILTER_PT_NHAP_KHO, function* infoPTNhapKho({ type, filter }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_TABLE_NHAP_KHO,
      })
      const result = yield call(() => NhapKhoService.getPTBycondition(filter)) //
      yield put({
        type: typeAction.DISPATCH_LIST_PHIEU_NHAP,
        payload: result.data,
      })
      yield put({
        type: typeAction.CLOSE_LOADING_TABLE_NHAP_KHO,
      })
    } catch (err) {
      yield put({
        type: typeAction.CLOSE_LOADING_TABLE_NHAP_KHO,
      })
      console.log(err)
    }
  })
  // Lây Danh sách thuốc vật tư theo keyword
  yield takeLatest(
    typeAction.GET_THUOCVT_BY_KEYWORD,
    function* SearchThuocVT({ type, keyword, company }) {
      try {
        const result = yield call(() => NhapKhoService.getThuocVTByKeyword(keyword, company)) //
        yield put({
          type: typeAction.DISPATCH_ALL_THUOCVT,
          payload: result.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // UPDATE PHIẾU THU KHO NHẬP HÀNG HOÁ
  yield takeLatest(
    typeAction.UPDATE_INFO_PT_NHAP_KHO,
    function* UpdateInfoPTNhapKho({ type, form, idNhapXuat }) {
      try {
        yield call(() => NhapKhoService.putInfoPTNhapKho(form, idNhapXuat)) //
        const result = yield call(() => NhapKhoService.getInfoPTNhapKho(idNhapXuat)) //
        yield put({
          type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
          payload: result.data,
        })
        Toast.fire({
          icon: 'success',
          title: 'Cập nhập thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhập thất bại!',
        })
      }
    },
  )
  // UPDATE HÀNG HOÁ KHO CHI TIẾT
  yield takeLatest(
    typeAction.UPDATE_KHO_CHI_TIET,
    function* UpdateInfokhoChiTiet({ type, form, idNhapXuat, cancel }) {
      try {
        let count = form.soLuongLe + form.countLe
        let SL = Math.floor(count / form.soLuongDongGoi)
        const data = { ...form, soLuongLe: count, soLuong: SL }
        // console.log(form)
        yield call(() => NhapKhoService.putKhoChiTiet(data)) // cập  nhập hàng
        const thuocVT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
        const infoThuoc = thuocVT.data.map((items, index) => {
          let ptvat = 0
          let countLe =
            items.soLuongLe -
            Math.floor(items.soLuongLe / items.soLuongDongGoi) * items.soLuongDongGoi
          if (items.vat5 !== 0) {
            ptvat = 5
          } else if (items.vat8 !== 0) {
            ptvat = 8
          } else if (items.vat10 !== 0) {
            ptvat = 10
          }
          return { ...items, ID: index, ptvat, countLe }
        })
        cancel()
        yield put({
          type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
          payload: infoThuoc,
        })
        Toast.fire({
          icon: 'success',
          title: 'Cập nhập thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhập thất bại!',
        })
      }
    },
  )
  //XÁC NHÂN NHẬP KHO
  yield takeLatest(
    typeAction.UPDATE_XAC_NHAN_NHAP_KHO,
    function* UpdateKho({ type, idNhapXuat, filter }) {
      try {
        const STATUS = 3
        yield call(() => NhapKhoService.updateXacNhanKho(idNhapXuat, STATUS))
        yield put(filterPTNhapKhoAction(filter))
        Toast.fire({
          icon: 'success',
          title: 'Cập nhập thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhập thất bại!',
        })
      }
    },
  )
  // Delete Kho chi tiết
  yield takeLatest(
    typeAction.DELETE_KHO_CHI_TIET,
    function* UpdateInfokhoChiTiet({ type, id, idNhapXuat }) {
      try {
        yield call(() => NhapKhoService.deleteKhoChiTiet(id)) //
        const thuocVT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
        const infoThuoc = thuocVT.data.map((items, index) => {
          let ptvat = 0
          let countLe =
            items.soLuongLe -
            Math.floor(items.soLuongLe / items.soLuongDongGoi) * items.soLuongDongGoi
          if (items.vat5 !== 0) {
            ptvat = 5
          } else if (items.vat8 !== 0) {
            ptvat = 8
          } else if (items.vat10 !== 0) {
            ptvat = 10
          }
          return { ...items, ID: index, ptvat, countLe }
        })
        yield put({
          type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
          payload: infoThuoc,
        })
        Toast.fire({
          icon: 'success',
          title: 'xoá thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Xoá thất bại!',
        })
      }
    },
  )
  //------------- UPLOAD FILE------------
  yield takeLatest(typeAction.UPLOAD_FILE_NHAP_KHO, function* uploadFile({ type, id, form }) {
    try {
      yield call(() => NhapKhoService.uploadFile(id, form))
      const result = yield call(() => NhapKhoService.getInfoPTNhapKho(id)) //
      yield put({
        type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  //------------- DELTE FILE NHAP KHO------------
  yield takeLatest(typeAction.DELETE_FILE_NHAP_KHO, function* deleteFile({ type, id, form }) {
    try {
      yield call(() => NhapKhoService.deleteFile(form))
      const result = yield call(() => NhapKhoService.getInfoPTNhapKho(id)) //
      yield put({
        type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  //------------- ACCPECT NHAP KHO MODAL ------------
  yield takeLatest(typeAction.ACCPECT_NHAP_KHO_MODAL, function* accecptNhapKho({ type, id }) {
    try {
      const STATUS = 3
      yield call(() => NhapKhoService.updateXacNhanKho(id, STATUS))
      const result = yield call(() => NhapKhoService.getInfoPTNhapKho(id)) //
      yield put({
        type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
        payload: result.data,
      })
      Toast.fire({
        icon: 'success',
        title: 'Cập nhập thành công!',
      })
    } catch (err) {
      console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Cập nhập thất bại!',
      })
    }
  })
  //------------------UPDATE trạng thái nhập kho ----------
  yield takeLatest(
    typeAction.UPDATE_STATUS_NHAP_KHO_TOOL,
    function* updateStatusKho({ type, maPhieu, status }) {
      try {
        const { data } = yield call(() => NhapKhoService.getPhieuHangByMaHang(maPhieu))
        const result = yield call(() => NhapKhoService.updateStatusPhieu(data.idnhapxuat, status)) //

        Toast.fire({
          icon: 'success',
          title: 'Cập nhập thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhập thất bại!',
        })
      }
    },
  )
  //------------- SAP CHEP MA HANG Phiếu nhập ------------
  yield takeLatest(
    typeAction.COPY_LIST_HANG_HOA,
    function* copyHangHoa({ type, mahang, setMaHang, setSelectedItem, formik }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data } = yield call(() => NhapKhoService.getPhieuHangByMaHang(mahang))
        yield put(getInfoDoitac(data.iddoitac))
        formik.setFieldValue('SoHoaDon', data.sohoadon)
        formik.setFieldValue('IdHinhThuc', Number(data.idhinhthuc))
        formik.setFieldValue('IdPhuongThuc', Number(data.idphuongthuc))
        formik.setFieldValue('IdDoiTac', data.iddoitac)
        if (data.ngayhoadon) {
          formik.setFieldValue('NgayHoaDon', data.ngayhoadon)
        }
        const result = yield call(() => NhapKhoService.getKhoChitietByNhapXuat(data.idnhapxuat))
        setMaHang(null)
        for (let item of result.data) {
          const dataInfo = yield call(() => NhapKhoService.getInfoThuocVT(item.idThuoc))
          const formKhoChiTiet = yield {
            tienVAT: item.vat5 + item.vat8 + item.vat10,
            ptVAT: item.vat5 !== 0 ? 0.05 : item.vat8 !== 0 ? 0.08 : item.vat10 !== 0 ? 0.1 : 0,
            idThuoc: item.idThuoc,
            soLo: item.soLo,
            hanDung: item.hanDung,
            soLuong: item.soLuong,
            countLe: item.soLuongLe - item.soLuong * item.soLuongDongGoi,
            donGiaMua: item.donGiaMua,
            donGiaBan: item.donGiaBan,
            phiVanChuyen: item.phiVanChuyen,
            phiGiaCong: item.phiGiaCong,
            soLuongNhan: item.soLuongNhan,
            trangThaiChuyenKho: 5,
            ptckTruocVat: item.ptckTruocVat,
            ckTruocVat: item.ckTruocVat,
            vat5: item.vat5,
            vat8: item.vat8,
            vat10: item.vat10,
            thanhTien: item.thanhTien,
            thucTra: item.thucTra,
            soLuongDongGoi: item.soLuongDongGoi,
            quyCachDongGoi: item.quyCachDongGoi,
            nhietDo: item.nhietDo,
            maSoDangKy: item.maSoDangKy,
            tongTienTruocVAT: item.tongTienTruocVAT,
            ghiChu: item.ghiChu,
          }
          const newItems = { ...dataInfo.data, khoChiTiet: formKhoChiTiet }
          yield put({
            type: typeAction.DISPATCH_LIST_INFO_THUOCVT,
            payload: { newItems, setSelectedItem },
          })
        }
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (err) {
        console.log(err)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //---------- SAO CHÉP HÀNG HOÁ VPP -------------
  yield takeLatest(
    typeAction.COPY_PRODUCTS_VPP,
    function* copyHangVPP({ type, mahang, setMaHang, iddoitac, setSelectedItem, formik }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data } = yield call(() => NhapKhoService.getHangVPPNhaCC(mahang, iddoitac))
        setMaHang(null)
        for (let item of data) {
          const dataInfo = yield call(() => NhapKhoService.getInfoThuocVT(item.idthuoc))
          let vat_5 = 0
          let vat_8 = 0
          let Vat_10 = 0
          if (item.vat === 5) {
            vat_5 = 0.05
          } else if (item.vat === 8) {
            vat_8 = 0.08
          } else if (item.vat === 10) {
            Vat_10 = 0.1
          }
          const formKhoChiTiet = yield {
            tienVAT: item.vat * item.thanhtien,
            ptVAT: item.vat === 5 ? 0.05 : item.vat === 8 ? 0.08 : item.vat === 10 ? 0.1 : 0,
            idThuoc: item.idThuoc,
            soLo: '',
            hanDung: null,
            soLuong: Math.floor(item.soluong / dataInfo.data.QUYCACHDONGGOI),
            countLe:
              item.soluong -
              Math.floor(item.soluong / dataInfo.data.QUYCACHDONGGOI) *
                dataInfo.data.QUYCACHDONGGOI,
            donGiaMua: item.giamua,
            donGiaBan: dataInfo.data.GIABAN,
            phiVanChuyen: 0,
            phiGiaCong: 0,
            soLuongNhan: 0,
            trangThaiChuyenKho: 5,
            ptckTruocVat: 0,
            ckTruocVat: 0,
            vat5: item.giamua * dataInfo.data.QUYCACHDONGGOI * vat_5,
            vat8: item.giamua * dataInfo.data.QUYCACHDONGGOI * vat_8,
            vat10: item.giamua * dataInfo.data.QUYCACHDONGGOI * Vat_10,
            thanhTien:
              item.giamua * dataInfo.data.QUYCACHDONGGOI +
              item.giamua * dataInfo.data.QUYCACHDONGGOI * item.vat,
            thucTra:
              item.giamua * dataInfo.data.QUYCACHDONGGOI +
              item.giamua * dataInfo.data.QUYCACHDONGGOI * item.vat,
            soLuongDongGoi: dataInfo.data.QUYCACHDONGGOI,
            quyCachDongGoi: dataInfo.data.QUYCACH,
            nhietDo: null,
            maSoDangKy: dataInfo.data.MASODANGKY,
            tongTienTruocVAT: item.giamua * dataInfo.data.QUYCACHDONGGOI,
            ghiChu: '',
          }
          const newItems = { ...dataInfo.data, khoChiTiet: formKhoChiTiet }
          yield put({
            type: typeAction.DISPATCH_LIST_INFO_THUOCVT,
            payload: { newItems, setSelectedItem },
          })
        }
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (err) {
        console.log(err)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //------------- ADD NEW THUOC VAT TU CHI TIET PHIEU ------------
  yield takeLatest(
    typeAction.ADD_NEW_THUOC_DETAIL_NHAP_KHO,
    function* addNewThuocVT({ type, index, id, idNhapXuat, cancel }) {
      try {
        const toDay = moment()
        cancel()
        const result = yield call(() => NhapKhoService.getInfoThuocVT(id))
        const data = result.data
        const Newdata = {
          stt: ++index,
          idNhapXuat: idNhapXuat,
          tienVAT: data.GIAMUA * data.QUYCACHDONGGOI * 0.08,
          ptVAT: 0.08,
          idThuoc: data.IDTHUOC,
          soLo: '0',
          hanDung: toDay.format('YYYY-MM-DD'),
          soLuong: 0,
          donGiaMua: data.GIAMUA,
          donGiaBan: 0,
          phiVanChuyen: 0,
          phiGiaCong: 0,
          soLuongNhan: 0,
          soLuongLe: data.QUYCACHDONGGOI,
          trangThaiChuyenKho: 5,
          ptckTruocVat: 0,
          ckTruocVat: 0,
          vat5: 0,
          vat8: data.GIAMUA * data.QUYCACHDONGGOI * 0.08,
          vat10: 0,
          thanhTien: data.GIAMUA * data.QUYCACHDONGGOI + data.GIAMUA * data.QUYCACHDONGGOI * 0.08,
          thucTra: data.GIAMUA * data.QUYCACHDONGGOI + data.GIAMUA * data.QUYCACHDONGGOI * 0.08,
          soLuongDongGoi: data.QUYCACHDONGGOI,
          quyCachDongGoi: data.QUYCACH,
          maSoDangKy: data.MASODANGKY,
          tongTienTruocVAT: data.GIAMUA * data.QUYCACHDONGGOI,
        }
        yield call(() => NhapKhoService.postkhoChiTiet([Newdata]))
        const thuocVT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
        const infoThuoc = thuocVT.data.map((items, index) => {
          let ptvat = 0
          let countLe =
            items.soLuongLe -
            Math.floor(items.soLuongLe / items.soLuongDongGoi) * items.soLuongDongGoi
          if (items.vat5 !== 0) {
            ptvat = 5
          } else if (items.vat8 !== 0) {
            ptvat = 8
          } else if (items.vat10 !== 0) {
            ptvat = 10
          }
          return { ...items, ID: index, ptvat, countLe }
        })

        yield put({
          type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
          payload: infoThuoc,
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'thêm thất bại!',
        })
      }
    },
  )
}
