import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, DatePicker, Input, Select, Space, Table } from 'antd'
import { IconButton } from '@mui/material'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { https } from '../../../services/apiService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import dayjs from 'dayjs'
import * as XLSX from 'xlsx'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { cloneDeep, set } from 'lodash'
import 'moment/locale/vi'
moment.locale('vi')

const now = moment()
const formatMoment = 'YYYY-MM-DD'
const dateFormat = 'DD-MM-YYYY'
function ThongKeMatHangDaMua(props) {
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [dateFrom, setDateFrom] = useState(now.format(formatMoment))
  const [dateTo, setDateTo] = useState(now.format(formatMoment))
  const [keyword, setKeyword] = useState('')
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [chiNhanh, setChiNhanh] = useState('')
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [filteredData, setFilteredData] = useState(data)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [showAll, setShowAll] = useState(false)
  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat).format(formatMoment)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const getAllCN_By_idct = async (value) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetChiNhanhByIDCT?idct=${value}`)
      setListChiNhanh(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCompany = (value) => {
    setCompany(value)
    setChiNhanh('')
    getAllCN_By_idct(value)
  }

  const getDataAsync = async (company, chinhanh, tuNgay, denNgay, keyword) => {
    setLoading(true)
    try {
      const { data } = await https.get(
        `VPPDatHang/GetThongkeHangHoaThuMua?idcn=${chinhanh}&idct=${company}&tuNgay=${tuNgay}&denNgay=${denNgay}&keyword=${keyword}`,
      )
      setData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDataAsync(company, chiNhanh, dateFrom, dateTo, keyword)
    dispatch(listAllCompanyAction())
    getAllCN_By_idct(company)
  }, [])

  const handleChiNhanh = (value) => {
    setChiNhanh(value)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex, isChildTable = false, recordId = null) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (isChildTable) {
        return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      } else {
        return true
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => {
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
    },
  })

  const handleChildTableFilterChange = (parentId, filters) => {
    const cloneData = cloneDeep(data)

    const parent = cloneData.find((item) => item.idncc === parentId)

    const filterData = parent.mathang.filter((item) => {
      for (const key in filters) {
        if (filters[key] !== null) {
          return item[key].toLowerCase().includes(filters[key][0].toLowerCase())
        }
      }
    })
    parent.mathang = filterData
    setFilteredData(cloneData)
  }

  const columnsHangHoa = [
    {
      title: 'Mã hàng',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 150,
      ...getColumnSearchProps('mathuoc', true),
    },
    {
      title: 'Tên hàng hóa',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
      ...getColumnSearchProps('tenbietduoc', true),
    },
    {
      title: 'Số lượng mua',
      dataIndex: 'soluongduyet',
      key: 'soluongduyet',
      width: 120,
      align: 'center',
      sorter: (a, b) => a.soluongduyet - b.soluongduyet,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Chi nhánh đặt',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      ...getColumnSearchProps('tenchinhanh', true),
    },
    {
      title: 'Người mua',
      width: 250,
      dataIndex: 'tennguoidat',
      ...getColumnSearchProps('tennguoidat', true),
    },
    {
      title: 'Ngày mua',
      width: 120,
      align: 'center',
      dataIndex: 'ngaydat',
      render: (text) => moment(text).format('DD/MM/YYYY'),
      sorter: (a, b) => moment(a.ngaydat).unix() - moment(b.ngaydat).unix(),
      sortDirections: ['descend', 'ascend'],
    },
  ]

  const exportToExcel = () => {
    // Prepare data for Excel
    const excelData = []

    filteredData.forEach((group) => {
      // Add supplier/warehouse row
      excelData.push({
        'Tên nhà cung cấp/Kho tổng': group.tennhacungcap,
      })

      // Add header row for items
      excelData.push({
        STT: 'STT',
        'Mã hàng hóa': 'Mã hàng hóa',
        'Hàng hóa': 'Hàng hóa',
        'Số lượng mua': 'Số lượng mua',
        'Chi nhánh đặt': 'Chi nhánh đặt',
        'Người mua': 'Người mua',
        'Ngày mua': 'Ngày mua',
      })

      // Add item rows
      group.mathang.forEach((item, index) => {
        excelData.push({
          STT: index + 1,
          'Mã hàng hóa': item.mathuoc,
          'Hàng hóa': item.tenbietduoc,
          'Số lượng mua': item.soluongdat,
          'Chi nhánh đặt': item.tenchinhanh,
          'Người mua': item.tennguoidat,
          'Ngày mua': moment(item.ngaydat).format('DD/MM/YYYY'),
        })
      })
      // Add an empty row for separation
      excelData.push({})
    })

    // Create a new workbook and add the data
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(excelData, { skipHeader: true })

    // Set column widths
    const colWidths = [
      { wch: 5 }, // STT
      { wch: 15 }, // Mã hàng hóa
      { wch: 30 }, // Tên hàng hóa
      { wch: 10 }, // SL đặt
      { wch: 10 }, // SL duyệt
      { wch: 10 }, // SL giao
      { wch: 10 }, // SL nhận
      { wch: 20 }, // Ghi chú
    ]
    ws['!cols'] = colWidths

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    // Generate Excel file and trigger download
    XLSX.writeFile(
      wb,
      `Thống kê hàng mua ${moment(dateFrom).format('DD/MM/YYYY')} - ${moment(dateTo).format('DD/MM/YYYY')}.xlsx`,
    )
  }

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRowKeys(expandedRows)
    if (expandedRows.length === data.length) setShowAll(true)
  }

  const handleHideAll = () => {
    setExpandedRowKeys([])
    setShowAll(false)
  }
  const handleShowAll = (data) => {
    const allKeys = data.map((item) => item.idncc)
    setExpandedRowKeys(allKeys)
    setShowAll(true)
  }

  const columnsGroup = [
    {
      title: 'Tên nhà cung cấp/Kho tổng',
      dataIndex: 'tennhacungcap',
      key: 'tennhacungcap',
    },
    {
      title: (
        <div>
          {showAll ? (
            <Button
              disabled={filteredData.length === 0}
              type="primary"
              size="small"
              onClick={handleHideAll}
            >
              Đóng tất cả
            </Button>
          ) : (
            <Button
              disabled={filteredData.length === 0}
              onClick={() => handleShowAll(filteredData)}
              type="primary"
              size="small"
            >
              Hiện tất cả
            </Button>
          )}
        </div>
      ),
      dataIndex: 'action',
      key: 'action',
      width: 120,
      align: 'center',
    },
  ]

  useEffect(() => {
    // Set all rows to be expanded by default
    const allKeys = data.map((item) => item.idncc)
    setExpandedRowKeys(allKeys)
    setShowAll(true)
  }, [data])

  return (
    <div className="h-full w-full bg-[#EBEBEB] p-2 border rounded-sm ">
      <div className="flex gap-5 justify-between bg-white border-dotted border-b-0 p-4">
        <div className="flex gap-5 justify-between w-full">
          <Select
            showSearch
            className="w-72"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleCompany}
            value={company}
            options={listCompany?.map((items) => ({
              label: items.tenct,
              value: items.idct,
            }))}
          />
          <Select
            showSearch
            className="w-72"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleChiNhanh}
            value={chiNhanh}
            options={[
              {
                tenchinhanh: 'Tất cả',
                idchinhanh: '',
              },
              ...listChiNhanh,
            ].map((items) => ({
              label: items.tenchinhanh,
              value: items.idchinhanh,
            }))}
          />
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('from')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          {/* <div className="w-64">
            <Input
              className="w-full"
              placeholder="Nhập tên hàng"
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              value={keyword}
            />
          </div> */}
          <div className="flex gap-3 mr-auto">
            <IconButton
              color="primary"
              aria-label="delete"
              size="small"
              onClick={() => getDataAsync(company, chiNhanh, dateFrom, dateTo, keyword)}
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="ml-auto">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-white p-2">
        <div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              scroll={{
                y: 800,
              }}
              locale={{
                triggerDesc: 'Sắp xếp giảm dần',
                triggerAsc: 'Sắp xếp tăng dần',
                cancelSort: 'Hủy sắp xếp',
              }}
              bordered
              pagination={false}
              columns={columnsGroup}
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <Table
                      bordered
                      columns={columnsHangHoa}
                      dataSource={record.mathang}
                      pagination={false}
                      onChange={(pagination, filters) =>
                        handleChildTableFilterChange(record.idncc, filters)
                      }
                    />
                  )
                },
                expandedRowKeys, // Bind expandedRowKeys to the state
                onExpandedRowsChange, // Update expanded row keys on change
              }}
              dataSource={data.map((item) => ({
                ...item,
                key: item.idncc,
              }))}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  )
}

ThongKeMatHangDaMua.propTypes = {}

export default ThongKeMatHangDaMua
