export function splitLongWord(word, maxLength) {
    const result = []
    let start = 0
    let end
    while (start < word.length) {
        end = start + maxLength
        // Check if the end is within the word's length
        if (end < word.length) {
            // Find the nearest space character before the end
            while (end > start && word[end] !== ' ') {
                end--
            }
        }
        // If no space was found, split at the maxLength
        if (end === start) {
            end = start + maxLength
        }
        result.push(word.substring(start, end))
        start = end + 1 // Move start to the next word
    }
    return result
}


export function splitLongWordFitWidth(doc, word, maxWidth) {
    const result = [];
    let start = 0;
    let end;

    while (start < word.length) {
        end = start;

        while (end < word.length && doc.getTextWidth(word.substring(start, end)) < maxWidth) {
            end++;
        }

        // If the word is too long and no space was found, split at the max length
        if (end < word.length && word[end] !== ' ') {
            while (end > start && word[end] !== ' ') {
                end--;
            }
        }

        // If no space was found, split at maxWidth
        if (end === start) {
            end = start + 1;
        }

        result.push(word.substring(start, end).trim());
        start = end + 1; // Move to the next word
    }

    return result;
}
