import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  SearchOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, ConfigProvider, Input, Modal, Select, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { https } from '../../../services/apiService'
import ModalAdd from './Modal/ModalAdd'
import ToastCus from '../../../utils/Toast'
import ModalEdit from './Modal/ModalEdit'
const { confirm } = Modal

function Kho(props) {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [loadingKho, setLoadingKho] = useState(false)
  const [loadingCN, setLoadingCN] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEdit, setIsEdit] = useState({
    show: false,
    data: null,
  })
  const [rowActive, setRowActive] = useState(null)
  const searchInput = useRef(null)
  const [searchedColumn, setSearchedColumn] = useState('')
  const [searchText, setSearchText] = useState('')
  const [listKho, setListKho] = useState([])
  const [listCN, setListCN] = useState([])
  const [listCnDefault, setListCnDefault] = useState([])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    getListCNbyIdCt(company)
  }, [])

  useEffect(() => {
    onLoad()
  }, [])

  const onLoad = () => {
    getListKhobyIdCt(company)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleResetCN = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleResetCN(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      width: 60,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã kho',
      dataIndex: 'maKho',
      width: 100,
      ...getColumnSearchProps('maKho'),
    },
    {
      title: 'Tên kho',
      dataIndex: 'tenKho',
      ...getColumnSearchProps('tenKho'),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 80,
      fixed: 'right',
    },
  ]

  const getListKhobyIdCt = async (id) => {
    setLoadingKho(true)
    try {
      const { data } = await https.get(`Kho/GetByCongTy?IDCT=${id}`)
      setListKho(data.result)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingKho(false)
    }
  }

  const getListCNbyIdCt = async (id) => {
    setLoadingCN(true)
    try {
      const { data } = await https.get(`ChiNhanh/GetChiNhanhByIDCT?idct=${id}`)
      setListCnDefault(data)
      setListCN(
        data.map((item) => ({
          ...item,
          checked: false,
        })),
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingCN(false)
    }
  }

  const handleCompany = async (value) => {
    setCompany(value)
    setRowActive(null)
    await Promise.all([getListKhobyIdCt(value), getListCNbyIdCt(value)])
  }

  const getChiNhanhActive = async (id) => {
    setLoadingCN(true)
    try {
      const { data } = await https.get(`KhoChiNhanh/GetKhoChiNhanhByIDKho?idKho=${id}`)
      setListCN(data.map((item) => ({ ...item, checked: item.suDung ? true : false, tenchinhanh: item.tenChiNhanh })))
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingCN(false)
    }
  }
  const handleClickRow = (row) => {
    setRowActive(row)
    getChiNhanhActive(row.idKho)
  }

  const handleCheckBox = (e, record) => {
    const { checked } = e.target
    const index = listCN.findIndex((item) => item.idchinhanh === record.idchinhanh)
    listCN[index].checked = checked
    setListCN([...listCN])
  }

  const handleSelect = (e) => {
    setListCN((prev) => prev.map((item) => ({ ...item, checked: e.target.checked })))
  }

  const columnsCN = [
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      ...getColumnSearchProps('tenchinhanh'),
    },
    {
      title: (
        <div className="flex justify-center flex-col items-center">
          <p>Sử dụng</p>
          <Checkbox
            checked={listCN?.every((item) => item.checked)}
            onChange={handleSelect}
            disabled={!rowActive || loadingCN}
          />
        </div>
      ),
      dataIndex: 'checked',
      width: 100,
      align: 'center',
      key: 'checked',
      render: (text, record, index) => (
        <Checkbox
          disabled={!rowActive}
          checked={record.checked}
          onChange={(e) => {
            handleCheckBox(e, record)
          }}
        />
      ),
    },
  ]

  const handleDeleteKho = async (id) => {
    try {
      await https.delete(`Kho/DeleteKho?idKho=${id}`)
      ToastCus.fire({
        icon: 'success',
        title: 'Xoá kho thành công',
      })
      onLoad()
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi xóa kho',
      })
    }
  }

  const showDeleteConfirm = (item) => {
    confirm({
      title: `Bạn có muốn xóa ${item.tenKho} ?`,
      icon: <ExclamationCircleFilled />,
      content: `${item.tenKho} thuộc ${item.tenCongTy} ?`,
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk() {
        handleDeleteKho(item.idKho)
      },
      onCancel() {},
    })
  }

  const handleShowModalEdit = (item) => {
    ;(async () => {
      try {
        const { data } = await https.get(`/Kho/GetByIDKHO?IDKHO=${item.idKho}`)
        setIsEdit({ show: true, data: data.result })
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const handleUpdate = async () => {
    setLoadingCN(true)
    try {
      const dataFormat = listCN.map((item) => ({
        idKho: rowActive.idKho,
        idCN: item.idchinhanh,
        nguoiTao: infoUser.idnv,
        suDung: item.checked ? 1 : 0,
        idKhoCN: item.idKhoCN,
      }))
      await https.put(`KhoChiNhanh/UpdateKhoChiNhanh`, dataFormat)
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật thất bại',
      })
    } finally {
      setLoadingCN(false)
    }
  }

  return (
    <div className="p-5 bg-[#efefef]">
      <div className="flex w-full items-center gap-5 p-2 bg-white rounded-md">
        <div className="flex w-full gap-3">
          <Select
            showSearch
            className="w-80"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleCompany}
            value={company}
            options={[
              ...(listCompany
                ? listCompany.map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  }))
                : []),
            ]}
          />
          <Button className="ml-auto" type="primary" onClick={() => setIsModalOpen(true)}>
            Tạo kho
          </Button>
        </div>
      </div>
      <div className="flex gap-2 h-full mt-2">
        <div className="w-1/3 h-full bg-white rounded-xl">
          <div className="p-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  fontSize: 14,
                },
              }}
            >
              <Table
                loading={loadingKho}
                pagination={false}
                bordered
                scroll={{ y: 700 }}
                onRow={(row) => {
                  return {
                    onClick: () => {
                      handleClickRow(row)
                    },
                  }
                }}
                columns={columns}
                dataSource={listKho.map((item) => ({
                  ...item,
                  action: (
                    <ul className="flex justify-around">
                      <li>
                        <Tooltip title="Chỉnh sửa" color="green">
                          <EditOutlined
                            onClick={(e) => {
                              e.stopPropagation()
                              handleShowModalEdit(item)
                            }}
                            className="text-xl text-green-500  cursor-pointer"
                          />
                        </Tooltip>
                      </li>
                      <li>
                        <Tooltip title="Xoá" color="red">
                          <DeleteOutlined
                            onClick={(e) => {
                              e.stopPropagation()
                              showDeleteConfirm(item)
                            }}
                            className="text-xl text-red-500  cursor-pointer"
                          />
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                }))}
              />
            </ConfigProvider>
          </div>
        </div>
        <div className="w-2/3 h-full bg-white rounded-md">
          <div className="px-2 pt-2 flex justify-between text-base">
            <div className="flex gap-1 w4/5 ">
              {rowActive && (
                <>
                  <p>
                    {rowActive?.tenCongTy} -{' '}
                    <span className="font-semibold">{rowActive?.tenKho}</span>
                  </p>
                </>
              )}
            </div>
            <div className="w-1/5">
              <Button
                onClick={handleUpdate}
                disabled={!rowActive || loadingCN || company === ''}
                className="h-7 w-full"
                type="primary"
                size="small"
              >
                Lưu
              </Button>
            </div>
          </div>

          <div className="p-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
              }}
            >
              <Table
                loading={loadingCN}
                scroll={{ y: 650 }}
                pagination={false}
                columns={columnsCN}
                dataSource={listCN}
                bordered
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ModalAdd isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onLoad={onLoad} />
      )}
      {isEdit.show && <ModalEdit isEdit={isEdit} setIsEdit={setIsEdit} onLoad={onLoad} />}
    </div>
  )
}

Kho.propTypes = {}

export default Kho
