import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Button, Input } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined, SyncOutlined } from '@ant-design/icons'
import { calculateAge } from '../../../../utils/calculateAge'
import * as typeAction from '../../../../store/constants/constants'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD/MM/YYYY'
function InfoBN({ isShowTab, isEdit, formBenhNhan, formSinhHieu, setFormBenhNhan }) {
  const handleChangeInfoBN = (field, value) => {
    setFormBenhNhan((prev) => ({ ...prev, [field]: value }))
  }
  return (
    <div
      className="bg-white border rounded-lg p-2 grid grid-flow-row gap-2"
      style={{
        boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
      }}
    >
      <div className="grid grid-cols-6">
        <div className="col-span-2 grid grid-cols-5 gap-1 items-center">
          <div className="text-end">Họ và tên:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-4"
            value={formBenhNhan?.tenBenhNhan}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 items-center">
          <div className="text-end">Mã BN:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-2"
            value={formBenhNhan?.maBenhNhan}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 items-center">
          <div className="text-end">Ngày sinh:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-2"
            value={formBenhNhan?.ngaySinh && moment(formBenhNhan?.ngaySinh).format(dateFormat)}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 items-center">
          <div className="text-end">Tuổi:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className={`col-span-2 font-semibold text-blue-500 leading-[22px] ${isShowTab ? 'text-[12px]' : ''}`}
            value={formBenhNhan?.ngaySinh && calculateAge(formBenhNhan?.ngaySinh)}
            // value={'3 tuổi, 5 tháng, 10 ngày'}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 items-center">
          <div className="text-end">Giới tính:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-2"
            value={formBenhNhan?.gioiTinh}
          />
        </div>
      </div>
      <div className="grid grid-cols-6">
        <div className="col-span-2 grid grid-cols-5 gap-1 items-center">
          <div className="text-end">Người nhà:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-4"
            value={formSinhHieu?.tennguoinha}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 items-center">
          <div className="text-end">Vai vế:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-2"
            value={formSinhHieu?.nguoinha}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 items-center">
          <div className="text-end">Mã TCQG:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-2"
            value={formBenhNhan?.maTCQG}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 items-center">
          <div className="text-end">Quốc tịch:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-2"
            value={formBenhNhan?.tenQuocTich}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 items-center">
          <div className="text-end">Dân tộc:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-2"
            value={formBenhNhan?.tenDanToc}
          />
        </div>
      </div>
      {/* <div className="grid grid-cols-6">
        <div className="col-span-2 grid grid-cols-5 gap-1 items-center">
          <div className="text-end">Số TTV:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-4"
            value={infoTTV?.mathe}
          />
        </div>
        <div className="col-span-2 grid grid-cols-6 gap-1 items-center">
          <div className="text-end">Loại thẻ:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-5"
            value={infoTTV?.tenthe}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 items-center">
          <div className="text-end">Từ ngày:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-2"
                value={infoTTV?.tungay && moment(infoTTV?.tungay).format(dateFormat)}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 items-center">
          <div className="text-end">Đến ngày:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-2"
                value={infoTTV?.denngay && moment(infoTTV?.denngay).format(dateFormat)}
          />
        </div>
      </div> */}
      <div className="grid grid-cols-6">
        <div className="col-span-4 grid grid-cols-10 gap-1 items-center">
          <div className="text-end">Địa chỉ:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-9"
            value={formBenhNhan?.diaChi}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 items-center">
          <div className="text-end">Đối tượng:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-2"
            value={formBenhNhan?.tenDoiTuong}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-1">
          <div className={`text-end ${isShowTab ? 'text-[13.5px]' : ''}`}>Ngày khám:</div>
          <Input
            size="small"
            readOnly
            variant="filled"
            className="col-span-2"
            value={
              formSinhHieu?.ngaykham && moment(formSinhHieu?.ngaykham).format('DD/MM/YYYY HH:mm:ss')
            }
          />
        </div>
      </div>
      <div className="grid grid-cols-6">
        <div className="col-span-4 grid grid-cols-10 gap-1 items-center">
          <div className="text-end">Tiền sử:</div>
          <Input
            size="small"
            className="col-span-9"
            value={formBenhNhan ? formBenhNhan?.tienSuBanThan : null}
            onChange={(e) => handleChangeInfoBN('tienSuBanThan', e.target.value)}
            variant={isEdit ? 'outlined' : 'filled'}
            readOnly={!isEdit}
          />
        </div>
        <div className="col-span-2 grid grid-cols-6 gap-1 items-center">
          <div className="text-end">Dị ứng:</div>
          <Input
            size="small"
            className="col-span-5"
            // value={infoBN?.diUng}
            value={formBenhNhan ? formBenhNhan?.diUng : null}
            onChange={(e) => handleChangeInfoBN('diUng', e.target.value)}
            variant={isEdit ? 'outlined' : 'filled'}
            readOnly={!isEdit}
          />
        </div>
      </div>
    </div>
  )
}

export default InfoBN
