import { call, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as typeAction from '../constants/constants'
import { tiemChungService } from '../../services/tiemChung/tiemChungService'
import { thuThuatService } from '../../services/thuThuat/thuThuatService'
import { drugstoreService } from '../../services/drugstore/drugstoreService'
import { generatePhieuKiemSauTiem } from '../../utils/report/phieuKiemSauTiem'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export function* tiemChungSaga() {
  //LẤY DANH SÁCH BỆNH NHÂN CHỜ TIÊM CHỦNG
  yield takeLatest(
    typeAction.GET_LIST_BN_CHO_TIEMCHUNG,
    function* getListBNChoTCSaga({ type, idcn }) {
      try {
        const result = yield call(() => tiemChungService.getListBNChoTC(idcn))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_CHO_TIEMCHUNG,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LẤY DANH SÁCH BỆNH NHÂN ĐÃ TIÊM CHỦNG
  yield takeLatest(
    typeAction.GET_LIST_BN_DA_TIEMCHUNG,
    function* getListBNDaTCSaga({ type, idcn, tungay, denngay }) {
      try {
        const result = yield call(() => tiemChungService.getListBNDaTC(idcn, tungay, denngay))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_TIEMCHUNG,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LẤY THÔNG TIN BỆNH NHÂN TIÊM CHỦNG
  yield takeLatest(
    typeAction.GET_INFO_BN_TIEMCHUNG,
    function* getInfoBNTiemChungSaga({ type, idbnttc, idcn }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => tiemChungService.getInfoBNTiemChung(idbnttc))
        const infoDetailToaThuoc = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        const infoTheoDoiSauTiem = yield call(() =>
          tiemChungService.getInfoBNTheoDoiSauTiem(idbnttc),
        )
        const listVtthBn = infoTheoDoiSauTiem?.data?.DanhSachVatTuTieuHao?.filter(
          (item) => item?.idDichVu,
        )
        // const listVT = yield call(() => thuThuatService.getListVTThuThuat(idcn))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_TIEMCHUNG,
          payload: result.data,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM_TC,
          payload: infoDetailToaThuoc.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_THEO_DOI_SAU_TIEM,
          payload: { ...infoTheoDoiSauTiem.data, DanhSachVatTuTieuHao: listVtthBn },
        })
        // yield put({
        //     type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
        //     payload: listVT?.data.filter((items) => items.tonKho > 0).map((items) => ({ ...items, iD: ('' + items.idThuoc + items.soLo + items.hanDung + items.soHoaDon + items.maSoDangKy + items.tonKho) })),
        // })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //LƯU THÔNG TIN THEO DÕI SAU TIÊM CHỦNG
  yield takeLatest(
    typeAction.POST_INFO_BN_THEO_DOI_SAU_TIEM,
    function* postInfoBNTheoDoiSauTiemSaga({
      type,
      form,
      idcn,
      tungay,
      denngay,
      formPrint,
      resetForm,
    }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => tiemChungService.postInfoBNTheoDoiSauTiem(form))
        const resultCho = yield call(() => tiemChungService.getListBNChoTC(idcn))
        const resultDa = yield call(() => tiemChungService.getListBNDaTC(idcn, tungay, denngay))
        yield call(() => generatePhieuKiemSauTiem(formPrint))
        yield call(() => resetForm(formPrint))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_TIEMCHUNG,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM_TC,
          payload: [],
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_THEO_DOI_SAU_TIEM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_CHO_TIEMCHUNG,
          payload: resultCho.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_TIEMCHUNG,
          payload: resultDa.data,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thông tin thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Lưu thông tin thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // //LƯU DANH SÁCH VẬT TƯ TIÊU HAO BỆNH NHÂN TIÊM CHỦNG
  // yield takeLatest(
  //     typeAction.POST_LIST_VT_BN_TIEMCHUNG,
  //     function* postListVTBNThuThuatSaga({ type, list, listdv,idcn }) {
  //         try {
  //             const result = yield call(() => thuThuatService.postListVTBNThuThuat(list))
  //             const listDV = yield call(() => thuThuatService.putListDVBNThuThuat(listdv))
  //             const listVT = yield call(() => thuThuatService.getListVTThuThuat(idcn))
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
  //                 payload: listVT?.data.filter((items) => items.tonKho > 0).map((items) => ({ ...items, iD: ('' + items.idThuoc + items.soLo + items.hanDung + items.soHoaDon + items.maSoDangKy + items.tonKho) })),
  //             })
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_BN_CHO_THUTHUAT,
  //                 payload: listCho.data,
  //             })
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_BN_DATHUCHIEN_THUTHUAT,
  //                 payload: listDaThucHien.data,
  //             })
  //             yield put({
  //                 type: typeAction.DISPATCH_INFO_BN_DV_THUTHUAT,
  //                 payload: null,
  //             })
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_VT_BN_THUTHUAT,
  //                 payload: [],
  //             })
  //             Toast.fire({
  //                 icon: 'success',
  //                 title: 'Lưu vật tư tiêu hao thành công!',
  //             })
  //         } catch (err) {
  //             yield console.log(err)
  //             Toast.fire({
  //                 icon: 'error',
  //                 title: 'Lưu vật tư tiêu hao thất bại!',
  //             })
  //         }
  //     }
  // )
  // //LẤY DANH SÁCH TỒN KHO VẬT TƯ TIÊU HAO THỦ THUẬT
  // yield takeLatest(
  //     typeAction.GET_LIST_VT_THUTHUAT,
  //     function* getListVTThuThuatSaga({ type, idcn }) {
  //         try {
  //             const result = yield call(() => thuThuatService.getListVTThuThuat(idcn))
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
  //                 payload: result?.data.filter((items) => items.tonKho > 0).map((items) => ({ ...items, iD: ('' + items.idThuoc + items.soLo + items.hanDung + items.soHoaDon + items.maSoDangKy + items.tonKho) })),
  //             })
  //         } catch (err) {
  //             yield console.log(err)
  //         }
  //     }
  // )
  // //LẤY DANH SÁCH BỆNH NHÂN CHỜ THỰC HIỆN THỦ THUẬT
  // yield takeLatest(
  //     typeAction.GET_LIST_BN_CHO_THUTHUAT,
  //     function* getListBNChoSaga({ type, idcn }) {
  //         try {
  //             const result = yield call(() => thuThuatService.getListBNCho(idcn))
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_BN_CHO_THUTHUAT,
  //                 payload: result.data,
  //             })
  //         } catch (err) {
  //             yield console.log(err)
  //         }
  //     }
  // )
  // //LẤY DANH SÁCH BỆNH NHÂN ĐÃ THỰC HIỆN THỦ THUẬT
  // yield takeLatest(
  //     typeAction.GET_LIST_BN_DATHUCHIEN_THUTHUAT,
  //     function* getListBNDaThucHienSaga({ type, idcn, tungay, denngay }) {
  //         try {
  //             const result = yield call(() => thuThuatService.getListBNDaThucHien(idcn, tungay, denngay))
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_BN_DATHUCHIEN_THUTHUAT,
  //                 payload: result.data,
  //             })
  //         } catch (err) {
  //             yield console.log(err)
  //         }
  //     }
  // )
  // //LƯU DANH SÁCH VẬT TƯ TIÊU HAO BỆNH NHÂN THỦ THUẬT
  // yield takeLatest(
  //     typeAction.POST_LIST_VT_BN_THUTHUAT,
  //     function* postListVTBNThuThuatSaga({ type, list, listdv, idcn, tungay, denngay }) {
  //         try {
  //             const result = yield call(() => thuThuatService.postListVTBNThuThuat(list))
  //             const listDV = yield call(() => thuThuatService.putListDVBNThuThuat(listdv))
  //             const listCho = yield call(() => thuThuatService.getListBNCho(idcn))
  //             const listDaThucHien = yield call(() => thuThuatService.getListBNDaThucHien(idcn, tungay, denngay))
  //             const listVT = yield call(() => thuThuatService.getListVTThuThuat(idcn))
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
  //                 payload: listVT?.data.filter((items) => items.tonKho > 0).map((items) => ({ ...items, iD: ('' + items.idThuoc + items.soLo + items.hanDung + items.soHoaDon + items.maSoDangKy + items.tonKho) })),
  //             })
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_BN_CHO_THUTHUAT,
  //                 payload: listCho.data,
  //             })
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_BN_DATHUCHIEN_THUTHUAT,
  //                 payload: listDaThucHien.data,
  //             })
  //             yield put({
  //                 type: typeAction.DISPATCH_INFO_BN_DV_THUTHUAT,
  //                 payload: null,
  //             })
  //             yield put({
  //                 type: typeAction.DISPATCH_LIST_VT_BN_THUTHUAT,
  //                 payload: [],
  //             })
  //             Toast.fire({
  //                 icon: 'success',
  //                 title: 'Lưu thủ thuật thành công!',
  //             })
  //         } catch (err) {
  //             yield console.log(err)
  //             Toast.fire({
  //                 icon: 'error',
  //                 title: 'Lưu thủ thuật thất bại!',
  //             })
  //         }
  //     }
  // )
}
