import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import _ from 'lodash'
import { khamSucKhoeService } from '../../services/khamSucKhoe/khamSucKhoeService'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
import { drugstoreService } from '../../services/drugstore/drugstoreService'
import { recieveService } from '../../services/receive/recieveService'
import { generatePhieuToaThuoc } from '../../utils/report/phieuToaThuoc'
// import { BNChoKham, thuocKeToa, updateDetailBN } from '../actions/childrenClinicAction'
// import { generateKhamBenhXuatVacXin } from '../../utils/report/vacxin/phieuKhamBenhXuatVacxin'
// import { generatePhieuToaThuoc } from '../../utils/report/phieuToaThuoc'
// import { generatePhieuChiDinh } from '../../utils/report/phieuChiDinh'
// import { cashierService } from '../../services/cashier/cashierService'
// import { doKhucXaService } from '../../services/doKhucXa/doKhucXaService'
// import { generatePhieuKiemTruocTiemChung } from '../../utils/report/phieuKiemTruocTiemChung'
// import { generatePhieuKiemTruocTiemChungLao } from '../../utils/report/phieuKiemTruocTiemChungLao'
// import { generatePhieuKiemTruocTiemChungSan } from '../../utils/report/phieuKiemTruocTiemChungSan'
// // import { generatePhieuToaThuocTA } from '../../utils/report/phieuToaThuocTA'
// import { generatePhieuKhamBenh } from '../../utils/report/phieuKhamBenh'
// // import { generatePhieuToaThuocKoLayThuoc } from '../../utils/report/phieuToaThuocKoLayThuoc'
import ToastCus from '../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
export function* khamSucKhoeSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_LIST_BN_CHO_KSK,
    function* getListBNChoSaga({ type, setLoadingListBNCho }) {
      try {
        yield call(() => setLoadingListBNCho(true))
        const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
        // const { PkDangNhap } = yield select((state) => state.branchReducer)
        const { data } = yield call(() => khamSucKhoeService.getListBNCho(ID_CHINHANH_LOGIN))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_CHO_KSK,
          payload: data,
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Lấy dữ liệu thất bại!',
        })
      } finally {
        yield call(() => {
          setLoadingListBNCho(false)
        })
      }
    },
  )
  // LẤY DANH SÁCH BỆNH NHÂN ĐÃ VÀ ĐANG KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_LIST_BN_DANG_VA_DA_KSK,
    function* getListBNDaVaDangSaga({ type, dateFrom, dateTo, setLoadingListBNDa }) {
      try {
        yield call(() => setLoadingListBNDa(true))
        const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
        // const { PkDangNhap } = yield select((state) => state.branchReducer)
        const { data: listBNDang } = yield call(() =>
          khamSucKhoeService.getListBNDang(ID_CHINHANH_LOGIN, dateFrom, dateTo),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DANG_KSK,
          payload: listBNDang,
        })
        const { data: listBNDa } = yield call(() =>
          khamSucKhoeService.getListBNDa(ID_CHINHANH_LOGIN, dateFrom, dateTo),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_KSK,
          payload: listBNDa,
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Lấy dữ liệu thất bại!',
        })
      } finally {
        yield call(() => {
          setLoadingListBNDa(false)
          // setTimeout(() => {
          //   setLoadingListBNDa(false)
          // }, 1000)
        })
      }
    },
  )
  // LẤY THÔNG TIN CHI TIẾT BỆNH NHÂN KHÁM BỆNH
  yield takeLatest(
    typeAction.GET_INFO_BN_KSK,
    function* getInfoBNKhamSaga({ type, idbn, idbnttc }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const { data: infoBN } = yield call(() => recieveService.getInfoBN(idbn))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KSK,
          payload: infoBN,
        })
        const { data: infoBNTTC } = yield call(() => childrenClinicService.getDetailBNKham(idbnttc))
        yield put({
          type: typeAction.DISPATCH_INFO_BNTTC_KSK,
          payload: infoBNTTC,
        })
        const { data: infoToa } = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        yield put({
          type: typeAction.DISPATCH_INFO_TOA_KSK,
          payload: infoToa,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        // const { data: infoThuoc } = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        // yield put({
        //   type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM,
        //   payload: infoThuoc,
        // })
        // const { data: infoTTV } = yield call(() =>
        //   cardService.getInfoMemberCard(idbn, PkDangNhap?.idChuyenKhoa),
        // )
        // yield put({
        //   type: typeAction.DISPATCH_INFO_TTV_KSK,
        //   payload: infoTTV,
        // })

        // const history = yield call(() => recieveService.getLichSuTongQuat(idbn))
        // const sortedData = _.orderBy(history.data, 'ngaykham', 'desc')
        // const newDataHistory = sortedData.slice(1)
        // yield put({
        //   type: typeAction.DISPATCH_HISTORY_KHAM_PK,
        //   payload: newDataHistory,
        // })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Lấy dữ liệu thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // LẤY THÔNG TIN CHI TIẾT KHÁM LÂM SÀNG BỆNH NHÂN KHÁM BỆNH
  yield takeLatest(
    typeAction.GET_INFO_BN_KLS_KSK,
    function* getInfoBNKhamLamSangSaga({ type, idbnttc }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data: infoKetLuanKSK } = yield call(() =>
          khamSucKhoeService.getInfoKetLuanKSK(idbnttc),
        )
        yield put({
          type: typeAction.DISPATCH_INFO_KETLUAN_KSK,
          payload: infoKetLuanKSK,
        })
        const { data: infoKhamNoiKhoaKSK } = yield call(() =>
          khamSucKhoeService.getInfoKhamNoiKhoaKSK(idbnttc),
        )
        yield put({
          type: typeAction.DISPATCH_INFO_KHAMNOIKHOA_KSK,
          payload: infoKhamNoiKhoaKSK,
        })
        const { data: infoKhamNgoaiKhoaKSK } = yield call(() =>
          khamSucKhoeService.getInfoKhamNgoaiKhoaKSK(idbnttc),
        )
        yield put({
          type: typeAction.DISPATCH_INFO_KHAMNGOAIKHOA_KSK,
          payload: infoKhamNgoaiKhoaKSK,
        })
        const { data: infoKhamDaLieuKSK } = yield call(() =>
          khamSucKhoeService.getInfoKhamDaLieuKSK(idbnttc),
        )
        yield put({
          type: typeAction.DISPATCH_INFO_KHAMDALIEU_KSK,
          payload: infoKhamDaLieuKSK,
        })
        const { data: infoKhamSanPhuKhoaKSK } = yield call(() =>
          khamSucKhoeService.getInfoKhamSanPhuKhoaKSK(idbnttc),
        )
        yield put({
          type: typeAction.DISPATCH_INFO_KHAMSANPHUKHOA_KSK,
          payload: infoKhamSanPhuKhoaKSK,
        })
        const { data: infoKhamMatKSK } = yield call(() =>
          khamSucKhoeService.getInfoKhamMatKSK(idbnttc),
        )
        yield put({
          type: typeAction.DISPATCH_INFO_KHAMMAT_KSK,
          payload: infoKhamMatKSK,
        })
        const { data: infoKhamTaiMuiHongKSK } = yield call(() =>
          khamSucKhoeService.getInfoKhamTaiMuiHongKSK(idbnttc),
        )
        yield put({
          type: typeAction.DISPATCH_INFO_KHAMTAIMUIHONG_KSK,
          payload: infoKhamTaiMuiHongKSK,
        })
        const { data: infoKhamRangHamMatKSK } = yield call(() =>
          khamSucKhoeService.getInfoKhamRangHamMatKSK(idbnttc),
        )
        yield put({
          type: typeAction.DISPATCH_INFO_KHAMRANGHAMMAT_KSK,
          payload: infoKhamRangHamMatKSK,
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Lấy dữ liệu thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // LƯU THÔNG TIN KHÁM NỘI KHOA KSK
  yield takeLatest(
    typeAction.PUT_INFO_KLS_KSK,
    function* putInfoKhamLamSanKSKSaga({ type, form, tabForm, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        // const { PkDangNhap } = yield select((state) => state.branchReducer)
        // console.log(tabForm)
        // const { infoUser } = yield select((state) => state.userReducer)
        const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
        const { infoBNTTC } = yield select((state) => state.khamSucKhoeReducer)
        let result = null
        if (tabForm === 'khamnoikhoa')
          // coi ở file FormKLS.jsx
          result = yield call(() => khamSucKhoeService.putInfoKhamNoiKhoaKSK(form))
        if (tabForm === 'khamngoaikhoa')
          // coi ở file FormKLS.jsx
          result = yield call(() => khamSucKhoeService.putInfoKhamNgoaiKhoaKSK(form))
        if (tabForm === 'khamdalieu')
          // coi ở file FormKLS.jsx
          result = yield call(() => khamSucKhoeService.putInfoKhamDaLieuKSK(form))
        if (tabForm === 'khamsanphukhoa')
          // coi ở file FormKLS.jsx
          result = yield call(() => khamSucKhoeService.putInfoKhamSanPhuKhoaKSK(form))
        if (tabForm === 'khammat')
          // coi ở file FormKLS.jsx
          result = yield call(() => khamSucKhoeService.putInfoKhamMatKSK(form))
        if (tabForm === 'khamtaimuihong')
          // coi ở file FormKLS.jsx
          result = yield call(() => khamSucKhoeService.putInfoKhamTaiMuiHongKSK(form))
        if (tabForm === 'khamranghammat')
          // coi ở file FormKLS.jsx
          result = yield call(() => khamSucKhoeService.putInfoKhamRangHamMatKSK(form))
        if (tabForm === 'ketluan') {
          // coi ở file FormKLS.jsx
          result = yield call(() => khamSucKhoeService.putInfoKetLuanKSK(form))

          const { data: infoToa } = yield call(() =>
            drugstoreService.getServiceNhaThuoc(infoBNTTC?.idbnttc),
          )
          yield call(() =>
            childrenClinicService.updateDetailBN({
              idbnttc: infoBNTTC?.idbnttc,
              idbn: infoBNTTC?.idbn,
              trangthaikhambenh: infoToa?.DanhSachThuoc?.length ? 2 : 4,
            }),
          )

          // const { data: dsBnCho } = yield call(() =>
          //   khamSucKhoeService.getListBNCho(ID_CHINHANH_LOGIN),
          // )
          // yield put({
          //   type: typeAction.DISPATCH_LIST_BN_CHO_KSK,
          //   payload: dsBnCho,
          // })
          // const now = moment().format('YYYY-MM-DD')
          // if (infoToa?.DanhSachThuoc?.length) {
          //   const { data: listBNDang } = yield call(() =>
          //     khamSucKhoeService.getListBNDang(ID_CHINHANH_LOGIN, now, now),
          //   )
          //   yield put({
          //     type: typeAction.DISPATCH_LIST_BN_DANG_KSK,
          //     payload: listBNDang,
          //   })
          // } else {
          //   const { data: listBNDa } = yield call(() =>
          //     khamSucKhoeService.getListBNDa(ID_CHINHANH_LOGIN, now, now),
          //   )
          //   yield put({
          //     type: typeAction.DISPATCH_LIST_BN_DA_KSK,
          //     payload: listBNDa,
          //   })
          // }
        }
        yield put({
          type: typeAction.DISPATCH_RESET_STORE_KSK,
        })
        yield call(() => resetForm())
        if (!result) {
          yield put({
            type: typeAction.CLOSE_LOADING_PAGE,
          })
          ToastCus.fire({
            icon: 'error',
            title: 'Thao tác thất bại!',
          })
          return
        }
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // LƯU THÔNG TIN CHẨN ĐOÁN
  yield takeLatest(
    typeAction.PUT_INFO_CHANDOAN_KSK,
    function* putInfoChanDoanSaga({ type, form, formKL, list, isPrint, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
        const ID_CONGTY_LOGIN = Number(localStorage.getItem('id_company'))
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const { infoUser } = yield select((state) => state.userReducer)
        const { infoBN, infoBNTTC } = yield select((state) => state.khamSucKhoeReducer)

        // check dược sĩ đang thao tác thì không lưu
        if (infoBNTTC?.trangthaikhambenh !== 1) {
          const { data: infoToa } = yield call(() =>
            drugstoreService.getServiceNhaThuoc(infoBNTTC?.idbnttc),
          )
          if (infoToa?.DANHANTOA === 1) {
            yield put({
              type: typeAction.DISPATCH_INFO_TOA_KSK,
              payload: infoToa,
            })
            // yield put({
            //   type: typeAction.DISPATCH_RESET_STORE_KSK,
            // })
            // yield call(() => resetForm())
            yield put({
              type: typeAction.CLOSE_LOADING_PAGE,
            })
            ToastCus.fire({
              icon: 'error',
              title: 'Dược sĩ đang thao tác!',
            })
            return
          }
        }

        const { data: infoBNTTCnew } = yield call(() =>
          childrenClinicService.updateDetailBN({
            idbnttc: infoBNTTC?.idbnttc,
            idbn: infoBNTTC?.idbn,
            chieucao: form?.editsinhhieu?.chieucao,
            cannang: form?.editsinhhieu?.cannang,
            mach: form?.editsinhhieu?.mach,
            huyetap: form?.editsinhhieu?.huyetap,
            nhietdo: form?.editsinhhieu?.nhietdo,
            nhiptho: form?.editsinhhieu?.nhiptho,
            spO2: form?.editsinhhieu?.spO2,
            // ngaykham: '2024-11-01T06:20:38.288Z',
            // sttkham: 0,
            // idcnpk: 0,
            // idlankham: 0,
            // idnguon: 0,
            ghichu: form?.ghichu,
            // nguoitao: 'string',
            // ngaysua: '2024-11-01T06:20:38.288Z',
            // nguoisua: 0,
            trangthaikhambenh: isPrint ? (list?.length ? 2 : 4) : infoBNTTC?.trangthaikhambenh,
            bskham: infoUser?.tenNV,
            lydokham: form?.lydokham,

            // // benhsu: 'string',
            // trieuchung: form?.trieuchung,
            // idchandoan: form?.idchandoan,
            // chandoan: form?.chandoan,
            // idloidan: form?.idloidan,
            // loidan: form?.loidan,

            tiensubanthan: form?.editbenhnhan?.tienSuBanThan,
            diung: form?.editbenhnhan?.diUng,
            // idcalamviec: 0,
            idbskham: infoUser?.idnv,
            idcn: ID_CHINHANH_LOGIN,
            idct: ID_CONGTY_LOGIN,
            idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
            ngaytaikham: form?.ngaytaikham,
            // soLanDaTaiKham: 0,
            // idloaikham: 0,
            // nguoinha: 'string',
            // tennguoinha: 'string',
            // dienthoainguoinha: 'string',
            lydokolaythuoc: list?.length ? 0 : form?.lydokolaythuoc,
            noidungkolaythuoc: list?.length ? '' : form?.noidungkolaythuoc,
            // tenPhongKham: 'string',
            // trangThaiKB: 'string',
            // tenLanKham: 'string',
            // tenLoaiKham: 'string',
            // tenNguon: 'string',
            // tenCaLamViec: 'string',
          }),
        )
        const { data: toaNew } = yield call(() =>
          childrenClinicService.putToaThuocBsTuLaySoLo(
            infoBNTTC?.idbnttc,
            ID_CHINHANH_LOGIN,
            list?.map((item, index) => ({
              // idbnthuoc: 0,
              stt: `${++index}`,
              idbnttc: infoBNTTC?.idbnttc,
              idthuoc: item?.idthuoc,
              maToaThuoc: item?.info?.thuocVatTu?.maThuoc,
              tenBietDuoc: item?.tenbietduoc,
              tenHoatChat: item?.tenhoatchat,
              dvt: item?.dvt,
              nongDo: item?.info?.thuocVatTu?.nongDo,
              hamLuong: item?.dvt,
              quyCach: item?.info?.thuocVatTu?.quyCach,
              donGia: item?.giaban,
              soLuong: item?.soluong,
              // thanhTien: item?.soluong * item?.giaban,
              // tongTien: item?.soluong * item?.giaban,
              bsKeDon: infoUser?.tenNV,
              // ngayKeDon: '2024-11-01T07:07:15.175Z',
              ghiChu: item?.ghichu,
              lieuDung: `${item?.lieudung}`,
              soLanNgay: item?.solanngay,
              idkhocn: item?.idkhocn,
              duongDung: item?.duongdung,
              idnhomthuoc: item?.info?.thuocVatTu?.idNhom,
              donvidung: item?.donvidung,
              // muitiem: 0,
              // ngayhentiem: '2024-11-01T07:07:15.175Z',
              // idphanloai: item?.info?.thuocVatTu?.idNhom,
              // soLo: item?.solo,
              // hanDung: item?.handung,
              // soHoaDon: item?.sohoadon,
            })),
          ),
        )
        yield call(() =>
          khamSucKhoeService.putInfoKetLuanKSK(
            formKL,
            //   {
            //   ...formKL,
            //   lydokham: form?.lydokham,
            //   // benhsu: 'string',
            //   trieuchung: form?.trieuchung,
            //   idchandoan: form?.idchandoan,
            //   chandoan: form?.chandoan,
            //   idloidan: form?.idloidan,
            //   loidan: form?.loidan,
            // }
          ),
        )
        yield call(() =>
          childrenClinicService.putInfoBN({
            idbn: infoBN?.idbn,
            diUng: form?.editbenhnhan?.diUng,
            tienSuBanThan: form?.editbenhnhan?.tienSuBanThan,
            dienThoai: form?.editbenhnhan?.dienThoai,
          }),
        )
        if (isPrint && toaNew?.length) {
          yield call(() => {
            let listVT = []
            // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
            toaNew
              ?.sort((a, b) => (a?.stt ?? '').localeCompare(b?.stt ?? ''))
              ?.filter((item) => item?.idnhomthuoc !== 179 && item?.idnhomthuoc !== 205)
              ?.map((item) => {
                if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
                  listVT?.map(
                    (itemVT) =>
                      itemVT?.idthuoc === item?.idthuoc && (itemVT.soLuong += item.soLuong),
                  )
                } else {
                  listVT.push({
                    ...item,
                  })
                }
              })
            // for (const item of toaPrint) {
            //   console.log(item)

            // }
            generatePhieuToaThuoc(
              infoBN,
              {
                ...infoBNTTCnew,
                // dataDoKhucXa: form?.dataDoKhucXa?.nguoikham ? form?.dataDoKhucXa : null,
              },
              listVT,
              PkDangNhap,
            )
          })
        }
        yield put({
          type: typeAction.DISPATCH_RESET_STORE_KSK,
        })
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.log('Lưu thông tin chẩn đoán : ', error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
