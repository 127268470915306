import React, { useCallback, useEffect, useState } from 'react'
import { Button, ConfigProvider, Input, Pagination, Table, Tooltip, Modal, Select } from 'antd'
import {
  PlusOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  SearchOutlined,
} from '@ant-design/icons'
import BieuMau from '../../layout/SieuAm/Template/BieuMau'
import { sieuAmService } from '../../../services/SieuAm/sieuAmService'
import { https } from '../../../services/apiService'
import ToastCus from '../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal
const listLoaiBM = [
  {
    key: 1,
    value: 1,
    label: 'Siêu âm',
  },
  {
    key: 2,
    value: 2,
    label: 'Khám sức khỏe',
  },
  {
    key: 4,
    value: 4,
    label: 'X-Quang',
  },
  {
    key: 3,
    value: 3,
    label: 'Nội soi CTC',
  },
  {
    key: 5,
    value: 5,
    label: 'Nội soi TH',
  },
  {
    key: 6,
    value: 6,
    label: 'Nội soi TMH',
  },
]
const BieuMauSA = () => {
  const now = moment()
  const [isloading, setIsLoading] = useState(false)
  const [loaiBieuMau, setLoaiBieuMau] = useState(0)
  const [listBieuMau, setListBieuMau] = useState([])
  const [itemEdit, setItemEdit] = useState(null)
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const cancelModal = () => {
    setOpen(false)
    setItemEdit(null)
  }

  //----------- lấy danh sách loại khám -----------
  const fetchListBieuMau = async () => {
    setIsLoading(true)
    try {
      const { data } = await sieuAmService.getALLBieuMau(
        loaiBieuMau === 0 ? null : loaiBieuMau,
        search || null,
      )
      setListBieuMau(data?.map((item, index) => ({ ...item, stt: ++index })))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleDeleteBM = async (idbm) => {
    try {
      await https.delete(`BieuMau/DeleteBieuMau`, { params: { idbm } })
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
      fetchListBieuMau()
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    }
  }
  const showConfirm = async (record) => {
    confirm({
      title: 'Xóa biểu mẫu',
      icon: <ExclamationCircleFilled />,
      content: `Bạn có muốn xóa biểu mẫu ${record?.tenbieumau ?? ''}`,
      okText: 'Đồng ý', // Thay đổi văn bản nút "OK"
      cancelText: 'Hủy bỏ', // Thay đổi văn bản nút "Cancel"
      onOk() {
        handleDeleteBM(record?.idbm)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
      // render: (text, record, index) => ++index,
    },
    {
      title: 'Tên biểu mẫu',
      dataIndex: 'tenbieumau',
      key: 'tenbieumau',
      width: 350,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'tieude',
      key: 'tieude',
      width: 250,
    },
    {
      title: 'Loại',
      dataIndex: 'idloaibieumau',
      key: 'idloaibieumau',
      width: 100,
      align: 'center',
      render: (text, record, index) =>
        listLoaiBM?.find((item) => item?.value === record?.idloaibieumau).label,
      // (record?.idloaibieumau === 1 && 'Siêu âm') ||
      // (record.idloaibieumau === 2 && 'Khám sức khỏe') ||
      // (record.idloaibieumau === 4 && 'X-Quang') ||
      // (record.idloaibieumau === 3 && 'Nội soi CTC') ||
      // (record.idloaibieumau === 5 && 'Nội soi TH') ||
      // (record.idloaibieumau === 6 && 'Nội soi TMH'),
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      key: 'nguoisua',
      width: 220,
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 'ngaysua',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
      width: 220,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 70,
      render: (text, record, index) => (
        <ul className="flex justify-center gap-2">
          <li>
            <Tooltip title="Chỉnh sửa" color="green">
              <EditOutlined
                onClick={() => {
                  setOpen(true)
                  setItemEdit(record)
                }}
                className="text-xl text-green-500  cursor-pointer"
              />
            </Tooltip>
          </li>
          <li>
            <Tooltip title="Xóa" color="red">
              <CloseOutlined
                onClick={() => {
                  showConfirm(record)
                }}
                className="text-xl text-red-500  cursor-pointer"
              />
            </Tooltip>
          </li>
        </ul>
      ),
    },
  ]
  useEffect(() => {
    fetchListBieuMau()
  }, [loaiBieuMau, search])
  useEffect(() => {
    !open && fetchListBieuMau()
  }, [open])
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-1">
            <label className="font-semibold">Loại biểu mẫu:</label>
            <Select
              disabled={isloading}
              value={loaiBieuMau}
              options={[
                {
                  key: 0,
                  value: 0,
                  label: 'Tất cả',
                },
                ...listLoaiBM,
              ]}
              onChange={setLoaiBieuMau}
              className="w-60"
            />
            <div className="flex w-64">
              <Input
                value={search}
                placeholder="Nhập Tên biểu mẫu"
                onChange={(e) => setSearch(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
          </div>
          <Button
            type="primary"
            className="flex items-center"
            onClick={() => {
              setOpen(true)
              setItemEdit(null)
            }}
          >
            <PlusOutlined />
            &nbsp;Tạo biểu mẫu
          </Button>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            loading={isloading}
            scroll={{ x: 1000 }}
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
            }}
            columns={columns}
            dataSource={listBieuMau}
            bordered
          />
        </ConfigProvider>
        <BieuMau
          cancelModal={cancelModal}
          open={open}
          itemEdit={itemEdit}
          listLoaiBM={listLoaiBM}
        />
      </div>
    </div>
  )
}

export default BieuMauSA
