import React, { useEffect, useRef, useState, useCallback } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import * as typeAction from '../../../store/constants/constants'
import AttachmentIcon from '@mui/icons-material/Attachment'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Input, Tabs, Select, Table, ConfigProvider, notification } from 'antd'
import {
  CloseSquareOutlined,
  DeleteOutlined,
  TruckOutlined,
  SnippetsOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons'
import { Button } from '@mui/material'
import Receive from './Satus/Receive/Receive'
import Pending from './Satus/Pending/Pending'
import Create from './Satus/Create/Create'
import Transfer from './Satus/Transfer/Transfer'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import {
  getInfoKhoDefault,
  getListTonkhoCn_action,
  postPhieuCTYAction,
} from '../../../store/actions/chuyenKhoAction'
import moment from 'moment'
import { chuyenKhoSchema } from '../../../schemas/chuyenKhoSchema'
import ModalDetail from './Modal/ModalDetail'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import * as pdfjsLib from 'pdfjs-dist/build/pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { branchService } from '../../../services/branch/branchService'
import { chuyenKhoService } from '../../../services/ChuyenKho/chuyenKhoService'
import 'moment/locale/vi'
moment.locale('vi')
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker
const columns = [
  {
    key: 1,
    title: 'Thông tin hàng hoá',
    children: [
      {
        title: 'STT',
        dataIndex: 'STT',
        key: 1.4,
        width: 50,
        align: 'center',
        fixed: 'left',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Mã hàng',
        dataIndex: 'MaHangHoa',
        key: 1.2,
        width: 120,
        fixed: 'left',
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Tên hàng',
        dataIndex: 'tenHangHoa',
        key: 1.1,
        width: 250,
        fixed: 'left',
        // sorter: (a, b) => a.age - b.age,
      },

      // {
      //   title: "Số lô",
      //   dataIndex: "SOLO",
      //   key: 1.3,
      //   width: 120,
      //   // sorter: (a, b) => a.age - b.age,
      // },
    ],
  },
  {
    key: 2,
    title: 'Đơn vị chẳn',
    children: [
      {
        title: 'SL',
        dataIndex: 'SLCHAN',
        key: 3.2,
        width: 90,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Đơn vị',
        dataIndex: 'DVCHAN',
        key: 2.2,
        width: 80,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Tồn',
        dataIndex: 'tkChan',
        key: 2.3,
        width: 60,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
    ],
  },
  {
    key: 3,
    title: 'Đơn vị lẻ',
    children: [
      {
        title: 'Quy cách',
        dataIndex: 'QUYCACH',
        key: 3.1,
        width: 150,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'SL',
        dataIndex: 'SLLE',
        key: 3.2,
        width: 90,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Đơn vị',
        dataIndex: 'DVLE',
        key: 3.3,
        width: 80,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Tồn',
        dataIndex: 'tonKho',
        key: 3.4,
        width: 60,
        align: 'center',
        // sorter: (a, b) => a.age - b.age,
      },
    ],
  },

  {
    key: 4,
    title: 'Số lô',
    dataIndex: 'soLo',
    width: 120,
    align: 'center',
  },
  {
    key: 5,
    title: 'Hạn dùng',
    dataIndex: 'hanDung',
    width: 120,
    align: 'center',
  },
  {
    key: 7,
    title: 'MSĐK',
    dataIndex: 'maSoDangKy',
    width: 150,
    align: 'center',
  },
  {
    key: 8,
    title: 'Số HĐ',
    dataIndex: 'soHoaDon',
    width: 120,
    align: 'center',
  },
  {
    key: 9,
    title: 'Đối tác',
    dataIndex: 'tenDoiTac',
    width: 500,
  },
  {
    key: 10,
    title: 'Ghi chú',
    dataIndex: 'ghiChu',
    width: 150,
  },
  {
    key: 6,
    title: '',
    dataIndex: 'action',
    width: 40,
    align: 'center',
    fixed: 'right',
  },
]

const ChuyenKhoCTY = () => {
  const dispatch = useDispatch()
  const id_company = Number(localStorage.getItem('id_company'))
  const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const now = moment()
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const { infoUser } = useSelector((state) => state.userReducer)
  //########### STATE ###############
  const [open, setOpen] = useState(false)
  const [statusUpdate, setStatusUpdate] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [branchNhan, setBranchNhan] = useState([])
  const [date, setDate] = useState(now.format('YYYY-MM-DDTHH:mm:ss'))
  const [selectedFile, setSelectedFile] = useState([])
  const [IDcompanyChuyen, setIDCompanyChuyen] = useState(id_company)
  const [IDcompanyNhan, setIDCompanyNhan] = useState(id_company)
  const [branchsChuyen, setBranchsChuyen] = useState([])
  const [idBranhChuyen, setIdBranhChuyen] = useState(ID_BRANCH_LOGIN)
  const [idBranhNhan, setIdBranhNhan] = useState(ID_BRANCH_LOGIN)
  const [kho, setKho] = useState({ khoChuyen: [], khoNhap: [] })
  //############## STORE ############
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { KhoNhan, KhoVT, TonKho, branchByCompany } = useSelector((state) => state.chuyenKhoReducer)
  // mở modal detail Phiếu
  const showModal = () => {
    setOpen(true)
  }
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  const onChangeCompanyChuyen = async (value) => {
    setIDCompanyChuyen(value)
    const branchs = await fetchBranchs(value)
    setBranchsChuyen(branchs)
    setIdBranhChuyen(null)
    formik.setFieldValue('IdKhoXuat', null)
    dispatch({
      type: typeAction.RESET_KHOVT_CK,
    })
  }
  const onChangeCompanyNhan = async (value) => {
    setIDCompanyNhan(value)
    const branchs = await fetchBranchs(value)
    setBranchNhan(branchs)
    setIdBranhNhan(null)
    formik.setFieldValue('IdKhoNhap', null)
  }
  //-------selected kho ------//
  const handleSelected = (name) => (value) => {
    const { IdKhoNhap, IdKhoXuat } = formik.values
    if (IdKhoNhap === value || IdKhoXuat === value) {
      openNotificationWithIcon('error', 'Chọn kho nhập', 'không thể chọn trùng kho!')
      return
    } else if (name === 'IdKhoXuat') {
      dispatch(getListTonkhoCn_action(value))
    }
    formik.setFieldValue(name, value)
  }
  //-----------onChange branch-------//
  const onChangeBranch = (name) => async (value) => {
    const data = await fetchKho(value)
    setKho((preState) => ({ ...preState, [name]: data }))
    if (name === 'khoChuyen') {
      setIdBranhChuyen(value)
      formik.setFieldValue('IdKhoXuat', null)
      dispatch({
        type: typeAction.RESET_KHOVT_CK,
      })
    } else {
      setIdBranhNhan(value)
      formik.setFieldValue('IdKhoNhap', null)
    }
  }
  //------------ fetch brach by company--------//
  const fetchBranchs = async (id) => {
    try {
      const { data } = await branchService.getListBranch(id)
      return data
    } catch (error) {
      console.log(error)
    }
  }
  //-------------- fetch kho by branch --------//
  const fetchKho = async (id) => {
    try {
      const { data } = await chuyenKhoService.getlistKhoNhan(id)
      return data
    } catch (error) {
      console.log(error)
    }
  }
  // cancel modal Phiếu
  const cancelModal = () => {
    setOpen(false)
  }
  // xử lý lưu  phiếu chuyển kho
  const handleSave = (values, action) => {
    setDate(now.format('YYYY-MM-DDTHH:mm:ss'))
    formik.setFieldValue('NgayXuat', date)
    const data = KhoVT.map((items) => ({
      ...items,
      soLuongLe: items.soLuongLe + items.soLuong * items.thuocVatTu.quyCachDongGoi,
      soLuong: items.soLuong + items.soLuongLe / items.thuocVatTu.quyCachDongGoi,
    }))
    if (data.some((items) => items.soLuongLe === 0)) {
      openNotificationWithIcon('error', 'Lưu phiếu chuyển', 'Số lượng thuốc không được trống!')
      return
    }
    if (selectedFile.length !== 0) {
      const data = selectedFile.map((item) => item.file)
      values.HoaDonFiles = data
    }
    const formData = new FormData()

    for (let key in values) {
      if (key === 'HoaDonFiles') {
        if (values[key] && values[key].length > 0) {
          values[key].forEach((file, index) => {
            formData.append(key, file)
          })
        }
      } else {
        formData.append(key, values[key])
      }
    }
    dispatch(postPhieuCTYAction(formData, data))
    handleResetForm()
  }
  const handleResetForm = () => {
    setSelectedFile([])
    formik.handleReset()
    dispatch({
      type: typeAction.RESET_KHOVT_CK,
    })
  }
  //XỬ LÍ LẤY THUỐC KHO VT
  const handleKhoVT = (value) => {
    for (let items of KhoVT) {
      if (value === items.ID) {
        openNotificationWithIcon('error', 'Thêm thuốc vật tư', 'thuốc vật tư đã được chọn!')
        return
      }
    }
    dispatch({
      type: typeAction.PUSH_THUOC_VT_CHUYEN_KHO,
      payload: value,
    })
  }

  // XỬ LÝ TĂNG SỐ LƯỢNG THUỐC
  const handleChangeSL = (items) => (e) => {
    const value = Number(e.target.value)
    if (value + items.soLuong * items.thuocVatTu.quyCachDongGoi > items.tonKho) {
      openNotificationWithIcon('error', 'Thay đổi số lượng', 'Tồn kho không đủ số lượng')
      return
    }
    dispatch({
      type: typeAction.CHANGE_SL_THUOC_CK,
      payload: {
        ID: items.ID,
        value: value < 0 || value === '' ? 0 : value, // nếu giá trị < 0 or = '' ==> value = 0
      },
    })
  }
  // ----------tăng số lượng chẳn ------------
  const handleSLChan = (items) => (e) => {
    const value = Number(e.target.value)
    if (value * items.thuocVatTu.quyCachDongGoi + items.soLuongLe > items.tonKho) {
      openNotificationWithIcon('error', 'Thay đổi số lượng', 'Tồn kho không đủ số lượng')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_SL_CHAN_CHUYEN_KHO,
      payload: {
        ID: items.ID,
        value: value < 0 || value === '' ? 0 : value, // nếu giá trị < 0 or = '' ==> value = 0
      },
    })
  }
  const onChangeNote = (items) => (e) => {
    const { value } = e.target
    dispatch({
      type: typeAction.DISPATCH_NOTE_CHUYEN_KHO,
      payload: {
        ID: items.ID,
        value: value, // nếu giá trị < 0 or = '' ==> value = 0
      },
    })
  }
  // XỬ LÝ XOÁ 1 THUỐC TRONG KHO
  const handleDeleteKhoVTById = (ID) => {
    dispatch({
      type: typeAction.CLOSE_THUOC_CK_BY_ID,
      payload: ID,
    })
  }
  //--------------- CHOOSE FILE -----------//
  const handleFileChange = async (file) => {
    //let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'application/pdf',
    ]
    if (!allowedFileTypes.includes(file.type)) {
      openNotificationWithIcon('error', 'File không hợp lệ', 'Chỉ chấp nhận file pdf hoặc file ảnh')
      fileInputRef.current.value = '' // clear the input
      return
    }
    if (file.type === 'application/pdf') {
      const pdfImgSrc = await renderPdfAsImage(file)
      fileInputRef.current.value = '' // clear the input
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const localUrl = URL.createObjectURL(file)
        setSelectedFile((preState) => [
          ...preState,
          { base64: pdfImgSrc, URL: localUrl, name: file.name, id: uuidv4(), file },
        ])
      }
    } else {
      // formik.setFieldValue('HoaDonFile', file)
      // console.log(formData);
      fileInputRef.current.value = ''
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const localUrl = URL.createObjectURL(file)
        setSelectedFile((preState) => [
          ...preState,
          { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
        ])
      }
    }
  }
  //------------- RENDER PDF----------//
  const renderPdfAsImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = async (e) => {
        const pdf = await pdfjsLib.getDocument({ data: e.target.result }).promise
        const page = await pdf.getPage(1)
        const viewport = page.getViewport({ scale: 1.5 })
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        canvas.height = viewport.height
        canvas.width = viewport.width

        await page.render({ canvasContext: context, viewport: viewport }).promise
        resolve(canvas.toDataURL())
      }
    })
  }
  //-----------delete file--------------//
  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #cccccc'
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #cccccc'
    handleFileChange(e.dataTransfer.files[0])
    // const files = Array.from(e.dataTransfer.files)
    //  setFileList(files);
  }
  const dropzoneStyle = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '30px',
    textAlign: 'center',
    cursor: 'pointer',
  }
  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleFileSelect = (e) => {
    handleFileChange(e.target.files[0])
  }
  const formik = useFormik({
    initialValues: {
      TenPhieu: '',
      IdKhoXuat: null,
      IdKhoNhap: null,
      NhanVienXuat: infoUser?.idnv,
      NgayXuat: date,
      GhiChu: '',
      // daNhan: 0,
      TrangThai: 1,
      IDCT: id_company,
    },
    onSubmit: (value, action) => {
      handleSave(value, action)
    },
    validationSchema: chuyenKhoSchema,
  })
  const callBranhsAndKho = async () => {
    const branch = await fetchBranchs(id_company)
    setBranchNhan(branch)
    setBranchsChuyen(branch)
    const resKho = await fetchKho(ID_BRANCH_LOGIN)
    setKho({ khoChuyen: resKho, khoNhap: resKho })
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    dispatch(getInfoKhoDefault()) // lấy thông tin mặc của của chi nhánh nhánh tên và list kho chi nhánh
    callBranhsAndKho()
    handleResetForm()
  }, [])
  return (
    <>
      {contextHolder}
      <div className="p-2 bg-[#E7E7E7] h-full">
        <div className="h-full p-3 bg-white">
          <ConfigProvider
            theme={{
              components: {
                Tabs: {
                  cardGutter: 0,
                  itemColor: '#4b6584  ',
                },
              },
            }}
          >
            <Tabs
              type="card"
              items={[
                {
                  label: (
                    <div className="flex items-center gap-2 font-semibold ">
                      {' '}
                      <TruckOutlined style={{ fontSize: 20 }} /> Chuyển kho CTY{' '}
                    </div>
                  ),
                  key: 1,
                  children: (
                    <>
                      <div>
                        <div className="">
                          <div className="flex gap-5"></div>
                          <div className="flex gap-2">
                            <div className="border p-2 rounded w-2/3">
                              <div className="border-b border-dashed pb-5 mb-5">
                                <h2 className="font-semibold text-gray-400 ">Nơi chuyển hàng</h2>
                                <div className="p-2 flex gap-5">
                                  <div className="w-1/3">
                                    <label className=" font-medium  text-base ">
                                      {' '}
                                      Công ty chuyển
                                    </label>
                                    <Select
                                      value={IDcompanyChuyen}
                                      options={listCompany?.map((items) => ({
                                        label: items.tenct,
                                        value: items.idct,
                                      }))}
                                      className="w-full mt-2"
                                      onChange={onChangeCompanyChuyen}
                                    />
                                  </div>
                                  <div className="w-1/3">
                                    <label className=" font-medium text-base">
                                      {' '}
                                      Chi nhánh chuyển
                                    </label>
                                    <Select
                                      filterOption={(input, option) => {
                                        const label = option?.label ?? ''
                                        const searchText = input.toLowerCase()
                                        return label.toLowerCase().includes(searchText)
                                      }}
                                      showSearch
                                      placeholder="Chọn chi nhánh chuyển"
                                      value={idBranhChuyen}
                                      options={branchsChuyen?.map((items) => ({
                                        label: items.tenChiNhanh,
                                        value: items.idChiNhanh,
                                      }))}
                                      className="w-full mt-2"
                                      onChange={onChangeBranch('khoChuyen')}
                                    />
                                  </div>
                                  <div className="w-1/3">
                                    <label className="font-medium text-base">
                                      {' '}
                                      <span className="text-red-500">(*)</span> kho chuyển
                                    </label>
                                    <Select
                                      filterOption={(input, option) => {
                                        const label = option?.label ?? ''
                                        const searchText = input.toLowerCase()
                                        return label.toLowerCase().includes(searchText)
                                      }}
                                      showSearch
                                      name="IdKhoXuat"
                                      status={formik.errors.IdKhoXuat ? 'error' : ''}
                                      placeholder="Chọn kho chuyển"
                                      value={formik.values.IdKhoXuat}
                                      options={kho?.khoChuyen.map((items) => ({
                                        label: items.tenKho,
                                        value: items.idKhoCN,
                                      }))}
                                      className="w-full mt-2"
                                      onChange={handleSelected('IdKhoXuat')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <h2 className="font-semibold text-gray-400 ">Nơi nhận hàng</h2>
                                <div className="p-2 flex gap-5">
                                  <div className="w-1/3">
                                    <label className=" font-medium  text-base ">
                                      {' '}
                                      Công ty nhận
                                    </label>
                                    <Select
                                      defaultValue={IDcompanyNhan}
                                      options={listCompany?.map((items) => ({
                                        label: items.tenct,
                                        value: items.idct,
                                      }))}
                                      className="w-full mt-2"
                                      onChange={onChangeCompanyNhan}
                                    />
                                  </div>
                                  <div className="w-1/3">
                                    <label className=" font-medium text-base">
                                      {' '}
                                      Chi nhánh nhận
                                    </label>
                                    <Select
                                      filterOption={(input, option) => {
                                        const label = option?.label ?? ''
                                        const searchText = input.toLowerCase()
                                        return label.toLowerCase().includes(searchText)
                                      }}
                                      showSearch
                                      placeholder="Chọn chi nhánh nhận"
                                      value={idBranhNhan}
                                      options={branchNhan?.map((items) => ({
                                        label: items.tenChiNhanh,
                                        value: items.idChiNhanh,
                                      }))}
                                      className="w-full mt-2"
                                      onChange={onChangeBranch('khoNhap')}
                                    />
                                  </div>
                                  <div className="w-1/3">
                                    <label className="font-medium text-base">
                                      {' '}
                                      <span className="text-red-500">(*)</span> kho nhận
                                    </label>
                                    <Select
                                      filterOption={(input, option) => {
                                        const label = option?.label ?? ''
                                        const searchText = input.toLowerCase()
                                        return label.toLowerCase().includes(searchText)
                                      }}
                                      showSearch
                                      name="IdKhoNhap"
                                      status={formik.errors.IdKhoNhap ? 'error' : ''}
                                      placeholder="Chọn kho nhận"
                                      value={formik.values.IdKhoNhap}
                                      options={kho?.khoNhap.map((items) => ({
                                        label: items.tenKho,
                                        value: items.idKhoCN,
                                      }))}
                                      className="w-full mt-2"
                                      onChange={handleSelected('IdKhoNhap')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-1/3 border rounded p-2">
                              <h2 className="font-semibold text-gray-400 ">Thông tin phiếu</h2>
                              <div className="p-2">
                                <div className="flex gap-2">
                                  <div className="w-1/2">
                                    <label className="font-medium text-base"> Người chuyển</label>
                                    <Input className="mt-1" readOnly value={infoUser?.tenNV} />
                                  </div>
                                  <div className="w-1/2">
                                    <label className="font-medium"> Ngày Chuyển</label>
                                    <Input
                                      className="mt-1"
                                      readOnly
                                      value={moment(formik.values.NgayXuat).format(
                                        'DD/MM/YYYY HH:mm:ss ',
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="mt-1">
                                  <label className="font-medium"> Tên phiếu</label>
                                  <Input
                                    className="mt-1"
                                    name="TenPhieu"
                                    value={formik.values.TenPhieu}
                                    onChange={formik.handleChange}
                                  />
                                </div>
                                <div className="mt-1">
                                  <label className=" font-medium"> Ghi chú</label>
                                  <Input.TextArea
                                    value={formik.values.GhiChu}
                                    name="GhiChu"
                                    onChange={formik.handleChange}
                                    showCount
                                    maxLength={300}
                                    style={{
                                      height: 60,
                                      resize: 'none',
                                    }}
                                    size="small"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <Tabs
                            items={[
                              {
                                label: 'Hàng hoá',
                                key: 1,
                                children: (
                                  <>
                                    <div className="flex mb-2">
                                      <Select
                                        className="w-full"
                                        showSearch
                                        filterOption={(input, option) => {
                                          const label = option?.label ?? ''
                                          const soHoaDon = option?.soHoaDon ?? ''
                                          const soLo = option?.soLo ?? ''
                                          const maHang = option.maThuoc ?? ''
                                          const searchText = input.toLowerCase()
                                          return (
                                            label.toLowerCase().includes(searchText) ||
                                            soHoaDon.toLowerCase().includes(searchText) ||
                                            soLo.toLowerCase().includes(searchText) ||
                                            maHang.toLowerCase().includes(searchText)
                                          )
                                        }}
                                        value={null}
                                        placeholder="Nhập tên hàng hoá, số hoá đơn, số lố, mã hàng"
                                        onChange={handleKhoVT}
                                        defaultActiveFirstOption={false}
                                        // onSearch={debounceDropDown}
                                        notFoundContent={null}
                                        options={TonKho?.map((d) => ({
                                          value: d.ID,
                                          label: d.thuocVatTu?.tenBietDuoc,
                                          maThuoc: d.thuocVatTu?.maThuoc,
                                          hoatChat: d.thuocVatTu?.tenHoatChat,
                                          tonKho: d.tonKho,
                                          handung: d.hanDung,
                                          soHoaDon: d.soHoaDon,
                                          soLo: d.soLo,
                                        }))}
                                        optionRender={(options) => (
                                          <ul className="flex">
                                            {/* <li className="w-1/12">{options.data.maThuoc}</li> */}
                                            <li className="w-8/12  text-pretty ">
                                              <p className="font-semibold">
                                                {' '}
                                                {options.data.label} - {options.data.maThuoc}
                                              </p>
                                              <i className="text-gray-400 text-sm">
                                                {options.data.hoatChat}
                                              </i>
                                            </li>
                                            <li className="w-2/12 ">
                                              <p className="font-semibold">
                                                Số hoá đơn: {options.data.soHoaDon}
                                              </p>
                                              <i className="text-gray-400">
                                                {' '}
                                                Số lô: {options.data.soLo}
                                              </i>
                                            </li>
                                            <li className="w-2/12 ">
                                              <p className="font-semibold">
                                                Số lượng:{' '}
                                                <span className="text-blue-500">
                                                  {options.data.tonKho}
                                                </span>
                                              </p>
                                              <i className="text-gray-400">
                                                {' '}
                                                Hạn dùng:{' '}
                                                {options.data.handung &&
                                                  moment(options.data.handung).format('DD/MM/YYYY')}
                                              </i>
                                            </li>
                                          </ul>
                                        )}
                                      />
                                    </div>
                                    <div className="rounded-lg  border-1 ">
                                      <ConfigProvider
                                        theme={{
                                          token: {
                                            padding: 3,
                                            borderRadius: 0,
                                          },
                                          components: {
                                            Table: {
                                              rowHoverBg: '#ecf0f1',
                                            },
                                          },
                                        }}
                                      >
                                        <Table
                                          scroll={{
                                            x: 1500,
                                            y: 400,
                                          }}
                                          pagination={false}
                                          bordered
                                          columns={columns}
                                          dataSource={KhoVT?.map((items, index) => ({
                                            STT: ++index,
                                            tenHangHoa: items.thuocVatTu?.tenBietDuoc,
                                            MaHangHoa: items.thuocVatTu?.maThuoc,
                                            tonKho:
                                              items.tonKho -
                                              (items.thuocVatTu.quyCachDongGoi * items.soLuong +
                                                items.soLuongLe),
                                            soLo: items.soLo,
                                            SLCHAN: (
                                              <Input
                                                size="small"
                                                onChange={handleSLChan(items)}
                                                value={items.soLuong}
                                                type="number"
                                              />
                                            ),
                                            tkChan: Math.floor(
                                              items.tonKho / items.thuocVatTu.quyCachDongGoi -
                                                items.soLuong -
                                                items.soLuongLe / items.thuocVatTu.quyCachDongGoi,
                                            ),
                                            DVCHAN: items.thuocVatTu.donViChan,
                                            QUYCACH: items.thuocVatTu.quyCach,
                                            tenDoiTac: items.tenDoiTac,
                                            soHoaDon: items.soHoaDon,
                                            maSoDangKy: items.maSoDangKy,
                                            hanDung:
                                              items.hanDung &&
                                              moment(items.hanDung).format('DD/MM/YYYY'),
                                            SLLE: (
                                              <Input
                                                size="small"
                                                onChange={handleChangeSL(items)}
                                                value={items.soLuongLe}
                                                type="number"
                                              />
                                            ),
                                            DVLE: items.thuocVatTu.dvt,
                                            ghiChu: (
                                              <Input
                                                size="small"
                                                onChange={onChangeNote(items)}
                                                value={items.GhiChu}
                                              />
                                            ),
                                            action: (
                                              <div
                                                onClick={() => {
                                                  handleDeleteKhoVTById(items.ID)
                                                }}
                                              >
                                                <CloseSquareOutlined
                                                  style={{ fontSize: 18 }}
                                                  className="text-red-500 cursor-pointer"
                                                />
                                              </div>
                                            ),
                                          }))}
                                        />
                                      </ConfigProvider>
                                    </div>
                                  </>
                                ),
                              },
                              {
                                label: 'Đính kèm',
                                key: 2,
                                children: (
                                  <>
                                    <div>
                                      <ul className="flex flex-wrap gap-2 mt-2">
                                        {selectedFile.map((items) => (
                                          <li className="w-1/6 h-32 w-32 overflow-hidden border rounded-md relative">
                                            <DeleteOutlined
                                              onClick={() => deleteFileById(items.id)}
                                              className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                                              style={{ fontSize: 18 }}
                                            />
                                            <a href={items.URL} target="_blank">
                                              <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                                                <div className="relative "></div>
                                                <p className="absolute text-black bottom-0 truncate w-full p-1 bg-white left-0 z-10">
                                                  {items.name}
                                                </p>
                                              </div>
                                              <img
                                                src={items.base64}
                                                className="w-full"
                                                alt={items.name}
                                              />
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                    <div
                                      style={dropzoneStyle}
                                      className=" mt-2 border"
                                      ref={dropzoneRef}
                                      onDragOver={handleDragOver}
                                      onDragLeave={handleDragLeave}
                                      onDrop={handleDrop}
                                      onClick={handleDivClick}
                                    >
                                      <CloudUploadOutlined
                                        style={{ fontSize: 36 }}
                                        className="text-gray-500"
                                      />
                                      <label style={{ cursor: 'pointer' }}>
                                        <p className="font-semibold text-lg text-gray-500">
                                          Thả tập tin vào đây hoặc bấm vào để tải lên.
                                        </p>
                                      </label>

                                      {/* <label for="file-upload" className={style.custom_file_upload}>
          <CloudUploadOutlined />
          Chọn Hình ảnh
        </label> */}
                                      <input
                                        ref={fileInputRef}
                                        id="fileInput"
                                        type="file"
                                        onChange={handleFileSelect}
                                      />
                                    </div>
                                  </>
                                ),
                              },
                            ]}
                          />
                        </div>
                        <div className="flex gap-2 justify-end mt-5">
                          <div className="flex gap-2">
                            <Button
                              // onClick={saveDocx}
                              className="w-32"
                              onClick={formik.handleSubmit}
                              size="small"
                              color="success"
                              variant="contained"
                              disabled={KhoVT.length === 0}
                            >
                              Lưu phiếu
                            </Button>
                            <Button
                              onClick={handleResetForm}
                              type="button"
                              className="w-32"
                              size="small"
                              color="primary"
                              variant="outlined"
                            >
                              Làm mới
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                },
                {
                  label: (
                    <div className="font-semibold flex gap-2 items-center">
                      <SnippetsOutlined style={{ fontSize: 20 }} />
                      Phiếu chuyển
                    </div>
                  ),
                  key: 2,
                  children: (
                    <Tabs
                      className="h-full"
                      tabPosition="left"
                      defaultActiveKey="1"
                      type="line"
                      items={[
                        {
                          label: 'Phiếu tạo',
                          key: 1,
                          children: (
                            <Create
                              showModal={showModal}
                              cancelModal={cancelModal}
                              setStatusUpdate={setStatusUpdate}
                            />
                          ),
                        },
                        {
                          label: 'Phiếu đã rời',
                          key: 2,
                          children: (
                            <Transfer showModal={showModal} setStatusUpdate={setStatusUpdate} />
                          ),
                        },
                        {
                          label: 'Phiếu chờ xác nhận',
                          key: 3,
                          children: (
                            <Pending showModal={showModal} setStatusUpdate={setStatusUpdate} />
                          ),
                        },

                        {
                          label: 'Phiếu đã nhận',
                          key: 4,
                          children: (
                            <Receive showModal={showModal} setStatusUpdate={setStatusUpdate} />
                          ),
                        },
                      ]}
                    />
                  ),
                },
              ]}
            />
          </ConfigProvider>
        </div>
      </div>
      <ModalDetail
        open={open}
        showModal={showModal}
        cancelModal={cancelModal}
        statusUpdate={statusUpdate}
      />
    </>
  )
}

export default ChuyenKhoCTY
