import { https } from '../apiService'

export const dichVuService = {
  getAllListDichVu: () => https.get(`DichVu/Mobile/GetAllDichVu`),
  getListDichVu: (idNhomdv, idct) =>
    https.get(`DichVu/GetDichVuByIdNhomDVAndIDCT?idnhomdv=${idNhomdv}&idct=${idct}`),
  getListGroupsDichVu: () => https.get('DichVu/GetAllDichVuNhom'),
  searchByKeyword: (idnhomdv, idct, keyword) =>
    https.get(`DichVu/SearchDichVuByIDCT`, {
      params: {
        idnhomdv,
        idct,
        keyword,
      },
    }),
  addDichVu: (form) => https.post(`DichVu/AddDichVu`, form),
  editDichVu: (form) => https.put(`DichVu/UpdateDichVu?iddv=${form.iddv}`, form),
  deleteDichVu: (iddv) => https.delete(`DichVu/DeleteDichVu?iddv=${iddv}`),
  fetchInfoDV: (iddv) => https.get(`DichVu/GetDichVuByIDDV?iddv=${iddv}`),
  getListDichVuByIdNhom: (idNhomDv) => https.get(`DichVu/GetDichVuByIdNhomDV?idNhomDv=${idNhomDv}`),
  postGiaVatTuChoDVThuThuat: (form) =>
    https.post(`DichVuThuThuatHoaHong/AddDichVuThuThuatHoaHong`, form),
  getListGiaVatTuDVThuThuat: (idct, idchuyenkhoa, keyword) =>
    https.get(`DichVuThuThuatHoaHong/GetAllDichVuThuThuatHoaHong`, {
      params: { idct, idchuyenkhoa, keyword },
    }),
  putGiaVatTuChoDVThuThuat: (form) =>
    https.put(`DichVuThuThuatHoaHong/UpdateDichVuThuThuatHoaHong`, form),
  deleteGiaVatTuChoDVThuThuat: (idhh) =>
    https.delete(`DichVuThuThuatHoaHong/DeleteDichVuThuThuatHoaHong?idhh=${idhh}`),
  getListDichVuThuThuatChuaAddGVT: (idct) =>
    https.get(`DichVuThuThuatHoaHong/GetDichVuThuThuat`, {
      params: { idct },
    }),
  getListDichVuGiaChiNhanhAllNhom: (idChiNhanh, idct) =>
    https.get(`DichVuChiNhanh/GetAllDichVuChiNhanh`, {
      params: { idChiNhanh, idct },
    }),
  putListChiTietGoiKham: (idgk, list) =>
    https.put(`GoiKham/UpdateGoiKhamChiTiet?idgk=${idgk}`, list),
  getInfoGoiKham: (idgk) => https.get(`GoiKham/GetInfoGoiKham`, { params: { idgk } }),
  getListDichVuChuyenKhoa: (idchuyenkhoa) =>
    https.get(`DichVu/GetDichVuChuyenKhoa`, { params: { idchuyenkhoa } }),
  putSuDungListDichVuChuyenKhoa: (idchuyenkhoa, sudung, list) =>
    https.put(
      `DichVu/UpdateSuDungDichVuChuyenKhoa?idchuyenkhoa=${idchuyenkhoa}&sudung=${sudung}`,
      list,
    ),
}
