import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  ConfigProvider,
  Input,
  Pagination,
  Table,
  Tabs,
  Modal,
  Select,
  Tooltip,
  DatePicker,
} from 'antd'
import {
  SearchOutlined,
  PlusOutlined,
  CheckOutlined,
  FilterOutlined,
  ContainerOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import { https } from '../../../../services/apiService'
import { phanAnhServices } from '../../../../services/phanAnh/phanAnhServices'
import { postListXuLyAction, putDaXuLyPAKHAction } from '../../../../store/actions/phanAnhAction'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import ToastCus from '../../../../utils/Toast'
import style from '../style.module.css'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal

function TabPhanAnhKhachHang() {
  const dispatch = useDispatch()
  const now = moment()
  const dateFormat = 'DD-MM-YYYY'
  const momentDate = 'YYYY-MM-DD'
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { listBranch, PkDangNhap } = useSelector((state) => state.branchReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [Branch, setBranch] = useState('all')
  const [Company, setCompany] = useState(ID_COMPANY)
  const [isLoading, setIsLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [isModal, setIsModal] = useState(false)
  const [listPAKH, setListPAKH] = useState([])
  const [infoPAKH, setInfoPAKH] = useState(null)
  const [listXLPA, setListXLPA] = useState([])
  const [infoXLPA, setInfoXLPA] = useState(null)
  const [listBoPhanXuLy, setListBoPhanXuLy] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [noiDungXuLy, setNoiDungXuLy] = useState(EditorState.createEmpty())

  const onEditorStateChange = (newState) => {
    setEditorState(newState)
  }
  const handleSubmit = () => {
    if (infoPAKH?.IDTRANGTHAI === 3 || infoPAKH?.IDTRANGTHAI === 4) setIsModal(false)
    if (listXLPA?.find((item) => !item?.bophanxuly)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    const listAdd = listXLPA?.filter((item) => item?.new)
    if (listAdd?.length) {
      dispatch(
        postListXuLyAction(
          listAdd,
          infoPAKH,
          getInfoPhanAnh,
          getListPhanAnhKhachHang,
          setIsLoading,
        ),
      )
    } else setIsModal(false)
  }
  const handleDaXuLyPhanAnh = () => {
    dispatch(putDaXuLyPAKHAction(infoPAKH, getInfoPhanAnh, getListPhanAnhKhachHang, setIsLoading))
  }
  const handleChangeBoPhanXL = (value, index) => {
    setListXLPA((prev) =>
      prev?.map((item, idx) => (idx === index ? { ...item, bophanxuly: value } : item)),
    )
  }
  const handleChangeGhiChu = (value, index) => {
    setListXLPA((prev) =>
      prev?.map((item, idx) =>
        idx === index ? { ...item, ghichu: value?.length > 0 ? value?.trim() : '' } : item,
      ),
    )
  }
  //-------- lấy thông tin phản ảnh khách hàng -------//
  const getInfoPhanAnh = async (idpakh) => {
    try {
      setIsLoading(true)
      const { data } = await phanAnhServices.getInfoPhanAnh(idpakh)
      setInfoPAKH(data)
      setListXLPA(
        data?.DanhSachXuLyPhanAnh?.map((item) => ({
          ...item,
          tennguoitao: item?.nguoitao,
          tenbophantiepnhan: item?.bophantiepnhan,
          tennguoisua: item?.nguoisua,
        })),
      )
      if (data?.NOIDUNG) {
        const convertJson = JSON.parse(data?.NOIDUNG)
        const contentState = convertFromRaw(convertJson)
        setEditorState(EditorState.createWithContent(contentState))
      } else {
        setEditorState(null)
      }
      await getListBoPhanXuLy()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  //-------- lấy danh sách phản ảnh khách hàng -------//
  const getListPhanAnhKhachHang = async () => {
    // const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
    // console.log(userInfo)
    try {
      setIsLoading(true)
      const { data } = await phanAnhServices.getListPhanAnhKhachHang(
        keyword,
        Branch === 'all' ? null : Branch,
        Company,
        infoUser?.idChiNhanh, // chi nhánh bộ phận của user
        dateForm,
        dateTo,
        //loai phản ảnh
        //NGUOITIEPNHAN // id chi nhánh người tiếp nhận
      )
      setListPAKH(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  //-------- lấy danh sách bộ phận xử lý phản ảnh -------//
  const getListBoPhanXuLy = async () => {
    try {
      const { data } = await phanAnhServices.getListBoPhanXuLy()
      setListBoPhanXuLy(data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  useEffect(() => {
    if (infoUser?.idChiNhanh) getListPhanAnhKhachHang()
  }, [infoUser?.idChiNhanh])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 45,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaytao ?? '').localeCompare(b?.ngaytao ?? '')
        },
      },
    },
    {
      title: 'Mã phản ảnh',
      dataIndex: 'mapakh',
      key: 'mapakh',
      width: 120,
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.mapakh ?? '').localeCompare(b?.mapakh ?? '')
        },
      },
    },
    {
      title: 'Tên phản ảnh',
      dataIndex: 'tenpakh',
      key: 'tenpakh',
      // width: 350,
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tenpakh ?? '').localeCompare(b?.tenpakh ?? '')
        },
      },
    },
    {
      title: 'Chi nhánh phản ảnh',
      dataIndex: 'chinhanhphananh',
      key: 'chinhanhphananh',
      width: 300,
      sorter: {
        compare: (a, b) => {
          return (a?.chinhanhphananh ?? '').localeCompare(b?.chinhanhphananh ?? '')
        },
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
      width: 220,
      sorter: {
        compare: (a, b) => {
          return (a?.nguoitao ?? '').localeCompare(b?.nguoitao ?? '')
        },
      },
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      key: 'nguoisua',
      width: 220,
      sorter: {
        compare: (a, b) => {
          return (a?.nguoisua ?? '').localeCompare(b?.nguoisua ?? '')
        },
      },
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 'ngaysua',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysua ?? '').localeCompare(b?.ngaysua ?? '')
        },
      },
    },
    {
      title: 'Loại phản ảnh',
      dataIndex: 'tenloaipakh',
      key: 'tenloaipakh',
      width: 200,
      // align: 'center',
      fixed: 'right',
      sorter: {
        compare: (a, b) => {
          return (a?.tenloaipakh ?? '').localeCompare(b?.tenloaipakh ?? '')
        },
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangthai',
      key: 'trangthai',
      width: 120,
      align: 'center',
      fixed: 'right',
      filters: [
        {
          text: 'Chờ xác nhận',
          value: 1,
        },
        {
          text: 'Đang xử lý',
          value: 2,
        },
        {
          text: 'Đã xử lý',
          value: 3,
        },
        {
          text: 'Hoàn thành',
          value: 4,
        },
      ],
      onFilter: (value, record) => record?.idtrangthai === value,
      sorter: {
        compare: (a, b) => {
          return (a?.trangthai ?? '').localeCompare(b?.trangthai ?? '')
        },
      },
      render: (text, record, index) => (
        <>
          <span
            className={`px-2 py-1 rounded-full text-xs font-medium ${(record?.idtrangthai === 1 && 'bg-amber-200 text-amber-800') || (record?.idtrangthai === 2 && 'bg-sky-200 text-sky-800') || (record?.idtrangthai === 3 && 'bg-indigo-200 text-indigo-800') || (record?.idtrangthai === 4 && 'bg-emerald-200 text-emerald-800')}`}
          >
            {text}
          </span>
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 45,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <ul className="flex justify-center items-center gap-2">
          <li className="flex justify-center items-center">
            <ContainerOutlined
              onClick={() => {
                setIsModal(true)
                getInfoPhanAnh(record?.idpakh)
              }}
              className="text-xl text-blue-500 cursor-pointer"
            />
          </li>
        </ul>
      ),
    },
  ]
  const columnsXL = [
    {
      title: 'STT',
      dataIndex: '',
      key: '',
      width: 35,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      width: 130,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaytao ?? '').localeCompare(b?.ngaytao ?? '')
        },
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'tennguoitao',
      key: 'tennguoitao',
      width: 180,
      sorter: {
        compare: (a, b) => {
          return (a?.tennguoitao ?? '').localeCompare(b?.tennguoitao ?? '')
        },
      },
    },
    {
      title: 'Bộ phận tiếp nhận',
      dataIndex: 'tenbophantiepnhan',
      key: 'tenbophantiepnhan',
      width: 200,
      sorter: {
        compare: (a, b) => {
          return (a?.tenbophantiepnhan ?? '').localeCompare(b?.tenbophantiepnhan ?? '')
        },
      },
    },
    {
      title: 'Bộ phận xử lý',
      dataIndex: 'tenbophanxuly',
      key: 'tenbophanxuly',
      width: 200,
      sorter: {
        compare: (a, b) => {
          return (a?.tenbophanxuly ?? '').localeCompare(b?.tenbophanxuly ?? '')
        },
      },
      render: (text, record, index) => (
        <>
          {record?.idxl ? (
            record?.bophanxuly
          ) : (
            <Select
              size="small"
              className="w-full"
              status={!record?.bophanxuly ? 'error' : ''}
              value={record?.bophanxuly}
              onChange={(value) => handleChangeBoPhanXL(value, index)}
              options={listBoPhanXuLy?.map((item) => ({
                value: item?.idChiNhanh,
                label: item?.tenChiNhanh,
              }))}
            />
          )}
        </>
      ),
    },
    {
      title: 'Ngày xử lý',
      dataIndex: 'ngayxuly',
      key: 'ngayxuly',
      width: 130,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngayxuly ?? '').localeCompare(b?.ngayxuly ?? '')
        },
      },
    },
    {
      title: 'Người sửa',
      dataIndex: 'tennguoisua',
      key: 'tennguoisua',
      width: 200,
      sorter: {
        compare: (a, b) => {
          return (a?.tennguoisua ?? '').localeCompare(b?.tennguoisua ?? '')
        },
      },
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 'ngaysua',
      width: 130,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysua ?? '').localeCompare(b?.ngaysua ?? '')
        },
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 200,
      render: (text, record, index) => (
        <>
          {record?.idxl ? (
            text
          ) : (
            <Input
              size="small"
              className="w-full"
              value={record?.ghichu}
              onChange={(e) => handleChangeGhiChu(e.target.value, index)}
            />
          )}
        </>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangthaixuly',
      key: 'trangthaixuly',
      width: 110,
      align: 'center',
      fixed: 'right',
      sorter: {
        compare: (a, b) => {
          return (a?.trangthaixuly ?? '').localeCompare(b?.trangthaixuly ?? '')
        },
      },
      render: (text, record, index) => (
        <>
          <span
            className={`px-2 py-1 rounded-full text-xs font-medium ${(record?.idtrangthaixuly === 1 && 'bg-amber-200 text-amber-800') || (record?.idtrangthaixuly === 2 && 'bg-sky-200 text-sky-800') || (record?.idtrangthaixuly === 3 && 'bg-indigo-200 text-indigo-800') || (record?.idtrangthaixuly === 4 && 'bg-emerald-200 text-emerald-800')}`}
          >
            {text}
          </span>
        </>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 35,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <ul className="flex justify-center items-center gap-2">
          <li className="flex justify-center items-center">
            {record?.idxl && (
              <ContainerOutlined
                onClick={() => {
                  setInfoXLPA(record)
                }}
                className="text-xl text-blue-500 cursor-pointer"
              />
            )}
            {record?.new && (
              <CloseOutlined
                onClick={() => {
                  setListXLPA((prev) => prev?.filter((item, idx) => idx !== index))
                }}
                className="text-xl text-red-500 cursor-pointer"
              />
            )}
          </li>
        </ul>
      ),
    },
  ]
  return (
    <div>
      <Modal
        loading={isLoading}
        width={1100}
        open={isModal}
        onCancel={() => setIsModal(false)}
        onOk={handleSubmit}
        okText="Lưu"
        cancelText="Huỷ bỏ"
        title={<p className="text-center">Phản ảnh khách hàng</p>}
        className="flex flex-col gap-2"
      >
        <div className="grid grid-flow-row gap-2">
          <div className="grid grid-cols-3">
            <div className="grid grid-cols-3 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Mã phản ảnh:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.MAPAKH} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Tên phản ảnh:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.TENPAKH} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Loại phản ảnh:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.TENLOAIPAKH} />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="grid grid-cols-3 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Tên khách hàng:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.NGUOIPHANANH} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Số điện thoại:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.SODIENTHOAI} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Bộ phận xử lý:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.NGUOITIEPNHAN} />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="col-span-2 grid grid-cols-6 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Địa chỉ:</label>
              </div>
              <div className="col-span-5">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.DIACHI} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Người tiếp nhận:</label>
              </div>
              <div className="col-span-2">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.NGUOITAO} />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="col-span-2 grid grid-cols-6 pr-1 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Ghi chú:</label>
              </div>
              <div className="col-span-5">
                <Input size="small" variant="filled" readOnly value={infoPAKH?.GHICHU} />
              </div>
            </div>
            <div className="grid grid-cols-3 pl-2 items-center">
              <div className="font-semibold text-end pr-1">
                <label>Ngày tiếp nhận:</label>
              </div>
              <div className="col-span-2">
                <Input
                  size="small"
                  variant="filled"
                  readOnly
                  value={
                    infoPAKH?.NGAYTAO && moment(infoPAKH?.NGAYTAO).format('DD/MM/YYYY HH:mm:ss')
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Tabs
          items={[
            {
              key: 1,
              label: 'Xử lý',
              children: (
                <>
                  <div className="w-full flex items-center justify-between mb-2">
                    <Button
                      disabled={infoPAKH?.IDTRANGTHAI === 3 || infoPAKH?.IDTRANGTHAI === 4}
                      icon={<PlusOutlined style={{ fontSize: 16 }} />}
                      type="primary"
                      ghost
                      onClick={() =>
                        setListXLPA((prev) => [
                          {
                            idpakh: infoPAKH?.IDPAKH,
                            ngaytao: moment(),
                            nguoitao: infoUser?.idnv,
                            tennguoitao: infoUser?.tenNV,
                            bophantiepnhan: infoUser?.idChiNhanh,
                            tenbophantiepnhan: infoUser?.tenChiNhanh,
                            bophanxuly: null,
                            ghichu: '',
                            new: 1,
                          },
                          ...prev,
                        ])
                      }
                    >
                      Thêm xử lý
                    </Button>
                    <Button
                      disabled={
                        infoPAKH?.IDTRANGTHAI !== 2 ||
                        infoPAKH?.DanhSachXuLyPhanAnh?.find(
                          (item) => item?.idtrangthaixuly === 1 || item?.idtrangthaixuly === 2,
                        ) ||
                        listXLPA?.find((item) => item?.new)
                      }
                      icon={<CheckOutlined style={{ fontSize: 16 }} />}
                      type="primary"
                      onClick={handleDaXuLyPhanAnh}
                    >
                      Đã xử lý
                    </Button>
                  </div>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 3,
                        fontSize: 13,
                      },
                    }}
                  >
                    <Table
                      scroll={{ x: 0, y: 155 }}
                      pagination={false}
                      loading={isLoading}
                      columns={columnsXL}
                      dataSource={listXLPA}
                      bordered
                      rowClassName={(record) =>
                        record?.idxl && record?.idxl === infoXLPA?.idxl
                          ? 'bg-sky-200 bg-opacity-50'
                          : 'cursor-pointer'
                      }
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            if (record?.idxl == infoXLPA?.idxl) setInfoXLPA(null)
                            else {
                              setInfoXLPA(record)
                              if (record?.noidung) {
                                const convertJson = JSON.parse(record?.noidung)
                                const contentState = convertFromRaw(convertJson)
                                setNoiDungXuLy(EditorState.createWithContent(contentState))
                              } else {
                                setNoiDungXuLy(null)
                              }
                            }
                          }, // click row
                        }
                      }}
                    />
                  </ConfigProvider>
                  {infoXLPA?.idxl && (
                    <>
                      <ul className="flex flex-wrap gap-2 mt-2">
                        {infoXLPA?.hinhanh?.split(';')?.map((url, index) => {
                          return (
                            url?.length && (
                              <li
                                key={`hinh${index}:${url}`}
                                className="w-[6.5rem] h-[6.5rem] overflow-hidden border rounded-md relative"
                              >
                                {/* <DeleteOutlined
                    // onClick={() => deleteFileById(items.id)}
                    className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                    style={{ fontSize: 18 }}
                  /> */}
                                <a href={url} target="_blank">
                                  <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                                    <div className="relative "></div>
                                    {/* <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                            {`${infoPhieuXuat?.maphieu}-Hinh${++index}`}
                          </p> */}
                                  </div>
                                  <img src={url} className="w-full" alt={url} />
                                </a>
                              </li>
                            )
                          )
                        })}
                      </ul>
                      <div className="h-[25rem] p-2 border border-gray-400 mt-2">
                        <Editor
                          editorState={noiDungXuLy}
                          //onEditorStateChange={onEditorStateChange}
                          toolbar={{
                            options: [
                              'inline',
                              'blockType',
                              'fontFamily',
                              'fontSize',
                              'textAlign',
                              'colorPicker',
                            ],
                            inline: {
                              options: ['bold', 'italic', 'underline'],
                            },
                            blockType: {
                              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                            },
                            fontFamily: {
                              options: ['Times New Roman'],
                            },
                            fontSize: {
                              options: [14, 16, 18, 24, 30],
                            },
                          }}
                          toolbarClassName={style.toolbarClassName}
                          editorClassName={style.editorClassNameModal}
                        />
                      </div>
                    </>
                  )}
                </>
              ),
            },
            {
              key: 2,
              label: 'Thông tin',
              children: (
                <>
                  <div className="mb-2">
                    <ul className="flex flex-wrap gap-2">
                      {infoPAKH?.HINHANH?.split(';')?.map((url, index) => {
                        return (
                          url?.length && (
                            <li
                              key={`hinh${index}:${url}`}
                              className="w-[6.5rem] h-[6.5rem] overflow-hidden border rounded-md relative"
                            >
                              {/* <DeleteOutlined
                    // onClick={() => deleteFileById(items.id)}
                    className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                    style={{ fontSize: 18 }}
                  /> */}
                              <a href={url} target="_blank">
                                <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                                  <div className="relative "></div>
                                  {/* <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                            {`${infoPhieuXuat?.maphieu}-Hinh${++index}`}
                          </p> */}
                                </div>
                                <img src={url} className="w-full" alt={url} />
                              </a>
                            </li>
                          )
                        )
                      })}
                    </ul>
                    <div className="h-[25rem] p-2 border border-gray-400 mt-2">
                      <Editor
                        editorState={editorState}
                        //onEditorStateChange={onEditorStateChange}
                        toolbar={{
                          options: [
                            'inline',
                            'blockType',
                            'fontFamily',
                            'fontSize',
                            'textAlign',
                            'colorPicker',
                          ],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                          },
                          blockType: {
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                          },
                          fontFamily: {
                            options: ['Times New Roman'],
                          },
                          fontSize: {
                            options: [14, 16, 18, 24, 30],
                          },
                        }}
                        toolbarClassName={style.toolbarClassName}
                        editorClassName={style.editorClassNameModal}
                      />
                    </div>
                  </div>
                </>
              ),
            },
          ]}
        />
      </Modal>
      <div className="flex justify-between items-center mb-2">
        <div className="flex justify-start items-center gap-2">
          <div className="flex items-center gap-2">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<FilterOutlined />}
            defaultValue={ID_COMPANY}
            options={listCompany?.map((items) => ({
              label: items.tenct,
              value: items.idct,
            }))}
            className="w-[17rem]"
            value={Company}
            onChange={(value) => {
              setCompany(value)
              dispatch(listBranchAction(value))
              setBranch('all')
            }}
          />
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<FilterOutlined />}
            defaultValue={'all'}
            value={Branch}
            options={[
              { label: 'Tất cả', value: 'all' },
              ...(listBranch
                ? listBranch.map((items) => ({
                    label: items.tenChiNhanh,
                    value: items.idChiNhanh,
                  }))
                : []),
            ]}
            className="w-[17rem]"
            onChange={(value) => setBranch(value)}
          />
          <Input
            placeholder="Nhập từ khóa..."
            prefix={<SearchOutlined className="pr-1" />}
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
            value={keyword}
            className="w-[17rem]"
          />
          <IconButton
            onClick={() => {
              setKeyword((prev) => (prev?.length > 0 ? prev?.trim() : prev))
              getListPhanAnhKhachHang()
            }}
            disabled={isLoading}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        {/* <Button
            type="primary"
            className="flex justify-center items-center"
            onClick={() => {
              //   setIsModal(true)
            }}
          >
            <PlusOutlined />
            &nbsp;Thêm
          </Button> */}
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
        }}
      >
        <Table
          scroll={{ x: 2000, y: 715 }}
          // pagination={{
          //   pageSize: 20,
          //   showSizeChanger: false,
          // }}
          pagination={false}
          loading={isLoading}
          columns={columns}
          dataSource={listPAKH}
          bordered
        />
      </ConfigProvider>
    </div>
  )
}

export default TabPhanAnhKhachHang
