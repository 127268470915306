import * as typeAction from '../constants/constants'
export const postInfoBnNoiSoiAction = (form, resetForm) => ({
  type: typeAction.POST_INFO_BN_NOISOI,
  form,
  resetForm,
})
export const putInfoBnNoiSoiAction = (form, isPrint, resetForm) => ({
  type: typeAction.PUT_INFO_BN_NOISOI,
  form,
  isPrint,
  resetForm,
})
