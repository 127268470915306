import { Button, Input, Modal, InputNumber } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { formattedNumber } from '../../../../utils/formattedNumber'
const ModalPay = ({ isModalPay, setIsModalPay, form, setForm, onSubmit, setIsPrint }) => {
  const handleCancel = () => {
    setIsModalPay(false)
  }
  const { paymentCard } = useSelector((state) => state.receiveReducer)
  // kiển tra tổng giá có vược quá giá thanh toán hay không
  const checkValuePrice = (total, name, value) => {
    if (total > form.tienthanhtoan) {
      setForm((prevState) => ({ ...prevState, [name]: 0 }))
    } else {
      setForm((prevState) => ({ ...prevState, [name]: value }))
    }
  }

  const submit = (isPrint) => {
    onSubmit()
    setIsPrint(isPrint)
    handleCancel()
  }
  const onChangeInput = (name, value) => {
    // const { name, value } = e.target
    const newValue = Number(value)
    // setForm({ ...form, [name]: newValue });
    switch (name) {
      case 'tienmat':
        const total_0 =
          newValue +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_0, name, newValue)
        break
      case 'chuyenkhoan':
        const total_1 =
          form.tienmat + newValue + form.pos + form.momo + form.vnpay + form.zalopay + form.thett
        checkValuePrice(total_1, name, newValue)
        break
      case 'momo':
        const total_2 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          newValue +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_2, name, newValue)
        break
      case 'vnpay':
        const total_3 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          newValue +
          form.zalopay +
          form.thett
        checkValuePrice(total_3, name, newValue)
        break
      case 'zalopay':
        const total_4 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          newValue +
          form.thett
        checkValuePrice(total_4, name, newValue)
        break
      case 'pos':
        const total_5 =
          form.tienmat +
          form.chuyenkhoan +
          newValue +
          form.momo +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_5, name, newValue)
        break
      case 'thett':
        if (newValue > paymentCard?.tienVi) return
        const total_6 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          form.zalopay +
          newValue
        checkValuePrice(total_6, name, newValue)
        break
      default:
        break
    }
  }

  const handleKeyDown = (e) => {
    const { name } = e.target
    if (e.key === 'P' || e.key === 'p') {
      // Kiểm tra nếu phím nhấn là "P"
      // Cập nhật giá tiền vào ô input
      switch (name) {
        case 'tienmat':
          const priceTM = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceTM }))
          break
        case 'chuyenkhoan':
          const priceCK = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceCK }))
          break
        case 'momo':
          const priceMOMO = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceMOMO }))
          break
        case 'vnpay':
          const priceVNPAY = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceVNPAY }))
          break
        case 'zalopay':
          const priceZALOPAY = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceZALOPAY }))
          break
        case 'pos':
          const pricePOS = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: pricePOS }))
          break
        case 'thett':
          const priceTheTT = form.tienthanhtoan - form.tongtien
          if (priceTheTT > paymentCard?.tienVi) {
            setForm((prevState) => ({ ...prevState, [name]: paymentCard?.tienVi }))
            break
          }
          setForm((prevState) => ({ ...prevState, [name]: priceTheTT }))
          break
        default:
          break
      }
    }
  }
  useEffect(() => {
    const newTotal =
      parseFloat(form.chuyenkhoan) +
      parseFloat(form.momo) +
      parseFloat(form.pos) +
      parseFloat(form.vnpay) +
      parseFloat(form.zalopay) +
      parseFloat(form.tienmat) +
      parseFloat(form.thett)
    setForm((prevState) => ({ ...prevState, tongtien: newTotal }))
  }, [form.chuyenkhoan, form.momo, form.pos, form.vnpay, form.zalopay, form.tienmat, form.thett])

  return (
    <Modal
      className="text-center"
      title="Hình thức thanh toán"
      open={isModalPay}
      okText="Thanh Toán"
      width={600}
      cancelText="Huỷ"
      onCancel={handleCancel}
      footer={[
        <Button
          onClick={() => submit(true)}
          disabled={form.tongtien !== form.tienthanhtoan}
          type="primary"
        >
          Thanh toán & In
        </Button>,
        <Button
          onClick={() => submit(false)}
          disabled={form.tongtien !== form.tienthanhtoan}
          type="primary"
        >
          Thanh toán
        </Button>,
      ]}
    >
      <form className="text-start">
        <div className="flex flex-col gap-2">
          <div className="flex gap-5">
            <div className="w-1/2">
              <h2 className="font-semibold">Tiền mặt:</h2>
              <InputNumber
                value={form.tienmat}
                name="tienmat"
                onKeyDown={handleKeyDown}
                onChange={(value) => onChangeInput('tienmat', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold">Chuyển khoản:</h2>
              <InputNumber
                value={form.chuyenkhoan}
                name="chuyenkhoan"
                onKeyDown={handleKeyDown}
                onChange={(value) => onChangeInput('chuyenkhoan', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <h2 className="font-semibold">Máy POS:</h2>
              <InputNumber
                value={form.pos}
                name="pos"
                onKeyDown={handleKeyDown}
                onChange={(value) => onChangeInput('pos', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold">Momo:</h2>
              <InputNumber
                value={form.momo}
                name="momo"
                onKeyDown={handleKeyDown}
                onChange={(value) => onChangeInput('momo', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <h2 className="font-semibold">VNPay:</h2>
              <InputNumber
                value={form.vnpay}
                name="vnpay"
                onKeyDown={handleKeyDown}
                onChange={(value) => onChangeInput('vnpay', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
            <div className="w-1/2">
              <h2 className="font-semibold">Zalo Pay:</h2>
              <InputNumber
                value={form.zalopay}
                name="zalopay"
                onKeyDown={handleKeyDown}
                onChange={(value) => onChangeInput('zalopay', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
          </div>
          <div className="flex gap-5 items-center">
            <div className="w-1/2">
              <h2 className="font-semibold">Thẻ thanh toán:</h2>
              <InputNumber
                disabled={!paymentCard}
                value={form.thett}
                name="thett"
                onKeyDown={handleKeyDown}
                onChange={(value) => onChangeInput('thett', value)} //onChangeInput
                className="w-full"
                step={100}
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                min={0}
                max={999999999}
              />
            </div>
            <div className="w-1/2">
              {' '}
              {!paymentCard ? (
                <h2 className="font-semibold text-red-500 pt-5">* Chưa kích hoạt thẻ</h2>
              ) : (
                <h2 className="font-semibold  pt-5">
                  Tối đa : {formattedNumber(paymentCard?.tienVi)}
                </h2>
              )}
            </div>
          </div>
          <p>
            <i>
              {' '}
              * Gợi ý: chọn ô thanh toán và nhấn phím <span className="font-semibold">P</span> để
              tính tổng tiền{' '}
            </i>
          </p>
        </div>
        <div className="flex py-2 ">
          <h2 className="w-1/2 text-lg font-semibold text-center">
            Tiền thanh toán: {formatNumberVND(form.tienthanhtoan)} VNĐ
          </h2>
          <h2 className="w-1/2 text-lg font-semibold text-center">
            Tổng tiền: {formatNumberVND(form.tongtien)} VNĐ
          </h2>
        </div>
      </form>
    </Modal>
  )
}

export default ModalPay
