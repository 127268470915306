import { SearchOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, DatePicker, Divider, Input, Modal, Space, Table } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { cloneDeep } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { nhanDonHang } from '../../../../store/actions/vanPhongPhamAction'
import { DISPATCH_CLEAR_INFO_DONHANG } from '../../../../store/constants/constants'
import ToastCus from '../../../../utils/Toast'

const dateFormat = 'DD/MM/YYYY'
const dateFormatAPI = 'YYYY-MM-DD'

function groupDeliveryInfo(data) {
  const grouped = Object.entries(data).reduce((acc, [key, value]) => {
    if (key.startsWith('nguoigiao-') || key.startsWith('ngaygiao-')) {
      const [type, id] = key.split('-')
      if (!acc.deliveryInfo) {
        acc.deliveryInfo = {}
      }
      if (!acc.deliveryInfo[id]) {
        acc.deliveryInfo[id] = {}
      }
      acc.deliveryInfo[id][type] = value
    } else {
      acc[key] = value
    }
    return acc
  }, {})

  return grouped
}

function prepareGroupedData(data) {
  // Check if data is undefined or null, if so, return an empty array
  if (!data) return []

  // Ensure data is an array
  if (!Array.isArray(data)) return []

  const grouped = data.reduce((acc, item) => {
    const key = item.idncc || item.idkhotong
    if (!key) {
      console.warn('Item has neither idncc nor idkhotong:', item)
      return acc
    }

    if (!acc[key]) {
      acc[key] = {
        id: key,
        name: item.nhacungcap || item.khotong,
        nguoigiao: item.nguoigiao,
        ngaygiao: item.ngaygiao,
        items: [],
      }
    }

    acc[key].items.push(item)

    return acc
  }, {})

  return Object.values(grouped)
}

function ModalEdit({ openEdit, setOpenEdit }) {
  const { inforDonHang } = useSelector((state) => state.vanPhongPhamReducer)
  const [dataTable, setDataTable] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [loadingBtn, setLoadingBtn] = useState(false)

  const searchInput = useRef(null)
  const dispatch = useDispatch()
  const selectRef = useRef(null)
  const { infoUser } = useSelector((state) => state.userReducer)

  const groupedDataArrayFormik = prepareGroupedData(inforDonHang?.DanhSachHangHoa)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      iddathang: inforDonHang?.IDDATHANG,
      chinhanhdat: inforDonHang?.CHINHANHDAT,
      chinhanhnhan: inforDonHang?.CHINHANHNHAN,
      tendonhang: inforDonHang?.TENDONHANG,
      ghichudathang: inforDonHang?.GHICHU,
      nguoidat: inforDonHang?.NGUOIDAT,
      ngaydat: inforDonHang?.NGAYDAT,
      trangThaiDatHang: inforDonHang?.TRANGTHAIDATHANG,
      nguoinhan: infoUser?.idnv,
      thoigiandathang: inforDonHang?.THOIGIANDATHANG,
      ...groupedDataArrayFormik.reduce((acc, group) => {
        acc[`nguoigiao-${group.id}`] = group.nguoigiao
        acc[`ngaygiao-${group.id}`] = group.ngaygiao
        return acc
      }, {}),
    },
    onSubmit: (value) => handleSubmit(value),
    // validationSchema: nhanHangVVPSchema,
  })

  const handleSubmit = (value) => {
    const deliveryObj = groupDeliveryInfo(value)
    const dataFormat = dataTable.map((item) => {
      const deliveryInfo =
        deliveryObj.deliveryInfo && deliveryObj.deliveryInfo[item.idncc || item.idkhotong]
      return {
        iddathang: value.iddathang,
        idhang: item.idhang,
        soluonggiao: item.soluonggiao,
        soluongnhan: item.soluongnhan,
        nguoigiao: deliveryInfo ? deliveryInfo.nguoigiao : null,
        ngaygiao: deliveryInfo ? deliveryInfo.ngaygiao : null,
        nguoisua: infoUser.idnv,
      }
    })
    dispatch(nhanDonHang(dataFormat, setLoadingBtn, handleCancel))
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size="small"
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchTable(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchTable(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearchTable = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const handleChangeInput = (e, record, key) => {
    const findItemIdx = dataTable.findIndex((item) => item.idthuoc === record.idthuoc)
    if (findItemIdx !== -1) {
      const cloneListHang = cloneDeep(dataTable)
      if (e.target.value < 0) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng nhập số hợp lệ',
        })
        cloneListHang[findItemIdx][key] = cloneListHang[findItemIdx].soluongduyet
        setDataTable(cloneListHang)
        return
      }
      cloneListHang[findItemIdx][key] = e.target.value
      if (key === 'soluongnhan') {
        if (e.target.value > record.soluonggiao) {
          ToastCus.fire({
            icon: 'error',
            title: 'Số lượng nhận phải nhỏ hơn hoặc bằng số lượng giao',
          })
          return
        }
        if (e.target.value > record.soluongduyet) {
          ToastCus.fire({
            icon: 'error',
            title: 'Số lượng nhận phải nhỏ hơn hoặc bằng số lượng duyệt',
          })
          return
        }
      } else {
        if (e.target.value > record.soluongduyet) {
          ToastCus.fire({
            icon: 'error',
            title: 'Số lượng giao phải nhỏ hơn hoặc bằng số lượng duyet',
          })
          return
        }
      }
      setDataTable(cloneListHang)
    }
  }

  const columnsHangHoa = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 60,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã hàng hóa',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 120,
      ...getColumnSearchProps('mathuoc'),
    },
    {
      title: 'Tên hàng hóa',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
      ...getColumnSearchProps('tenbietduoc'),
    },
    {
      title: 'SL đặt',
      dataIndex: 'soluongdat',
      key: 'soluongdat',
      align: 'center',
      width: 100,
    },
    {
      title: 'SL duyệt',
      dataIndex: 'soluongduyet',
      key: 'soluongduyet',
      align: 'center',
      width: 100,
    },
    {
      title: 'SL giao',
      dataIndex: 'soluonggiao',
      key: 'soluonggiao',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Input
          size="small"
          min={0}
          step={1}
          type="number"
          key={record.idthuoc}
          value={record.soluonggiao}
          className="w-full text-center"
          onChange={(e) => handleChangeInput(e, record, 'soluonggiao')}
          disabled={record.soluongduyet === 0}
        />
      ),
    },
    {
      title: 'SL nhận',
      dataIndex: 'soluongnhan',
      key: 'soluongnhan',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Input
          size="small"
          min={0}
          step={1}
          type="number"
          key={record.idthuoc}
          value={record.soluongnhan}
          className="w-full text-center"
          onChange={(e) => handleChangeInput(e, record, 'soluongnhan')}
          disabled={record.soluongduyet === 0}
        />
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 200,
    },
  ]

  const groupedDataArray = useMemo(() => {
    const groupedData = dataTable.reduce((acc, item) => {
      const key = item.idncc || item.idkhotong || 'unknown'
      const name = item.nhacungcap || item.khotong || 'Chưa duyệt'
      if (!acc[key]) {
        acc[key] = {
          name: name,
          items: [],
        }
      }
      acc[key].items.push(item)
      return acc
    }, {})

    return Object.entries(groupedData).map(([id, group]) => ({
      key: id,
      id,
      name: group.name,
      items: group.items,
    }))
  }, [dataTable])

  const columnsGroup = [
    {
      title: 'Tên nhà cung cấp/Kho tổng',
      dataIndex: 'name',
      key: 'name',
    },
  ]

  useEffect(() => {
    if (inforDonHang) {
      setDataTable(
        inforDonHang.DanhSachHangHoa.map((item) => ({
          ...item,
          idthuoc: item.idhang,
          soluonggiao: item.soluonggiao ?? item.soluongduyet,
          soluongnhan: item.soluongnhan ?? item.soluongduyet,
        })),
      )
    }
  }, [inforDonHang?.IDDATHANG])

  const rowClassName = (record) => {
    return record.soluongduyet === 0 ? 'bg-gray-100 bg-opacity-50' : ''
  }

  const handleCancel = () => {
    formik.resetForm()
    setOpenEdit(false)
    dispatch({ type: DISPATCH_CLEAR_INFO_DONHANG })
  }

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRowKeys(expandedRows)
  }

  const handleChangeNguoiGiao = (e, record) => {
    formik.setFieldValue(`nguoigiao-${record.id}`, e.target.value)
  }

  useEffect(() => {
    // Set all rows to be expanded by default
    const allKeys = groupedDataArray.map((item) => item.id)
    setExpandedRowKeys(allKeys)
  }, [groupedDataArray])

  return (
    <Modal
      okText="Nhận hàng"
      cancelText="Huỷ"
      className="text-center"
      title={'Chi tiết đơn đặt hàng'}
      onOk={formik.handleSubmit}
      open={openEdit}
      onCancel={handleCancel}
      width={1200}
      style={{ top: 20 }}
      confirmLoading={loadingBtn}
    >
      <form className="text-start flex flex-col gap-2">
        <div className="flex gap-4">
          <div className="w-1/2 flex gap-4">
            <div className="w-2/3">
              <label className="font-semibold  text-base">Người đặt</label>
              <Input
                size="small"
                value={formik.values.nguoidat}
                className="w-full cursor-not-allowed bg-gray-100"
                name="nguoidat"
                readOnly
              />
            </div>
            <div className="w-1/3">
              <label className="font-semibold  text-base">Ngày đặt</label>
              <DatePicker
                size="small"
                name="ngaydat"
                format={dateFormat}
                value={dayjs(formik.values.ngaydat)}
                className="w-full"
                disabled
              />
            </div>
          </div>
          <div className="w-1/2 flex gap-4">
            <div className="w-1/2">
              <label className="font-semibold  text-base">Chi nhánh đặt</label>
              <Input
                size="small"
                value={formik.values.chinhanhdat}
                className="w-full cursor-not-allowed bg-gray-100"
                name="chinhanhdat"
                readOnly
              />
            </div>
            <div className="w-1/2">
              <label className="font-semibold  text-base">Bộ phận duyệt</label>
              <Input
                size="small"
                value={formik.values.chinhanhnhan}
                name="chinhanhnhan"
                className="w-full cursor-not-allowed bg-gray-100"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-1/2">
            <label className="font-semibold  text-base">Tên đơn hàng</label>
            <Input
              size="small"
              value={formik.values.tendonhang}
              name="tendonhang"
              className="w-full cursor-not-allowed bg-gray-100"
              readOnly
            />
          </div>
          <div className="w-1/2 flex gap-4">
            <div className="w-2/3">
              <label className="font-semibold  text-base">Người duyệt</label>
              <Input
                size="small"
                value={formik.values.nguoiduyet}
                name="nguoiduyet"
                className="w-full cursor-not-allowed bg-gray-100"
                readOnly
              />
            </div>
            <div className="w-1/3">
              <label className="font-semibold  text-base">Ngày duyệt</label>
              <DatePicker
                size="small"
                name="ngayduyet"
                format={dateFormat}
                value={formik.values.ngayduyet ? dayjs(formik.values.ngayduyet) : null}
                className="w-full"
                disabled
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-1/2 ">
            <label className="font-semibold  text-base">Ghi chú</label>
            <Input
              size="small"
              value={formik.values.ghichudathang}
              name="ghichudathang"
              className="w-full cursor-not-allowed bg-gray-100"
              readOnly
            />
          </div>
        </div>
      </form>
      <Divider />
      <div className="overflow-auto h-[500px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                cellFontSize: 12,
              },
            },
          }}
        >
          <Table
            scroll={{ y: 450 }}
            bordered
            pagination={false}
            columns={columnsGroup}
            rowClassName={rowClassName}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <Table
                    columns={columnsHangHoa}
                    dataSource={record.items}
                    pagination={false}
                    footer={() =>
                      record.id === 'unknown' ? null : (
                        <div className="flex gap-4">
                          <div className="w-96 flex">
                            <label className="font-semibold w-32  text-sm">Người giao</label>
                            <Input
                              size="small"
                              value={formik.values[`nguoigiao-${record.id}`]}
                              onChange={(e) => handleChangeNguoiGiao(e, record)}
                              type="text"
                              name={`nguoigiao-${record.id}`}
                              className="w-full"
                            />
                          </div>
                          <div className="w-60 flex">
                            <label className="font-semibold w-32 text-sm">Ngày giao</label>
                            <DatePicker
                              size="small"
                              name={`ngaygiao-${record.id}`}
                              format={dateFormat}
                              onChange={(date, dateString) => {
                                formik.setFieldValue(
                                  `ngaygiao-${record.id}`,
                                  dayjs(dateString, dateFormat).format(dateFormatAPI),
                                )
                              }}
                              allowClear={false}
                              value={
                                formik.values[`ngaygiao-${record.id}`]
                                  ? dayjs(formik.values[`ngaygiao-${record.id}`])
                                  : null
                              }
                              maxDate={dayjs(new Date())}
                              className="w-full"
                              placeholder="Chọn ngày giao"
                            />
                          </div>
                        </div>
                      )
                    }
                  />
                )
              },
              expandedRowKeys,
              onExpandedRowsChange,
            }}
            dataSource={groupedDataArray}
          />
        </ConfigProvider>
      </div>
    </Modal>
  )
}

ModalEdit.propTypes = {}

export default ModalEdit
