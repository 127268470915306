import React from "react";
import { Divider, Table, ConfigProvider } from "antd";
import style from "./style.module.css";
import moment from "moment";
const columns = [
  {
    title: "Ngày khám",
    dataIndex: "ngaykham",
    width: 150,
  },
  {
    title: "Tái khám",
    dataIndex: "TaiKham",
    width: 100,
  },
  {
    title: "Loại khám",
    dataIndex: "LoaiKham",
    width: 150,
  },
  {
    title: "Chẩn đoán",
    dataIndex: "ChuanDoan",
  },
  {
    title: "Bác sĩ khám",
    dataIndex: "BSKham",
  },
];

const TableHistory = ({ history }) => {
  return (
    <div className="p-2">
      <Divider style={{
        margin: '10px 0'
      }}>
        <span className="text-blue-500">LỊCH SỬ KHÁM BỆNH</span>
      </Divider>
      <h2>
        {" "}
        <span className="text-blue-500 font-semibold">Bệnh nhân:</span>
      </h2>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
        }}
      >
        <Table
          dataSource={history.map((items) => ({
            ngaykham: items.ngaykham && moment(items.ngaykham).format('DD/MM/YYYY HH:mm:ss'),
            TaiKham: items.ngaytaikham && moment(items.ngaytaikham).format('DD/MM/YYYY'),
            LoaiKham: items.tenLoaiKham,
            ChuanDoan: items.chandoan,
            BSKham: items.bskham,
          }))}
          pagination={false}
          bordered
          className={style.table_history}
          scroll={{ y: 200 }}
          columns={columns}
        />
      </ConfigProvider>
    </div>
  );
};

export default TableHistory;
