import { Button, Input, Select } from 'antd'
import React, { useEffect, useState, useCallback } from 'react'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import ModalCreateSuCo from '../CreateSuCo/ModalCreateSuCo'
import { getAllNhanVienBaoHong, getDanhSachBaoHong } from '../../../../store/actions/suCoAction'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { branchService } from '../../../../services/branch/branchService'
import TableBaoCao from './TableBaoCao'
import moment from 'moment'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'

const Todo = ({ setIsOpen }) => {
  const IDCT = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { DanhSachBaoHong } = useSelector((state) => state.suCoReducer)
  const [valueExport, setValueExport] = useState([])
  const [keyWord, setKeyWord] = useState('')
  const [company, setCompany] = useState(IDCT)
  const [branch, setBranch] = useState([])
  const [open, setOpen] = useState(false)
  const [idChiNhanh, setIdChiNhanh] = useState(null)
  const dispatch = useDispatch()
  const fetchData = () => {
    dispatch(getDanhSachBaoHong(keyWord, company, idChiNhanh))
  }
  const fetchBranch = async (id) => {
    const result = await branchService.getListBranch(id)
    setBranch(result.data)
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      dispatch(getDanhSachBaoHong(keyword, company, idChiNhanh))
    }, 300),
    [],
  )
  const exportToExcel = () => {
    const formattedData = valueExport?.map((item) => ({
      'Chi nhánh': item.CHINHANHBAOHONG,
      'Tên tiêu đề': item.TENBAOHONG,
      'Người tạo': item.NGUOIBAOHONG,
      'Ngày tạo': item.NGAYBAOHONG ? moment(item.NGAYBAOHONG).format('YYYY/MM/DD HH:mm:ss') : '',
      'Đơn vị': item.DONVINHAN,
      'Người thực hiện':
        item.DanhSachNhanVien.length !== 0
          ? item.DanhSachNhanVien.map((nv) => nv.tennhanvien).join(', ')
          : '',
      'Mức độ': item.MUCDOSUCO,
      'Trạng thái': item.TRANGTHAIPHIEU,
      'Người nhận': item.NGUOINHAN,
      'Ngày nhận': item.NGAYNHAN ? moment(item.NGAYNHAN).format('YYYY/MM/DD HH:mm:ss') : '',
    }))
    const name = 'Danh sách sự cố'
    exportExcelformat(formattedData, name)
  }
  useEffect(() => {
    fetchData()
    dispatch(listAllCompanyAction())
    fetchBranch(IDCT)
    dispatch(getAllNhanVienBaoHong())
  }, [])
  useEffect(() => {
    setValueExport(DanhSachBaoHong)
  }, [DanhSachBaoHong])
  return (
    <>
      <div className="flex justify-between mb-2">
        <div className="flex gap-2">
          <Select
            onChange={(value) => {
              setCompany(value)
              fetchBranch(value)
              setIdChiNhanh(null)
            }}
            className="w-64"
            value={company}
            options={listCompany?.map((item) => ({
              label: item.tenct,
              value: item.idct,
            }))}
          />
          <Select
            className="w-64"
            onChange={(value) => setIdChiNhanh(value)}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { label: 'Tất cả', value: null },
              ...branch.map(({ idChiNhanh, tenChiNhanh }) => ({
                label: tenChiNhanh,
                value: idChiNhanh,
              })),
            ]}
            value={idChiNhanh}
          />
          <IconButton onClick={fetchData} color="primary" aria-label="delete" size="small">
            <SyncIcon />
          </IconButton>
          <Button
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>

        <div className="flex gap-2">
          <Input.Search
            value={keyWord}
            className="w-72"
            placeholder="Nhập tìm kiếm"
            onChange={(e) => {
              setKeyWord(e.target.value)
              debounceDropDown(e.target.value)
            }}
          />
          <Button onClick={() => setOpen(true)} type="primary">
            Tạo sự cố
          </Button>
        </div>
      </div>
      <TableBaoCao setIsOpen={setIsOpen} fetchData={fetchData} />
      <ModalCreateSuCo openAdd={open} setOpenAdd={setOpen} fetchData={fetchData} branch={branch} />
    </>
  )
}

export default Todo
