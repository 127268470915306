import { Tabs } from 'antd'
import React, { useState,useRef  } from 'react'
import Todo from './Todo/Todo'
import Completed from './Completed/Completed'

const CreateSuCo = () => {
  const [open,setOpen] = useState(false) 

  return (
    <div className="p-3 bg-[#EFEFEF] h-full">
      <div className='p-2 bg-white h-full rounded-lg border'>
        <Tabs
          items={[
            {
              label: 'Tạo sự cố',
              key: 1,
              children: <Todo  setOpen={setOpen} open={open} />,
            },
            {
              label: 'Danh sách hoàn thành',
              key: 2,
              children: <Completed  setOpen={setOpen} open={open} />,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default CreateSuCo
