import moment from "moment";
import { formatNumberVND } from "../../../../../utils/formatNumberVND";

export const columns = [
  {
    title: 'Ngày',
    dataIndex: 'ngay',
    key: 'ngay',
    width: 100,
    render:(text) => text ? moment(text).format('DD/MM/YYYY'): '-'
  },
  {
    title: 'Bác sĩ',
    dataIndex: 'tenChiNhanh',
    key: 'tenChiNhanh',
    width: 250,
  },
  {
    title: 'Tổng phiếu',
    dataIndex: 'tenNhomDV',
    key: 'tenNhomDV',
    width: 250,
  },
  {
    title: 'Thời gian trung bình',
    dataIndex: 'maDV',
    key: 'maDV',
    width: 250,
  },
]
