import { ContainerOutlined } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { IconButton, Tooltip } from '@mui/material'
import { Button, ConfigProvider, DatePicker, Input, Modal, Pagination, Select, Table } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { https } from '../../../services/apiService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { getAllDonHangByIdCtyIdCn, getInfoDonHang } from '../../../store/actions/vanPhongPhamAction'
import { trangThaiDatHang } from '../../../utils/trangThaiDatHang'
import ModalEdit from './Modal/ModalEdit'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const formatMoment = 'YYYY-MM-DD'
const dateFormat = 'DD-MM-YYYY'
const { confirm } = Modal

function DonDatHang(props) {
  const { listDatHang } = useSelector((state) => state.vanPhongPhamReducer)
  const [dateFrom, setDateFrom] = useState(now.format(formatMoment))
  const [dateTo, setDateTo] = useState(now.format(formatMoment))
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [chiNhanh, setChiNhanh] = useState('')
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [keyword, setKeyword] = useState('')

  const dispatch = useDispatch()
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 40,
      align: 'center',
    },
    {
      title: 'Mã đặt hàng',
      dataIndex: 'madathang',
      key: 'madathang',
      width: 100,
    },
    {
      title: 'Chi nhánh đặt',
      dataIndex: 'chinhanhdat',
      key: 'chinhanhdat',
      width: 150,
    },
    {
      title: 'Bộ phận duyệt',
      dataIndex: 'chinhanhnhan',
      key: 'chinhanhnhan',
      width: 150,
    },
    {
      title: 'Tên đơn hàng',
      dataIndex: 'tendonhang',
      key: 'tendonhang',
      width: 200,
    },
    {
      title: 'Thời gian đặt hàng',
      dataIndex: 'thoigiandathang',
      key: 'thoigiandathang',
      width: 100,
      align: 'center',
    },
    {
      title: 'Người đặt hàng',
      dataIndex: 'nguoidat',
      key: 'nguoidat',
      width: 120,
    },
    // {
    //   title: 'Ngày đặt',
    //   dataIndex: 'ngaydat',
    //   key: 'ngaydat',
    //   width: 80,
    //   align: 'center',
    // },
    // {
    //   title: 'Người duyệt',
    //   dataIndex: 'nguoiduyet',
    //   key: 'nguoiduyet',
    //   width: 120,
    // },
    // {
    //   title: 'Ngày duyệt',
    //   dataIndex: 'ngayduyet',
    //   key: 'ngayduyet',
    //   width: 80,
    //   align: 'center',
    // },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 120,
    },
    {
      title: 'Trạng thái',
      key: 'trangthai',
      dataIndex: 'trangthai',
      width: 80,
      align: 'center',
      fixed: 'right',
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      width: 40,
      align: 'center',
      fixed: 'right',
    },
  ]

  const onLoad = () => {
    dispatch(getAllDonHangByIdCtyIdCn(keyword, dateFrom, dateTo, 1, company, chiNhanh))
  }

  useEffect(() => {
    onLoad()
  }, [])

  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat).format(formatMoment)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const handleShowModalDetail = (record) => {
    setOpenEdit(true)
    //call api get info
    dispatch(getInfoDonHang(record.iddathang))
  }

  const handleChangePage = (keyword, dateFrom, dateTo, company, chiNhanh, page) => {
    setCurrentPage(page)
    SetSearchParams({ page })
    dispatch(getAllDonHangByIdCtyIdCn(keyword, dateFrom, dateTo, page, company, chiNhanh))
  }

  const getAllCN_By_idct = async (value) => {
    try {
      const { data } = await https.get(`ChiNhanh/GetChiNhanhByIDCT?idct=${value}`)
      setListChiNhanh(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCompany = (value) => {
    setCompany(value)
    setChiNhanh('')
    getAllCN_By_idct(value)
  }

  const handleChiNhanh = (value) => {
    setChiNhanh(value)
  }

  const renderTrangThai = useCallback(
    (value) => {
      let statusProps = {
        text: '',
        className: '',
      }

      switch (value) {
        case trangThaiDatHang.taoPhieu:
          statusProps = { text: 'Tạo phiếu', className: 'bg-blue-200 text-blue-800' }
          break
        case trangThaiDatHang.guiPhieu:
          statusProps = { text: 'Gửi phiếu', className: 'bg-indigo-200 text-indigo-800' }
          break
        case trangThaiDatHang.duyetPhieu:
          statusProps = { text: 'Duyệt phiếu', className: 'bg-orange-200 text-orange-800' }
          break
        case trangThaiDatHang.duyetPhieuKSNB:
          statusProps = { text: 'Duyệt phiếu KSNB', className: 'bg-purple-200 text-purple-800' }
          break
        case trangThaiDatHang.vanChuyen:
          statusProps = { text: 'Vận chuyển', className: 'bg-yellow-200 text-yellow-800' }
          break
        case trangThaiDatHang.nhapHang:
          statusProps = { text: 'Nhập hàng', className: 'bg-red-200 text-red-800' }
          break
        case trangThaiDatHang.hoanThanh:
          statusProps = { text: 'Hoàn thành', className: 'bg-green-200 text-green-800' }
          break
        default:
          statusProps = { text: 'Không xác định', className: 'bg-gray-200 text-gray-800' }
          break
      }

      return (
        <span className={`px-2 py-1 rounded-full text-xs font-medium ${statusProps.className}`}>
          {statusProps.text}
        </span>
      )
    },
    [trangThaiDatHang],
  )

  useEffect(() => {
    dispatch(listAllCompanyAction())
    getAllCN_By_idct(company)
  }, [])

  //setTotalPage
  useEffect(() => {
    if (listDatHang?.totalPages) setTotalPage(listDatHang?.totalPages)
  }, [listDatHang?.totalPages])

  return (
    <div className="h-full w-full bg-[#EBEBEB] p-2 border rounded-sm ">
      <div className="flex gap-5 justify-between bg-white border-dotted border-b-0 p-4">
        <div className="flex gap-5 justify-between w-full">
          <Select
            showSearch
            className="w-72"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleCompany}
            value={company}
            options={listCompany?.map((items) => ({
              label: items.tenct,
              value: items.idct,
            }))}
          />
          <Select
            showSearch
            className="w-72"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleChiNhanh}
            value={chiNhanh}
            options={[
              {
                tenchinhanh: 'Tất cả',
                idchinhanh: '',
              },
              ...listChiNhanh,
            ].map((items) => ({
              label: items.tenchinhanh,
              value: items.idchinhanh,
            }))}
          />
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('from')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="w-64">
            <Input
              className="w-full"
              placeholder="Nhập tên đơn hàng"
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              value={keyword}
            />
          </div>
          <div className="flex gap-3 mr-auto">
            <IconButton
              color="primary"
              aria-label="delete"
              size="small"
              onClick={() =>
                dispatch(getAllDonHangByIdCtyIdCn(keyword, dateFrom, dateTo, 1, company, chiNhanh))
              }
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="ml-auto">
            {/* <Button
              //   onClick={() => exportToExcel(search, chiNhanh, company, dateFrom, dateTo)}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
              //   loading={loadingExport}
              //   disabled={loadingExport}
            >
              Xuất Excel
            </Button> */}
          </div>
        </div>
      </div>
      <div className="bg-white p-2">
        <div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
                fontSize: 14,
              },
              components: {
                Table: {
                  headerBorderRadius: 0,
                  rowHoverBg: '#ecf0f1',
                },
              },
            }}
          >
            <Table
              bordered
              scroll={{
                x: 2000,
                y: 800,
              }}
              pagination={false}
              columns={columns}
              dataSource={listDatHang?.data?.map((item, idx) => ({
                ...item,
                stt: (currentPage - 1) * 20 + (idx + 1),
                thoigiandathang: item.thoigiandathang
                  ? moment(item.thoigiandathang).format('DD/MM/YYYY HH:mm')
                  : '',
                ngayduyet: item.ngayduyet ? moment(item.ngayduyet).format('DD/MM/YYYY') : '',
                ngaydat: item.ngaydat ? moment(item.ngaydat).format('DD/MM/YYYY') : '',
                trangthai: renderTrangThai(item.trangthaidathang),
                action: (
                  <ul className="flex justify-around">
                    <li>
                      <Tooltip title="Chi tiết" color="#108ee9">
                        <ContainerOutlined
                          onClick={() => handleShowModalDetail(item)}
                          className="text-xl text-[#108ee9]  cursor-pointer"
                        />
                      </Tooltip>
                    </li>
                  </ul>
                ),
              }))}
            />
          </ConfigProvider>
          <div className="flex justify-end">
            <Pagination
              className="mt-5"
              showSizeChanger={false}
              current={+useSearch.get('page')}
              simple
              defaultCurrent={1}
              pageSize={20}
              total={20 * totalPage}
              onChange={(page, pagesize) => {
                handleChangePage(keyword, dateFrom, dateTo, company, chiNhanh, page)
              }}
            />
          </div>
        </div>
      </div>
      {openEdit && <ModalEdit openEdit={openEdit} setOpenEdit={setOpenEdit} />}
    </div>
  )
}

DonDatHang.propTypes = {}

export default DonDatHang
