import CachedIcon from '@mui/icons-material/Cached'
import { Button, ConfigProvider, DatePicker, Input, Select, Space, Table, Typography } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import { branchNhapKhoPT, filterPTNhapKhoAction } from '../../../../store/actions/NhapKhoAction'
import * as typeAction from '../../../../store/constants/constants'
import { https } from '../../../../services/apiService'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { columnsDetails } from './columns'
import { NhapKhoService } from '../../../../services/NhapKho/NhapKhoService'
import { branchService } from '../../../../services/branch/branchService'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import * as XLSX from 'xlsx'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
moment.locale('vi')
const DetaiKho = () => {
  const today = moment()
  const dateFormat = 'DD/MM/YYYY'
  const formatDateDB = 'YYYY-MM-DD'
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [since, setSince] = useState(today.format(formatDateDB))
  const [toDate, SetToDate] = useState(today.format(formatDateDB))
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [idChiNhanh, setIdChiNhanh] = useState(Number(BranchLogin))
  const [branch, setBranch] = useState([])
  const [data, setData] = useState([])
  const [loadingChiTiet, setLoadingChiTiet] = useState(false)
  const [loadingListPhieu, setLoadingListPhieu] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [search, setSearch] = useState(null)
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  // set ngày lại từ ngày
  const handleSince = (name) => (date, dateString) => {
    if (name === 'since') {
      setSince(dateString)
    } else {
      SetToDate(dateString)
    }
  }
  const handleDatePicker = (data, dateString) => {
    setSince(moment(dateString[0], dateFormat).format(formatDateDB))
    SetToDate(moment(dateString[1], dateFormat).format(formatDateDB))
  }
  const handleCompany = (value) => {
    setCompany(value)
    dispatch(branchNhapKhoPT(value))
    fetchBranch(value)
    setIdChiNhanh(null)
  }

  // xử lí chọn chi nhánh
  const handleSelected = (value) => {
    setIdChiNhanh(value)
  }
  // xử lí lọc dưc liệu
  const handleFilter = async (keyword, idct, idcn, tuNgay, denNgay) => {
    try {
      setLoadingListPhieu(true)
      const { data } = await NhapKhoService.getDetailkhoXuat(keyword, idct, idcn, tuNgay, denNgay)
      setData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingListPhieu(false)
    }
  }
  const onSearch = () => {
    handleFilter(search, company, idChiNhanh, since, toDate)
  }
  // search
  const debounceDropDown = useCallback(
    _.debounce((e) => {
      setSearch(e.target.value)
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const fetchBranch = async () => {
    const { data } = await branchService.getListBranch(company)
    setBranch(data)
  }
  useEffect(() => {
    handleFilter(search, company, idChiNhanh, since, toDate)
  }, [search])
  useEffect(() => {
    dispatch(branchNhapKhoPT(company))
    dispatch(listBranchAction())
    fetchBranch()
  }, [])

  const exportToExcel = () => {
    const formattedData = data.map((item) => ({
      'Mã phiếu': item?.maphieu,
      'Ngày nhập': item?.ngaynhan ? moment(item.ngaynhan).format('DD/MM/YYYY HH:mm:ss') : '',
      'Tên phiếu': item?.tenphieu,
      'Số hóa đơn': item?.sohoadon,
      'Ngày HĐ': item?.ngayhoadon,
      'Chi nhánh chuyển': item?.tenchinhanh,
      'Kho chuyển': item?.tenkho,
      'Chi nhánh nhập': item?.tenchinhanhnhap,
      'Kho nhập': item?.tenkhonhap,
      'Mã hàng': item?.mathuoc,
      'Tên hoạt chất': item?.tenhoatchat,
      'Tên biệt dược': item?.tenbietduoc,
      'SL chẳn': Math.floor(item?.soluongle / item?.soluongdonggoi) ?? 0,
      'Đơn vị chẳn': item?.donvichan,
      'Giá bán': item?.giaban,
      'Quy cách': item?.quycach,
      'SL lẻ': item?.soluongle,
      'Mã số ĐK': item?.masodangky,
      'Số lô': item?.solo,
      'Hạn dùng': item?.handung ? moment(item?.handung).format('DD/MM/YYYY') : '',
      'Ghi chú': item?.ghichu,
    }))

    const name = `Chi tiết hàng - từ ${moment(since).format('DD-MM-YYYY')} đến ${moment(toDate).format('DD-MM-YYYY')}`
    exportExcelformat(formattedData, name)
  }

  return (
    <>
      <div className="flex justify-between mb-5">
        <div className="flex gap-3 items-center w-full">
          <Input
            onChange={debounceDropDown}
            placeholder="Tìm mã phiếu, tên phiếu nhập, số hóa đơn"
            allowClear
            prefix={
              <SearchOutlined
                style={{
                  color: 'rgba(0,0,0,.25)',
                }}
              />
            }
          />
          <Select
            onChange={handleCompany}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            value={company}
            className="min-w-64"
          />
          <Select
            onChange={handleSelected}
            value={idChiNhanh}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { label: 'Tất cả', value: null },
              ...branch?.map(({ idChiNhanh, tenChiNhanh }) => ({
                label: tenChiNhanh,
                value: idChiNhanh,
              })),
            ]}
            className="min-w-72"
          />
          <DatePicker.RangePicker
            className="min-w-60"
            allowClear={false}
            value={[dayjs(since, formatDateDB), dayjs(toDate, formatDateDB)]}
            format={dateFormat}
            onChange={handleDatePicker}
          />
          <Button type="primary" onClick={onSearch} icon={<CachedIcon sx={{ fontSize: 25 }} />}>
            Tìm{' '}
          </Button>
          <div className="flex gap-3 ml-auto">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
              disabled={data?.length === 0}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>
      <div className=" h-full">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              borderRadius: 0,
            },
          }}
        >
          <Table
            loading={loadingChiTiet}
            scroll={{ x: 2500, y: 660 }}
            bordered
            pagination={{
              pageSize: 100,
              showSizeChanger: false,
            }}
            columns={columnsDetails}
            dataSource={data?.map((item, index) => ({
              STT: index + 1,
              maphieu: item.maphieu,
              tenphieu: item.tenphieu,
              ngaynhan: item.ngaynhan,
              sohoadon: item.sohoadon,
              ngayhoadon: item.ngayhoadon,
              tenchinhanh: item.tenchinhanh,
              tenkho: item.tenkho,
              tenchinhanhnhap: item?.tenchinhanhnhap,
              tenkhonhap: item?.tenkhonhap,
              tendoitac: item.tendoitac,
              quycach: item.quycach,
              madoitac: item.madoitac,
              tenbietduoc: item.tenbietduoc,
              tenhoatchat: item.tenhoatchat,
              mahang: item.mathuoc,
              soLuongChan: Math.floor(item?.soluongle / item?.soluongdonggoi) ?? 0,
              donViChan: item?.donvichan,
              donGiaChan: item?.donGiaMua * item?.soLuongDongGoi,
              soLuongLe: item?.soluongle,
              donViLe: item?.dvt,
              donGiaLe: item?.dongiamua,
              tongTien: item?.dongiamua * item?.soluongle,
              giaCong: item?.phigiacong,
              vanChuyen: item?.phivanchuyen,
              ptcktruocvat: item?.ptcktruocvat,
              cktruocvat: item?.cktruocvat,
              tongtientruocvat: item?.tongtientruocvat,
              giaban: item?.giaban,
              vat: item.vat5 !== 0 ? '5%' : item.vat8 !== 0 ? '8%' : '10%',
              phanTramVat:
                item?.vaT5 !== 0
                  ? Math.round(item?.vaT5)
                  : item?.vaT8 !== 0
                    ? Math.round(item?.vaT8)
                    : Math.round(item?.vaT10),
              thanhTien: item?.thanhtien,
              thucTra: item?.thuctra,
              nhietDo: item?.nhietdo,
              maSoDangKi: item?.masodangky,
              soLo: item?.solo,
              hanDung: item?.handung,
              ghiChu: item?.ghichu,
              status: item.trangthaichuyenkho === 5 ? 'Chưa nhập kho' : 'Đã chuyển kho',
            }))}
            summary={(pageData) => {
              const totalSoLuongChan = pageData.reduce(
                (sum, record) => sum + (record.soLuongChan || 0),
                0,
              )
              const totalSoLuongLe = pageData.reduce(
                (sum, record) => sum + (record.soLuongLe || 0),
                0,
              )
              const totalPrice = pageData.reduce((sum, record) => sum + (record.giaban || 0), 0)
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    {Array.from({ length: 22 }, (_, index) => (
                      <Table.Summary.Cell key={index} index={index} align="center">
                        {index === 13 && (
                          <Typography.Text type="danger">
                            {formatNumberVND(totalSoLuongChan)}
                          </Typography.Text>
                        )}
                        {index === 15 && (
                          <Typography.Text type="danger">
                            {formatNumberVND(totalPrice)}
                          </Typography.Text>
                        )}
                        {index === 17 && (
                          <Typography.Text type="danger">
                            {formatNumberVND(totalSoLuongLe)}
                          </Typography.Text>
                        )}
                      </Table.Summary.Cell>
                    ))}
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default DetaiKho
