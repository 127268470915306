import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Input, Select, Skeleton, Table, Tabs, notification } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { recieveService } from '../../../../../../services/receive/recieveService'
import { cashierService } from '../../../../../../services/cashier/cashierService'
import { addNewChiDinhBN } from '../../../../../../store/actions/childrenClinicAction'
import { formatNumberVND } from '../../../../../../utils/formatNumberVND'
import { generatePhieuChiDinh } from '../../../../../../utils/report/phieuChiDinh'
const FormCLS = ({ formik }) => {
  const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [isLoad, setIsLoad] = useState(false)
  const [isLoadChiDinhBN, setisLoadChiDinhBN] = useState(false)
  const [groupsDV, setGroupsDV] = useState([])
  const [groupsXN, setGroupsXN] = useState([])
  const [groupsCDHA, setGroupsCDHA] = useState([])
  const [valueDV, setValueDV] = useState(null)
  const [valueXN, setValueXN] = useState(null)
  const [valueCDHA, setValueCDHA] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchXN, setsearchXN] = useState('')
  const [searchCdha, setsearchCdha] = useState('')
  const [dichVu, setDichVu] = useState([])
  const [xetNghiem, setXetNghiem] = useState([])
  const [cdha, setCdha] = useState([])
  const [value, setValue] = useState([])
  const [valueDel, setValueDel] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [api, contextHolder] = notification.useNotification()
  const dispatch = useDispatch()
  //------------STORE --------------//
  const { infoBN, DetailBN, infoTTV } = useSelector((state) => state.childrenClinicReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  //--------------------- lấy danh sách chỉ định của bệnh nhân ----------------//
  const fetchChiDinh = async (idbnttc) => {
    try {
      setisLoadChiDinhBN(true)
      const dv = await cashierService.getDicVuByBNTTC(idbnttc)
      const xn = await cashierService.getXNByBNTTC(idbnttc)
      const cdha = await cashierService.getCDHAByBNTTC(idbnttc)
      let Arr = dv?.data.concat(xn?.data, cdha?.data)
      let ArrCD = Arr?.map((item, index) => {
        let name = item.iddichvu ? 'dv' : item.idxn ? 'xn' : item.idcdha ? 'cdha' : ''
        return {
          key: ++index,
          type: name,
          dongia: item.dongia,
          idchiDinh: item.iddichvu ?? item.idxn ?? item.idcdha,
          name: item.tendichvu ?? item.tenxn ?? item.tencdha,
          old: item.trangthaitt === 2 || item.idtrangthaitt === 2 ? true : null,
          idnhomdv: item.idnhomdv,
          infoCD: item,
          trangthaitt: item.trangthaitt ?? item.idtrangthaitt ?? 0,
        }
      })
      setValue(ArrCD?.sort((a, b) => a?.trangthaitt - b?.trangthaitt))
      setValueDel([])
      setisLoadChiDinhBN(false)
    } catch (error) {
      console.log(error)
      api.warning({
        message: 'Lấy dữ liệu dịch vụ',
        description: 'Lấy dữ liệu thất bại, vui lòng thử lại!',
      })
    }
    // finally {
    //   // setTimeout(() => {
    //   // }, 3000)
    // }
  }
  //--------------------- lấy danh sách chỉ định dịch vụ ----------------//
  const fetchDichVu = async (idGroups) => {
    try {
      setIsLoad(true)
      const result = await recieveService.getListChiDinhByGroups(
        idGroups,
        ID_BRANCH_LOGIN,
        ID_COMPANY,
      )
      setDichVu(result?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoad(false)
    }
  }
  //---------------- lấy danh sách chỉ định xét nghiệm by group ------------//
  const fetchXetNghiem = async (idGroups) => {
    try {
      setIsLoad(true)
      const result = await recieveService.getListXetNghiemByGroups(
        idGroups,
        ID_BRANCH_LOGIN,
        ID_COMPANY,
      )
      setXetNghiem(result?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoad(false)
    }
  }
  // ------------- lấy danh sách chỉ định xét nghiệm all -------------//
  const fetchListXN = async () => {
    try {
      setIsLoad(true)
      const result = await recieveService.getListXetNghiemTiepNhan(ID_BRANCH_LOGIN)
      setXetNghiem(result?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoad(false)
    }
  }
  //----------------- lấy danh sách chỉ định chuẩn đoán hình ảnh -----------//
  const fetchCDHA = async (idGroups) => {
    try {
      setIsLoad(true)
      const result = await recieveService.getLisChiDinhCĐHA(idGroups, ID_COMPANY)
      setCdha(result.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoad(false)
    }
  }
  //------------- get list Group Dịch vụ -----------
  const fetchGroupsDV = async () => {
    try {
      setIsLoad(true)
      const result = await recieveService.getGroupsChiDinh(ID_BRANCH_LOGIN)
      setGroupsDV(result?.data)
      setValueDV(result?.data[0]?.idNhom ?? -1)
      await fetchDichVu(result?.data[0]?.idNhom ?? -1)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoad(false)
    }
  }
  // -------------fetchGroupsXetNghiem -------------//
  const fetchGroupsXN = async () => {
    try {
      setIsLoad(true)
      const result = await recieveService.getGroupXetNghiemByBranch(ID_BRANCH_LOGIN)
      setGroupsXN(result?.data)
      setValueXN(result?.data[0]?.idnhomxn ?? -1)
      fetchXetNghiem(result?.data[0]?.idnhomxn ?? -1)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoad(false)
    }
  }
  //--------------- fetch groups chẩn đoán hình ảnh-----------//
  const fetchGroupsCDHA = async () => {
    try {
      setIsLoad(true)
      const result = await recieveService.getGroupsCĐHA()
      setGroupsCDHA(result?.data)
      setValueCDHA(result?.data[0]?.idnhomcdha ?? -1)
      fetchCDHA(result?.data[0]?.idnhomcdha ?? -1)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoad(false)
    }
  }
  // ------------- xử lý chọn chỉ định khám bệnh -----------------//
  const handleSelected = (name) => (value) => {
    if (name === 'dv') {
      fetchDichVu(value)
      setValueDV(value)
    } else if (name === 'xn') {
      fetchXetNghiem(value)
      setValueXN(value)
    } else if (name === 'cdha') {
      fetchCDHA(value)
      setValueCDHA(value)
    }
  }
  //----------thông báo ----------//
  const openNotificationWithIcon = () => {
    api.warning({
      message: 'Chọn chỉ định',
      description: 'Chỉ định này đã được chọn',
    })
  }
  // ------------- chọn chỉ định khám bệnh ------------//
  const onClickChiDing = async (name, item) => {
    if (DetailBN?.trangthaikhambenh === 4) {
      api.warning({
        message: 'Chọn chỉ định',
        description: 'Bệnh nhân đã khám xong!',
      })
      return
    }
    if (isLoadChiDinhBN) {
      api.warning({
        message: 'Chọn chỉ định',
        description: 'Vui lòng thử lại!',
      })
      return
    }
    setValue((preState) => {
      // Kiểm tra nếu chỉ định đã tồn tại
      const exists = preState.some(
        (entry) =>
          entry.type === name && entry.idchiDinh === item.idChiDinh && entry.idnhomdv !== 5, // chỉ nhóm thủ thuật được chọn nhiều lần
      )
      if (exists) {
        // Hiển thị thông báo nếu chỉ định đã tồn tại
        openNotificationWithIcon() // Cập nhật trạng thái thông báo
        return preState
      } else {
        const lastKey = preState.length > 0 ? preState[preState.length - 1].key : 0
        const newKey = lastKey + 1
        // Nếu chỉ định chưa tồn tại, thêm vào mảng
        // Add the new item to the array
        const updatedState = [
          ...preState,
          {
            key: newKey,
            type: name,
            dongia: item.donGia,
            idchiDinh: item.idChiDinh,
            name: item.tenChiDinh,
            idnhomdv: item.idNhomDV,
          },
        ]
        // Update the selected row keys
        setSelectedRowKeys((prevKeys) => [...prevKeys, newKey])
        return updatedState
      }
    })
  }
  //----------- xoá chỉ định khám ---------------------//
  const deleteChiDinh = (name, id, key) => {
    setValueDel((preState) =>
      [
        ...preState,
        value?.find((item) => item?.type === name && item?.key === key && item?.infoCD),
      ]?.filter((item) => item),
    )
    setValue((preState) => preState?.filter((item) => !(item?.type === name && item?.key === key)))
  }
  //------------- reset lại tất cả store ------ //
  const handleReset = () => {
    setValue([])
    setSelectedRowKeys([])
  }
  //---------- xử lý lưu bệnh nhân  --------------//
  const handleSubmit = () => {
    // console.log(valueDel)
    // return
    const ValuePrinter =
      selectedRowKeys.length === 0
        ? value
        : value
            .filter((item) => selectedRowKeys.includes(item.key))
            .map((item) => item.infoCD || item)
    dispatch(
      addNewChiDinhBN(
        ValuePrinter,
        value,
        valueDel,
        DetailBN.idbnttc,
        infoUser.tenNV,
        handleReset,
        formik.values.chandoanbandau,
      ),
    )
  }
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }
  //------------ row selected --------//
  const onSelectChange = (newSelectedRowKeys, record) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  // Filter and sort dichVu based on search query and tenChiDinh
  const filteredAndSortedDichVu = dichVu
    ?.filter((item) => item.tenChiDinh.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => a.tenChiDinh.localeCompare(b.tenChiDinh))
  //fiter xetnghiem
  const filteredAndSortedXetNghiem = xetNghiem
    ?.filter((item) => item.tenChiDinh.toLowerCase().includes(searchXN.toLowerCase()))
    .sort((a, b) => a.tenChiDinh.localeCompare(b.tenChiDinh))
  //fiter chan daon hinh anh
  const filteredAndSortedCDHA = cdha
    ?.filter((item) => item.tenChiDinh.toLowerCase().includes(searchCdha.toLowerCase()))
    .sort((a, b) => a.tenChiDinh.localeCompare(b.tenChiDinh))

  useEffect(() => {
    fetchGroupsDV()
    //fetchGroupsXN()
    fetchListXN()
    fetchGroupsCDHA()
  }, [])
  useEffect(() => {
    if (DetailBN?.idbnttc) {
      fetchChiDinh(DetailBN?.idbnttc)
    }
    setSelectedRowKeys([])
  }, [DetailBN])

  const handlePrint = () => {
    const ValuePrinter =
      selectedRowKeys.length === 0
        ? value
        : value
            .filter((item) => selectedRowKeys.includes(item.key))
            .map((item) => item.infoCD || item)
    // Chỉ sử dụng cho in lại mới cần ngày chỉ định (các phiếu còn lại k cần sẽ lấy ngày hiện tại(do các phiếu còn lại k đc in lại))
    const ngayChiDinh =
      selectedRowKeys.length === 0
        ? ValuePrinter[0]?.infoCD?.ngaysudung
        : ValuePrinter[0]?.ngaysudung
    generatePhieuChiDinh(
      infoUser,
      DetailBN,
      ValuePrinter,
      PkDangNhap,
      DetailBN.chandoanbandau,
      ngayChiDinh,
    )
  }

  return (
    <div className="mt-2">
      {contextHolder}
      <div className="flex justify-end">
        {/* <Button type="primary" onClick={handleSubmit} disabled={!DetailBN}>
          In chỉ định
        </Button> */}
        <Button
          loading={isLoadChiDinhBN}
          disabled={!infoBN || DetailBN?.trangthaikhambenh === 4}
          type="primary"
          onClick={handleSubmit}
        >
          Lưu & In
        </Button>
        {DetailBN?.trangthaikhambenh === 4 && (
          <Button loading={isLoadChiDinhBN} onClick={handlePrint} className="ml-4" type="primary">
            In
          </Button>
        )}
      </div>
      <div className="flex border rounded-md  mt-2 gap-1">
        <div className="w-1/2 border-r">
          <div className="p-2">
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: 'Khám bệnh',
                  key: '1',
                  children: (
                    <div>
                      <Select
                        onChange={handleSelected('dv')}
                        filterOption={(input, option) =>
                          (option?.label ?? '')?.toLowerCase()?.includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            ?.toLowerCase()
                            ?.localeCompare((optionB?.label ?? '')?.toLowerCase())
                        }
                        showSearch
                        value={valueDV === -1 ? 'CN không có dịch vụ này' : valueDV}
                        options={groupsDV?.map((items) => ({
                          key: `dv` + items?.idNhom,
                          label: items?.tenNhom,
                          value: items?.idNhom,
                        }))}
                        className="w-full"
                      />
                      <div>
                        <div className="mt-2">
                          <Input.Search
                            onChange={handleSearchChange}
                            placeholder="Nhập từ khoá tìm kiếm "
                          />
                        </div>
                        <ul className="border h-[465px] overflow-auto my-5 rounded p-2 flex flex-col gap-2">
                          {!isLoad ? (
                            filteredAndSortedDichVu.map((items) => (
                              <li
                                key={items.iddvcn}
                                className="border rounded min-h-10 flex justify-between p-2 items-center hover:bg-blue-50"
                              >
                                <p>{items.tenChiDinh}</p>
                                <span
                                  onClick={() => onClickChiDing('dv', items)}
                                  className="cursor-pointer hover:text-green-700"
                                >
                                  <PlusOutlined />
                                </span>
                              </li>
                            ))
                          ) : (
                            <Skeleton active />
                          )}
                        </ul>
                      </div>
                    </div>
                  ),
                },
                {
                  label: 'Xét nghiệm',
                  key: '2',
                  children: (
                    <div>
                      {/* <Select
                        onChange={handleSelected('xn')}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        showSearch
                        value={valueXN === -1 ? 'CN không có dịch vụ này' : valueXN}
                        options={groupsXN?.map((items) => ({
                          key: `xn` + items?.idnhomxn,
                          label: items?.tennhom,
                          value: items?.idnhomxn,
                        }))}
                        className="w-full"
                      /> */}
                      <div>
                        <div className="mt-2">
                          <Input.Search
                            onChange={(e) => setsearchXN(e.target.value)}
                            placeholder="Nhập từ khoá tìm kiếm "
                          />
                        </div>
                        <ul className="border h-[465px] overflow-auto my-5 rounded p-2 flex flex-col gap-2">
                          {!isLoad ? (
                            filteredAndSortedXetNghiem?.map((items) => (
                              <li
                                key={items.idxncn}
                                className="border rounded min-h-10 flex justify-between p-2 items-center hover:bg-blue-50"
                              >
                                <p>{items.tenChiDinh}</p>
                                <span
                                  onClick={() => onClickChiDing('xn', items)}
                                  className="cursor-pointer hover:text-green-700"
                                >
                                  <PlusOutlined />
                                </span>
                              </li>
                            ))
                          ) : (
                            <Skeleton active />
                          )}
                        </ul>
                      </div>
                    </div>
                  ),
                },
                {
                  label: 'Chẩn đoán hình ảnh',
                  key: '3',
                  children: (
                    <div>
                      <Select
                        onChange={handleSelected('cdha')}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        showSearch
                        value={valueCDHA === -1 ? 'CN không có dịch vụ này' : valueCDHA}
                        options={groupsCDHA.map((items) => ({
                          key: `cdha` + items?.idnhomcdha,
                          label: items?.tennhom,
                          value: items?.idnhomcdha,
                        }))}
                        className="w-full"
                      />
                      <div>
                        <div className="mt-2">
                          <Input.Search
                            onChange={(e) => setsearchCdha(e.target.value)}
                            placeholder="Nhập từ khoá tìm kiếm "
                          />
                        </div>
                        <ul className="border h-[465px] overflow-auto my-5 rounded p-2 flex flex-col gap-2">
                          {!isLoad ? (
                            filteredAndSortedCDHA.map((items) => (
                              <li
                                key={items.idChiDinh}
                                className="border rounded min-h-10 flex justify-between p-2 items-center hover:bg-blue-50"
                              >
                                <p>{items.tenChiDinh}</p>
                                <span
                                  onClick={() => onClickChiDing('cdha', items)}
                                  className="cursor-pointer hover:text-green-700"
                                >
                                  <PlusOutlined />
                                </span>
                              </li>
                            ))
                          ) : (
                            <Skeleton active />
                          )}
                        </ul>
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="p-2">
            <ConfigProvider
              theme={{
                token: { padding: 5 },
                // components: {
                //   Table: {
                //     rowHoverBg: '#ecf0f1',
                //     headerBg: '#e6e6e6',
                //     footerBg: '#e6e6e6',
                //     borderColor: '#BABABA',
                //   },
                // },
              }}
            >
              <Table
                loading={isLoadChiDinhBN}
                rowSelection={rowSelection}
                pagination={false}
                scroll={{ y: 550 }}
                dataSource={value}
                columns={[
                  {
                    title: 'Dịch vụ',
                    dataIndex: 'name',
                  },
                  {
                    title: 'Giá',
                    width: 100,
                    align: 'right',
                    dataIndex: 'dongia',
                    render: (text, record, index) => formatNumberVND(text ?? 0),
                  },
                  {
                    title: '',
                    width: 40,
                    align: 'center',
                    render: (text, record, index) =>
                      record.old ? (
                        <></>
                      ) : (
                        <span
                          onClick={() => deleteChiDinh(record.type, record.idchiDinh, record.key)}
                          className="text-red-500 bg-red-50 p-1 rounded cursor-pointer"
                        >
                          <DeleteOutlined />
                        </span>
                      ),
                  },
                ]}
                summary={(pageData) => {
                  let total = 0
                  pageData.forEach(({ dongia }) => {
                    total += dongia
                  })

                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align="right">
                        <p className="font-semibold">Tổng tiền:</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} align="right">
                        <p className="font-semibold"> {formatNumberVND(total)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    </Table.Summary>
                  )
                }}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormCLS
