import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Input, DatePicker, Select, Spin, Radio, ConfigProvider, Button } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined, SyncOutlined } from '@ant-design/icons'
import { putInfoKhamLamSanKSKAction } from '../../../../../../store/actions/khamSucKhoeAction'
import ToastCus from '../../../../../../utils/Toast'
// import styled from 'styled-components'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { TextArea } = Input
// const BtnLuu = styled(Button)`
//   color: #fff;
//   border-color: #fff;
//   background-color: #1677ff;

//   &:hover {
//     color: #fff !important;
//     border-color: #2566e8 !important;
//     background-color: #2566e8 !important;
//   }
// `
const listPhanLoai = [
  {
    value: 1,
    label: 'Loại I',
  },
  {
    value: 2,
    label: 'Loại II',
  },
  {
    value: 3,
    label: 'Loại III',
  },
  {
    value: 4,
    label: 'Loại IV',
  },
  {
    value: 5,
    label: 'Loại V',
  },
  {
    value: 6,
    label: 'Không phân loại',
  },
]

function TabKetLuan({ tabForm, reloadListBN }) {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBN, infoBNTTC, infoToa, infoKetLuanKSK } = useSelector(
    (state) => state.khamSucKhoeReducer,
  )
  const isBsDuocKeToa =
    infoBNTTC?.trangthaikhambenh === 1 ||
    (infoBNTTC?.trangthaikhambenh === 2 && !infoToa?.DANHANTOA)
      ? true
      : false
  const initialState = {
    idbnttc: infoBNTTC?.idbnttc,
    ketluannhantrachoc: infoKetLuanKSK?.ketluannhantrachoc ?? '',
    canlamsang: infoKetLuanKSK?.canlamsang ?? '',
    khamlamsang: infoKetLuanKSK?.khamlamsang ?? '',
    ketluan: infoKetLuanKSK?.ketluan ?? '',
    cacbenhtattheothongtu: infoKetLuanKSK?.cacbenhtattheothongtu ?? '',
    loai: infoKetLuanKSK?.loai ?? 1,
    danhgia: infoKetLuanKSK?.danhgia ?? 1,
  }
  const [form, setForm] = useState(initialState)
  // const [rowCount, setRowCount] = useState(3)

  const resetForm = () => {
    setForm(initialState)
    if (tabForm === 'ketluan') {
      reloadListBN()
    }
  }
  const handleChangeForm = (name, value) => {
    setForm((prev) => ({ ...prev, [name]: value }))
  }
  const handleSubmit = () => {
    if (!isBsDuocKeToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    dispatch(
      putInfoKhamLamSanKSKAction(
        { ...form, idbnttc: infoBNTTC?.idbnttc, bskham: infoUser?.idnv },
        tabForm,
        resetForm,
      ),
    )
  }
  useEffect(() => {
    if (infoBNTTC?.idbnttc !== form?.idbnttc) {
      setForm(initialState)
    }
  }, [tabForm])
  useEffect(() => {
    setForm(initialState)
  }, [infoBNTTC, infoKetLuanKSK])
  // const divFormKham = (labelKham, fieldKham, fieldLoai, fieldGhichu) => {
  //   return (
  //     <div className="grid grid-cols-12 items-center text-[12.5px] border rounded-md p-1 bg-slate-50">
  //       <div className="col-span-6 grid grid-cols-6 items-center gap-1">
  //         <div className="font-semibold text-end">{labelKham}:</div>
  //         <TextArea
  //           className="col-span-5 w-full text-[12.5px]"
  //           rows={3}
  //           // autoSize={{ minRows: 3, maxRows: rowCount }}
  //           //   showCount
  //           maxLength={2000}
  //           value={form[fieldKham]}
  //           onChange={(e) => handleChangeForm(fieldKham, e.target.value)}
  //         />
  //       </div>
  //       <div className="col-span-3 grid grid-cols-4 items-center gap-1">
  //         <div className="font-semibold text-end">Phân loại:</div>
  //         <div className="col-span-3 w-full border rounded-md pl-2 py-1 bg-white">
  //           <Radio.Group
  //             //   onChange={onChange} value={value}
  //             // defaultValue={1}
  //             value={infoBNTTC ? form[fieldLoai] : null}
  //             onChange={(e) => handleChangeForm(fieldLoai, e.target.value)}
  //             className="grid grid-cols-2"
  //           >
  //             {listPhanLoai?.map((item) => (
  //               <Radio value={item?.value} className="text-[12.5px] flex items-center">
  //                 {item?.label}
  //               </Radio>
  //             ))}
  //           </Radio.Group>
  //         </div>
  //       </div>
  //       <div className="col-span-3 grid grid-cols-4 items-center gap-1">
  //         <div className="font-semibold text-end">Ghi chú:</div>
  //         <TextArea
  //           className="col-span-3 w-full text-[12.5px]"
  //           rows={3}
  //           // autoSize={{ minRows: 3, maxRows: rowCount }}
  //           //   showCount
  //           value={form[fieldGhichu]}
  //           onChange={(e) => handleChangeForm(fieldGhichu, e.target.value)}
  //           maxLength={2000}
  //         />
  //       </div>
  //     </div>
  //   )
  // }
  return (
    <div>
      <div className="flex justify-start items-center">
        <div className="w-[25rem]">
          <span className="font-semibold">BS Kết luận:&nbsp;</span>
          {infoBNTTC?.idbnttc ? infoKetLuanKSK?.tenbsketluan : ''}
        </div>
        <Button
          type="primary"
          className="ml-auto"
          onClick={handleSubmit}
          disabled={!infoBN || !infoBNTTC || infoBNTTC?.trangthaikhambenh === 4}
        >
          Lưu
        </Button>
      </div>
      <div className="grid grid-flow-row items-center mt-2 gap-3">
        {/* {divFormKham('Khám', 'kham', 'loai', 'ghichu')} */}
        {/* {divFormKham('b. Hô hấp', 'hohap', 'hohaploai', 'hohapghichu')}
        {divFormKham('c. Tiêu hóa', 'tieuhoa', 'tieuhoaloai', 'tieuhoaghichu')}
        {divFormKham(
          'd. Thận - tiết niệu',
          'thantietnieu',
          'thantietnieuloai',
          'thantietnieughichu',
        )}
        {divFormKham('đ. Nội tiết', 'noitiet', 'noitietloai', 'noitietghichu')}
        {divFormKham('e. Cơ xương khớp', 'coxuongkhop', 'coxuongkhoploai', 'coxuongkhopghichu')}
        {divFormKham('g. Thần kinh', 'thankinh', 'thankinhloai', 'thankinhghichu')}
        {divFormKham('h. Tâm thần', 'tamthan', 'tamthanloai', 'tamthanghichu')} */}

        <div className="grid grid-cols-2 items-center text-[12.5px] border rounded-md p-1 bg-slate-50">
          <div className="grid grid-cols-6 gap-1 items-center">
            <div className="font-semibold text-end">
              Kết luận
              <br />
              nhân trắc học:
            </div>
            <TextArea
              className="col-span-5 w-full text-[12.5px]"
              rows={3}
              maxLength={2000}
              value={form?.ketluannhantrachoc}
              onChange={(e) => handleChangeForm('ketluannhantrachoc', e.target.value)}
            />
          </div>
          <div className="grid grid-cols-6 gap-1 items-center">
            <div className="font-semibold text-end">Cận lâm sàng:</div>
            <TextArea
              className="col-span-5 w-full text-[12.5px]"
              rows={3}
              maxLength={2000}
              value={form?.canlamsang}
              onChange={(e) => handleChangeForm('canlamsang', e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 items-center text-[12.5px] border rounded-md p-1 bg-slate-50">
          <div className="grid grid-cols-6 gap-1 items-center">
            <div className="font-semibold text-end">Khám lâm sàng:</div>
            <TextArea
              className="col-span-5 w-full text-[12.5px]"
              rows={3}
              maxLength={2000}
              value={form?.khamlamsang}
              onChange={(e) => handleChangeForm('khamlamsang', e.target.value)}
            />
          </div>
          <div className="grid grid-cols-6 gap-1 items-center">
            <div className="font-semibold text-end">
              Các bệnh tật
              <br />
              theo thông tư:
            </div>
            <TextArea
              className="col-span-5 w-full text-[12.5px]"
              rows={3}
              maxLength={2000}
              value={form?.cacbenhtattheothongtu}
              onChange={(e) => handleChangeForm('cacbenhtattheothongtu', e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-12 gap-1 items-center text-[12.5px] border rounded-md p-1 bg-slate-50">
          <div className="font-semibold text-end">Kết luận:</div>
          <TextArea
            className="col-span-11 w-full text-[12.5px]"
            rows={3}
            maxLength={2000}
            value={form?.ketluan}
            onChange={(e) => handleChangeForm('ketluan', e.target.value)}
          />
        </div>
        <div className="grid grid-cols-2 items-center text-[12.5px] border rounded-md p-1 bg-slate-50">
          <div className="grid grid-cols-6 gap-1 items-center">
            <div className="font-semibold text-end">Phân loại:</div>
            <div className="col-span-5 w-full border rounded-md pl-2 py-1 bg-white">
              <Radio.Group
                value={infoBNTTC ? form?.loai : null}
                onChange={(e) => handleChangeForm('loai', e.target.value)}
                className="grid grid-cols-6"
              >
                {listPhanLoai?.map((item) => (
                  <Radio value={item?.value} className="text-[12.5px] flex items-center">
                    {item?.label}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-1 items-center">
            <div className="font-semibold text-end">Đánh giá:</div>
            <div className="col-span-5 w-full border rounded-md pl-2 py-6 bg-white">
              <Radio.Group
                value={infoBNTTC ? form?.danhgia : null}
                onChange={(e) => handleChangeForm('danhgia', e.target.value)}
                className="grid grid-cols-2"
              >
                <Radio value={1} className="text-[12.5px] flex items-center">
                  Đủ sức khỏe làm việc
                </Radio>
                <Radio value={2} className="text-[12.5px] flex items-center">
                  Không đủ sức khỏe làm việc
                </Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabKetLuan
