import React, { useEffect, useState } from 'react'
import { ConfigProvider, Tabs } from 'antd'
import Branch from './Branch/Branch'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import Doctor from './Doctor/Doctor'
import Detail from './Detail/Detail'

const ThoiGianKham = () => {
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchChuyenKhoa()
  }, [])
  return (
    <div className="p-3 bg-[#EFEFEF] h-full">
      <div className="bg-white p-2 rounded-lg border h-full">
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                itemColor: '#888888',
                padding: 5,
              },
            },
          }}
        >
          <Tabs
            items={[
              {
                key: 1,
                label: <p className="font-semibold ">Theo Chi nhánh</p>,
                children: <Branch ChuyenKhoa={ChuyenKhoa} />,
              },
              {
                key: 2,
                label: <p className="font-semibold ">Theo bác sĩ</p>,
                children: <Doctor ChuyenKhoa={ChuyenKhoa} />,
              },
              {
                key: 3,
                label: <p className="font-semibold ">Chi tiết phiếu</p>,
                children: <Detail ChuyenKhoa={ChuyenKhoa} />,
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default ThoiGianKham
