import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Button, Input, DatePicker, Select, Spin, Segmented } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined, SyncOutlined } from '@ant-design/icons'
import ListBN from '../KhamSucKhoe/ListBN/ListBN'
import InfoBN from '../KhamSucKhoe/InfoBN/InfoBN'
import SinhHieu from '../KhamSucKhoe/SinhHieu/SinhHieu'
import FormCD from './TabKham/FormCD/FormCD'
import FormCLS from '../KhamSucKhoe/TabKham/FormCLS/FormCLS'
// import FormKLS from './TabKham/FormKLS/FormKLS'
import FormKQXN from '../ChildrenClinic/General/Tab/FormKQXN/FormKQXN'
import FormKQCDHA from '../MaternityClinic/General/Tab/FormKQCDHA/FormKQCDHA'
import HistoryTongQuat from '../ChildrenClinic/General/Tab/HistoryTongQuat/HistoryTongQuat'
// import General from './General/General'
import * as typeAction from '../../../store/constants/constants'
import { khamNoiKhoaService } from '../../../services/khamNoiKhoa/khamNoiKhoaService'
import { recieveService } from '../../../services/receive/recieveService'
import { getInfoBNKhamAction } from '../../../store/actions/khamSucKhoeAction'
import ToastCus from '../../../utils/Toast'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const KhamNoiKhoa = () => {
  const dispatch = useDispatch()
  const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_CONGTY_LOGIN = Number(localStorage.getItem('id_company'))
  const INFO_USER_LOGIN = JSON.parse(localStorage.getItem('USER_INFO'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { historyKham } = useSelector((state) => state.childrenClinicReducer)
  const { infoBN, infoBNTTC } = useSelector((state) => state.khamSucKhoeReducer)
  const [isShowTab, setIsShowTab] = useState(true)
  const [tabListBN, setTabListBN] = useState(1)
  const [tabKham, setTabKham] = useState(1)
  const [loadingListBNCho, setLoadingListBNCho] = useState(false)
  const [loadingListBNDaVaDang, setLoadingListBNDaVaDang] = useState(false)
  const [dateFrom, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [formBenhNhan, setFormBenhNhan] = useState(null)
  const [formSinhHieu, setFormSinhHieu] = useState(null)

  const [listCnPk, setlistCnPk] = useState([])
  const [idCnPkChon, setidCnPkChon] = useState(null)

  const [listBNCho, setlistBNCho] = useState([])
  const [listBNDang, setlistBNDang] = useState([])
  const [listBNDa, setlistBNDa] = useState([])

  const isEdit = tabKham === 1 ? true : false // chọn tab formcd mới đc edit
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const reloadListBN = () => {
    getListBNCho(idCnPkChon)
    getListBNDaVaDang()
  }
  const getListCnPk = async () => {
    try {
      setLoadingListBNCho(true)
      const { data } = await recieveService.getPhongKham(ID_CHINHANH_LOGIN)
      const newArr = data
        ?.filter((item) => item?.tenPhongKham?.trim()?.toLowerCase()?.includes('khám nội'))
        ?.sort((a, b) =>
          a?.tenPhongKham
            ?.trim()
            ?.toLowerCase()
            ?.localeCompare(b?.tenPhongKham?.trim()?.toLowerCase()),
        )
      if (newArr?.length) {
        setidCnPkChon(newArr[0]?.idcnpk)
        getListBNCho(newArr[0]?.idcnpk)
      }
      setlistCnPk(newArr)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    } finally {
      setLoadingListBNCho(false)
    }
    // dispatch(getListBNChoAction(setLoadingListBNCho))
  }
  const getListBNCho = async (idcnpk) => {
    try {
      setLoadingListBNCho(true)
      const { data } = await khamNoiKhoaService.getListBNCho(idcnpk)
      setlistBNCho(data)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    } finally {
      setLoadingListBNCho(false)
    }
    // dispatch(getListBNChoAction(setLoadingListBNCho))
  }
  const getListBNDaVaDang = async () => {
    // dispatch(getListBNDaVaDangAction(dateFrom, dateTo, setLoadingListBNDaVaDang))
    try {
      setLoadingListBNDaVaDang(true)
      const { data: dsBnDang } = await khamNoiKhoaService.getListBNDang(
        INFO_USER_LOGIN?.idnv,
        dateFrom,
        dateTo,
      )
      setlistBNDang(dsBnDang)
      const { data: dsBnDa } = await khamNoiKhoaService.getListBNDa(
        INFO_USER_LOGIN?.idnv,
        dateFrom,
        dateTo,
      )
      setlistBNDa(dsBnDa)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    } finally {
      setLoadingListBNDaVaDang(false)
    }
  }
  const handleGetInfoBNKham = (idbn, idbnttc) => {
    // console.log(item)
    dispatch(getInfoBNKhamAction(idbn, idbnttc))
  }
  useEffect(() => {
    dispatch({
      type: typeAction.RESET_STORE_CLINIC,
    })
    dispatch({
      type: typeAction.DISPATCH_RESET_STORE_KSK,
    })
    getListCnPk()
    getListBNDaVaDang()
  }, [])
  // useEffect(() => {
  // }, [listCnPk])
  useEffect(() => {
    if (!infoBN || !infoBN?.idbn) {
      setFormBenhNhan(null)
      return
    }

    setFormBenhNhan((prev) => ({
      ...prev,
      ...infoBN,
      diUng: infoBN?.diUng,
      tienSuBanThan: infoBN?.tienSuBanThan,
      dienThoai: infoBN?.dienThoai,
    }))
  }, [infoBN, infoBN?.idbn])
  useEffect(() => {
    if (!infoBNTTC || !infoBNTTC?.idbnttc) {
      setFormSinhHieu(null)
      return
    }

    setFormSinhHieu((prev) => ({
      ...prev,
      ...infoBNTTC,
      chieucao: infoBNTTC?.chieucao,
      cannang: infoBNTTC?.cannang,
      mach: infoBNTTC?.mach,
      huyetap: infoBNTTC?.huyetap,
      nhietdo: infoBNTTC?.nhietdo,
      nhiptho: infoBNTTC?.nhiptho,
      spO2: infoBNTTC?.spO2,
    }))
  }, [infoBNTTC, infoBNTTC?.idbnttc])
  // useEffect(() => {
  //   if (tabKham === 2 && infoBNTTC?.idbnttc) {
  //     dispatch(getInfoBNKhamLamSangAction(infoBNTTC?.idbnttc))
  //   }
  // }, [tabKham, infoBNTTC, infoBNTTC?.idbnttc])
  return (
    <>
      <div className="bg-[#EBEBEB] w-full h-full p-2 gap-2 flex justify-start items-start">
        <div
          className={`relative bg-white border rounded-lg px-2 ${isShowTab ? 'h-full w-[20%]' : 'h-[3.2rem] w-[3.2rem]'}`}
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="absolute z-10 right-2 top-2">
            <Button
              // type="link"
              // className="bg-black"
              icon={isShowTab ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
              onClick={() => setIsShowTab((prev) => !prev)}
            />
          </div>
          <div className="relative">
            {isShowTab && (
              <Tabs
                activeKey={tabListBN}
                onChange={(key) => {
                  setTabListBN(key)
                }}
                items={[
                  {
                    key: 1,
                    value: 1,
                    label: 'Chờ khám',
                    children: (
                      <>
                        <div className="flex justify-end items-center gap-2">
                          <Select
                            className="w-full"
                            // defaultValue={1}
                            // options={[{ key: 1, value: 1, label: 'Phòng khám sức khỏe' }]}
                            value={idCnPkChon}
                            onChange={(value) => setidCnPkChon(value)}
                            options={listCnPk?.map((item) => ({
                              key: item?.idcnpk,
                              value: item?.idcnpk,
                              label: item?.tenPhongKham,
                            }))}
                          />
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<SyncOutlined />}
                            onClick={() => getListBNCho(idCnPkChon)}
                            loading={loadingListBNCho}
                          />
                        </div>
                        <div className="text-md mt-1 py-1 border-b">
                          <span className="font-semibold">BS Thực hiện:&nbsp;</span>
                          {infoUser?.tenNV}
                        </div>
                        <Spin
                          spinning={loadingListBNCho}
                          //tip="Loading..."
                        >
                          <ListBN
                            tabListBN={tabListBN}
                            listBN={listBNCho}
                            handleGetInfoBNKham={handleGetInfoBNKham}
                            formSinhHieu={formSinhHieu}
                          />
                        </Spin>
                      </>
                    ),
                  },
                  {
                    key: 2,
                    value: 2,
                    label: 'Đã khám',
                    children: (
                      <>
                        <div className="flex justify-end items-center gap-2">
                          <div className="flex justify-between items-start gap-2">
                            <DatePicker
                              placeholder=" "
                              allowClear={false}
                              defaultValue={dayjs(now)}
                              maxDate={dayjs(now)}
                              onChange={handleDatePicker('form')}
                              format={dateFormat}
                            />
                            <span className="font-semibold text-2xl">-</span>
                            <DatePicker
                              placeholder=" "
                              allowClear={false}
                              defaultValue={dayjs(now)}
                              maxDate={dayjs(now)}
                              onChange={handleDatePicker('to')}
                              format={dateFormat}
                            />
                          </div>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<SyncOutlined />}
                            // onClick={() => setLoadingListBNDa(true)}
                            onClick={getListBNDaVaDang}
                            loading={loadingListBNDaVaDang}
                          />
                        </div>
                        <div className="text-md mt-1 py-1 border-b">
                          <span className="font-semibold">BS Thực hiện:&nbsp;</span>
                          {infoUser?.tenNV}
                        </div>
                        <Spin
                          spinning={loadingListBNDaVaDang}
                          //tip="Loading..."
                        >
                          <ListBN
                            tabListBN={tabListBN}
                            listBN={[...listBNDang, ...listBNDa]}
                            handleGetInfoBNKham={handleGetInfoBNKham}
                            formSinhHieu={formSinhHieu}
                          />
                        </Spin>
                      </>
                    ),
                  },
                ]}
              />
            )}
          </div>
          {/* <div className="row-span-2">row 1</div>   grid grid-rows-3 */}
          {/* <div className="">row 2</div> */}
        </div>
        <div className={`${isShowTab ? 'w-[80%]' : 'w-full'}`}>
          <div className="grid grid-flow-row gap-2">
            <InfoBN
              isShowTab={isShowTab}
              isEdit={isEdit}
              formBenhNhan={formBenhNhan}
              setFormBenhNhan={setFormBenhNhan}
              formSinhHieu={formSinhHieu}
            />
            <SinhHieu
              isEdit={isEdit}
              // formBenhNhan={formBenhNhan}
              formSinhHieu={formSinhHieu}
              setFormSinhHieu={setFormSinhHieu}
            />
            <div
              className="bg-white border rounded-lg p-2"
              style={{
                boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
              }}
            >
              <Segmented
                options={[
                  { key: 1, value: 1, label: 'Chẩn đoán' },
                  // { key: 2, value: 2, label: 'Khám lâm sàng' },
                  { key: 3, value: 3, label: 'Lịch sử tổng quát' },
                  { key: 4, value: 4, label: 'Chỉ định cận lâm sàng' },
                  { key: 5, value: 5, label: 'Kết quả xét nghiệm' },
                  { key: 6, value: 6, label: 'Kết quả CĐHA' },
                ]}
                value={tabKham}
                onChange={(value) => setTabKham(value)}
              />
              <div>
                {tabKham === 1 && (
                  <>
                    <FormCD
                      formBenhNhan={formBenhNhan}
                      formSinhHieu={formSinhHieu}
                      tabKham={tabKham}
                      reloadListBN={reloadListBN}
                    />
                  </>
                )}
                {/* {tabKham === 2 && (
                  <>
                    <FormKLS reloadListBN={reloadListBN} />
                  </>
                )} */}
                {tabKham === 3 && (
                  <>
                    <div
                      className={`${isShowTab ? 'w-[83.7rem]' : 'w-[101.7rem]'}`}
                      // className="w-[83.7rem]"
                      // className="w-[101.7rem]"
                    >
                      <HistoryTongQuat historyKham={historyKham} />
                    </div>
                  </>
                )}
                {tabKham === 4 && (
                  <>
                    <FormCLS />
                  </>
                )}
                {tabKham === 5 && (
                  <>
                    <FormKQXN />
                  </>
                )}
                {tabKham === 6 && (
                  <>
                    <FormKQCDHA />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default KhamNoiKhoa
