import React from 'react'
import { Select, ConfigProvider, Table, Input, InputNumber } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import ToastCus from '../../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const VatTu = ({ isLoadingVatTu, listVT, listVtthBn, setlistVtthBn, isKhongDuocChinhSua }) => {
  const handleChonVatTu = (value, opt) => {
    if (isKhongDuocChinhSua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ KTV X-Quang và cùng ngày mới được chỉnh sửa!',
      })
      return
    }
    if (listVtthBn?.find((item) => item?.key === opt?.key)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vật tư này đã được chọn!',
      })
      return
    }
    setlistVtthBn((prev) => [
      ...prev,
      {
        key: opt?.key,
        // idbnttc: infoBNTiemChung?.IDBNTTC,
        idthuoc: opt?.info?.idThuoc,
        tenbietduoc: opt?.info?.thuocVatTu?.tenBietDuoc,
        tenhoatchat: opt?.info?.thuocVatTu?.tenHoatChat,
        dvt: opt?.info?.thuocVatTu?.dvt,
        nongdo: opt?.info?.thuocVatTu?.nongDo,
        hamluong: opt?.info?.thuocVatTu?.hamLuong,
        quycach: opt?.info?.thuocVatTu?.quyCach,
        dongia: opt?.info?.thuocVatTu?.giaBan,
        soluong: 1,
        // thanhTien: opt?.info?.thuocVatTu?.giaBan,
        // tongTien: opt?.info?.thuocVatTu?.giaBan,
        // bsKeDon: infoBNTiemChung?.BSCHANDOAN,
        // nguoiThucHien: infoUser?.tenNV,
        ghichu: '',
        idkhocn: opt?.info?.idKhoCN,
        solo: opt?.info?.soLo,
        duongdung: opt?.info?.thuocVatTu?.duongDung,
        handung: opt?.info?.hanDung,
        // idcdha: opt?.info?.hanDung,
        sohoadon: opt?.info?.soHoaDon,
        ton: opt?.info?.tonKho,
        // masodangky: opt?.info?.maSoDangKy,
      },
    ])
  }
  return (
    <div className="px-2 pb-2">
      <Select
        disabled={isLoadingVatTu}
        placeholder={isLoadingVatTu ? 'Đang lấy dữ liệu...' : 'Nhập tên vật tư...'}
        className="w-full mb-2"
        showSearch
        onChange={handleChonVatTu}
        filterOption={(input, option) => {
          const label = option?.label ?? ''
          const tenBietDuoc = option?.tenBietDuoc ?? ''
          const searchText = input.toLowerCase()
          return (
            label.toLowerCase().includes(searchText) ||
            tenBietDuoc.toLowerCase().includes(searchText)
          )
        }}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
        value={null}
        options={listVT?.map((items) => ({
          key: `${items?.idThuoc}${items?.soLo}${items?.hanDung}${items?.soHoaDon}${items?.idKhoCN}`,
          value: `${items?.idThuoc}${items?.soLo}${items?.hanDung}${items?.soHoaDon}${items?.idKhoCN}`,
          label: items?.thuocVatTu?.tenHoatChat,
          tenBietDuoc: items?.thuocVatTu?.tenBietDuoc,
          dvt: items?.thuocVatTu?.dvt,
          soLo: items?.soLo,
          hanDung: items?.hanDung,
          soHoaDon: items?.soHoaDon,
          maSoDangKy: items?.maSoDangKy,
          ton: items?.tonKho,
          info: items,
        }))}
        dropdownRender={(menu) => (
          <div className="text-[12px]">
            <div className="flex bg-gray-100" style={{ padding: '8px', fontWeight: 'bold' }}>
              <span className="w-[50%] text-center">Tên hàng</span>
              <span className="w-[12.5%] text-center">Số lô</span>
              <span className="w-[12.5%] text-center">Hạn dùng</span>
              <span className="w-[12.5%] text-center">Đơn vị</span>
              <span className="w-[12.5%] text-center">Tồn</span>
            </div>
            <div style={{ marginBottom: '8px' }}>{menu}</div>
          </div>
        )}
        optionLabelProp="label"
        optionRender={(options) => (
          <ul className="flex text-[12px]">
            <li className="w-[50%] ">
              <p className="font-semibold w-full text-pretty">{options?.data?.label}</p>
              <span className="text-xs w-full text-gray-500"> ({options?.data?.tenBietDuoc})</span>
            </li>
            <li className="w-[12.5%] border-x flex items-center justify-center text-center">
              {options?.data?.soLo}
            </li>
            <li className="w-[12.5%] text-center flex items-center justify-center">
              {options?.data?.hanDung ? moment(options?.data?.hanDung).format('DD/MM/YYYY') : '-'}
            </li>
            <li className="w-[12.5%] border-x text-center flex items-center justify-center">
              {options?.data?.dvt}
            </li>
            <li className="w-[12.5%] text-center flex items-center justify-center">
              {options?.data?.ton}
            </li>
          </ul>
        )}
      />
      <div className="grid grid-flow-row">
        <ConfigProvider
          theme={{
            token: { padding: 3, fontSize: 13 },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
              },
            },
          }}
        >
          <Table
            scroll={{ x: 1000, y: 294 }}
            className="min-w-1"
            bordered
            pagination={false}
            dataSource={listVtthBn}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 35,
                align: 'center',
                fixed: 'left',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Tên vật tư',
                dataIndex: 'tenbietduoc',
                key: 'tenbietduoc',
                fixed: 'left',
              },
              {
                title: 'Số lô',
                dataIndex: 'solo',
                key: 'solo',
                width: 120,
              },
              {
                title: 'Hạn dùng',
                dataIndex: 'handung',
                key: 'handung',
                align: 'center',
                width: 90,
                render: (text, record, index) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
              },
              {
                title: 'ĐVT',
                dataIndex: 'dvt',
                key: 'dvt',
                align: 'center',
                width: 60,
              },
              {
                title: 'SL',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 70,
                render: (text, record, index) => (
                  <InputNumber
                    size="small"
                    className="w-full"
                    variant={isKhongDuocChinhSua ? 'filled' : 'outlined'}
                    readOnly={isKhongDuocChinhSua}
                    min={1}
                    step={1}
                    max={record?.ton}
                    status={
                      !text || !record?.ton || text <= 0 || record?.ton <= 0 || text > record?.ton
                        ? 'error'
                        : ''
                    }
                    value={text}
                    onChange={(value) => {
                      if (isKhongDuocChinhSua) {
                        ToastCus.fire({
                          icon: 'error',
                          title: 'Chỉ KTV X-Quang và cùng ngày mới được chỉnh sửa!',
                        })
                        return
                      }
                      setlistVtthBn((prev) =>
                        prev?.map((item, idx) =>
                          idx === index ? { ...item, soluong: value } : item,
                        ),
                      )
                    }}
                  />
                ),
              },
              {
                title: 'Tồn',
                dataIndex: 'ton',
                key: 'ton',
                align: 'center',
                width: 70,
              },
              {
                title: 'Ghi chú',
                dataIndex: 'ghichu',
                key: 'ghichu',
                width: 200,
                render: (text, record, index) => (
                  <Input
                    size="small"
                    className="w-full"
                    variant={isKhongDuocChinhSua ? 'filled' : 'outlined'}
                    readOnly={isKhongDuocChinhSua}
                    value={text}
                    onChange={(e) => {
                      if (isKhongDuocChinhSua) {
                        ToastCus.fire({
                          icon: 'error',
                          title: 'Chỉ KTV X-Quang và cùng ngày mới được chỉnh sửa!',
                        })
                        return
                      }
                      setlistVtthBn((prev) =>
                        prev?.map((item, idx) =>
                          idx === index ? { ...item, ghichu: e.target.value } : item,
                        ),
                      )
                    }}
                  />
                ),
              },
              {
                title: '',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                fixed: 'right',
                width: 35,
                render: (text, record, index) =>
                  !isKhongDuocChinhSua && (
                    <CloseOutlined
                      className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                      onClick={() =>
                        setlistVtthBn((prev) => prev?.filter((item) => item?.key !== record?.key))
                      }
                    />
                  ),
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default VatTu
