import moment from 'moment'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { Tag } from 'antd'
import 'moment/locale/vi'
moment.locale('vi')

export const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
    width: 40,
    fixed: 'left',
    render: (text, record, index) => ++index,
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'tenchinhanh',
    key: 'tenchinhanh',
    width: 250,
    fixed: 'left',
    // ...getColumnSearchProps('tenchinhanh'),
  },
  {
    title: 'Ngày',
    dataIndex: 'ngaythanhtoan',
    key: 'ngaythanhtoan',
    width: 150,
    render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
  },
  {
    title: 'Mã BN',
    dataIndex: 'mabenhnhan',
    key: 'mabenhnhan',
    width: 200,
  },
  {
    title: 'Tên bệnh nhân',
    dataIndex: 'tenbenhnhan',
    key: 'tenbenhnhan',
    width: 200,
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'diachi',
    key: 'diachi',
    width: 250,
  },
  {
    title: 'Mã xét nghiệm',
    dataIndex: 'maxetnghiem',
    key: 'maxetnghiem',
    width: 150,
  },
  {
    title: 'Tên xét nghiệm',
    dataIndex: 'tenxetnghiem',
    key: 'tenxetnghiem',
    width: 200,
  },
  {
    title: 'Đơn Vị',
    dataIndex: 'dvt',
    key: 'dvt',
    width: 100,
  },
  {
    title: 'Thanh toán',
    dataIndex: 'thanhtoan',
    key: 'thanhtoan',
    width: 150,
    align: 'right',
    render: (text) => formatNumberVND(text ?? 0),
  },
  {
    title: 'Tiền gửi mẫu',
    dataIndex: 'tienguimau',
    key: 'tienguimau',
    width: 150,
    align: 'right',
    render: (text) => formatNumberVND(text ?? 0),
  },
  {
    title: 'Lợi nhuận',
    dataIndex: 'loinhuan',
    key: 'loinhuan',
    width: 150,
    align: 'right',
    fixed: 'right',
    render: (text) => formatNumberVND(text ?? 0),
  },

  {
    title: 'Trạng thái',
    dataIndex: 'trangthai',
    key: 'trangthai',
    width: 150,
    align: 'right',
    fixed: 'right',
    render: (text) => (
      <span style={{ color: text === '1' ? 'green' : 'red', fontWeight: 'bold' }}>
        {text === '1' ? 'Đã gửi mẫu' : 'Chưa gửi mẫu'}
      </span>
    ),
  },
]
