import { https } from "../apiService"

export const khachhangCRMService = {
    getAllKhachHangCRM: () => https.get('CRMKhachHang/GetAll'),
    addKhachHangCRM: (form) => https.post('CRMKhachHang/ADDKhachHang',form),
    editKhachHangCRM: (form) => https.put('CRMKhachHang/UpdateKhachHang',form),
    getKhachHangCRMById: (id) => https.get(`CRMKhachHang/GetCRMKhachHangByIDKH?idKH=${id}`),
    getAllBNCRM: (idKH,keyword) => https.get('CRMBenhNhan/SearchBenhNhanByCondition',{
        params:{
            idKH,keyword
        }
    })
}