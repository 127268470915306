import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Select, Tabs, DatePicker, Spin } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import ListBN from './ListBN/ListBN'
import InfoBN from './InfoBN/InfoBN'
import HinhAnh from './HinhAnh/HinhAnh'
import BieuMau from './BieuMau/BieuMau'
import LichSu from './LichSu/LichSu'
import { noiSoiService } from '../../../services/NoiSoi/noiSoiService'
import { branchService } from '../../../services/branch/branchService'
import { sieuAmService } from '../../../services/SieuAm/sieuAmService'
import { postInfoBnNoiSoiAction, putInfoBnNoiSoiAction } from '../../../store/actions/noiSoiAction'
import { generatePhieuSieuAm } from '../../../utils/report/san/phieuSieuAm'
import { generatePhieuSieuAmMultiImage } from '../../../utils/report/san/phieuSieuAmForMultiImageOnly'
import ToastCus from '../../../utils/Toast'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import _ from 'lodash'
import dayjs from 'dayjs'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'dayjs/locale/vi'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'

const NoiSoi = ({ tenNoiSoi }) => {
  const dispatch = useDispatch()
  const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_CONGTY_LOGIN = Number(localStorage.getItem('id_company'))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [infoBnNoiSoi, setinfoBnNoiSoi] = useState(null)
  const [listBnCho, setlistBnCho] = useState([])
  const [listBnDa, setlistBnDa] = useState([])
  const [isLoadingInfo, setisLoadingInfo] = useState(false)
  const [isLoadingDsCho, setisLoadingDsCho] = useState(false)
  const [isLoadingDsDa, setisLoadingDsDa] = useState(false)
  const [isLoadingLichSu, setisLoadingLichSu] = useState(false)
  const [listBieuMau, setlistBieuMau] = useState([])
  const [listCn, setlistCn] = useState([])
  const [idCn, setidCn] = useState(ID_CHINHANH_LOGIN)
  const [dateFrom, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [listImage, setlistImage] = useState([])
  const [selectedFile, setSelectedFile] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [infoBieuMau, setinfoBieuMau] = useState(null)
  const [ketLuan, setketLuan] = useState('')
  const [listLichSuNoiSoi, setlistLichSuNoiSoi] = useState([])
  // const [ghiChu, setghiChu] = useState(null)
  const isKhongDuocChinhSua =
    infoBnNoiSoi?.idbnns &&
    infoBnNoiSoi?.idttns &&
    (infoBnNoiSoi?.bsnoisoi !== infoUser?.idnv ||
      (infoBnNoiSoi?.ngaynoisoi &&
        moment().diff(moment(infoBnNoiSoi?.ngaynoisoi, momentDate), 'days') !== 0))
  const handleSubmit = (isPrint) => {
    if (!infoBnNoiSoi || !infoBnNoiSoi?.idbnttc || !infoBnNoiSoi?.idbncdha) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    if (isKhongDuocChinhSua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ BS nội soi và cùng ngày mới được chỉnh sửa!',
      })
      return
    }

    let motaNew = ''
    if (editorState) {
      const contentState = editorState.getCurrentContent()
      const row = convertToRaw(contentState)
      motaNew = JSON.stringify(row)
    }
    if (!infoBnNoiSoi?.idbnns || !infoBnNoiSoi?.idttns) {
      // lưu in lần đầu tiên tạo mới
      dispatch(
        postInfoBnNoiSoiAction(
          {
            infoBnNoiSoi: infoBnNoiSoi,
            listHinh: selectedFile,
            mota: motaNew,
            infoBieuMau: infoBieuMau,
            ketLuan: ketLuan,
            listHinhPrint: selectedFile?.filter((item) => item?.checked),
          },
          resetForm,
        ),
      )
      return
    }
    if (infoBnNoiSoi?.idttns === 1) {
      // nếu có nội soi rồi thi cập nhật
      let listHinhXoa = []
      infoBnNoiSoi?.linkhinhanh
        ?.split(';')
        .map(
          (urlXoa) => !listImage?.find((item) => item?.url === urlXoa) && listHinhXoa.push(urlXoa),
        )
      dispatch(
        putInfoBnNoiSoiAction(
          {
            infoBnNoiSoi: infoBnNoiSoi,
            listHinh: selectedFile,
            listHinhXoa: listHinhXoa,
            mota: motaNew,
            infoBieuMau: infoBieuMau,
            ketLuan: ketLuan,
            listHinhPrint: [
              ...listImage
                ?.filter((item) => item?.checked && infoBnNoiSoi?.linkhinhanh?.includes(item?.url))
                ?.map((item) => ({ base64: item?.url })),
              ...selectedFile?.filter((item) => item?.checked),
            ],
          },
          isPrint,
          resetForm,
        ),
      )
    }
  }
  const resetForm = () => {
    setSelectedFile([])
    setlistImage([])
    setinfoBieuMau(null)
    setEditorState(EditorState.createEmpty())
    setketLuan('')
    setinfoBnNoiSoi(null)
    getListBnCho()
    getListBnDa()
  }
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const handleBieuMau = (value, opt) => {
    if (isKhongDuocChinhSua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ BS nội soi và cùng ngày mới được chỉnh sửa!',
      })
      return
    }
    setinfoBieuMau({
      idbm: value,
      tieude: opt?.info?.tieude,
    })
    if (opt?.info?.mota) {
      const convertJson = JSON.parse(opt?.info?.mota)
      const contentState = convertFromRaw(convertJson)
      setEditorState(EditorState.createWithContent(contentState))
    } else {
      setEditorState(null)
    }
    setketLuan(opt?.info?.ketluan)
  }
  const getListBnCho = async () => {
    try {
      setisLoadingDsCho(true)
      const { data } =
        tenNoiSoi === 'noisoictc'
          ? await noiSoiService.getListBnChoCTC(idCn)
          : tenNoiSoi === 'noisoith'
            ? await noiSoiService.getListBnChoTH(idCn)
            : tenNoiSoi === 'noisoitmh'
              ? await noiSoiService.getListBnChoTMH(idCn)
              : { data: [] }
      setlistBnCho(data)
    } catch (error) {
      console.log('getListBnCho : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    } finally {
      setisLoadingDsCho(false)
    }
  }
  const getListBnDa = async () => {
    try {
      setisLoadingDsDa(true)
      const { data } =
        tenNoiSoi === 'noisoictc'
          ? await noiSoiService.getListBnDaCTC(idCn, dateFrom, dateTo)
          : tenNoiSoi === 'noisoith'
            ? await noiSoiService.getListBnDaTH(idCn, dateFrom, dateTo)
            : tenNoiSoi === 'noisoitmh'
              ? await noiSoiService.getListBnDaTMH(idCn, dateFrom, dateTo)
              : { data: [] }
      setlistBnDa(data)
    } catch (error) {
      console.log('getListBnDa : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    } finally {
      setisLoadingDsDa(false)
    }
  }
  const getListChiNhanh = async () => {
    try {
      const { data } = await branchService.getBranchNotVP(ID_CONGTY_LOGIN)
      setlistCn(data)
    } catch (error) {
      console.log('getListChiNhanh : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    }
  }
  const getListBieuMau = async () => {
    try {
      const { data } = await sieuAmService.getALLBieuMau(
        tenNoiSoi === 'noisoictc'
          ? 3
          : tenNoiSoi === 'noisoith'
            ? 5
            : tenNoiSoi === 'noisoitmh'
              ? 6
              : null,
      )
      setlistBieuMau(data)
    } catch (error) {
      console.log('getListBieuMau : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    }
  }
  const getListLichSuNoiSoi = async (idbn) => {
    try {
      setisLoadingLichSu(true)
      const { data: listLSNS } =
        tenNoiSoi === 'noisoictc'
          ? await noiSoiService.getListLichSuCTC(idbn)
          : tenNoiSoi === 'noisoith'
            ? await noiSoiService.getListLichSuTH(idbn)
            : tenNoiSoi === 'noisoitmh'
              ? await noiSoiService.getListLichSuTMH(idbn)
              : { data: [] }
      setlistLichSuNoiSoi(listLSNS)
      setisLoadingLichSu(false)
    } catch (error) {
      console.log('getListLichSuNoiSoi : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    }
  }
  const getInfoBnNoiSoi = async (idbncdha) => {
    try {
      setisLoadingInfo(true)
      // const { data: bnCDHA } = await sieuAmService.getInfoBnPending(idbnttc, idbncdha)
      const { data: bnNoiSoi } = await noiSoiService.getInfoBnNoiSoi(idbncdha)
      setinfoBnNoiSoi(bnNoiSoi)
      setisLoadingInfo(false)
      if (bnNoiSoi?.idbn) getListLichSuNoiSoi(bnNoiSoi?.idbn)
    } catch (error) {
      console.log('getInfoBnNoiSoi : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    }
  }
  useEffect(() => {
    getListChiNhanh()
    getListBieuMau()
  }, [])
  useEffect(() => {
    // load lần đầu
    if (listCn?.length && idCn) {
      getListBnCho()
      getListBnDa()
    }
  }, [listCn])
  useEffect(() => {
    setSelectedFile([])
    setketLuan(infoBnNoiSoi?.ketluan)
    setinfoBieuMau({
      idbm: infoBnNoiSoi?.idbm,
      tieude: infoBnNoiSoi?.tieude,
    })
    if (infoBnNoiSoi?.linkhinhanh) {
      setlistImage(
        infoBnNoiSoi?.linkhinhanh?.split(';').map((item) => ({ url: item, checked: false })),
      )
    } else {
      setlistImage([])
    }
    if (infoBnNoiSoi?.bieumau) {
      const convertJson = JSON.parse(infoBnNoiSoi?.bieumau)
      const contentState = convertFromRaw(convertJson)
      setEditorState(EditorState.createWithContent(contentState))
    } else {
      setEditorState(null)
    }
  }, [infoBnNoiSoi])
  const handlePrint = () => {
    const formatInfoBnNS = {
      ...infoBnNoiSoi,
      loaisieuam: infoBnNoiSoi?.loainoisoi,
      bschidinh: infoBnNoiSoi?.tenbschidinh,
      ngaysieuam: infoBnNoiSoi?.ngaynoisoi,
      bssieuam: infoBnNoiSoi?.tenbsnoisoi,
    }
    const listHinhPrint = listImage
      ?.filter((item) => item?.checked && infoBnNoiSoi?.linkhinhanh?.includes(item?.url))
      ?.map((item) => ({ base64: item?.url }))

    listHinhPrint?.length >= 3
      ? generatePhieuSieuAmMultiImage(formatInfoBnNS, listHinhPrint, PkDangNhap)
      : generatePhieuSieuAm(formatInfoBnNS, listHinhPrint, PkDangNhap)
  }
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <div
        className="h-full bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <div className="grid grid-cols-2 h-full">
          <div className="border-e h-full grid grid-rows-2">
            <div className="grid grid-cols-2 h-full">
              <div className="border-e">
                <Tabs
                  items={[
                    {
                      key: 1,
                      value: 1,
                      label: <div className="px-2">Chờ thực hiện</div>,
                      children: (
                        <>
                          <div className="px-2 flex items-center justify-between gap-2">
                            <Select
                              className="w-full"
                              showSearch
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              value={idCn}
                              // onChange={(value) => setidCn(value)}
                              options={listCn?.map((item) => ({
                                key: item?.idChiNhanh,
                                value: item?.idChiNhanh,
                                label: item?.tenChiNhanh,
                              }))}
                            />
                            <Button
                              loading={isLoadingDsCho}
                              type="primary"
                              shape="circle"
                              icon={<SyncOutlined />}
                              onClick={getListBnCho}
                            />
                          </div>
                          <ListBN
                            isLoading={isLoadingDsCho}
                            listBn={listBnCho}
                            getInfoBnNoiSoi={getInfoBnNoiSoi}
                          />
                        </>
                      ),
                    },
                    {
                      key: 2,
                      value: 2,
                      label: <div className="px-2">Đã thực hiện</div>,
                      children: (
                        <>
                          <div className="px-2 flex items-center justify-between gap-2">
                            <div className="w-full flex justify-start items-center gap-2">
                              <DatePicker
                                placeholder=" "
                                allowClear={false}
                                defaultValue={dayjs(now)}
                                maxDate={dayjs(now)}
                                onChange={handleDatePicker('form')}
                                format={dateFormat}
                                className="w-full"
                                locale={{ ...locale, week: { start: 1 } }}
                              />
                              <span className="font-semibold text-2xl">-</span>
                              <DatePicker
                                placeholder=" "
                                allowClear={false}
                                defaultValue={dayjs(now)}
                                maxDate={dayjs(now)}
                                onChange={handleDatePicker('to')}
                                format={dateFormat}
                                className="w-full"
                                locale={{ ...locale, week: { start: 1 } }}
                              />
                            </div>
                            <Button
                              isLoading={isLoadingDsDa}
                              type="primary"
                              shape="circle"
                              icon={<SyncOutlined />}
                              onClick={getListBnDa}
                            />
                          </div>
                          <ListBN
                            isLoading={isLoadingDsDa}
                            listBn={listBnDa}
                            getInfoBnNoiSoi={getInfoBnNoiSoi}
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div className="overflow-auto">
                <Spin spinning={isLoadingInfo}>
                  <InfoBN tenNoiSoi={tenNoiSoi} infoBnNoiSoi={infoBnNoiSoi} />
                </Spin>
              </div>
            </div>
            <div className="border-t">
              <Spin spinning={isLoadingInfo}>
                <div className="absolute w-full flex justify-end items-center p-2 pt-3 text-red-500">
                  Hình in:&nbsp;
                  {listImage?.concat(selectedFile)?.filter((item) => item?.checked)?.length}/
                  {listImage?.concat(selectedFile)?.length <= 8
                    ? listImage?.concat(selectedFile)?.length
                    : 8}
                </div>
                <Tabs
                  items={[
                    {
                      key: 1,
                      value: 1,
                      label: <div className="px-2">Hình ảnh</div>,
                      children: (
                        <HinhAnh
                          selectedFile={selectedFile}
                          setSelectedFile={setSelectedFile}
                          listImage={listImage}
                          setlistImage={setlistImage}
                          isKhongDuocChinhSua={isKhongDuocChinhSua}
                        />
                      ),
                    },
                    {
                      key: 2,
                      value: 2,
                      label: <div className="px-2">Lịch sử</div>,
                      children: (
                        <LichSu
                          listLichSuNoiSoi={listLichSuNoiSoi}
                          isLoadingLichSu={isLoadingLichSu}
                        />
                      ),
                    },
                  ]}
                />
              </Spin>
            </div>
          </div>
          <Spin spinning={isLoadingInfo}>
            <div className="h-full p-2">
              <div className="flex justify-end items-center gap-2">
                <div className="mr-auto flex justify-start items-center gap-1">
                  <div className="font-semibold">Biểu mẫu</div>
                  <Select
                    className="w-[17rem]"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    value={infoBieuMau?.idbm}
                    onChange={handleBieuMau}
                    options={listBieuMau?.map((item) => ({
                      key: item?.idbm,
                      value: item?.idbm,
                      label: item?.tenbieumau,
                      info: item,
                    }))}
                  />
                </div>
                {infoBnNoiSoi?.idbnns && infoBnNoiSoi?.idttns && (
                  <>
                    <Button type="primary" onClick={handlePrint}>
                      In
                    </Button>
                    <Button type="primary" onClick={() => handleSubmit(false)}>
                      Lưu
                    </Button>
                  </>
                )}
                <Button type="primary" onClick={() => handleSubmit(true)} disabled={!infoBnNoiSoi}>
                  Lưu & In
                </Button>
              </div>
              <div>
                <BieuMau
                  editorState={editorState}
                  setEditorState={setEditorState}
                  ketLuan={ketLuan}
                  setketLuan={setketLuan}
                  isKhongDuocChinhSua={isKhongDuocChinhSua}
                />
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  )
}

export default NoiSoi
