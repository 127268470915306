import React, { useEffect, useRef, useState } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import {
  ConfigProvider,
  DatePicker,
  Select,
  Table,
  Space,
  Tabs,
  Input,
  Button as AntButton,
} from 'antd'
import Button from '@mui/material/Button'
import CachedIcon from '@mui/icons-material/Cached'
import PrintIcon from '@mui/icons-material/Print'
import moment from 'moment'
import dayjs from 'dayjs'
import {
  FileTextOutlined,
  PrinterOutlined,
  SaveOutlined,
  SendOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import InfoBN from './InfoBN/InfoBN'
import TableChiDinh from './TabChiDinh/TableChiDinh'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { branchService } from '../../../services/branch/branchService'
import { xetNghiemService } from '../../../services/xetNghiem/xetNghiemService'
import { infoBNXetNghiem, updateBNXN } from '../../../store/actions/xetNghiemAction'
import { useNavigate } from 'react-router-dom'
import { generatePhieuGuiChiDinhLayMau } from '../../../utils/report/phieuGuiLayMau'
import { https } from '../../../services/apiService'
import { generatePhieuChiDinh } from '../../../utils/report/phieuChiDinh'
import { thuThuatService } from '../../../services/thuThuat/thuThuatService'
import * as typeAction from '../../../store/constants/constants'
import ToastCus from '../../../utils/Toast'
import { getListVTTHThuThuatTheoKhoAction } from '../../../store/actions/thuThuatAction'
import { cloneDeep } from 'lodash'
import 'moment/locale/vi'
moment.locale('vi')

const today = moment()
const formatDate = 'DD/MM/YYYY'
const XetNghiemClinic = () => {
  const formatDateAPI = 'YYYY-MM-DD'
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const { listVTBN } = useSelector((state) => state.thuThuatReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { detailXN } = useSelector((state) => state.xetNghiemReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [dateForm, setDateForm] = useState(today.format(formatDateAPI))
  const [dateTo, setDateTo] = useState(today.format(formatDateAPI))
  const [branchs, setBranch] = useState([])
  const [branchPedding, setBranchPendding] = useState(ID_BRANCH)
  const [branchDone, setBranchDone] = useState(ID_BRANCH)
  const [BNPending, setBNPending] = useState([])
  const [BNDaGuiMau, setBNDaGuiMau] = useState([])
  const [BNDone, setBNDone] = useState([])
  const [khoVatTu, setKhoVatTu] = useState(1) // kho vật tư mặc định là điều dưỡng
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [loading, setLoading] = useState(false)
  const isDaLuuKQVaQuaNgay =
    detailXN?.DanhSachXetNghiem[0]?.ktvxn &&
    moment().diff(moment(detailXN?.DanhSachXetNghiem[0]?.ngayketqua, formatDateAPI), 'days') > 0
      ? true
      : false
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <AntButton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </AntButton>
          <AntButton
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </AntButton>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
    // searchedColumn === dataIndex ? (
    //   <Highlighter
    //     highlightStyle={{
    //       backgroundColor: '#ffc069',
    //       padding: 0,
    //     }}
    //     searchWords={[searchText]}
    //     autoEscape
    //     textToHighlight={text ? text.toString() : ''}
    //   />
    // ) : (
    //   text
    // ),
  })
  // --------- handle submit ----------
  const handleSumit = (value) => {
    const data = detailXN?.DanhSachXetNghiem.map((item) => ({
      idbnxn: item.idchidinh,
      ktvxn: infoUser?.tenNV,
      ghichu: value.ghichu,
      ketqua: item.ketqua,
      chisobinhthuong: item.chisobinhthuong,
      donviguimau: value.donviguimau,
    }))
    dispatch(updateBNXN(data, onLoad))
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbnttc: detailXN?.IDBNTTC,
      bschidinh: detailXN?.BSCHIDINH,
      ktvxn: infoUser?.tenNV,
      ngayketqua: '',
      donviguimau: 'Diag',
      ghichu: null,
    },
    onSubmit: (value) => handleSumit(value),
  })
  const onLoad = () => {
    fetchBNPendingXN()
    fetchBNDaGuiMauXN()
    fetchBNDoneXn()
  }
  //--------- đến danh sách phiếu thu  ------------//
  const goToPhieuThu = () => {
    navigate('/canlamsang/xetnghiem/PhieuThu')
  }
  //-------- lấy danh sách công ty -------//
  const fetchBranchByCompany = async () => {
    try {
      const result = await branchService.getListBranch(ID_COMPANY)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  //------ lấy danh sách bệnh nhân chờ xét nghiệm theo chi nhánh --------//
  const fetchBNPendingXN = async () => {
    try {
      const result = await xetNghiemService.getBNPendingXN(branchPedding)
      setBNPending(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  //------ lấy danh sách bệnh nhân đã gửi mẫu theo chi nhánh --------//
  const fetchBNDaGuiMauXN = async () => {
    try {
      const result = await xetNghiemService.getBNDaGuiMauXN(branchPedding)
      setBNDaGuiMau(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  //---------------- lấy danh sách đã hoàn thành xét nghiêm --------//
  const fetchBNDoneXn = async () => {
    try {
      const result = await xetNghiemService.getBNDoneXN(branchDone, dateForm, dateTo)
      setBNDone(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  // --------- onClick bệnh nhân chờ khám --------//
  const onClickBNPending = (record) => {
    dispatch(infoBNXetNghiem(record.idbn, record.idbnttc))
  }
  // // --------- onClick bệnh nhân đã gửi mẫu --------//
  // const onClickBNDaGuiMau = (record) => {
  //   dispatch(infoBNXetNghiem(record.idbn, record.idbnttc))
  // }

  const handlePrintPhieuGui = async () => {
    try {
      const { data } = await https.get(`BenhNhanTTC/GetByIDBNTTC?id=${detailXN.IDBNTTC}`)
      const cloneDetailXN = cloneDeep(detailXN)
      cloneDetailXN.DanhSachXetNghiem = cloneDetailXN.DanhSachXetNghiem.filter((item) =>
        selectedRowKeys.includes(item.idchidinh),
      )
      if (cloneDetailXN.DanhSachXetNghiem.length === 0) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng chọn xét nghiệm muốn in phiếu gửi mẫu',
        })
        return
      }
      generatePhieuGuiChiDinhLayMau(cloneDetailXN, PkDangNhap, data)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePrintPhieuChiDinh = async () => {
    try {
      const { data } = await https.get(`BenhNhanTTC/GetByIDBNTTC?id=${detailXN.IDBNTTC}`)
      const detailBN = {
        idbnttc: detailXN.IDBNTTC,
        benhNhan: {
          tenBenhNhan: detailXN.TENBENHNHAN,
          ngaySinh: data.benhNhan.ngaySinh,
          tuoi: data.benhNhan.tuoi,
          gioiTinh: data.benhNhan.gioiTinh,
          diaChi: data.benhNhan.diaChi,
          tenPhuongXa: data.benhNhan.tenPhuongXa,
          tenQuanHuyen: data.benhNhan.tenQuanHuyen,
          tenTinhTP: data.benhNhan.tenTinhTP,
        },
        bskham: detailXN.BSCHIDINH,
      }
      const arrChiDinh = detailXN.DanhSachXetNghiem.map((item) => ({
        tendichvu: item.tenchidinh,
        dongia: item.dongia,
      }))
      generatePhieuChiDinh(infoUser, detailBN, arrChiDinh, PkDangNhap, data.chandoanbandau)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGuiMau = async () => {
    try {
      await https.put(`BenhNhanXetNghiem/UpdateTrangThaiGuiMau?idbnttc=${detailXN?.IDBNTTC}`)
      await thuThuatService.postListVTBNThuThuat(listVTBN)
      await fetchBNPendingXN()
      await fetchBNDaGuiMauXN()
      dispatch(infoBNXetNghiem(detailXN?.IDBN, detailXN?.IDBNTTC))
      ToastCus.fire({
        icon: 'success',
        title: 'Lưu thông tin thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lưu thông tin thất bại!',
      })
    }
  }
  const handleCapNhat = async () => {
    setLoading(true)
    const data = detailXN?.DanhSachXetNghiem.map((item) => ({
      idbnxn: item.idchidinh,
      ktvxn: infoUser?.tenNV,
      ketqua: item.ketqua,
      chisobinhthuong: item.chisobinhthuong,
    }))
    try {
      await xetNghiemService.updateBNXN(data)
      await xetNghiemService.putVTTHchoXN(detailXN?.IDBNTTC, listVTBN)
      dispatch({ type: typeAction.DISPATCH_HISTORY_XET_NGHIEM, payload: null })
      dispatch({
        type: typeAction.DISPATCH_DETAIL_BN_XN,
        payload: null,
      })
      //dispatch(infoBNXetNghiem(detailXN?.IDBN, detailXN?.IDBNTTC))
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      setLoading(false)
    }
  }
  const reloadListVTTieuHao = () => {
    //dispatch(getListVTTieuHaoThuThuatAction(PkDangNhap?.idChiNhanh))
    dispatch(getListVTTHThuThuatTheoKhoAction(PkDangNhap?.idChiNhanh, khoVatTu)) // khoVatTu = 1 là kho điều dưỡng, khoVatTu = 2 là kho cấp cứu
  }
  useEffect(() => {
    fetchBranchByCompany()
    fetchBNPendingXN()
    fetchBNDaGuiMauXN()
    fetchBNDoneXn()
    reloadListVTTieuHao()
  }, [])
  useEffect(() => {
    reloadListVTTieuHao()
  }, [khoVatTu, detailXN])
  const columns = [
    {
      title: 'Mã BN',
      dataIndex: 'maBenhNhan',
      key: 'maBenhNhan',
      width: '30%',
      ...getColumnSearchProps('maBenhNhan'),
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenBenhNhan',
      key: 'tenBenhNhan',
      width: '70%',
      ...getColumnSearchProps('tenBenhNhan'),
    },
  ]
  return (
    <>
      <div className="h-full p-3 bg-[#EFEFEF]">
        <div className="bg-white rounded-2xl border h-full">
          <div className="p-3 h-full flex gap-2">
            <div className="w-1/4 border h-full rounded-lg">
              <div className="border-b p-2 h-1/2">
                {/* <h2 className="font-semibold  text-lg text-gray-500">Chờ thực hiện</h2> */}
                <Tabs
                  items={[
                    {
                      key: '1',
                      label: 'Chờ lấy mẫu',
                      children: (
                        <>
                          <div className="">
                            <div className=" flex gap-2">
                              <Select
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '')
                                    .toLowerCase()
                                    .localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                value={branchPedding}
                                onChange={(value) => {
                                  setBranchPendding(value)
                                }}
                                showSearch
                                options={branchs.map((items) => ({
                                  label: items.tenChiNhanh,
                                  value: items.idChiNhanh,
                                }))}
                                className="w-full"
                              />
                              <Button
                                onClick={fetchBNPendingXN}
                                // className="w-1/4"
                                variant="text"
                                size="small"
                                // endIcon={<CachedIcon />}
                              >
                                <CachedIcon />
                              </Button>
                            </div>
                          </div>
                          <div className="mt-2">
                            <ConfigProvider
                              theme={{
                                token: { padding: 5 },
                                // components: {
                                //   Table: {
                                //     rowHoverBg: '#ecf0f1',
                                //     headerBg: '#e6e6e6',
                                //     footerBg: '#e6e6e6',
                                //     borderColor: '#BABABA',
                                //   },
                                // },
                              }}
                            >
                              <Table
                                scroll={{
                                  y: 255, //290 - 230
                                }}
                                onRow={(record) => ({
                                  onClick: () => onClickBNPending(record),
                                })}
                                pagination={false}
                                dataSource={BNPending}
                                columns={columns}
                              />
                            </ConfigProvider>
                          </div>
                        </>
                      ),
                    },
                    {
                      key: '2',
                      label: 'Đã gửi mẫu',
                      children: (
                        <>
                          <div className="">
                            <div className=" flex gap-2">
                              <Select
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '')
                                    .toLowerCase()
                                    .localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                value={branchPedding}
                                onChange={(value) => {
                                  setBranchPendding(value)
                                }}
                                showSearch
                                options={branchs.map((items) => ({
                                  label: items.tenChiNhanh,
                                  value: items.idChiNhanh,
                                }))}
                                className="w-full"
                              />
                              <Button
                                onClick={fetchBNDaGuiMauXN}
                                // className="w-1/4"
                                variant="text"
                                size="small"
                                // endIcon={<CachedIcon />}
                              >
                                <CachedIcon />
                              </Button>
                            </div>
                          </div>
                          <div className="mt-2">
                            <ConfigProvider
                              theme={{
                                token: { padding: 5 },
                                // components: {
                                //   Table: {
                                //     rowHoverBg: '#ecf0f1',
                                //     headerBg: '#e6e6e6',
                                //     footerBg: '#e6e6e6',
                                //     borderColor: '#BABABA',
                                //   },
                                // },
                              }}
                            >
                              <Table
                                scroll={{
                                  y: 255, //290 - 230
                                }}
                                onRow={(record) => ({
                                  onClick: () => onClickBNPending(record),
                                })}
                                pagination={false}
                                dataSource={BNDaGuiMau}
                                columns={columns}
                              />
                            </ConfigProvider>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div className=" p-2 h-1/2">
                <div className="flex justify-between">
                  <h2 className="font-semibold text-lg text-gray-500">Đã thực hiện</h2>
                </div>

                <div className="flex items-center justify-center">
                  <DatePicker
                    allowClear={false}
                    value={dayjs(dateForm)}
                    format={formatDate}
                    onChange={(date, dateString) => {
                      setDateForm(moment(dateString, formatDate).format(formatDateAPI))
                    }}
                    className="w-2/5"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 w-1/5"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                  </svg>

                  <DatePicker
                    onChange={(date, dateString) => {
                      setDateTo(moment(dateString, formatDate).format(formatDateAPI))
                    }}
                    value={dayjs(dateTo)}
                    format={formatDate}
                    allowClear={false}
                    className="w-2/5"
                  />
                </div>
                <div className="mt-2">
                  <div className=" flex gap-2">
                    <Select
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      onChange={(value) => {
                        setBranchDone(value)
                      }}
                      value={branchDone}
                      showSearch
                      options={branchs.map((items) => ({
                        label: items.tenChiNhanh,
                        value: items.idChiNhanh,
                      }))}
                      className="w-full"
                    />
                    <Button
                      onClick={fetchBNDoneXn}
                      // className="w-1/4"
                      variant="text"
                      size="small"
                      // endIcon={<CachedIcon />}
                    >
                      <CachedIcon />
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <ConfigProvider
                    theme={{
                      token: { padding: 5 },
                      // components: {
                      //   Table: {
                      //     rowHoverBg: '#ecf0f1',
                      //     headerBg: '#e6e6e6',
                      //     footerBg: '#e6e6e6',
                      //     borderColor: '#BABABA',
                      //   },
                      // },
                    }}
                  >
                    <Table
                      pagination={false}
                      scroll={{
                        y: 250,
                      }}
                      onRow={(record) => ({
                        onClick: () => onClickBNPending(record),
                      })}
                      dataSource={BNDone}
                      columns={columns}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
            <div className="w-3/4 border h-full rounded-lg">
              <div className="p-2 pb-0">
                <div className="flex justify-between gap-5">
                  <AntButton
                    icon={<SendOutlined style={{ fontSize: 16 }} />}
                    onClick={handleGuiMau}
                    disabled={
                      !detailXN ||
                      detailXN?.DanhSachXetNghiem[0]?.ktvxn ||
                      detailXN?.DanhSachXetNghiem[0]?.trangthaikb
                    }
                    type="primary"
                  >
                    Gửi mẫu & Lưu VTTH
                  </AntButton>
                  <AntButton
                    type="default"
                    onClick={goToPhieuThu}
                    icon={<FileTextOutlined style={{ fontSize: 16 }} />}
                  >
                    Phiếu
                  </AntButton>
                  <AntButton
                    icon={<PrinterOutlined style={{ fontSize: 20 }} />}
                    disabled={!detailXN}
                    onClick={handlePrintPhieuGui}
                    type="link"
                    className="ml-auto"
                  >
                    Phiếu gửi
                  </AntButton>
                  <AntButton
                    icon={<PrinterOutlined style={{ fontSize: 20 }} />}
                    disabled={!detailXN}
                    type="link"
                    onClick={handlePrintPhieuChiDinh}
                  >
                    Chỉ định
                  </AntButton>
                  {detailXN?.DanhSachXetNghiem[0]?.ktvxn && (
                    <AntButton
                      loading={loading}
                      onClick={handleCapNhat}
                      disabled={isDaLuuKQVaQuaNgay}
                      type="primary"
                      icon={<SaveOutlined style={{ fontSize: 16 }} />}
                    >
                      Cập nhật
                    </AntButton>
                  )}
                  <AntButton
                    icon={<SaveOutlined style={{ fontSize: 16 }} />}
                    onClick={formik.handleSubmit}
                    disabled={
                      !detailXN ||
                      detailXN?.DanhSachXetNghiem[0]?.ktvxn ||
                      !detailXN?.DanhSachXetNghiem[0]?.trangthaikb
                    }
                    type="primary"
                  >
                    Lưu KQXN
                  </AntButton>
                </div>
                <div>
                  <InfoBN formik={formik} />
                </div>
                <div>
                  <TableChiDinh
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    khoVatTu={khoVatTu}
                    setKhoVatTu={setKhoVatTu}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default XetNghiemClinic
