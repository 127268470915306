import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Input,
  Modal,
  Select,
  Space,
  Table,
} from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { cloneDeep } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import {
  addDonDatHang,
  getAllHangHoa,
  getAllNguoiDuyet,
} from '../../../../store/actions/vanPhongPhamAction'
import ToastCus from '../../../../utils/Toast'
import { branchService } from '../../../../services/branch/branchService'
import { vanPhongPhamSchema } from '../../../../schemas/vanphongphamSchema'
import { https } from '../../../../services/apiService'

const dateFormat = 'DD/MM/YYYY'
const dateFormatAPI = 'YYYY-MM-DD'

function ModalAdd({ open, setOpen, onLoad }) {
  const { listHangHoa, listNguoiDuyet } = useSelector((state) => state.vanPhongPhamReducer)
  const [dataTable, setDataTable] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const ID_COMPANY = localStorage.getItem('id_company')
  const BranchLogin = localStorage.getItem('BRANH_LOGIN')
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [listBranch, setListBranch] = useState([])
  const dispatch = useDispatch()
  const selectRef = useRef(null)
  const { infoUser } = useSelector((state) => state.userReducer)
  const formik = useFormik({
    initialValues: {
      nguoidat: infoUser.idnv,
      idchinhanhnhan: '',
      idchinhanhdat: BranchLogin,
      tendonhang: '',
      ghichudathang: '',
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: vanPhongPhamSchema,
  })

  const handleCloseModal = () => {
    formik.resetForm()
    setOpen(false)
  }

  const handleSubmit = (value) => {
    if (dataTable.length === 0) {
      ToastCus.fire({
        icon: 'warning',
        title: 'Vui lòng chọn hàng hóa cần đặt',
      })
      return
    }
    const data = {
      ...value,
      chitietdathangs: [
        ...dataTable
          .filter((item) => item.soluongdat > 0)
          .map((item) => ({
            idhang: item.idthuoc,
            soluongdat: item.soluongdat,
            ghichu: item.ghichu,
          })),
      ],
    }
    dispatch(addDonDatHang(data, onLoad, setLoadingBtn, handleCloseModal))
  }

  const fetchBranch = async () => {
    try {
      const { data } = await https.get('VPPDatHang/GetChiNhanhVPP')
      setListBranch(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeSelect = async (value) => {
    const idx = dataTable.findIndex((item) => item.idthuoc === value)
    if (idx !== -1) {
      ToastCus.fire({
        icon: 'warning',
        title: 'Hàng hóa đã được chọn',
      })
      return
    }
    const formatData = {
      ...listHangHoa.find((item) => item.idthuoc === value),
      ghichu: '',
      soluongdat: 1,
    }
    setDataTable((prev) => [...prev, formatData])
  }

  const handleDeleteInTable = (record) => {
    setDataTable((prev) => prev.filter((item) => item.idthuoc !== record.idthuoc))
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size="small"
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchTable(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchTable(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearchTable = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const handleChangeInput = (e, record, key) => {
    const findItemIdx = dataTable.findIndex((item) => item.idthuoc === record.idthuoc)
    if (findItemIdx !== -1) {
      const cloneListHang = cloneDeep(dataTable)

      if (e.target.value < 0 && key === 'soluongdat') {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng nhập số hợp lệ',
        })
        cloneListHang[findItemIdx][key] = 1
        setDataTable(cloneListHang)
        return
      }

      cloneListHang[findItemIdx][key] = e.target.value
      setDataTable(cloneListHang)
    }
  }

  const columnsHangHoa = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 60,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã hàng hóa',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 120,
      ...getColumnSearchProps('mathuoc'),
    },
    {
      title: 'Tên hàng hóa',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
      ...getColumnSearchProps('tenbietduoc'),
    },
    {
      title: 'SL đặt',
      dataIndex: 'soluongdat',
      key: 'soluongdat',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Input
          size="small"
          min={0}
          step={1}
          max={999999}
          type="number"
          key={record.idthuoc}
          value={record.soluongdat}
          className="w-full text-center"
          onChange={(e) => handleChangeInput(e, record, 'soluongdat')}
        />
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 250,
      render: (text, record) => (
        <Input
          size="small"
          key={record.idthuoc}
          value={record.ghichu}
          className="w-full"
          onChange={(e) => handleChangeInput(e, record, 'ghichu')}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 40,
      align: 'center',
    },
  ]

  useEffect(() => {
    dispatch(getAllHangHoa(ID_COMPANY))
    dispatch(getAllNguoiDuyet())
    fetchBranch()
  }, [])

  return (
    <Modal
      okText="Xác nhận"
      cancelText="Huỷ"
      className="text-center"
      title={'Đặt hàng'}
      onOk={formik.handleSubmit}
      open={open}
      onCancel={handleCloseModal}
      width={1200}
      confirmLoading={loadingBtn}
    >
      <form className="text-start flex flex-col gap-2">
        <div className="flex gap-4">
          <div className="w-1/2 flex gap-2">
            <div className="w-1/2">
              <label className="font-semibold  text-base">
                <span className="text-sx text-red-500">(*)</span>Bộ phận duyệt
              </label>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={(value) => formik.setFieldValue('idchinhanhnhan', value)}
                options={listBranch?.map((item) => ({
                  label: item.tenChiNhanh,
                  value: item.idChiNhanh,
                }))}
                className="w-full"
                size="small"
                placeholder="Chọn chi nhánh"
                status={formik.errors.idchinhanhnhan ? 'error' : ''}
              />
            </div>
            <div className="w-1/2">
              <label className="font-semibold  text-base">
                {' '}
                <span className="text-sx text-red-500">(*)</span>Tên đơn hàng
              </label>
              <Input
                size="small"
                value={formik.values.tendonhang}
                onChangeCapture={formik.handleChange}
                className="w-full"
                name="tendonhang"
                status={formik.errors.tendonhang ? 'error' : ''}
              />
            </div>
          </div>
          <div className="w-1/2 flex flex-col">
            <label className="font-semibold text-base">Ghi chú</label>
            <Input
              size="small"
              value={formik.values.ghichudathang}
              onChangeCapture={formik.handleChange}
              className="w-full"
              name="ghichudathang"
              status={formik.errors.ghichudathang ? 'error' : ''}
            />
          </div>
        </div>
      </form>
      <Divider />
      <div className="flex items-center mb-2">
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          onChange={handleChangeSelect}
          value={null}
          options={listHangHoa?.map((item) => ({
            label: item.tenbietduoc,
            value: item.idthuoc,
          }))}
          className="w-full"
          size="small"
          placeholder="Chọn hàng hóa"
        />
      </div>
      <div className="overflow-auto h-[500px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            scroll={{ y: 450 }}
            bordered
            pagination={false}
            columns={columnsHangHoa}
            dataSource={
              dataTable.map((item) => ({
                ...item,
                action: (
                  <ul className="flex gap-2 justify-around">
                    <li className="text-lg text-red-500">
                      <DeleteOutlined onClick={() => handleDeleteInTable(item)} />
                    </li>
                  </ul>
                ),
              })) || []
            }
          />
        </ConfigProvider>
      </div>
    </Modal>
  )
}

ModalAdd.propTypes = {}

export default ModalAdd
