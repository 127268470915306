import {
  Button,
  ConfigProvider,
  DatePicker,
  Checkbox,
  Input,
  Select,
  Table,
  Divider,
  Collapse,
  notification,
} from 'antd'
import dayjs from 'dayjs'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'
import _, { set } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import {
  getListTrongVaGongKinhAction,
  postInfoBNTrongVaGongKinhAction,
} from '../../../../../../store/actions/doKhucXaAction'
import { formatNumberVND } from '../../../../../../utils/formatNumberVND'
import * as typeAction from '../../../../../../store/constants/constants'
import { doKhucXaService } from '../../../../../../services/doKhucXa/doKhucXaService'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
const { TextArea } = Input
const FormTK = ({ formik, checkBox, setCheckBox }) => {
  const dispatch = useDispatch()
  const { infoBN, DetailBN } = useSelector((state) => state.childrenClinicReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const {
    listTrongKinh,
    listGongKinh,
    listTrongKinhBN,
    listGongKinhBN,
    InfoBNTrongVaGongKinh,
    listBNDV,
  } = useSelector((state) => state.doKhucXaReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { InfoBNDoKhucXa } = useSelector((state) => state.doKhucXaReducer)
  const [listDoiTacTK, setListDoiTacTK] = useState([])
  const [listDoiTacTKChiPhi, setListDoiTacTKChiPhi] = useState([])
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (message, description) => {
    api.warning({
      message,
      description,
    })
  }

  //-------- lấy danh sách đối tác tròng kính -------//
  const fetchListDoiTacTrongKinh = async () => {
    try {
      const result = await doKhucXaService.getListDoiTacTK(PkDangNhap?.idCongTy)
      setListDoiTacTK(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  //-------- lấy danh sách đối tác tròng kính chi phí -------//
  const fetchListDoiTacTrongKinhChiPhi = async (iddttk) => {
    try {
      const result = await doKhucXaService.getListDoiTacTKChiPhi(iddttk)
      setListDoiTacTKChiPhi(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const onLoad = () => {
    dispatch(getListTrongVaGongKinhAction(41, PkDangNhap?.idChiNhanh)) // 41 là id nhóm dịch vụ tròng kính
  }
  const handleLuuVaIn = async () => {
    // let flagDoiTac = false,
    //   flagLoaiGong = false
    // for (const item of listTrongKinhBN) {
    //   if (!item?.doitacthuchien) {
    //     flagDoiTac = true
    //     return
    //   }
    //   if (!item?.iddttkcp) {
    //     flagLoaiGong = true
    //     return
    //   }
    // }
    // await listTrongKinhBN?.map((item) => {
    // })
    if (listTrongKinhBN?.find((item) => !item?.doitacthuchien)) {
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại đối tác!',
      })
      return
    }
    if (listTrongKinhBN?.find((item) => !item?.iddttkcp)) {
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại loại gọng!',
      })
      return
    }
    let isKhamMat = false
    listBNDV?.map((item) => {
      // check xem bệnh nhân có đi khám mắt không
      if (item.idnhomdv === 22) isKhamMat = true
    })
    // if (!isKhamMat) {
    //   console.log('không khám mắt')
    //   return
    // }
    dispatch(
      postInfoBNTrongVaGongKinhAction(
        DetailBN?.idbnttc,
        listTrongKinhBN?.map((item) => ({
          ...item,
          idnguoichidinh: infoUser?.idnv,
        })),
        listGongKinhBN,
        isKhamMat,
      ),
    )
  }
  const handleDeleteDichVuTK = (iddv) => {
    const index = listTrongKinhBN?.findIndex((item) => item.iddichvu === iddv)
    if (index === -1) {
      openNotificationWithIcon('Chọn tròng kính', 'Vui lòng kiểm tra lại!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_DEL_LIST_TRONG_KINH_BN_DKX,
      payload: index,
    })
  }
  const handleDeleteTonGK = (ID) => {
    const index = listGongKinhBN?.findIndex((item) => item.ID === ID)
    if (index === -1) {
      openNotificationWithIcon('Chọn gọng kính', 'Vui lòng kiểm tra lại!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_DEL_LIST_GONG_KINH_BN_DKX,
      payload: index,
    })
  }
  const handleDoiTacTK = (index) => (value, opt) => {
    // const index = listTrongKinhBN.findIndex((item) => item.iddichvu === iddv)
    // if (index === -1) {
    //   openNotificationWithIcon('Chọn tròng kính', 'Vui lòng kiểm tra lại!')
    //   return
    // }
    dispatch({
      type: typeAction.DISPATCH_EDIT_DOITAC_TK_BN_DKX,
      payload: {
        index,
        value: value,
      },
    })
  }
  const handleDoiTacTKChiPhi = (index) => (value, opt) => {
    // const index = listTrongKinhBN.findIndex((item) => item.iddichvu === iddv)
    // if (index === -1) {
    //   openNotificationWithIcon('Chọn tròng kính', 'Vui lòng kiểm tra lại!')
    //   return
    // }
    dispatch({
      type: typeAction.DISPATCH_EDIT_LOAIGONG_TK_BN_DKX,
      payload: {
        index,
        value: value,
        cong: opt?.chiphi?.cong,
        vanchuyen: opt?.chiphi?.vanchuyen,
        chiphi: opt?.chiphi,
      },
    })
  }
  const handleGhiChuTK = (index) => (e) => {
    // const index = listTrongKinhBN.findIndex((item) => item.iddichvu === iddv)
    // if (index === -1) {
    //   openNotificationWithIcon('Chọn tròng kính', 'Vui lòng kiểm tra lại!')
    //   return
    // }
    dispatch({
      type: typeAction.DISPATCH_EDIT_GHICHU_TK_BN_DKX,
      payload: {
        index,
        value: e.target.value,
      },
    })
  }
  const handleTrongKinh = (iddv) => {
    // if (!InfoBNDoKhucXa?.NGUOIKHAM) {
    //   openNotificationWithIcon('Chọn tròng kính', 'Bệnh nhân cần được đo khúc xạ!')
    //   return
    // }
    // for (const item of listTrongKinhBN) {
    //   if (item?.iddichvu === iddv) {
    //     openNotificationWithIcon('Chọn tròng kính', 'Tròng kính đã được chọn!')
    //     return
    //   }
    // }
    const index = listTrongKinh?.findIndex((item) => item?.dichVu?.iddv === iddv)
    if (index === -1) {
      openNotificationWithIcon('Chọn tròng kính', 'Vui lòng kiểm tra lại!')
      return
    }
    const data = {
      idbnttc: DetailBN?.idbnttc,
      iddichvu: listTrongKinh[index]?.dichVu?.iddv,
      madichvu: listTrongKinh[index]?.dichVu?.maDichVu,
      tendichvu: listTrongKinh[index]?.dichVu?.tenDichVu,
      donvitinh: listTrongKinh[index]?.dichVu?.donVi,
      dongia: listTrongKinh[index]?.dichVu?.donGia,
      ghichu: null,
      // trangthaikb: 0,
      trangthaitt: 3,
      tiencong: 0,
      chiphivanchuyen: 0,
      doitacthuchien: null,
      iddttkcp: null,
      // tenct: '',
      // tengong: '',
      // nguoithuchien: infoUser?.tenNV,
      ptgiamgia: 0,
      tiengiamgia: 0,
      tienthanhtoan: listTrongKinh[index]?.dichVu?.donGia,
    }
    dispatch({
      type: typeAction.DISPATCH_ADD_LIST_TRONG_KINH_BN_DKX,
      payload: data,
    })
  }

  const handleChangeSoLuong = (vt, soLuongNew) => {
    const newValue = Number(soLuongNew)
    const index = listGongKinhBN?.findIndex((item) => item.ID === vt.ID)
    if (index === -1) {
      openNotificationWithIcon('Chọn tròng kính', 'Vui lòng kiểm tra lại!')
      return
    }
    if (newValue > listGongKinh.find((item) => item.ID === vt.ID).tonKho) {
      openNotificationWithIcon('Chọn tròng kính', 'Số lượng tồn kho không đủ!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_EDIT_SOLUONG_GK_BN_DKX,
      payload: {
        index: index,
        value: newValue,
      },
    })
  }
  const handleGongKinh = (idvt) => {
    // if (!InfoBNDoKhucXa?.NGUOIKHAM) {
    //   openNotificationWithIcon('Chọn gọng kính', 'Bệnh nhân cần được đo khúc xạ!')
    //   return
    // }
    for (const item of listGongKinhBN) {
      if (item?.ID === idvt) {
        openNotificationWithIcon('Chọn gọng kính', 'Gọng kính đã được chọn!')
        return
      }
    }
    const index = listGongKinh?.findIndex((item) => item?.ID === idvt)
    if (index === -1) {
      openNotificationWithIcon('Chọn gọng kính', 'Vui lòng kiểm tra lại!')
      return
    }
    const data = {
      ID: listGongKinh[index].ID,
      ton: listGongKinh[index].tonKho,
      idthuoc: listGongKinh[index].idThuoc,
      mathuoc: listGongKinh[index].thuocVatTu.maThuoc,
      tenBietDuoc: listGongKinh[index].thuocVatTu.tenBietDuoc,
      tenhoatchat: listGongKinh[index].thuocVatTu.tenHoatChat,
      duongDung: listGongKinh[index].thuocVatTu.duongDung,
      dvt: listGongKinh[index].thuocVatTu.dvt,
      hamLuong: listGongKinh[index].thuocVatTu.hamLuong,
      soLuong: 1,
      bsKeDon: infoUser?.tenNV,
      //   ngayKeDon: "2024-05-23T16:39:02.286Z",
      ghiChu: '',
      lieuDung: null,
      soLanNgay: null,
      soLo: listGongKinh[index].soLo,
      idkhocn: listGongKinh[index].idKhoCN,
      idbnttc: DetailBN?.idbnttc,
      hanDung: listGongKinh[index].hanDung,
      soHoaDon: listGongKinh[index].soHoaDon,
      maSoDangKy: listGongKinh[index].maSoDangKy,
      giaBan: listGongKinh[index].thuocVatTu.giaBan,
      thanhToan: listGongKinh[index].thuocVatTu.giaBan,
    }
    dispatch({
      type: typeAction.DISPATCH_ADD_LIST_GONG_KINH_BN_DKX,
      payload: data,
    })
  }
  // const handlePrint = () => {
  //   const infoBn = {
  //     diaChi: DetailBN.benhNhan.diaChi,
  //     tenPhuongXa: DetailBN.benhNhan.tenPhuongXa,
  //     tenQuanHuyen: DetailBN.benhNhan.tenQuanHuyen,
  //     tenTinhTP: DetailBN.benhNhan.tenTinhTP,
  //   }
  //   const formatToaThuoc = DetailToaThuocBN.DanhSachThuoc.map((item) => ({
  //     ...item,
  //     soLuong: item.soluong,
  //     tenBietDuoc: item.tenbietduoc,
  //     duongDung: item.duongdung,
  //     soLanNgay: item.solanngay,
  //     lieuDung: item.lieudung,
  //     ghiChu: item.ghichu,
  //   }))
  //   generatePhieuToaThuoc(infoBn, DetailBN, formatToaThuoc, PkDangNhap)
  // }
  useEffect(() => {
    onLoad()
    fetchListDoiTacTrongKinh()
  }, [])
  const columnsTK = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã DV',
      dataIndex: 'madichvu',
      key: 'madichvu',
      width: 120,
      // render: (text, record, index) => record?.tendichvu + record?.tenct ?? "" + record?.tengong ?? "",
    },
    {
      title: 'Tên tròng kính',
      dataIndex: 'tendichvu',
      key: 'tendichvu',
      // render: (text, record, index) => record?.tendichvu + record?.tenct ?? "" + record?.tengong ?? "",
    },
    {
      title: 'Đối tác',
      dataIndex: 'doitacthuchien',
      key: 'doitacthuchien',
      align: 'left',
      width: 250,
      render: (text, record, index) => (
        <Select
          placeholder="Nhập tên đối tác..."
          showSearch
          allowClear
          onChange={handleDoiTacTK(index)}
          value={record?.doitacthuchien}
          filterOption={(input, option) => {
            const label = option?.label ?? ''
            const searchText = input.trim().toLowerCase()
            return label.toLowerCase().includes(searchText)
          }}
          options={listDoiTacTK?.map((item) => ({
            key: item?.id,
            value: item?.id,
            label: item?.tenct,
            doitac: item,
          }))}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          className="w-full text-start"
          size="small"
        />
      ),
    },
    {
      title: 'Loại gọng',
      dataIndex: 'iddttkcp',
      key: 'iddttkcp',
      align: 'center',
      width: 105,
      render: (text, record, index) => (
        <Select
          placeholder="Gọng..."
          // showSearch
          // allowClear
          // onChange={handleTrongKinh}
          // filterOption={(input, option) => {
          //   const label = option?.label ?? ''
          //   const searchText = input.trim().toLowerCase()
          //   return (label.toLowerCase().includes(searchText))
          // }}
          value={record?.iddttkcp}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          onClick={() => {
            fetchListDoiTacTrongKinhChiPhi(record?.doitacthuchien)
          }}
          onChange={handleDoiTacTKChiPhi(index)}
          options={
            record?.doitacthuchien &&
            listDoiTacTKChiPhi?.map((item) => ({
              key: item?.id,
              value: item?.id,
              label: item?.tengong,
              chiphi: { ...item, tendv: record?.tendichvu },
            }))
          }
          className="w-full text-start"
          size="small"
        />
      ),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'donvitinh',
      key: 'donvitinh',
      align: 'center',
      width: 60,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      align: 'center',
      width: 150,
      render: (text, record, index) => (
        <Input
          placeholder="Ngày hẹn lấy..."
          //handleDoiTacTKChiPhi(record?.iddichvu)
          onChange={handleGhiChuTK(index)}
          value={text}
          size="small"
        />
      ),
    },
    {
      title: <p className="text-center">Thành tiền</p>,
      dataIndex: 'dongia',
      key: 'dongia',
      align: 'right',
      width: 90,
      render: (text, record, index) => formatNumberVND(text),
    },
    // {
    //   title: <p className="text-center">Công</p>,
    //   dataIndex: 'tiencong',
    //   key: 'tiencong',
    //   align: 'right',
    //   width: 90,
    //   render: (text, record, index) => formatNumberVND(text),
    // },
    // {
    //   title: <p className="text-center">Vận chuyển</p>,
    //   dataIndex: 'chiphivanchuyen',
    //   key: 'chiphivanchuyen',
    //   align: 'right',
    //   width: 90,
    //   render: (text, record, index) => formatNumberVND(text),
    // },
    {
      title: <p className="text-center">Thanh toán</p>,
      dataIndex: 'tienthanhtoan',
      key: 'tienthanhtoan',
      align: 'right',
      width: 90,
      render: (text, record, index) => formatNumberVND(text),
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
      render: (text, record, index) =>
        DetailBN &&
        (DetailBN?.trangthaikhambenh !== 1 ||
          InfoBNTrongVaGongKinh?.DanhSachGongKinhBenhNhan?.length) ? (
          <></>
        ) : (
          <>
            <CloseOutlined
              onClick={() => {
                handleDeleteDichVuTK(record?.iddichvu)
              }}
              className="text-red-500"
            />
          </>
        ),
    },
  ]

  const columnsGK = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 120,
    },
    {
      title: 'Tên gọng kính',
      dataIndex: 'tenBietDuoc',
      key: 'tenBietDuoc',
    },
    {
      title: 'Số lô',
      dataIndex: 'soLo',
      key: 'soLo',
      align: 'center',
      width: 120,
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'hanDung',
      key: 'hanDung',
      align: 'center',
      width: 90,
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 70,
    },
    {
      title: 'SL',
      dataIndex: 'soLuong',
      key: 'soLuong',
      align: 'center',
      width: 65,
      render: (text, record, index) => (
        <Input
          onChange={(e) => {
            handleChangeSoLuong(record, e.target.value)
          }}
          value={text}
          min={0}
          type="number"
          size="small"
        />
      ),
    },
    {
      title: 'Tồn',
      dataIndex: 'ton',
      key: 'ton',
      align: 'center',
      width: 65,
    },
    {
      title: <p className="text-center">Thành tiền</p>,
      dataIndex: 'giaBan',
      key: 'giaBan',
      align: 'right',
      width: 90,
      render: (text, record, index) => formatNumberVND(text),
    },
    {
      title: <p className="text-center">Thanh toán</p>,
      dataIndex: 'thanhToan',
      key: 'thanhToan',
      align: 'right',
      width: 90,
      render: (text, record, index) => formatNumberVND(text),
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
      render: (text, record, index) =>
        DetailBN &&
        (DetailBN?.trangthaikhambenh !== 1 ||
          InfoBNTrongVaGongKinh?.DanhSachGongKinhBenhNhan?.length) ? (
          <></>
        ) : (
          <>
            <CloseOutlined
              onClick={() => {
                handleDeleteTonGK(record?.ID)
              }}
              className="text-red-500"
            />
          </>
        ),
    },
  ]
  return (
    <div>
      {contextHolder}
      <form>
        <div className="flex gap-2 justify-end">
          <Button
            disabled={
              !infoBN ||
              // || DetailBN?.trangthaikhambenh === 2
              DetailBN?.trangthaikhambenh !== 1 ||
              InfoBNTrongVaGongKinh?.DanhSachGongKinhBenhNhan?.length ||
              !InfoBNDoKhucXa?.NGUOIKHAM
            }
            onClick={handleLuuVaIn}
            type="primary"
          >
            Lưu & in
          </Button>
          {/* {checkBox && ()} */}
          {/* {DetailBN?.trangthaikhambenh !== 1 && !checkBox && (
            <Button onClick={handlePrint} type="primary">
              In
            </Button>
          )} */}
        </div>
        <div className="overflow-x-hidden pb-10">
          <div className="mb-2">
            <Divider orientation={'left'} plain style={{ margin: 5 }}>
              <p className="font-semibold text-lg text-gray-600 text-start">Tròng kính</p>
            </Divider>
            <Select
              placeholder="Nhập tên tròng kính..."
              showSearch
              allowClear
              onChange={handleTrongKinh}
              filterOption={(input, option) => {
                const label = option?.label ?? ''
                const madv = option?.madv ?? ''
                const searchText = input.trim().toLowerCase()
                return (
                  label.toLowerCase().includes(searchText) ||
                  madv.toLowerCase().includes(searchText)
                )
              }}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              value={null}
              className="w-full"
              options={listTrongKinh?.map((item) => ({
                key: item?.dichVu?.iddv,
                value: item?.dichVu?.iddv,
                label: item?.dichVu?.tenDichVu,
                madv: item?.dichVu?.maDichVu,
                giadv: item?.dichVu?.donGia,
                infoDv: item,
              }))}
              optionRender={(options) => (
                <ul className="flex">
                  <li className="w-5/6 border-r-[1px]">
                    <p className="font-semibold w-full text-pretty">{options.data.label}</p>
                    <span className="text-xs w-full text-gray-500"> ({options.data.madv})</span>
                  </li>
                  {/* <li className="w-1/6 border-x flex items-center justify-center text-center">
                    {moment(options.data.hanDung).format('DD/MM/YYYY')}
                  </li> */}
                  <li className="w-1/6 pr-16 flex items-center justify-end">
                    {formatNumberVND(options.data.giadv)}
                  </li>
                  {/* <li className="w-1/6 text-center flex items-center justify-center">
                    {options.data.dvt}
                  </li> */}
                </ul>
              )}
            />
            <div className="mt-2 h-64">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                  },
                }}
              >
                <Table
                  bordered
                  columns={columnsTK}
                  dataSource={listTrongKinhBN}
                  pagination={false}
                  scroll={{ y: 190 }}
                  summary={() => (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4}></Table.Summary.Cell>
                        <Table.Summary.Cell index={5}></Table.Summary.Cell>
                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                        <Table.Summary.Cell index={7} className="text-end font-semibold">
                          <span>
                            {formatNumberVND(
                              listTrongKinhBN?.reduce((tong, item) => tong + item.dongia, 0) ?? 0,
                            )}
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8} className="text-end font-semibold">
                          <span>
                            {formatNumberVND(
                              listTrongKinhBN?.reduce(
                                (tong, item) => tong + item.tienthanhtoan,
                                0,
                              ) ?? 0,
                            )}
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={9}></Table.Summary.Cell>
                        {/* <Table.Summary.Cell index={7} className="text-end font-semibold">
                          <span>
                            {formatNumberVND(
                              listTrongKinhBN?.reduce((tong, item) => tong + item.tiencong, 0) ?? 0,
                            )}
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8} className="text-end font-semibold">
                          <span>
                            {formatNumberVND(
                              listTrongKinhBN?.reduce(
                                (tong, item) => tong + item.chiphivanchuyen,
                                0,
                              ) ?? 0,
                            )}
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={9} className="text-end font-semibold">
                          <span>
                            {formatNumberVND(
                              listTrongKinhBN?.reduce(
                                (tong, item) => tong + item.tienthanhtoan,
                                0,
                              ) ?? 0,
                            )}
                          </span>
                        </Table.Summary.Cell> */}
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                />
              </ConfigProvider>
            </div>
          </div>
          <div className="mb-2 mt-10">
            <Divider orientation={'left'} plain style={{ margin: 5 }}>
              <p className="font-semibold text-lg text-gray-600 text-start">Gọng kính</p>
            </Divider>
            <Select
              placeholder="Nhập tên gọng kính..."
              showSearch
              allowClear
              onChange={handleGongKinh}
              filterOption={(input, option) => {
                const label = option?.label ?? ''
                const tenBietDuoc = option?.tenBietDuoc ?? ''
                const searchText = input.toLowerCase()
                return (
                  label.toLowerCase().includes(searchText) ||
                  tenBietDuoc.toLowerCase().includes(searchText)
                )
              }}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              value={null}
              className="w-full"
              options={listGongKinh?.map((item) => ({
                key: item?.ID,
                value: item?.ID,
                label: item?.thuocVatTu?.tenBietDuoc,
                magongkinh: item?.thuocVatTu?.maThuoc,
                ton: item?.tonKho,
                dvt: item?.thuocVatTu?.dvt,
                giaBan: item?.thuocVatTu?.giaBan,
                hanDung: item?.hanDung,
                soLo: item?.soLo,
                soHoaDon: item?.soHoaDon,
                maSoDangKy: item?.maSoDangKy,
              }))}
              optionRender={(options) => (
                <ul className="flex">
                  <li className="w-[50%] ">
                    <p className="font-semibold w-full text-pretty">{options.data.label}</p>
                    <span className="text-xs w-full text-gray-500">
                      {' '}
                      ({options.data.magongkinh})
                    </span>
                  </li>
                  <li className="w-[10%] border-x flex items-center justify-center">
                    {options.data.soLo}
                  </li>
                  <li className="w-[10%] text-center flex items-center justify-center">
                    {options.data.hanDung && moment(options.data.hanDung).format('DD/MM/YYYY')}
                  </li>
                  <li className="w-[10%] border-x text-center flex items-center justify-center">
                    {options.data.ton}
                  </li>
                  <li className="w-[10%] border-r-[1px] text-center flex items-center justify-center">
                    {options.data.dvt}
                  </li>
                  <li className="w-[10%] pr-6 flex items-center justify-end">
                    {formatNumberVND(options.data.giaBan ?? 0)}
                  </li>
                </ul>
              )}
            />
            <div className="mt-2 h-64">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                  },
                }}
              >
                <Table
                  bordered
                  columns={columnsGK}
                  dataSource={listGongKinhBN}
                  pagination={false}
                  scroll={{ y: 190 }}
                  summary={() => (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4}></Table.Summary.Cell>
                        <Table.Summary.Cell index={5}></Table.Summary.Cell>
                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                        <Table.Summary.Cell index={7}></Table.Summary.Cell>
                        <Table.Summary.Cell index={8} className="text-end font-semibold">
                          <span>
                            {formatNumberVND(
                              listGongKinhBN?.reduce((tong, item) => tong + item.giaBan, 0) ?? 0,
                            )}
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={9} className="text-end font-semibold">
                          <span>
                            {formatNumberVND(
                              listGongKinhBN?.reduce((tong, item) => tong + item.thanhToan, 0) ?? 0,
                            )}
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={10}></Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
export default FormTK
