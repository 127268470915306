import React, { useState, useRef } from 'react'
import { Table, ConfigProvider, Button, Input, Divider } from 'antd'

const XQuangGK = () => {
  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            padding: 3,
            fontSize: 13,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
            },
          },
        }}
      >
        <Table
          scroll={{ y: 200 }}
          className="w-[99.85%]"
          bordered
          pagination={false}
          dataSource={[
            1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9,
          ]}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 'stt',
              width: 35,
              align: 'center',
              fixed: 'left',
              render: (text, record, index) => ++index,
            },
            {
              title: 'Mã CĐHA',
              dataIndex: 'stt',
              key: 'stt',
              width: 140,
            },
            {
              title: 'Tên CĐHA',
              dataIndex: 'stt',
              key: 'stt',
            },
            {
              title: 'ĐVT',
              dataIndex: 'stt',
              key: 'stt',
              width: 50,
              align: 'center',
            },
            {
              title: '',
              dataIndex: 'action',
              key: 'action',
              width: 50,
              align: 'center',
            },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default XQuangGK
