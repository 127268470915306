import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SyncIcon from '@mui/icons-material/Sync'
import _ from 'lodash'
import { DatePicker, Select, ConfigProvider, Table, Input, Button, TreeSelect } from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import dayjs from 'dayjs'
import moment from 'moment'
import { FormOutlined, SearchOutlined } from '@ant-design/icons'
import { formattedNumber } from '../../../../utils/formattedNumber'
import IconButton from '@mui/material/IconButton'
import { fetchPhieuThu } from '../../../../store/actions/drugstoreAction'
import DrawerThuocBanToa from './DrawerThuocBanToa/DrawerThuocBanToa'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { reportService } from '../../../../services/Report/reportService'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import 'moment/locale/vi'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const { SHOW_PARENT } = TreeSelect
const PTThuoc = () => {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { PTThuoc } = useSelector((state) => state.drugstoreReducer)
  const [open, setOpen] = useState(false)
  const [branch, setBranch] = useState([])
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState(null)
  const [value, setValue] = useState([BranchLogin])
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState({ form: now, to: now })
  const [chiTiet, setChiTiet] = useState(null)
  const [keyword, setKeyword] = useState('')
  const dispatch = useDispatch()
  const filter = {
    idChuyenKhoa: idChuyenKhoa,
    idct: id_company,
    idcn: value,
    tuNgay: date.form,
    denNgay: date.to,
    keyword: keyword.trim(),
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDateValue = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDateValue }))
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      dispatch(fetchPhieuThu({ ...filter, keyword: keyword }, setIsLoading))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const onReload = () => {
    setChiTiet(null)
    dispatch(fetchPhieuThu(filter, setIsLoading))
  }
  const onSearch = (e) => {
    const { value } = e.target
    setKeyword(value)
    // debounceDropDown(value)
  }
  useEffect(() => {
    fetchBranchByChuyenKhoa(null)
    fetchChuyenKhoa()
    onReload()
  }, [])
  const exportExcel = () => {
    const formatData = PTThuoc?.map((item) => ({
      STT: item.stt,
      'Mã phiếu Thu': item.maphieuthu,
      'Chi nhánh': item.tenchinhanh,
      'Ngày bán': moment(item.ngayban).format('DD/MM/YYYY'),
      'Tên bệnh nhân': item.tenbenhnhan,
      'Mã bệnh nhân': item.mabenhnhan,
      'Ngày sinh': moment(item.ngaysinh).format('DD/MM/YYYY'),
      'Bác sĩ kê toa': item.bskedon,
      'Dược sĩ bán': item.duocsiban,
      'Tiền mặt': formattedNumber(item.tienmat),
      'Chuyển khoản': formattedNumber(item.chuyenkhoan),
      'Máy Pos': formattedNumber(item.pos),
      Momo: formattedNumber(item.momo),
      VNPay: formattedNumber(item.vnpay),
      ZaloPay: formattedNumber(item.zalopay),
      'Thẻ thanh toán': formattedNumber(item.thett),
      'Tổng tiền': formattedNumber(item.tongtien),
      'Tiền giảm': formattedNumber(item.tiengiamgia),
      'Tiền thanh toán': formattedNumber(item.tienthanhtoan),
    }))
    const name = `Phiếu thu toa thuốc - ${moment().format('DD-MM-YYYY')}` // format date L = '26.08.2024'
    exportExcelformat(formatData, name)
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(id_company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      children: branch?.map((branch, index) => ({
        key: branch.idChiNhanh,
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 45,
      align: 'center',
      key: 'stt',
      fixed: 'left',
      showSorterTooltip: false,
    },
    {
      title: 'Mã phiếu thu',
      dataIndex: 'maphieuthu',
      width: 120,
      showSorterTooltip: false,
      key: 'maphieuthu',
      fixed: 'left',
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.maphieuthu ?? '').localeCompare(b?.maphieuthu ?? '')
        },
      },
      render: (text, record) => (
        <span
          className="text-blue-500 cursor-pointer"
          onClick={() => {
            setChiTiet(record)
            setOpen(true)
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      // width: 250,
      showSorterTooltip: false,
      key: 'tenchinhanh',
      fixed: 'left',
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tenchinhanh ?? '').localeCompare(b?.tenchinhanh ?? '')
        },
      },
    },
    {
      title: 'Ngày bán',
      dataIndex: 'ngayban',
      // width: 140,
      align: 'center',
      key: 'ngayban',
      showSorterTooltip: false,
      render: (text) => (text ? moment(text)?.format('DD/MM/YYYY HH:mm:ss') : '-'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngayban ?? '').localeCompare(b?.ngayban ?? '')
        },
      },
    },
    {
      title: 'Tên BN',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
      showSorterTooltip: false,
      // width: 250,
      sorter: {
        compare: (a, b) => {
          return (a?.tenbenhnhan ?? '').localeCompare(b?.tenbenhnhan ?? '')
        },
      },
    },
    {
      title: 'Mã BN',
      dataIndex: 'mabenhnhan',
      showSorterTooltip: false,
      key: 'mabenhnhan',
      sorter: {
        compare: (a, b) => {
          return (a?.mabenhnhan ?? '').localeCompare(b?.mabenhnhan ?? '')
        },
      },
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      width: 100,
      align: 'center',
      key: 'ngaysinh',
      showSorterTooltip: false,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysinh ?? '').localeCompare(b?.ngaysinh ?? '')
        },
      },
    },
    {
      title: 'Bác sĩ kê toa',
      dataIndex: 'bskedon',
      key: 'bskedon',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.bskedon ?? '').localeCompare(b?.bskedon ?? '')
        },
      },
    },
    {
      title: 'Dược sĩ bán',
      dataIndex: 'duocsiban',
      key: 'duocsiban',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.duocsiban ?? '').localeCompare(b?.duocsiban ?? '')
        },
      },
    },
    {
      title: 'Tiền mặt',
      dataIndex: 'tienmat',
      key: 'tienmat',
      align: 'right',
      width: 100,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tienmat ?? 0) - (b.tienmat ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.tienMat ?? '').localeCompare(b?.tienMat ?? '')
      //   },
      // },
    },
    {
      title: 'Chuyển khoản',
      dataIndex: 'chuyenkhoan',
      key: 'chuyenkhoan',
      width: 120,
      showSorterTooltip: false,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (a.chuyenkhoan ?? 0) - (b.chuyenkhoan ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.chuyenKhoan ?? '').localeCompare(b?.chuyenKhoan ?? '')
      //   },
      // },
    },
    {
      title: 'Máy Pos',
      dataIndex: 'pos',
      key: 'pos',
      width: 90,
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.pos ?? 0) - (b.pos ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Momo',
      dataIndex: 'momo',
      key: 'momo',
      align: 'right',
      showSorterTooltip: false,
      width: 90,
      sorter: {
        compare: (a, b) => {
          return (a.momo ?? 0) - (b.momo ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.moMo ?? '').localeCompare(b?.moMo ?? '')
      //   },
      // },
    },
    {
      title: 'VNPay',
      dataIndex: 'vnpay',
      key: 'vnpay',
      align: 'right',
      width: 90,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.vnpay ?? 0) - (b.vnpay ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'ZaloPay',
      dataIndex: 'zalopay',
      key: 'zalopay ',
      align: 'right',
      width: 90,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.zalopay ?? 0) - (b.zalopay ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Thẻ thanh toán',
      dataIndex: 'thett',
      key: 'thett',
      align: 'right',
      width: 130,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.thett ?? 0) - (b.thett ?? 0)
        },
      },
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Mã voucher',
      dataIndex: 'mavoucher',
      key: 'mavoucher',
      width: 110,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.mavoucher ?? '').localeCompare(b?.mavoucher ?? '')
        },
      },
    },
    {
      title: <p className="text-center">Tổng tiền</p>,
      dataIndex: 'tongtien',
      key: 'tongtien',
      align: 'right',
      width: 100,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tongtien ?? 0) - (b.tongtien ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
    },
    {
      title: <p className="text-center">Tiền giảm</p>,
      dataIndex: 'tiengiamgia',
      key: 'tiengiamgia',
      width: 100,
      align: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tiengiamgia ?? 0) - (b.tiengiamgia ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
    },
    {
      title: <p className="text-center">Thanh toán</p>,
      dataIndex: 'tienthanhtoan',
      key: 'tienthanhtoan',
      width: 100,
      align: 'right',
      fixed: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a.tienthanhtoan ?? 0) - (b.tienthanhtoan ?? 0)
        },
      },
      render: (text) => (text ? formattedNumber(text) : 0),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 45,
      align: 'center',
      showSorterTooltip: false,
      fixed: 'right',
      render: (text, record, index) => (
        <ul
          className="flex justify-center"
          onClick={() => {
            setChiTiet(record)
            setOpen(true)
          }}
        >
          <li className="text-green-700 bg-green-200 cursor-pointer w-6 hover:text-white hover:bg-green-700 duration-200 rounded">
            <FormOutlined style={{ fontSize: 16, fontWeight: 700 }} />
          </li>
        </ul>
      ),
    },
  ]
  return (
    <div className="h-full w-full ">
      <div className="flex gap-5 justify-between   mb-2 h-full">
        <div className="flex gap-2">
          <div className="flex items-center gap-1">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="w-56">
            <Select
              onChange={onChangeChuyenKhoa}
              className="w-full"
              value={idChuyenKhoa}
              options={[
                { label: 'Tất cả', value: null },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
            />
          </div>
          <div className="w-80">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="w-60 flex gap-1 items-center">
            <Input
              placeholder="Nhập từ khóa..."
              prefix={<SearchOutlined className="pr-1" />}
              onChange={onSearch}
              value={keyword}
              className="w-full"
            />
          </div>
          <div className="flex gap-2">
            <IconButton onClick={onReload} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={exportExcel}
              disabled={PTThuoc.length === 0}
              className="text-green-700 font-semibold"
              type="text"
            >
              <ImportExportIcon /> Excel
            </Button>
          </div>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            scroll={{
              x: PTThuoc.length !== 0 ? 'max-content' : 2200,
              y: 682,
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={PTThuoc}
            columns={columns}
            summary={(pageData) => {
              let sumtienmat = 0
              let sumchuyenkhoan = 0
              let summaypos = 0
              let summomo = 0
              let sumvnpay = 0
              let sumzalopay = 0
              let sumthethanhtoan = 0
              let sumtiengiam = 0
              let sumthanhtoan = 0
              let sumtongtien = 0
              pageData.forEach(
                ({
                  tienmat,
                  chuyenkhoan,
                  pos,
                  momo,
                  zalopay,
                  vnpay,
                  thett,
                  tongtien,
                  tiengiamgia,
                  tienthanhtoan,
                }) => {
                  sumtienmat += tienmat
                  sumchuyenkhoan += chuyenkhoan
                  summaypos += pos
                  summomo += momo
                  sumvnpay += vnpay
                  sumzalopay += zalopay
                  sumthethanhtoan += thett
                  sumtongtien += tongtien
                  sumtiengiam += tiengiamgia
                  sumthanhtoan += tienthanhtoan
                },
              )
              // console.log;
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                    <Table.Summary.Cell index={9}>
                      <p className="font-semibold text-right">{formattedNumber(sumtienmat)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10}>
                      <p className="font-semibold text-right">{formattedNumber(sumchuyenkhoan)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>
                      <p className="font-semibold text-right">{formattedNumber(summaypos)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12}>
                      <p className="font-semibold text-right">{formattedNumber(summomo)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13}>
                      <p className="font-semibold text-right">{formattedNumber(sumvnpay)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14}>
                      <p className="font-semibold text-right">{formattedNumber(sumzalopay)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15}>
                      <p className="font-semibold text-right">{formattedNumber(sumthethanhtoan)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16}></Table.Summary.Cell>
                    <Table.Summary.Cell index={17}>
                      <p className="font-semibold text-right">{formattedNumber(sumtongtien)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18}>
                      <p className="font-semibold text-right">{formattedNumber(sumtiengiam)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19}>
                      <p className="font-semibold text-right">{formattedNumber(sumthanhtoan)}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
      <DrawerThuocBanToa open={open} setOpen={setOpen} chiTiet={chiTiet} />
    </div>
  )
}

export default PTThuoc
