import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Input } from "antd";
const { TextArea } = Input;
const BN = () => {
  const { infoBN, service, BNDetail } = useSelector((state) => state.drugstoreReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  return (
    <div className="border rounded h-[400px]">
      <h2 className="border-b text-lg font-semibold text-gray-500 p-2">
        Bệnh nhân
      </h2>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Tên BN:</label>
          <p>{infoBN?.tenBenhNhan}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Mã BN:</label>
          <p>{infoBN?.maBenhNhan}</p>
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Giới tính:</label>
          <p>{infoBN?.gioiTinh}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Ngày sinh:</label>
          <p>
            {infoBN?.ngaySinh &&
              moment(infoBN?.ngaySinh).format("DD/MM/YYYY HH:mm:ss")}
          </p>
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Người nhà:</label>
          <p>{BNDetail?.tennguoinha}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">SĐT:</label>
          <p>{BNDetail?.dienthoainguoinha}</p>
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Dược sĩ:</label>
          {service?.BSKEDON && (<p>{infoUser?.tenNV}</p>)}
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Chi nhánh:</label>
          {service?.BSKEDON && (<p>{PkDangNhap?.tenChiNhanh}</p>)}
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Bác sĩ kê toa:</label>
          <p>{service?.BSKEDON}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Ngày kê:</label>
          <p>
            {service?.NGAYKEDON &&
              moment(service?.NGAYKEDON).format("DD/MM/YYYY HH:mm:ss")}
          </p>
        </div>
      </div>
      <div className="flex p-2 items-center">
        <label className="font-semibold w-32">Lý do khám :</label>
        <TextArea
          disabled
          style={{ color: "black" }}
          value={BNDetail?.lydokham}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </div>
      <div className="flex p-2 items-center">
        <label className="font-semibold w-32">Lời dặn :</label>
        <TextArea
          disabled
          style={{ color: "black" }}
          value={BNDetail?.loidan}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </div>
    </div>
  );
};

export default BN;
