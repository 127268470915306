import * as typeAction from '../constants/constants'
export const putInfoChanDoanAction = (form, list, isPrint, resetForm) => ({
  type: typeAction.PUT_INFO_CHANDOAN,
  form,
  list,
  isPrint,
  resetForm,
})
// export const putInfoKhamNgoaiKhoaKSKAction = (form, resetForm) => ({
//   type: typeAction.PUT_INFO_KHAMNGOAIKHOA_KSK,
//   form,
//   resetForm,
// })
// export const BNDetailInfo = (idbn, idbnttc) => ({
//   type: typeAction.GET_INFO_DETAIL_BN_KHAM,
//   idbn,
//   idbnttc,
// })
// export const updateBnDetail = (infoBN, formBenhNhan, form, idcnPk, newArr, isPrint) => ({
//   type: typeAction.UPDATE_DETAIL_BN_KHAM,
//   infoBN,
//   formBenhNhan,
//   form,
//   idcnPk,
//   newArr,
//   isPrint,
// })
// export const SuggestDoctor = () => ({
//   type: typeAction.GET_SUGGEST_DOCTOR,
// })
// export const thuocKeToa = (idChiNhanh) => ({
//   type: typeAction.GET_THUOC_KE_TOA_DOCTOR,
//   idChiNhanh,
// })
// //------- thêm chỉ định bệnh nhân ------//
// export const addNewChiDinhBN = (
//   ValuePrinter,
//   form,
//   listDel,
//   idbnttc,
//   tenNV,
//   handleReset,
//   chandoanbandau,
// ) => ({
//   type: typeAction.ADD_NEW_CHI_DINH_KHAM,
//   ValuePrinter,
//   form,
//   listDel,
//   idbnttc,
//   tenNV,
//   handleReset,
//   chandoanbandau,
// })
// export const BNDaKhamAction = (idnv, idcn, tuNgay, denNgay) => ({
//   type: typeAction.GET_BN_DA_KHAM_BY_PK,
//   idnv,
//   idcn,
//   tuNgay,
//   denNgay,
// })
// export const deleteBNThuocBSKhongKeToaAction = (
//   form,
//   formBenhNhan,
//   idbnttc,
//   idlydokhonglaythuoc,
// ) => ({
//   type: typeAction.DELETE_BN_THUOC_BS_KHONG_KE_TOA,
//   form,
//   formBenhNhan,
//   idbnttc,
//   idlydokhonglaythuoc,
// })
// export const updateBNThuocBSKeToaLaiAction = (
//   form,
//   formBenhNhan,
//   idbnttc,
//   list,
//   infobn,
//   bnttc,
//   pkdangnhap,
//   isPrint,
// ) => ({
//   type: typeAction.PUT_BN_THUOC_BS_KE_TOA_LAI,
//   form,
//   formBenhNhan,
//   idbnttc,
//   list,
//   infobn,
//   bnttc,
//   pkdangnhap,
//   isPrint,
// })
// // src/store/actions/childrenClinicAction.js
// export const updateDetailBN = (payload) => ({
//   type: 'UPDATE_DETAIL_BN',
//   payload,
// })
