import React from 'react'
import { ConfigProvider, Tabs } from 'antd'
import MemberCard from './TTV/MemberCard'

const DoanhSo = () => {
  return (
    <div className="p-3 bg-[#EFEFEF] h-full">
      <div className="bg-white p-2 rounded-lg border h-full">
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                itemColor: '#888888',
                padding: 5,
              },
            },
          }}
        >
          <Tabs
            items={[
              {
                key: 1,
                label: <p className="font-semibold ">Thẻ thành viên nhi</p>,
                children: <MemberCard chuyenKhoa={1} />,
              },
              {
                key: 2,
                label: <p className="font-semibold ">Thẻ thành viên sản</p>,
                children: <MemberCard chuyenKhoa={2} />,
              },
              {
                key: 3,
                label: <p className="font-semibold ">Thẻ thành viên lão</p>,
                children: <MemberCard chuyenKhoa={3} />,
              },
              {
                key: 4,
                label: <p className="font-semibold ">Thẻ thành viên mắt</p>,
                children: <MemberCard chuyenKhoa={4} />,
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default DoanhSo
