import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { columns } from './Columns'
import { useDispatch, useSelector } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import * as typeAction from '../../../../store/constants/constants'
import moment from 'moment'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { getBranchNotVP } from '../../../../store/actions/BranchAction'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../utils/formattedNumber'
import 'moment/locale/vi'
moment.locale('vi')

const { SHOW_PARENT } = TreeSelect
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)

const generateFakeData = (num) => {
  const fakeData = []
  for (let i = 0; i < num; i++) {
    fakeData.push({
      stt: i + 1,
      tenchinhanh: `Chi nhánh ${i + 1}`,
      ngaythanhtoan: moment().subtract(i, 'days').format(momentDate),
      mabenhnhan: `BN${i + 1}`,
      tenbenhnhan: `Bệnh nhân ${i + 1}`,
      diachi: `Địa chỉ ${i + 1}`,
      maxetnghiem: `XN${i + 1}`,
      tenxetnghiem: `Xét nghiệm ${i + 1}`,
      dvt: 'Đơn vị',
      thanhtoan: Math.floor(Math.random() * 1000000),
      tienguimau: Math.floor(Math.random() * 1000000),
      loinhuan: Math.floor(Math.random() * 1000000),
      trangthai: i % 2 === 0 ? '1' : '0', // 1: Đã gửi mẫu, 0: Chưa gửi mẫu
    })
  }
  return fakeData
}

const BaoCaoXetNghiem = () => {
  const dispatch = useDispatch()
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const [company, setCompany] = useState(id_company)
  const [date, setDate] = useState({ form: now, to: now })
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState([BranchLogin])
  const [data, setData] = useState([])

  useEffect(() => {
    dispatch(getBranchNotVP(company, setBranch))
  }, [company])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    setData(generateFakeData(50)) // Tạo 50 dòng dữ liệu giả
  }, [])

  const openLoading = () => {
    dispatch({
      type: typeAction.OPEN_LOADING_TABLE,
    })
  }
  const closeLoading = () => {
    dispatch({
      type: typeAction.CLOSE_LOADING_TABLE,
    })
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDate }))
  }
  const onChangeCompany = (value) => {
    setCompany(value)
    setValue([])
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn công ty ',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = () => {
    // const formatData = data.map((item) => ({
    //   'Ngày chứng từ': moment(item.ngaythanhtoan).format('DD/MM/YYYY'),
    //   'Số chứng từ': item.machungtu,
    //   'Mã công cụ': item.mathe,
    //   'Mã bệnh nhân': item.mabenhnhan,
    //   'Tên công cụ': item.tenbenhnhan,
    //   'Ngày tăng': moment(item.ngayhieuluc).format('DD/MM/YYYY'),
    //   'Ngày tính phân bổ': moment(item.ngayhieuluc).format('DD/MM/YYYY'),
    //   'Ngày kết thúc pb': moment(item.ngayketthuc).format('DD/MM/YYYY'),
    //   'Số kỳ phân bổ': item.sothang,
    //   ĐVT: 'Tháng',
    //   'Mã bộ phận': item.machinhanh,
    //   'Tên bộ phận': item.tenchinhanh,
    //   'Nguyên giá': item.tienthanhtoan,
    //   'Gt phân bổ kỳ': item.giaphanbo,
    // }))
    // const name = 'Báo cáo thẻ thành viên'
    // exportExcelformat(formatData, name)
  }

  return (
    <div className="p-2 bg-[#EFEFEF] h-full">
      <div className="p-3 bg-white h-full">
        <div className="flex gap-3 border-dotted border-b-0  p-2 ">
          <Select
            showSearch
            value={company}
            onChange={onChangeCompany}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className={`w-80`}
          />
          <div className={`w-80 `}>
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              value={dayjs(date.form)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              value={dayjs(date.to)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>

          <div className="flex gap-3">
            <IconButton
              color="primary"
              // onClick={fetchReport}
              aria-label="delete"
              size="small"
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="flex gap-3 ml-auto">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              borderRadius: 0,
            },
          }}
        >
          <Table
            loading={isLoadingTable}
            bordered
            scroll={{
              x: 1500,
              y: 660,
            }}
            dataSource={data}
            columns={columns}
            pagination={false} // Disable pagination
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={9}>
                    <span className="font-semibold">
                      {formattedNumber(
                        //đơn giá
                        data?.reduce((tong, item) => (tong += item?.thanhtoan), 0),
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={10}>
                    <span className="font-semibold">
                      {formattedNumber(
                        //thành tiền
                        data?.reduce((tong, item) => (tong += item?.tienguimau), 0),
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={11}>
                    <span className="font-semibold">
                      {formattedNumber(
                        //thành tiền
                        data?.reduce((tong, item) => (tong += item?.loinhuan), 0),
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default BaoCaoXetNghiem
