import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import LayoutApp from '../../../HOCs/LayoutApp'
import {
  Button,
  ConfigProvider,
  Input,
  Popconfirm,
  Select,
  Table,
  Tabs,
  Tooltip,
  Checkbox,
} from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import AddIcon from '@mui/icons-material/Add'
import CachedIcon from '@mui/icons-material/Cached'

import { IconButton } from '@mui/material'
import { https } from '../../../services/apiService'
import ToastCus from '../../../utils/Toast'
import {
  ContainerOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import ModalCreateVoucher from './ModalAddVoucher/ModalAddVoucher'
import { debounce } from 'lodash'
import * as XLSX from 'xlsx'
import ModalEditVoucher from './ModalEditVoucher/ModalEditVoucher'
import { render } from 'bwip-js'
import { useSearchParams } from 'react-router-dom'
import 'moment/locale/vi'
moment.locale('vi')
function Voucher(props) {
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [dataShow, setDataShow] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [showEditModal, setShowEditModal] = useState({
    show: false,
    data: null,
  })
  const getAllChuongTrinh = async () => {
    try {
      const result = await https.get(`Voucher/GetAllVoucher`)
      setData(result.data)
      setDataShow(result.data?.sort((a, b) => (b?.ngaytao ?? '').localeCompare(a?.ngaytao ?? '')))
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại',
      })
    }
  }
  useEffect(() => {
    getAllChuongTrinh()
  }, [])

  const debounceSearch = useCallback(
    debounce((keyword, data) => {
      const result = data.filter(
        (item) =>
          item.mact.toLowerCase().includes(keyword.toLowerCase()) ||
          item.tenct.toLowerCase().includes(keyword.toLowerCase()),
      )
      setDataShow(result)
    }, 500),
    [],
  )

  const handleTableChange = (pagination) => {
    SetSearchParams({ page: pagination.current })
  }
  const exportToExcel = () => {
    // Custom column names
    const headers = [
      'Mã chương trình',
      'Tên chương trình',
      'Bắt đầu',
      'Kết thúc',
      'Loại giá trị',
      'Giá trị',
      'Người tạo',
      'Ngày tạo',
      'Số lượng mã',
      'Độ dài mã',
      'Ký tự đầu',
      'Ký tự cuối',
      'Ghi chú',
    ]
    const formattedData = dataShow.map((item) => ({
      'Mã chương trình': item.mact || '',
      'Tên chương trình': item.tenct || '',
      'Bắt đầu': item.batdau ? moment(item.batdau).format('DD/MM/YYYY HH:mm') : '',
      'Kết thúc': item.ketthuc ? moment(item.ketthuc).format('DD/MM/YYYY HH:mm') : '',
      'Loại giá trị': item.loaigia || '',
      'Giá trị': item.giatri || '',
      'Người tạo': item.nguoitao || '',
      'Ngày tạo': item.ngaytao ? moment(item.ngaytao).format('DD/MM/YYYY HH:mm') : '',
      'Số lượng mã': item.soluongma || '',
      'Độ dài mã': item.dodaima || '',
      'Ký tự đầu': item.kytudau || '',
      'Ký tự cuối': item.kytucuoi || '',
      'Ghi chú': item.ghichu || '',
    }))
    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
    XLSX.writeFile(wb, `Danh Sách Chương Trình - ${moment(new Date()).format('DD-MM-YYYY')}.xlsx`)
  }

  const handleSearch = (e) => {
    const { value } = e.target
    debounceSearch(value, data) // Pass data as a parameter
    setSearch(value)
  }

  const checkConHan = (ngayBD, ngayKT, format) => {
    return (
      moment().isAfter(moment(ngayBD, format)) &&
      moment().isBefore(moment(ngayKT, format).add(1, 'days'))
    )
  }
  const columnsAllVoucher = [
    {
      title: 'STT ',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
      render: (text, record, index) => (useSearch.get('page') - 1) * 20 + index + 1,
    },
    {
      title: 'Mã chương trình',
      dataIndex: 'mact',
      width: 120,
      align: 'center',
      key: 'mact',
    },
    {
      title: 'Tên chương trình',
      dataIndex: 'tenct',
      key: 'tenct',
    },
    {
      title: 'Loại chương trình',
      dataIndex: 'loaict',
      key: 'loaict',
      width: 130,
      align: 'center',
    },
    {
      title: 'Bắt đầu',
      dataIndex: 'batdau',
      key: 'batdau',
      width: 90,
      align: 'center',
    },
    {
      title: 'Kết thúc',
      dataIndex: 'ketthuc',
      key: 'ketthuc',
      width: 90,
      align: 'center',
    },
    {
      title: 'Giá trị thẻ',
      dataIndex: 'giatri',
      key: 'giatri',
      width: 100,
      align: 'right',
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
    },
    {
      title: 'Ngày  tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      width: 140,
      align: 'center',
    },
    {
      title: 'Còn hạn',
      dataIndex: 'conhan',
      key: 'conhan',
      width: 70,
      align: 'center',
      render: (text, record, index) => (
        <Checkbox checked={checkConHan(record.batdau, record.ketthuc, 'DD/MM/YYYY')} />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 45,
    },
  ]
  return (
    <>
      <div className="flex  gap-2 h-full p-2 bg-[#efefef]">
        <div className="h-full w-full bg-white rounded-xl">
          <div className="flex justify-between p-2">
            <div className="flex 2xl:gap-5 gap-2 items-center ">
              <Input.Search
                value={search}
                onChange={handleSearch}
                className="2xl:w-72 w-56"
                placeholder="Nhập thông tin cần tìm"
              />
            </div>
            <div className="flex items-center 2xl font-semibold gap-5">
              <Button disabled type="primary">
                In
              </Button>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: 'rgb(22, 163, 74)',
                  },
                }}
              >
                <Button
                  onClick={exportToExcel}
                  className="text-green-700 font-semibold"
                  type="default"
                >
                  Xuất <ImportExportIcon />
                </Button>
              </ConfigProvider>
              <Button
                type="primary"
                onClick={() => setShowAddModal(true)}
                className="flex items-center font-semibold"
              >
                Tạo <AddIcon fontSize="small" />
              </Button>
            </div>
          </div>
          <div className="p-2 pt-0">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
              }}
            >
              <Table
                scroll={{
                  x: 1500,
                }}
                bordered
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,
                }}
                columns={columnsAllVoucher}
                onChange={handleTableChange}
                dataSource={dataShow?.map((item) => ({
                  mact: item.mact,
                  tenct: item.tenct,
                  batdau: item.batdau ? moment(item.batdau).format('DD/MM/YYYY') : '',
                  ketthuc: item.ketthuc ? moment(item.ketthuc).format('DD/MM/YYYY') : '',
                  giatri: item.giatri && formatNumberVND(item.giatri),
                  ngaytao: item.ngaytao ? moment(item.ngaytao).format('DD/MM/YYYY HH:mm:ss') : '',
                  nguoitao: item.nguoitao,
                  loaict:
                    item.idcombo === 3
                      ? 'Giảm giá'
                      : item.idcombo === 2
                        ? 'Mã đối tác'
                        : item.idcombo === 1
                          ? 'Combo'
                          : 'Voucher',
                  action: (
                    <ul className="flex gap-2 justify-around">
                      <li className="text-lg">
                        <Tooltip title="Chi tiết" color="#108ee9">
                          <ContainerOutlined
                            onClick={() => setShowEditModal({ show: true, data: { ...item } })}
                            className="text-xl text-[#108ee9]  cursor-pointer"
                          />
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                }))}
              />
            </ConfigProvider>
          </div>
        </div>
        {showAddModal && (
          <ModalCreateVoucher
            showAddModal={showAddModal}
            setShowAddModal={setShowAddModal}
            getAllChuongTrinh={getAllChuongTrinh}
            maChuongTrinh={Number(dataShow[0]?.mact?.replace('VC', '')) ?? data.length ?? 0}
          />
        )}
        {showEditModal.show && (
          <ModalEditVoucher
            showEditModal={showEditModal}
            setShowEditModal={setShowEditModal}
            getAllChuongTrinh={getAllChuongTrinh}
          />
        )}
      </div>
    </>
  )
}

Voucher.propTypes = {}

export default Voucher
