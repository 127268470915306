import moment from "moment";
import { formatNumberVND } from "../../../../../utils/formatNumberVND";

export const columns = [
  {
    title: 'Phiếu thu',
    dataIndex: 'ngay',
    key: 'ngay',
    width: 100,
    render:(text) => text ? moment(text).format('DD/MM/YYYY'): '-'
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'tenChiNhanh',
    key: 'tenChiNhanh',
    width: 250,
  },
  {
    title: 'Ngày',
    dataIndex: 'tenNhomDV',
    key: 'tenNhomDV',
    width: 250,
  },
  {
    title: 'Mã bệnh nhân',
    dataIndex: 'tenDV',
    key: 'tenDV',
    width: 250,
  },
  {
    title: 'Tên bệnh nhân',
    dataIndex: 'tenDV',
    key: 'tenDV',
    width: 250,
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'dvt',
    key: 'dvt',
    width: 250,
  },
  {
    title: 'Tiếp nhận lúc',
    dataIndex: 'soLuong',
    key: 'soLuong',
    width: 250,
  },
  {
    title: 'Bắt đầu khám',
    dataIndex: 'donGia',
    key: 'donGia',
    width: 250,
    render:(text) => formatNumberVND(text)
  },
  {
    title: 'Kết thúc khám',
    dataIndex: 'thanhTien',
    key: 'thanhTien',
    width: 250,
    render:(text) => formatNumberVND(text)
  },
  {
    title: 'Thời gian khám',
    dataIndex: 'ptGiamGia',
    key: 'ptGiamGia',
    width: 250,
  },
  {
    title: 'Phát thuốc',
    dataIndex: 'tienGiam',
    key: 'tienGiam',
    width: 250,
    render:(text) => formatNumberVND(text)
  },
  {
    title: 'Bác sĩ khám',
    dataIndex: 'thanhToan',
    key: 'thanhToan',
    width: 250,
    render:(text) => formatNumberVND(text)
  },
]
