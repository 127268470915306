import JsBarcode from 'jsbarcode'
import moment from 'moment/moment'
import { generateLogo } from '../algorithm'
import { jsPDFCus } from '../jsPdfAddFont'
import { numberToWords } from '../numberToWords'
import { splitLongWord } from '../splitWordForReport'
import { formatNumberVND } from '../formatNumberVND'

export const generatePhieuKiemNhapVacxin = (infoBn, benhNhanTTC, toaThuoc, PkDangNhap) => {
  console.log(infoBn, benhNhanTTC, toaThuoc, PkDangNhap)
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 10
  const pageWidth = doc.internal.pageSize.width
  //header
  generateLogo(doc, xPos, PkDangNhap)
  //barcode
  JsBarcode('#barcode', `TT${benhNhanTTC?.idbnttc}`, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 35 // Width of the barcode image
  const barcodeX = pageWidth - 45 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', barcodeX, 5, barcodeWidth, 14)

  // Calculate the center position for text
  const text = `HS: TT${benhNhanTTC?.idbnttc}`
  doc.setFontSize(9)
  const textWidth = doc.getTextWidth(text)
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text

  doc.text(text, textX, 22)
  // Title
  doc.text(`Mã BN: ${benhNhanTTC.benhNhan.maBenhNhan ?? ''}`, textX, 26)
  doc.setFont('times', 'bold')
  doc.setFontSize(12)
  doc.text('BIÊN BẢN KIỂM NHẬP VACCINE', doc.internal.pageSize.width / 2, 32, { align: 'center' })
  // Patient Info
  doc.setFontSize(10)
  let textWidthName = doc.getTextDimensions('Họ và tên: ').w
  doc.text(`${(benhNhanTTC?.benhNhan?.tenBenhNhan ?? '')?.toUpperCase()}`, xPos + textWidthName, 38)
  doc.setFont('times', 'regular')
  doc.text('Họ và tên: ', xPos, 38)
  doc.text(
    `Ngày sinh: ${benhNhanTTC?.benhNhan?.ngaySinh ? moment(benhNhanTTC?.benhNhan?.ngaySinh).format('DD/MM/YYYY') : ''}`,
    pageWidth - 70,
    38,
  )
  yPos = 43
  const diaChiBenhNhan = `Địa chỉ: ${infoBn?.diaChi ?? ''}${infoBn?.tenPhuongXa ? ', ' + infoBn?.tenPhuongXa : ''}${infoBn?.tenQuanHuyen ? ', ' + infoBn?.tenQuanHuyen : ''}${infoBn?.tenTinhTP ? ', ' + infoBn?.tenTinhTP : ''}`
  if (diaChiBenhNhan.length > 85) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 85)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(diaChiBenhNhan, xPos, yPos)
    yPos += 5
  }
  doc.text(`Bác sĩ: ${benhNhanTTC?.bskham ?? ''}`, xPos, yPos)
  yPos += 5
  const chandoan = `Chẩn đoán: ${benhNhanTTC?.chandoan ?? ''}`
  if (chandoan.length > 85) {
    const wordsArray = splitLongWord(chandoan, 85)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(chandoan, xPos, yPos)
    yPos += 3
  }
  // Table
  const headers = [
    'STT',
    'Tên hàng hóa',
    'Số lô',
    'Hạn dùng',
    'Số hoá đơn',
    'Đơn vị',
    'SL',
    'Chỉ thị đông băng',
  ]
  const data = toaThuoc?.map((item, idx) => [
    ++idx,
    item?.tenBietDuoc ?? item?.tenbietduoc,
    item?.soLo ?? item?.solo,
    (item?.hanDung && moment(item?.hanDung).format('DD/MM/YYYY')) ||
      (item?.handung && moment(item?.handung).format('DD/MM/YYYY')),
    item?.soHoaDon ?? item?.sohoadon,
    item?.dvt,
    item?.soLuong ?? item?.soluong,
    ' ',
  ])
  doc.autoTable({
    head: [headers],
    body: data,
    startY: yPos,
    columnStyles: {
      0: { cellWidth: 10 },
      1: { cellWidth: 35, halign: 'left' },
      2: { cellWidth: 15 },
      3: { cellWidth: 18 },
      4: { cellWidth: 18 },
      5: { cellWidth: 15 },
      6: { cellWidth: 10 },
      7: { cellWidth: 18 },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1,
      fillColor: '#fff',
      textColor: '#000',
      lineColor: '#000',
      halign: 'center',
      valign: 'middle',
      fontStyle: 'regular',
      fontSize: 8,
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff',
      textColor: '#000',
      fontStyle: 'bold',
      fontSize: 8,
    },
    alternateRowStyles: {
      font: 'times',
      fontStyle: 'regular',
      fillColor: '#fff',
    },
    showHead: 'firstPage',
    margin: xPos - 5,
  })

  const tableFinalY = doc.autoTable.previous.finalY
  yPos = tableFinalY + 5 // Add a small margin after the table

  if (yPos > 180) {
    doc.addPage()
    yPos = 10
  }
  doc.setFont('times', 'italic')
  doc.setFontSize(10)
  doc.text(moment().format('HH:mm').toString(), doc.internal.pageSize.width / 2, yPos, {
    align: 'center',
  })
  doc.text(
    `Ngày ${moment().format('DD')} tháng ${moment().format('MM')} năm ${moment().format('YYYY')}`,
    xPos + 6,
    yPos,
  )
  yPos += 5
  doc.setFont('times', 'regular')
  doc.text('Người giao (dược sĩ)', xPos + 10, yPos)
  doc.text('Người nhận (Điều dưỡng)', pageWidth - 50, yPos)
  yPos += 5
  doc.text('(Ký, ghi rõ chức danh, họ và tên)', xPos + 3, yPos)
  doc.text('(Ký, ghi rõ chức danh, họ và tên)', pageWidth - 55, yPos)

  doc.setFontSize(9)
  doc.setFont('times', 'italic')
  doc.text(
    `(*)Ngày in phiếu: ${moment().format('DD/MM/YYYY HH:mm:ss')}`,
    xPos,
    doc.internal.pageSize.height - 5,
  )
  // Auto print
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
