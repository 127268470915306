import { SearchOutlined } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { Button, ConfigProvider, DatePicker, Divider, Input, Modal, Space, Table } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { cloneDeep } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { branchService } from '../../../../services/branch/branchService'
import { DISPATCH_CLEAR_INFO_DONHANG } from '../../../../store/constants/constants'

const dateFormat = 'DD/MM/YYYY'
const dateFormatAPI = 'YYYY-MM-DD'

function prepareGroupedData(data) {
  // Check if data is undefined or null, if so, return an empty array
  if (!data) return []

  // Ensure data is an array
  if (!Array.isArray(data)) return []

  const grouped = data.reduce((acc, item) => {
    const key = item.idncc || item.idkhotong
    if (!key) {
      console.warn('Item has neither idncc nor idkhotong:', item)
      return acc
    }

    if (!acc[key]) {
      acc[key] = {
        id: key,
        name: item.nhacungcap || item.khotong,
        nguoigiao: item.nguoigiao,
        ngaygiao: item.ngaygiao,
        items: [],
      }
    }

    acc[key].items.push(item)

    return acc
  }, {})

  return Object.values(grouped)
}

function ModalEdit({ openEdit, setOpenEdit }) {
  const { inforDonHang } = useSelector((state) => state.vanPhongPhamReducer)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [listBranch, setListBranch] = useState([])
  const searchInput = useRef(null)
  const selectRef = useRef(null)
  const dispatch = useDispatch()
  const ID_COMPANY = localStorage.getItem('id_company')

  const fetchBranch = async () => {
    const { data } = await branchService.getListBranch(ID_COMPANY)
    setListBranch(data)
  }
  const groupedDataArrayFormik = prepareGroupedData(inforDonHang?.DanhSachHangHoa)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      iddathang: inforDonHang?.IDDATHANG,
      chinhanhdat: inforDonHang?.CHINHANHDAT,
      chinhanhnhan: inforDonHang?.CHINHANHNHAN,
      tendonhang: inforDonHang?.TENDONHANG,
      ghichudathang: inforDonHang?.GHICHU,
      nguoiduyet: inforDonHang?.NGUOIDUYET,
      nguoidat: inforDonHang?.NGUOIDAT,
      ngaydat: inforDonHang?.NGAYDAT,
      ngayduyet: inforDonHang?.NGAYDUYET,
      trangThaiDatHang: inforDonHang?.TRANGTHAIDATHANG,
      ...groupedDataArrayFormik.reduce((acc, group) => {
        acc[`nguoigiao-${group.id}`] = group.nguoigiao
        acc[`ngaygiao-${group.id}`] = group.ngaygiao
        return acc
      }, {}),
    },
  })

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size="small"
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchTable(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchTable(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearchTable = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const handleChangeInput = (e, record, key) => {
    const findItemIdx = dataTable.findIndex((item) => item.idthuoc === record.idthuoc)
    if (findItemIdx !== -1) {
      const cloneListHang = cloneDeep(dataTable)
      cloneListHang[findItemIdx][key] = e.target.value
      setDataTable(cloneListHang)
    }
  }

  const columnsHangHoa = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 60,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã hàng hóa',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 120,
      ...getColumnSearchProps('mathuoc'),
    },
    {
      title: 'Tên hàng hóa',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
      ...getColumnSearchProps('tenbietduoc'),
    },
    {
      title: 'SL đặt',
      dataIndex: 'soluongdat',
      key: 'soluongdat',
      align: 'center',
      width: 70,
    },
    {
      title: 'SL duyệt',
      dataIndex: 'soluongduyet',
      key: 'soluongduyet',
      align: 'center',
      width: 70,
    },
    {
      title: 'SL giao',
      dataIndex: 'soluonggiao',
      key: 'soluonggiao',
      align: 'center',
      width: 70,
    },
    {
      title: 'SL nhận',
      dataIndex: 'soluongnhan',
      key: 'soluongnhan',
      align: 'center',
      width: 70,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 150,
    },
    // {
    //   title: 'Nhà cung cấp',
    //   dataIndex: '',
    //   key: '',
    //   width: 250,
    // },
  ]

  const groupedDataArray = useMemo(() => {
    const groupedData = dataTable.reduce((acc, item) => {
      const key = item.idncc || item.idkhotong || 'unknown'
      const name = item.nhacungcap || item.khotong || 'Chưa duyệt'
      if (!acc[key]) {
        acc[key] = {
          name: name,
          items: [],
        }
      }
      acc[key].items.push(item)
      return acc
    }, {})

    return Object.entries(groupedData).map(([id, group]) => ({
      key: id,
      id,
      name: group.name,
      items: group.items,
    }))
  }, [dataTable])

  const columnsGroup = [
    {
      title: 'Tên nhà cung cấp/Kho tổng',
      dataIndex: 'name',
      key: 'name',
    },
  ]

  useEffect(() => {
    if (inforDonHang) {
      setDataTable(
        inforDonHang.DanhSachHangHoa.map((item) => ({
          ...item,
          idthuoc: item.idhang,
        })),
      )
    }
  }, [inforDonHang?.IDDATHANG])

  useEffect(() => {
    fetchBranch()
  }, [])

  const handleCancel = () => {
    formik.resetForm()
    setOpenEdit(false)
    dispatch({ type: DISPATCH_CLEAR_INFO_DONHANG })
  }

  const onExpandedRowsChange = (expandedRows) => {
    setExpandedRowKeys(expandedRows)
  }

  const exportToExcel = () => {
    // Prepare data for Excel
    const excelData = []

    groupedDataArray.forEach((group) => {
      // Add supplier/warehouse row
      excelData.push({
        'Tên nhà cung cấp/Kho tổng': group.name,
      })

      // Add header row for items
      excelData.push({
        STT: 'STT',
        'Mã hàng hóa': 'Mã hàng hóa',
        'Tên hàng hóa': 'Tên hàng hóa',
        'SL đặt': 'SL đặt',
        'SL duyệt': 'SL duyệt',
        'SL giao': 'SL giao',
        'SL nhận': 'SL nhận',
        'Ghi chú': 'Ghi chú',
      })

      // Add item rows
      group.items.forEach((item, index) => {
        excelData.push({
          STT: index + 1,
          'Mã hàng hóa': item.mathuoc,
          'Tên hàng hóa': item.tenbietduoc,
          'SL đặt': item.soluongdat,
          'SL duyệt': item.soluongduyet,
          'SL giao': item.soluonggiao,
          'SL nhận': item.soluongnhan,
          'Ghi chú': item.ghichu,
        })
      })

      // Add an empty row for separation
      excelData.push({})
    })

    // Create a new workbook and add the data
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(excelData, { skipHeader: true })

    // Set column widths
    const colWidths = [
      { wch: 5 }, // STT
      { wch: 15 }, // Mã hàng hóa
      { wch: 30 }, // Tên hàng hóa
      { wch: 10 }, // SL đặt
      { wch: 10 }, // SL duyệt
      { wch: 10 }, // SL giao
      { wch: 10 }, // SL nhận
      { wch: 20 }, // Ghi chú
    ]
    ws['!cols'] = colWidths

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    // Generate Excel file and trigger download
    XLSX.writeFile(wb, `${inforDonHang.TENDONHANG}.xlsx`)
  }

  useEffect(() => {
    // Set all rows to be expanded by default
    const allKeys = groupedDataArray.map((item) => item.id)
    setExpandedRowKeys(allKeys)
  }, [groupedDataArray])


  return (
    <Modal
      okText="Xác nhận"
      cancelText="Huỷ"
      className="text-center"
      title={'Chi tiết đơn hàng'}
      onOk={formik.handleSubmit}
      open={openEdit}
      onCancel={handleCancel}
      width={1200}
      footer={null}
      okButtonProps={{ hidden: true }}
      style={{ top: 20 }}
    >
      <form className="text-start flex flex-col gap-2">
        <div className="flex gap-4">
          <div className="w-1/2 flex gap-4">
            <div className="w-2/3">
              <label className="font-semibold  text-base">Người đặt</label>
              <Input
                size="small"
                value={formik.values.nguoidat}
                className="w-full cursor-not-allowed bg-gray-100"
                name="nguoidat"
                readOnly
              />
            </div>
            <div className="w-1/3">
              <label className="font-semibold  text-base">Ngày đặt</label>
              <DatePicker
                size="small"
                name="ngaydat"
                format={dateFormat}
                value={dayjs(formik.values.ngaydat)}
                className="w-full"
                disabled
              />
            </div>
          </div>
          <div className="w-1/2 flex gap-4">
            <div className="w-1/2">
              <label className="font-semibold  text-base">Chi nhánh đặt</label>
              <Input
                size="small"
                value={formik.values.chinhanhdat}
                className="w-full cursor-not-allowed bg-gray-100"
                name="chinhanhdat"
                readOnly
              />
            </div>
            <div className="w-1/2">
              <label className="font-semibold  text-base">Bộ phận duyệt</label>
              <Input
                size="small"
                value={formik.values.chinhanhnhan}
                name="chinhanhnhan"
                className="w-full cursor-not-allowed bg-gray-100"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-1/2 flex gap-4">
            <div className="w-1/2">
              <label className="font-semibold  text-base">Tên đơn hàng</label>
              <Input
                size="small"
                value={formik.values.tendonhang}
                name="tendonhang"
                className="w-full cursor-not-allowed bg-gray-100"
                readOnly
              />
            </div>
            <div className="w-1/2">
              <label className="font-semibold  text-base">Mã đặt hàng</label>
              <Input
                size="small"
                value={inforDonHang?.MADATHANG}
                className="w-full cursor-not-allowed bg-gray-100 font-semibold"
                readOnly
              />
            </div>
          </div>
          <div className="w-1/2">
            <label className="font-semibold  text-base">Ghi chú</label>
            <Input
              size="small"
              value={formik.values.ghichudathang}
              name="ghichudathang"
              className="w-full cursor-not-allowed bg-gray-100"
              readOnly
            />
          </div>
        </div>
        <div className="flex gap-4">
          
          <div className="w-1/2 flex gap-4">
            <div className="w-2/3">
              <label className="font-semibold  text-base">Người duyệt</label>
              <Input
                size="small"
                value={formik.values.nguoiduyet}
                name="nguoiduyet"
                className="w-full cursor-not-allowed bg-gray-100"
                readOnly
              />
            </div>
            <div className="w-1/3">
              <label className="font-semibold  text-base">Ngày duyệt</label>
              <DatePicker
                size="small"
                name="ngayduyet"
                format={dateFormat}
                value={formik.values.ngayduyet ? dayjs(formik.values.ngayduyet) : null}
                className="w-full"
                disabled
                placeholder=""
              />
            </div>
          </div>
        </div>
      </form>
      <Divider />
      <div className="w-full text-left mb-2">
        <Button
          onClick={exportToExcel}
          type="text"
          size="middle"
          className="text-green-700 p-1"
          icon={<ImportExportIcon />}
        >
          Xuất Excel
        </Button>
      </div>
      <div className="overflow-auto h-[500px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                cellFontSize: 12,
              },
            },
          }}
        >
          <Table
            scroll={{ y: 450 }}
            bordered
            pagination={false}
            columns={columnsGroup}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <Table
                  bordered
                    columns={columnsHangHoa}
                    dataSource={record.items}
                    pagination={false}
                    footer={() =>
                      record.id === 'unknown' ? null : (
                        <div className="flex gap-4">
                          <div className="w-96 flex">
                            <label className="font-semibold w-32  text-sm">Người giao</label>
                            <Input
                              size="small"
                              value={formik.values[`nguoigiao-${record.id}`]}
                              type="text"
                              name={`nguoigiao-${record.id}`}
                              className="w-full bg-gray-100"
                              readOnly
                            />
                          </div>
                          <div className="w-60 flex">
                            <label className="font-semibold w-32 text-sm">Ngày giao</label>
                            <DatePicker
                              size="small"
                              name={`ngaygiao-${record.id}`}
                              format={dateFormat}
                              value={
                                formik.values[`ngaygiao-${record.id}`]
                                  ? dayjs(formik.values[`ngaygiao-${record.id}`])
                                  : null
                              }
                              className="w-full text-gray-100"
                              placeholder="Chọn ngày giao"
                              disabled
                            />
                          </div>
                        </div>
                      )
                    }
                  />
                )
              },
              expandedRowKeys,
              onExpandedRowsChange,
            }}
            dataSource={groupedDataArray}
          />
        </ConfigProvider>
      </div>
    </Modal>
  )
}

ModalEdit.propTypes = {}

export default ModalEdit
