import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createNhanSuCoShema } from '../../../schemas/createNhanSuCoShema'
import { createNhanSuCo } from '../../../store/actions/suCoAction'
const CreateNhan = ({ openAdd, setOpenAdd }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { groupNhan } = useSelector((state) => state.suCoReducer)
  const dispatch = useDispatch()
  const handleCancel = () => {
    setOpenAdd(false)
  }
  const handleSubmit = (value) => {
  dispatch(createNhanSuCo(value,onReset))
  }
  const onChangeSelected = (value) => {
    formik.setFieldValue('nhom', value)
  }
  const onReset = () => {
    formik.resetForm()
    handleCancel()
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tennhan: '',
      mota: '',
      nhom: null,
      thoigianxuly: '',
      ghichu: '',
      nguoitao: infoUser?.idnv,
    },
    validationSchema: createNhanSuCoShema,
    onSubmit: (value) => handleSubmit(value),
  })
  return (
    <Modal
      onOk={formik.handleSubmit}
      okText="Tạo"
      cancelText="Huỷ bỏ"
      open={openAdd}
      onCancel={handleCancel}
      title={<p className="font-semibold text-xl w-full text-center text-blue-500">TẠO NHÃN</p>}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Tên nhãn <span className="text-red-500">(*)</span>
          </label>
          <Input
            name="tennhan"
            onChange={formik.handleChange}
            status={formik.errors.tennhan && formik.touched.tennhan ? 'error' : null}
            value={formik.values.tennhan}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            {' '}
            Thời gian xử lý <span className="text-red-500">(*)</span>
          </label>
          <Input
            name="thoigianxuly"
            onChange={formik.handleChange}
            status={formik.errors.thoigianxuly && formik.touched.tennhan ? 'error' : null}
            value={formik.values.thoigianxuly}
          />
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">
            Nhóm người dùng <span className="text-red-500">(*)</span>
          </label>
          <Select
            placeholder="chọn nhóm người dùng"
            onChange={onChangeSelected}
            value={formik.values.nhom}
            options={groupNhan?.map((item) => ({
              label: item.tennhom,
              value: item.idnhom,
            }))}
            className="w-full"
            name="nhom"
            status={formik.errors.nhom && formik.touched.nhom ? 'error' : null}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">Mô tả</label>
          <Input name="mota" onChange={formik.handleChange} value={formik.values.mota} />
          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Ghi chú</label>
            <Input name="ghichu" onChange={formik.handleChange} value={formik.values.ghichu} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CreateNhan
