import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  loadingLogin: false,
  modalMoCa: false,
  modalCheckIn: false,
  modalAddKhamDoan: false,
  modalAddCtyKhamDoan: false,
  modalEditCtyKhamDoan: false,
  modalImportKhamDoan: false,
  modalEditBNKhamDoan: false,
  isLoadingPage: false,
  modalCreateCustomer: false,
  version: false,
  isLoadingTable: false,
}

const modalReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.OPEN_LOADING:
        draft.loadingLogin = true
        break
      case typeAction.CLOSE_LOADING:
        draft.loadingLogin = false
        break
      case typeAction.OPEN_MODAL_CREATE_CUSTOMER:
        draft.modalCreateCustomer = true
        break
      case typeAction.CLOSE_MODAL_CREATE_CUSTOMER:
        draft.modalCreateCustomer = false
        break
      case typeAction.OPEN_MODAL_CHECK_IN:
        draft.modalCheckIn = true
        break
      case typeAction.OPEN_LOADING_TABLE:
        draft.isLoadingTable = true
        break
      case typeAction.CLOSE_LOADING_TABLE:
        draft.isLoadingTable = false
        break
      case typeAction.OPEN_MODAL_VERSION:
        draft.version = true
        break
      case typeAction.CLOSE_MODAL_VERSION:
        draft.version = false
        break
      case typeAction.CLOSE_MODAL_CHECK_IN:
        draft.modalCheckIn = false
        break
      case typeAction.OPEN_LOADING_PAGE:
        draft.isLoadingPage = true
        break
      case typeAction.CLOSE_LOADING_PAGE:
        draft.isLoadingPage = false
        break
      case typeAction.OPEN_MODAL_MOCA:
        draft.modalMoCa = true
        break
      case typeAction.CLOSE_MODAL_MOCA:
        draft.modalMoCa = false
        break
      case typeAction.OPEN_ADD_KHAM_DOAN:
        draft.modalAddKhamDoan = true
        break
      case typeAction.CLOSE_ADD_KHAM_DOAN:
        draft.modalAddKhamDoan = false
        break
      case typeAction.OPEN_ADD_CTY_KHAM_DOAN:
        draft.modalAddCtyKhamDoan = true
        break
      case typeAction.CLOSE_ADD_CTY_KHAM_DOAN:
        draft.modalAddCtyKhamDoan = false
        break
      case typeAction.OPEN_MODAL_EDIT_CTY_KHAM_DOAN:
        draft.modalEditCtyKhamDoan = true
        break
      case typeAction.CLOSE_MODAL_EDIT_CTY_KHAM_DOAN:
        draft.modalEditCtyKhamDoan = false
        break
      case typeAction.OPEN_MODAL_IMPORT_KHAM_DOAN:
        draft.modalImportKhamDoan = true
        break
      case typeAction.CLOSE_MODAL_IMPORT_KHAM_DOAN:
        draft.modalImportKhamDoan = false
        break
      case typeAction.OPEN_MODAL_EDIT_BN_KHAM_DOAN:
        draft.modalEditBNKhamDoan = true
        break
      case typeAction.CLOSE_MODAL_EDIT_BN_KHAM_DOAN:
        draft.modalEditBNKhamDoan = false
        break
      default:
        return state
    }
  })
}

export default modalReducer
