import React, { useCallback, useState } from 'react'
import { Input, Modal, Upload, Image, notification, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import { useFormik } from 'formik'
import { priority } from './Priority'
import _ from 'lodash'
import { CreateBaoHongShema } from '../../../../schemas/CreateBaoHongShema'
import { createBaoHong, getAllNhanVienBaoHong, getInfoNhanByDanhMuc } from '../../../../store/actions/suCoAction'
const { TextArea } = Input
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
const CreateSuCo = ({ openAdd, setOpenAdd, fetchData, branch }) => {
  const { thucHien, AllDanhMuc,nhanVien } = useSelector((state) => state.suCoReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [nhanVienThucHien, setNhanVien] = useState([])
  const [infoNhan, setInfoNhan] = useState(null)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [file, setFiles] = useState([])
  const [api, contextHolder] = notification.useNotification()
  const dispatch = useDispatch()
  const handleCancel = () => {
    setOpenAdd(false)
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      dispatch(getAllNhanVienBaoHong(keyword))
    }, 300),
    [],
  )
  const handleSubmit = (values) => {
    if (file.length !== 0) {
      const listImage = file.map((img) => img.originFileObj)
      values.files = listImage
    }
    const cleanedValues = Object.fromEntries(
      Object.entries(values).filter(([key, value]) => value !== null && value !== undefined),
    )
    const formData = new FormData()
    for (let key in cleanedValues) {
      // console.log(key)
      if (key === 'files') {
        if (cleanedValues[key] && cleanedValues[key].length > 0) {
          cleanedValues[key].forEach((file, index) => {
            formData.append(key, file)
          })
        }
      } else {
        formData.append(key, cleanedValues[key])
      }
    }

    dispatch(createBaoHong(formData, nhanVienThucHien, infoUser?.idnv, fetchData, onReset))
  }
  const onChangeDanhMuc = (id) => {
    formik.setFieldValue('IDDANHMUCNHAN', id)
    dispatch(getInfoNhanByDanhMuc(id, setInfoNhan, formik))
  }
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Upload file',
      description: 'Chỉ chấp nhận file ảnh!',
    })
  }
  const onChangeSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const onReset = () => {
    formik.resetForm()
    handleCancel()
    setPreviewImage('')
    setFiles([])
    setInfoNhan(null)
    setNhanVien([])
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      TENBAOHONG: '',
      NOIDUNGBAOHONG: null,
      IDNHAN: null,
      CHINHANHBAOHONG: null,
      NGUOIBAOHONG: infoUser?.idnv,
      DONVINHAN: null,
      GHICHU: null,
      files: null,
      MUCDOSUCO: 2,
      IDDANHMUCNHAN: null,
    },
    validationSchema: CreateBaoHongShema,
    onSubmit: (value) => handleSubmit(value),
  })
  const handleChangeImge = ({ fileList }) => {
    setFiles(fileList)
  }
  const handleSelectedThucHien = (value) => {
    setNhanVien(value)
  }
  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/')
    if (!isImage) {
      openNotificationWithIcon('error')
      return Upload.LIST_IGNORE // Ignore non-image files
    }
    return false // Prevent the default upload behavior
  }
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
  }
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  )

  return (
    <>
      {contextHolder}
      <Modal
        width="55%"
        onOk={formik.handleSubmit}
        okText="Tạo"
        cancelText="Huỷ bỏ"
        open={openAdd}
        onCancel={handleCancel}
        title={<p className="font-semibold text-xl w-full text-center text-blue-500">Tạo sự cố</p>}
      >
        <div className="flex gap-5">
          <div className="flex flex-col gap-2 w-2/3">
            <div className="flex flex-col gap-1">
              <label className="font-medium text-gray-400">
              <span className="text-red-500">(*)</span>Tên báo hỏng:
              </label>
              <Input
                name="TENBAOHONG"
                onChange={formik.handleChange}
                status={formik.errors.TENBAOHONG && formik.touched.TENBAOHONG ? 'error' : null}
                value={formik.values.TENBAOHONG}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">
                {' '}
                <span className="text-red-500">(*)</span> Loại sự cố:
              </label>
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={AllDanhMuc?.map((i) => ({
                  label: i.tendanhmuc,
                  value: i.idnhandm,
                }))}
                onChange={onChangeDanhMuc}
                status={
                  formik.errors.IDDANHMUCNHAN && formik.touched.IDDANHMUCNHAN ? 'error' : null
                }
                // value={formik.values.thoigianxuly}
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">Nội dung báo hỏng:</label>
              <TextArea
                showCount
                // maxLength={100}
                onChange={formik.handleChange}
                value={formik.values.NOIDUNGBAOHONG}
                name="NOIDUNGBAOHONG"
                placeholder="Nhập nội dung chi tiết"
                style={{
                  height: 150,
                  resize: 'none',
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">Người thực hiện:</label>
              <Select
                onSearch={debounceDropDown}
                value={nhanVienThucHien}
                mode="multiple"
                filterOption={false}
                placeholder="Chọn người thực hiện"
                onChange={handleSelectedThucHien}
                style={{
                  width: '100%',
                }}
                options={nhanVien?.map((item) => ({
                  label: item.tennv,
                  value: item.id,
                }))}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 w-1/3">
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">Mức độ sự cố</label>
              <Select
                value={formik.values.MUCDOSUCO}
                onChange={onChangeSelected('MUCDOSUCO')}
                className="w-full"
                options={priority}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium"><span className="text-red-500">(*)</span> Chi nhánh:</label>
              <Select
                placeholder="Chọn chi nhánh"
                className="w-full"
                onChange={(value) => formik.setFieldValue('CHINHANHBAOHONG', value)}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                status={
                  formik.errors.CHINHANHBAOHONG && formik.touched.CHINHANHBAOHONG ? 'error' : null
                }
                options={branch?.map(({ idChiNhanh, tenChiNhanh }) => ({
                  label: tenChiNhanh,
                  value: idChiNhanh,
                }))}
                value={formik.values.CHINHANHBAOHONG}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium"> Đơn vị nhận:</label>
              <Input
                variant="filled"
                readOnly
                name="thoigianxuly"
                onChange={formik.handleChange}
                // status={formik.errors.thoigianxuly && formik.touched.tennhan ? 'error' : null}
                value={infoNhan?.nhom}
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-gray-400 font-medium">Loại nhãn sự cố:</label>
              <Input
                variant="filled"
                readOnly
                name="thoigianxuly"
                onChange={formik.handleChange}
                value={infoNhan?.tennhan}
                // status={formik.errors.thoigianxuly && formik.touched.tennhan ? 'error' : null}
                // value={formik.values.thoigianxuly}
              />
            </div>
            <label className="text-gray-400 font-medium">Hình ảnh kèm theo</label>
            <div className="flex gap-1 ">
              {previewImage && (
                <Image
                  wrapperStyle={{
                    display: 'none',
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}
              <Upload
                name="avatar"
                listType="picture-card"
                beforeUpload={beforeUpload}
                onChange={handleChangeImge}
                onPreview={handlePreview}
                fileList={file}
              >
                {file.length > 5 ? null : uploadButton}
              </Upload>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CreateSuCo
