import { call, put, takeLatest, select } from 'redux-saga/effects'
import { dichVuService } from '../../services/dichVu/dichVuService'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
export function* dichVuSaga() {
  //GET ALL LIST DICH VU
  yield takeLatest(typeAction.GET_ALL_LIST_DICH_VU, function* listDichVu({ type }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => dichVuService.getAllListDichVu())
      yield put({
        type: typeAction.DISPATCH_LIST_DICH_VU,
        payload: result.data.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  // GET LIST DICH VU THEO NHOM DV
  yield takeLatest(typeAction.GET_LIST_DICH_VU, function* listDichVu({ type, idNhomDv, idct }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => dichVuService.getListDichVu(idNhomDv, idct))
      yield put({
        type: typeAction.DISPATCH_LIST_DICH_VU,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  // GET LIST GROUPS DICH VU
  yield takeLatest(typeAction.GET_LIST_GROUPS_DICH_VU, function* listGroupsDichVu({ type }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => dichVuService.getListGroupsDichVu())
      yield put({
        type: typeAction.DISPATCH_GROUPS_DICH_VU,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //CHI TIET DICH VU
  yield takeLatest(typeAction.GET_INFO_DICH_VU, function* infoXetNgiem({ type, iddv }) {
    try {
      const result = yield call(() => dichVuService.fetchInfoDV(iddv))
      yield put({
        type: typeAction.DISPATCH_INFO_DICH_VU,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })

  // SEARCH DV BY KEYWORD
  yield takeLatest(
    typeAction.SEARCH_DV_BY_KEYWORD,
    function* listDVByKeyword({ type, idnhomdv, idct, keyword }) {
      try {
        const result = yield call(() => dichVuService.searchByKeyword(idnhomdv, idct, keyword))
        yield put({
          type: typeAction.DISPATCH_LIST_DICH_VU,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )

  // ADD NEW DICH VU
  yield takeLatest(
    typeAction.POST_NEW_DICH_VU,
    function* addNewDichVu({ type, form, listCdGK, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        const { data: infoDv } = yield call(() =>
          dichVuService.addDichVu({
            // iddv: 0,
            maDichVu: form?.madv,
            tenDichVu: form?.tendv,
            tenVietTat: form?.tenviettat,
            donGia: form?.dongia,
            tienGuiMau: form?.tienguimau,
            tienChietKhau: form?.tienchietkhau,
            nguoiTao: infoUser?.tenNV,
            // ngayTao: form?.dongia,
            idct: form?.idct,
            idNhomDV: form?.idnhomdv,
            ghiChu: form?.ghichu,
            // idChuyenKhoa: form?.dongia,
            donVi: form?.dvt,
            // pkMacDinh: form?.dongia,
            // vatTuTieuHao: form?.dongia,
            // loaiChiDinh: form?.dongia,
            tienGiaCong: form?.tiengiacong,
            tienCongVanChuyen: form?.tienvanchuyen,
            giaVon: form?.tienvon,
            // tienCong: form?.dongia,
            // solanvltl: form?.dongia,
          }),
        )
        yield call(
          () =>
            listCdGK?.length &&
            dichVuService.putListChiTietGoiKham(
              infoDv?.iddv,
              listCdGK?.map((item) => ({
                // idgkct: 0,
                idgk: infoDv?.iddv,
                iddv: item?.iddv,
                madichvu: item?.madv,
                tendichvu: item?.tendv,
                dvt: item?.dvt,
                dongia: item?.dongia,
                loaichidinh: item?.loaichidinh, // 1 = dịch vụ, 2 = xét nghiệm, 3 = cdha
              })),
            ),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // DELETE DANH MỤC XÉT NGHIÊM
  yield takeLatest(typeAction.DELETE_DICH_VU, function* deleteDVXetNghiem({ type, iddv, onLoad }) {
    try {
      yield call(() => dichVuService.deleteDichVu(iddv))
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Xóa thất bại!',
      })
    }
  })
  // UPDATE DANH MỤC XÉT NGHIÊM
  yield takeLatest(
    typeAction.UPDATE_DICH_VU,
    function* updateDVXetNghiem({ type, form, listCdGK, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        yield call(() =>
          dichVuService.editDichVu({
            iddv: form?.iddv,
            maDichVu: form?.madv,
            tenDichVu: form?.tendv,
            tenVietTat: form?.tenviettat,
            donGia: form?.dongia,
            tienGuiMau: form?.tienguimau,
            tienChietKhau: form?.tienchietkhau,
            nguoisua: infoUser?.idnv,
            // ngayTao: form?.dongia,
            idct: form?.idct,
            idNhomDV: form?.idnhomdv,
            ghiChu: form?.ghichu,
            // idChuyenKhoa: form?.dongia,
            donVi: form?.dvt,
            // pkMacDinh: form?.dongia,
            // vatTuTieuHao: form?.dongia,
            // loaiChiDinh: form?.dongia,
            tienGiaCong: form?.tiengiacong,
            tienCongVanChuyen: form?.tienvanchuyen,
            giaVon: form?.tienvon,
            // tienCong: form?.dongia,
            // solanvltl: form?.dongia,
          }),
        )
        yield call(
          () =>
            listCdGK?.length &&
            dichVuService.putListChiTietGoiKham(
              form?.iddv,
              listCdGK?.map((item) => ({
                // idgkct: 0,
                idgk: form?.iddv,
                iddv: item?.iddv,
                madichvu: item?.madv,
                tendichvu: item?.tendv,
                dvt: item?.dvt,
                dongia: item?.dongia,
                loaichidinh: item?.loaichidinh, // 1 = dịch vụ, 2 = xét nghiệm, 3 = cdha
              })),
            ),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // UPDATE LIST CHI DINH GOI KHAM, INFO GOI KHAM
  yield takeLatest(
    typeAction.PUT_LIST_CHIDINH_GOIKHAM,
    function* putListChiTietCuaGoiKhamSaga({ type, infoGK, listCdGK, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          dichVuService.editDichVu({
            iddv: infoGK?.iddv,
            maDichVu: infoGK?.maDichVu,
            tenDichVu: infoGK?.tenDichVu,
            tenVietTat: infoGK?.tenVietTat,
            ghichu: infoGK?.ghiChu,
            idNhomDV: infoGK?.idNhomDV,
            donGia: infoGK?.donGia ?? 0,
            tienGuiMau: infoGK?.tienGuiMau ?? 0,
            tienChietKhau: infoGK?.tienChietKhau ?? 0,
            tienGiaCong: infoGK?.tienGiaCong ?? 0,
            tienCongVanChuyen: infoGK?.tienCongVanChuyen ?? 0,
            giaVon: infoGK?.giaVon ?? 0,
            idct: infoGK?.idct,
            donVi: infoGK?.donVi,
            idChuyenKhoa: infoGK?.idChuyenKhoa,
          }),
        )
        yield call(() =>
          dichVuService.putListChiTietGoiKham(
            infoGK?.iddv,
            listCdGK?.map((item) => ({
              // idgkct: 0,
              idgk: infoGK?.iddv,
              iddv: item?.iddv,
              madichvu: item?.madv,
              tendichvu: item?.tendv,
              dvt: item?.dvt,
              dongia: item?.dongia,
              loaichidinh: item?.loaichidinh, // 1 = dịch vụ, 2 = xét nghiệm, 3 = cdha
            })),
          ),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // UPDATE SỬ DỤNG LIST DỊCH VỤ CHUYÊN KHOA
  yield takeLatest(
    typeAction.PUT_SUDUNG_LIST_DICHVU_CHUYENKHOA,
    function* putSuDungListDichVuChuyenKhoaSaga({
      type,
      idck,
      sudung,
      listIdDv,
      getListDichVuChuyenKhoa,
    }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => dichVuService.putSuDungListDichVuChuyenKhoa(idck, sudung, listIdDv))
        yield call(() => getListDichVuChuyenKhoa(idck))
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
