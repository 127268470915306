import Button from '@mui/material/Button'
import {
  Button as AntButton,
  ConfigProvider,
  DatePicker,
  Input,
  Radio,
  Select,
  Space,
  Table,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import * as Yup from 'yup'
import { FileTextOutlined, PrinterOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import dayjs from 'dayjs'
import moment from 'moment'

import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { https } from '../../../services/apiService'
import { branchService } from '../../../services/branch/branchService'
import { xetNghiemService } from '../../../services/xetNghiem/xetNghiemService'
import { generatePhieuSieuAm } from '../../../utils/report/san/phieuSieuAm'
import Index from './Index/Index'
import InfoBN from './InfoBN/InfoBN'
import { infoBNXetNghiem } from '../../../store/actions/xetNghiemAction'
import { addBNMonitor, getAllBNCho, infoBNMonitor } from '../../../store/actions/monitorAction'
import { monitorService } from '../../../services/monitor/monitorService'
import { generateMonitorTimThai } from '../../../utils/report/san/monitorTimThai'
import Highlighter from 'react-highlight-words'
import 'moment/locale/vi'
moment.locale('vi')
const today = moment()
const formatDate = 'DD/MM/YYYY'

const validationSchema = Yup.object({
  nhiptimthaicoban: Yup.string().required(),
  daodongnoitai: Yup.string().required(),
})

const listNhipGiam = [
  { id: 1, name: 'Không' },
  { id: 2, name: 'Có, dạng nhịp giảm sớm' },
  { id: 3, name: 'Có dạng bất định' },
  { id: 4, name: 'Có, dạng nhịp giảm muộn' },
  { id: 5, name: 'Có, dạng nhịp giảm kèo dài &gt; 2 phút' },
]
const Mornitor = () => {
  const formatDateAPI = 'YYYY-MM-DD'
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { ListMonitor } = useSelector((state) => state.monitorReducer)
  const { detailXN } = useSelector((state) => state.xetNghiemReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [dateForm, setDateForm] = useState(today.format(formatDateAPI))
  const [dateTo, setDateTo] = useState(today.format(formatDateAPI))
  const [branchs, setBranch] = useState([])
  const [branchPedding, setBranchPendding] = useState(ID_BRANCH)
  const [branchDone, setBranchDone] = useState(ID_BRANCH)
  const [BNDone, setBNDone] = useState([])
  const [allKetLuan, setAllKetLuan] = useState([])
  const [infoBN, setInfoBN] = useState(null)
  const [activeRow, setActiveRow] = useState(null)
  const [isDone, setIsDone] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const dispatch = useDispatch()

  const onLoad = () => {
    dispatch(getAllBNCho(branchPedding))
    setActiveRow(null)
    setInfoBN(null)
    formik.resetForm()
    fetchBNDoneXn()
  }
  const handleSumit = (value) => {
    dispatch(
      addBNMonitor(
        {
          ...value,
          idbnttc: activeRow.idbnttc,
          idbncdha: activeRow.idbncdha,
          bschidinh: infoBN.bskham,
          bsthuchien: infoUser.tenNV,
        },
        onLoad,
      ),
    )
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idkl: 1,
      nhiptimthaicoban: '',
      daodongnoitai: '',
      nhiptang: 'Không',
      nhipgiam: 'Không',
      cogotucung: 'Không',
      ghiChu: '',
      bsthuchien: '',
    },
    onSubmit: (value) => handleSumit(value),
    validationSchema: validationSchema,
  })
  //-------- lấy danh sách công ty -------//
  const fetchBranchByCompany = async () => {
    try {
      const result = await branchService.getListBranch(ID_COMPANY)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchBNDoneXn = async () => {
    try {
      const { data } = await https.get(
        `BenhNhanMonitor/BenhNhanDaThucHienMonitor?idcn=${branchDone}&fromdate=${dateForm}&toDate=${dateTo}`,
      )
      setBNDone(data)
    } catch (error) {
      console.log(error)
    }
  }

  const onClickBNDone = async (record) => {
    try {
      setIsDone(true)
      const bnttcData = await https.get(`BenhNhanTTC/GetByIDBNTTC?id=${record.idbnttc}`)
      const { data } = await https.get(
        `BenhNhanMonitor/GetBenhNhanMonitorByIDBNMT?IDBNMT=${record.idbnmt}`,
      )
      formik.setFieldValue('nhiptimthaicoban', data.nhiptimthaicoban)
      formik.setFieldValue('idkl', data.idkl)
      formik.setFieldValue('daodongnoitai', data.daodongnoitai)
      formik.setFieldValue('nhiptang', data.nhiptang)
      formik.setFieldValue('nhipgiam', data.nhipgiam)
      formik.setFieldValue('cogotucung', data.cogotucung)
      formik.setFieldValue('bsthuchien', data.bsthuchien)
      formik.setFieldValue('ngaythuchien', data.ngaythuchien)
      // setInfoBN(data)
      setInfoBN(bnttcData.data)
    } catch (error) {
      console.log(error)
    }
  }

  // --------- onClick bệnh nhân chờ khám --------//
  const onClickGetInfoBN = async (record) => {
    formik.resetForm()
    setIsDone(false)
    setActiveRow(record)
    try {
      const { data } = await https.get(`BenhNhanTTC/GetByIDBNTTC?id=${record.idbnttc}`)
      setInfoBN(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getAllKetLuan = async () => {
    try {
      const { data } = await https.get('KetLuanMonitor/GetAllKetLuan')
      setAllKetLuan(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchBranchByCompany()
    dispatch(getAllBNCho(ID_BRANCH))
    getAllKetLuan()
    fetchBNDoneXn()
  }, [])

  const fetchBNPendingMonitor = async () => {
    dispatch(getAllBNCho(branchPedding))
  }

  const handlePrint = () => {
    const bnMonitor = {
      ...formik.values,
      ketluan:
        formik.values.idkl === 1
          ? 'Nhóm 1: Biểu đồ tim thai không dấu đe dọa sức khỏe thai'
          : formik.values.idkl === 2
            ? 'Nhóm 2: Biểu đồ tim thai nghi ngờ, cần theo dõi sát'
            : formik.values.idkl === 3
              ? 'Nhóm 3: Sức khỏe thai không tốt, cần can thiệp tích cực'
              : '',
    }
    generateMonitorTimThai(infoBN, bnMonitor, PkDangNhap)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <AntButton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </AntButton>
          <AntButton
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </AntButton>
          <AntButton
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </AntButton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  return (
    <>
      <div className="h-full p-2 bg-[#EFEFEF]">
        <div className="bg-white rounded-2xl border h-full">
          <div className="p-3 h-full flex gap-2">
            <div className="w-1/4 border h-full rounded-lg">
              <div className="border-b p-2 h-1/2">
                <h2 className="font-semibold  text-lg text-gray-500">Chờ thực hiện</h2>
                <div className="">
                  <div className=" flex gap-2">
                    <Select
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      value={branchPedding}
                      onChange={(value) => {
                        setBranchPendding(value)
                      }}
                      showSearch
                      options={branchs.map((items) => ({
                        label: items.tenChiNhanh,
                        value: items.idChiNhanh,
                      }))}
                      className="w-full"
                    />
                    <Button
                      onClick={fetchBNPendingMonitor}
                      // className="w-1/4"
                      variant="text"
                      size="small"
                      // endIcon={<CachedIcon />}
                    >
                      <CachedIcon />
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <ConfigProvider theme={{ token: { padding: 5 } }}>
                    <Table
                      scroll={{
                        y: 290,
                      }}
                      onRow={(record) => ({
                        onClick: () => onClickGetInfoBN(record),
                      })}
                      pagination={false}
                      dataSource={ListMonitor}
                      columns={[
                        {
                          title: 'Mã BN',
                          dataIndex: 'mabenhnhan',
                          key: 'mabenhnhan',
                          width: '30%',
                        },
                        {
                          title: 'Tên bệnh nhân',
                          dataIndex: 'tenbenhnhan',
                          key: 'tenbenhnhan',
                          width: '70%',
                        },
                      ]}
                    />
                  </ConfigProvider>
                </div>
              </div>
              <div className=" p-2 h-1/2">
                <div className="flex justify-between">
                  <h2 className="font-semibold text-lg text-gray-500">Đã thực hiện</h2>
                </div>

                <div className="flex items-center justify-center">
                  <DatePicker
                    allowClear={false}
                    value={dayjs(dateForm)}
                    format={formatDate}
                    onChange={(date, dateString) => {
                      setDateForm(moment(dateString, formatDate).format(formatDateAPI))
                    }}
                    className="w-2/5"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 w-1/5"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                  </svg>

                  <DatePicker
                    onChange={(date, dateString) => {
                      setDateTo(moment(dateString, formatDate).format(formatDateAPI))
                    }}
                    value={dayjs(dateTo)}
                    format={formatDate}
                    allowClear={false}
                    className="w-2/5"
                  />
                </div>
                <div className="mt-2">
                  <div className=" flex gap-2">
                    <Select
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      onChange={(value) => {
                        setBranchDone(value)
                      }}
                      value={branchDone}
                      showSearch
                      options={branchs.map((items) => ({
                        label: items.tenChiNhanh,
                        value: items.idChiNhanh,
                      }))}
                      className="w-full"
                    />
                    <Button
                      onClick={fetchBNDoneXn}
                      // className="w-1/4"
                      variant="text"
                      size="small"
                      // endIcon={<CachedIcon />}
                    >
                      <CachedIcon />
                    </Button>
                  </div>
                </div>
                <div className="mt-2">
                  <ConfigProvider theme={{ token: { padding: 5 } }}>
                    <Table
                      pagination={false}
                      scroll={{
                        y: 250,
                      }}
                      onRow={(record) => ({
                        onClick: () => onClickBNDone(record),
                      })}
                      dataSource={BNDone}
                      columns={[
                        {
                          title: 'Mã BN',
                          dataIndex: 'mabenhnhan',
                          key: 'mabenhnhan',
                          width: '30%',
                        },
                        {
                          title: 'Tên bệnh nhân',
                          dataIndex: 'tenbenhnhan',
                          key: 'tenbenhnhan',
                          width: '70%',
                          ...getColumnSearchProps('tenbenhnhan'),
                        },
                      ]}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
            <div className="w-3/4 border h-full rounded-lg">
              <div className="p-2">
                <div className="flex justify-between gap-5">
                  <AntButton
                    icon={<SaveOutlined style={{ fontSize: 16 }} />}
                    onClick={formik.handleSubmit}
                    disabled={!activeRow || isDone}
                    type="primary"
                  >
                    Lưu
                  </AntButton>
                  <AntButton
                    icon={<PrinterOutlined style={{ fontSize: 16 }} />}
                    type="primary"
                    onClick={handlePrint}
                    disabled={!isDone}
                  >
                    In
                  </AntButton>
                </div>
                <div>
                  <InfoBN formik={formik} infoBN={infoBN} />
                </div>
                <div className="mt-2">
                  <Index infoBN={infoBN} />
                </div>
                <div className="mt-2 flex">
                  <div className="w-1/2 p-2 border mt-2 rounded-md">
                    <h6 className="font-bold text-gray-500 ">
                      Kết quả monitor tim thai - cơn gò tử cung
                    </h6>
                    <div className="flex flex-col gap-4">
                      <div className="flex gap-1 mt-1">
                        <label className="font-semibold w-56 text-end">
                          <span className="text-red-500">(*)</span> Nhịp tim thai cơ bản:{' '}
                        </label>
                        <Input
                          status={isDone ? '' : formik.errors.nhiptimthaicoban ? 'error' : ''}
                          onChange={formik.handleChange}
                          value={formik.values.nhiptimthaicoban}
                          name="nhiptimthaicoban"
                          size="small"
                          suffix="Nhịp/phút"
                          readOnly={isDone}
                        />
                      </div>
                      <div className="flex gap-1 mt-1">
                        <label className="font-semibold w-56 text-end">
                          <span className="text-red-500">(*)</span> Dao động nội tại:{' '}
                        </label>
                        <Input
                          status={isDone ? '' : formik.errors.daodongnoitai ? 'error' : ''}
                          onChange={formik.handleChange}
                          value={formik.values.daodongnoitai}
                          name="daodongnoitai"
                          size="small"
                          suffix="Nhịp/phút"
                          readOnly={isDone}
                        />
                      </div>
                      <div className="flex gap-1 mt-1">
                        <label className="font-semibold w-56 text-end">
                          <span className="text-red-500">(*)</span> Nhịp tăng:{' '}
                        </label>
                        <div className="w-full pl-4">
                          <Radio.Group
                            value={formik.values.nhiptang}
                            onChange={(e) => formik.setFieldValue('nhiptang', e.target.value)}
                            disabled={isDone}
                          >
                            <Radio value={'Không'} className="text-black">
                              Không
                            </Radio>
                            <Radio value={'Có'} className="text-black">
                              Có
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>
                      <div className="flex gap-1 mt-1">
                        <label className="font-semibold w-56 text-end">
                          <span className="text-red-500">(*)</span> Nhịp giảm:{' '}
                        </label>
                        <div className="w-full pl-4">
                          <Radio.Group
                            disabled={isDone}
                            value={formik.values.nhipgiam}
                            onChange={(e) => formik.setFieldValue('nhipgiam', e.target.value)}
                          >
                            <Space size={'large'} direction="vertical">
                              {listNhipGiam.map((item) => (
                                <Radio value={item.name} key={item.id} className="text-black">
                                  {item.name}
                                </Radio>
                              ))}
                            </Space>
                          </Radio.Group>
                        </div>
                      </div>
                      <div className="flex gap-1 mt-1">
                        <label className="font-semibold w-56 text-end">
                          <span className="text-red-500">(*)</span> Cơ gò tử cung:{' '}
                        </label>
                        <div className="w-full pl-4">
                          <Radio.Group
                            value={formik.values.cogotucung}
                            onChange={(e) => formik.setFieldValue('cogotucung', e.target.value)}
                            disabled={isDone}
                          >
                            <Radio value={'Không'} className="text-black">
                              Không
                            </Radio>
                            <Radio value={'Có'} className="text-black">
                              Có
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2 p-2 border mt-2 rounded-md ml-2">
                    <h6 className="font-bold text-gray-500">
                      Kết luận: Biểu dồ đo tim thai - cơn gò tử cung thuộc
                    </h6>
                    <div className="w-full pl-4 mt-4">
                      <Radio.Group
                        value={formik.values.idkl}
                        onChange={(e) => formik.setFieldValue('idkl', e.target.value)}
                        disabled={isDone}
                      >
                        <Space size={'large'} direction="vertical">
                          {allKetLuan.map((item) => (
                            <Radio value={item.idkl} key={item.idkl} className="text-black">
                              {item.ketluan}
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Mornitor
