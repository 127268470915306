import { https } from '../apiService'

export const nhanvienService = {
  // người dùng //
  getStaffs: () => https.get(`NhanVien`),
  getRulerUser: () => https.get('NhomNguoiDung/GetAll'),
  // nhân viên //
  // getAllNv: (pageIndex) => https.get('NhanVien/GetAllNhanVien?pageIndex={pageIndex}'),
  getAllNv: () => https.get('NhanVien/GetAllNhanVien'),
  postStaffs: (form) => https.post(`NhanVien/AddNhanVien`, form),
  putUpdateStaff: (idnv, update) => https.put(`NhanVien/UpdateNhanVien/${idnv}`, update),
  deleteStaffs: (idnv) => https.delete(`NhanVien/DeleteNhanVien?id=${idnv}`),
  getNhanVienByID: (id) => https.get(`NhanVien/GetNhanVienByID/${id}`),
  getAllMaNV: () => https.get('NhanVien/GetAllMaNhanVien'),
  // getSearch: (keyword, idcn) => https.get(`NhanVien/SearchNhanVien?keyword=${keyword}&idcn=${idcn}`),
  getSearch: (keyword) => https.get(`NhanVien/SearchNhanVienThe`, { params: { keyword: keyword } }),
  getCity: (Conscious) => https.get(`tinh?conscious=${Conscious}`),
  getDistrict: (idTinh) => https.get(`QuanHuyen/${idTinh}`),
  getWards: (idQuan) => https.get(`PhuongXa/${idQuan}`),
  getSearchTinhTP: (keyword) => https.get(`tinh/SearchTinhTP?keyword=${keyword}`),
  getSearchQuanHuyen: (idtinh) => https.get(`QuanHuyen/SearchQuanHuyen?idtinh=${idtinh}`),
  getSearchPhuongXa: (idquan) => https.get(`PhuongXa/SearchPhuongXa?idquan=${idquan}`),
  getCongTy: (company) => https.get(`CongTy?company=${company}`),
  getChiNhanh: (idCT) => https.get(`ChiNhanh?idCT=${idCT}`),
  getCNByIDCT: (idct, keyword) => https.get(`ChiNhanh/GetChiNhanhByIDCT?idct=${idct}&keyword=${keyword}`),
  getNhanVienByIDCN: (idcn, page) => https.get(`NhanVien/GetNhanVienByIDCN?idcn=${idcn}&pageIndex=${page}`),
  getTitle: () => https.get('ChucDanh/GetChucDanh'),
  getQualifications: () => https.get('BangCap/GetAllBangCap'),
  getAllNhanVienExcel: () => https.get('NhanVien/GetAllNhanVienExcel'),
  getSearchNhanVien: (keyword, idcn, idct, pageIndex) => https.get(`NhanVien/SearchNhanVien`, { params: { keyword: keyword, idcn: idcn, idct: idct, pageIndex: pageIndex } }),
  getStaffsChuaTaoTK: (keyword) => https.get(`NguoiDung/GetNhanVienNguoiDung`, { params: { keyword: keyword } }),
}
