import React, { useEffect } from 'react'
import { Input } from 'antd'
import { EditorState, Modifier } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import ToastCus from '../../../../utils/Toast'

const BieuMau = ({ editorState, setEditorState, ketLuan, setketLuan, isKhongDuocChinhSua }) => {
  const handleReturn = (event, editorState) => {
    const contentState = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const newContentState = Modifier.splitBlock(contentState, selection)
    const newEditorState = EditorState.push(editorState, newContentState, 'split-block')

    setEditorState(newEditorState)
    return 'handled'
  }
  return (
    <div className="pt-2">
      <style>
        {`.toolbarClassName {
            background-color: #f5f5f5;
        }
        
        .wrapperClassName {
            border: 1px solid #d4d4d4;
            border-radius: 0.25rem;
        }
        
        .editorClassName {
            min-height: 609px;
            max-height: 609px;
            overflow-y: auto;
            padding: 0 10px;
        }`}
      </style>
      <Editor
        toolbar={{
          options: ['inline', 'blockType', 'fontFamily', 'textAlign', 'colorPicker'],
          inline: {
            options: ['bold', 'italic'],
          },
          blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
          },
          fontFamily: {
            options: ['Times New Roman'],
          },
        }}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={
          isKhongDuocChinhSua
            ? () => {
                if (isKhongDuocChinhSua) {
                  ToastCus.fire({
                    icon: 'error',
                    title: 'Chỉ BS nội soi và cùng ngày mới được chỉnh sửa!',
                  })
                  return
                }
              }
            : setEditorState
        }
        handleReturn={handleReturn}
      />
      <div className="pt-3">
        <h2 className="font-semibold">KẾT LUẬN</h2>
        <Input.TextArea
          style={{
            height: 120,
            resize: 'none',
            fontFamily: 'Time New Roman',
          }}
          className="font-bold text-[1rem]"
          value={ketLuan}
          onChange={(e) => {
            if (isKhongDuocChinhSua) {
              ToastCus.fire({
                icon: 'error',
                title: 'Chỉ BS nội soi và cùng ngày mới được chỉnh sửa!',
              })
              return
            }
            setketLuan(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

export default BieuMau
