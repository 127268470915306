import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, Divider, Form, Input, Space, Table, Tabs, Tooltip } from 'antd'
import { https } from '../../../../services/apiService'
import { cloneDeep, debounce } from 'lodash'
import { DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import ToastCus from '../../../../utils/Toast'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import 'moment/locale/vi'
moment.locale('vi')

function TabChiNhanh({ dataChiNhanh, setDataChiNhanh }) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const [loadingSearching, setLoadingSearching] = useState(false)
  // const [dataChiNhanh, setDataChiNhanh] = useState([])// các chi nhánh dc chọn cho voucher
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [searchText, setSearchText] = useState('')
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [filterActive, setFilterActive] = useState(false)
  const [listTTV, setListTTV] = useState([])
  const searchInput = useRef(null)
  const [searchedColumn, setSearchedColumn] = useState('')

  const columsChiNhanhChon = [
    {
      title: <p className="text-center">Mã chi nhánh</p>,
      dataIndex: 'maChiNhanh',
      key: 'maChiNhanh',
      align: 'center',
      className: 'text-sm',
      width: 110,
    },
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      align: 'left',
      className: 'text-sm',
    },
    {
      title: 'Chuyên khoa',
      dataIndex: 'chuyenKhoa',
      key: 'chuyenKhoa',
      align: 'center',
      className: 'text-sm',
      width: 100,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 40,
      className: 'text-sm',
    },
  ]

  const columsChiNhanh = [
    {
      title: <p className="text-center">Mã chi nhánh</p>,
      dataIndex: 'maChiNhanh',
      key: 'maChiNhanh',
      align: 'left',
      className: 'text-sm',
      width: 110,
    },
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      align: 'left',
      className: 'text-sm',
      render: (text, record) => <p className={`${record.children ? 'font-medium' : ''}`}>{text}</p>,
    },
    {
      title: 'Chuyên khoa',
      dataIndex: 'chuyenKhoa',
      key: 'chuyenKhoa',
      className: 'text-sm',
      align: 'center',
      width: 100,
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return record.children ? null : (
          <PlusOutlined
            onClick={() => handleAddChiNhanh(record)}
            className="text-xl text-green-500 cursor-pointer"
          />
        )
      },
      width: 40,
    },
  ]

  const getListCongTy = async () => {
    setLoadingSearching(true)
    try {
      const { data } = await https.get('CongTy')
      //format data
      const formatData = data.map((item) => ({
        key: item.idct,
        maChiNhanh: '',
        tenChiNhanh: `${item.mact} - ${item.tenct}`,
        chuyenKhoa: '',
        children: [],
      }))
      setListChiNhanh(formatData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearching(false)
    }
  }

  useEffect(() => {
    getListCongTy()
  }, [])

  const getListChiNhanhByIdCongTy = async (idct) => {
    setLoadingSearching(true)
    try {
      const { data } = await https.get(`ChiNhanh/GetChiNhanhByIDCT`, { params: { idct } })
      //set data in table
      let newDataInTable = [...listChiNhanh]
      const idx = newDataInTable.findIndex((item) => item.key === idct)
      newDataInTable[idx].children = data.map((item, index) => ({
        key: '' + item?.idchinhanh + item?.tenchinhanh,
        idcn: item?.idchinhanh,
        // idCongTy: idct,
        maChiNhanh: item?.machinhanh,
        tenChiNhanh: item?.tenchinhanh,
        // donVi: val.donVi,
        chuyenKhoa: item?.tenchuyenkhoa?.replace('Chuyên khoa', '')?.trim(),
        info: item,
      }))
      // console.log(data)
      setListChiNhanh(newDataInTable)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSearching(false)
    }
  }

  const handleExpand = (expanded, record) => {
    if (searchText) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record.key])
      getListChiNhanhByIdCongTy(record.key)
    } else {
      // const newDataInTable = cloneDeep(listChiNhanh)
      // const idx = newDataInTable.findIndex((item) => item.key === record.key)
      // newDataInTable[idx].children = ([])
      // // console.log(data)
      // setListChiNhanh(newDataInTable)
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key))
    }
  }

  const handleAddChiNhanh = (record) => {
    const idx = dataChiNhanh.findIndex((item) => item.key === record.key)
    if (idx !== -1) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dịch vụ đã được chọn',
      })
      return
    }
    //add record
    setDataChiNhanh((prev) => [
      ...prev,
      {
        ...record,
      },
    ])
  }

  const handleDeleteInTable = (record) => {
    setDataChiNhanh((prev) => prev.filter((item) => item.key !== record.key))
  }

  return (
    <div>
      <div className="overflow-auto h-[280px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            // loading={loadingTable}
            scroll={{ y: 180 }}
            bordered
            pagination={{
              pageSize: 5,
              showSizeChanger: false,
            }}
            columns={columsChiNhanhChon}
            dataSource={
              dataChiNhanh.map((item) => ({
                ...item,
                action: (
                  <ul className="flex gap-2 justify-around">
                    <li className="text-lg text-red-500">
                      <DeleteOutlined onClick={() => handleDeleteInTable(item)} />
                    </li>
                  </ul>
                ),
              })) || []
            }
          />
        </ConfigProvider>
      </div>
      <div>
        <div className="overflow-auto h-[280px]">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              scroll={{ y: 180 }}
              loading={loadingSearching}
              bordered
              pagination={{
                pageSize: 5,
                showSizeChanger: false,
              }}
              columns={columsChiNhanh}
              dataSource={listChiNhanh}
              expandable={{ onExpand: handleExpand, expandedRowKeys: expandedRowKeys }}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  )
}

export default TabChiNhanh
