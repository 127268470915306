import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Button, Input } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined, SoundTwoTone } from '@ant-design/icons'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD/MM/YYYY'
function ListBN({ tabListBN, listBN, handleGetInfoBNKham, formSinhHieu }) {
  return (
    <>
      <ul className="overflow-auto h-[46.2rem] pt-1">
        {listBN?.map((item) => (
          <li
            key={item?.idbnttc}
            className={`grid grid-cols-6 rounded-md border w-full h-[3.5rem] mt-2 cursor-pointer ${item?.idbnttc === formSinhHieu?.idbnttc ? 'bg-sky-100' : 'hover:bg-sky-50'}`}
            onClick={() => handleGetInfoBNKham(item?.idbn, item?.idbnttc)}
          >
            <div className="flex justify-center items-center font-semibold text-xl">
              {item?.sttkham}
            </div>
            <div className="col-span-5 flex justify-between items-center">
              <div className="grid grid-flow-row">
                <div className="font-semibold leading-5">{item?.tenbenhnhan}</div>
                <div className="text-gray-500 text-[13px] leading-4">
                  Ngày sinh:&nbsp;{item?.ngaysinh ? moment(item?.ngaysinh).format(dateFormat) : '-'}
                </div>
              </div>
              {tabListBN === 1 ? (
                <Button shape="circle" icon={<SoundTwoTone />} className="mr-3" />
              ) : (
                <></>
              )}
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default ListBN
