import JsBarcode from 'jsbarcode'
import moment from 'moment/moment'
import { generateLogo } from '../algorithm'
import { jsPDFCus } from '../jsPdfAddFont'
import { numberToWords } from '../numberToWords'
import { splitLongWord } from '../splitWordForReport'
import { formatNumberVND } from '../formatNumberVND'

export const generatePhieuXuatBanKhachHang = (infoPhieu, listHH, PkDangNhap) => {
  const doc = jsPDFCus('l', 'mm', 'a5')
  let yPos = 10
  let xPos = 10
  const pageWidth = doc.internal.pageSize.width
  //header
  generateLogo(doc, xPos, PkDangNhap)

  //barcode
  JsBarcode('#barcode', `${infoPhieu?.maphieu ?? '0'}`, {
    displayValue: false,
  })
  const canvas = document.getElementById('barcode')
  const barcodeDataURL = canvas.toDataURL()
  const barcodeWidth = 35 // Width of the barcode image
  const barcodeX = pageWidth - 45 // X coordinate of the barcode
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 45, 5, 35, 14)
  // Calculate the center position for text
  const text = `${infoPhieu.maphieu ?? ''}`
  const textWidth = (doc.getStringUnitWidth(text) * 11) / doc.internal.scaleFactor // Width of the text
  const textX = barcodeX + (barcodeWidth - textWidth) / 2 // Centered X coordinate for the text
  doc.setFontSize(11)
  doc.text(text, textX, 22)
  // Title
  doc.setFont('times', 'bold')
  doc.setFontSize(12)
  doc.text('PHIẾU THU TIỀN', doc.internal.pageSize.width / 2, 30, { align: 'center' })
  // Patient Info
  doc.setFontSize(10)
  let textWidthName = doc.getTextDimensions('Họ và tên: ').w
  doc.text(`${infoPhieu?.tenbenhnhan}`, xPos + textWidthName, 38)
  doc.setFont('times', 'regular')
  doc.text('Họ và tên: ', xPos, 38)
  doc.text(`Mã bệnh nhân: ${infoPhieu?.mabenhnhan}`, pageWidth - 70, 38)
  yPos = 43
  const diaChiBenhNhan = `Địa chỉ: ${infoPhieu.diachi ?? ''}`
  if (diaChiBenhNhan.length > 100) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 100)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(diaChiBenhNhan, xPos, yPos)
    yPos += 5
  }
  const ghichu = `Ghi chú: ${infoPhieu.ghichu ?? ''}`
  if (ghichu.length > 100) {
    const wordsArray = splitLongWord(ghichu, 100)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(ghichu, xPos, yPos)
    yPos += 3
  }
  // Table
  const headers = ['STT', 'Tên hàng hóa', 'Mã hàng', 'Số lô', 'Đơn vị', 'Số lượng']
  const data = listHH.map((item, index) => [
    index + 1,
    item.tenthuoc,
    item.mathuoc,
    item.solo,
    item.dvt,
    item.soluong,
  ])

  doc.autoTable({
    head: [headers],
    body: data,
    startY: yPos,
    columnStyles: {
      0: { cellWidth: 15 },
      1: { cellWidth: 80, halign: 'left' },
      2: { cellWidth: 25 },
      3: { cellWidth: 25 },
      4: { cellWidth: 25 },
      5: { cellWidth: 20 },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1,
      fillColor: '#fff',
      textColor: '#000',
      lineColor: '#000',
      halign: 'center',
      valign: 'middle',
      fontStyle: 'regular',
      fontSize: 9,
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff',
      textColor: '#000',
      fontStyle: 'bold',
      fontSize: 9,
    },
    alternateRowStyles: {
      font: 'times',
      fontStyle: 'regular',
      fillColor: '#fff',
    },
    showHead: 'firstPage',
    margin: xPos,
  })
  const tongTien = listHH.reduce((acc, item) => acc + item.thanhtien, 0)
  const data2Column = [
    ['', ''],
    ['Tổng tiền: ', formatNumberVND(tongTien)],
  ]
  doc.autoTable({
    head: [data2Column[0]],
    body: data2Column.slice(1),
    startY: doc.autoTable.previous.finalY,
    columnStyles: {
      0: { cellWidth: 170, halign: 'right' },
      1: { cellWidth: 20, halign: 'right' },
    },
    styles: {
      font: 'times',
      lineWidth: 0.1, // Set line width for table borders
      fillColor: '#fff', // Set fill color to white for headers and rows
      textColor: '#000', // Set text color to black for headers and rows
      lineColor: '#000',
      valign: 'middle',
      fontStyle: 'regular', // Set font weight to bold for headers
      fontSize: 8,
      // Set line color to black for headers and rows
    },
    headStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for headers
      textColor: '#000', // Set text color to black for headers
      fontSize: 8,
    },
    alternateRowStyles: {
      font: 'times',
      fillColor: '#fff', // Set fill color to white for alternate rows
      fontStyle: 'bold',
    },
    showHead: 'never',
    margin: xPos,
  })
  const tableFinalY = doc.autoTable.previous.finalY
  yPos = tableFinalY + 5 // Add a small margin after the table

  if (yPos > 130) {
    doc.addPage()
    yPos = 10
  }
  doc.setFont('times', 'italic')
  doc.setFontSize(10)
  if (`Bằng chữ: ${numberToWords(infoPhieu.tienthanhtoan)}.`.length > 85) {
    const wordsArray = splitLongWord(`Bằng chữ: ${numberToWords(infoPhieu.tienthanhtoan)}./.`, 85)
    for (let i = 0; i < wordsArray.length; i++) {
      doc.text(wordsArray[i], xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(`${`Bằng chữ: ${numberToWords(infoPhieu.tienthanhtoan)}.` ?? ''}`, xPos, yPos + 5)
    yPos += 5
  }
  // Footer
  doc.setFontSize(10)
  const dateText = `Ngày ${moment(infoPhieu.ngayxuat ?? new Date()).format('DD')} tháng ${moment(infoPhieu.ngayxuat ?? new Date()).format('MM')} năm ${moment(infoPhieu.ngayxuat ?? new Date()).format('YYYY')}`
  const doctorText = infoPhieu.tennguoixuat ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 25 // 15 is the padding from the right edge

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5

  doc.text('Người lập phiếu', pageWidth - 57, yPos)
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)

  doc.setFontSize(9)
  doc.setFont('times', 'italic')
  doc.text(
    `(*)Ngày in phiếu: ${moment().format('DD/MM/YYYY HH:mm:ss')}`,
    xPos,
    doc.internal.pageSize.height - 5,
  )
  // Auto print
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
