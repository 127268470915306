import React, { useState, useRef } from 'react'
import { Table, ConfigProvider, Button, Input, Divider } from 'antd'
import { DeleteOutlined, CloudUploadOutlined } from '@ant-design/icons'
import ToastCus from '../../../../../../utils/Toast'
import { v4 as uuidv4 } from 'uuid'

const XetNghiemGK = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedFile, setSelectedFile] = useState([])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item?.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
    // handleFileChange(e.dataTransfer.files)
    handleFileChange(Object.values(e.dataTransfer.files))
  }
  const handleFileSelect = (e) => {
    // handleFileChange(e.target.files)Object.values(e.target.files)
    handleFileChange(Object.values(e.target.files))
  }
  const handleFileChange = async (files) => {
    //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'application/pdf',
    ]
    if (!files?.length || files?.find((item) => !allowedFileTypes.includes(item?.type))) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ chấp nhận tệp là hình ảnh hoặc pdf!',
      })
      fileInputRef.current.value = '' // clear the input
      return
    }
    for (const file of files) {
      fileInputRef.current.value = ''
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const localUrl = URL.createObjectURL(file)
        setSelectedFile((preState) => [
          ...preState,
          { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
        ])
      }
    }
  }
  return (
    <div>
      <div className="flex justify-end items-center gap-2 mb-2">
        <Button type="primary">Lưu kết quả</Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 3,
            fontSize: 13,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
            },
          },
        }}
      >
        <Table
          // loading={isLoading}
          // rowSelection={rowSelection}
          scroll={{ y: 200 }}
          className="w-[99.85%]"
          bordered
          pagination={false}
          dataSource={[
            1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9,
          ]?.map((item, index) => ({
            key: index,
            value: item,
          }))}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 'stt',
              width: 35,
              align: 'center',
              fixed: 'left',
              render: (text, record, index) => ++index,
            },
            {
              title: 'Mã xét nghiệm',
              dataIndex: 'stt',
              key: 'stt',
              width: 140,
            },
            {
              title: 'Tên xét nghiệm',
              dataIndex: 'stt',
              key: 'stt',
            },
            {
              title: 'ĐVT',
              dataIndex: 'stt',
              key: 'stt',
              width: 50,
              align: 'center',
            },
            {
              title: 'Kết quả',
              dataIndex: 'stt',
              key: 'stt',
              width: 270,
              render: (text, record, index) => <Input size="small" variant="Borderless" />,
            },
            {
              title: 'Chỉ số',
              dataIndex: 'stt',
              key: 'stt',
              width: 270,
              render: (text, record, index) => <Input size="small" variant="Borderless" />,
            },
          ]}
        />
      </ConfigProvider>
      <Divider orientation={'left'} plain style={{ margin: 0, marginTop: 8, marginBottom: 3 }}>
        <p className="font-semibold">Tệp đính kèm</p>
      </Divider>
      <div>
        <ul className="flex flex-wrap gap-2 mb-2">
          {selectedFile?.map((items, index) => (
            <li
              key={`upHinh${index}`}
              className="w-[7rem] h-[7rem] overflow-hidden border rounded-md relative"
            >
              <DeleteOutlined
                onClick={() => deleteFileById(items.id)}
                className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                style={{ fontSize: 18 }}
              />
              <a href={items?.URL} target="_blank">
                <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                  <div className="relative "></div>
                  <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                    {items.name}
                  </p>
                </div>
                <img src={items.base64} className="w-full" alt={items.name} />
              </a>
            </li>
          ))}
        </ul>
        <div
          style={{
            border: '2px dashed #cccccc',
            borderRadius: '4px',
            padding: '30px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          className="border"
          ref={dropzoneRef}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleDivClick}
        >
          <CloudUploadOutlined style={{ fontSize: 36 }} className="text-gray-500" />
          <label style={{ cursor: 'pointer' }}>
            <p className="font-semibold text-lg text-gray-500">
              Thả tập tin vào đây hoặc bấm vào để tải lên.
            </p>
          </label>
          <input
            multiple
            ref={fileInputRef}
            id="fileInput"
            type="file"
            onChange={handleFileSelect}
          />
        </div>
      </div>
    </div>
  )
}

export default XetNghiemGK
