import {
  CheckOutlined,
  CloseOutlined,
  PrinterOutlined,
  CalculatorOutlined,
} from '@ant-design/icons'
import { Checkbox, ConfigProvider, Divider, Input, Select, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import { recieveService } from '../../../../services/receive/recieveService'
import {
  detailBNCashier,
  getListDiscountCashier,
  removeListDiscountCashier,
  setChuongTrinhGiamGiaDoiTacCashier,
  getListPhieuDaThuCashier,
  updateServiceBN,
  CalculateServiceCustomer_ByMemberCard,
} from '../../../../store/actions/cashierAction'
import ToastCus from '../../../../utils/Toast'
import ServiceKham from '../ServiceKham/ServiceKham'
import TabPT from '../TabPT/TabPT'
import TableChiDinh from '../TableChiDinh/TableChiDinh'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import ModalPay from '../ModalPay/ModalPay'
import { Button } from '@mui/material'
import * as typeAction from '../../../../store/constants/constants'
import _ from 'lodash'
import { generatePhieuThuTiepNhan } from '../../../../utils/report/phieuThuTiepNhan'
import ModalAdd from '../../CardReceive/Modal/ModalAdd'
import 'moment/locale/vi'
moment.locale('vi')
const today = moment()
const InfoPhieu = ({ BNChuaThu }) => {
  const {
    ArrXN,
    ArrCĐHA,
    ArrKB,
    tongGiam,
    tongThanhToan,
    BNTTC,
    InfoBN,
    memberCard,
    infoBNcuaPT,
    discountArr,
    infoChuongTrinhGiamGiaDoiTac,
    phieuDaThu,
    DetailTT,
  } = useSelector((state) => state.cashierReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { BNPending, listCTGiamGia } = useSelector((state) => state.receiveReducer)

  let Arr = ArrKB.concat(ArrXN, ArrCĐHA) // nối các mãng lại với nhau
  const newArr = Arr.sort((a, b) => {
    return a.trangthaitt - b.trangthaitt
  }) // sắp xếp danh sách chỉ định trên phiếu theo trạng thái thanh toán
  // const tongThanhTien = newArr.reduce((tong, item) => tong + item.dongia, 0)
  // const tongGiam = newArr.reduce((tong, item) => tong + item.tiengiamgia, 0)
  // const tongThanhToan = newArr.reduce((tong, item) => tong + item.tienthanhtoan, 0)
  // const [tongGiam, setTongGiam] = useState(0)
  // const [tongThanhToan, setTongThanhToan] = useState(0)
  const [isModalPay, setIsModalPay] = useState(false)
  const [isPrint, setIsPrint] = useState(false)

  const handlePay = () => {
    if (
      ((maVoucher?.trim()?.length > 0 && discountArr.length === 0) ||
        (Number(valueGiaVoucherDoiTac) > 0 && !infoChuongTrinhGiamGiaDoiTac)) &&
      infoBNcuaPT &&
      newArr.length > 0 &&
      tongThanhToan > 0
    ) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn áp dụng voucher hoặc đối tác!',
      })
      return
    }
    setForm({
      ...form,
      // tiengiamgia: tongGiam,
      // tienthanhtoan: tongThanhToan,
      mavoucher: maVoucher?.trim(),
      voucherDoiTac: infoChuongTrinhGiamGiaDoiTac,
      listMaVC: listMaVoucher?.length > 0 ? listMaVoucher : null,

      tiengiamgia: Number(tongGiam), //+ Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
      tienthanhtoan: Number(tongThanhToan) - Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
    })
    setIsModalPay(true)
  }
  // const [phieuThu, setPhieuThu] = useState([])
  const [nguon, setNguon] = useState([])
  const [maVoucher, setMaVoucher] = useState('')
  const [maVoucherDoiTac, setMaVoucherDoiTac] = useState('')
  const [isDoiTac, setIsDoiTac] = useState(false)
  const [valueChuongTrinhDoiTac, setValueChuongTrinhDoiTac] = useState('')
  const [valueGiaVoucherDoiTac, setValueGiaVoucherDoiTac] = useState(0)
  const [isDelete, setIsDelete] = useState({ DV: [], XN: [], CDHA: [] })
  const [listVoucher, setListVoucher] = useState([]) // list chương trình đối tác gotit, bảo hiểm
  const [listMaVoucher, setListMaVoucher] = useState([]) // list mã voucher ['abc','xyz',...]
  const [listDVTruocApplyVC, setListDVTruocApplyVC] = useState([])
  const [CTGiamGiaApply, setCTGiamGiaApply] = useState(null)
  const [open, setOpen] = useState(false)
  const maCTGGdangApply =
    `${infoBNcuaPT?.chuongtrinhgiamgia ? infoBNcuaPT?.chuongtrinhgiamgia + ',' : ''}${CTGiamGiaApply ?? ''}`
      ?.replaceAll(' ', '')
      ?.replaceAll(',,', ',')

  const [form, setForm] = useState({
    // idbntt: 0,
    // idbnttchung: 0,
    // idthe: 0,
    tienmat: 0,
    chuyenkhoan: 0,
    thett: 0,
    momo: 0,
    vnpay: 0,
    zalopay: 0,
    pos: 0,
    tongtien: 0,
    tiengiamgia: 0,
    tienthanhtoan: 0,
    trangthaithanhtoan: 2,
    // the: {
    //   idthe: 0,
    //   mathe: "string",
    //   tenthe: "string",
    //   dongia: 0,
    //   sothang: 0,
    //   idchuyenkhoa: 0,
    //   sothanhvien: 0,
    //   ghichu: "string",
    //   idnhom: 0,
    //   hieuluc: 0,
    //   ngaydangky: "2024-05-08T06:57:31.082Z",
    //   tiensudung: 0,
    //   thethanhtoan: 0,
    //   tungay: "2024-05-08T06:57:31.082Z",
    //   denngay: "2024-05-08T06:57:31.082Z",
    // },
  })
  const dispatch = useDispatch()

  const getListDoiTacVoucher = async () => {
    try {
      const { data } = await https.get(`Voucher/GetChuongTrinhDoiTac`)
      setListVoucher(data)
    } catch (error) {
      console.log(error)
    }
  }
  //---------- tính lại giá dịch vụ có thẻ thành viên--------- //
  const calculatorPriceServiveByTTV = () => {
    if (!memberCard) {
      // nếu không co thẻ không tính lại
      ToastCus.fire({
        icon: 'warning',
        text: 'Khách chưa có thẻ thành viên để tính lại dịch vụ !',
      })
      return
    }
    dispatch(CalculateServiceCustomer_ByMemberCard(memberCard?.idthe, BNTTC?.idbnttc))
  }
  //-----------fetch phiếu đã thu tiền trong ngày -------------//-
  const fetchPhieuThu = () => {
    dispatch(
      getListPhieuDaThuCashier(
        today.format('YYYY-MM-DD'),
        today.format('YYYY-MM-DD'),
        PkDangNhap?.idChiNhanh,
      ),
    )
  }
  //---------------- in phiếu ---------/////
  const handlePrint = async (DetailTT, InfoBN, BNTTC) => {
    const dataBenhNhanTTC = {
      sttkham: BNTTC.sttkham,
      tenPhongKham: BNTTC.tenPhongKham,
      tenLoaiKham: BNTTC?.tenLoaiKham,
      soLanDaTaiKham: BNTTC?.soLanDaTaiKham,
      benhNhan: {
        tenBenhNhan: InfoBN?.tenBenhNhan,
        maBenhNhan: InfoBN?.maBenhNhan,
        diaChi: InfoBN?.diaChi,
        tenPhuongXa: InfoBN?.tenPhuongXa,
        tenQuanHuyen: InfoBN?.tenQuanHuyen,
        tenTinhThanh: InfoBN?.tenTinhThanh,
        chiNhanh: {
          diaChi: InfoBN?.chiNhanh?.diaChi,
          soDienThoai: InfoBN?.chiNhanh?.dienThoai,
        },
      },
    }
    const dataChiDinh = [...ArrXN, ...ArrCĐHA, ...ArrKB].map((item) => ({
      tenChiDinh: item?.tendichvu || item?.tenxn || item?.tencdha,
      dvt: item.donvitinh || item.dvt,
      tienThanhToan: item?.tienthanhtoan,
      dongia: item?.dongia,
    }))
    const dataBenhNhanThanhToan = {
      maPhieuThu: DetailTT?.maphieuthu,
      tienThanhToan: DetailTT?.tienthanhtoan,
      ngaythanhtoan: DetailTT?.ngaythanhtoan,
      thuNgan: DetailTT?.thungan,
    }
    const dataBenhNhanChiTietTT = {}
    generatePhieuThuTiepNhan(
      dataBenhNhanTTC,
      dataChiDinh,
      dataBenhNhanThanhToan,
      dataBenhNhanChiTietTT,
      PkDangNhap,
    )
  }
  //
  // -------------- lấy thông tin chi tiết bệnh nhân chưa thu tiền ------------/
  const onClickDetailBN = (record) => {
    setIsDelete({ DV: [], XN: [], CDHA: [] })
    dispatch(detailBNCashier(record.idbn, record?.idbntt, record.idbnttc, PkDangNhap?.idChuyenKhoa))
    // dispatch({// cập nhật lại tổng tiền giảm khi xem phiếu
    //   type: typeAction.DISPATCH_SET_TONG_GIAM_CASHIER,
    //   payload: 0,
    // })
    // dispatch({// cập nhật lại tổng tiền thanh toán khi xem phiếu
    //   type: typeAction.DISPATCH_SET_TONG_THANH_TOAN_CASHIER,
    //   payload: 0,
    // })
    resetFormThanhToan()
  }
  //------------- lấy danh sách nguồn khách hàng -----------//
  const fetchNguonKH = async () => {
    try {
      const result = await recieveService.getNguonKH()
      setNguon(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  const resetFormThanhToan = () => {
    setForm({
      tienmat: 0,
      chuyenkhoan: 0,
      thett: 0,
      momo: 0,
      vnpay: 0,
      zalopay: 0,
      pos: 0,
      tongtien: 0,
      tiengiamgia: 0,
      tienthanhtoan: 0,
      trangthaithanhtoan: 2,
    })
  }

  const columnsCT = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      key: 'stt',
      width: 40,
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã BN',
      dataIndex: 'maBenhNhan',
      key: 'maBenhNhan',
      width: 120,
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenBenhNhan',
      key: 'tenBenhNhan',
    },
  ]
  useEffect(() => {
    fetchPhieuThu()
    fetchNguonKH()
    getListDoiTacVoucher()
  }, [])

  useEffect(() => {
    fetchPhieuThu()
  }, [BNChuaThu?.length, BNPending?.length])
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { data } = await https.get(`Voucher/GetChuongTrinhDoiTac`)
  //       setListVoucher(data)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })()
  // }, [])

  useEffect(() => {
    // if(!infoBNcuaPT){
    // }
    setMaVoucher('')
    setMaVoucherDoiTac('')
    setIsDoiTac(false)
    setValueChuongTrinhDoiTac('')
    setValueGiaVoucherDoiTac(0)
    setListVoucher([])
    getListDoiTacVoucher()
    dispatch({
      type: typeAction.UPDATE_NEW_PRICE_CHI_DINH_CASHIER,
      payload: [],
    })
    dispatch({
      type: typeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC_CASHIER,
      payload: null,
    })
  }, [infoBNcuaPT])

  // const [maVoucher, setMaVoucher] = useState('')
  // const [maVoucherDoiTac, setMaVoucherDoiTac] = useState('')
  // const [isDoiTac, setIsDoiTac] = useState(false)
  // const [valueChuongTrinhDoiTac, setValueChuongTrinhDoiTac] = useState('')
  // const [valueGiaVoucherDoiTac, setValueGiaVoucherDoiTac] = useState(0)
  // const [listVoucher, setListVoucher] = useState([])
  const handleApplyVoucherDoiTac = () => {
    if (!valueChuongTrinhDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn chương trình đối tác',
      })
      return
    }
    if (!valueGiaVoucherDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập giá trị voucher của đối tác',
      })
      return
    }
    if (tongThanhToan < valueGiaVoucherDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Giá trị hóa đơn thanh toán nhỏ hơn giá trị voucher',
      })
      setMaVoucherDoiTac('')
      setValueGiaVoucherDoiTac(0)
      return
    }
    dispatch(
      setChuongTrinhGiamGiaDoiTacCashier({
        idctDoiTac: valueChuongTrinhDoiTac,
        maVoucher: maVoucherDoiTac,
        giaVoucher: valueGiaVoucherDoiTac,
      }),
    )
  }

  const handleUnApplyVoucherDoiTac = () => {
    dispatch(setChuongTrinhGiamGiaDoiTacCashier(null))
    setMaVoucherDoiTac('')
    setIsDoiTac(false)
    setValueGiaVoucherDoiTac(0)
    setValueChuongTrinhDoiTac('')
  }

  const handleApplyVoucher = async () => {
    if (infoChuongTrinhGiamGiaDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập voucher trước khi nhập đối tác!',
      })
      return
    }
    if (tongThanhToan <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Giá trị hóa đơn thanh toán nhỏ hơn giá trị voucher',
      })
      return
    }
    if (!maVoucher || maVoucher?.trim()?.length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Voucher không hợp lệ!',
      })
      return
    }
    //setMaVoucher(maVoucher?.trim())
    // call api get list discount
    // if (!maVoucher) return
    try {
      let temp = maVoucher
        ?.trim()
        ?.replaceAll(' ', '')
        ?.replaceAll(';', ',')
        ?.replaceAll('-', ',')
        ?.replaceAll('/', ',')
      setMaVoucher(temp)
      //let formatData = [...newArr]
      let formatData = newArr
        ?.filter((item) => item.trangthaitt !== 2)
        ?.map((item) => ({
          iddv: item?.iddichvu ?? item?.idxn ?? item?.idcdha,
          dongia: item?.dongia,
          mavoucher: null, ///////////////
          ptgiam: Math.round(item?.ptgiamgia),
          tiengiam: Math.round(item?.tiengiamgia),
          thanhtien: Math.round(item?.tienthanhtoan),
          trangthaitt: 1,
          nguoikichhoat: infoUser?.tenNV,
          iddt: InfoBN?.idDoiTuong ?? -1,
          idcn: PkDangNhap?.idChiNhanh,
          loaivoucherdichvu: (item?.iddichvu && 1) || (item?.idxn && 2) || (item?.idcdha && 3), /////////
        }))
      setListDVTruocApplyVC(newArr)
      let listMaVC = []
      const vouchers = temp?.split(',')?.filter((vc) => _.isString(vc) && vc?.length > 0)
      if (vouchers?.filter((item, index) => vouchers?.indexOf(item) !== index)?.length > 0) {
        ToastCus.fire({
          icon: 'error',
          title: `Voucher bị trùng!`,
        })
        return
      }
      for (let i = 0; i < vouchers.length; i++) {
        const vc = vouchers[i]
        listMaVC.push(vc)
        formatData = formatData?.map((item) => ({
          ...item,
          mavoucher: vc, // gán mã voucher hiện tại
        }))
        const { data } = await recieveService.postApplyVoucherTiepNhan(formatData) // https.post(`VoucherDanhSach/ApplyVoucher`, formatData);
        formatData = [...data] // cập nhật formatData với kết quả từ API
      }
      setListMaVoucher(listMaVC)
      // dispatch action apply voucher
      dispatch(getListDiscountCashier(formatData))
    } catch (error) {
      console.log(error)
      const message = error?.response?.data?.message
      ToastCus.fire({
        icon: 'error',
        title: (message ? `${message}!\n` : '') + 'Áp dụng voucher thất bại!',
      })
    }

    // try {
    //   let formatData = []
    //   newArr.map((item) => {
    //     if (item.trangthaitt !== 2) {
    //       formatData.push({
    //         iddv: item.iddichvu || item.idxn || item.idcdha,
    //         dongia: item.dongia ?? 0,
    //         mavoucher: maVoucher?.trim(),
    //         ptgiam: item.ptgiamgia ?? 0,
    //         tiengiam: item.tiengiamgia ?? 0,
    //         thanhtien: item.tienthanhtoan ?? 0,
    //         trangthaitt: (item.trangthaitt === 2 ? 2 : 1),
    //         nguoikichhoat: infoUser.tenNV,
    //         iddt: InfoBN?.idDoiTuong,
    //         idcn: PkDangNhap?.idChiNhanh,
    //       })
    //     }
    //   })
    //   const { data } = await https.post(`VoucherDanhSach/ApplyVoucher`, formatData)
    //   //console.log(data)
    //   // if (data?.length > 0 && data[0]?.giatrigiam === 0) {
    //   //   handleUnApplyVoucher()
    //   //   ToastCus.fire({
    //   //     icon: 'error',
    //   //     title: 'Voucher không hợp lệ!',
    //   //   })
    //   //   return
    //   // }
    //   //dispatch action apply voucher
    //   await dispatch(getListDiscountCashier(data))
    // } catch (error) {
    //   ToastCus.fire({
    //     icon: 'error',
    //     title: error?.response?.data?.message,
    //   })
    //   console.log(error)
    // }
  }

  const handleUnApplyVoucher = async () => {
    //call api unuse voucher
    try {
      //https.put(`VoucherDanhSach/UpdateCancelVoucher?mavoucher=${maVoucher}`)
      //dispatch action apply voucher
      await dispatch(removeListDiscountCashier(listDVTruocApplyVC))
      setListMaVoucher([])
      setMaVoucher('')
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message,
      })
      console.log(error)
    }
  }

  const handleChangeDoiTac = (e) => {
    if (infoChuongTrinhGiamGiaDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng bỏ sử dụng voucher đối tác',
      })
      return
    }
    setIsDoiTac(e.target.checked)
    if (!e.target.checked) {
      setMaVoucherDoiTac('')
      setValueChuongTrinhDoiTac('')
      setValueGiaVoucherDoiTac(0)
    }
  }

  // const processServiceItems = (items, discountType) => {
  //   let listDVGiam = [];
  //   let listDVKhongGiam = [];
  //   let tongTG = 0;
  //   let tongTT = 0;

  //   for (const item of items) {
  //     let daGiam = false;
  //     if ((item?.trangthaitt ?? item?.idtrangthaitt) !== 2 && item?.ptgiamgia === 0) {
  //       for (const itemCT of listCTGiamGia) {
  //         if (
  //           (itemCT?.IDTTDT === 0 ||
  //             (InfoBN &&
  //               itemCT?.IDTTDT === 1 &&
  //               itemCT?.DanhSachDoiTuong?.find(
  //                 (itemDT) => itemDT?.iddt === InfoBN?.idDoiTuong
  //               )
  //             )
  //           ) &&
  //           itemCT?.DanhSachChiDinh?.find(
  //             (itemCD) => itemCD?.loaivoucherdichvu === discountType && itemCD?.iddv === item?.id
  //           )
  //         ) {
  //           daGiam = true;
  //           const ptgiamNew = (item?.ptgiamgia ?? 0) + (itemCT?.GIATRI ?? 0);
  //           listDVGiam.push({
  //             ...item,
  //             ptgiamgia: itemCT?.GIATRI,
  //             tiengiamgia: (item?.dongia * itemCT?.GIATRI) / 100,
  //             tienthanhtoan: item?.dongia * (1 - itemCT?.GIATRI / 100),
  //             ctgg: itemCT,
  //           });
  //           setMaCTGGApply(itemCT?.MACT);
  //           break;
  //         }
  //       }
  //     }
  //     if (!daGiam) listDVKhongGiam.push(item);
  //   }

  //   const tempArr = [...listDVGiam, ...listDVKhongGiam];
  //   if (listDVGiam.length > 0) {
  //     dispatch({
  //       type: typeAction[`DISPATCH_SET_ARR_${discountType}_CASHIER`],
  //       payload: tempArr,
  //     });
  //   }

  //   tongTG += tempArr.reduce(
  //     (tongtg, item) =>
  //       tongtg + ((item?.trangthaitt ?? item?.idtrangthaitt) !== 2 && item?.tiengiamgia),
  //     0
  //   );
  //   tongTT += tempArr.reduce(
  //     (tongtt, item) =>
  //       tongtt + ((item?.trangthaitt ?? item?.idtrangthaitt) !== 2 && item?.tienthanhtoan),
  //     0
  //   );

  //   return { tongTG, tongTT };
  // };

  // const applyCTGiamGia = () => {
  //   let tongTG = 0;
  //   let tongTT = 0;

  //   if (ArrKB?.length > 0) {
  //     const { tongTG: tgKB, tongTT: ttKB } = processServiceItems(ArrKB, 1);
  //     tongTG += tgKB;
  //     tongTT += ttKB;
  //   }

  //   if (ArrXN?.length > 0) {
  //     const { tongTG: tgXN, tongTT: ttXN } = processServiceItems(ArrXN, 2);
  //     tongTG += tgXN;
  //     tongTT += ttXN;
  //   }

  //   if (ArrCĐHA?.length > 0) {
  //     const { tongTG: tgCĐHA, tongTT: ttCĐHA } = processServiceItems(ArrCĐHA, 3);
  //     tongTG += tgCĐHA;
  //     tongTT += ttCĐHA;
  //   }

  //   dispatch({
  //     type: typeAction.DISPATCH_LOAD_TONG_GIAM_CASHIER,
  //     payload: tongTG,
  //   });
  //   dispatch({
  //     type: typeAction.DISPATCH_LOAD_TONG_THANH_TOAN_CASHIER,
  //     payload: tongTT,
  //   });
  // };

  const applyCTGiamGia = () => {
    // setCTGiamGiaApply(infoBNcuaPT?.chuongtrinhgiamgia ?? null)
    let listDVGiam = [],
      listDVKhongGiam = [],
      ptgiamNew = 0,
      daGiam = false,
      tongTG = 0,
      tongTT = 0,
      tempArr = []
    //////////////////////////////////////////////////////
    if (ArrKB?.length > 0) {
      listDVGiam = []
      listDVKhongGiam = []
      for (const itemKB of ArrKB) {
        daGiam = false
        if ((itemKB?.trangthaitt ?? itemKB?.idtrangthaitt) !== 2 && itemKB?.ptgiamgia === 0) {
          for (const itemCT of listCTGiamGia) {
            if (
              (itemCT?.IDTTDT === 0 ||
                (itemCT?.IDTTDT === 1 &&
                  InfoBN &&
                  itemCT?.DanhSachDoiTuong?.find(
                    (itemDT) => itemDT?.iddt === InfoBN?.idDoiTuong,
                  ))) &&
              itemCT?.DanhSachChiDinh?.find(
                (itemCD) => itemCD?.loaivoucherdichvu === 1 && itemCD?.iddv === itemKB?.iddichvu,
              )
            ) {
              daGiam = true
              //ptgiamNew = (itemKB?.ptgiamgia ?? 0) + (itemCT?.GIATRI ?? 0)
              listDVGiam.push({
                ...itemKB,
                ptgiamgia: itemCT?.GIATRI,
                tiengiamgia: (itemKB?.dongia * itemCT?.GIATRI) / 100,
                tienthanhtoan: (itemKB?.dongia * (100 - itemCT?.GIATRI)) / 100,
                ctgg: itemCT,
              })
              setMaCTGGApply(itemCT?.MACT)
              // setCTGiamGiaApply((prev) => {
              //   if (!prev || prev?.trim().length === 0) return itemCT?.MACT
              //   else return `${prev}, ${itemCT?.MACT}`
              // })
              break
            }
          }
        }
        if (!daGiam) listDVKhongGiam.push(itemKB)
      }
      tempArr = [...listDVGiam, ...listDVKhongGiam]
      if (listDVGiam?.length > 0) {
        dispatch({
          type: typeAction.DISPATCH_SET_ARR_KB_CASHIER,
          payload: tempArr,
        })
      }

      tongTG += tempArr?.reduce(
        (tongtg, item) =>
          tongtg + ((item?.trangthaitt ?? item?.idtrangthaitt) !== 2 && item?.tiengiamgia),
        0,
      )
      tongTT += tempArr?.reduce(
        (tongtt, item) =>
          tongtt + ((item?.trangthaitt ?? item?.idtrangthaitt) !== 2 && item?.tienthanhtoan),
        0,
      )
    }
    //////////////////////////////////////////////////////
    if (ArrXN?.length > 0) {
      listDVGiam = []
      listDVKhongGiam = []
      for (const itemXB of ArrXN) {
        daGiam = false
        if ((itemXB?.trangthaitt ?? itemXB?.idtrangthaitt) !== 2 && itemXB?.ptgiamgia === 0) {
          for (const itemCT of listCTGiamGia) {
            if (
              (itemCT?.IDTTDT === 0 ||
                (InfoBN &&
                  itemCT?.IDTTDT === 1 &&
                  itemCT?.DanhSachDoiTuong?.find(
                    (itemDT) => itemDT?.iddt === InfoBN?.idDoiTuong,
                  ))) &&
              itemCT?.DanhSachChiDinh?.find(
                (itemCD) => itemCD?.loaivoucherdichvu === 2 && itemCD?.iddv === itemXB?.idxn,
              )
            ) {
              daGiam = true
              //ptgiamNew = (itemXB?.ptgiamgia ?? 0) + (itemCT?.GIATRI ?? 0)
              listDVGiam.push({
                ...itemXB,
                ptgiamgia: itemCT?.GIATRI,
                tiengiamgia: (itemXB?.dongia * itemCT?.GIATRI) / 100,
                tienthanhtoan: (itemXB?.dongia * (100 - itemCT?.GIATRI)) / 100,
                ctgg: itemCT,
              })
              setMaCTGGApply(itemCT?.MACT)
              break
            }
          }
        }
        if (!daGiam) listDVKhongGiam.push(itemXB)
      }
      tempArr = [...listDVGiam, ...listDVKhongGiam]
      if (listDVGiam?.length > 0) {
        dispatch({
          type: typeAction.DISPATCH_SET_ARR_XN_CASHIER,
          payload: tempArr,
        })
      }

      tongTG += tempArr?.reduce(
        (tongtg, item) =>
          tongtg + ((item?.trangthaitt ?? item?.idtrangthaitt) !== 2 && item?.tiengiamgia),
        0,
      )
      tongTT += tempArr?.reduce(
        (tongtt, item) =>
          tongtt + ((item?.trangthaitt ?? item?.idtrangthaitt) !== 2 && item?.tienthanhtoan),
        0,
      )
    }
    //////////////////////////////////////////////////////
    if (ArrCĐHA?.length > 0) {
      listDVGiam = []
      listDVKhongGiam = []
      for (const itemCDHA of ArrCĐHA) {
        daGiam = false
        if ((itemCDHA?.trangthaitt ?? itemCDHA?.idtrangthaitt) !== 2 && itemCDHA?.ptgiamgia === 0) {
          for (const itemCT of listCTGiamGia) {
            if (
              (itemCT?.IDTTDT === 0 ||
                (InfoBN &&
                  itemCT?.IDTTDT === 1 &&
                  itemCT?.DanhSachDoiTuong?.find(
                    (itemDT) => itemDT?.iddt === InfoBN?.idDoiTuong,
                  ))) &&
              itemCT?.DanhSachChiDinh?.find(
                (itemCD) => itemCD?.loaivoucherdichvu === 3 && itemCD?.iddv === itemCDHA?.idcdha,
              )
            ) {
              daGiam = true
              //ptgiamNew = (itemCDHA?.ptgiamgia ?? 0) + (itemCT?.GIATRI ?? 0)
              listDVGiam.push({
                ...itemCDHA,
                ptgiamgia: itemCT?.GIATRI,
                tiengiamgia: (itemCDHA?.dongia * itemCT?.GIATRI) / 100,
                tienthanhtoan: (itemCDHA?.dongia * (100 - itemCT?.GIATRI)) / 100,
                ctgg: itemCT,
              })
              setMaCTGGApply(itemCT?.MACT)
              break
            }
          }
        }
        if (!daGiam) listDVKhongGiam.push(itemCDHA)
      }
      tempArr = [...listDVGiam, ...listDVKhongGiam]
      if (listDVGiam?.length > 0) {
        dispatch({
          type: typeAction.DISPATCH_SET_ARR_CDHA_CASHIER,
          payload: tempArr,
        })
      }

      tongTG += tempArr?.reduce(
        (tongtg, item) =>
          tongtg + ((item?.trangthaitt ?? item?.idtrangthaitt) !== 2 && item?.tiengiamgia),
        0,
      )
      tongTT += tempArr?.reduce(
        (tongtt, item) =>
          tongtt + ((item?.trangthaitt ?? item?.idtrangthaitt) !== 2 && item?.tienthanhtoan),
        0,
      )
    }
    /////////
    dispatch({
      type: typeAction.DISPATCH_LOAD_TONG_GIAM_CASHIER,
      payload: tongTG,
    })
    dispatch({
      type: typeAction.DISPATCH_LOAD_TONG_THANH_TOAN_CASHIER,
      payload: tongTT,
    })

    // setCTGiamGiaApply(
    //   `${CTGiamGiaApply}${infoBNcuaPT?.chuongtrinhgiamgia ? ', ' + infoBNcuaPT?.chuongtrinhgiamgia : ''}`,
    // )
  }
  const setMaCTGGApply = (mact) => {
    setCTGiamGiaApply((prev) => {
      if (!maCTGGdangApply.includes(mact)) {
        if (!prev || prev?.trim()?.length === 0) return mact
        else if (!prev.includes(mact)) return `${prev},${mact}`
      }
      return prev
    })
  }
  // update service bênh nhân
  const handleUpdateService = () => {
    dispatch(updateServiceBN(isDelete, infoBNcuaPT?.idbntt, infoUser?.idnv, resetCheckDelete))
  }
  const resetCheckDelete = () => {
    setIsDelete({ DV: [], XN: [], CDHA: [] })
  }
  const checkDelete = [...isDelete.XN, ...isDelete.CDHA, ...isDelete.DV].length > 0
  useEffect(() => {
    setCTGiamGiaApply(null)
  }, [InfoBN])
  useEffect(() => {
    if (listCTGiamGia?.length > 0) {
      //setCTGiamGiaApply(null)
      applyCTGiamGia()
    }
  }, [ArrKB?.length, ArrXN?.length, ArrCĐHA?.length, infoBNcuaPT]) // ArrKB?.length, ArrXN?.length, ArrCĐHA?.length

  return (
    <>
      <div className="flex h-full gap-3">
        <div className=" flex h-full flex-col gap-5 w-3/4">
          <div className="h-2/3 border rounded-md">
            <Divider style={{ margin: 0 }} orientation="left">
              Thông tin
            </Divider>
            <div className="p-2">
              <div className="flex flex-col gap-2">
                <ul className="flex justify-between">
                  <li className="font-semibold w-1/2 text-end gap-1 flex">
                    {' '}
                    <span className="w-20 lg:w-24">Tên BN:</span>
                    <Input
                      className="font-normal"
                      size="small"
                      variant="filled"
                      readOnly
                      value={infoBNcuaPT?.tenbenhnhan}
                    />
                  </li>
                  <li className="font-semibold gap-1 text-end flex w-1/2">
                    <div className="flex w-1/2 gap-1">
                      <span className="  w-52">Mã BN:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={infoBNcuaPT?.mabenhnhan}
                      />
                    </div>
                    <div className="flex w-1/2 gap-1">
                      <span className="w-52">Phiếu thu:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={infoBNcuaPT?.phieuthu}
                      />
                    </div>
                  </li>
                </ul>
                <ul className="flex justify-between">
                  <li className="font-semibold w-1/2 text-end gap-1 flex">
                    {' '}
                    <span className="w-20 lg:w-24">Địa chỉ:</span>
                    <Input
                      className="font-normal"
                      size="small"
                      variant="filled"
                      readOnly
                      value={infoBNcuaPT?.diachi}
                    />
                  </li>
                  <li className="font-semibold gap-1 text-end flex w-1/2">
                    <div className="flex w-1/2 gap-1">
                      <span className="w-52 ">Ngày sinh:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={
                          infoBNcuaPT?.ngaysinh &&
                          moment(infoBNcuaPT?.ngaysinh).format('DD/MM/YYYY')
                        }
                      />
                    </div>
                    <div className="flex w-1/2 gap-1">
                      <span className="w-52">Ngày thu: </span>
                      <Input
                        size="small"
                        variant="filled"
                        className="font-normal"
                        readOnly
                        value={
                          infoBNcuaPT?.ngaythu &&
                          moment(infoBNcuaPT?.ngaythu).format('DD/MM/YYYY HH:mm:ss')
                        }
                      />
                    </div>
                  </li>
                </ul>
                <ul className="flex justify-between">
                  <li className="font-semibold gap-1 text-end flex w-1/2">
                    <div className="flex w-1/2 gap-1">
                      <span className="w-24 lg:w-28 ">Đối tượng:</span>
                      <Input
                        size="small"
                        variant="filled"
                        className="font-normal"
                        readOnly
                        value={infoBNcuaPT?.doituong}
                      />
                    </div>
                    <div className="flex w-1/2 gap-1 ">
                      <span className="w-24 ">Giới tính:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={infoBNcuaPT?.gioitinh}
                      />
                    </div>
                  </li>
                  <li className="font-semibold gap-1 text-end flex w-1/2">
                    <div className="flex w-1/2 gap-1">
                      <span className="w-52 ">Mã TCQG:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={infoBNcuaPT?.matcqg}
                      />
                    </div>
                    <div className="flex w-1/2 gap-1">
                      <span className="w-52">Người thân:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={infoBNcuaPT?.nguoithan}
                      />
                    </div>
                  </li>
                </ul>
                <ul className="flex justify-between">
                  <li className="font-semibold gap-1 text-end flex w-1/2">
                    <div className="flex w-1/2 gap-1">
                      <span className="w-28 lg:w-32">Số TTV:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={memberCard?.mathe}
                      />
                      <Tooltip title="Tạo thẻ" color="green">
                        <button
                          onClick={() => setOpen(true)}
                          className="text-green-600 bg-green-100 rounded-md hover:text-white hover:bg-green-500 duration-200"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6 "
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 4.5v15m7.5-7.5h-15"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                    </div>
                    <div className="flex w-1/2 gap-1 ">
                      <span className="w-24 ">Loại thẻ:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={memberCard?.tenloaithe}
                      />
                    </div>
                  </li>
                  <li className="font-semibold gap-1 text-end flex w-1/2">
                    <div className="flex w-1/2 gap-1">
                      <span className="w-52 ">Ngày hiệu lực:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={
                          memberCard?.tungay ? moment(memberCard?.tungay).format('DD/MM/YYYY') : ''
                        }
                      />
                    </div>
                    <div className="flex w-1/2 gap-1">
                      <span className="w-52">Ngày hết hạn:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={
                          memberCard?.denngay
                            ? moment(memberCard?.denngay).format('DD/MM/YYYY')
                            : ''
                        }
                      />
                    </div>
                  </li>
                </ul>
                <ul className="flex justify-between">
                  <li className="font-semibold gap-1 text-end flex w-1/2">
                    <div className="flex w-1/2 gap-1">
                      <span className="w-24 lg:w-28">Nhân viên:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={infoBNcuaPT?.nhanvien}
                      />
                    </div>
                    <div className="flex w-1/2 gap-1 ">
                      <span className="w-24 ">Bác sĩ:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={infoBNcuaPT?.bacsi}
                      />
                    </div>
                  </li>
                  <li className="font-semibold gap-1 text-end flex w-1/2">
                    <div className="flex w-1/2 gap-1">
                      <span className="w-52">STT:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={infoBNcuaPT?.stt}
                      />
                    </div>
                    <div className="flex w-1/2 gap-1">
                      <span className="w-52">Phòng khám:</span>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={infoBNcuaPT?.phongkham}
                      />
                    </div>
                  </li>
                </ul>
                <ul className="flex justify-between">
                  <li className="font-semibold gap-1 text-end flex w-1/2">
                    <div className="flex w-full gap-1">
                      <div className="w-[15.1%]">Loại khám:</div>
                      <Input
                        className="font-normal"
                        size="small"
                        variant="filled"
                        readOnly
                        value={infoBNcuaPT?.loaikham}
                      />
                      {/* <Select
                        className="w-full text-start"
                        size="small"
                        defaultValue="bm"
                        options={[
                          {
                            label: 'Bệnh mới',
                            value: 'bm',
                          },
                          {
                            label: 'Tái khám',
                            value: 'tk',
                          },
                        ]}
                      /> */}
                    </div>
                  </li>
                  <li className=" text-end flex w-1/2">
                    <div className="flex w-1/2 ">
                      <div className="w-2/5 pr-1">Nguồn khách:</div>
                      <Input
                        className="w-3/5 "
                        size="small"
                        variant="filled"
                        readOnly
                        value={nguon?.find((i) => i.idNguonKH === BNTTC?.idnguon)?.nguon || ''}
                      />
                    </div>
                    <div className="flex w-1/2">
                      <div className="w-2/5 pr-1">Phương thức:</div>
                      <Input
                        className="w-3/5 "
                        size="small"
                        variant="filled"
                        readOnly
                        value={DetailTT?.idphuongthuc !== 1 ? 'Công nợ' : 'Thanh toán'}
                      />
                    </div>
                  </li>
                </ul>
                <ul className="flex justify-between">
                  <li className="gap-1 text-end flex w-1/2">
                    <div className="flex w-full gap-1">
                      <span className="w-[14.8%] font-semibold">Ghi chú:</span>
                      <Input.TextArea
                        autoSize={{
                          minRows: 1,
                          maxRows: 2,
                        }}
                        size={'small'}
                      />
                    </div>
                  </li>
                  <li className="gap-1 text-end flex w-1/2">
                    <div className="flex w-1/2 gap-1">
                      <span className="w-[13rem] font-semibold">Combo:</span>
                      <Input
                        size={'small'}
                        variant="filled"
                        readOnly
                        value={infoBNcuaPT?.chuongtrinhcombo}
                      />
                    </div>
                    <div className="flex w-1/2 gap-1">
                      <span className="w-[13rem] font-semibold">CT giảm giá:</span>
                      <Input size={'small'} variant="filled" readOnly value={maCTGGdangApply} />
                    </div>
                    {/* {(infoBNcuaPT?.chuongtrinhcombo) && (
                      <>
                      </>
                    )}
                    {(CTGiamGiaApply || infoBNcuaPT?.chuongtrinhgiamgia) && (
                      <>
                      </>
                    )} */}
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <Divider style={{ margin: 0 }} orientation="left">
                Chỉ định
              </Divider>
              <TableChiDinh
                setIsDelete={setIsDelete}
                CTGiamGiaApply={CTGiamGiaApply}
                setCTGiamGiaApply={setCTGiamGiaApply}
              />
              <div className="grid grid-cols-4 mt-2 pl-2 pb-3">
                <div className="col-span-3 grid grid-rows-3 gap-1">
                  <div className="flex justify-start items-center">
                    <label className="text-sm font-semibold text-end w-[8.5%] flex items-center justify-end">
                      Voucher:
                    </label>
                    <Input
                      size="small"
                      className="w-[24.2%] ml-1"
                      value={infoBNcuaPT?.mavoucher ?? maVoucher}
                      disabled={!infoBNcuaPT || infoBNcuaPT?.mavoucher || discountArr.length}
                      style={{ color: 'black' }}
                      onChange={(e) => setMaVoucher(e.target.value)}
                    />
                    <ul className="flex justify-around ml-10">
                      {maVoucher &&
                      infoBNcuaPT &&
                      newArr.length &&
                      tongThanhToan > 0 &&
                      !discountArr.length ? (
                        <li
                          onClick={handleApplyVoucher}
                          className="bg-green-200 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                        >
                          <CheckOutlined />
                        </li>
                      ) : null}
                      {discountArr.length ? (
                        <li
                          onClick={handleUnApplyVoucher}
                          className="bg-red-200 text-red-700 hover:bg-red-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                        >
                          <CloseOutlined />
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  {infoBNcuaPT?.mavoucherdoitac?.trim().length > 0 ? (
                    <>
                      <div className="flex justify-start items-center">
                        <label className="text-sm font-semibold text-end w-[8.5%] flex items-center justify-end">
                          Đối tác:
                        </label>
                        <Input
                          size="small"
                          className="w-[24.2%] ml-1"
                          value={infoBNcuaPT?.mavoucherdoitac ?? maVoucherDoiTac}
                          disabled={
                            !infoBNcuaPT ||
                            infoBNcuaPT?.mavoucherdoitac ||
                            infoChuongTrinhGiamGiaDoiTac
                          }
                          style={{ color: 'black' }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-start items-center">
                        <label className="text-sm font-semibold text-end pl-[3.3%] mr-1 flex items-center">
                          Đối tác:
                        </label>
                        <Checkbox checked={isDoiTac} onChange={handleChangeDoiTac} />
                        {isDoiTac ? (
                          <>
                            <Select
                              disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac}
                              style={{ color: 'black' }}
                              className="w-[22%] ml-1"
                              size="small"
                              value={valueChuongTrinhDoiTac}
                              options={listVoucher?.map((items) => ({
                                label: items.tenct,
                                value: items.idct,
                              }))}
                              onChange={(value) => setValueChuongTrinhDoiTac(value)}
                            />
                            <label className="text-sm font-semibold w-[75px] text-end">Giá:</label>
                            <Input
                              disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac}
                              style={{ color: 'black' }}
                              type="number"
                              step={1}
                              min={0}
                              size="small"
                              value={valueGiaVoucherDoiTac}
                              className="w-[14.5rem] ml-1 text-end"
                              // className="w-[24.2%] ml-1 text-end"
                              // value={maVoucher}
                              onChange={(e) =>
                                setValueGiaVoucherDoiTac(
                                  Number(e.target.value ?? 0) > Number(tongThanhToan)
                                    ? Number(tongThanhToan)
                                    : Number(e.target.value ?? 0),
                                )
                              }
                            />
                          </>
                        ) : null}
                      </div>
                      <div className="flex justify-start items-center">
                        {isDoiTac ? (
                          <>
                            <label className="text-sm font-semibold text-end w-[8.5%] flex items-center justify-end">
                              VC đối tác:
                            </label>
                            <Input
                              disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac}
                              style={{ color: 'black' }}
                              size="small"
                              //className="w-[21.3%] ml-1"
                              className="w-[24.2%] ml-1"
                              value={maVoucherDoiTac}
                              onChange={(e) => setMaVoucherDoiTac(e.target.value)}
                            />
                            <ul className="flex justify-around ml-10">
                              {Number(valueGiaVoucherDoiTac) > 0 &&
                              newArr?.length > 0 &&
                              tongThanhToan > 0 &&
                              infoBNcuaPT &&
                              !infoChuongTrinhGiamGiaDoiTac ? (
                                <li
                                  onClick={handleApplyVoucherDoiTac}
                                  className=" bg-green-200 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                                >
                                  <CheckOutlined />
                                </li>
                              ) : null}
                              {infoChuongTrinhGiamGiaDoiTac ? (
                                <li
                                  onClick={handleUnApplyVoucherDoiTac}
                                  className=" bg-red-200 text-red-700 hover:bg-red-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                                >
                                  <CloseOutlined />
                                </li>
                              ) : null}
                            </ul>
                          </>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
                <div className="items-start pr-3">
                  <div className="flex items-center  text-red-500 text-xl font-semibold justify-between">
                    <span className="mr-10">Tiền giảm:</span>
                    <span className="">
                      -
                      {formatNumberVND(
                        Number(tongGiam) + Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
                      )}{' '}
                      VNĐ
                    </span>
                  </div>
                  <div className="flex items-center text-green-700 text-xl font-semibold justify-between">
                    <span className="mr-10">Thanh toán:</span>
                    <span className="">
                      {formatNumberVND(
                        Number(tongThanhToan) -
                          Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
                      )}{' '}
                      VNĐ
                    </span>
                  </div>
                </div>

                {/* {infoChuongTrinhGiamGiaDoiTac && (
                  <div className="flex items-center ml-auto mr-4">
                    <span className="text-red-500 text-xl font-semibold mr-10">
                      Voucher đối tác:
                    </span>
                    <span className="text-red-500 text-xl font-semibold">
                      -{' '}
                      {formatNumberVND(
                        infoChuongTrinhGiamGiaDoiTac ? infoChuongTrinhGiamGiaDoiTac?.giaVoucher : 0,
                      )}{' '}
                      VNĐ
                    </span>
                  </div>
                )} */}
              </div>
              <div className="flex items-center gap-2 justify-end p-2">
                {checkDelete && (
                  <Button
                    disabled={!infoBNcuaPT}
                    className="w-1/10"
                    onClick={handleUpdateService}
                    variant="contained"
                    size="small"
                    color="primary"
                  >
                    Cập nhật
                  </Button>
                )}
                <Button
                  disabled={!InfoBN}
                  onClick={() => handlePrint(DetailTT, InfoBN, BNTTC)}
                  variant="outlined"
                  size="small"
                  startIcon={<PrinterOutlined />}
                >
                  In
                </Button>
                <Button
                  onClick={calculatorPriceServiveByTTV}
                  variant="contained"
                  className="flex items-center"
                  size="small"
                  startIcon={<CalculatorOutlined />}
                >
                  Tính lại
                </Button>
                <Button
                  disabled={
                    !infoBNcuaPT ||
                    checkDelete ||
                    newArr?.findIndex(
                      (item) => (item?.trangthaitt ?? item?.idtrangthaitt) !== 2,
                    ) === -1
                  }
                  className="w-1/10"
                  onClick={() => {
                    handlePay()
                  }}
                  variant="contained"
                  size="small"
                  color="success"
                >
                  Thanh toán
                </Button>
              </div>
              {/* <ul className="flex justify-between">
                <div className="flex gap-5 items-center w-full">
                  <div className="flex w-full gap-1 items-center">
                    <label className="text-sm w-[80px] font-semibold text-end">Đối tác:</label>
                    <Checkbox
                      checked={isDoiTac}
                      onChange={handleChangeDoiTac}
                    />
                    {isDoiTac ? (
                      <Select
                        disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac}
                        style={{ color: 'black' }}
                        className="w-full"
                        size="small"
                        value={valueChuongTrinhDoiTac}
                        options={listVoucher?.map((items) => ({
                          label: items.tenct,
                          value: items.idct,
                        }))}
                        onChange={(value) => setValueChuongTrinhDoiTac(value)}
                      />
                    ) : null}
                  </div>

                  <div className="flex w-full gap-1 items-center">
                    {isDoiTac ? (
                      <>
                        <label className="text-sm font-semibold text-end">Giá:</label>
                        <Input
                          disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac}
                          style={{ color: 'black' }}
                          type="number"
                          step={1}
                          min={0}
                          size="small"
                          value={valueGiaVoucherDoiTac}
                          className="w-[120px] text-end"
                          // value={maVoucher}
                          onChange={(e) => setValueGiaVoucherDoiTac(e.target.value)}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </ul>
              <ul className="flex justify-between w-full">
                {isDoiTac && (
                  <div className="flex w-full gap-1 items-center">
                    <div className="flex w-1/3 gap-1">
                      <label className="text-sm font-semibold w-[160px] text-end">
                        Voucher đối tác:
                      </label>
                      <Input
                        disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac}
                        style={{ color: 'black' }}
                        size="small"
                        className="w-full"
                        value={maVoucherDoiTac}
                        onChange={(e) => setMaVoucherDoiTac(e.target.value)}
                      />

                    </div>
                  </div>
                )}
              </ul> */}

              {/* <ul className="flex justify-between">
                <li className="font-semibold text-end flex w-1/2">
                  <div className="flex w-full gap-1 items-center">

                  </div>
                  <ul className="flex justify-around">
                    {maVoucher && infoBNcuaPT && newArr.length && tongThanhToan > 0 && !discountArr.length ? (
                      <li
                        onClick={handleApplyVoucher}
                        className="bg-green-200 ml-4 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                      >
                        <CheckOutlined />
                      </li>
                    ) : null}
                    {discountArr.length ? (
                      <li
                        onClick={handleUnApplyVoucher}
                        className="bg-red-200 ml-4 text-red-700 hover:bg-red-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                      >
                        <CloseOutlined />
                      </li>
                    ) : null}
                  </ul>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="h-auto border overflow-hidden rounded-md">
            <ServiceKham checkDelete={checkDelete} />
          </div>
        </div>
        <div className="w-1/4 h-full flex flex-col gap-2 ">
          <div className=" h-96 border overflow-hidden rounded-md">
            <TabPT
              resetFormThanhToan={resetFormThanhToan}
              setIsDelete={setIsDelete}
              BNChuaThu={BNChuaThu}
            />
          </div>
          <div className="h-96 overflow-hidden border rounded-md">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                  },
                },
              }}
            >
              <Table
                scroll={{ y: 312 }}
                bordered
                pagination={false}
                dataSource={phieuDaThu}
                title={() => (
                  <Divider style={{ margin: 0 }} orientation="left" onClick={fetchPhieuThu}>
                    Phiếu đã thu
                  </Divider>
                )}
                columns={columnsCT}
                onRow={(record) => ({
                  onClick: () => onClickDetailBN(record),
                })}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      <ModalPay
        form={form}
        setForm={setForm}
        isModalPay={isModalPay}
        tongThanhToan={tongThanhToan}
        setIsModalPay={setIsModalPay}
        setIsPrint={setIsPrint}
        resetFormThanhToan={resetFormThanhToan}
        maCTGGdangApply={maCTGGdangApply}
      />
      <ModalAdd isModalOpen={open} setIsModalOpen={setOpen} />
    </>
  )
}

export default InfoPhieu
