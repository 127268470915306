import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Space,
  Table,
  Tabs,
  Tooltip,
  TimePicker,
} from 'antd'
import Highlighter from 'react-highlight-words'
import { https } from '../../../../services/apiService'
import { cardService } from '../../../../services/card/cardService'
import { cloneDeep, debounce } from 'lodash'
import { DeleteOutlined, PlusOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import ToastCus from '../../../../utils/Toast'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
moment.locale('vi')

function TabKhungGio({ dataKhungGio, setDataKhungGio }) {
  const formatTime = 'HH:mm'
  // const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  // const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  // const [listKhungGio, setListKhungGio] = useState([])
  // const [isLoading, setIsLoading] = useState(false)
  const [tuGio, setTuGio] = useState(moment().format(formatTime))
  const [denGio, setDenGio] = useState(moment().format(formatTime))

  // const searchInput = useRef(null)
  // const [searchText, setSearchText] = useState('')
  // const [searchedColumn, setSearchedColumn] = useState('')
  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm()
  //   setSearchText(selectedKeys[0])
  //   setSearchedColumn(dataIndex)
  // }
  // const handleReset = (clearFilters) => {
  //   clearFilters()
  //   setSearchText('')
  // }
  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
  //     <div
  //       style={{
  //         padding: 8,
  //       }}
  //       onKeyDown={(e) => e.stopPropagation()}
  //     >
  //       <Input
  //         ref={searchInput}
  //         placeholder={'Nhập tìm kiếm'}
  //         value={selectedKeys[0]}
  //         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{
  //           marginBottom: 8,
  //           display: 'block',
  //         }}
  //         className="rounded-md"
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{
  //             width: 90,
  //             backgroundColor: '#1677ff',
  //           }}
  //           className="rounded-md"
  //         >
  //           Tìm
  //         </Button>
  //         <Button
  //           onClick={() => {
  //             clearFilters && handleReset(clearFilters)
  //             handleSearch('', confirm, dataIndex)
  //           }}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //           className="rounded-md"
  //         >
  //           Xoá
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined
  //       style={{
  //         color: filtered ? '#1677ff' : undefined,
  //       }}
  //     />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownOpenChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.current?.select(), 100)
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{
  //           backgroundColor: '#ffc069',
  //           padding: 0,
  //         }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ''}
  //       />
  //     ) : (
  //       text
  //     ),
  // })

  const handleAddKhungGio = () => {
    if (!tuGio || !denGio) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    setDataKhungGio((prev) => [
      ...prev,
      {
        tugio: tuGio,
        dengio: denGio,
        nguoitao: infoUser?.tenNV,
        ngaytao: moment().format('YYYY-MM-DDTHH:mm:ss'),
      },
    ])
    setTuGio(null)
    setDenGio(null)
  }
  const handleRemoveKhungGio = (idx) => {
    setDataKhungGio((prev) => [...prev?.filter((item, index) => index !== idx)])
  }
  // useEffect(() => {
  //   ;(async () => {
  //     try {
  //       setIsLoading(true)
  //       const { data } = await https.get(`VoucherKhungGio/GetKhungGioVoucher`, {
  //         params: { idct: ID_COMPANY },
  //       })
  //       setListKhungGio(data)
  //     } catch (error) {
  //       console.log(error)
  //     } finally {
  //       setIsLoading(false)
  //     }
  //   })()
  // }, [])
  return (
    <div>
      <div className="overflow-auto h-[529px]">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            scroll={{ y: 250 }}
            bordered
            pagination={false}
            dataSource={[
              ...dataKhungGio,
              {
                add: 1,
              },
            ]}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                render: (text, record, index) => (record?.add ? '' : ++index),
              },
              {
                title: 'Giờ bắt đầu',
                dataIndex: 'tugio',
                key: 'tugio',
                width: 100,
                align: 'center',
                render: (text, record, index) =>
                  record?.add ? (
                    <>
                      <TimePicker
                        placeholder=" "
                        size="small"
                        format={formatTime}
                        value={tuGio && dayjs(tuGio, formatTime)}
                        onChange={(time, timeString) => setTuGio(timeString)}
                        needConfirm={false}
                      />
                    </>
                  ) : (
                    text
                  ),
              },
              {
                title: 'Giờ kết thúc',
                dataIndex: 'dengio',
                key: 'dengio',
                width: 100,
                align: 'center',
                render: (text, record, index) =>
                  record?.add ? (
                    <>
                      <TimePicker
                        placeholder=" "
                        size="small"
                        format={formatTime}
                        value={denGio && dayjs(denGio, formatTime)}
                        onChange={(time, timeString) => setDenGio(timeString)}
                        needConfirm={false}
                      />
                    </>
                  ) : (
                    text
                  ),
              },
              {
                title: 'Người tạo',
                dataIndex: 'nguoitao',
                key: 'nguoitao',
              },
              {
                title: 'Ngày tạo',
                dataIndex: 'ngaytao',
                key: 'ngaytao',
                width: 200,
                align: 'center',
                render: (text, record, index) =>
                  text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-',
              },
              {
                title: '',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: 40,
                render: (text, record, index) => (
                  <>
                    {record?.add ? (
                      <>
                        <PlusOutlined
                          className="text-lg text-blue-500 cursor-pointer flex justify-center items-center"
                          onClick={() => handleAddKhungGio(record)}
                        />
                      </>
                    ) : (
                      <>
                        <CloseOutlined
                          className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                          onClick={() => handleRemoveKhungGio(index)}
                        />
                      </>
                    )}
                  </>
                ),
              },
            ]}
          />
        </ConfigProvider>
      </div>
      {/* <div>
        <div className="overflow-auto h-[264px]">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              scroll={{ y: 180 }}
              pagination={{
                pageSize: 5,
                showSizeChanger: false,
              }}
              loading={isLoading}
              bordered
              dataSource={listKhungGio}
              columns={[
                {
                  title: 'Mã hàng',
                  dataIndex: 'mathuoc',
                  key: 'mathuoc',
                  width: 120,
                  ...getColumnSearchProps('mathuoc'),
                },
                {
                  title: 'Tên vật tư hàng hóa',
                  dataIndex: 'tenbietduoc',
                  key: 'tenbietduoc',
                  ...getColumnSearchProps('tenbietduoc'),
                },
                // {
                //   title: 'Nhóm hàng',
                //   dataIndex: '',
                //   key: '',
                //   width: 200,
                // },
                {
                  title: 'ĐVT',
                  dataIndex: 'dvt',
                  key: 'dvt',
                  align: 'center',
                  width: 75,
                },
                {
                  title: 'Đơn giá',
                  dataIndex: 'giaban',
                  key: 'giaban',
                  align: 'right',
                  width: 100,
                  render: (text, record, index) => formatNumberVND(text ?? 0),
                },
                {
                  title: '',
                  dataIndex: 'action',
                  key: 'action',
                  align: 'center',
                  width: 40,
                  render: (text, record, index) => (
                    <>
                      <PlusOutlined
                        className="text-lg text-blue-500 cursor-pointer flex justify-center items-center"
                        onClick={() => handleAddKhungGio(record)}
                      />
                    </>
                  ),
                },
              ]}
            />
          </ConfigProvider>
        </div>
      </div> */}
    </div>
  )
}

export default TabKhungGio
