import React, { useEffect, useState, useRef, useCallback } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import _ from 'lodash'
import { ConfigProvider, Select, Table, Checkbox, Input, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Button, IconButton } from '@mui/material'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import Highlighter from 'react-highlight-words'
import Pagination from '@mui/material/Pagination'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getListThuocVTByPageIndex_Action,
  listAllChuyeKhoa_Action,
  listBranchByThuocVT,
  listGroupsVTBranchBuy_action,
  listTypesThuocVT_Action,
  updateBranchBuyTV_Action,
} from '../../../store/actions/thuocVTAction'
import { listAllCompanyAction } from '../../../store/actions/companyAction'

const BranchBuy = () => {
  const dispatch = useDispatch()
  const [types, setTypes] = useState(null)
  const [nameVT, setNameVT] = useState(null)
  const id_company = Number(localStorage.getItem('id_company'))
  const [company, setCompany] = useState(id_company)
  const [valueGroups, SetValueGroups] = useState(null)
  const [idThuoc, setIdThuoc] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [listBranchBuy, setListBranchBuy] = useState(null)
  const [search, setSearch] = useState(null)
  const searchInput = useRef(null)
  const [valueChuyenKhoa, setValueChuyenKhoa] = useState('all')
  const [isLoadingBranch, setIsloadingBranch] = useState(false)
  const [ListUpdate, setListUpdate] = useState([])
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { listThuocVT, typesVT, branchBuy, groupsVTBranchBuy, chuyenKhoa } = useSelector(
    (state) => state.thuocVTReducer,
  )
  const handleBranchBuy = (record) => {
    setValueChuyenKhoa('all')
    setNameVT(record.tenBietDuoc)
    setListUpdate([])
    setIdThuoc(record.idThuoc)
    dispatch(listBranchByThuocVT(record.idThuoc, setIsloadingBranch))
  }
  const handleTypes = (idTypes) => {
    setTypes(idTypes)
    dispatch(listGroupsVTBranchBuy_action(idTypes))
    SetValueGroups(null)
  }
  const handleCompany = (value) => {
    setCompany(value)
  }
  const handleGroups = (idGroups) => {
    SetValueGroups(idGroups)
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword, company, types, valueGroups) => {
      dispatch(
        getListThuocVTByPageIndex_Action(
          keyword,
          company,
          types,
          valueGroups,
          useSearch.get('page'),
        ),
      )
    }, 300),
    [],
  )
  const handleSearchInput = (e) => {
    const { value } = e.target
    setSearch(value)
    SetSearchParams({ page: 1 })
    debounceDropDown(value, company, types, valueGroups)
  }
  const addListBranchUpdate = (id, status) => {
    const index = ListUpdate.findIndex((items) => items.idCNBan === id)
    // tìm inde trong danh sách update
    if (index === -1) {
      // nếu không có  => phần update mới
      setListUpdate([...ListUpdate, { idCNBan: id, checkBan: status }])
    } else {
      // nếu có  => phần này bỏ update
      const updatedList = [...ListUpdate]
      updatedList.splice(index, 1)
      setListUpdate(updatedList)
    }
  }
  const handleUpdate = () => {
    dispatch(updateBranchBuyTV_Action(ListUpdate, idThuoc, setIsloadingBranch))
    setListUpdate([])
  }
  const handleSelected = (value) => {
    if (value === 'all') {
      setListBranchBuy(branchBuy)
      setValueChuyenKhoa('all')
      return
    }
    const result = branchBuy?.filter((items) => items.tenChuyenKhoa === value)
    setListBranchBuy(result)
    setValueChuyenKhoa(value)
  }
  const handleCheckBox = (id, status) => {
    const updatedBranchBuy = listBranchBuy.map((item) => {
      // tìm chi  nhánh bán
      if (item.idcnban === id) {
        const newObj = { ...item, trangThaiBan: status === 1 ? 0 : 1 } // update lại trạng thái
        addListBranchUpdate(newObj.idcnban, newObj.trangThaiBan)
        return newObj
      }
      return item
    })
    // set lại mãng
    setListBranchBuy(updatedBranchBuy)
  }
  const refreshPage = () => {
    SetSearchParams({ page: 1 })
    dispatch(
      getListThuocVTByPageIndex_Action(search, company, types, valueGroups, useSearch.get('page')),
    )
  }
  useEffect(() => {
    dispatch(
      getListThuocVTByPageIndex_Action(search, company, types, valueGroups, useSearch.get('page')),
    )
  }, [useSearch.get('page')])
  useEffect(() => {
    dispatch(listTypesThuocVT_Action())
    dispatch(listAllChuyeKhoa_Action())
    dispatch(listAllCompanyAction())
  }, [])
  useEffect(() => {
    setListBranchBuy(branchBuy)
  }, [branchBuy])
  const columnsVT = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 40,
      align: 'center',
      key: 'stt',
    },
    {
      title: 'Mã thuốc',
      dataIndex: 'maThuoc',
      width: 120,
      align: 'center',
      key: 'maThuoc',
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenBietDuoc',
      key: 'tenBietDuoc',
    },
    {
      title: 'Hoạt chất',
      dataIndex: 'tenHoatChat',
      key: 'tenHoatChat',
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'dvt',
      width: 100,
      align: 'center',
      key: 'dvt',
    },
  ]
  const columnsCN = [
    {
      title: 'STT',
      dataIndex: 'STT',
      width: 40,
      align: 'center',
      key: 'STT',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      ...getColumnSearchProps('tenChiNhanh'),
    },
    {
      title: 'Trạng thái',
      //   dataIndex: "tenChiNhanh",
      width: 80,
      align: 'center',
      key: 'tenChiNhanh',
      render: (text, record, index) => (
        <Checkbox
          checked={record.trangThaiBan !== 0}
          onChange={() => {
            handleCheckBox(record.idcnban, record.trangThaiBan)
          }}
        />
      ),
    },
  ]
  return (
    <>
      <div className="flex gap-2 h-full p-5 bg-[#efefef]">
        <div className="w-2/3 h-full bg-white rounded-xl">
          <div className="p-2 min-h-16 flex items-center ">
            <ul className="flex gap-2 items-center">
              <li className="flex flex-col w-60">
                <Select
                  showSearch
                  className="w-full"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={handleCompany}
                  value={company}
                  options={listCompany?.map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  }))}
                />
              </li>
              <li className="flex flex-col w-52">
                <Select
                  onChange={handleTypes}
                  defaultValue={null}
                  className="w-full"
                  placeholder="Loại thuốc"
                  options={[
                    { label: 'Tất cả', value: null },
                    ...(typesVT
                      ? typesVT.map((items) => ({
                        label: items.phanLoai,
                        value: items.idPhanLoai,
                      }))
                      : []),
                  ]}
                />
              </li>
              <li className="w-60">
                <Select
                  value={valueGroups}
                  onChange={handleGroups}
                  className="w-full"
                  placeholder="Nhóm thuốc"
                  options={[
                    { label: 'Tất cả', value: null },
                    ...(groupsVTBranchBuy
                      ? groupsVTBranchBuy?.map((items) => ({
                        label: items.nhom,
                        value: items.idNhom,
                      }))
                      : []),
                  ]}
                />
              </li>
              <li className="w-60">
                <Input
                  value={search}
                  placeholder="Tìm kiếm"
                  onChange={handleSearchInput}
                  prefix={<SearchOutlined />}
                />
              </li>
              <li>
                <IconButton color="primary" onClick={refreshPage}>
                  <AutorenewRoundedIcon />
                </IconButton>
              </li>
            </ul>
          </div>
          <div className="px-2">
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    rowHoverBg: '#bae0ff',
                  },
                },
                token: {
                  padding: 5,
                },
              }}
            >
              <Table
                scroll={{ y: 680 }}
                onRow={(record) => ({
                  onClick: () => handleBranchBuy(record),
                })}
                bordered
                pagination={false}
                columns={columnsVT}
                dataSource={listThuocVT ? listThuocVT.data : []}
              />
            </ConfigProvider>
          </div>

          <div className="flex justify-end w-full p-2 ">
            <Pagination
              count={listThuocVT?.totalPage}
              page={+useSearch.get('page')}
              onChange={(e, page) => {
                SetSearchParams({ page })
              }}
              color="primary"
            />
          </div>
        </div>
        <div className="w-1/3 h-full bg-white rounded-xl">
          <div className=" min-h-16 px-2 pt-2 ">
            <div className="flex gap-5 justify-between items-center ">
              <div className=" w-4/5 h-full">
                {/* <Select
                  value={valueChuyenKhoa}
                  onChange={handleSelected}
                  defaultValue="all"
                  options={[
                    { label: 'Tất Cả', value: 'all' },
                    ...(chuyenKhoa
                      ? chuyenKhoa.map((items) => ({
                        label: items.tenChuyenKhoa,
                        value: items.tenChuyenKhoa,
                      }))
                      : []),
                  ]}
                  size="small"
                  className="w-full"
                /> */}
                <Select
                  showSearch
                  className="w-full"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={handleCompany}
                  value={company}
                  options={listCompany?.map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  }))}
                />
              </div>
              <div className="w-1/5">
                <Button
                  onClick={handleUpdate}
                  disabled={ListUpdate.length === 0}
                  className="h-7 w-full"
                  variant="contained"
                  size="small"
                >
                  Lưu
                </Button>
              </div>
            </div>
            <div className="flex gap-1 ">
              <span className="font-semibold">Tên:</span>
              <p>{nameVT}</p>
            </div>
          </div>

          <div className="px-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components:{
                  Table:{
                     rowHoverBg: '#ecf0f1'
                  }
                }
              }}
            >
              <Table
                scroll={{ y: 680 }}
                loading={isLoadingBranch}
                pagination={false}
                columns={columnsCN}
                dataSource={listBranchBuy}
                bordered
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default BranchBuy
