import { Select, Input, Checkbox, DatePicker, InputNumber } from 'antd'
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AddCardBN,
  fetchInfoTypeCard,
  fetchListTypesCard_Action,
  getListDiscountTTV,
  getListTypeCardByCNAction,
  removeListDiscountTTV,
} from '../../../../../store/actions/cardAction'
import { useFormik } from 'formik'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import moment from 'moment'
import dayjs from 'dayjs'
import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons'
import ToastCus from '../../../../../utils/Toast'
import { https } from '../../../../../services/apiService'
import { cardService } from '../../../../../services/card/cardService'
import { nhanvienService } from '../../../../../services/nhanvien/nhanvienService'
import { recieveService } from '../../../../../services/receive/recieveService'
import * as typeAction from '../../../../../store/constants/constants'
import _ from 'lodash'
import 'moment/locale/vi'
moment.locale('vi')
const { TextArea } = Input
const CardTTV = ({
  isEditTTV,
  handleOk,
  handleCancel,
  formTTV,
  setFormTTV,
  isDoiTac,
  setIsDoiTac,
  valueChuongTrinhDoiTac,
  setValueChuongTrinhDoiTac,
  valueGiaVoucherDoiTac,
  setValueGiaVoucherDoiTac,
}) => {
  const dateFormat = 'DD/MM/YYYY'
  const dateFormatAPI = 'YYYY-MM-DD'
  const idBranch = Number(JSON.parse(localStorage.getItem('BRANH_LOGIN')))
  const {
    listTypesCardByCN,
    detailCard,
    tongThanhToan,
    tongGiamGia,
    discountArr,
    infoBN,
    infoVoucherDoiTac,
    CTGiamTTV,
  } = useSelector((state) => state.cartReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  // const [fromDate, setFromDate] = useState(new Date())
  const [monthDate, setMonthDate] = useState(0)
  const [fromDateEdit, setFromDateEdit] = useState(null)
  const [toDateEdit, setToDateEdit] = useState(null)
  const [listNhanVien, setListNhanVien] = useState(null)
  const [infoLoaiTTV, setInfoLoaiTTV] = useState(null)
  const [listVoucher, setListVoucher] = useState([])
  // const [listMaVoucher, setListMaVoucher] = useState([])// list mã voucher ['abc','xyz',...]
  const dispatch = useDispatch()

  const handleChangeDoiTac = (e) => {
    if (!infoBN) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn bệnh nhân!',
      })
      return
    }
    // if (!newArr.length) {
    //   ToastCus.fire({
    //     icon: 'error',
    //     title: 'Vui lòng thêm chỉ định!',
    //   })
    //   return
    // }
    // if (infoChuongTrinhGiamGiaDoiTac) {
    //   ToastCus.fire({
    //     icon: 'error',
    //     title: 'Vui lòng bỏ sử dụng voucher đối tác',
    //   })
    //   return
    // }
    setIsDoiTac(e.target.checked)
  }
  const handleApplyVoucherDoiTac = () => {
    let maVCDT = formTTV?.mavoucherdoitac?.trim()
    setFormTTV({
      ...formTTV,
      mavoucherdoitac: maVCDT,
    })
    if (!maVCDT || maVCDT?.length === 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui nhập voucher đối tác',
      })
      return
    }
    if (!valueChuongTrinhDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn chương trình đối tác',
      })
      return
    }
    if (!valueGiaVoucherDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập giá trị voucher của đối tác',
      })
      return
    }
    if (tongThanhToan < valueGiaVoucherDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Giá trị hóa đơn thanh toán nhỏ hơn giá trị voucher',
      })
      setFormTTV({
        ...formTTV,
        mavoucherdoitac: '',
      })
      setValueGiaVoucherDoiTac(0)
      return
    }
    dispatch({
      type: typeAction.SET_INFO_VOUCHER_DOITAC_TTV,
      payload: {
        idctDoiTac: valueChuongTrinhDoiTac,
        maVoucher: maVCDT,
        giaVoucher: valueGiaVoucherDoiTac,
      },
    })
    ToastCus.fire({
      icon: 'success',
      title: 'Áp dụng voucher đối tác thành công',
    })
    // dispatch(
    //   setChuongTrinhGiamGiaDoiTac({
    //     idctDoiTac: valueChuongTrinhDoiTac,
    //     maVoucher: maVoucherDoiTac,
    //     giaVoucher: valueGiaVoucherDoiTac,
    //   }),
    // )
  }
  const handleUnApplyVoucherDoiTac = () => {
    dispatch({
      type: typeAction.SET_INFO_VOUCHER_DOITAC_TTV,
      payload: null,
    })
    setFormTTV({
      ...formTTV,
      mavoucherdoitac: '',
    })
    setIsDoiTac(false)
    setValueGiaVoucherDoiTac(0)
    setValueChuongTrinhDoiTac('')
    ToastCus.fire({
      icon: 'success',
      title: 'Đã hủy áp dụng voucher đối tác',
    })
  }

  const handleChecked = (e) => {
    const { checked } = e.target
    setFormTTV({
      ...formTTV,
      hoadondientu: checked ? 1 : 0,
      ghichuxuathoadon: checked ? formTTV?.ghichuxuathoadon : null,
    })
  }
  const handleTypeCard = (value) => {
    if (discountArr) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng hủy áp dụng voucher!',
      })
      return
    }
    dispatch(fetchInfoTypeCard(value, PkDangNhap?.idChiNhanh, PkDangNhap?.idTinh, infoBN?.idbn))
  }
  const handleTypeCardEdit = async (value) => {
    if (discountArr) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng hủy áp dụng voucher!',
      })
      return
    }
    // if (discountArr) {
    //   handleUnApplyVoucher()
    // }
    // if (infoVoucherDoiTac) {
    //   handleUnApplyVoucherDoiTac()
    // }
    try {
      const result = await cardService.getInfoTypeCardByCN(value, PkDangNhap?.idChiNhanh)
      setInfoLoaiTTV(result?.data)
      setMonthDate(result?.data?.sothang)
      setFormTTV({
        ...formTTV,
        tiensudung: result?.data?.tiensudung,
        sothang: result?.data?.sothang,
        idlt: value,
        sothanhvien: result?.data?.caploaithe >= 2 ? formTTV.sothanhvien : 1,
      })
      dispatch({
        type: typeAction.DISPATCH_TONG_GIAM_TTV,
        payload: 0,
      })
      dispatch({
        type: typeAction.DISPATCH_TONG_THANH_TOAN_TTV,
        payload: result?.data?.tiensudung,
      })
    } catch (error) {
      console.log(error)
    }
    // handleUnApplyVoucher()
  }
  // const handleSubmit = (values) => {
  //   values.nguoitao = infoUser.tenNV
  //   dispatch(AddCardBN(values, detailCard.idlt, infoBN.idbn, idBranch))
  //   handleCancel()
  // }
  const handleFromDateChange = async (date, dateString) => {
    const stateDate = moment(dateString, dateFormat)
    const toDate = stateDate.add(monthDate, 'months').subtract(1, 'days')
    if (isEditTTV) {
      setFromDateEdit(moment(dateString, dateFormat).format(dateFormatAPI))
      setToDateEdit(moment(toDate).format(dateFormatAPI))
    }
    setFormTTV({
      ...formTTV,
      tungay: moment(dateString, dateFormat).format(dateFormatAPI),
      denngay: moment(toDate).format(dateFormatAPI),
    })
  }
  // const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     mathe: "",
  //     idchuyenkhoa: detailCard?.idchuyenkhoa,
  //     sothanhvien: 1,
  //     //   ghichu: "",
  //     idnhom: detailCard?.idnhom,
  //     //   ngaydangky: "2024-05-13T07:35:55.001Z",
  //     tungay: null,
  //     denngay: null,
  //     nguoitao: infoUser?.tenNV,
  //     // tienmat: 0,
  //     // chuyenkhoan: 0,
  //     // momo: 0,
  //     // vnpay: 0,
  //     // zalopay: 0,
  //     // pos: 0,
  //     // thett: 0,
  //     // tiengiamgia: 0,
  //     // tienthanhtoan: 0
  //   },
  //   onSubmit: (value) => handleSubmit(value),
  // })

  //-------- lấy danh sách nhân viên -------//
  const fetchListNhanVien = async (keyword) => {
    try {
      const result = await nhanvienService.getSearch(keyword)
      setListNhanVien(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        fetchListNhanVien(keyword)
      }
    }, 300),
    [],
  )

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`Voucher/GetChuongTrinhDoiTac`)
        setListVoucher(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    if (isEditTTV) {
      setFormTTV({
        ...formTTV,
        tungay: fromDateEdit,
      })
    }
  }, [fromDateEdit])
  useEffect(() => {
    if (isEditTTV) {
      setFormTTV({
        ...formTTV,
        denngay: toDateEdit,
      })
    }
  }, [toDateEdit])
  useEffect(() => {
    detailCard ? setMonthDate(detailCard.sothang) : setMonthDate(null)
    dispatch(fetchListTypesCard_Action())
    setFormTTV({
      ...formTTV,
      idnhom: detailCard?.idnhom,
      idchuyenkhoa: detailCard?.idchuyenkhoa,
      tiensudung: detailCard?.tiensudung,
      sothanhvien: detailCard?.caploaithe < 2 ? 1 : formTTV?.sothanhvien,
    })
  }, [detailCard])
  useEffect(() => {
    if (isEditTTV) {
      if (!formTTV?.sodienthoai) setFormTTV({ ...formTTV, sodienthoai: infoBN?.dienThoai })
      if (!formTTV?.diachi) setFormTTV({ ...formTTV, diachi: infoBN?.diaChi })
    }
  }, [infoBN])
  useEffect(() => {
    if (isEditTTV) {
      setFromDateEdit(null)
      setToDateEdit(null)
    }
  }, [isEditTTV])
  const handleApplyVoucher = async () => {
    // // call api get list discount
    // if (!formTTV.mavoucher) return

    if (!formTTV.mavoucher || formTTV.mavoucher?.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Voucher không hợp lệ!',
      })
      return
    }
    // setFormTTV({ ...formTTV, mavoucher: formTTV?.mavoucher?.trim(), })
    // setMaVoucher(maVoucher?.trim())
    try {
      let temp = formTTV?.mavoucher
        ?.trim()
        ?.replaceAll(' ', '')
        ?.replaceAll(';', ',')
        ?.replaceAll('-', ',')
        ?.replaceAll('/', ',')
      setFormTTV({ ...formTTV, mavoucher: temp })
      let formatData = [
        {
          // iddv: 1889, //dich vu the thanh vien
          // dongia: formTTV?.tiensudung,
          // ptgiam: 0,
          // tiengiam: 0,
          // thanhtien: formTTV?.tiensudung,
          // mavoucher: null, ////////////////////////////
          // trangthaitt: 1,
          // nguoikichhoat: infoUser?.tenNV,
          // iddt: infoBN?.idDoiTuong ?? -1,
          // idcn: PkDangNhap?.idChiNhanh,

          idlt: detailCard?.idlt, //dich vu the thanh vien
          dongia: detailCard?.tiensudung, //formTTV?.tiensudung,
          mavoucher: null,
          ptgiam: 0,
          tiengiam: 0,
          thanhtien: detailCard?.tiensudung, //formTTV?.tiensudung,
          trangthaitt: 1,
          nguoikichhoat: infoUser.tenNV,
          iddt: infoBN?.idDoiTuong ?? -1,
          idcn: PkDangNhap?.idChiNhanh,
          // loaivoucherdichvu: 1, /////////
        },
      ]
      let listMaVC = []
      const vouchers = temp?.split(',')?.filter((vc) => _.isString(vc) && vc?.length > 0)
      if (vouchers?.filter((item, index) => vouchers?.indexOf(item) !== index)?.length > 0) {
        ToastCus.fire({
          icon: 'error',
          title: `Voucher bị trùng!`,
        })
        return
      }
      for (let i = 0; i < vouchers.length; i++) {
        const vc = vouchers[i]
        listMaVC.push(vc)
        formatData = formatData?.map((item) => ({
          ...item,
          mavoucher: vc, // gán mã voucher hiện tại
        }))
        const { data } = await cardService.postApplyVoucherTTV(formatData) // https.post(`VoucherDanhSach/ApplyVoucher`, formatData);
        formatData = [...data] // cập nhật formatData với kết quả từ API
      }
      setFormTTV({ ...formTTV, listMaVoucher: [...listMaVC] }) // setListMaVoucher(listMaVC) // list mã voucher ['abc','xyz',...]
      // const { data } = await https.post(`VoucherDanhSach/ApplyVoucher`, formatData)
      // if (data?.length > 0 && data[0]?.giatrigiam === 0) {
      //   handleUnApplyVoucher()
      //   ToastCus.fire({
      //     icon: 'error',
      //     title: 'Voucher không hợp lệ!',
      //   })
      //   return
      // }
      //dispatch action apply voucher

      // nếu tổng giảm của voucher nhỏ hơn giảm của ctgg thì lấy giảm của ctgg là giảm lớn nhất
      if (
        formatData?.reduce((tong, item) => tong + (item.trangthaitt !== 2 ? item.tiengiam : 0), 0) <
        CTGiamTTV
      ) {
        dispatch(
          getListDiscountTTV(
            formatData?.map((item) => ({
              ...item,
              dongia: detailCard?.tiensudung,
              ptgiam: 0,
              tiengiam: 0,
              thanhtien: detailCard?.tiensudung,
            })),
          ),
        )
        return
      }
      dispatch(getListDiscountTTV(formatData))
      dispatch({
        type: typeAction.DISPACTH_PRICE_TTV_CT_GIAM,
        payload: 0,
      })
    } catch (error) {
      console.log(error)
      const message = error?.response?.data?.message
      ToastCus.fire({
        icon: 'error',
        title: (message ? `${message}!\n` : '') + 'Áp dụng voucher thất bại!',
      })
    }
  }

  const handleUnApplyVoucher = async () => {
    //call api unuse voucher
    try {
      //https.put(`VoucherDanhSach/UpdateCancelVoucher?mavoucher=${formTTV.mavoucher}`)
      //dispatch action apply voucher
      dispatch(
        removeListDiscountTTV(detailCard?.tiensudung, {
          idlt: detailCard?.idlt,
          idcn: PkDangNhap?.idChiNhanh,
          idtinh: PkDangNhap?.idTinh,
          idbn: infoBN?.idbn,
        }),
      )
      setFormTTV((pre) => ({
        ...pre,
        mavoucher: '',
        listMaVoucher: null,
      }))
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message,
      })
      console.log(error)
    }
  }
  useEffect(() => {
    dispatch(getListTypeCardByCNAction(idBranch))
  }, [])
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-5 text-end">
        <div className="flex w-1/2 gap-1 items-center">
          <label className="w-1/3">
            {' '}
            <span className="text-sx text-red-500">(*) </span>Mã thẻ:
          </label>
          <Input
            status={detailCard && formTTV?.mathe?.trim().length <= 0 ? 'error' : ''}
            disabled={isEditTTV || !detailCard}
            style={{ color: 'black' }}
            name="mathe"
            value={formTTV?.mathe}
            onChange={(e) => {
              if (!isEditTTV) {
                setFormTTV({ ...formTTV, mathe: e.target.value })
              }
            }}
            // type="Number"
            className="w-full"
            size="small"
          />
        </div>
        <div className="flex w-1/2 gap-1  items-center">
          <label className="w-1/3"> Loại thẻ:</label>
          <Select
            status={infoBN && ((isEditTTV && !formTTV?.idlt) || !detailCard?.idlt) ? 'error' : ''}
            disabled={!infoBN}
            onChange={isEditTTV ? handleTypeCardEdit : handleTypeCard}
            options={listTypesCardByCN?.map((items) => ({
              label: items.loaithe,
              value: items.idlt,
            }))}
            value={isEditTTV ? formTTV.idlt : detailCard?.idlt}
            className="w-full text-start"
            size="small"
          />
        </div>
      </div>
      <div className="flex gap-5 text-end">
        <div className="flex w-1/3 gap-1 items-center">
          <label className="w-2/5">Thành viên:</label>
          <Input
            type="Number"
            status={
              (!formTTV.sothanhvien && detailCard && !isEditTTV) ||
              (!formTTV.sothanhvien && infoLoaiTTV)
                ? 'error'
                : ''
            }
            min={1}
            step={1}
            max={2}
            disabled={
              isEditTTV
                ? !infoLoaiTTV
                  ? true
                  : infoLoaiTTV?.caploaithe < 2
                : !detailCard
                  ? true
                  : detailCard?.caploaithe < 2
            }
            style={{ color: 'Black' }}
            value={detailCard ? formTTV?.sothanhvien : null}
            onChange={(e) => {
              setFormTTV({
                ...formTTV,
                sothanhvien: Number(
                  e.target.value >= 1 && e.target.value <= 2 ? e.target.value : 1,
                ),
              })
            }}
            name="sothanhvien"
            className="w-3/5"
            size="small"
          />
        </div>
        <div className="flex w-1/3 gap-1 items-center">
          <label className="w-2/5">Phương thức:</label>
          <Input
            value={detailCard ? 'Thanh toán' : null}
            style={{ color: 'Black' }}
            disabled
            className="w-3/5"
            size="small"
          />
        </div>
        <div className="flex w-2/5 gap-1 items-center">
          <label className="w-3/5">Đơn giá:</label>
          <Input
            style={{ color: 'black' }}
            value={
              isEditTTV
                ? formTTV?.tiensudung && formattedNumber(formTTV?.tiensudung)
                : detailCard?.tiensudung && formattedNumber(detailCard?.tiensudung)
            }
            disabled
            className="w-3/5"
            size="small"
          />
        </div>
      </div>
      <div className="flex gap-5 text-end">
        <div className="flex w-1/3 gap-1 items-center">
          <label className="w-2/5">Số tháng:</label>
          <Input
            style={{ color: 'black' }}
            value={isEditTTV ? formTTV?.sothang : detailCard?.sothang}
            disabled
            className="w-3/5"
            size="small"
          />
        </div>
        <div className="flex w-1/3 gap-1 items-center">
          <label className="w-2/5">Ngày hiệu lực:</label>
          <DatePicker
            status={detailCard && formTTV?.tungay?.trim().length <= 0 ? 'error' : ''}
            disabled={!detailCard}
            allowClear={false}
            format={dateFormat}
            onChange={handleFromDateChange}
            placeholder=" "
            value={
              isEditTTV
                ? fromDateEdit && dayjs(fromDateEdit)
                : formTTV.tungay && dayjs(formTTV.tungay)
            }
            className="w-3/5 rounded"
            size="small"
          />
        </div>
        <div className="flex w-2/5 gap-1 items-center">
          <label className="w-3/5"> Ngày hết hạn:</label>
          <Input
            style={{ color: 'black' }}
            value={
              isEditTTV
                ? toDateEdit && moment(toDateEdit).format(dateFormat)
                : formTTV.denngay && moment(formTTV.denngay).format(dateFormat)
            }
            disabled
            className="w-3/5"
            size="small"
          />
        </div>
      </div>
      <div className="flex gap-5 text-end">
        <div className="flex w-1/2 gap-1 items-center mr-3">
          <label className="w-1/4">Người tạo:</label>
          <Input
            value={detailCard ? formTTV.nguoitao : null}
            //defaultValue={detailCard ? formTTV.nguoitao : null}
            style={{ color: 'Black' }}
            disabled
            className="w-3/4"
            size="small"
          />
        </div>
        <div className="flex w-1/2 gap-1 items-center">
          <label className="w-1/4">Người bán:</label>
          <Select
            status={detailCard && formTTV?.nguoiban?.trim().length <= 0 ? 'error' : ''}
            disabled={!detailCard}
            onSearch={debounceDropDown}
            value={detailCard ? formTTV.nguoiban : null}
            onChange={(value, opt) => {
              setFormTTV({ ...formTTV, idnguoiban: value, nguoiban: opt?.label ?? null })
            }}
            filterOption={false}
            notFoundContent={null}
            placeholder={!detailCard ? '' : 'Tìm tên nhân viên...'}
            suffixIcon={<SearchOutlined className=" " />}
            options={listNhanVien?.map((items) => ({
              label: items.tennv,
              value: items.id,
              manv: items.manv,
            }))}
            optionRender={(options) => (
              <ul className="flex">
                <li className="w-3/4">{options.data.label}</li>
                <li className="w-1/4 border-x text-center">{options.data.manv}</li>
              </ul>
            )}
            showSearch
            className="w-3/4 text-left"
            size="small"
          />
        </div>
      </div>
      <div className="flex gap-5 text-end">
        <div className="flex w-1/2 gap-1 items-center mr-3">
          <label className="w-1/4">Người nộp tiền:</label>
          <Input
            status={detailCard && formTTV?.nguoimua?.trim().length <= 0 ? 'error' : ''}
            disabled={!detailCard}
            value={formTTV?.nguoimua}
            name="nguoimua"
            onChange={(e) => {
              setFormTTV({ ...formTTV, nguoimua: e.target.value })
            }}
            style={{ color: 'Black' }}
            className="w-3/4"
            size="small"
          />
        </div>
        <div className="flex w-1/2 gap-1 items-center">
          <label className="w-1/4">SĐT:</label>
          <Input
            status={detailCard && formTTV?.sodienthoai?.trim().length <= 0 ? 'error' : ''}
            disabled={!detailCard}
            value={formTTV?.sodienthoai}
            name="sodienthoai"
            onChange={(e) => {
              setFormTTV({ ...formTTV, sodienthoai: e.target.value })
            }}
            style={{ color: 'Black' }}
            className="w-3/4"
            size="small"
          />
        </div>
      </div>
      <div className="flex gap-5 text-end">
        <div className="flex w-1/2 gap-1 items-center mr-3">
          <label className="w-1/4">Địa chỉ:</label>
          <Input
            status={detailCard && formTTV?.diachi?.trim().length <= 0 ? 'error' : ''}
            disabled={!detailCard}
            value={formTTV?.diachi}
            name="diachi"
            onChange={(e) => {
              setFormTTV({ ...formTTV, diachi: e.target.value })
            }}
            style={{ color: 'Black' }}
            className="w-3/4"
            size="small"
          />
        </div>
        <div className="flex w-1/2 gap-1 items-center">
          <label className="w-1/4">Lý do:</label>
          <Input
            status={detailCard && formTTV?.lydo?.trim().length <= 0 ? 'error' : ''}
            disabled={!detailCard}
            value={formTTV?.lydo}
            name="lydo"
            onChange={(e) => {
              setFormTTV({ ...formTTV, lydo: e.target.value })
            }}
            style={{ color: 'Black' }}
            className="w-3/4"
            size="small"
          />
        </div>
      </div>
      <div className="flex text-end">
        <div className="w-[43.5%]">
          <div className="grid grid-row-2">
            <div className="flex items-start gap-1">
              <label className="w-[27.3%]">Voucher:</label>
              <Input
                disabled={!detailCard || discountArr}
                style={{ color: 'black' }}
                value={formTTV?.mavoucher}
                onChange={(e) => setFormTTV({ ...formTTV, mavoucher: e.target.value })}
                className="w-[72.7%]"
                size="small"
              />
            </div>
            <div className="flex items-start gap-1 mt-2">
              {/* <label className="w-[27.3%]">Đối tác:</label>
              <Checkbox
                disabled={!detailCard}
                checked={isDoiTac}
                onChange={handleChangeDoiTac}
              /> */}
              {isDoiTac ? (
                <Select
                  style={{ color: 'black' }}
                  className="w-[66.4%] text-start"
                  size="small"
                  disabled={!detailCard || infoVoucherDoiTac}
                  value={valueChuongTrinhDoiTac}
                  options={listVoucher?.map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  }))}
                  onChange={(value) => setValueChuongTrinhDoiTac(value)}
                />
              ) : null}
              {/* <Input
                disabled={!detailCard || discountArr}
                style={{ color: 'black' }}
                value={formTTV?.mavoucher}
                onChange={(e) => setFormTTV({ ...formTTV, mavoucher: e.target.value })}
                className="w-[67.2%]"
                size="small"
              /> */}
            </div>
          </div>
        </div>
        <div className="flex w-[56.5%] gap-1 items-center">
          <div className="w-full grid grid-cols-12 h-full">
            <div className="grid grid-rows-2">
              <div>
                <ul className="flex justify-around items-center">
                  {formTTV?.mavoucher &&
                  !discountArr &&
                  detailCard &&
                  infoBN &&
                  formTTV?.tiensudung ? (
                    <li
                      onClick={handleApplyVoucher}
                      className="bg-green-200 ml-4 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                    >
                      <CheckOutlined />
                    </li>
                  ) : null}
                  {discountArr ? (
                    <li
                      onClick={handleUnApplyVoucher}
                      className="bg-red-200 ml-4 text-red-700 hover:bg-red-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                    >
                      <CloseOutlined />
                    </li>
                  ) : null}
                </ul>
              </div>

              {/* //////////////////////////////////////////////////// */}
              <div>
                <ul className="flex justify-around items-center mt-1">
                  {!infoVoucherDoiTac &&
                  formTTV?.mavoucherdoitac &&
                  detailCard &&
                  infoBN &&
                  formTTV?.tiensudung ? (
                    <li
                      onClick={handleApplyVoucherDoiTac}
                      className="bg-green-200 ml-4 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                    >
                      <CheckOutlined />
                    </li>
                  ) : null}
                  {infoVoucherDoiTac ? (
                    <li
                      onClick={handleUnApplyVoucherDoiTac}
                      className="bg-red-200 ml-4 text-red-700 hover:bg-red-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                    >
                      <CloseOutlined />
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
            <div className="col-span-11 flex justify-start pl-[6.5%] items-start">
              <label className="mr-1">Xuất HĐ điện tử:</label>
              <Checkbox
                disabled={!detailCard}
                className="mr-2"
                checked={formTTV?.hoadondientu === 1}
                onChange={handleChecked}
              />
              {formTTV?.hoadondientu === 1 && (
                <TextArea
                  status={formTTV?.ghichuxuathoadon?.trim().length <= 0 ? 'error' : ''}
                  value={formTTV?.ghichuxuathoadon}
                  onChange={(e) => setFormTTV({ ...formTTV, ghichuxuathoadon: e.target.value })}
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  className="w-[68.3%]"
                />
              )}
            </div>
          </div>
        </div>
        {/* <div className="flex w-2/5 gap-1 items-center">
          <Input
            // disabled={!detailCard}
            // value={formTTV?.mavoucher}
            // onChange={(e) => setFormTTV({ ...formTTV, mavoucher: e.target.value })}
            className="w-[80%]"
            size="small"
                checked={isDoiTac}
          />
        </div> */}
      </div>
      {isDoiTac && (
        <>
          <div className="flex text-end">
            <div className="w-[43.5%]">
              <div className="flex items-start gap-1">
                <label className="w-[27.3%]">Voucher đối tác:</label>
                <Input
                  disabled={!detailCard || infoVoucherDoiTac}
                  style={{ color: 'black' }}
                  value={formTTV?.mavoucherdoitac}
                  onChange={(e) => {
                    setFormTTV({
                      ...formTTV,
                      mavoucherdoitac: e.target.value,
                    })
                  }}
                  className="w-[72.7%]"
                  size="small"
                />
              </div>
            </div>
          </div>
          <div className="flex text-end">
            <div className="w-[43.5%]">
              <div className="flex items-start gap-1">
                <label className="w-[27.3%]">Giá trị:</label>
                <Input
                  disabled={!detailCard || infoVoucherDoiTac}
                  style={{ color: 'black' }}
                  type="number"
                  step={1}
                  min={0}
                  value={valueGiaVoucherDoiTac}
                  onChange={(e) => setValueGiaVoucherDoiTac(e.target.value)}
                  className="w-[72.7%]"
                  size="small"
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className={
          isEditTTV
            ? !formTTV?.tiensudung
              ? 'hidden'
              : ''
            : !detailCard?.tiensudung
              ? 'hidden'
              : ''
        }
      >
        <div className="flex flex-col gap-2 justify-end mt-2">
          {/* {true && (//infoChuongTrinhGiamGiaDoiTac
            <div className="flex items-center ml-auto mr-4">
              <span className="text-red-500 text-xl font-semibold mr-10">
                Voucher đối tác:
              </span>
              <span className="text-red-500 text-xl font-semibold">
                -{' '}
                {formattedNumber(tongGiamGia)
                }{' '}
                VNĐ
              </span>
            </div>
          )} */}
          <div className="flex items-center ml-auto mr-4 text-red-500 text-xl font-semibold justify-between w-2/6">
            <div className="mr-4">Tiền giảm:</div>
            <div className="">-{formattedNumber(tongGiamGia + CTGiamTTV)} VNĐ</div>
          </div>
          <div className="flex items-center ml-auto mr-4 text-green-700 text-xl font-semibold justify-between w-2/6">
            <span className="mr-4">Thanh toán:</span>
            <span className="">{formattedNumber(tongThanhToan - CTGiamTTV)} VNĐ</span>
          </div>
        </div>
      </div>

      {/* <div className="flex gap-2 justify-end mt-5">
        <Button onClick={handleCancel}>Huỷ</Button>
        <Button type="primary" onClick={formik.handleSubmit}>
          Tạo thẻ
        </Button>
      </div> */}
    </div>
  )
}

export default CardTTV
