import JsBarcode from 'jsbarcode'
import moment from 'moment'
import { generateLogo, generateThuVietNamese } from '../../algorithm'
import { jsPDFCus } from '../../jsPdfAddFont'
import { splitLongWord } from '../../splitWordForReport'
import 'moment/locale/vi'
moment.locale('vi')

export const generateNhuHoa = (BNTTC, formPhuSan, PkDangNhap) => {
  const doc = jsPDFCus('p', 'mm', 'a4')
  let yPos = 10
  let xPos = 8
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  //header
  generateLogo(doc, xPos, PkDangNhap)

  //barcode
  JsBarcode('#barcode', `TTD${BNTTC.benhNhan.maBenhNhan}`, {
    displayValue: false,
  })

  const canvas = document.getElementById('barcode')
  // Convert canvas to an image
  const barcodeDataURL = canvas.toDataURL()
  doc.addImage(barcodeDataURL, 'PNG', pageWidth - 50, 5, 40, 15) // Adjust position and size as needed
  doc.setFontSize(11)
  doc.text(`Ref.ID: TTD${BNTTC.benhNhan.maBenhNhan}`, pageWidth - 50, 22)
  doc.setFont('times', 'bold')
  doc.setFontSize(16)
  doc.text('KHÁM NHŨ HOA', doc.internal.pageSize.width / 2, 35, {
    align: 'center',
  })
  doc.setFontSize(12)
  doc.setFont('times', 'bold')
  doc.text('1. Hành chính:', xPos, 45)
  doc.text(BNTTC.benhNhan.tenBenhNhan, xPos + doc.getTextDimensions('Họ tên: ').w, 50)
  doc.setFont('times', 'regular')
  doc.text('Họ tên:', xPos, 50)
  doc.text(
    `Năm sinh: ${moment(BNTTC.benhNhan.ngaySinh).format('YYYY')}  Tuổi: ${BNTTC.benhNhan.tuoi}`,
    pageWidth - 115,
    50,
  )
  doc.text(`Para: ${BNTTC.para ?? ''}`, pageWidth - 30, 50)
  const diaChiBenhNhan = `Địa chỉ: ${BNTTC.benhNhan.diaChi ?? ''}${BNTTC.benhNhan.tenPhuongXa ? ', ' + BNTTC.benhNhan.tenPhuongXa : ''}${BNTTC.benhNhan.tenQuanHuyen ? ', ' + BNTTC.benhNhan.tenQuanHuyen : ''}${BNTTC.benhNhan.tenTinhTP ? ', ' + BNTTC.benhNhan.tenTinhTP : ''}`
  if (diaChiBenhNhan.length > 80) {
    const wordsArray = splitLongWord(diaChiBenhNhan, 80)
    doc.text(wordsArray[0], xPos, 57)
    doc.text(wordsArray[1], xPos, 62)
    yPos = 62
  } else {
    doc.text(diaChiBenhNhan, xPos, 57)
    yPos = 57
  }
  yPos += 5
  doc.setFont('times', 'bold')
  doc.text('2. Kỳ kinh:', xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text(`Kinh chót: ${BNTTC.kinhchot ?? ''}`, xPos + 25, yPos)
  doc.text(`Kinh áp chót: ${BNTTC.kinhapchot ?? ''}`, pageWidth - 80, yPos)
  yPos += 5
  doc.text(`Chu kỳ kinh: ${BNTTC.chukikinh ?? ''}`, xPos + 25, yPos)
  yPos += 5
  doc.text(`Ngừa thai: ${BNTTC.nguathai ?? ''}`, xPos + 25, yPos)
  yPos += 7
  doc.setFont('times', 'bold')
  doc.text('3. Tiền căn:', xPos, yPos)
  yPos += 7
  doc.text(`4. Bệnh sử:`, xPos, yPos)
  doc.setFont('times', 'regular')
  const benhsu = `${BNTTC.benhsu ?? ''}`
  if (doc.getTextWidth(benhsu) > 100) {
    const wordsArray = splitLongWord(benhsu, 92)
    doc.text(wordsArray[0], xPos + doc.getTextDimensions('4. Bệnh sử:').w + 3, yPos)
    yPos += 5
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    yPos += 2
  } else {
    doc.text(benhsu, xPos + doc.getTextDimensions('4. Bệnh sử:').w + 3, yPos)
    yPos += 7
  }
  doc.setFont('times', 'bold')
  doc.text(`5. Lý do đến khám: `, xPos, yPos)
  doc.setFont('times', 'regular')
  const lydokham = `${BNTTC.lydokham ?? ''}`
  if (doc.getTextWidth(lydokham) > 90) {
    const wordsArray = splitLongWord(lydokham, 85)
    doc.text(wordsArray[0], xPos + doc.getTextDimensions('5. Lý do đến khám:').w + 3, yPos)
    yPos += 5
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
    yPos += 2
  } else {
    doc.text(lydokham, xPos + doc.getTextDimensions('5. Lý do đến khám:').w + 3, yPos)
    yPos += 7
  }
  doc.setFont('times', 'bold')
  doc.text('6. Khám tổng quát:', xPos, yPos)
  doc.setFont('times', 'regular')
  yPos += 5
  doc.text(`Mạch: ${BNTTC.mach ?? '...'} Lần/phút`, xPos, yPos)
  doc.text(`Huyết áp: ${BNTTC.huyetap ?? ''} mmHg`, pageWidth - 95, yPos)
  yPos += 7
  doc.text(`SpO2: ${BNTTC.spO2 ?? '...'}%`, xPos, yPos)
  doc.text(`Chiều cao: ${BNTTC.chieucao ?? '...'} cm`, pageWidth - 95, yPos)
  yPos += 7
  doc.text(`Nhịp thở: ${BNTTC.nhiptho ?? '...'}`, xPos, yPos)
  doc.text(`Cân nặng: ${BNTTC.cannang ?? '...'} kg`, pageWidth - 95, yPos)
  yPos += 7
  doc.text(`Nhiệt độ: ${BNTTC.nhietdo ?? '...'}°C`, xPos, yPos)
  doc.text(
    `BMI: ${(BNTTC.cannang / Math.pow(BNTTC.chieucao / 100, 2)).toFixed(3) ?? ''}`,
    pageWidth - 95,
    yPos,
  )
  yPos += 7
  doc.text(`Tổng trạng: ${BNTTC.tongtrang ?? ''}`, xPos, yPos)
  yPos += 7
  doc.text(`Tim phổi: ${BNTTC.timphoi ?? ''}`, xPos, yPos)
  doc.text(`Bụng: ${BNTTC.bung ?? ''}`, pageWidth - 95, yPos)
  yPos += 7
  doc.text(`Da niêm: ${BNTTC.daniem ?? ''}`, xPos, yPos)
  doc.text(`Phù: ${BNTTC.phu ?? ''}`, pageWidth - 95, yPos)
  yPos += 5
  doc.setFont('times', 'bold')
  doc.text('7. Khám nhũ hoa:', xPos, yPos)
  yPos += 5
  doc.setFont('times', 'regular')
  doc.text(`Nhìn 2 vú: ${formPhuSan.nhiN2VU ?? ''}`, xPos, yPos)
  doc.text(`Hạch nách: ${formPhuSan.hachnach}`, pageWidth - 95, yPos)
  yPos += 7
  doc.text(`Nhìn vú trái: ${formPhuSan.nhintrai ?? ''}`, xPos, yPos)
  doc.text(`Nhìn vú phải: ${formPhuSan.nhinphai ?? ''}`, pageWidth - 95, yPos)
  yPos += 7
  doc.text(`Sờ nắn vú trái: ${formPhuSan.sonantrai ?? ''}`, xPos, yPos)
  doc.text(`Sờ nắn vú phải: ${formPhuSan.sonanphai ?? ''}`, pageWidth - 95, yPos)
  yPos += 7
  doc.text(`Ghi nhận khác: ${formPhuSan.gnkhac ?? ''}`, xPos, yPos)
  yPos += 7
  doc.setFont('times', 'bold')
  doc.text(`8. Chẩn đoán sơ bộ:`, xPos, yPos)
  doc.setFont('times', 'regular')
  const chandoanbandau = `${BNTTC.chandoanbandau ?? ''}`
  if (doc.getTextWidth(chandoanbandau) > 120) {
    const wordsArray = splitLongWord(chandoanbandau, 100)
    doc.text(wordsArray[0], xPos + doc.getTextDimensions('8. Chẩn đoán sơ bộ:').w + 5, yPos)
    yPos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
  } else {
    doc.text(chandoanbandau, xPos + doc.getTextDimensions('8. Chẩn đoán sơ bộ:').w + 5, yPos)
  }
  yPos += 7

  doc.setFont('times', 'bold')
  doc.text('9. Cận lâm sàng:', xPos, yPos)
  doc.setFont('times', 'regular')

  // const chandoanbandau = `${BNTTC.chandoanbandau ?? ''}`
  // if (doc.getTextWidth(chandoanbandau) > 180) {
  //   const wordsArray = splitLongWord(chandoanbandau, 100)
  //   doc.text(wordsArray[0], xPos + doc.getTextDimensions('8. Chẩn đoán sơ bộ:').w + 5, yPos)
  //   yPos += 6
  //   for (let index = 1; index < wordsArray.length; index++) {
  //     doc.text(wordsArray[index], xPos, yPos)
  //     yPos += 6
  //   }
  // } else {
  //   doc.text(chandoanbandau, xPos, yPos)
  // }
  yPos += 7
  doc.setFont('times', 'bold')
  doc.text(`10. Kết quả CLS:`, xPos, yPos)
  doc.setFont('times', 'regular')

  const kqcls = `${BNTTC.kqcls ?? ''}`
  if (doc.getTextWidth(kqcls) > 120) {
    const wordsArray = splitLongWord(kqcls, 100)
    doc.text(wordsArray[0], xPos + doc.getTextDimensions('10. Kết quả CLS:').w + 5, yPos)
    yPos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
  } else {
    doc.text(kqcls, xPos + doc.getTextDimensions('10. Kết quả CLS:').w + 5, yPos)
  }
  yPos += 7
  //check page
  if (pageHeight - yPos < 40) {
    doc.addPage()
    yPos = 10
  }
  doc.setFont('times', 'bold')
  doc.text(`11. Chẩn đoán:`, xPos, yPos)
  doc.setFont('times', 'regular')
  const chandoan = `${BNTTC.chandoan ?? ''}`
  if (doc.getTextWidth(chandoan) > 120) {
    const wordsArray = splitLongWord(chandoan, 100)
    doc.text(wordsArray[0], xPos + doc.getTextDimensions('11. Chẩn đoán:').w + 5, yPos)
    yPos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
  } else {
    doc.text(chandoan, xPos + doc.getTextDimensions('11. Chẩn đoán:').w + 5, yPos)
  }
  yPos += 7
  //check page
  if (pageHeight - yPos < 40) {
    doc.addPage()
    yPos = 10
  }

  doc.setFont('times', 'bold')
  doc.text(`12.Xử trí:`, xPos, yPos)
  doc.setFont('times', 'regular')
  const huongxuly = `${BNTTC.huongxuly ?? ''}`
  if (doc.getTextWidth(huongxuly) > 150) {
    const wordsArray = splitLongWord(huongxuly, 100)
    doc.text(wordsArray[0], xPos + doc.getTextDimensions('12.Xử trí:').w + 5, yPos)
    yPos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
  } else {
    doc.text(huongxuly, xPos + doc.getTextDimensions('12.Xử trí:').w + 5, yPos)
  }
  yPos += 7
  //check page
  if (pageHeight - yPos < 40) {
    doc.addPage()
    yPos = 10
  }

  doc.setFont('times', 'bold')
  doc.text(`13. Lời dặn:`, xPos, yPos)
  doc.setFont('times', 'regular')
  const loidan = `${BNTTC.loidan ?? ''}`
  if (doc.getTextWidth(loidan) > 140) {
    const wordsArray = splitLongWord(loidan, 90)
    doc.text(wordsArray[0], xPos + doc.getTextDimensions('13. Lời dặn:').w + 5, yPos)
    yPos += 6
    for (let index = 1; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 6
    }
  } else {
    doc.text(loidan, xPos + doc.getTextDimensions('13. Lời dặn:').w + 5, yPos)
    yPos += 7
  }
  //check page
  if (pageHeight - yPos < 40) {
    doc.addPage()
    yPos = 10
  }
  doc.setFont('times', 'bold')
  doc.text('14. Tái khám:', xPos, yPos)
  doc.setFont('times', 'regular')
  doc.text(
    `${generateThuVietNamese(BNTTC.ngaytaikham)} - ${moment(BNTTC.ngaytaikham).format('DD/MM/YYYY')}`,
    xPos + 30,
    yPos,
  )
  yPos += 8

  const dateText = `TP.HCM, Ngày ${moment(BNTTC.ngaykham ?? new Date()).format('DD')} tháng ${moment(BNTTC.ngaykham ?? new Date()).format('MM')} năm ${moment(BNTTC.ngaykham ?? new Date()).format('YYYY')}`
  const doctorText = BNTTC.bskham ?? ''
  const dateTextWidth = doc.getTextWidth(dateText)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 15 // 15 is the padding from the right edge

  // Calculate the center point of the date text
  const dateCenterX = dateTextX + dateTextWidth / 2

  // Position the doctor text centered below the date text
  const doctorTextX = dateCenterX - doctorTextWidth / 2

  doc.text(dateText, dateTextX, yPos)
  yPos += 5
  doc.text('Người thực hiện', pageWidth - 60, yPos)
  doc.setFont('times', 'bold')
  doc.text(doctorText, doctorTextX, yPos + 20)

  doc.setFont('times', 'italic')
  doc.setFontSize(10)
  doc.line(10, pageHeight - 9, pageWidth - 10, pageHeight - 9)
  doc.text('(*) Đem theo phiếu này khi tái khám.', xPos, pageHeight - 5)
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)
  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
