import { https } from '../apiService'

export const noiSoiService = {
  getBNPendingByBranch: (idcn) => https.get(`BenhNhanCDHA/GetBenhNhanChoNoiSoi?idcn=${idcn}`),
  getBNDoneBtBranch: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanCDHA/GetBenhNhanDaNoiSoi?idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`),

  getListBnChoCTC: (idcn) =>
    https.get(`BenhNhanCDHA/GetBenhNhanChoNoiSoi`, {
      params: { idcn },
    }),
  getListBnDaCTC: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanCDHA/GetBenhNhanDaNoiSoi`, {
      params: { idcn, tuNgay, denNgay },
    }),
  getListLichSuCTC: (idbn) =>
    https.get(`BenhNhanNoiSoi/GetLichSuBenhNhanNoiSoi`, {
      params: { idbn },
    }),

  getListBnChoTH: (idcn) =>
    https.get(`BenhNhanNoiSoi/GetBenhNhanChoNoiSoiTieuHoa`, {
      params: { idcn },
    }),
  getListBnDaTH: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanNoiSoi/GetBenhNhanDaNoiSoiTieuHoa`, {
      params: { idcn, tuNgay, denNgay },
    }),
  getListLichSuTH: (idbn) =>
    https.get(`BenhNhanNoiSoi/GetLichSuBenhNhanNoiSoiTieuHoa`, {
      params: { idbn },
    }),

  getListBnChoTMH: (idcn) =>
    https.get(`BenhNhanNoiSoi/GetBenhNhanChoNoiSoiTMH`, {
      params: { idcn },
    }),
  getListBnDaTMH: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanNoiSoi/GetBenhNhanDaNoiSoiTMH`, {
      params: { idcn, tuNgay, denNgay },
    }),
  getListLichSuTMH: (idbn) =>
    https.get(`BenhNhanNoiSoi/GetLichSuBenhNhanNoiSoiTMH`, {
      params: { idbn },
    }),

  getInfoBnNoiSoi: (idbncdha) =>
    https.get(`BenhNhanNoiSoi/GetThongTinBenhNhanNoiSoi`, {
      params: { idbncdha },
    }),
  postInfoBnNoiSoi: (form) => https.post(`BenhNhanNoiSoi/AddBenhNhanNoiSoi`, form),
  putInfoBnNoiSoi: (form) => https.put(`BenhNhanNoiSoi/UpdateBenhNhanNoiSoi`, form),
  putFileBnNoiSoi: (form) => https.put(`BenhNhanNoiSoi/UpdateFileNoiSoi`, form),
  deleteFileBnNoiSoi: (idbnns, fileKetQua) =>
    https.delete(`BenhNhanNoiSoi/DeleteFileNoiSoi?idbnns=${idbnns}&fileKetQua=${fileKetQua}`),
}
