import { call, put, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { khoVTservice } from '../../services/khoVT/khoVTservice'
import Swal from 'sweetalert2'
import moment from 'moment'
import { listBranchByThuocVT } from '../actions/thuocVTAction'
import { branchService } from '../../services/branch/branchService'
import { NhapKhoService } from '../../services/NhapKho/NhapKhoService'
import 'moment/locale/vi'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
const now = moment()
export function* ThuocVTSaga() {
  //lấy danh sách thuốc vật tư
  yield takeLatest(
    typeAction.GET_ALL_THUOC_VT,
    function* listThuocVT({ type, keyword, idCT, idPhanLoai, idNhom, pageIndex }) {
      try {
        const result = yield call(() =>
          khoVTservice.SearchByCondition(keyword, idCT, idPhanLoai, idNhom, pageIndex),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_THUOCVT,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //ADD THUỐC VẬT TƯ
  yield takeLatest(typeAction.POST_THUOC_VT, function* postThuocVT({ type, form }) {
    try {
      form.ngayTao = now.format()
      yield call(() => khoVTservice.addThuocVT(form))
      Toast.fire({
        icon: 'success',
        title: 'Thêm thuốc VT thành công!',
      })
    } catch (err) {
      yield console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Thêm thuốc VT thất bại!',
      })
    }
  })
  //DELETE THUỐC VẬT TƯ
  yield takeLatest(typeAction.DELETE_THUOC_VT, function* deleteThuocVT({ type, id }) {
    try {
      yield call(() => khoVTservice.deleteThuocVT(id))
      // yield put(getListThuocVTByPageIndex_Action(form))
      Toast.fire({
        icon: 'success',
        title: 'Xoá Thuốc-Vật tư thành công!',
      })
    } catch (err) {
      yield console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Xoá Thuốc-Vật tư thất bại!',
      })
    }
  })
  //GET INFO THUOC VT
  yield takeLatest(typeAction.GET_INFO_KHO_VT, function* infoThuocVT({ type, id }) {
    try {
      const result = yield call(() => khoVTservice.getInfoVT(id))
      yield put({
        type: typeAction.DISPATCH_INFO_KHO_VT,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //GET INFO Nước xuất sứ
  yield takeLatest(typeAction.GET_INFO_COUNTRY, function* infoThuocVT({ type }) {
    try {
      const result = yield call(() => khoVTservice.getListCountry())
      yield put({
        type: typeAction.DISPATCH_INFO_COUNTRY,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //lấy danh sách thuốc vâtj tư kho tồn
  yield takeLatest(
    typeAction.GET_LIST_TON_KHO_VT,
    function* getListTonKho({
      type,
      idct,
      idChiNhanh,
      idkhocn,
      toDate,
      pageNumber,
      keyword,
      setLoading,
    }) {
      try {
        setLoading(true)
        const result = yield call(() =>
          khoVTservice.getTonKhoByConditionForData(idct, idChiNhanh, idkhocn, toDate, keyword),
        )
        //  const result = yield call(() => khoVTservice.getTonKhoCondition(idct, idChiNhanh, idkhocn,))
        // const data =  result.data.filter((item) => item.tonKho > 0)
        yield put({
          type: typeAction.DISPATCH_LIST_TON_KHO,
          payload: result.data,
        })
        setLoading(false)
      } catch (err) {
        yield console.log(err)
        setLoading(false)
      }
    },
  )
  //------------------- TỒN KHO TỔNG HỢP -----------------//
  yield takeLatest(
    typeAction.GET_TON_KHO_TONG_HOP,
    function* getListTonKhoTongHop({
      type,
      IDCongTy,
      IDChiNhanh,
      IDKhoCN,
      // toDate,
      keyword,
      setLoading,
    }) {
      try {
        setLoading(true)
        const result = yield call(() =>
          khoVTservice.getTonKhoTongHop(IDCongTy, IDChiNhanh, IDKhoCN, keyword),
        )
        yield put({
          type: typeAction.DISPATCH_TONKHO_TON_HOP,
          payload: result.data,
        })
        setLoading(false)
      } catch (err) {
        yield console.log(err)
        setLoading(false)
      }
    },
  )
  //lấy danh sách thuốc vâtj tư kho tồn theo kho chi nhanh
  yield takeLatest(
    typeAction.GET_LIST_TON_KHO_CN_VT,
    function* getListTonKho({ type, idKhoCN, setLoading }) {
      try {
        setLoading(true)
        const result = yield call(() => khoVTservice.getListTonkhoByKhoCN(idKhoCN))
        yield put({
          type: typeAction.DISPATCH_LIST_TON_KHO,
          payload: result.data.result,
        })
        setLoading(false)
      } catch (err) {
        yield console.log(err)
        setLoading(false)
      }
    },
  )

  //LẤY LIST INFO LOẠI THUỐC VẬT TƯ
  yield takeLatest(
    typeAction.GET_LIST_TYPES_THUOC_VT,
    function* listTypesThuocVT({ type, idChiNhanh, setLoading }) {
      try {
        const result = yield call(() => khoVTservice.getListTypesThuocVT())
        yield put({
          type: typeAction.DISPATCH_TYPES_THUOC_VT,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LẤY LIST INFO LOẠI THUỐC VẬT TƯ
  yield takeLatest(
    typeAction.GET_ALL_LIST_GROUPS_THUOC_VT,
    function* listAllGroupsThuocVT({ type, idType }) {
      try {
        const result = yield call(() => khoVTservice.getListGroupsThuocVT(idType))
        yield put({
          type: typeAction.DISPATCH_GET_ALL_GROUPS_VT,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // LẤY TẤT CẢ DANH SÁCH LOẠI THUỐC VẬT TƯ
  yield takeLatest(typeAction.GET_ALL_TYPES_THUOC_VT, function* listAllTypesThuocVT({ type }) {
    try {
      const result = yield call(() => khoVTservice.getListTypesThuocVT())
      yield put({
        type: typeAction.DISPATCH_ALL_TYPE_VT,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // GET LIST GROUPS BY TYPE
  yield takeLatest(
    typeAction.GET_LIST_GROUPS_THUOC_VT_BY_TYPE,
    function* ListGroups({ type, idTypes }) {
      try {
        const result = yield call(() => khoVTservice.getListGroupsThuocVT(idTypes))
        yield put({
          type: typeAction.DISPATCH_GROUPS_THUOC_VT,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // GET LIST BRANCH BUY BY THUOC VT
  yield takeLatest(
    typeAction.GET_LIST_BRACNH_BUY,
    function* listBranchBuy({ type, idThuoc, setIsloadingBranch }) {
      try {
        setIsloadingBranch(true)
        const result = yield call(() => khoVTservice.getListBranchBuy(idThuoc))
        yield put({
          type: typeAction.DISPATCH_BRANCH_BUY,
          payload: result.data,
        })
        setIsloadingBranch(false)
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // GET LIST GROUPS VT BRANCH BUY
  yield takeLatest(typeAction.GET_GROUPS_VT_BRANCH_BUY, function* groupsVTBuy({ type, idTypes }) {
    try {
      const result = yield call(() => khoVTservice.getListGroupsThuocVT(idTypes))
      yield put({
        type: typeAction.DISPATCH_GROUPS_VT_BRANCH_BUY,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // GET ALL CHUYEN KHOA
  yield takeLatest(typeAction.GET_ALL_CHUYEN_KHOA, function* groupsVTBuy({ type }) {
    try {
      const result = yield call(() => khoVTservice.getAllChuyenKhoa())
      yield put({
        type: typeAction.DISPATCH_ALL_CHUYEN_KHOA,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // LẤY DANH SÁCH CHI NHÁNH CỦA TỒN KHO
  yield takeLatest(typeAction.GET_BRANCH_TON_KHO, function* branchTonKho({ type, IDCompany }) {
    try {
      const result = yield call(() => branchService.getListBranch(IDCompany))
      yield put({
        type: typeAction.DISPATCH_BRANCH_TON_KHO,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // UPDATE CHI NHANH BAN THEO THUOC VT
  yield takeLatest(
    typeAction.UPDATE_STATUS_BRANCH_BUY,
    function* updateBranchBuy({ type, form, idThuoc, setIsloadingBranch }) {
      try {
        yield call(() => khoVTservice.putBranchBuy(form))
        yield put(listBranchByThuocVT(idThuoc, setIsloadingBranch))
        Toast.fire({
          icon: 'success',
          title: 'cập nhập thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'cập nhập thất bại!',
        })
      }
    },
  )
  //LẤY DANH SÁCH THUÔC VẬT TƯ THEO TỪ KHOÁ
  yield takeLatest(
    typeAction.GET_THUOC_VT_BY_KEYWORD,
    function* searchKeyword({ type, keyword, pageIndex }) {
      try {
        const result = yield call(() => khoVTservice.searchVTKeyword(keyword, pageIndex))
        yield put({
          type: typeAction.DISPATCH_LIST_THUOCVT,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LẤY LỊCH SỮ MÃ SỐ ĐĂNG KÝ
  yield takeLatest(typeAction.GET_HISTORY_MSDK, function* historyMSDK({ type, idThuoc }) {
    try {
      const result = yield call(() => khoVTservice.getMSDKThuoc(idThuoc))
      yield put({
        type: typeAction.DISPATCH_MSDK_THU_MUA,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //------------ LẤY DANH SÁCH NHÓM THUỐC VẬT TƯ THẺ KHO -----------------//
  yield takeLatest(typeAction.GET_GROUPS_THE_KHO, function* groupsTheKho({ type, idType }) {
    try {
      const result = yield call(() => khoVTservice.getListGroupsThuocVT(idType))
      yield put({
        type: typeAction.DISPATCH_GROUPS_THE_KHO,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  //---------------- LẤY DANH SÁCH THẺ KHO THEO CHI NHÁNH --------------- //
  yield takeLatest(typeAction.GET_KHO_THE_KHO, function* khoTheKho({ type, idChinhanh }) {
    try {
      const result = yield call(() => NhapKhoService.getListKhoNhap(idChinhanh))
      yield put({
        type: typeAction.DISPATCH_KHO_THE_KHO,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  //------------- lấy danh sách đường dùng thuốc vật tư ---------//
  yield takeLatest(typeAction.GET_DUONG_DUNG_THUOC, function* fetchDuongDung({ type }) {
    try {
      const result = yield call(() => khoVTservice.fetchDuongDung())
      yield put({
        type: typeAction.DISPATCH_DUONG_DUNG,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  //UPDATE THUỐC VẬT TƯ
  yield takeLatest(typeAction.UPDATE_THUOC_VAT_TU, function* updateThuocVT({ type, form }) {
    try {
      const result = yield call(() => khoVTservice.updateThuocVTById(form))
      yield put({
        type: typeAction.DISPATCH_INFO_KHO_VT, // update lại store
        payload: result.data,
      })
      Toast.fire({
        icon: 'success',
        title: 'cập nhập thành công!',
      })
    } catch (err) {
      yield console.log(err)
      Toast.fire({
        icon: 'error',
        title: err.response.data,
      })
    }
  })
  ////------------ update danh sách hoàng hóa hàng loặt -----------//
  yield takeLatest(
    typeAction.IMPORT_THU_MUA_HANG_LOAT,
    function* importThuMua({ type, form, setIsLoading }) {
      try {
        const totalItems = form.length
        setIsLoading(true)
        for (let index = 0; index < totalItems; index++) {
          const item = form[index]
          yield call(() => khoVTservice.addThuocVT(item))
          // Cập nhật tiến trình
          const progress = Math.round(((index + 1) / totalItems) * 100)
          yield put({
            type: typeAction.UPDATE_PROGRESS_THU_MUA,
            payload: progress,
          })
        }
        yield put({
          type: typeAction.DISPATCH_FILE_IMPORT_THU_MUA, // update lại store
          payload: [],
        })
        setIsLoading(false)
        Toast.fire({
          icon: 'success',
          title: 'cập nhập thành công!',
        })
      } catch (err) {
        yield console.log(err)
        setIsLoading(false)
        Toast.fire({
          icon: 'error',
          title: 'cập nhập thất bại!',
        })
      }
    },
  )
  //-------------------- LẤY DANH SÁCH LỊCH SỬ THẺ KHO ----------//
  yield takeLatest(
    typeAction.FILTER_HISTORY_THE_KHO,
    function* FilterTheKho({ type, filter, setIsLoading }) {
      try {
        setIsLoading(true)
        const { IDCT, branch, kho, dateFrom, dateTo, idThuoc } = filter
        const result = yield call(() =>
          khoVTservice.getTheKho(IDCT, branch, kho, dateFrom, dateTo, idThuoc),
        )
        yield put({
          type: typeAction.DISPATCH_HISTORY_THE_KHO, // update lại store
          payload: result.data,
        })
        setIsLoading(false)
      } catch (err) {
        yield console.log(err)
        setIsLoading(false)
      }
    },
  )
  //-------------- CHECK MA HANG DANH SACH THUOC VAT TU IMPORT -------------//
  yield takeLatest(
    typeAction.CHECK_IMPORT_DANH_SACH_VAT_TU,
    function* checkMaHang({ type, data, idct, openNotificationWithIcon }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const maHangArray = data.map((item) => item.maThuoc)
        yield call(() => khoVTservice.checkMaHangHoa(idct, maHangArray))
        yield put({
          type: typeAction.DISPATCH_FILE_IMPORT_THU_MUA,
          payload: data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        if (error.response.status === 400) {
          openNotificationWithIcon('error', `Trung mã hàng ${error.response.data.duplicates}`)
        }
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
