import * as typeAction from '../constants/constants'

export const getAllBNCRM = () => ({
  type: typeAction.GET_ALL_BN_CRM,
})

export const deleteBNCRMById = (id) => ({
  type: typeAction.DELETE_BN_CRM_BY_ID,
  id,
})

export const searchBNCRM = (idkh, keyword) => ({
  type: typeAction.SEARCH_BN_CRM,
  idkh,
  keyword,
})

export const postBNCRM = (payload) => ({
  type: typeAction.POST_BN_CRM,
  payload,
})

export const getListKHCRM = () => ({
  type: typeAction.GET_ALL_KH_CRM,
})

export const infoBNCRMAction = (id) => ({
  type: typeAction.GET_INFO_BN_CRM,
  id,
})

export const sendSMSCRMAction = (payload) => ({
  type: typeAction.SEND_SMS_BN_CRM,
  payload,
})

export const UpdateBNCRMAction = (id, form) => ({
  type: typeAction.UPDATE_INFO_BN_CRM,
  id,
  form,
})

export const importListBNCRM = (payload) => ({
  type: typeAction.IMPORT_LIST_BN_CRM,
  payload,
})

export const sendSMSCRMSendAll = (params, setReload, setSendAll) => ({
  type: typeAction.SEND_SMS_ALL_BN_CRM,
  params,
  setReload,
  setSendAll
})