import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Checkbox, Select, Radio, Popover } from 'antd'
import { SearchOutlined, PrinterOutlined } from '@ant-design/icons'
// import { calculateAge } from '../../../../utils/calculateAge'
import * as typeAction from '../../../../../store/constants/constants'
import { childrenClinicService } from '../../../../../services/childrenClinic/childrenClinicService'
import { putInfoChanDoanAction } from '../../../../../store/actions/khamSucKhoeAction'
import { generatePhieuToaThuoc } from '../../../../../utils/report/phieuToaThuoc'
import { generateTT32 } from '../../../../../utils/report/TT32'
import ToastCus from '../../../../../utils/Toast'
import ToaThuoc from './ToaThuoc/ToaThuoc'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import 'dayjs/locale/vi'
moment.locale('vi')
const now = moment()
const { TextArea } = Input
const dateFormat = 'DD/MM/YYYY'
const momentDate = 'YYYY-MM-DD'
const dvtKhongNhanSL = ['lọ', 'chai', 'hủ', 'tuýp', 'tube']
const listPhanLoai = [
  {
    value: 1,
    label: 'Loại I',
  },
  {
    value: 2,
    label: 'Loại II',
  },
  {
    value: 3,
    label: 'Loại III',
  },
  {
    value: 4,
    label: 'Loại IV',
  },
  {
    value: 5,
    label: 'Loại V',
  },
  {
    value: 6,
    label: 'Không phân loại',
  },
]
const FormCD = ({ formBenhNhan, formSinhHieu, tabKham, reloadListBN }) => {
  const dispatch = useDispatch()
  const ID_CHINHANH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_CONGTY_LOGIN = Number(localStorage.getItem('id_company'))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBN, infoBNTTC, infoToa, infoKetLuanKSK } = useSelector(
    (state) => state.khamSucKhoeReducer,
  )
  const [soNgayTK, setSoNgayTK] = useState(0)
  const [ngayHenTK, setNgayHenTK] = useState(now.format(momentDate))
  const [checkKhongKeToa, setCheckKhongKeToa] = useState(true)
  const [listLyDoBsKhongKeToa, setListLyDoBsKhongKeToa] = useState([])
  const [listChanDoan, setListChanDoan] = useState([])
  const [listLoiDan, setListLoiDan] = useState([])
  const [listThuocBN, setListThuocBN] = useState([])
  const [listThuocTon, setListThuocTon] = useState([])
  const [isLoadingLyDo, setIsLoadingLyDo] = useState(false)
  const [isLoadingThuocTon, setIsLoadingThuocTon] = useState(false)
  const [ngayThuoc, setNgayThuoc] = useState(2)
  const isBsKhongDuocKeToa =
    infoBNTTC &&
    ((infoBNTTC?.trangthaikhambenh === 2 && infoToa?.DANHANTOA) ||
      infoBNTTC?.trangthaikhambenh === 4)
  const lyDoDefault = listLyDoBsKhongKeToa?.find(
    (item) => item?.lydo === 'Khám sức khỏe không kê toa',
  )
  const initialState = {
    idbnttc: infoBNTTC?.idbnttc,
    lydokham: infoBNTTC?.lydokham ?? '',

    // trieuchung: infoBNTTC?.trieuchung ?? '',
    // idchandoan: infoBNTTC?.idchandoan,
    // chandoan: infoBNTTC?.chandoan ?? '',
    // idloidan: infoBNTTC?.idloidan,
    // loidan: infoBNTTC?.loidan ?? '',

    lydokolaythuoc: infoBNTTC?.lydokolaythuoc ?? lyDoDefault?.idld ?? 0,
    noidungkolaythuoc: infoBNTTC?.noidungkolaythuoc ?? lyDoDefault?.lydo ?? '',
    ghichu: infoBNTTC?.ghichu,

    // ngaytaikham: infoBNTTC?.ngaytaikham,
    // trangthaikhambenh: 2,
    // bskham: infoBNTTC?.bskham,
    // benhsu: infoBNTTC?.benhsu ?? '',
    // tiensubanthan: infoBNTTC?.tiensubanthan,
    // diung: infoBNTTC?.diung,
    // quatrinhbenhly: infoBNTTC?.quatrinhbenhly,
    // tieusugiadinh: infoBNTTC?.tieusugiadinh,
    // toanthan: infoBNTTC?.toanthan,
    // cacbophan: infoBNTTC?.cacbophan,
    // chandoanbandau: infoBNTTC?.chandoanbandau,
    // idicD10BANDAU: infoBNTTC?.idicD10BANDAU,
    // daxuly: infoBNTTC?.daxuly,
    // chandoanravien: infoBNTTC?.chandoanravien,
    // idicD10RAVIEN: infoBNTTC?.idicD10RAVIEN,
    // dieutringoaitru: infoBNTTC?.dieutringoaitru,
    // dienbienlamsang: infoBNTTC?.dienbienlamsang,
    // benhkemtheo: infoBNTTC?.benhkemtheo,
    // idicD10BENHKEMTHEO: infoBNTTC?.idicD10BENHKEMTHEO,
    // ppdieutri: infoBNTTC?.ppdieutri,
    // ttlucravien: infoBNTTC?.ttlucravien,
    // huongxuly: infoBNTTC?.huongxuly,
    // chieucao: infoBNTTC?.chieucao,
    // cannang: infoBNTTC?.cannang,
    // mach: infoBNTTC?.mach,
    // huyetap: infoBNTTC?.huyetap,
    // nhietdo: infoBNTTC?.nhietdo,
    // nhiptho: infoBNTTC?.nhiptho,
    // spO2: infoBNTTC?.spO2,
  }
  const [form, setForm] = useState(initialState)
  const initialStateKL = {
    idbnttc: infoBNTTC?.idbnttc,
    ketluannhantrachoc: infoKetLuanKSK?.ketluannhantrachoc ?? '',
    canlamsang: infoKetLuanKSK?.canlamsang ?? '',
    khamlamsang: infoKetLuanKSK?.khamlamsang ?? '',
    ketluan: infoKetLuanKSK?.ketluan ?? '',
    cacbenhtattheothongtu: infoKetLuanKSK?.cacbenhtattheothongtu ?? '',
    loai: infoKetLuanKSK?.loai ?? 1,
    danhgia: infoKetLuanKSK?.danhgia ?? 1,

    trieuchung: infoKetLuanKSK?.trieuchung ?? '',
    idchandoan: infoKetLuanKSK?.idchandoan,
    chandoan: infoKetLuanKSK?.chandoan ?? '',
    idloidan: infoKetLuanKSK?.idloidan,
    loidan: infoKetLuanKSK?.loidan ?? '',
  }
  const [formKL, setFormKL] = useState(initialStateKL)
  const maLoiDan =
    infoBNTTC && formKL?.idloidan
      ? `${listLoiDan?.find((item) => item?.idld === formKL?.idloidan)?.maLoiDan?.split(' ')[0] ?? ''} ...`
      : null

  const handleSubmit = (isPrint) => {
    if (isBsKhongDuocKeToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    if (
      // !ngayHenTK ||
      // soNgayTK < 0 ||
      // !form?.lydokham ||
      // !form?.trieuchung ||
      // !form?.loidan ||
      checkKhongKeToa &&
      !form?.lydokolaythuoc
    ) {
      // if (checkKhongKeToa)
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại thông tin!',
      })
      return
    }
    if (isPrint && !checkKhongKeToa && !listThuocBN?.length) {
      setCheckKhongKeToa(true)
      setForm((prev) => ({ ...prev, lydokolaythuoc: null }))
      handleChangeSoNgayTK(0)
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại lý do không kê toa!',
      })
      return
    }
    if (
      !checkKhongKeToa &&
      listThuocBN?.find(
        (item) =>
          !item?.soluong ||
          !item?.ton ||
          item?.soluong <= 0 ||
          item?.ton <= 0 ||
          item?.soluong > item?.ton,
      )
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng!',
      })
      return
    }
    dispatch(
      putInfoChanDoanAction(
        {
          ...form,
          ngaytaikham: moment().format('YYYY-MM-DD'),
          editbenhnhan: formBenhNhan,
          editsinhhieu: formSinhHieu,
        },
        {
          ...formKL,
          idbnttc: infoBNTTC?.idbnttc,
          // bskham: infoUser?.idnv,
          bsketluan: infoUser?.idnv,
        },
        checkKhongKeToa ? [] : listThuocBN, // không kê toa thì không truyền thuốc
        isPrint,
        resetForm,
      ),
    )
  }
  const resetForm = () => {
    setForm(initialState)
    setFormKL(initialStateKL)
    setSoNgayTK(0)
    setNgayHenTK(now.format(momentDate))
    setCheckKhongKeToa(true)
    setNgayThuoc(2)
    setListThuocBN([])
    reloadListBN()
    getListThuocTonChoBsKe()
  }

  const tinhLaiSLThuoc = (dvt, ngaythuoc, lieudung, solanngay, soluong, ton) => {
    const formatLieuDung = (lieudung) => {
      let text = ('' + lieudung)?.replace(/[^\d,.\/]/g, '')?.replaceAll(',', '.') //?.replaceAll(' ', '')
      if (text?.includes('/')) {
        const arr = text?.split('/')
        if (arr?.length === 2 && arr[1]?.length) return Number(arr[0]) / Number(arr[1])
      }
      return _.isNumber(+text) && !_.isNaN(+text) ? +text : 0
    }
    if (!dvt || !dvtKhongNhanSL?.includes(dvt?.replaceAll(' ', '')?.toLowerCase())) {
      const slnew = Math.ceil(ngaythuoc * formatLieuDung(lieudung) * solanngay)
      return slnew <= ton ? slnew : ton
    }
    return soluong
  }

  const tinhLaiSLListThuocBN = (ngaythuoc) => {
    setListThuocBN((prev) =>
      prev?.map((item) => ({
        ...item,
        soluong: tinhLaiSLThuoc(
          item?.dvt,
          ngaythuoc,
          item?.lieudung,
          item?.solanngay,
          item?.soluong,
          item?.ton,
        ),
      })),
    )
  }
  const demSoNgay = (dateString) => {
    const today = moment().format(momentDate)
    const ngaykham = moment(infoBNTTC?.ngaykham, momentDate).format(momentDate)
    const ngaytk = moment(dateString, dateFormat).format(momentDate)
    const startDate = moment(today)
    const startDateNgayKham = moment(ngaykham)
    const endDate = moment(ngaytk)
    return infoBNTTC
      ? infoBNTTC?.trangthaikhambenh === 1
        ? endDate.diff(startDate, 'days')
        : endDate.diff(startDateNgayKham, 'days')
      : 0
  }
  const handleChangeSoNgayTK = (value) => {
    setSoNgayTK(value)
    setNgayThuoc(value)
    tinhLaiSLListThuocBN(value)
    setNgayHenTK(moment()?.add(value, 'days')?.format('YYYY-MM-DD'))
  }
  const handleChangeNgayHenTK = (date, dateString) => {
    setSoNgayTK(demSoNgay(dateString))
    setNgayThuoc(demSoNgay(dateString))
    tinhLaiSLListThuocBN(demSoNgay(dateString))
    setNgayHenTK(moment(dateString, dateFormat).format(momentDate))
  }
  const handleChangeKhongKeToa = (e) => {
    if (isBsKhongDuocKeToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    const { checked } = e.target
    setCheckKhongKeToa(checked)
    if (checked) {
      const today = moment().format(dateFormat)
      setSoNgayTK(demSoNgay(today))
      setNgayThuoc(demSoNgay(today))
      tinhLaiSLListThuocBN(demSoNgay(today))
      setNgayHenTK(moment(today, dateFormat).format(momentDate))
    }
  }
  const getListLyDoBsKhongKeToa = async () => {
    try {
      setIsLoadingLyDo(true)
      const { data } = await childrenClinicService.getListLyDoKhongKeToa()
      setListLyDoBsKhongKeToa(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingLyDo(false)
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      getListChanDoan(keyword)
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const getListChanDoan = async (keyword) => {
    try {
      const { data } = await childrenClinicService.getChanDoan(keyword)
      setListChanDoan(data)
    } catch (error) {
      console.log('getListChanDoan : ', error)
    }
  }
  const getListLoiDan = async () => {
    try {
      const { data } = await childrenClinicService.getLoiDan()
      setListLoiDan(data)
    } catch (error) {
      console.log('getListLoiDan : ', error)
    }
  }
  const getListThuocTonChoBsKe = async () => {
    try {
      setIsLoadingThuocTon(true)
      const { data } = await childrenClinicService.getKetoaThuoc(ID_CHINHANH_LOGIN)
      let listVT = []
      await data
        ?.filter((item) => item?.tonKho > 0)
        ?.map((item, index) => {
          if (listVT?.find((itemVT) => itemVT?.idThuoc === item?.idThuoc)) {
            listVT?.map(
              (itemVT) => itemVT?.idThuoc === item?.idThuoc && (itemVT.tonKho += item.tonKho),
            )
          } else {
            listVT.push(item)
          }
        })
      // console.log(data)
      setListThuocTon(listVT)
    } catch (error) {
      console.log('getListThuocTonChoBsKe : ', error)
    } finally {
      setIsLoadingThuocTon(false)
    }
  }
  useEffect(() => {
    getListChanDoan('')
    getListLoiDan()
    getListLyDoBsKhongKeToa()
    getListThuocTonChoBsKe()
  }, [])
  useEffect(() => {
    setForm(initialState)
    const dateString = infoBNTTC?.ngaytaikham
      ? moment(infoBNTTC?.ngaytaikham, momentDate).format(dateFormat)
      : moment().format(dateFormat)
    setSoNgayTK(demSoNgay(dateString))
    setNgayThuoc(2)
    setNgayHenTK(moment(dateString, dateFormat).format(momentDate))
    if (infoBNTTC?.idbnttc) {
      getListThuocTonChoBsKe()
    }
  }, [infoBNTTC, tabKham])
  useEffect(() => {
    setFormKL(initialStateKL)
  }, [infoKetLuanKSK, tabKham])
  useEffect(() => {
    if (infoToa?.DanhSachThuoc?.length) {
      let listVT = []
      infoToa?.DanhSachThuoc?.map((item) => {
        const infoThuoc = listThuocTon?.find((itemTon) => itemTon?.idThuoc === item?.idthuoc)
        if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
          listVT?.map(
            (itemVT) =>
              itemVT?.idthuoc === item?.idthuoc &&
              (itemVT.soluong += item?.soluong ?? 0) &&
              (itemVT.ton += isBsKhongDuocKeToa ? 0 : item?.soluong + (infoThuoc?.tonKho ?? 0)),
          )
        } else {
          listVT.push({
            idthuoc: item?.idthuoc,
            tenbietduoc: item?.tenbietduoc,
            tenhoatchat: item?.tenhoatchat,
            dvt: item?.dvt,
            solanngay: item?.solanngay,
            lieudung: item?.lieudung,
            donvidung: item?.donvidung,
            duongdung: item?.duongdung,
            soluong: item?.soluong,
            ton: isBsKhongDuocKeToa // || infoBNTTC?.trangthaikhambenh === 1
              ? infoThuoc?.tonKho ?? 0
              : item?.soluong + (infoThuoc?.tonKho ?? 0),
            hamluong: item?.hamluong,
            ghichu: item?.ghichu,
            idkhocn: item?.idkhocn,
            giaban: item?.dongia,
            // solo: item?.solo,
            // handung: item?.handung,
            // sohoadon: item?.sohoadon,
            // masodangky: item?.masodangky,
            info: {
              thuocVatTu: {
                nongDo: item?.nongdo,
                quyCach: item?.quycach,
                idNhom: item?.idnhomthuoc,
                maThuoc: item?.mathuoc,
              },
            },
            // bskedon: infoUser?.tenNV,
            // dvt: ThuocVT[index].thuocVatTu.dvt,
            // ngayKeDon: "2024-05-23T16:39:02.286Z",
            // lieuDungTinh: 1,
            // idbnttc: infoBNTTC?.idbnttc,
          })
        }
        setCheckKhongKeToa(false)
        setListThuocBN(listVT)
      })
    } else {
      setListThuocBN([])
      setCheckKhongKeToa(infoBNTTC?.lydokolaythuoc || !infoBNTTC?.idbskham ? true : false)
    }
  }, [infoToa, infoToa?.DanhSachThuoc, infoToa?.DanhSachThuoc?.length])

  const fetchMissingOption = async (idIcd) => {
    try {
      const { data } = await childrenClinicService.getChanDoanByidICD(idIcd)
      const newOption = data
      //   console.log(ChanDoan, newOption)
      setListChanDoan((prev) => [...prev, newOption])
    } catch (error) {
      console.log('fetchMissingOption : ', error)
    }
  }
  useEffect(() => {
    if (form?.idchandoan && !listChanDoan?.find((item) => item?.idicD10 === form?.idchandoan)) {
      fetchMissingOption(form?.idchandoan)
    }
  }, [form?.idchandoan])

  const handlePrint = () => {
    //có thuốc in lại toa
    const infoBn = {
      ...infoBN,
      diaChi: infoBNTTC?.benhNhan?.diaChi,
      tenPhuongXa: infoBNTTC?.benhNhan?.tenPhuongXa,
      tenQuanHuyen: infoBNTTC?.benhNhan?.tenQuanHuyen,
      tenTinhTP: infoBNTTC?.benhNhan?.tenTinhTP,
    }
    let listVT = []
    // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
    for (const item of infoToa?.DanhSachThuoc) {
      if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
        listVT?.map(
          (itemVT) =>
            itemVT?.idthuoc === item?.idthuoc &&
            (itemVT.soLuong += item.soluong) &&
            (itemVT.soluong += item.soluong),
        )
      } else {
        listVT.push({
          ...item,
          soLuong: item?.soluong,
          tenhoatchat: item?.tenhoatchat,
          duongDung: item?.duongdung,
          soLanNgay: item?.solanngay,
          lieuDung: item?.lieudung,
          ghiChu: item?.ghichu,
        })
      }
    }
    generatePhieuToaThuoc(infoBn, infoBNTTC, listVT, PkDangNhap)
  }
  const divInputFormCD = (label, field, require) => {
    return (
      <div className="grid grid-cols-7 items-center gap-1">
        <div className="font-semibold text-end">
          {require && <span className="text-red-500">(*)&nbsp;</span>}
          {label}:
        </div>
        <div className="col-span-6">
          <Input
            disabled={isBsKhongDuocKeToa}
            size="small"
            className="w-full"
            style={{ color: 'black' }}
            status={require && infoBNTTC && !form[field] ? 'error' : ''}
            value={form[field]}
            onChange={(e) => setForm((prev) => ({ ...prev, [field]: e.target.value }))}
          />
        </div>
      </div>
    )
  }
  const divInputFormKLCD = (label, field, require) => {
    return (
      <div className="grid grid-cols-7 items-center gap-1">
        <div className="font-semibold text-end">
          {require && <span className="text-red-500">(*)&nbsp;</span>}
          {label}:
        </div>
        <div className="col-span-6">
          <Input
            disabled={isBsKhongDuocKeToa}
            size="small"
            className="w-full"
            style={{ color: 'black' }}
            status={require && infoBNTTC && !formKL[field] ? 'error' : ''}
            value={formKL[field]}
            onChange={(e) => setFormKL((prev) => ({ ...prev, [field]: e.target.value }))}
          />
        </div>
      </div>
    )
  }
  const divSelectICD10 = (label, field, fieldIDC, require) => {
    const onClickChanDoan = (value, options, key, keyIDC) => {
      const newValue = options?.mabenh + '-' + options?.icD10TV + '/'
      setFormKL((prev) => ({ ...prev, [key]: newValue, [keyIDC]: value }))
    }
    return (
      <div className="grid grid-cols-7 items-center gap-1">
        <div className="font-semibold text-end">
          {require && <span className="text-red-500">(*)&nbsp;</span>}
          {label}:
        </div>
        <div className="col-span-6">
          <div className="flex justify-start items-center gap-3">
            <Select
              disabled={isBsKhongDuocKeToa}
              placeholder="ICD-10"
              size="small"
              className="w-28"
              style={{ color: 'black' }}
              showSearch
              dropdownStyle={{ width: 550 }}
              filterOption={false}
              onChange={(value, options) => onClickChanDoan(value, options, field, fieldIDC)}
              onSearch={debounceDropDown}
              suffixIcon={<SearchOutlined />}
              notFoundContent={null}
              value={formKL[fieldIDC]}
              options={listChanDoan?.map((item) => ({
                label: item?.mabenh,
                value: item?.idicD10,
                mabenh: item?.mabenh,
                icD10TV: item?.icD10TV,
                ...item,
              }))}
              optionRender={(options) => (
                <ul className="flex ">
                  <li className="w-10 mr-2">{options?.data?.mabenh}</li>
                  <li className="w-full border-l px-2 truncate">{options?.data?.icD10TV}</li>
                </ul>
              )}
            />
            <Input
              disabled={isBsKhongDuocKeToa}
              size="small"
              className="w-full"
              style={{ color: 'black' }}
              status={require && infoBNTTC && !formKL[field] ? 'error' : ''}
              value={formKL[field]}
              onChange={(e) => setFormKL((prev) => ({ ...prev, [field]: e.target.value }))}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="mt-1">
      <div className="flex justify-end items-center mb-2">
        <div className="flex justify-start items-center gap-5 mr-5">
          {/* <div className="flex justify-start items-center gap-1">
            <div className="font-semibold">Số ngày TK:</div>
            <InputNumber
              disabled={checkKhongKeToa || !isBsDuocKeToa}
              size="small"
              className="w-12"
              style={{ color: 'black' }}
              min={0}
              step={1}
              status={infoBNTTC && soNgayTK < 0 ? 'error' : ''}
              value={soNgayTK}
              onChange={(value) => handleChangeSoNgayTK(value > 0 ? value : 0)}
            />
          </div>
          <div className="flex justify-start items-center gap-1">
            <div className="font-semibold">Hẹn tái khám:</div>
            <DatePicker
              disabled={checkKhongKeToa || !isBsDuocKeToa}
              placeholder=" "
              size="small"
              className="w-28"
              style={{ color: 'black' }}
              allowClear={false}
              status={infoBNTTC && (!ngayHenTK || soNgayTK < 0) ? 'error' : ''}
              value={ngayHenTK ? dayjs(ngayHenTK) : null}
              minDate={dayjs(now)}
              onChange={handleChangeNgayHenTK}
              format={dateFormat}
              locale={{ ...locale, week: { start: 1 } }}
            />
          </div> */}
          <div className="flex justify-start items-center gap-1">
            <Checkbox checked={checkKhongKeToa} onChange={handleChangeKhongKeToa}>
              Không kê toa
            </Checkbox>
          </div>
        </div>
        <div className="flex justify-center items-center gap-2">
          {infoBNTTC?.trangthaikhambenh === 1 ? (
            <Button
              type="primary"
              disabled={!infoBN || !infoBNTTC || infoBNTTC?.trangthaikhambenh === 4}
              onClick={() => handleSubmit(false)}
            >
              Cập nhật
            </Button>
          ) : (
            <Popover
              content={() => {
                return (
                  <div className="w-32">
                    <Button
                      type="primary"
                      disabled={!infoBN || !infoBNTTC || !infoToa?.DanhSachThuoc?.length}
                      onClick={handlePrint}
                      style={{ marginBottom: '8px', width: '100%' }}
                    >
                      Toa thuốc
                    </Button>
                    <Button
                      type="primary"
                      disabled={!infoBN || !infoBNTTC}
                      onClick={() => generateTT32(PkDangNhap)}
                      style={{ width: '100%' }}
                    >
                      Phiếu in TT32
                    </Button>
                  </div>
                )
              }}
              trigger="click"
              placement="bottom"
            >
              <Button type="primary" disabled={!infoBN || !infoBNTTC}>
                <PrinterOutlined /> In
              </Button>
            </Popover>
          )}
          <Button
            type="primary"
            disabled={!infoBN || !infoBNTTC || infoBNTTC?.trangthaikhambenh === 4}
            onClick={() => handleSubmit(true)}
          >
            Kết luận
          </Button>
        </div>
      </div>
      <div className="grid grid-flow-row items-center overflow-auto gap-1">
        {checkKhongKeToa && (
          <div className="grid grid-cols-7 items-center gap-1">
            <div className="font-semibold text-end">
              <span className="text-red-500">(*)&nbsp;</span>
              Lý do không kê toa:
            </div>
            <div className="col-span-6">
              <Select
                disabled={isLoadingLyDo || isBsKhongDuocKeToa}
                size="small"
                className="w-full"
                style={{ color: 'black' }}
                status={infoBNTTC && !form?.lydokolaythuoc ? 'error' : ''}
                value={
                  isLoadingLyDo
                    ? 'Đang lấy dữ liệu...'
                    : infoBNTTC && form?.lydokolaythuoc
                      ? form?.lydokolaythuoc
                      : null
                }
                onChange={(value, opt) =>
                  setForm((prev) => ({
                    ...prev,
                    lydokolaythuoc: value,
                    noidungkolaythuoc: opt?.label,
                  }))
                }
                options={listLyDoBsKhongKeToa?.map((item) => ({
                  key: item?.idld,
                  value: item?.idld,
                  label: item?.lydo,
                }))}
              />
            </div>
          </div>
        )}
        {divInputFormCD('Lý do khám', 'lydokham', false)}
        {/* {divInputFormCD('Bệnh sử', 'benhsu', true)} */}
        {divInputFormKLCD('Triệu chứng', 'trieuchung', false)}
        {divSelectICD10('Chẩn đoán', 'chandoan', 'idchandoan', false)}
        {/* {divSelectICD10('Chẩn đoán bệnh phụ', 'chandoan', 'idchandoan', false)}
        {divSelectICD10('Chẩn đoán ban đầu', 'chandoan', 'idchandoan', false)}
        {divSelectICD10('Chẩn đoán ra viện', 'chandoan', 'idchandoan', false)} */}
        <div className="grid grid-cols-7 items-center gap-1">
          <div className="font-semibold text-end">
            {/* <span className="text-red-500">(*)&nbsp;</span> */}
            Lời dặn:
          </div>
          <div className="col-span-6">
            <div className="flex justify-start items-center gap-3">
              <Select
                disabled={isBsKhongDuocKeToa}
                size="small"
                className="w-28"
                style={{ color: 'black' }}
                placeholder="Gợi ý"
                showSearch
                suffixIcon={<SearchOutlined />}
                dropdownStyle={{ width: 750 }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                  (option?.mald ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                optionRender={(options) => (
                  <h2 className="text-pretty border-b">
                    {options?.data?.mald} - {options?.data?.label}
                  </h2>
                )}
                value={maLoiDan}
                onChange={(value, opt) =>
                  setFormKL((prev) => ({ ...prev, idloidan: value, loidan: opt?.label }))
                }
                options={listLoiDan?.map((item) => ({
                  mald: item?.maLoiDan,
                  label: item?.loiDan,
                  value: item?.idld,
                  key: item?.idld,
                }))}
              />
              <Input
                disabled={isBsKhongDuocKeToa}
                size="small"
                className="w-full"
                style={{ color: 'black' }}
                // status={infoBNTTC && !formKL?.loidan ? 'error' : ''}
                value={formKL?.loidan}
                onChange={(e) => setFormKL((prev) => ({ ...prev, loidan: e.target.value }))}
              />
            </div>
          </div>
        </div>
        {divInputFormCD('Ghi chú', 'ghichu', false)}

        <div className="grid grid-flow-row items-center mt-2 gap-3">
          <div className="grid grid-cols-2 items-center text-[12.5px] border rounded-md p-1 bg-slate-50">
            <div className="grid grid-cols-6 gap-1 items-center">
              <div className="font-semibold text-end">
                Kết luận
                <br />
                nhân trắc học:
              </div>
              <TextArea
                disabled={isBsKhongDuocKeToa}
                className="col-span-5 w-full text-[12.5px]"
                style={{ color: 'black' }}
                rows={3}
                maxLength={2000}
                value={formKL?.ketluannhantrachoc}
                onChange={(e) =>
                  setFormKL((prev) => ({ ...prev, ketluannhantrachoc: e.target.value }))
                }
              />
            </div>
            <div className="grid grid-cols-6 gap-1 items-center">
              <div className="font-semibold text-end">Cận lâm sàng:</div>
              <TextArea
                disabled={isBsKhongDuocKeToa}
                className="col-span-5 w-full text-[12.5px]"
                style={{ color: 'black' }}
                rows={3}
                maxLength={2000}
                value={formKL?.canlamsang}
                onChange={(e) => setFormKL((prev) => ({ ...prev, canlamsang: e.target.value }))}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 items-center text-[12.5px] border rounded-md p-1 bg-slate-50">
            <div className="grid grid-cols-6 gap-1 items-center">
              <div className="font-semibold text-end">Khám lâm sàng:</div>
              <TextArea
                disabled={isBsKhongDuocKeToa}
                className="col-span-5 w-full text-[12.5px]"
                style={{ color: 'black' }}
                rows={3}
                maxLength={2000}
                value={formKL?.khamlamsang}
                onChange={(e) => setFormKL((prev) => ({ ...prev, khamlamsang: e.target.value }))}
              />
            </div>
            <div className="grid grid-cols-6 gap-1 items-center">
              <div className="font-semibold text-end">
                Các bệnh tật
                <br />
                theo thông tư:
              </div>
              <TextArea
                disabled={isBsKhongDuocKeToa}
                className="col-span-5 w-full text-[12.5px]"
                style={{ color: 'black' }}
                rows={3}
                maxLength={2000}
                value={formKL?.cacbenhtattheothongtu}
                onChange={(e) =>
                  setFormKL((prev) => ({ ...prev, cacbenhtattheothongtu: e.target.value }))
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-12 gap-1 items-center text-[12.5px] border rounded-md p-1 bg-slate-50">
            <div className="font-semibold text-end">Kết luận:</div>
            <TextArea
              disabled={isBsKhongDuocKeToa}
              className="col-span-11 w-full text-[12.5px]"
              style={{ color: 'black' }}
              rows={3}
              maxLength={2000}
              value={formKL?.ketluan}
              onChange={(e) => setFormKL((prev) => ({ ...prev, ketluan: e.target.value }))}
              // value={form?.ketluan}
              // onChange={(e) => handleChangeForm('ketluan', e.target.value)}
            />
          </div>
          <div className="grid grid-cols-2 items-center text-[12.5px] border rounded-md p-1 bg-slate-50">
            <div className="grid grid-cols-6 gap-1 items-center">
              <div className="font-semibold text-end">Phân loại:</div>
              <div className="col-span-5 w-full border rounded-md pl-2 py-6 bg-white">
                <Radio.Group
                  value={infoBNTTC ? formKL?.loai : null}
                  onChange={(e) => {
                    if (isBsKhongDuocKeToa) {
                      ToastCus.fire({
                        icon: 'error',
                        title: 'Dược sĩ đang thao tác!',
                      })
                      return
                    }
                    setFormKL((prev) => ({ ...prev, loai: e.target.value }))
                  }}
                  className="flex justify-center items-center gap-2"
                >
                  {listPhanLoai?.map((item) => (
                    <Radio value={item?.value} className="text-[12.5px] flex items-center">
                      {item?.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-1 items-center">
              <div className="font-semibold text-end">Đánh giá:</div>
              <div className="col-span-5 w-full border rounded-md pl-2 py-6 bg-white">
                <Radio.Group
                  value={infoBNTTC ? formKL?.danhgia : null}
                  onChange={(e) => {
                    if (isBsKhongDuocKeToa) {
                      ToastCus.fire({
                        icon: 'error',
                        title: 'Dược sĩ đang thao tác!',
                      })
                      return
                    }
                    setFormKL((prev) => ({ ...prev, danhgia: e.target.value }))
                  }}
                  className="flex justify-center items-center gap-24"
                >
                  <Radio value={1} className="text-[12.5px] flex items-center">
                    Đủ sức khỏe làm việc
                  </Radio>
                  <Radio value={2} className="text-[12.5px] flex items-center">
                    Không đủ sức khỏe làm việc
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>
        {!checkKhongKeToa && (
          <>
            <ToaThuoc
              listThuocBN={listThuocBN}
              setListThuocBN={setListThuocBN}
              listThuocTon={listThuocTon}
              isLoadingThuocTon={isLoadingThuocTon}
              ngayThuoc={ngayThuoc}
              setNgayThuoc={setNgayThuoc}
              tinhLaiSLThuoc={tinhLaiSLThuoc}
              tinhLaiSLListThuocBN={tinhLaiSLListThuocBN}
              dvtKhongNhanSL={dvtKhongNhanSL}
              isBsKhongDuocKeToa={isBsKhongDuocKeToa}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default FormCD
