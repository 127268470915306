import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigProvider, Table, Checkbox, Input, Select, Button, notification } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import * as typeAction from '../../../../store/constants/constants'
import { getInfoSoLanTapVLTLAction } from '../../../../store/actions/vltlAction'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { TextArea } = Input
const InfoVLTL = ({ ghiChu, setGhiChu, soLanConLai }) => {
  const dispatch = useDispatch()
  const { infoBNTapVLTL, infoSoLanTapVLTL } = useSelector((state) => state.vltlReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (message, description) => {
    api.warning({
      message,
      description,
    })
  }
  const onClickDetailDVBN = (record) => {
    dispatch(getInfoSoLanTapVLTLAction(record.idbnttc, record.iddv))
    setGhiChu(null)
  }
  return (
    <div className="border rounded">
      {contextHolder}
      <div className="mb-2">
        {/* <Divider orientation="left">Vật tư tiêu hao
      </Divider> */}
        <div className="flex justify-between">
          <h2 className="font-semibold  text-lg ml-2 mt-2 text-gray-500">
            Theo dõi lần số lần tập
            {infoSoLanTapVLTL?.TENDICHVU ? <span> - {infoSoLanTapVLTL?.TENDICHVU}</span> : <></>}
          </h2>
          <div className="flex items-center pt-2 justify-end">
            <label className="font-semibold">Còn lại:</label>
            <Input
              className="w-1/4 m-1 mr-2"
              size="small"
              variant="filled"
              readOnly
              value={soLanConLai}
            />
          </div>
        </div>
        <div className="p-2 pt-0">
          {/* <div className='flex'>
            <div className='w-9/12'>
              <label className="font-semibold">Gói tập vltl - 20 lần: </label>
              <div className='grid grid-cols-5'>
                <div>
                  <Checkbox checked />
                  <label className='ml-1'>02/07/2024</label>
                </div>
              </div>
            </div>
            <div className='w-3/12'>
              <label className="font-semibold">Ghi chú: </label>
              <TextArea
                rows={5}
                // onChange={formikPhuSan.handleChange}
                // value={formikPhuSan.values.gnkhac}
                name="ghichu"
                size="small"
              />
            </div>
          </div> */}
          {/* <Select
            placeholder="Nhập tên vật tư..."
            className='w-full mb-2'
            showSearch
            onChange={handleVTTieuHao}
            filterOption={(input, option) => {
              const label = option?.label ?? ''
              const tenBietDuoc = option?.tenBietDuoc ?? ''
              const searchText = input.toLowerCase()
              return (
                label.toLowerCase().includes(searchText) ||
                tenBietDuoc.toLowerCase().includes(searchText)
              )
            }}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            value={null}
            options={listVT?.map((items) => ({
              key: items.iD,
              value: items.iD,
              label: items.thuocVatTu.tenHoatChat,
              tenBietDuoc: items.thuocVatTu.tenBietDuoc,
              dvt: items.thuocVatTu.dvt,
              soLo: items.soLo,
              hanDung: items.hanDung,
              soHoaDon: items.soHoaDon,
              maSoDangKy: items.maSoDangKy,
              ton: items.tonKho,
            }))}
            optionRender={(options) => (<ul className='flex'>
              <li className="w-[50%] ">
                <p className="font-semibold w-full text-pretty">{options.data.label}</p>
                <span className="text-xs w-full text-gray-500"> ({options.data.tenBietDuoc})</span>
              </li>
              <li className="w-[12.5%] border-x flex items-center justify-center text-center">
                {options.data.soLo}
              </li>
              <li className="w-[12.5%] text-center flex items-center justify-center">
                {moment(options.data.hanDung).format('DD/MM/YYYY')}
              </li>
              <li className="w-[12.5%] border-x text-center flex items-center justify-center">
                {options.data.ton}
              </li>
              <li className="w-[12.5%]  text-center flex items-center justify-center">
                {options.data.dvt}
              </li>
            </ul>
            )}
          /> */}
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              pagination={false}
              dataSource={infoSoLanTapVLTL?.DanhSachBenhNhanVatLyTriLieu ?? []}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'stt',
                  key: 'stt',
                  align: 'center',
                  width: 40,
                  render: (text, record, index) => ++index,
                },
                {
                  title: 'Ngày thực hiện',
                  dataIndex: 'ngaytap',
                  key: 'ngaytap',
                  align: 'center',
                  width: 115,
                  render: (text, record, index) => text && moment(text).format('DD/MM/YYYY'),
                },
                {
                  title: 'Người thực hiện',
                  dataIndex: 'nguoitao',
                  key: 'nguoitao',
                  width: 280,
                },
                {
                  title: 'Ghi chú',
                  dataIndex: 'ghichu',
                  key: 'ghichu',
                  align: 'center',
                  render: (text, record, index) => (
                    <Input
                      // onChange={(e) => {
                      //   handleChangeGhiChu(record, e.target.value)
                      // }}
                      value={text ?? ''}
                      size="small"
                      variant="filled"
                      readOnly
                    />
                  ),
                },
                {
                  title: '',
                  dataIndex: 'action',
                  key: 'action',
                  align: 'center',
                  width: 40,
                  render: (text, record, index) => (
                    <>
                      {/* <CloseOutlined
                        onClick={() => {
                          dispatch({
                            type: typeAction.DISPATCH_DELETE_VT_BN_THUTHUAT,
                            payload: record.iD,
                          })
                        }}
                        className="text-red-500 cursor-pointer"
                      /> */}
                    </>
                  ),
                },
              ]}
              bordered
              scroll={{ y: 175 }}
              summary={() =>
                infoSoLanTapVLTL &&
                soLanConLai > 0 && (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="text-center">
                      <Table.Summary.Cell index={0}>
                        {/* {1 + (infoSoLanTapVLTL?.DanhSachBenhNhanVatLyTriLieu?.length ?? 0)} */}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        {moment().format('DD/MM/YYYY')}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} className="text-left">
                        {infoUser?.tenNV}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Input
                          onChange={(e) => {
                            setGhiChu(e.target.value)
                          }}
                          value={ghiChu}
                          size="small"
                        />
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )
              }
            />
          </ConfigProvider>
        </div>
      </div>
      <div className="p-2">
        {/* <Divider orientation="left">Dịch vụ</Divider> */}
        <h2 className="font-semibold  text-lg m-2 text-gray-500">Dịch vụ</h2>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            pagination={false}
            dataSource={infoBNTapVLTL?.DanhSachVatGoiVatLyTriLieu ?? []}
            onRow={(record) => ({
              onClick: () => record.solanvltl > 0 && onClickDetailDVBN(record),
            })}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Mã DV',
                dataIndex: 'madichvu',
                key: 'madichvu',
                width: 130,
              },
              {
                title: 'Tên dịch vụ',
                dataIndex: 'tendichvu',
                key: 'tendichvu',
                render: (text, record, index) =>
                  record.trangthaikb === 4 ? text + ' - Đã hoàn thành' : text,
              },
              {
                title: 'Số lần tập',
                dataIndex: 'solanvltl',
                key: 'solanvltl',
                width: 90,
                align: 'center',
              },
            ]}
            bordered
            scroll={{ y: 175 }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default InfoVLTL
