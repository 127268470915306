import moment from 'moment'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import 'moment/locale/vi'
moment.locale('vi')

export const columnsDetails = [
  {
    title: 'STT',
    dataIndex: 'STT',
    align: 'center',
    key: 'STT',
    width: 40,
  },
  {
    title: 'Thông tin phiếu chuyển',
    dataIndex: 'STT',
    align: 'center',
    key: 'STT',
    children: [
      {
        title: 'Mã phiếu',
        dataIndex: 'maphieu',
        align: 'center',
        key: 'maphieu',
        width: 150,
      },
      {
        title: 'Ngày nhập',
        dataIndex: 'ngaynhan',
        key: 'ngaynhan',
        width: 150,
        render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
      },
      {
        title: 'Tên phiếu',
        dataIndex: 'tenphieu',
        key: 'tenphieu',
        width: 350,
      },

      {
        title: 'Số hoá đơn',
        dataIndex: 'sohoadon',
        align: 'center',
        key: 'sohoadon',
        width: 150,
      },
      {
        title: 'Ngày HĐ',
        dataIndex: 'ngayhoadon',
        align: 'center',
        key: 'ngayhoadon',
        width: 100,
        render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
      },
      {
        title: 'Chi nhánh chuyển',
        dataIndex: 'tenchinhanh',
        width: 250,
        key: 'tenchinhanh',
      },
      {
        title: 'Kho chuyển',
        dataIndex: 'tenkho',
        key: 'tenkho',
        width: 200,
      },
      {
        title: 'Chi nhánh nhập',
        dataIndex: 'tenchinhanhnhap',
        width: 250,
        key: 'tenchinhanhnhap',
      },
      {
        title: 'Kho nhập',
        dataIndex: 'tenkhonhap',
        key: 'tenkhonhap',
        width: 200,
      },
    ],
  },
  {
    title: 'Thông tin hàng hoá',
    dataIndex: 'tenThuocVatTu',
    key: 'tenThuocVatTu',
    align: 'center',
    children: [
      {
        title: 'Mã hàng',
        dataIndex: 'mahang',
        key: 'maHang',
        width: 120,
      },
      {
        title: 'Tên hoạt chất ',
        dataIndex: 'tenhoatchat',
        key: 'tenThuocVatTu',
        width: 250,
      },
      {
        title: 'Tên biệt dược',
        dataIndex: 'tenbietduoc',
        key: 'tenbietduoc',
        width: 250,
      },

      {
        title: 'SL chẳn',
        dataIndex: 'soLuongChan',
        key: 'soLuongChan',
        align: 'center',
        width: 90,
      },
      {
        title: 'Đơn vi chẳn',
        dataIndex: 'donViChan',
        align: 'center',
        key: 'donViChan',
        width: 120,
      },
      {
        title: 'Giá bán',
        dataIndex: 'giaban',
        align: 'right',
        key: 'giaban',
        width: 120,
        render: (text) => (text ? formatNumberVND(text) : 0),
      },
      {
        title: 'Quy cách',
        dataIndex: 'quycach',
        key: 'quycach',
        align: 'center',
        width: 120,
      },
      {
        title: 'SL lẻ',
        dataIndex: 'soLuongLe',
        key: 'soLuongLe',
        align: 'center',
        width: 90,
      },
      {
        title: 'Mã số ĐK',
        dataIndex: 'maSoDangKi',
        key: 'maSoDangKi',
        align: 'left',
        width: 120,
      },
      {
        title: 'Số Lô',
        dataIndex: 'soLo',
        key: 'soLo',
        align: 'center',
        width: 120,
      },
      {
        title: 'Hạn Dùng',
        dataIndex: 'hanDung',
        key: 'hanDung',
        width: 120,
        align: 'center',
        render: (text) => (text ? moment(text).format('DD/MM/YYYY') : ''),
      },
      {
        title: 'Ghi chú',
        dataIndex: 'ghiChu',
        key: 'ghiChu',
        width: 200,
      },
    ],
  },
]
