import React, { useRef } from 'react'
import { Image, Checkbox, Button } from 'antd'
import { DeleteOutlined, CloudUploadOutlined } from '@ant-design/icons'
import ToastCus from '../../../../utils/Toast'
import imageNotFound from '../../../../assets/images/imageNotFound.jpg'
import { v4 as uuidv4 } from 'uuid'

const HinhAnh = ({
  selectedFile,
  setSelectedFile,
  listImage,
  setlistImage,
  isKhongDuocChinhSua,
}) => {
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const deleteFileById = (id) => {
    if (isKhongDuocChinhSua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ KTV X-Quang và cùng ngày mới được chỉnh sửa!',
      })
      return
    }
    const updatedData = selectedFile.filter((item) => item?.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDivClick = () => {
    if (isKhongDuocChinhSua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ KTV X-Quang và cùng ngày mới được chỉnh sửa!',
      })
      return
    }
    fileInputRef.current.click()
  }
  const handleDragOver = (e) => {
    if (isKhongDuocChinhSua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ KTV X-Quang và cùng ngày mới được chỉnh sửa!',
      })
      return
    }
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }
  const handleDragLeave = (e) => {
    if (isKhongDuocChinhSua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ KTV X-Quang và cùng ngày mới được chỉnh sửa!',
      })
      return
    }
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
  }
  const handleDrop = (e) => {
    if (isKhongDuocChinhSua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ KTV X-Quang và cùng ngày mới được chỉnh sửa!',
      })
      return
    }
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
    // handleFileChange(e.dataTransfer.files)
    handleFileChange(Object.values(e.dataTransfer.files))
  }
  const handleFileSelect = (e) => {
    if (isKhongDuocChinhSua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ KTV X-Quang và cùng ngày mới được chỉnh sửa!',
      })
      return
    }
    // handleFileChange(e.target.files)Object.values(e.target.files)
    handleFileChange(Object.values(e.target.files))
  }
  const handleFileChange = async (files) => {
    if (isKhongDuocChinhSua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ KTV X-Quang và cùng ngày mới được chỉnh sửa!',
      })
      return
    }
    //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'application/pdf',
    ]
    if (!files?.length || files?.find((item) => !allowedFileTypes.includes(item?.type))) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ chấp nhận tệp là hình ảnh hoặc pdf!',
      })
      fileInputRef.current.value = '' // clear the input
      return
    }
    if (files?.concat(listImage, selectedFile)?.length > 20) {
      ToastCus.fire({
        icon: 'error',
        title: 'Tối đa chỉ được phép 20 file!',
      })
      fileInputRef.current.value = '' // clear the input
      return
    }
    for (const file of files) {
      fileInputRef.current.value = ''
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const localUrl = URL.createObjectURL(file)
        setSelectedFile((preState) => [
          ...preState,
          {
            base64: e.target.result,
            URL: localUrl,
            name: file.name,
            id: uuidv4(),
            file,
            checked: false,
          },
        ])
      }
    }
  }
  const checkHinhCu = (url, checked) => {
    if (checked && listImage?.concat(selectedFile)?.filter((item) => item?.checked)?.length >= 8) {
      ToastCus.fire({
        icon: 'error',
        title: 'Tối đa chỉ được phép in 8 hình!',
      })
      return
    }
    setlistImage((prev) =>
      prev?.map((item) => (item?.url === url ? { ...item, checked: checked } : item)),
    )
  }
  const checkHinhMoi = (id, checked) => {
    if (checked && listImage?.concat(selectedFile)?.filter((item) => item?.checked)?.length >= 8) {
      ToastCus.fire({
        icon: 'error',
        title: 'Tối đa chỉ được phép in 8 hình!',
      })
      return
    }
    setSelectedFile((prev) =>
      prev?.map((item) => (item?.id === id ? { ...item, checked: checked } : item)),
    )
  }
  const deleteHinhCu = (urlXoa) => {
    if (isKhongDuocChinhSua) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ KTV X-Quang và cùng ngày mới được chỉnh sửa!',
      })
      return
    }
    setlistImage((prev) => {
      return prev?.filter((itemCu) => itemCu?.url !== urlXoa)
    })
  }
  return (
    <div className="px-2">
      <ul
        className={`flex flex-wrap gap-2 mb-2 overflow-y-auto ${listImage?.concat(selectedFile)?.length > 5 ? ' h-[14rem] ' : ''}`}
      >
        {listImage?.concat(selectedFile)?.map((item, index) => (
          <li key={`item${index}`} className="rounded-md border grid grid-flow-row">
            <div className="w-[9.7rem] h-[8rem] rounded-t-md overflow-hidden flex justify-center items-center">
              <Image
                loading="lazy"
                key={`image${index}`}
                // alt={item?.id ? item?.base64 : item?.url}
                src={item?.id ? item?.base64 : item?.url}
                fallback={imageNotFound}
              />
            </div>
            <div className="h-[2rem] z-10 border-t flex justify-center items-center gap-10">
              <Checkbox
                checked={item?.checked}
                onChange={(e) =>
                  item?.id
                    ? checkHinhMoi(item?.id, e.target.checked)
                    : checkHinhCu(item?.url, e.target.checked)
                }
              />
              <style>{`
                .buttonDelete {
                  width: 24px !important;
                  padding: 3px !important;
                  font-size: 12px !important;
                  line-height: 12px !important;
                  height: fit-content !important;
                }
              `}</style>
              <Button
                danger
                type="primary"
                icon={<DeleteOutlined />}
                className="buttonDelete"
                onClick={() => (item?.id ? deleteFileById(item?.id) : deleteHinhCu(item?.url))}
              />
            </div>
          </li>
        ))}
      </ul>
      <div
        style={{
          border: '2px dashed #cccccc',
          borderRadius: '4px',
          padding: '30px',
          textAlign: 'center',
          cursor: 'pointer',
        }}
        className="border"
        ref={dropzoneRef}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleDivClick}
      >
        <CloudUploadOutlined style={{ fontSize: 36 }} className="text-gray-500" />
        <label style={{ cursor: 'pointer' }}>
          <p className="font-semibold text-lg text-gray-500">
            Thả tập tin vào đây hoặc bấm vào để tải lên.
          </p>
        </label>
        <input multiple ref={fileInputRef} id="fileInput" type="file" onChange={handleFileSelect} />
      </div>
    </div>
  )
}

export default HinhAnh
