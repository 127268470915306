import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Input, Select } from 'antd'
import { recieveService } from '../../../../services/receive/recieveService'

import 'moment/locale/vi'
moment.locale('vi')
const InfoBN = ({ infoBN, setInfoBNTTC, InfoBNTTC }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [NguoiNha, setNguoiNha] = useState([])
  // ------- lấy danh sách người nhà -------
  const nguoiNha = async () => {
    try {
      const result = await recieveService.getListNguoiThan()
      setNguoiNha(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    nguoiNha()
  }, [])
  return (
    <div className="border rounded-lg min-h-80">
      <h2 className="border-b text-lg font-semibold text-gray-400 p-2">Bệnh nhân</h2>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Tên BN:</label>
          <p>{infoBN?.tenBenhNhan}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Mã BN:</label>
          <p>{infoBN?.maBenhNhan}</p>
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Giới tính:</label>
          <p>{infoBN?.gioiTinh}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Ngày sinh:</label>
          <p>{infoBN?.ngaySinh && moment(infoBN?.ngaySinh).format('DD/MM/YYYY HH:mm:ss')}</p>
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Dân tộc:</label>
          <p>{infoBN?.tenDanToc}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Đối tượng:</label>
          <p>{infoBN?.tenDoiTuong}</p>
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Nghề nghiệp:</label>
          <p>{infoBN?.tenNgheNghiep}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Quốc tịch:</label>
          <p>{infoBN?.tenQuocTich}</p>
        </div>
      </div>
      <div className="flex p-2">
        <label className="font-semibold w-24">Địa chỉ: </label>
        <p>{infoBN?.diaChi}</p>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Dược sĩ:</label>
          <p>{infoUser?.tenNV}</p>
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">Chi nhánh:</label>
          <p>{PkDangNhap?.tenChiNhanh}</p>
        </div>
      </div>
      <div className="p-2 flex">
        <div className="flex w-1/2 gap-1">
          <label className="font-semibold w-24">Người nhà:</label>
          <Select
            value={InfoBNTTC.nguoinha}
            size="small"
            className="w-32"
            onChange={(value) => setInfoBNTTC((preState) => ({ ...preState, nguoinha: value }))}
            options={NguoiNha.map((items) => ({
              label: items.ten,
              value: items.ten,
            }))}
          />
        </div>
        <div className="flex w-1/2 gap-1 ">
          <label className="font-semibold w-24">SĐT:</label>
          <Input
            value={InfoBNTTC.dienthoainguoinha}
            size="small"
            onChange={(e) => {
              const { value } = e.target
              setInfoBNTTC((preState) => ({ ...preState, dienthoainguoinha: value }))
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default InfoBN
