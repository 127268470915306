import React, { useCallback } from 'react'
import { Input, Select, DatePicker, Radio } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { searchBN_Action } from '../../../../../store/actions/receiveAction'
import { fetchInfoBNTangNhiCuaTheSan } from '../../../../../store/actions/cardAction'
import ToastCus from '../../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const InfoBNTang = ({ isBNtang }) => {
  const dispatch = useDispatch()
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { listBN } = useSelector((state) => state.receiveReducer)
  const { infoBnTang, infoBnThu2Tang, infoBN, infoBNthu2 } = useSelector(
    (state) => state.cartReducer,
  )
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        dispatch(searchBN_Action(keyword, ID_COMPANY))
      }
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman

  const handleInfoBN = (value) => {
    if (
      infoBnTang?.idbn === value ||
      infoBnThu2Tang?.idbn === value ||
      infoBN?.idbn === value ||
      infoBNthu2?.idbn === value
    ) {
      ToastCus.fire({
        icon: 'error',
        text: 'Bệnh nhân này đã được chọn!',
      })
      return
    }
    dispatch(fetchInfoBNTangNhiCuaTheSan(value, isBNtang))
  }
  return (
    <form className="text-start">
      <div className="flex flex-col gap-2">
        <div className="flex gap-1">
          <label className="w-[14%] text-end">Tìm BN:</label>
          {/* <Select
            size="small"
            onSearch={debounceDropDown}
            value={null}
            onChange={handleInfoBN}
            filterOption={false}
            notFoundContent={null}
            placeholder="Tìm kiếm mã BN, SĐT, CCCD, Tên bệnh nhân"
            options={listBN?.map((items) => ({
              label: items.tenBenhNhan,
              value: items.idbn,
              MaBN: items.maBenhNhan,
              ngaySinh: items.ngaySinh
                ? moment(items.ngaySinh).format("DD/MM/YYYY")
                : "",
            }))}
            optionRender={(options) => (
              <ul className="flex">
                <li className="w-1/6">{options.data.MaBN}</li>
                <li className="w-4/6 border-x px-5">{options.data.label}</li>
                <li className="w-1/6 text-center">{options.data.ngaySinh}</li>
              </ul>
            )}
            showSearch
            allowClear
            className="w-full"
          /> */}
          <Select
            size="small"
            onSearch={debounceDropDown}
            value={null}
            onChange={handleInfoBN}
            filterOption={false}
            notFoundContent={null}
            placeholder="Tìm kiếm mã BN, SĐT, CCCD, Tên bệnh nhân..."
            suffixIcon={<SearchOutlined className=" " />}
            options={listBN?.map((items) => ({
              label: items.tenbenhnhan,
              value: items.idbn,
              MaBN: items.mabenhnhan,
              ngaySinh: items.ngaysinh ? moment(items.ngaysinh).format('DD/MM/YYYY') : '',
              diaChi: items.diachi,
              tinh: items.tentinhtp,
              quan: items.tenquanhuyen,
              xa: items.tenphuongxa,
              gioiTinh: items.gioitinh,
            }))}
            optionRender={(options) => (
              <ul className="flex">
                <li className="w-4/6">
                  <p className="font-semibold">
                    {options.data.label} - {options.data.MaBN}
                  </p>
                  <i className="text-gray-500">
                    Địa chỉ: {options.data.diaChi}
                    {/* {options.data.xa && `, ${options.data.xa}`}
                    {options.data.quan && `, ${options.data.quan}`}
                    {options.data.tinh && `, ${options.data.tinh}`} */}
                  </i>
                </li>
                <li className="w-2/6 flex flex-col text-end pr-1">
                  <p>Ngày sinh: {options.data.ngaySinh} </p>
                  <i className="text-gray-500">Giới tính: {options.data.gioiTinh}</i>
                </li>
              </ul>
            )}
            showSearch
            allowClear
            className="w-full"
          />
        </div>
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">
              <span className="text-sx text-red-500">(*)</span>
              Họ và tên:
            </label>
            <Input
              style={{ color: 'black' }}
              disabled
              value={isBNtang ? infoBnThu2Tang?.tenBenhNhan : infoBnTang?.tenBenhNhan}
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Mã BN:</label>
            <Input
              style={{ color: 'black' }}
              value={isBNtang ? infoBnThu2Tang?.maBenhNhan : infoBnTang?.maBenhNhan}
              disabled
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Đối tượng:</label>
            <Input
              disabled
              style={{ color: 'black' }}
              value={isBNtang ? infoBnThu2Tang?.tenDoiTuong : infoBnTang?.tenDoiTuong}
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-1 ">
            <label className="w-1/3">Nghề nghiệp:</label>
            <Input
              disabled
              style={{ color: 'black' }}
              value={isBNtang ? infoBnThu2Tang?.tenNgheNghiep : infoBnTang?.tenNgheNghiep}
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Ngày sinh:</label>
            <Input
              disabled
              style={{ color: 'black' }}
              value={
                isBNtang
                  ? infoBnThu2Tang?.ngaySinh &&
                    moment(infoBnThu2Tang?.ngaySinh).format('DD/MM/YYYY')
                  : infoBnTang?.ngaySinh && moment(infoBnTang?.ngaySinh).format('DD/MM/YYYY')
              }
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3">Dân tộc:</label>
            <Input
              disabled
              style={{ color: 'black' }}
              value={isBNtang ? infoBnThu2Tang?.tenDanToc : infoBnTang?.tenDanToc}
              size="small"
            />
          </div>
        </div>
        {/* <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">CCCD:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBnThu2Tang?.cccd : infoBnTang?.cccd}
              size="small"
            />
          </div>
          <div className="flex w-1/2 gap-1">
            <label className="w-1/3">Ngày cấp:</label>
            <Input
              disabled
              style={{ color: "black" }}
              value={isBNtang ? infoBnThu2Tang?.ngayCap && moment(infoBnThu2Tang?.ngayCap).format("DD/MM/YYYY") : infoBnTang?.ngayCap && moment(infoBnTang?.ngayCap).format("DD/MM/YYYY")}
              size="small"
            />
          </div>
        </div> */}
        <div className="flex gap-5 text-end">
          <div className="flex w-1/2 gap-1 items-center">
            <label className="w-1/3">Điện thoại:</label>
            <Input
              disabled
              style={{ color: 'black' }}
              value={isBNtang ? infoBnThu2Tang?.dienThoai : infoBnTang?.dienThoai}
              size="small"
            />
          </div>
          <div className="flex gap-1 w-1/2">
            <label className="w-1/3 ">Giới tính:</label>
            <Input
              disabled
              style={{ color: 'black' }}
              value={isBNtang ? infoBnThu2Tang?.gioiTinh : infoBnTang?.gioiTinh}
              size="small"
            />
          </div>
        </div>
        <div className="flex gap-1">
          <label className="w-[117px] text-end">Địa chỉ:</label>
          <Input
            disabled
            style={{ color: 'black' }}
            value={isBNtang ? infoBnThu2Tang?.diaChi : infoBnTang?.diaChi}
            size="small"
          />
        </div>
      </div>
    </form>
  )
}

export default InfoBNTang
