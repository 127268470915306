import CachedIcon from '@mui/icons-material/Cached'
import { Button, DatePicker, Input, Select } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import { branchNhapKhoPT } from '../../../../store/actions/NhapKhoAction'
import { SearchOutlined } from '@ant-design/icons'
import { NhapKhoService } from '../../../../services/NhapKho/NhapKhoService'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import TableHang from './TableHang'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
moment.locale('vi')
const ChiTietHangHoa = () => {
  const today = moment()
  const dateFormat = 'DD/MM/YYYY'
  const formatDateDB = 'YYYY-MM-DD'
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const dispatch = useDispatch()
  const { BranchPT } = useSelector((state) => state.NhapKhoReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [since, setSince] = useState(today.format(formatDateDB))
  const [toDate, SetToDate] = useState(today.format(formatDateDB))
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [idChiNhanh, setIdChiNhanh] = useState(Number(BranchLogin))
  const [valueExport, setValueExport] = useState([])
  const [data, setData] = useState([])
  const [loadingChiTiet, setLoadingChiTiet] = useState(false)

  const [search, setSearch] = useState(null)

  const exportToExcel = () => {
    const formattedData = valueExport.map((item) => ({
      'Mã Phiếu': item.maphieu,
      'Tên phiếu': item.tenphieu,
      'Ngày nhập': item.ngaynhan && moment(item.ngaynhan).format('DD/MM/YYYY'),
      'Số hoá đơn': item.sohoadon,
      'Ngày hoá đơn': item.ngayhoadon && moment(item.ngayhoadon).format('DD/MM/YYYY'),
      'Chi nhánh': item.tenchinhanh,
      'kho nhập': item.tenkho,
      'Mã đối tác': item.madoitac,
      'Tên đối tác': item.tendoitac,
      'Mã hàng': item.mathuoc,
      'Tên biệt dược': item.tenbietduoc,
      'Hoạt chất': item.tenhoatchat,
      'SL chẳn': Math.floor(item?.soluongle / item?.soluongdonggoi) ?? 0,
      'Đơn vị chẳn': item?.donvichan,
      'Đơn giá chẳn': item?.dongiamua * item?.soluongdonggoi,
      'Quy cách': item.quycach,
      'Số lượng lẻ': item.soluongle,
      'Đơn vị tính': item.dvt,
      'Đơn giá lẻ': item.dongiamua,
      'Tổng tiền': item?.dongiamua * item?.soluongle,
      'P. Gia công': item?.phigiacong,
      'P. Vận chuyển': item?.phivanchuyen,
      '% CK Trước VAT': item?.ptcktruocvat,
      'Tiền CK trước VAT': item?.cktruocvat,
      'Tổng trước VAT': item?.tongtientruocvat,
      VAT: item?.vaT5 !== 0 ? '5%' : item?.vaT8 !== 0 ? '8%' : item?.vaT10 !== 0 ? '10%' : '0%',
      'Tiền % VAT':
        item?.vaT5 !== 0
          ? item?.vaT5
          : item?.vaT8 !== 0
            ? item?.vaT8
            : item?.vaT10 !== 0
              ? item?.vaT10
              : 0,
      'Thành tiền': item?.thanhtien,
      'Thực trả': item?.thuctra,
      'Nhiệt độ': item?.nhietdo,
      'Mã số đăng kí': item?.masodangky,
      'Số lô': item.solo,
      'Hạn dùng': item.handung && moment(item.handung).format('DD/MM/YYYY'),
      'Ghi chú': item.ghichu,
    }))
    const name = 'Danh Sách chi tiết hàng'
    exportExcelformat(formattedData, name)
  }
  const handleDatePicker = (data, dateString) => {
    setSince(moment(dateString[0], dateFormat).format(formatDateDB))
    SetToDate(moment(dateString[1], dateFormat).format(formatDateDB))
  }
  const handleCompany = (value) => {
    setCompany(value)
    dispatch(branchNhapKhoPT(value))
    setIdChiNhanh('')
  }

  // xử lí chọn chi nhánh
  const handleSelected = (value) => {
    setIdChiNhanh(value)
  }
  // xử lí lọc dưc liệu
  const handleFilter = async (keyword, idct, idcn, tuNgay, denNgay) => {
    try {
      setLoadingChiTiet(true)
      const { data } = await NhapKhoService.getDetailKhoNhap(keyword, idct, idcn, tuNgay, denNgay)
      setData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingChiTiet(false)
    }
  }
  const onSearch = () => {
    handleFilter(search, company, idChiNhanh, since, toDate)
  }
  // search
  const debounceDropDown = useCallback(
    _.debounce((e) => {
      setSearch(e.target.value.trim())
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman

  useEffect(() => {
    handleFilter(search, company, idChiNhanh, since, toDate)
  }, [search])
  useEffect(() => {
    dispatch(branchNhapKhoPT(company))
    dispatch(listBranchAction())
  }, [])
  useEffect(() => {
    setValueExport(data)
  }, [data])
  return (
    <>
      <div className="flex justify-between mb-5">
        <div className="flex gap-3 items-center w-full">
          <Input
            onChange={debounceDropDown}
            placeholder="Tìm mã phiếu, tên phiếu nhập, số hóa đơn"
            allowClear
            prefix={
              <SearchOutlined
                style={{
                  color: 'rgba(0,0,0,.25)',
                }}
              />
            }
          />
          <Select
            onChange={handleCompany}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            value={company}
            className="min-w-64"
          />
          <Select
            onChange={handleSelected}
            value={idChiNhanh}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { label: 'Tất cả', value: '' },
              ...(BranchPT ?? []).map(({ idChiNhanh, tenChiNhanh }) => ({
                label: tenChiNhanh,
                value: idChiNhanh,
              })),
            ]}
            className="min-w-64"
          />
          <DatePicker.RangePicker
            className="min-w-60"
            allowClear={false}
            value={[dayjs(since, formatDateDB), dayjs(toDate, formatDateDB)]}
            format={dateFormat}
            onChange={handleDatePicker}
          />
          <Button type="primary" onClick={onSearch} icon={<CachedIcon sx={{ fontSize: 25 }} />}>
            Tìm{' '}
          </Button>
          <Button onClick={exportToExcel} icon={<FileDownloadIcon sx={{ fontSize: 25 }} />}>
            Xuất excel{' '}
          </Button>
        </div>
      </div>
      <div className=" h-full">
        <TableHang data={data} loadingChiTiet={loadingChiTiet} setValueExport={setValueExport} />
      </div>
    </>
  )
}

export default ChiTietHangHoa
