import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  ConfigProvider,
  Input,
  Pagination,
  Table,
  Tabs,
  Modal,
  Select,
  Checkbox,
  message,
  Popconfirm,
} from 'antd'
import { SyncOutlined, PlusOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons'
import ModalAdd from './ModalAdd'
import ModalEdit from './ModalEdit'
import { https } from '../../../services/apiService'
import { dichVuService } from '../../../services/dichVu/dichVuService'
import { formattedNumber } from '../../../utils/formattedNumber'
import ToastCus from '../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
// const { confirm } = Modal

function GoiKham() {
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [keyword, setKeyword] = useState(null)
  const [isModalAdd, setIsModalAdd] = useState(false)
  const [isModalEdit, setIsModalEdit] = useState(false)
  const [listGoiKham, setListGoiKham] = useState([])
  const [goiKhamEdit, setGoiKhamEdit] = useState(null)
  const getListGoiKham = async () => {
    try {
      setIsLoading(true)
      const { data } = await dichVuService.searchByKeyword(
        47, //nhóm dv gói khám = 47
        ID_COMPANY,
        keyword?.length ? keyword?.trim() : keyword,
      )
      setListGoiKham(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getListGoiKham()
  }, [])
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <ModalAdd isModalAdd={isModalAdd} setIsModalAdd={setIsModalAdd} />
      <ModalEdit
        isModalEdit={isModalEdit}
        setIsModalEdit={setIsModalEdit}
        goiKhamEdit={goiKhamEdit}
        setGoiKhamEdit={setGoiKhamEdit}
        getListGoiKham={getListGoiKham}
      />
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <div className="flex justify-between items-center mb-2">
          <div className="flex justify-start items-center gap-2">
            <Input
              className="w-60"
              placeholder="Tìm Mã gói khám, Tên gói khám..."
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <Button
              onClick={() => {
                setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
                getListGoiKham()
              }}
              loading={isLoading}
              type="primary"
              shape="circle"
              icon={<SyncOutlined />}
            />
          </div>
          <div className="flex justify-end items-center gap-2">
            {/* <Button
              onClick={() => {
                setIsModalAdd(true)
              }}
              loading={isLoading}
              type="primary"
              icon={<PlusOutlined />}
            >
              Tạo gói khám
            </Button> */}
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
          }}
        >
          <Table
            //   scroll={{ x: 1000 }}
            //   pagination={{
            //     pageSize: 20,
            //     showSizeChanger: false,
            //   }}
            loading={isLoading}
            scroll={{ y: 779 }}
            pagination={false}
            bordered
            dataSource={listGoiKham}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 50,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Mã gói khám',
                dataIndex: 'maDichVu',
                key: 'maDichVu',
                width: 250,
              },
              {
                title: 'Tên gói khám',
                dataIndex: 'tenDichVu',
                key: 'tenDichVu',
              },
              {
                title: 'Thành tiền',
                dataIndex: 'donGia',
                key: 'donGia',
                width: 150,
                align: 'right',
                render: (text, record, index) => formattedNumber(text ?? 0),
              },
              {
                title: 'Ghi chú',
                dataIndex: 'ghiChu',
                key: 'ghiChu',
                width: 350,
              },
              // {
              //   title: 'Sử dụng',
              //   dataIndex: 'magoikham',
              //   key: 'magoikham',
              //   width: 75,
              //   align: 'center',
              //   render: (text, record, index) => (
              //     <>
              //       <Popconfirm
              //         placement="topRight"
              //         title="Ngừng sử dụng gói khám"
              //         description="Bạn có chắc chắn muốn ngừng sử dụng <Tên gói khám>?"
              //         // onConfirm={confirm}
              //         // onCancel={cancel}
              //         okText="Xác nhận"
              //         cancelText="Hủy bỏ"
              //       >
              //         <Checkbox />
              //       </Popconfirm>
              //     </>
              //   ),
              // },
              {
                title: '',
                dataIndex: 'action',
                key: 'action',
                width: 50,
                align: 'center',
                render: (text, record, index) => (
                  <>
                    <EditOutlined
                      className="cursor-pointer text-green-500 text-lg"
                      onClick={() => {
                        setGoiKhamEdit(record)
                        setIsModalEdit(true)
                      }}
                    />
                  </>
                ),
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default GoiKham
