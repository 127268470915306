import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ConfigProvider, DatePicker, Input, Select, Table, TreeSelect } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import dayjs from 'dayjs'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import * as XLSX from 'xlsx'
import { branchService } from '../../../../services/branch/branchService'
import { reportService } from '../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { SearchOutlined } from '@ant-design/icons'
import { formattedNumber } from '../../../../utils/formattedNumber'

import 'moment/locale/vi'
import { LoaitoaService } from '../../../../services/LoaiToa/LoaitoaService'
import { render } from 'bwip-js'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

function XuatNhaThuoc(props) {
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [dataReport, setDataReport] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')
  const [loaiToa, setLoaiToa] = useState([])
  const [selectedBranches, setSelectedBranches] = useState(['all'])
  const [selectedLoaiToa, setSelectedLoaiToa] = useState([])
  const dispatch = useDispatch()

  const fetchReport = async () => {
    try {
      setIsLoading(true)
      let idChiNhanhs = ''
      if (!selectedBranches.includes('all')) {
        idChiNhanhs = selectedBranches.map((id) => `idChiNhanhs=${id}`).join('&')
      }
      const idLoaiToaQuery = selectedLoaiToa !== 'all' ? `idLoaiToa=${selectedLoaiToa}` : ''
      const queryString = `idCongTy=${company}&${idChiNhanhs}&${idLoaiToaQuery}&keyword=${search?.trim() || ''}&fromDate=${dateForm}&toDate=${dateTo}`
      const { data } = await reportService.getThongKeXuatNhaThuoc(queryString)

      const formattedData = data.flatMap((branch) =>
        branch.ngayList.map((item, index) => ({
          tenchinhanh: branch.tenChiNhanh,
          ngay: item.ngay,
          loaiphieu: item.tenLoaiToa,
          toathuoc: item.toaThuoc,
          mahang: item.maThuoc,
          tenhang: item.tenThuoc,
          hoatchat: item.tenHoatChat,
          donvi: item.dvt,
          soluong: item.soLuong,
          dongia: item.donGia,
          thanhtien: item.thanhTien,
          giam: item.phanTramGiam,
          giamgia: item.tienGiam,
          thanhtoan: item.tongTien,
          solo: item.soLo,
          handung: item.hanDung,
          sohoadon: item.soHoaDon,
          sodangky: item.soDangKy,
          ghichu: item.ghiChu,
        })),
      )
      setDataReport(formattedData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const fetchBranch = async (id) => {
    try {
      setIsLoading(true)
      const result = await branchService.getBranchNotVP(id)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchAllLoaiToa = async () => {
    try {
      const { data } = await LoaitoaService.getAllLoaiToa()
      setLoaiToa(data)
    } catch (error) {
      console.error('Error fetching Loai Toa:', error)
    }
  }

  console.log('loaiToa', loaiToa)

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]

  const onChange = (newValue) => {
    setSelectedBranches(newValue)
  }

  const tProps = {
    treeData,
    value: selectedBranches,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn công ty ',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const exportToExcel = () => {
    const data = dataReport.map((item) => ({
      'Chi nhánh': item.tenchinhanh,
      Ngày: item.ngay,
      'Loại phiếu': item.loaiphieu,
      'Toa thuốc': item.toathuoc,
      'Mã Hàng': item.mahang,
      'Tên Hàng': item.tenhang,
      'Hoạt chất': item.hoatchat,
      'Đơn vị': item.donvi,
      'Số lượng': item.soluong,
      'Đơn giá': item.dongia,
      'Thành tiền': item.thanhtien,
      '% giảm': item.giam,
      'Giảm giá': item.giamgia,
      'Thanh toán': item.thanhtoan,
      'Số Lô': item.solo,
      'Hạn dùng': item.handung,
      'Số Hóa đơn': item.sohoadon,
      'Số đăng ký': item.sodangky,
      'Ghi chú': item.ghichu,
    }))

    // XLSX.utils.sheet_add_aoa(data, [['Bảng thống kê xuất nhà thuốc']], { origin: 'A1' })
    exportExcelformat(data, 'Bảng thống kê xuất nhà thuốc')

    XLSX.utils.sheet_add_aoa(
      data,
      [
        [
          'Tổng số lượng',
          formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.soluong || 0), 0)),
          'Tổng thành tiền',
          formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.thanhtien || 0), 0)),
          'Tổng giảm giá',
          formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.giamgia || 0), 0)),
          'Tổng Thanh toán',
          formattedNumber(dataReport?.reduce((tong, item) => (tong += item?.thanhtoan || 0), 0)),
        ],
      ],
      { origin: `A${data.length + 2}` },
    )
  }

  const onLoad = () => {
    setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    fetchReport()
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        await dispatch(listAllCompanyAction())
        await fetchBranch(id_company)
        await fetchAllLoaiToa()
        fetchReport()
      } catch (error) {
        console.log(error)
      }
    }
    initialize()
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      fixed: 'left',
      width: 250,
    },
    {
      title: 'Ngày ',
      dataIndex: 'ngay',
      key: 'ngay',
      width: 140,
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'Loại phiếu',
      dataIndex: 'loaiphieu',
      key: 'loaiphieu',
      width: 150,
      align: 'center',
    },
    {
      title: 'Toa thuốc',
      dataIndex: 'toathuoc',
      key: 'toathuoc',
      width: 150,
      align: 'center',
    },
    {
      title: 'Mã Hàng',
      dataIndex: 'mahang',
      key: 'mahang',
      width: 150,
      align: 'center',
    },
    {
      title: 'Tên Hàng',
      dataIndex: 'tenhang',
      key: 'tenhang',
      width: 150,
      align: 'center',
    },
    {
      title: 'Hoạt chất',
      dataIndex: 'hoatchat',
      key: 'hoatchat',
      width: 250,
      align: 'center',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'donvi',
      key: 'donvi',
      width: 150,
      align: 'center',
    },
    {
      title: 'Số lượng',
      dataIndex: 'soluong',
      key: 'soluong',
      width: 150,
      align: 'center',
    },
    {
      title: 'Đơn giá',
      dataIndex: 'dongia',
      key: 'dongia',
      width: 150,
      align: 'center',
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      width: 150,
      align: 'center',
      render: (text) => formattedNumber(text),
    },
    {
      title: '% giảm',
      dataIndex: 'giam',
      key: 'giam',
      width: 150,
      align: 'center',
    },
    {
      title: 'Giảm giá',
      dataIndex: 'giamgia',
      key: 'giamgia',
      width: 150,
      align: 'center',
    },
    {
      title: 'Thanh toán',
      dataIndex: 'thanhtoan',
      key: 'thanhtoan',
      width: 150,
      align: 'center',
      render: (text) => formattedNumber(text),
    },
    {
      title: 'Số LÔ',
      dataIndex: 'solo',
      key: 'solo',
      width: 150,
      align: 'center',
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'handung',
      key: 'handung',
      width: 150,
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'Số Hóa đơn',
      dataIndex: 'sohoadon',
      key: 'sohoadon',
      width: 150,
      align: 'center',
    },
    {
      title: 'Số đăng ký',
      dataIndex: 'sodangky',
      key: 'sodangky',
      width: 150,
      align: 'center',
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 150,
      align: 'center',
    },
  ]

  console.log('dataReport', dataReport)
  return (
    <>
      <div className="p-3 h-full bg-[#EFEFEF]">
        <div
          className="h-full p-2 bg-white border rounded-xl"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="flex gap-2">
            <div className="w-[17rem] flex items-center gap-2">
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('form')}
                format={dateFormat}
              />
              <span className="font-semibold text-lg">-</span>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('to')}
                format={dateFormat}
              />
            </div>
            <div className="w-[17rem]">
              <Select
                showSearch
                value={company}
                onChange={(value) => {
                  setCompany(value)
                  fetchBranch(value)
                  setSelectedBranches(['all'])
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={listCompany?.map(({ idct, tenct }) => ({
                  label: tenct,
                  value: idct,
                }))}
                className="w-full"
              />
            </div>
            <div className="w-[17rem] flex gap-2">
              <TreeSelect
                {...tProps}
                style={{ flexWrap: 'unset' }}
                className="w-full h-8 overflow-clip"
              />
            </div>
            <div className="w-[17rem]">
              <Select
                showSearch
                value={selectedLoaiToa}
                onChange={(value) => {
                  setSelectedLoaiToa(value)
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={[
                  { label: 'Tất cả', value: '' },
                  ...loaiToa?.map(({ idLoaiToa, tenLoaiToa }) => ({
                    label: tenLoaiToa,
                    value: idLoaiToa,
                  })),
                ]}
                placeholder="Chọn loại toa"
                className="w-full"
              />
            </div>
            <div className="flex w-64">
              <Input
                value={search}
                placeholder="Tìm kiếm theo tên hàng"
                onChange={(e) => setSearch(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
            <div className="flex gap-2">
              <IconButton
                disabled={isLoading}
                onClick={onLoad}
                color="primary"
                aria-label="delete"
                size="small"
              >
                <SyncIcon />
              </IconButton>
            </div>
            <div className="font-semibold ">
              <Button
                disabled={isLoading || !dataReport?.length}
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button>
            </div>
          </div>
          <div className="mt-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  fontSize: 14,
                },
                components: {
                  Table: {
                    headerBorderRadius: 0,
                  },
                },
              }}
            >
              <Table
                scroll={{
                  x: 2000,
                  y: 715,
                }}
                pagination={false}
                loading={isLoading}
                bordered
                dataSource={dataReport}
                columns={columns}
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}></Table.Summary.Cell>
                      <Table.Summary.Cell index={6}></Table.Summary.Cell>
                      <Table.Summary.Cell index={7}></Table.Summary.Cell>
                      <Table.Summary.Cell index={8}></Table.Summary.Cell>
                      <Table.Summary.Cell className="text-center" index={9}>
                        <span className="font-semibold">
                          {formattedNumber(
                            // số lượng
                            dataReport?.reduce((tong, item) => (tong += item?.soluong || 0), 0),
                          )}
                        </span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9}></Table.Summary.Cell>
                      <Table.Summary.Cell className="text-center" index={10}>
                        <span className="font-semibold">
                          {formattedNumber(
                            // thành tiền
                            dataReport?.reduce((tong, item) => (tong += item?.thanhtien || 0), 0),
                          )}
                        </span>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={11}></Table.Summary.Cell>
                      <Table.Summary.Cell className="text-center" index={12}>
                        <span className="font-semibold">
                          {formattedNumber(
                            // tiền gia công
                            dataReport?.reduce((tong, item) => (tong += item?.giamgia || 0), 0),
                          )}
                        </span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className="text-center" index={13}>
                        <span className="font-semibold">
                          {formattedNumber(
                            // Thanh toán
                            dataReport?.reduce((tong, item) => (tong += item?.thanhtoan || 0), 0),
                          )}
                        </span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={14}></Table.Summary.Cell>
                      <Table.Summary.Cell index={15}></Table.Summary.Cell>
                      <Table.Summary.Cell index={16}></Table.Summary.Cell>
                      <Table.Summary.Cell index={17}></Table.Summary.Cell>
                      <Table.Summary.Cell index={18}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  )
}

XuatNhaThuoc.propTypes = {}

export default XuatNhaThuoc
