import React from 'react'
import NoiSoi from './NoiSoi'

const NoiSoiCTC = () => {
  return (
    <div>
      <NoiSoi tenNoiSoi="noisoictc" />
    </div>
  )
}

export default NoiSoiCTC
