import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Input, Select } from 'antd'
import { calculateAge } from '../../../../utils/calculateAge'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

const InfoBN = ({ infoBnXQuang, debounceDropDown, listNv, idBsDocPhim, setidBsDocPhim }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const now = useMemo(() => moment(), [infoBnXQuang])
  return (
    <div className="grid grid-flow-row pt-5 gap-4">
      <div className="">
        <Divider orientation={'left'} plain style={{ marginTop: 0, marginBottom: 3, fontSize: 15 }}>
          <p className="font-semibold text-blue-500">Thông tin hành chính</p>
        </Divider>
        <div className="grid grid-flow-row px-2 gap-2">
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Tên BN:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnXQuang?.TENBENHNHAN}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Mã BN:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnXQuang?.MABENHNHAN}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Ngày sinh:</div>
            <Input
              size="small"
              className="w-full"
              variant="filled"
              readOnly
              value={infoBnXQuang?.NGAYSINH && moment(infoBnXQuang?.NGAYSINH).format('DD/MM/YYYY')}
            />
            <div className="font-semibold">Giới tính:</div>
            <Input
              size="small"
              className="w-full"
              variant="filled"
              readOnly
              value={infoBnXQuang?.GIOITINH}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Tuổi:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnXQuang?.NGAYSINH && calculateAge(infoBnXQuang?.NGAYSINH)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Địa chỉ:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnXQuang?.DIACHI}
            />
          </div>
        </div>
      </div>
      <div className="">
        <Divider orientation={'left'} plain style={{ marginTop: 0, marginBottom: 3, fontSize: 15 }}>
          <p className="font-semibold text-blue-500">Thông tin phiếu X-Quang</p>
        </Divider>
        <div className="grid grid-flow-row px-2 gap-2">
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold text-[13.5px]">Ngày thực hiện:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={
                (infoBnXQuang?.NGAYXQUANG &&
                  moment(infoBnXQuang?.NGAYXQUANG).format('DD/MM/YYYY HH:mm:ss')) ||
                (infoBnXQuang && now.format('DD/MM/YYYY HH:mm:ss'))
              }
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">BS chỉ định:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnXQuang?.BSCHIDINH}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">BS đọc phim:</div>
            {infoBnXQuang?.IDBNXQ && infoBnXQuang?.IDTTXQ ? (
              <>
                <Input
                  size="small"
                  className="col-span-3 w-full"
                  variant="filled"
                  readOnly
                  value={infoBnXQuang?.TENBSXQUANG}
                />
              </>
            ) : (
              <>
                <Select
                  size="small"
                  className="col-span-3 w-full"
                  style={{ color: 'black' }}
                  status={infoBnXQuang && !idBsDocPhim ? 'error' : ''}
                  placeholder="Tìm Tên BS, Mã NV..."
                  showSearch
                  filterOption={false}
                  notFoundContent={null}
                  onSearch={debounceDropDown}
                  value={idBsDocPhim}
                  onChange={(value) => setidBsDocPhim(value)}
                  options={listNv?.map((item) => ({
                    key: item?.id,
                    value: item?.id,
                    label: item?.tennv,
                    manv: item?.manv,
                  }))}
                  optionRender={(opt) => (
                    <>
                      <div className="grid grid-cols-3 items-center gap-2 text-[13px]">
                        <div className="col-span-2">{opt?.data?.label}</div>
                        <div className="border-s pl-2">{opt?.data?.manv}</div>
                      </div>
                    </>
                  )}
                />
              </>
            )}
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Kỹ thuật viên:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnXQuang?.TENKTV || (infoBnXQuang && infoUser?.tenNV)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">CĐ sơ bộ:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={null}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="font-semibold">Chỉ định:</div>
            <Input
              size="small"
              className="col-span-3 w-full"
              variant="filled"
              readOnly
              value={infoBnXQuang?.CHIDINH}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBN
