import React, { useState, useEffect } from 'react'
import { Button, DatePicker, Select, TreeSelect, Input } from 'antd'
import SyncIcon from '@mui/icons-material/Sync'
import moment from 'moment'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import 'dayjs/locale/vi'
import locale from 'antd/es/date-picker/locale/vi_VN'
import CalendarControls from './CalendarControls'
import { nhanvienService } from '../../../../services/nhanvien/nhanvienService'
import { companyService } from '../../../../services/Company/companyService'
import { https } from '../../../../services/apiService'
import { Option } from 'antd/es/mentions'

import { IconButton } from '@mui/material'
import { useSelector } from 'react-redux'
import 'moment/locale/vi'
moment.locale('vi')

dayjs.extend(isoWeek)

const { Search } = Input

const getDaysOfMonth = (startDate) => {
  const daysOfWeek = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
  const days = []
  const currentMonth = startDate.getMonth()

  for (let i = 1; i <= 31; i++) {
    const currentDay = new Date(startDate.getFullYear(), currentMonth, i)
    if (currentDay.getMonth() !== currentMonth) break
    const formattedDate = `${daysOfWeek[currentDay.getDay()]} - ${currentDay.getDate().toString().padStart(2, '0')}/${(currentDay.getMonth() + 1).toString().padStart(2, '0')}`
    days.push(formattedDate)
  }

  return days
}

const CalendarHeader = ({
  setDays,
  setCurrentMonth,
  onBranchChange,
  onCompanyChange,
  searchKeyword,
  setSearchKeyword,
  selectedPosition,
  handlePositionChange,
  onLoad,
  selectedChuyenKhoa, // Add this prop
  setSelectedChuyenKhoa, // Add this prop
}) => {
  const [currentMonthStart, setCurrentMonthStart] = useState(moment().startOf('month').toDate())
  const [companies, setCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(1) // Default company to 1
  const [branch, setBranch] = useState([])
  const [branchPending, setBranchPending] = useState(null)
  const [positions, setPositions] = useState([])
  const [visible, setVisible] = useState(false)
  // const [selectedChuyenKhoa, setSelectedChuyenKhoa] = useState(null)
  const [branchForPosition, setBranchForPosition] = useState([])

  const { infoUser } = useSelector((state) => state.userReducer)
  const isBacSi = infoUser?.tenNhom && /Bác Sĩ/i.test(infoUser.tenNhom) // Check if the user is a doctor
  const [allChuyenKhoa, setAllChuyenKhoa] = useState([])
  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await https.get('/NhomNguoiDung/GetAllLoaiNhom')
        setPositions(response.data || []) // Ensure positions is an array
      } catch (error) {
        console.error('Error fetching positions:', error)
      }
    }

    fetchPositions()
  }, [])

  useEffect(() => {
    setDays(getDaysOfMonth(currentMonthStart))
    setCurrentMonth(currentMonthStart) // Update currentMonth in parent component
  }, [currentMonthStart, setDays, setCurrentMonth])

  useEffect(() => {
    const fetchCompanies = async () => {
      const { data } = await companyService.getAllListCompany()
      setCompanies(data)
      setSelectedCompany(1) // Set default company to 1
      onCompanyChange(data.length > 0 ? [data[0].idct] : []) // Pass the selected company ID to the parent component
    }

    fetchCompanies()
  }, [])

  const getAllChuyenKhoa = async () => {
    try {
      const { data } = await https.get('ChuyenKhoa/AllChuyenKhoa')
      setAllChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllChuyenKhoa()
  }, [])
  useEffect(() => {
    if (selectedCompany) {
      fetchBranch(selectedCompany)
    }
  }, [selectedCompany])

  useEffect(() => {
    if (isBacSi) {
      setBranchForPosition(infoUser?.idChiNhanh)
      onBranchChange(infoUser?.idChiNhanh ? [infoUser.idChiNhanh] : [])
    } else {
      onBranchChange(branch.length > 0 ? [branch[0].idChiNhanh] : [])
    }
  }, [isBacSi, infoUser, branch])

  const fetchBranch = async (companyId) => {
    const { data } = await nhanvienService.getChiNhanh(companyId, '')
    setBranch(data)

    if (data.length > 0) {
      setBranchPending(data[0].idChiNhanh) // Set default branch to the first element
      if (isBacSi) {
        setBranchForPosition(infoUser?.idChiNhanh)
        onBranchChange(infoUser?.idChiNhanh ? [infoUser.idChiNhanh] : [])
      } else {
        onBranchChange(data.length > 0 ? [data[0].idChiNhanh] : []) // Pass the selected branch ID to the parent component
      }
    }
  }

  const handlePreviousMonth = () => {
    const newStartDate = moment(currentMonthStart).subtract(1, 'months').startOf('month').toDate()
    setCurrentMonthStart(newStartDate)
  }

  const handleNextMonth = () => {
    const newStartDate = moment(currentMonthStart).add(1, 'months').startOf('month').toDate()
    setCurrentMonthStart(newStartDate)
  }

  const onChange = (date) => {
    if (date) {
      const newStartDate = date.startOf('month').toDate()
      setCurrentMonthStart(newStartDate)
    }
  }

  const customMonthFormat = (value) => value.format('MM/YYYY')

  const treeData = [
    {
      title: 'Tất cả',
      value: 'all',
      key: '0-1',
      children: branch?.map((branch, index) => ({
        title: branch.tenChiNhanh,
        value: branch.idChiNhanh,
        key: `0-1-${index}`,
      })),
    },
  ]

  const tProps = {
    treeData,
    value: branchPending,
    onChange: (value) => {
      setBranchPending(value)
      onBranchChange(value) // Pass the selected branch ID to the parent component
    },
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '15%',
    },
  }

  const tPropsPosition = {
    treeData,
    value: branchForPosition,
    onChange: (value) => {
      setBranchForPosition(value)
      onBranchChange(value) // Pass the selected branch ID to the parent
    },
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '15%',
    },
  }

  const positionDisplayNames = {
    BacSi: 'Bác Sĩ',
    DieuDuong: 'Điều Dưỡng',
    KyThuatVien: 'Kỹ Thuật Viên',
    DuocSi: 'Dược Sĩ',
    LeTan: 'Lễ Tân',
  }

  // New useEffect to set default position
  useEffect(() => {
    if (infoUser) {
      const tenNhom = infoUser.tenNhom
      if (/Bác Sĩ/i.test(tenNhom)) {
        handlePositionChange('BacSi')
      } else {
        handlePositionChange('')
      }
    }
  }, [positions, infoUser])

  useEffect(() => {
    if (infoUser) {
      const idChiNhanh = infoUser?.idChiNhanh
      if (idChiNhanh) {
        setBranchForPosition(idChiNhanh)
      }
    }
  }, [infoUser])

  useEffect(() => {
    if (infoUser) {
      const idCK = infoUser?.idCK
      if (idCK) {
        setSelectedChuyenKhoa(idCK)
      }
    }
  }, [infoUser])

  const handleSearchNhanvien = (value) => {
    setSearchKeyword(value)
  }

  useEffect(() => {
    if (selectedChuyenKhoa) {
      const fetchBranchByChuyenKhoa = async () => {
        try {
          setSelectedChuyenKhoa(selectedChuyenKhoa)
          const { data } = await https.get(
            `ChiNhanh/GetChiNhanhIdChuyenKhoa?idChuyenKhoa=${selectedChuyenKhoa}`,
          )
          setBranch(data)

          if (data.length > 0) {
            setBranchPending(data[0].idChiNhanh) // Set default branch to the first element
            // nếu isBacSi thì setBranchForPosition còn không thì setBranchPending
            if (isBacSi) {
              onBranchChange(branchForPosition) // Pass the default
            } else {
              onBranchChange(data[0].idChiNhanh) // Pass the default branch ID to the parent component
            }
          }
        } catch (error) {
          console.error('Error fetching branch:', error)
        }
      }
      fetchBranchByChuyenKhoa()
    }
  }, [selectedChuyenKhoa])

  return (
    <div className="flex gap-4 items-center ">
      <Select
        style={{
          width: '200px',
          minWidth: '200px',
        }}
        className="w-36"
        value={selectedPosition || undefined} // Ensure value is undefined when no option is selected
        onChange={handlePositionChange}
        placeholder="Chọn chức vụ"
        disabled={isBacSi}
      >
        {positions.map((position, index) => (
          <Option key={index} value={position}>
            {positionDisplayNames[position] || position}
          </Option>
        ))}
      </Select>
      {selectedPosition === 'BacSi' && (
        <>
          <Select
            style={{
              width: '300px',
              minWidth: '300px',
            }}
            placeholder="Chọn chuyên khoa"
            value={selectedChuyenKhoa}
            onChange={(value) => {
              setSelectedChuyenKhoa(value)
            }}
            showSearch
            options={allChuyenKhoa.map((chuyenKhoa) => ({
              label: chuyenKhoa.tenChuyenKhoa,
              value: chuyenKhoa.idChuyenKhoa,
            }))}
            className="w-72"
            disabled={isBacSi}
          />

          <TreeSelect {...tPropsPosition} className="w-64 h-8 overflow-clip" disabled={isBacSi} />
        </>
      )}
      {selectedPosition && selectedPosition !== 'BacSi' && (
        <>
          <Select
            showSearch
            className="w-72"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(value) => {
              setSelectedCompany(value)
              localStorage.setItem('id_company', value)
              onCompanyChange([value]) // Pass the selected company ID to the parent component
            }}
            value={selectedCompany}
            options={companies.map((company) => ({
              label: company.tenct,
              value: company.idct,
            }))}
            // disabled={!selectedPosition} // Disable if no position is selected
          />

          <TreeSelect
            {...tProps}
            className="w-64 h-8 overflow-clip"
            // disabled={!selectedPosition}
          />
        </>
      )}

      <Search
        placeholder="Tìm kiếm theo tên nhân viên"
        allowClear
        onSearch={handleSearchNhanvien}
        value={searchKeyword}
        enterButton
        className="w-72"
        disabled={!selectedPosition} // Disable if no position is selected
      />

      <IconButton
        onClick={onLoad}
        color="primary"
        aria-label="sync"
        size="small"
        disabled={!selectedPosition}
      >
        <SyncIcon />
      </IconButton>

      {/* <Button className="bg-blue-400 text-white" onClick={showDrawer} disabled={!selectedPosition}>
        Ca còn trống
      </Button> */}

      <div className="flex gap-2 items-center ml-auto">
        <Button onClick={handlePreviousMonth} disabled={!selectedPosition}>
          Tháng trước
        </Button>
        <DatePicker
          picker="month"
          value={dayjs(currentMonthStart)}
          onChange={onChange}
          format={customMonthFormat}
          locale={locale}
          disabled={!selectedPosition} // Disable if no position is selected
        />
        <Button onClick={handleNextMonth} disabled={!selectedPosition}>
          Tháng sau
        </Button>
      </div>
    </div>
  )
}

export default CalendarHeader
