import React from 'react'
import NoiSoi from './NoiSoi'

const NoiSoiTMH = () => {
  return (
    <div>
      <NoiSoi tenNoiSoi="noisoitmh" />
    </div>
  )
}

export default NoiSoiTMH
