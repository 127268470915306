import { Input, Modal, AutoComplete } from 'antd'
import React, { useState } from 'react'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { chuyenNhanVien } from '../../../../store/actions/suCoAction'
const ChuyenStaff = ({ chuyenStaff, setChuyenStaff, staff, debounceDropDown }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { nhanVien } = useSelector((state) => state.suCoReducer)
  const [reason, setReason] = useState(null)
  const [idStaff, setIdStaff] = useState(null)
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch()
  const handlecancel = () => {
    setChuyenStaff(false)
  }
  const reset = () => {
    setChuyenStaff(false)
    setReason(null)
    setIdStaff(null)
    setInputValue('')
  }
  const handlChuyen = () => {
    const form = {
      idbaohong: staff?.idbaohong,
      nguoinhan:idStaff,
      lydo: reason,
      nguoitao: infoUser?.idnv,
      nguoinhancu:staff?.idnv
    }
    dispatch(chuyenNhanVien(form, reset))
  }
  return (
    <Modal
      onOk={handlChuyen}
      okText="Cập nhập"
      cancelText="Huỷ"
      title={
        <>
          <ExclamationCircleFilled style={{ color: 'orange' }} /> Bạn có chắc muốn chuyển người thực
          hiện ?
        </>
      }
      open={chuyenStaff}
      onCancel={handlecancel}
      okButtonProps={{
        disabled: !reason || !idStaff,
      }}
    >
      <AutoComplete
        className="mb-2"
        value={inputValue} 
        placeholder="Nhập tên nhân viên"
        style={{ width: '100%' }}
        options={nhanVien?.map((item) => ({
          label: item.tennv,
          value: item.id,
        }))}
        onChange={(value) => setInputValue(value)}
        onSearch={debounceDropDown}
        onSelect={(value, option) => {
          setIdStaff(value)
          setInputValue(option.label)
        }}
      />
      <Input placeholder="Nhập lý do" value={reason} onChange={(e) => setReason(e.target.value)} />
    </Modal>
  )
}

export default ChuyenStaff
