import React, { useEffect, useState } from 'react'
import { Input, Modal, InputNumber } from 'antd'
import Button from '@mui/material/Button'
import { Statistic } from 'antd'
import { Table, Select } from 'antd'
import { Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../store/constants/constants'
import { listCaLamViec } from '../../../store/actions/BranchAction'
import { Formik } from 'formik'
import moment from 'moment'
import { logoutUser, openShiffCaLamViec } from '../../../store/actions/userAction'
import axios from 'axios'
import { authService } from '../../../services/auth/authService'
import { useNavigate } from 'react-router-dom'
import { formattedNumber } from '../../../utils/formattedNumber'

import 'moment/locale/vi'
moment.locale('vi')
const formatDate = 'YYYY-MM-DDTHH:mm:ss'
const ModalCheckOut = () => {
  const { modalMoCa } = useSelector((state) => state.modalReducer)
  const { PkDangNhap, ThongKeDoanhThuChiNhanh } = useSelector((state) => state.branchReducer)
  const [ip, setIP] = useState(null)
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const now = moment()
  const closeCaLamViec = () => {
    dispatch({
      type: typeAction.CLOSE_MODAL_MOCA,
    })
  }
  const checkout = async () => {
    await authService.checkout(infoUser?.dangNhap.idDangNhap)
    dispatch(logoutUser(navigate, infoUser?.dangNhap.idDangNhap))
    closeCaLamViec()
  }
  // research ip
  const searchIP = async () => {
    try {
      const result = await axios.get('http://ip-api.com/json/?fields=61439')
      setIP(result.data.query)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    // dispatch(listCaLamViec())
    searchIP()
  }, [])

  return (
    <>
      <Modal
        className="text-center"
        title={<p className="font-semibold text-lg">Đóng ca làm việc</p>}
        // centered
        footer={null}
        open={modalMoCa}
        width={700}
        onCancel={closeCaLamViec}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            idDangNhap: infoUser?.dangNhap.idDangNhap,
            thoiGianDangNhap: infoUser?.dangNhap.thoiGianDangNhap || now.format(formatDate),
            dangXuat: infoUser?.dangNhap.dangXuat || now.format(formatDate),
            ip: ip,
            idCaLamViec: 1,
            idChiNhanh: 22,
          }}
          onSubmit={(value, actions) => {
            dispatch(openShiffCaLamViec(value))
          }}
        >
          {(props) => (
            <form className="text-start" onSubmit={props.handleSubmit}>
              <div>
                <div className="text-base flex gap-5 ">
                  <div className="w-1/2">
                    <h2 className="font-medium text-gray-500">Chi nhánh</h2>
                    <span> {PkDangNhap?.tenChiNhanh}</span>
                  </div>
                  <div>
                    <h2 className="font-medium text-gray-500">Nhân viên</h2>
                    <span>
                      {' '}
                      {infoUser?.tenNV} - {infoUser?.maNV}
                    </span>
                  </div>
                </div>
                <div className="text-base flex gap-10 mt-3">
                  <div className="w-1/2">
                    <h2 className="font-medium text-gray-500">Thời gian làm việc</h2>
                    <span>
                      {' '}
                      {moment(props.values.thoiGianDangNhap).format('DD/MM/YYYY HH:mm:ss')} -{' '}
                      {now.format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                  </div>
                  <div className="w-1/2">
                    <h2 className="font-medium text-gray-500">Ca làm việc</h2>
                    <span>
                      {infoUser?.dangNhap?.idCaLamViec === 1
                        ? 'Ca A'
                        : infoUser?.dangNhap?.idCaLamViec === 2
                          ? 'Ca B'
                          : 'Ca c'}
                    </span>
                  </div>
                </div>
                <Divider className="my-3" />
                <div className="flex gap-3 flex-col">
                  {infoUser?.idNhom !== 4 ? (
                    <div>
                      <p className="font-semibold text-base flex items-center gap-2 text-gray-500">
                        <span>
                          Tiền dịch vụ: {formattedNumber(ThongKeDoanhThuChiNhanh?.tongTienDichVu)}đ
                        </span>
                      </p>
                      <ul className="ml-5 flex gap-20">
                        <li className="flex justify-between w-1/3 gap-2">
                          {' '}
                          Tiền Ca A{' '}
                          <InputNumber
                            readOnly
                            size="small"
                            variant="filled"
                            value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[0]?.doanhThuDichVu}
                            formatter={(value) =>
                              `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          />
                        </li>
                        <li className="flex justify-between w-1/3 gap-2">
                          {' '}
                          Tiền Ca B{' '}
                          <InputNumber
                            readOnly
                            size="small"
                            variant="filled"
                            value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[1]?.doanhThuDichVu}
                            formatter={(value) =>
                              `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          />
                        </li>
                        <li className="flex justify-between w-1/3 gap-2">
                          {' '}
                          Tiền Ca C{' '}
                          <InputNumber
                            readOnly
                            size="small"
                            variant="filled"
                            value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[2]?.doanhThuDichVu}
                            formatter={(value) =>
                              `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          />
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                  {infoUser?.idNhom !== 4 ? (
                    <div>
                      <p className="font-semibold text-base flex items-center gap-2 text-gray-500">
                        {' '}
                        <span>
                          Tiền thẻ thành viên:{' '}
                          {formattedNumber(ThongKeDoanhThuChiNhanh?.tongTienThe)}đ
                        </span>
                      </p>
                      <ul className="ml-5 flex gap-20">
                        <li className="flex justify-between w-1/3 gap-2">
                          {' '}
                          Tiền Ca A{' '}
                          <InputNumber
                            readOnly
                            size="small"
                            variant="filled"
                            value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[0]?.doanhThuThe}
                            formatter={(value) =>
                              `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          />
                        </li>
                        <li className="flex justify-between w-1/3 gap-2">
                          {' '}
                          Tiền Ca B{' '}
                          <InputNumber
                            readOnly
                            size="small"
                            variant="filled"
                            value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[1]?.doanhThuThe}
                            formatter={(value) =>
                              `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          />
                        </li>
                        <li className="flex justify-between w-1/3 gap-2">
                          {' '}
                          Tiền Ca C{' '}
                          <InputNumber
                            readOnly
                            size="small"
                            variant="filled"
                            value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[2]?.doanhThuThe}
                            formatter={(value) =>
                              `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          />
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                  {infoUser?.idNhom !== 4 ? (
                    <div className="text-base font-semibold flex justify-end gap-2">
                      Tổng lễ tân:{' '}
                      <span className="text-green-700 w-24 text-end">
                        {formattedNumber(
                          ThongKeDoanhThuChiNhanh?.tongTienDichVu +
                            ThongKeDoanhThuChiNhanh?.tongTienThe,
                        )}{' '}
                        đ
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                  {infoUser?.idNhom !== 4 ? <Divider className="my-1" /> : ''}
                  <div>
                    <p className="font-semibold text-base flex items-center gap-2 text-gray-500">
                      {' '}
                      <span>
                        Tiền bán lẻ: {formattedNumber(ThongKeDoanhThuChiNhanh?.tongTienThuocBanLe)}đ
                      </span>
                    </p>
                    <ul className="ml-5 flex gap-20">
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca A{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[0]?.doanhThuThuocBanLe}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca B{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[1]?.doanhThuThuocBanLe}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca C{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[2]?.doanhThuThuocBanLe}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="font-semibold text-base flex items-center gap-2 text-gray-500">
                      {' '}
                      <span>
                        Tiền toa thuốc:{' '}
                        {formattedNumber(ThongKeDoanhThuChiNhanh?.tongTienThuocKeToa)}đ
                      </span>
                    </p>
                    <ul className="ml-5 flex gap-20">
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca A{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[0]?.doanhThuThuocKeToa}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca B{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[1]?.doanhThuThuocKeToa}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                      <li className="flex justify-between w-1/3 gap-2">
                        {' '}
                        Tiền Ca C{' '}
                        <InputNumber
                          readOnly
                          size="small"
                          variant="filled"
                          value={ThongKeDoanhThuChiNhanh?.doanhThuCaLamViecs[2]?.doanhThuThuocKeToa}
                          formatter={(value) => `${value} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="text-base font-semibold flex justify-end gap-2">
                    Tổng Dược:{' '}
                    <span className="text-green-700 w-24 text-end">
                      {formattedNumber(
                        ThongKeDoanhThuChiNhanh?.tongTienThuocBanLe +
                          ThongKeDoanhThuChiNhanh?.tongTienThuocKeToa,
                      )}{' '}
                      đ
                    </span>
                  </div>
                  <Divider className="my-1" />
                </div>
              </div>
              {infoUser?.idNhom !== 4 ? (
                <div className="text-base font-semibold flex justify-end gap-2">
                  Tổng Doanh thu:{' '}
                  <span className="text-green-700 w-24 text-end">
                    {formattedNumber(ThongKeDoanhThuChiNhanh?.tongTienNgay)} đ
                  </span>
                </div>
              ) : (
                ''
              )}
              {infoUser?.idNhom !== 4 ? <Divider className="my-1" /> : ''}
              {!infoUser.dangNhap.thoiGianDangNhap ? (
                <div className="mt-5">
                  <Button
                    type="submit"
                    // onClick={closeCaLamViec}
                    className="w-full"
                    variant="contained"
                  >
                    Mở ca
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col justify-end gap-2 py-4">
                  <Button onClick={checkout} variant="contained">
                    Đóng ca
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(logoutUser(navigate, infoUser?.dangNhap.idDangNhap))
                      closeCaLamViec()
                    }}
                    variant="outlined"
                  >
                    Đăng xuất
                  </Button>
                </div>
              )}
            </form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default ModalCheckOut
